<template>
    <div>
        <div class="row">
            <div class="col-5 text-left">
                <h4 class="box-lot-titre m-0 mb-3 text-ellipsis" :title="lot.lotAbstract.caisse.libelle">
                    <span class="badge badge-dark ">{{ lot.lotAbstract.numero }}</span>
                    <span class="badge badge-secondary mr-3" :title="lot.date.date + ' '+ lot.date.time">{{ lot.date.date }}</span>
                    {{ lot.lotAbstract.caisse.libelle }}
                </h4>
                <div>
                    <span v-if="type === 'aTraiter'" class="badge badge-info"><i class="fa-regular fa-lightbulb mr-1"></i> À traiter</span>
                    <span v-if="type === 'enAttente'" class="badge badge-info"><i class="fa-regular fa-lightbulb mr-1"></i> En attente</span>

                    <span class="badge badge-success" v-if="lot.etatRetour === 1">Valide</span>
                    <span class="badge badge-info" v-else-if="lot.etatRetour === 3">En attente</span>
                    <template v-else-if="lot.etatRetour === 0">
                        <span class="badge badge-danger">Erreur</span>
                        <span class="badge badge-secondary" :title="lot.messageRetour">{{ Truncate(lot.messageRetour, 20) }}</span>

                    </template>
                </div>
            </div>
            <div class="col-7">
                <div class="row">
                    <div class="col-4">
                        <div class="box-lot-facture-nombre">
                            {{ lot.dossierCollection.length }}
                        </div>
                        <div class="box-lot-facture-libelle text-muted">{{ Translator.trans('libelle.factures') }}</div>
                    </div>
                    <div class="col-3">
                        <div class="box-lot-facture-nombre">
                            {{ totalPieceCount }}
                        </div>
                        <div class="box-lot-facture-libelle text-muted">{{ Translator.trans('libelle.pieces') }}</div>
                    </div>
                    <div class="col-5">
                        <div class="box-lot-facture-nombre">
                            <div class="position-relative">
                                <b-progress :max="lotMaxFilesize" height="26px" variant="secondary" class="bg-light">
                                    <b-progress-bar :value="lotFilesize"></b-progress-bar>
                                </b-progress>
                                <div class="position-absolute inset-0 d-flex justify-content-center align-items-center">
                                    <span class="font-size-16px"><span class="text-normal">{{ Filesize(lotFilesize) }}</span><small> / {{ Filesize(lotMaxFilesize) }}</small></span>
                                </div>
                            </div>
                        </div>
                        <div class="box-lot-facture-libelle text-muted">{{ Translator.trans('libelle.taille-lot') }}</div>
                    </div>

                </div>
            </div>
        </div>
        <b-table class="mt-4 table-compact" ref="factureTable" responsive no-sort-reset show-empty bordered striped :items="lot.dossierCollection" :fields="fields" :per-page="perPage" :current-page="currentPage" sort-by="numero" primary-key="id">
            <template #empty="scope">
                <span class="text-muted">{{ Translator.trans('libelle.aucun-element') }}</span>
            </template>
            <template #cell(patient)="{item}">
                {{ item.standard.patient.text }}
            </template>
            <template #cell(partCaisseMontant)="{item}">
                {{ Euro(item.standard.partCaisseMontant) }}
            </template>
            <template #cell(pieces)="{item}">
                <b-badge :id="'piecePopover'+item.id" variant="secondary">
                    <i class="im-files-empty mr-1"></i> {{ item.pieceCollection.length }} <small>({{ Filesize(item.filesize) }})</small>
                </b-badge>
                <Popover :target="'piecePopover'+item.id" placement="top" trigger="hover" class="piece-scor-popover">
                    <template v-slot:title>
                        <div class="text-center">Pièces</div>
                    </template>
                    <piece-scor :pieces-scor="item.pieceCollection" @open="openPiece($event, item)"></piece-scor>
                </Popover>
            </template>
            <template #cell(retour)="{value, item}">
                <template v-if="null === item.etatRetour">-</template>
                <span class="badge badge-success" v-else-if="1 === item.etatRetour">Valide</span>
                <template v-else>
                    <span class="badge badge-danger">Erreur</span>
                    <span class="badge badge-secondary" v-if="null !== item.messageRetour" :title="item.messageRetour">{{ Truncate(item.messageRetour, 10) }}</span>
                </template>
            </template>
            <template #cell(action)="{item}">
                <span class="text-nowrap">
                    <a :title="Translator.trans('action.voir')" class="btn btn-table btn-info" target="_blank" :href="path('facturation.facture.view', {id: item.standard.id})">
                        <i class="fa-solid fa-fw fa-eye"></i>
                    </a>
                </span>
            </template>
        </b-table>
        <table-pagination :count="lot.dossierCollection.length" :per-page="perPage" v-model="currentPage"></table-pagination>
        <div class="mt-4 text-center">
            <b-btn :href="path('facturation.lot.scor.view', {id: lot.id})" variant="info" target="_blank">
                <i class="fa-solid fa-eye mr-1"></i> {{ Translator.trans('action.voir') }}
            </b-btn>

            <template v-if="type === 'enAttente'">
                <b-btn variant="secondary" v-if="lot.etatEnvoiPapier !== null" :disabled="loading" @click="printLot(lot)">
                    <i class="fa-solid fa-print mr-1"></i> {{ Translator.trans('action.imprimer-bordereau') }}
                </b-btn>
            </template>
            <template v-else-if="type === 'aTraiter'">
                <template v-if="lot.etat === 0">
                    <b-btn variant="primary" :disabled="loading" @click="envoyerLot(lot)">
                        <i class="fa-solid fa-paper-plane mr-1"></i> {{ Translator.trans('action.envoyer') }}
                    </b-btn>
                </template>
                <template v-else-if="lot.etat === 3">
                    <b-btn variant="warning" :disabled="loading" @click="reconstituerLot(lot)">
                        <i class="fa-solid fa-arrows-rotate mr-1"></i> {{ Translator.trans('action.reconstituer') }}
                    </b-btn>
                </template>
                <template v-else-if="lot.etat === 4">
                    <b-btn variant="warning" :disabled="loading" @click="envoyerLot(lot)">
                        <i class="fa-solid fa-paper-plane mr-1"></i> {{ Translator.trans('action.retransmettre') }}
                    </b-btn>
                </template>
                <template v-else>
                    <b-btn variant="secondary" v-if="lot.etatEnvoiPapier !== null" :disabled="loading" @click="printLot(lot)">
                        <i class="fa-solid fa-print mr-1"></i> {{ Translator.trans('action.imprimer-bordereau') }}
                    </b-btn>
                    <b-btn variant="success" :disabled="loading" @click="'todo'">
                        <i class="fa-solid fa-check mr-1"></i> {{ Translator.trans('action.traiter') }}
                    </b-btn>
                </template>
            </template>
            <b-btn variant="secondary" class="js-close-swal" :disabled="loading">{{ Translator.trans('action.fermer') }}</b-btn>
        </div>
        <PieceViewer :piece-scor="previewPieceScor" :piece="previewPiece" :piece-stack="previewStack" @piece="openPiece($event)"></PieceViewer>
    </div>
</template>

<script setup>
import swal from "sweetalert2";
import Euro from "@/Vue/Filters/Euro";
import Filesize from "@/Vue/Filters/Filesize";
import Truncate from "@/Vue/Filters/Truncate";
import Translator from "@/App/Translator";
import Router from "@/App/Router";
import {computed, ref, toRefs} from "vue";
import {onKeyStroke} from '@vueuse/core'
import PieceViewer from '@/Vue/Components/Piece/Viewer';
import Popover from '@/Vue/Components/Popover';
import Request from "@/App/Request";

const emit = defineEmits(['refresh']);

const props = defineProps({
    lot: Object,
    pieceViewer: Object,
    type: String,
    lotMaxFilesize: Number,
});

const {lot, pieceViewer, type, lotMaxFilesize} = toRefs(props);

const perPage = ref(15);
const currentPage = ref(1);

const fields = ref([
    {key: 'standard.numero', label: Translator.trans('libelle.numero'), sortable: true},
    {key: 'standard.date', label: Translator.trans('libelle.date'), sortable: true},
    {key: 'patient', label: Translator.trans('libelle.patient'), sortable: true},
    {key: 'partCaisseMontant', label: Translator.trans('libelle.montant-caisse-abreviation'), sortable: true},
    {key: 'retour', label: Translator.trans('libelle.retour'), sortable: true},
    {key: 'pieces', label: Translator.trans('libelle.pieces'), sortable: true},
    {key: 'action', label: Translator.trans('libelle.actions')},
]);

const previewPiece = ref(null);
const previewDossier = ref(null);
const loading = ref(false);

onKeyStroke('Escape', (e) => {
    if(!previewPiece.value) {
        swal.clickCancel();
    }
});

const totalPieceCount = computed(() => lot.value.dossierCollection.reduce((res, item) => (res += item.pieceCollection.length, res), 0));
const lotFilesize = computed(() => lot.value.dossierCollection.reduce((res, item) => (res += item.filesize, res), 0));
const previewPieceScor = computed(() => previewPiece.value ? piecesScorByPieceId.value[previewPiece.value.id] : null);
const previewStack = computed(() => previewDossier.value ? previewDossier.value.pieceCollection.map(pieceScor => pieceScor.piece).filter(Boolean) : []);
const piecesScorByPieceId = computed(() => {
    return lot.value.dossierCollection.reduce((res, dossier) => {
        for (const pieceScor of dossier.pieceCollection) {
            if (pieceScor.piece) {
                res[pieceScor.piece.id] = pieceScor;
            }
        }

        return res;
    }, {});
});

const openPiece = (piece, dossier) => {
    previewPiece.value = piece;
    if (dossier) {
        previewDossier.value = dossier;
    }
};
const envoyerLot = (lot) => {
    loading.value = true;

    Request.getJson(Router.generate('facturation.lot.scor.send', {id: lot.id})).then((data) => {
        emit('refresh');

        swal.close();
    });
};
const traiterEnvoiPapier = (lot) => {
    loading.value = true;

    Request.getJson(Router.generate('facturation.lot.scor.traiter-envoi-papier', {id: lot.id})).then((data) => {
        emit('refresh');

        swal.close();
    });
};
const reconstituerLot = (lot) => {
    this.loading = true;

    Request.getJson(Router.generate('facturation.lot.scor.reconstituer', {id: lot.id})).then((data) => {
        emit('refresh');

        swal.close();
    });
};
const printLot = (lot) => {
    loading.value = true;

    App.Utils.print(Router.generate('facturation.lot.scor.export', {id: lot.id, type: 'html'}), lot.bordereauFileName).then(() => {
        loading.value = false;
    });
};
</script>
