import Moment from 'moment';

App.Interfaces = class {
    constructor(droits, paths) {
        this.droits = droits;
        this.paths = paths;

        this._initWebSocket();
        this._initTimer();
    }

    _initWebSocket() {
        if (this.droits.ROLE_REGULATION_TRANSPORT_INTERFACE_LIST) {
            let notificationSound = new Audio(this.paths.notificationSound);
            let newMissionSound = new Audio(this.paths.newMissionSound);
            let unansweredMissionSound = new Audio(this.paths.unansweredMissionSound);
            let unansweredMissionsSound = new Audio(this.paths.unansweredMissionsSound);

            App.webSocket.subscribe('regulation/transport/trajet/interfaces', (uri, data) => {
                if(data.alertType) {
                    notificationSound.play().then(() => {
                        setTimeout(() => {
                            if('new' === data.alertType) {
                                newMissionSound.play();
                            } else if('remind' === data.alertType) {
                                if(data.count === 1) {
                                    unansweredMissionSound.play();
                                } else {
                                    unansweredMissionsSound.play();
                                }
                            }
                        }, 500);
                    }).catch(() => {});
                }

                if(data.html) {
                    $('#js-proposition-transport-content').html(data.html.dropdown).parent().parent().toggleClass('dropdown-pulse-danger', data.count >= 1);
                    $('#js-proposition-transport-count').text(data.count).toggle(data.count >= 1);

                    let $modals = $('#js-proposition-transport-modals');
                    let hasModal = data.html.modals.indexOf('modal');

                    if ($modals.length && !hasModal) {
                        $modals.remove();
                    } else if (!$modals.length && hasModal) {
                        $('body').append('<div id="js-proposition-transport-modals"></div>');
                        $modals = $('#js-proposition-transport-modals');
                    }

                    if (hasModal) {
                        let $openedModal = $modals.find('.modal.show');

                        if ($openedModal.length) {
                            $openedModal = $openedModal.first();

                            $('.js-proposition-transport-modal', $modals).not('#' + $openedModal.attr('id')).remove();

                            let $newModals = $(data.html.modals);

                            $newModals.siblings('.js-proposition-transport-modal').each(function (index, element) {
                                let $element = $(element);

                                if ($openedModal.attr('id') === $element.attr('id')) {
                                    $openedModal.html($element.html());
                                } else {
                                    $modals.append($element);
                                }
                            });

                        } else {
                            $modals.html(data.html.modals);
                        }

                        this._initModalDecisionButton();
                    }

                    this._tickTimer();
                }
            });
        }
    }

    _initTimer() {
        setInterval(this._tickTimer, 1000);
    }

    _initModalDecisionButton() {
        $('.js-proposition-transport-decision-button').click((event) => {
            let $element = $(event.currentTarget);
            let role = $element.data('role');
            let hash = $element.data('hash');

            if ((('accept' === role && this.droits.ROLE_REGULATION_TRANSPORT_INTERFACE_ACCEPT) || ('deny' === role && this.droits.ROLE_REGULATION_TRANSPORT_INTERFACE_DENY)) && hash) {
                App.webSocket.publish('regulation/transport/trajet/interfaces', {'action': role, 'hash': hash})
            }
        });
    }

    _tickTimer() {
        $('#js-proposition-transport-content').find('.js-proposition-transport-timer').each((index, element) => {
            let $element = $(element);
            let date = $element.data('date');

            if (date) {
                date = Moment.utc(date, 'DD/MM/YYYY HH:mm:ss');

                if (date.isValid()) {
                    let now = Moment();

                    if (now <= date) {
                        let years = date.diff(now, 'years');
                        let months = date.diff(now, 'months');
                        let weeks = date.diff(now, 'weeks');
                        let days = date.diff(now, 'days');
                        let hours = date.diff(now, 'hours');
                        let minutes = date.diff(now, 'minutes');
                        let seconds = date.diff(now, 'seconds');
                        let str;

                        if (years) {
                            str = years + ' an' + (years > 1 ? 's' : '');
                        } else if (months) {
                            str = months + ' mois';
                        } else if (weeks) {
                            str = weeks + ' sem';
                        } else if (days) {
                            str = days + ' j';
                        } else if (hours) {
                            str = hours + ' h';
                        } else if (minutes) {
                            str = minutes + ' min';
                        } else {
                            str = seconds + ' s';
                        }

                        if (str !== $element.text()) {
                            $element.text(str);
                        }
                    }
                }
            }
        });
    }
};