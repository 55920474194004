'use strict';

import {createApp} from 'vue'
import moment from "moment";
import Fuse from "fuse.js";
import VueChartkick from 'vue-chartkick'
import 'chartkick/chart.js'

App.Administration.Supervision = class {
    constructor() {
        moment.locale('fr');

        const element = document.getElementById('app')

        createApp({
            delimiters: ['[[', ']]'],
            data() {
                return {
                    server: {},
                    clients: [],
                    me: null,
                    searchQuery: '',
                    mode: 'user',
                    showActiveTabsOnly: false,
                    // TODO get from repo
                    applications: App.Administration.applications,
                    browsersColors: {
                        'Chrome': '#eeb534',
                        'Firefox': '#ad46dd',
                        'Edge': '#43bad3',
                        'Opera': '#f02b39',
                    },
                };
            },
            mounted() {
                App.webSocket.subscribe('administration/websocket', (uri, data) => {
                    if(data.action === 'init') {
                        this.server = data.server;
                    }
                    else if(data.action === 'list') {
                        this.me = data.me;
                        this.clients = data.clients;
                    } else if(data.action === 'screenshot') {
                        $('#screenshotLoader').hide();
                        $('#screenshotImage').show().attr('src', data.image);
                    }
                });

            },
            methods: {
                action(id, action) {
                    App.webSocket.publish('administration/websocket', {action, id});

                    if(action === 'screenshot') {
                        $('#screenshotLoader').show();
                        $('#screenshotImage').hide();
                        $("#screenshotModalBtn").trigger('click');
                    }
                }
            },
            computed: {
                fuseSearch() {
                    return new Fuse(this.clients, {
                        keys: ['id', 'user.username', 'page.application', 'page.title'],
                        minMatchCharLength: 1,
                        tokenize: true,
                        threshold: 0,
                    })
                },
                filteredClients() {
                    let clients =  '' === this.searchQuery ? this.clients : this.fuseSearch.search(this.searchQuery);

                    let groupedClients = {};
                    clients.forEach((client) => {
                        if(this.showActiveTabsOnly && client.visibility === 'hidden') {
                            return;
                        }

                        let group = 0;
                        if('user' === this.mode) {
                            group = client.user.id || 'Inconnu';
                        }
                        else if('application' === this.mode) {
                            group = client.page.application || 'Aucune';
                        }

                        if(!(group in groupedClients)) {
                            groupedClients[group] = [];
                        }

                        groupedClients[group].push(client);
                    });

                    return Object.values(groupedClients);
                },
                browserChartData() {
                    let browsers = {};

                    this.clients.forEach((client) => {
                        let browser = client.userAgent.browser.name;

                        if(!(browser in browsers)) {
                            browsers[browser] = 0;
                        }

                        browsers[browser]++;
                    });

                    let data = [];

                    for(let browser in browsers) {
                        data.push([browser, browsers[browser]]);
                    }

                    return data;
                },
                browserChartColors() {
                    let colors = [];
                    let browsers = this.browserChartData;

                    for (let key in browsers) {
                        let browserName = browsers[key][0];
                        let color = '#414141';

                        if (browserName in this.browsersColors) {
                            color = this.browsersColors[browserName];
                        }

                        colors.push(color);
                    }

                    return colors;
                },
                applicationCounts() {
                    let applications = {};

                    this.clients.forEach((client) => {
                        let application = client.page.application || 'Aucune';

                        if(!(application in applications)) {
                            applications[application] = 0;
                        }

                        applications[application]++;
                    });

                    return applications;
                },
                applicationChartData() {
                    let applications = this.applicationCounts;

                    let data = [];

                    for(let application in applications) {
                        let libelle = application;
                        if(application in this.applications) {
                            libelle = this.applications[application].name;
                        }

                        data.push([libelle, applications[application]]);
                    }

                    return data;
                },
                applicationChartColors() {
                    let colors = [];
                    let applications = this.applicationCounts;

                    for(let application in applications) {
                        let color = '#000';
                        if(application in this.applications) {
                            color = this.applications[application].color;
                        }

                        colors.push(color);
                    }

                    return colors;
                },
            },
        }).use(VueChartkick).mount(element);
    }
};
