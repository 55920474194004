<template>
    <sefi-popup title="Rechercher données transporteur" :loading="loading" :error="error" :result="result" :init-form="true" @refresh="refresh">
        <template v-slot:initForm>
            <div class="mx-5">
                <sefi-form-row v-model="inputData.structure" :errors="errors.structure" component="sefi-form-structure" label="Société" required></sefi-form-row>
                <sefi-form-row v-model="inputData.dateDebut" :errors="errors.dateDebut" component="form-date" :props="{picker: true}" label="Date début" required></sefi-form-row>
                <sefi-form-row v-model="inputData.dateFin" :errors="errors.dateFin" component="form-date" :props="{picker: true}" label="Date fin"></sefi-form-row>
            </div>
        </template>
        <template v-slot:initFormButtons>
            <b-btn variant="primary" @click="refresh">
                <i class="fa-solid fa-magnifying-glass fa-fw"></i> Rechercher
            </b-btn>
        </template>
        <template v-slot:form>
            <div class="mb-3">
                <sefi-form-widget component="sefi-form-structure" v-model="inputData.structure" :errors="errors.structure"></sefi-form-widget>
            </div>
            <div class="mb-3">
                <sefi-form-widget component="form-input-group" v-model="inputData.dateDebut" :props="{label: 'Début', component: 'form-date', picker: true}" :errors="errors.dateDebut"></sefi-form-widget>
            </div>
            <div>
                <sefi-form-widget component="form-input-group" v-model="inputData.dateFin" :props="{label: 'Fin', component: 'form-date', picker: true}" :errors="errors.dateFin"></sefi-form-widget>
            </div>
        </template>
        <template v-slot:result v-if="result">
            <div class="row mb-3">
                <div class="col-2 d-flex">
                    <div class="badge badge-light font-size-12px d-flex flex-1 align-items-center justify-content-center" @click="toggleType">
                        {{ Translator.trans('chapitre.'+type) }}
                    </div>
                </div>
                <div class="col-3-5 ml-auto">
                    <form-input class="input-sm" v-model="filter" :placeholder="Translator.trans('placeholder.recherche')"></form-input>
                </div>
            </div>
            <template v-if="'personnels' === type">
                <b-table class="table-compact" responsive no-sort-reset show-empty bordered striped :per-page="personnelsPerPage" :empty-filtered-text="Translator.trans('libelle.aucun-element')" :filter="filter" :current-page="personnelsPage" :items="result.personnels" :fields="personnelsFields" sort-by="nom">
                    <template #empty="scope">
                        <span class="text-muted">{{ Translator.trans('libelle.aucun-element') }}</span>
                    </template>
                    <template #cell(qualifications)="{value}">
                        <span class="badge badge-block badge-secondary" v-for="qualification in value" :title="qualification.libelle + ', obtenue le ' + qualification.date">{{ qualification.acronyme }}</span>
                    </template>
                    <template #cell(action)="{item}">
                        <b-btn type="button" variant="outline-success" :title="Translator.trans('action.creer-le-personnel')" class="btn-table" target="_blank" :href="path('referentiel.personnel.new', {prenomUsuel: item.prenom, nomUsuel: item.nom, dateEntree: item.dateEntree, dateSortie: item.dateSortie, qualifications: item.qualifications.map(qualification => qualification.id)})">
                            <i class="fa-solid fa-fw fa-plus"></i>
                        </b-btn>
                    </template>
                </b-table>
                <table-pagination :count="result.personnels.length" :per-page="personnelsPerPage" v-model="personnelsPage"></table-pagination>
            </template>
            <template v-else-if="'vehicules' === type">
                <b-table class="table-compact" responsive no-sort-reset show-empty bordered striped :per-page="vehiculesPerPage" :empty-filtered-text="Translator.trans('libelle.aucun-element')" :filter="filter" :current-page="vehiculesPage" :items="result.vehicules" :fields="vehiculesFields" sort-by="nom">
                    <template #empty="scope">
                        <span class="text-muted">{{ Translator.trans('libelle.aucun-element') }}</span>
                    </template>
                    <template #cell(action)="{item}">
                        <b-btn type="button" variant="outline-success" :title="Translator.trans('action.creer-le-vehicule')" class="btn-table" target="_blank" :href="path('referentiel.vehicule.new', {immatriculation: item.immatriculation, entree: item.date, sortie: item.dateSortie})">
                            <i class="fa-solid fa-fw fa-plus"></i>
                        </b-btn>
                    </template>
                </b-table>
                <table-pagination :count="result.vehicules.length" :per-page="vehiculesPerPage" v-model="vehiculesPage"></table-pagination>
            </template>
        </template>
    </sefi-popup>
</template>

<script setup>
import Moment from "moment";
import {computed, ref, toRefs, onMounted} from "vue";
import Router from "@/App/Router";
import Request from "@/App/Request";
import Translator from "@/App/Translator";

const props = defineProps({
    data: Object,
});
const {data} = toRefs(props);


const result = ref(null);
const inputData = ref({
    structure: null,
        dateDebut: Moment().format('DD/MM/YYYY'),
});
const type = ref(null);
const loading = ref(false);
const error = ref(null);
const filter = ref('');

const vehiculesPerPage = 10;
const vehiculesPage = ref(1);
const vehiculesFields = [
    {key: 'immatriculation', label: Translator.trans('libelle.immatriculation'), sortable: true},
    {key: 'date', label: Translator.trans('libelle.date-autorisation'), sortable: true},
    {key: 'dateSortie', label: Translator.trans('libelle.dateSortie'), sortable: true},
    {key: 'action', label: Translator.trans('libelle.actions'), thClass: 'width-1px text-center', tdClass: 'text-center text-nowrap'},
];

const personnelsPerPage =10;
const personnelsPage = ref(1);
const personnelsFields = [
    {key: 'prenom', label: Translator.trans('libelle.prenom'), sortable: true},
    {key: 'nom', label: Translator.trans('libelle.nom'), sortable: true},
    {key: 'dateEntree', label: Translator.trans('libelle.dateEntree'), sortable: true},
    {key: 'dateSortie', label: Translator.trans('libelle.dateSortie'), sortable: true},
    {key: 'qualifications', label: Translator.trans('libelle.qualificationCollection'), sortable: true},
    {key: 'action', label: Translator.trans('libelle.actions'), thClass: 'width-1px text-center', tdClass: 'text-center text-nowrap'},
];

const errors = computed(() => {
    if(error.value) {
        return error.value.errors || [];
    }

    return [];
});

const toggleType = () => {
    type.value = 'vehicules' === type.value ? 'personnels' : 'vehicules';
};

const refresh = () => {
    error.value = null;
    loading.value = true;
    vehiculesPage.value = 1;
    personnelsPage.value = 1;

    Request.postJson(Router.generate('facturation.sefi.ps.rechercher-donnees-transporteur'), inputData.value).then((data) => {
        if(data.success) {
            result.value = data.data;
        } else {
            error.value = data.error;
        }

        loading.value = false;
    });
};

onMounted(() => {
    if(data.value) {
        type.value = data.value.type;

        if (data.value.inputData) {
            inputData.value = data.value.inputData;
        }
    }

    if(inputData.value.structure && inputData.value.dateDebut) {
        refresh();
    }
});
</script>
