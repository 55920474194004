<template>
    <td class="text-center">
        <template v-if="this.$parent.editEnabled">
            <input type="number" class="form-control text-center input-sm d-inline-block width-40px" :placeholder="placeholder" v-model.lazy="type[field]" :min="min" :max="max">
        </template>
        <quantite :value="value ?? placeholder" v-else></quantite>
    </td>
</template>

<script>
    export default {
        props: ['type', 'field', 'min', 'max'],
        computed: {
            placeholder() {
                if (this.field === 'indemniteCasseCroute') {
                    return this.type.indemniteCasseCrouteCalculee;
                }
            },
            value: function() {
                return this.type[this.field];
            }
        }
    }
</script>
