<template>
<div class="row">
    <div class="col-6">
      <div class="panel panel-compact">
          <div class="panel-heading d-flex justify-content-between bg-light py-3 px-4">
              <div>{{ Translator.trans('referentiel.tableau-de-bord.general.categorieVehicule') }}</div>
          </div>
          <div class="panel-wrapper">
              <div class="panel-body">
                  <pie-chart empty=" " :data="vehiculeChartData" :colors="vehiculeChartColors"></pie-chart>
              </div>
          </div>
      </div>
    </div>
    <div class="col-6">
        <div class="panel panel-compact">
            <div class="panel-heading d-flex justify-content-between bg-light py-3 px-4">
                <div>{{ Translator.trans('referentiel.tableau-de-bord.general.appareilsAMettreJour') }}</div>
            </div>
            <div class="panel-wrapper">
                <div class="panel-body">
                    <b-table table-class="table-compact" ref="aUpdateTable" :busy="loading" responsive no-sort-reset show-empty bordered striped :items="aUpdate" :fields="aUpdateFields" :per-page="perPage" :current-page="aUpdateCurrentPage" sort-by="date" primary-key="id">
                        <template #empty>
                            <span class="text-muted">{{ Translator.trans('libelle.aucun-element') }}</span>
                        </template>
                        <template #table-busy>
                            <div class="p-3">
                                <b-spinner class="mr-2"></b-spinner> Chargement…
                            </div>
                        </template>
                        <template #cell(libelle)="{item}">
                            <a v-if="item._roles.ROLE_REFERENTIEL_APPAREIL_VIEW" :href="Router.generate('referentiel.appareil.view', {id: item.id})">
                                {{ item.libelle }}
                            </a>
                            <template v-else>
                                {{ item.libelle }}
                            </template>
                        </template>
                        <template #cell(appVersion)="{item}">
                            {{ item.appVersion }}
                        </template>
                        <template #cell(structure)="{value}">
                            <a :href="Router.generate('referentiel.structure.view', {id: value.id})" :title="value.libelleComplet" class="badge badge-block badge-secondary label-outline text-nowrap">
                                {{ value.libelle }}
                            </a>
                        </template>
                        <template #cell(action)="{item}">
                            <a :href="Router.generate('referentiel.appareil.view', {id: item.id})"  v-if="item._roles.ROLE_REFERENTIEL_APPAREIL_VIEW" >
                                <button type="button" :title="Translator.trans('action.voir')" class="btn btn-table btn-info">
                                    <i class="fa-solid fa-fw fa-eye"></i>
                                </button>
                            </a>
                            <a :href="Router.generate('referentiel.appareil.edit', {id: item.id})"  v-if="item._roles.ROLE_REFERENTIEL_APPAREIL_EDIT" >
                                <button type="button" :title="Translator.trans('action.modifier')" class="btn btn-table btn-success">
                                        <i class="fa-solid fa-fw fa-pencil"></i>
                                </button>
                            </a>
                        </template>
                  </b-table>
                <table-pagination :count="aUpdate.length" :per-page="perPage" v-model="aUpdateCurrentPage"></table-pagination>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script setup>
import Router from "@/App/Router";
import Vue, {computed, onMounted, ref, toRefs, watch} from "vue";
import Translator from "@/App/Translator";
import {useRequest} from "@/Vue/Composables/Request";

const {postJson, abort} = useRequest();

const emit = defineEmits(['refresh', 'error']);
const props = defineProps({
    structures: Array,
    tab: String,
});

const {structures, tab} = toRefs(props);

const loading = ref(false);
const categorieVehicule = ref([]);
const appareilsAJour = ref([]);
const aUpdate = ref([]);
const perPage = ref(5);
const aUpdateCurrentPage = ref(1);

const vehiculeCategorieData = {
    'a6255606-c441-4c33-9524-a242a4202e16': {
        name: 'AMBU - Type A',
        color: '#1bb7fa',
    },
    'c073caf3-0435-44f0-8f08-de37262d0840': {
        name: 'ASSU - Type B',
        color: '#ab8ce4',
    },
    'de25341b-52c9-4935-b6e6-937314b0bf40': {
        name: 'ASSU - Type C',
        color: '#ab8ce4',
    },
    'd034aeb4-1be5-4893-a8d8-f8e1b0bfa5d1': {
        name: 'VSL - Catégorie D',
        color: '#00c292',
    },
    '1a86d1fa-9bc5-44ff-8dd1-043b46ac24bc': {
        name: 'ASSU Bariatrique',
        color: '#ab8ce4',
    },
    'fdda9518-e059-4141-87ef-16dbe2244805': {
        name: 'AMBU Pédiatrique',
        color: '#1bb7fa',
    },
    'bad71cf7-8739-4916-915d-8259ed03de31': {
        name: 'Taxi',
        color: '#fec107',
    },
    '054710ee-e470-4d9a-8a27-4d637660a7da': {
        name: 'Bus',
        color: '#5a5a5a',
    },
    '008f1462-7f30-4b08-913b-e90a1cbde4b0': {
        name: 'Naval',
        color: '#5a5a5a',
    },
    '23c67ac9-c564-4876-a332-eb5205e1ccef': {
        name: 'Avion',
        color: '#5a5a5a',
    },
    '0018b03e-e5d8-4fb2-b5bd-1353f64a0e58': {
        name: 'Hélicoptère',
        color: '#5a5a5a',
    },
    'f0328d6a-2a3e-46ff-bb6d-da32a767c313': {
        name: 'Corbillard',
        color: '#5a5a5a',
    },
    '84f435ca-1434-4526-b08c-6cb89584134a': {
        name:  'Autre',
        color: '#fb9678',
    }
};

const refresh = () => {
    if (!structures.value.length) {
        abort();
        categorieVehicule.value = [];
        appareilsAJour.value = [];

        aUpdate.value = [];
        perPage.value = 5;
        aUpdateCurrentPage.value = 1;

        loading.value = false;
        emit('refresh');

        return;
    }

    loading.value = true;

    postJson(Router.generate('referentiel.tableau-de-bord.general'), {
        tableau_de_bord_filter: {
            structure: structures.value,
        },
    }).then((data) => {
        categorieVehicule.value = data.categorieVehicule;
        appareilsAJour.value = data.appareilsAJour;
        aUpdate.value = data.aUpdate;
        loading.value = false;
        emit('refresh');
    }, (err) => emit('error', err)).finally(() => {
        loading.value = false;
    });

};

const aUpdateFields = computed(() => [
    {key: 'libelle', label: Translator.trans('libelle.libelle'), sortable: true},
    {key: 'appVersion', label: Translator.trans('libelle.version'), sortable: true},
    structures.value.length > 1 ? {key: 'structure', label: Translator.trans('libelle.societe'), tdClass: 'width-1px', sortable: true} : null,
    {key: 'action', label: Translator.trans('libelle.actions'), thClass: 'width-1px text-center', tdClass: 'text-right text-nowrap'},
]);

const vehiculeChartData = computed(() => {
    const data = [];

    for (let vehicule in categorieVehicule.value) {
        let libelle = vehicule;
        if(vehicule in vehiculeCategorieData) {
            libelle = vehiculeCategorieData[vehicule].name;
        }

        data.push([libelle, categorieVehicule.value[vehicule]]);
    }

    return data;
});

const vehiculeChartColors = (() => {
    let colors = [];

    for (let vehicule in categorieVehicule.value) {
        let color = '#000';
        if(vehicule in vehiculeCategorieData) {
            color = vehiculeCategorieData[vehicule].color;
        }

        colors.push(color);
    }

    return colors;
});

watch(structures, () => refresh());
onMounted(() => refresh());
</script>
