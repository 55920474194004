'use strict';

import Router from "@/App/Router";
import Translator from "@/App/Translator";

App.Referentiel = class {};

App.Referentiel.Sync = class {
    static checkSync(ressource, ids) {
        if (ids.length) {
            $.ajax({
                url: Router.generate('referentiel.' + ressource + '.ajax.sync.check'),
                method: 'POST',
                cache: false,
                data: {
                    ressourceCollection: ids
                },
            }).done((data) => {
                $('.js-synchronisation-loading').addClass('d-none');
                $('.js-synchronisation-success').removeClass('d-none').attr('title', Translator.trans('referentiel.' + ressource + '.sync.valide'));

                for (let id in data) {
                    const fields = data[id];
                    const $block = $('.js-referentiel-synchronisation-' + id);

                    $block.find('.js-synchronisation-success').addClass('d-none');

                    if (fields.includes('referentiel_ambulancier_id')) {
                        $block.find('.js-synchronisation-error').removeClass('d-none').attr('title', Translator.trans('referentiel.' + ressource + '.sync.introuvable'));
                    } else {
                        $block.find('.js-synchronisation-warning').removeClass('d-none').attr('title', Translator.trans('referentiel.' + ressource + '.sync.donnees-differentes', { fields: new Intl.ListFormat('fr').format(fields) }));
                    }
                }
            }).fail(() => {
                $('.js-synchronisation-loading').addClass('d-none');
            });
        }
    }
};
