<template>
    <button type="button" class="btn btn-outline-secondary" :class="small ? 'btn-xs' : 'btn-sm'" :id="id" :title="title">
        <IndicateurGeocodage :value="Number(initData.precision)" :small="small" v-model:title="title"></IndicateurGeocodage>
    </button>

    <Popover :target="id" :placement="placement" class="geocodage-popover" :offset="small ? 4 : 8" @show="onShow" ref="popover">
        <div class="mb-2">
            <div class="d-flex">
                <div class="flex-1 z-1 position-relative">
                    <div class="row">
                        <div class="col-6">
                            <GeocodageInput v-model="data.adresse" type="textarea" geocodageSuffix="" :sourceOrganisme="organismes" class="js-autosize-focus form-control input-sm" rows="1" data-absolute="true" placeholder="Adresse" @changes="onGeocodageInput" @data="onGeocodageData"></GeocodageInput>
                        </div>
                        <div class="col-2 px-0">
                            <GeocodageInput v-model="data.codePostal" type="text" geocodageField="city" geocodageType="municipality" geocodageSuffix="" class="form-control input-sm" placeholder="Code postal" @changes="onGeocodageInput" @data="onGeocodageData"></GeocodageInput>
                        </div>
                        <div class="col-4">
                            <GeocodageInput v-model="data.ville" type="text" geocodageField="postcode" geocodageType="municipality" geocodageSuffix="" class="form-control input-sm" placeholder="Ville" @changes="onGeocodageInput" @data="onGeocodageData"></GeocodageInput>
                        </div>
                    </div>
                </div>
                <div class="ml-2">
                    <button type="button" class="btn btn-secondary btn-sm" title="Géocoder à partir de la position" :disabled="reverseGeocodeLoading" @click="reverseGeocode">
                        <i class="fa-solid fa-map-location-dot fa-lg"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="position-relative tailwind isolate" style="height: 250px;">
            <GeocodageMap ref="map" :center="initData.longitudeDegre && initData.latitudeDegre ? [initData.longitudeDegre, initData.latitudeDegre] : null" @click="onMapClick">
                <Pin :variant="'gray'" v-model:lngLat="lngLat" draggable v-if="lngLat[1] && lngLat[0]">
                    <template #content>
                        <IndicateurGeocodage :value="Number(data.precision)"></IndicateurGeocodage>
                    </template>
                </Pin>
            </GeocodageMap>
        </div>
        <div class="d-flex justify-content-between mt-2">
            <div class="d-flex">
                <div class="input-group">
                    <div class="input-sm d-none"></div>
                    <div class="input-group-prepend">
                        <span class="input-group-text" title="Coordonnées géographiques">
                            <span class="fa-solid fa-globe"></span>
                        </span>
                    </div>
                    <input type="text" class="form-control input-sm width-80px" placeholder="Latitude" v-model="data.latitudeDegre" @input="onLngLatInput">
                    <input type="text" class="form-control input-sm width-80px" placeholder="Longitude" v-model="data.longitudeDegre" @input="onLngLatInput">
                </div>
                <div class="input-group ml-2">
                    <div class="input-sm d-none"></div>
                    <div class="input-group-prepend">
                        <span class="input-group-text" title="Code insee">
                            Insee
                        </span>
                    </div>
                    <input type="text" class="form-control input-sm width-60px" v-model="insee">
                </div>
            </div>
            <div>
                <button type="button" title="Annuler les modifications" class="btn btn-secondary btn-sm" :disabled="!isModified" @click="reset()">
                    <i class="fa-solid fa-undo"></i>
                </button>
                <button type="button" class="btn btn-info btn-sm" @click="save()" :disabled="!isModified">
                    <i class="fa-solid fa-check"></i> Appliquer
                </button>
            </div>
        </div>
    </Popover>
</template>

<script setup>
import GeocodageMap from '@/Vue/Components/GeocodageMap.vue';
import IndicateurGeocodage from '@/Vue/Components/IndicateurGeocodage.vue';
import GeocodageInput from '@/Vue/Components/Form/GeocodageInput.vue';
import Popover from "@/Vue/Components/Popover.vue";
import {getCurrentInstance, computed, ref, nextTick, onMounted, toRefs, configureCompat} from "vue";
import Request from "@/App/Request";
import Router from "@/App/Router";
import Pin from "@/Vue/Components/RegulationV2/Map/Base/Pin.vue";

const instance = getCurrentInstance();
const id = 'geocodage_'+instance.uid;

const emit = defineEmits(['changes', 'tempsSurPlace']);

const props = defineProps({
    data: Object,
    small: Boolean,
    placement: {type: String, default: 'bottom-end'},
    organismes: Boolean,
});
const {data: initData} = toRefs(props);

const map = ref();
const title = ref();
const data = ref({...initData.value});
const lngLat = computed({
    get: () => [data.value.longitudeDegre, data.value.latitudeDegre],
    set: (lngLat) => {
        data.value.longitudeDegre = lngLat[0];
        data.value.latitudeDegre = lngLat[1];

        onLngLatInput();
    },
});
const onLngLatInput = (event) => {
    data.value.precision = insee.value ? 4 : 0;
    precisionTemp = 4;

    if (event) {
        centerMap();
    }
}

const insee = computed({
    get: () => data.value.codeInsee,
    set: (insee) => {
        data.value.codeInsee = insee;
        if (insee) {
            data.value.precision = precisionTemp;
        } else {
            data.value.precision = 0;
        }
    },
});

let precisionTemp = data.value.precision;
const popover = ref();

const isModified = computed(() => JSON.stringify(data.value) !== JSON.stringify(initData.value));

const centerMap = () => {
    if (data.value.longitudeDegre && data.value.latitudeDegre) {
        map.value.centerMap([data.value.longitudeDegre, data.value.latitudeDegre]);
    }
}
const reset = () => {
    data.value = {...initData.value};
    centerMap();
};

const reverseGeocodeLoading = ref(false);
const reverseGeocode = () => {
    reverseGeocodeLoading.value = true;

    Request.getJson(Router.generate('geocodage.reverse', {latitude: data.value.latitudeDegre, longitude: data.value.longitudeDegre})).then((res) => {
        reverseGeocodeLoading.value = false;
        if (res.success) {
            data.value.adresse = res.data.adresse;
            data.value.codeInsee = res.data.codeInsee;
            data.value.codePostal = res.data.codePostal;
            data.value.ville = res.data.ville;

            data.value.precision = precisionTemp;
        } else {
            // show warning in btn
        }
    });

};

const onMapClick = (event) => {
    lngLat.value = [event.lngLat.lng, event.lngLat.lat];
}

const onGeocodageInput = (event) => {
    if ('adresse' in event) {
        data.value.adresse = event.adresse;
    }
    if ('codePostal' in event) {
        data.value.codePostal = event.codePostal;
    }
    if ('ville' in event) {
        data.value.ville = event.ville;
    }
    data.value.codeInsee = event.codeInsee;
    data.value.precision = event.precision;
    data.value.latitudeDegre = event.latitudeDegre;
    data.value.longitudeDegre = event.longitudeDegre;

    precisionTemp = data.value.precision;
    if (0 === data.value.precision) {
        tempsSurPlace = null;
    }

    centerMap();
};

let tempsSurPlace = undefined;

const onGeocodageData = (data) => {
    tempsSurPlace = data.tempsSurPlace ?? null;
};

const onShow = () => {
    tempsSurPlace = undefined;

    nextTick(() => {
        reset();
        App.Layout.enableAutosize();
        App.Layout.enableGeocodage();
    });
}

const save = () => {
    emit('changes', data.value);
    if (undefined !== tempsSurPlace) {
        emit('tempsSurPlace', tempsSurPlace);
    }
    popover.value.close();
};

onMounted(() => {
});
</script>