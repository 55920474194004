<template>
    <template v-if="visible">
        <Teleport :to="'#'+contentId">
            <slot></slot>
        </Teleport>
        <Teleport :to="'#'+titleId" v-if="slots.title">
            <slot name="title"></slot>
        </Teleport>
    </template>
</template>

<script setup>
import {ref, onMounted, toRefs, computed, onUnmounted, useSlots, shallowRef} from "vue";
import { onClickOutside } from '@vueuse/core'

defineOptions({
    inheritAttrs: false,
});

const slots = useSlots();

const props = defineProps({
    target: String,
    placement: String,
    class: String,
    offset: Number,
    trigger: {type: String, default: 'click'},
    show: Boolean,
});
const emit = defineEmits(['show', 'hide']);
const {target, placement, class: cssClass, offset, trigger, show} = toRefs(props);

const titleId = computed(() => target.value+'Title');
const contentId = computed(() => target.value+'Content');

const visible = ref(false);
const tip = shallowRef();
let $el = null;

const close = () => {
    $el.popover('hide');
}

onMounted(() => {
    $el = $('#'+target.value);

    onClickOutside(tip, (e) => {
        const ignoreSelector = '.piece-viewer, .context-menu-root, .swal2-container, .air-datepicker-global-container';

        if (!$(e.target).is(ignoreSelector) && $(e.target).parents(ignoreSelector).length === 0) {
            $el.popover('hide');
        }
    }, {ignore: [$el[0]]});

    $el.data('selector', true);

    $el.popover({
        title: slots.title ? '<div id="'+titleId.value+'"></div>' : '',
        content: '<div id="'+contentId.value+'"></div>',
        placement: placement.value.split('-')[0],
        boundary: 'window',
        popperConfig: {
            placement: placement.value,
            onUpdate: () => {},
        },
        fallbackPlacement: [],
        html: true,
        offset: (offset.value*(placement.value.split('-')[1] === 'start' ? -1 : 1))+'px 0',
        trigger: trigger.value,
    });

    let mouseover = false;

    $el
        .on('show.bs.popover', function () {
            const $tip = $($(this).data('bs.popover').tip);

            $tip.addClass(cssClass.value);

            if (trigger.value === 'hover') {
                $el
                    .on('mouseover', () => {
                        mouseover = true;
                    }).on('mouseleave', () => {
                        mouseover = false;
                        setTimeout(() => {
                            if (!mouseover && !$tip.is(':hover')) {
                                $el.popover('hide');
                            }
                        }, 100);
                    })
                ;
                $tip.on('mouseleave', () => {
                    setTimeout(() => {
                        if (!mouseover && !$tip.is(':hover')) {
                            $el.popover('hide');
                        }
                    }, 100);
                });
            }

            if (visible.value) {
                return false;
            }
            visible.value = true;
            tip.value = $tip[0];
            emit('show');
        })
        .on('hide.bs.popover', function (e) {
            if (trigger.value === 'hover' && mouseover) {
                return false;
            }
        })
        .on('hidden.bs.popover', function (e) {
            visible.value = false;
            emit('hide');
        })
    ;

    if (show.value) {
        $el.popover('show');
    }

});

onUnmounted(() => {
    $el.popover('dispose');
});

defineExpose({close})
</script>