<template>
    <div class="trajet-popover" :style="style">
        <div class="trajet-popover-arrow" :class="arrowClass" :style="arrowStyle"></div>
        <div class="trajet-popover-header">
            <slot name="header"></slot>
        </div>
        <div class="trajet-popover-content" v-if="width !== null">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['target'],
        data() {
            return {
                position: null,
                style: {},
                arrowStyle: {},
                arrowClass: '',
                width: null,
                willClose: false,
            };
        },
        mounted() {
            this.update();

            window.addEventListener('resize', this.update);
            window.addEventListener('sidebar', this.update);
            window.addEventListener('keydown', this.onKeyDown);
            window.addEventListener('mouseup', this.onMouseUp);
            window.addEventListener('mousedown', this.onMouseDown);

            this.observer = new MutationObserver(this.update);
            this.observer.observe(this.$parent.$el, {childList: true, subtree: true});

            this.$map = $('#trajetMap');
        },
        beforeDestroy: function () {
            window.removeEventListener('resize', this.update);
            window.removeEventListener('sidebar', this.update);
            window.removeEventListener('keydown', this.onKeyDown);
            window.removeEventListener('mouseup', this.onMouseUp);
            window.removeEventListener('mousedown', this.onMouseDown);

            this.observer.disconnect();
        },
        watch: {
            target() {
                this.update();
            },
            position(position, oldPosition) {
                let style = {
                    width: this.width + 'px',
                    transform: `translate3d(${position.left}px, ${position.top}px, 0px)`
                };
                let arrowStyle = {top: position.arrow+'px'};

                if(oldPosition) {
                    style.transition = '.15s';
                    arrowStyle.transition ='top .15s';
                }

                const mapHeight = this.$map.height();
                this.style = style;
                this.arrowStyle = arrowStyle;
                this.arrowClass = (mapHeight && position.arrow < mapHeight + 18) ? 'trajet-popover-arrow-map' : '';

                if(this.scrollTimeout) {
                    clearTimeout(this.scrollTimeout);
                }

                this.scrollTimeout = setTimeout(() => {
                    this.$el.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                }, 175);
            }
        },
        methods: {
            onMouseDown(e) {
                const $target = $(e.target);

                if (!$target.is('[data-edit-btn]') && $target.parents('.popover').length === 0 && $target.closest('[data-edit-btn]').length === 0 && $target.closest(this.$el).length === 0 && $target.closest('.air-datepicker-global-container').length === 0 && $target.parent().length) {
                    this.willClose = true;
                }
            },
            onMouseUp() {
                if(this.willClose) {
                    this.close();
                }
            },
            onKeyDown(e) {
                if (e.key === 'Escape') {
                    this.close();
                }
            },
            close() {
                this.$emit('close');
            },
            update() {
                this.$nextTick(() => {
                    const baseOffset = $(this.$el).parent().offset();

                    const $element = $('#'+this.target);
                    const $table = $element.closest('table');
                    const tableHeight = $table.height();

                    const min = $table.offset().top;
                    const max = min + tableHeight - 5;

                    const $button = $element.find('[data-edit-btn]');
                    const buttonPosition = $button.offset();

                    let topDelta = tableHeight >= this.$el.offsetHeight ? buttonPosition.top - max - this.$el.offsetHeight : buttonPosition.top - min - 10;
                    topDelta = Math.max(topDelta, 80);

                    let offsetTop = buttonPosition.top - topDelta;
                    if (offsetTop + this.$el.offsetHeight > document.body.clientHeight) {
                        let correction = (offsetTop + this.$el.offsetHeight) - document.body.clientHeight + 70;
                        offsetTop -= correction;
                        topDelta += correction;
                    }

                    let offsetLeft = buttonPosition.left - baseOffset.left;

                    let marginLeft = 8;
                    this.width = offsetLeft - marginLeft - $button.width() - 5;
                    this.position = {
                        left: 15 + marginLeft,
                        top: Math.round(offsetTop - baseOffset.top),
                        arrow: topDelta,
                    };
                });
            }
        }
    }
</script>
