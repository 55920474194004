<template>
    <div class="flex min-h-0" :class="bottomTabs ? 'flex-col-reverse ': 'flex-col'">
        <div class="flex" :class="{'-mt-2.5': showWindow && bottomTabs, '-mb-2.5': showWindow && !bottomTabs}">
            <TabMenu :class="{'ml-3': i > 0, 'flex-1': i === 0 && full, 'invisible': !group.length}" v-for="(group, i) in tabs">
                <TabItem v-for="(tab, j) in group" :class="tab.class" :key="tab.key" :bottom="bottomTabs" :standalone="!showWindow" :loading="tab.loading" :badge="tab.badge" :left="i === 0 && j === 0" :right="full && i === (tabs.length - 1) && j === (group.length - 1)" :active="tab.key === activeTab" @click="emit('update:activeTab', tab.key === activeTab ? null : tab.key)">
                    <i v-if="tab.icon" :class="tab.icon"></i>
                    <span v-if="tab.label && (!tab.labelActive || tab.key === activeTab)" :class="{'ml-1': tab.icon, 'hidden md:inline': i > 0 && tab.icon}">
                        {{ tab.label }}
                    </span>
                </TabItem>
            </TabMenu>
            <slot name="afterTabs"></slot>
        </div>
        <div class="z-10 relative flex flex-col bg-white/50 dark:bg-black/40 backdrop-blur-lg rounded-lg overflow-hidden shadow-md flex-1" v-if="showWindow">
            <div class="py-2 px-3 text-slate-700 dark:text-white/80" v-if="slots.header">
                <slot name="header"></slot>
            </div>
            <div class="bg-white/80 dark:bg-black/50 rounded-t-lg flex-1 p-2 overflow-y-auto flex flex-col">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script setup>
import TabMenu from '@/Vue/Components/RegulationV2/Layout/Tab/Menu';
import TabItem from '@/Vue/Components/RegulationV2/Layout/Tab/Item';
import {computed, useSlots, toRefs} from "vue";

const slots = useSlots()
const emit = defineEmits(['update:activeTab'])

const props = defineProps({
    activeTab: String,
    tabs: Array,
    full: Boolean,
    bottomTabs: Boolean,
});

const {activeTab, tabs, full, bottomTabs} = toRefs(props);

const activeTabData = computed(() => activeTab.value ? tabs.value.flat().filter(tab => tab.key === activeTab.value)[0] : null);

const showWindow = computed(() => !tabs.value || (activeTabData.value && !activeTabData.value.noWindow));
</script>
