<template>
    <Button icon="fa-solid fa-route" title="Itinéraire" severity="secondary" size="small" text @click="(event) => itineraireMenu.toggle(event)"/>
    <TieredMenu ref="itineraireMenu" :model="itineraireMenuItems" popup></TieredMenu>
</template>

<script setup>
import Button from "primevue/button";
import TieredMenu from "primevue/tieredmenu";
import {ref, toRefs, computed} from "vue";

const props = defineProps({
    type: String,
});
const {type} = toRefs(props);

const itineraireMenu = ref();

const name = computed(() => ({vehicule: 'véhicule'}[type.value] ?? 'lieu'));

const itineraireMenuItems = computed(() => [
    {label: 'Itinéraire', disabled: true},
    {separator: true},
    {label: 'Depuis ce '+name.value, icon: 'fa-solid fa-plane-departure'},
    {label: 'Vers ce '+name.value, icon: 'fa-solid fa-plane-arrival'},
]);

</script>