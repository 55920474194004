<template>
    <template v-if="ressource.position">
        <div :title="title" :class="{'text-green-500': color}" v-if="diffSec < palierOk">
            <i class="fa-solid fa-fw fa-signal-stream"></i> {{ precise ? 'Ok' : timer }}
        </div>
        <div :title="title" :class="{'text-yellow-500': color}" v-else-if="diffSec < palierDanger">
            <i class="fa-solid fa-fw fa-signal-stream"></i> {{ timer }}
        </div>
        <div :title="title" :class="{'text-red-500': color}" v-else>
            <i class="fa-solid fa-fw fa-signal-stream-slash"></i> {{ timer }}
        </div>
    </template>
    <div v-else :class="{'text-slate-500': color}">
        <i class="fa-solid fa-fw fa-signal-stream-slash"></i> N/A
    </div>
</template>

<script setup>
import {ref, toRefs, computed, onMounted, onUnmounted} from 'vue';
import moment from "moment";

const props = defineProps({
    ressource: Object,
    color: {type: Boolean, default: true},
    precise: {type: Boolean, default: false},
});

const {ressource} = toRefs(props);
const palierOk = 60 * 3;
const palierDanger = 60 * 60;
const diffSec = ref(0);

const timer = ref('');
const horodatage = computed(() => ressource.value.position ? moment(ressource.value.position.horodatage, 'YYYY-MM-DD HH:mm:ss') : null);
const title = computed(() => horodatage ? horodatage.value.format('DD/MM/YYYY HH:mm:ss') : null);

const updateTimer = () => {
    if (ressource.value.position) {
        const diff = moment().diff(horodatage.value);
        diffSec.value = diff / 1000;
        timer.value = moment.duration(diff).humanize();
    }
}

let interval = null;

onMounted(() => {
    updateTimer();
    interval = setInterval(updateTimer, 1000);
});

onUnmounted(() => {
    if (interval) {
        clearInterval(interval);
        interval = null;
    }
});

</script>
