<template>
    <MapPin variant="radar" shape="square" clickable v-model:popup="popup">
        <template #content>
            <i :class="icon" class="text-lg"></i>
        </template>
        <MapPopup>
            <Popup @close="popup = false">
                <template #headerLeft>
                    <span class="px-1 rounded-md mr-1 text-white bg-gray-900 text-yellow-200">Radar</span>
                    {{ data.typeLabel }}
                </template>
                <div class="p-2 flex flex-col gap-2">
                    <PopupGroup :loading="info === null">
                        <PopupItem label="Département">
                            {{ info.departement }}
                        </PopupItem>
                        <PopupItem label="Route">
                            {{ info.route }}
                        </PopupItem>
                        <PopupItem label="Direction">
                            {{ info.direction }}
                        </PopupItem>
                    </PopupGroup>
                    <PopupGroup :loading="info === null">
                        <PopupItem label="Mise en service">
                            {{ info.dateInstallation }}
                        </PopupItem>
                        <PopupItem label="Description">
                            {{ info.description }}
                        </PopupItem>
                    </PopupGroup>
                    <PopupGroup v-if="info">
                        <PopupItem :label="index === 0 ? (info.regles.length > 1 ? 'Infractions contrôlées' : 'Infraction contrôlée') : ''" v-for="(regle, index) in info.regles">
                            {{ regle.libelle }}
                        </PopupItem>
                    </PopupGroup>
                </div>
            </Popup>
            <Path v-if="path" id="radarTrace" color="#1f2937" autoFit :fitOptions="{maxZoom: 20, padding: 75}" :features="path"></Path>
        </MapPopup>
    </MapPin>
</template>

<script setup>
import MapPin from "@/Vue/Components/RegulationV2/Map/Base/Pin.vue";
import MapPopup from "@/Vue/Components/RegulationV2/Map/Base/Popup.vue";
import Popup from "@/Vue/Components/RegulationV2/Layout/Popup.vue";
import {computed, ref, toRefs, watch} from "vue";
import Path from "@/Vue/Components/RegulationV2/Map/Base/Path.vue";
import Skeleton from 'primevue/skeleton';
import Pin from "@/Vue/Components/RegulationV2/Map/Ressource/Pin.vue";
import Request from "@/App/Request";
import Router from "@/App/Router";
import PopupGroup from "@/Vue/Components/RegulationV2/Layout/Popup/Group.vue";
import PopupItem from "@/Vue/Components/RegulationV2/Layout/Popup/Item.vue";
import PopupLocation from "@/Vue/Components/RegulationV2/Layout/Popup/Location.vue";

const props = defineProps({
    data: Object,
});

const {data} = toRefs(props);

const popup = ref(false);

const icon = computed(() => ({
    feux: 'fa-regular fa-traffic-light-stop',
    discriminants: 'fa-regular fa-gauge-max',
    fixes: 'fa-regular fa-gauge-max',
    troncons: 'fa-regular fa-route',
    itineraire: 'fa-regular fa-diamond-exclamation',
    niveaux: 'fa-regular fa-train-track',
}[data.value.type]));

const path = computed(() => data.value.geoJson ? [{
    type: 'Feature',
    properties: {},
    geometry: {
        type: 'LineString',
        coordinates: JSON.parse(data.value.geoJson).map(coords => [coords[1], coords[0]]),
    }
}] : null);

const info = ref(null);

watch(popup, () => {
    if (popup.value && null === info.value) {
        Request.getJson(Router.generate('map.radars.info', {id: data.value.id})).then((res) => {
            info.value = res;
        });
    }
})

</script>