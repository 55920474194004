<template>
    <div class="row">
        <div class="col">
            <div class="box-lot-facture-nombre">
                {{ pretEnvoiCount }}<small v-if="pretEnvoiCount !== lot.standardCollection.length"> / {{ lot.standardCollection.length }}</small>
            </div>
            <div class="box-lot-facture-libelle text-muted">{{ Translator.trans(lot.standardCollection.length === 1 ? 'libelle.facture':'libelle.factures') }}</div>
        </div>
        <div class="col">
            <div class="box-lot-facture-nombre">{{ totalPieceCount }}</div>
            <div class="box-lot-facture-libelle text-muted">Pièces</div>
        </div>
    </div>
</template>

<script setup>
import Translator from "@/App/Translator";
import {toRefs, computed} from "vue";

const props = defineProps({
    lot: Object,
});

const {lot} = toRefs(props);

const pretEnvoiCount = computed(() => lot.value.standardCollection.filter(facture => facture.pretEnvoiScor && facture.etatScor !== -1).length);
const totalPieceCount = computed(() => lot.value.standardCollection.reduce((res, item) => (res += item.pieceScorPanierCollection.length, res), 0));
</script>
