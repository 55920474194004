<template>
    <div class="sefi-popup">
        <div class="d-flex justify-content-between border-bottom mb-4">
            <h2 class="mt-0 d-flex align-items-center">
                <slot name="title"></slot>
            </h2>
            <div>
                <slot name="header"></slot>
            </div>
        </div>
        <div>
            <slot name="content"></slot>
        </div>
        <div class="text-center mt-5">
            <slot name="footer"></slot>
            <button class="btn btn-secondary js-close-swal" type="button">Fermer</button>
        </div>
    </div>
</template>
