<template>
    <div class="position-sticky concurrency" style="z-index: 10" v-if="hasAlert">
        <div class="alert mb-3 p-0 d-flex" :class="saved ? 'alert-danger' : 'alert-warning'">
            <div class="p-4 flex-1">
                <template v-if="saved">
                    <i class="fa-solid fa-triangle-exclamation"></i> <strong>Ce formulaire a été modifié par {{ listFormatter.format(savedUsers) || 'un autre utilisateur' }}.</strong> {{ savedUsers.length > 1 ? 'Leurs' : 'Ses '}} modifications seront écrasées par les vôtres si vous sauvegardez.
                </template>
                <template v-else>
                    <i class="fa-solid fa-triangle-exclamation"></i>
                    <span v-if="selfCount > 1"><strong>Vous</strong> êtes actuellement <strong>{{ selfCount }} fois</strong> sur cette page.</span>
                    <span v-if="otherUsers.length > 0">
                    <template v-if="otherUsers.length > 1">
                        <strong v-if="otherUsers.length > 3">
                            {{ otherUsers.length + ' autres utilisateurs' }}
                        </strong>
                        <strong v-else>
                            {{ listFormatter.format(otherUsers) }}
                        </strong>
                    </template>
                    <template v-else-if="otherUsers.length === 1">
                        <strong>{{ otherUsers[0] }}</strong> {{ selfCount > 1 ? 'est également' : 'est actuellement'}}
                    </template>
                    sur cette page.
                </span>
                    Vos modifications pourraient être écrasées.
                </template>
            </div>
            <div class="align-items-center d-flex px-3" v-if="otherUsers.length">
                <b-btn title="Ouvrir la discussion" :variant="chatNotification ? 'info' : 'light'" id="chatBtn" class="my-n3 js-vue-popover-btn" style="min-width: 65px;">
                    <i ref="chatBtnIcon" class="fa-solid fa-comments" :class="{'fa-beat': chatNotification}"></i> <span class="badge badge-secondary ml-1 mr-n2 px-2 line-height-1" :style="chatNotification ? 'background: rgba(255, 255, 255, .25);color: white;' : ''" v-if="messages.length">{{messages.length}}</span>
                </b-btn>
                <Popover @show="chatPopoverOpen = true" @hide="chatPopoverOpen = false" target="chatBtn" placement="left-start" class="concurrency-chat-popover">
                    <template v-slot:title>
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                Discussion
                            </div>
                            <div>
                                <span class="badge badge-secondary ml-2" style="border-radius: 100%;" v-for="user in otherUsers" :title="user">
                                    {{ user.split(' ').map(mot => mot.substring(0, 1)).join('') }}
                                </span>
                            </div>
                        </div>
                    </template>
                    <concurrency-chat :messages="messages" @send="sendMessage"></concurrency-chat>
                </Popover>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, onMounted, ref, toRefs, watch} from "vue";
import ConcurrencyChat from "@/Vue/Components/ConcurrencyChat.vue";
import Popover from '@/Vue/Components/Popover';

const props = defineProps({
    hash: String,
});

const messages = ref([]);
const {hash} = toRefs(props);
const sendMessage = (message) => {
    App.webSocket.publish('concurrency/' + hash.value, {
        message
    });
}

const chatBtnIcon = ref();
const chatNotification = ref(false);
const chatPopoverOpen = ref(false);
const unloaded = ref(false);
const saved = ref(false);
const savedUsers = ref([]);

const selfCount = ref(0);
const otherUsers = ref([]);

const listFormatter = new Intl.ListFormat('fr');

const hasAlert = computed(() => {
   return saved.value || selfCount.value > 1 || otherUsers.value.length > 0;
});

window.addEventListener('beforeunload', () => {
    unloaded.value = true;
});

watch(chatPopoverOpen, () => chatNotification.value = false);

onMounted(() => {
    App.webSocket.subscribe('concurrency/'+hash.value, (uri, data) => {
        if (unloaded.value) {
            return;
        }

        if (data.chat) {
            messages.value.push(data.chat);
            if (data.chat.user.id !== App.Constants.USER_ID && !chatPopoverOpen.value) {
                chatNotification.value = true;
            }
        }

        if (data.saved) {
            saved.value = true;

            if (data.saved.user && !savedUsers.value.includes(data.saved.user)) {
                savedUsers.value.push(data.saved.user);
            }
        }

        if (data.update && !saved.value) {
            selfCount.value = data.update.selfCount;
            otherUsers.value = data.update.otherUsers;
        }
    });
});
</script>
