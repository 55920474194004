<template>
    <ContextMenu ref="contextMenu" :model="contextMenuItems" @hide="contextMenuTrajet = null" />

    <DataTable :value="trajetCollection"
               scrollable scrollHeight="flex" :virtualScrollerOptions="virtualScroll ? { itemSize: 36 } : null"
               contextMenu v-model:contextMenuSelection="contextMenuTrajet" @rowContextmenu="showContextMenu($event.originalEvent)"
               @row-click="onClick"
               selectionMode="single"
               dataKey="id"
               :selection="currentTrajet"
               stripedRows
               showGridlines
               highlightOnSelect
               size="small"
               filterDisplay="menu"
               v-model:filters="filters"
               sortField="rendezVous" :sortOrder="1"
               :rowGroupMode="groupBy ? 'subheader' : null" :groupRowsBy="groupBy"
               :pt="{column: {sortIcon: '!ml-0', columnHeaderContent: '!gap-1', pcColumnFilterButton: '!p-0 !size-8'}, wrapper: 'overscroll-none', rowGroupHeaderCell: 'py-1 px-3 bg-surface-200 leading-tight'}"
               :loading="isLoading"
               class="text-sm md:text-base"
    >
        <template #empty>Aucun trajet</template>
        <template #groupheader="{data}">
            <template v-if="groupMode === 'rendezVous'">
                {{ data.rendezVous.time.split(':')[0] }}:00
            </template>
            <template v-else-if="groupMode === 'vehicule'">
                <template v-if="data._vehicule">
                    {{ data._vehicule.shortString }}
                </template>
                <template v-else>
                    Non affectés
                </template>
            </template>
        </template>

        <Column field="etat" header="E" headerClass="!py-0 w-px" bodyClass="!py-1 md:!py-[0.375rem] text-center" sortable>
            <template #body="{data}">
                <trajet-etat :trajet="data"></trajet-etat>
            </template>
        </Column>
        <Column field="type" header="Type" headerClass="!py-0 w-px" bodyClass="!py-1 md:!py-[0.375rem] text-nowrap">
            <template #body="{data}">
                <trajet-type :trajet="data" class="-ml-1"></trajet-type>
            </template>
        </Column>
        <Column field="motif" header="Motif" headerClass="!py-0 w-px" bodyClass="!py-1 md:!py-[0.375rem] text-nowrap" sortable>
            <template #body="{data}">
                <motif :motif="data.transport.motif"></motif>
            </template>
        </Column>
        <Column field="rendezVous" header="RDV" headerClass="!py-0 w-[55px]" bodyClass="!py-1 md:!py-[0.375rem] text-center tabular-nums" sortable>
            <template #body="{data}">
                    <span :class="{'line-through': data.estAnnule}">
                        <span class="font-semibold">{{ data.rendezVous.time.split(':')[0] }}</span><span class="mx-[1px]">:</span><span>{{ data.rendezVous.time.split(':')[1] }}</span>
                    </span>
            </template>
        </Column>
        <Column field="patient" header="Patient" headerClass="!py-0" bodyClass="!py-1 md:!py-[0.375rem] truncate max-w-0">
            <template #body="{data}">
                <trajet-patient :trajet="data"></trajet-patient>
            </template>
            <template #filter="{ filterModel }">
                <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Search by name" />
            </template>
        </Column>
        <Column field="kilometre" header="Km" bodyClass="!py-1 md:!py-[0.375rem] text-center text-slate-400" headerClass="!py-0 w-px" sortable></Column>
        <Column :field="field" :header="label" bodyClass="!py-1 max-w-0 md:!py-1" headerClass="!py-0 text-nowrap" v-for="(label, field) in {PriseEnCharge: 'Prise en charge', Destination: 'Destination'}">
            <template #body="{data}">
                <div class="flex items-center leading-none">
                    <indicateur-geocodage :tailwind="true" :small="true" :value="data['precision'+field]"></indicateur-geocodage>
                    <div class="flex-1 truncate ml-2">
                        <div class="truncate" :title="[data['codePostal'+field], data['ville'+field]].join(' ')">
                            {{ data['ville'+field] }}
                        </div>
                        <div class="truncate text-xs md:text-sm !leading-none text-slate-400" :title="[data['adresse'+field], data['complementAdresse'+field]].join('\n')">
                            {{ data['adresse'+field] }} {{ data['complementAdresse'+field] }}
                        </div>
                    </div>
                </div>
            </template>
        </Column>
        <Column field="vehicule" header="VEH" bodyClass="!py-1 md:!py-[0.375rem] h-0" headerClass="!py-0 w-px text-nowrap" sortable>
            <template #body="{data}">
                <Ressource :trajet="data" field="vehicule"></Ressource>
            </template>
        </Column>
        <Column field="personnel1" header="P1" bodyClass="!py-1 md:!py-[0.375rem] h-0" headerClass="!py-0 w-px text-nowrap" sortable>
            <template #body="{data}">
                <Ressource :trajet="data" field="personnel1"></Ressource>
            </template>
        </Column>
        <Column field="personnel2" header="P2" bodyClass="!py-1 md:!py-[0.375rem] h-0" headerClass="!py-0 w-px text-nowrap" sortable>
            <template #body="{data}">
                <Ressource :trajet="data" field="personnel2"></Ressource>
            </template>
        </Column>
        <Column field="topDebut" header="DEB" bodyClass="!py-1 md:!py-[0.375rem] !px-0" headerClass="!py-0 w-px text-nowrap" sortable>
            <template #body="{data}">
                <Top :trajet="data" top="debut"></Top>
            </template>
        </Column>
        <Column field="topPriseEnCharge" header="PEC" bodyClass="!py-1 md:!py-[0.375rem] !px-0" headerClass="!py-0 w-px text-nowrap" sortable>
            <template #body="{data}">
                <Top :trajet="data" top="priseEnCharge"></Top>
            </template>
        </Column>
        <Column field="topDestination" header="DEST" bodyClass="!py-1 md:!py-[0.375rem] !px-0" headerClass="!py-0 w-px text-nowrap" sortable>
            <template #body="{data}">
                <Top :trajet="data" top="destination"></Top>
            </template>
        </Column>
        <Column field="topFin" header="FIN" bodyClass="!py-1 md:!py-[0.375rem] !px-0" headerClass="!py-0 w-px text-nowrap" sortable>
            <template #body="{data}">
                <Top :trajet="data" top="fin"></Top>
            </template>
        </Column>
        <Column header="Actions" bodyClass="!py-1 md:!py-[0.375rem]" headerClass="!py-0 w-px">
            <template #body="{data}">
                <div class="flex gap-x-1">
                    <Button size="small" outlined icon="fa-solid fa-pencil" class="!py-1 !w-8" title="Modifier"></Button>
                    <Button size="small" severity="secondary" icon="fa-solid fa-ellipsis-vertical" class="!py-1 !w-6" title="Autres actions" @click="showContextMenu($event)"></Button>
                </div>
            </template>
        </Column>
        <Column field="" header="" style="display:none"></Column>
    </DataTable>
</template>

<script setup>
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import Ressource from "@/Vue/Components/RegulationV2/Panel/Trajets/Ressource.vue";
import Top from "@/Vue/Components/RegulationV2/Panel/Trajets/Top.vue";
import DataTable from "primevue/datatable";
import {storeToRefs} from "pinia";
import {useRegulationStore} from "@/Vue/Stores/Regulation";
import {computed, ref, toRefs} from "vue";
import ContextMenu from "primevue/contextmenu";
import Button from "primevue/button";
import Translator from "@/App/Translator";
import {FilterMatchMode} from '@primevue/core/api';

const props = defineProps({
    groupBy: null,
});
const {groupBy} = toRefs(props);

const regulationStore = useRegulationStore();

const {currentTrajet, trajetCollection, isLoading} = storeToRefs(regulationStore);
const {selectTrajetById} = regulationStore;

const contextMenu = ref();
const contextMenuTrajet = ref(null);

const contextMenuItems = computed(() => {
    if (!contextMenuTrajet.value) {
        return;
    }

    const items = [];

    // Voir / Modifier
    // if(contextMenuTrajet.value.roles.ROLE_REGULATION_TRANSPORT_VIEW) {
    //     items.push({label: Translator.trans('action.voir'), icon: 'fa-solid fa-eye'});
    // }
    if(contextMenuTrajet.value.roles.ROLE_REGULATION_TRANSPORT_EDIT) {
        items.push({label: Translator.trans('action.modifier'), icon: 'fa-solid fa-pencil'});
    }

    // todo

    return items;
});
// context menu

// voir / modifier
// tops horaires -> réinitialiser, précédent, suivant, terminer
// créer simultane / créer série / dupliquer

const filters = ref({
    patient: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const virtualScroll = computed(() => true); // virtualScroll buggé avec rowgroupby

const onClick = (e) => {
    selectTrajetById(e.data.id);
    console.log(e.data.id);
};

const showContextMenu = (event) => {
    contextMenu.value.show(event);
};
</script>