<template>
    <api-popup>
        <template #title>
            <span class="badge badge-dark mr-3">Immatriculations</span>
            Recherche
        </template>
        <template #content>
            <template v-if="loading">
                <div class="mt-5 d-flex align-items-center justify-content-center">
                    <b-spinner class="mr-3"></b-spinner> Chargement…
                </div>
            </template>
            <template v-else-if="result">
                <sefi-result-table ref="table" @canReporter="canReporter = $event">
                    <tr>
                        <th colspan="3" class="bg-light py-1 text-center">{{ trans('chapitre.informations') }}</th>
                    </tr>
                    <sefi-result-row :label="Translator.trans('libelle.vin')" :value="result.vin" target="#vehicule_vin"></sefi-result-row>
                    <sefi-result-row :label="Translator.trans('libelle.premiereMiseEnCirculation')" :value="result.premiereMiseEnCirculation" target="#vehicule_premiereMiseEnCirculation"></sefi-result-row>
                    <sefi-result-row :label="Translator.trans('libelle.puissanceFiscale')" :value="{id: result.puissanceFiscale, text: result.puissanceFiscale + ' CV'}" target="#vehicule_puissanceFiscale"></sefi-result-row>
                    <sefi-result-row :label="Translator.trans('libelle.marque')" :value="result.marque" target="#vehicule_marque"></sefi-result-row>
                    <sefi-result-row :label="Translator.trans('libelle.modele')" :value="result.modele" target="#vehicule_modele"></sefi-result-row>
                    <sefi-result-row :label="Translator.trans('libelle.carburant')" :value="result.carburant" target="#vehicule_carburantCollection"></sefi-result-row>
                </sefi-result-table>
            </template>
            <template v-else-if="error">
                <div class="alert alert-danger">
                    <strong>Erreur :</strong> {{ error }}
                </div>
            </template>
        </template>
        <template #footer>
            <sefi-result-reporter v-if="result" :enabled="canReporter" @click="reporter"></sefi-result-reporter>
        </template>
    </api-popup>
</template>

<script setup>
import {ref, toRefs} from "vue";
import Router from "@/App/Router";
import Request from "@/App/Request";
import Translator from "@/App/Translator";


const props = defineProps({
    immatriculation: String,
    structureId: String,
});

const canReporter = ref(false);

const table = ref();
const loading = ref(true);
const result = ref(null);
const error = ref(null);
const {immatriculation, structureId} = toRefs(props);

const reporter = () => { table.value.reporter(); }

Request.postJson(Router.generate('referentiel.vehicule.immatriculation'), {
    immatriculation_api: {
        immatriculation: immatriculation.value,
        structure: structureId.value,
    },
}).then((data) => {
    if(data.success) {
        result.value = data.data;
    } else {
        error.value = data.error;
    }

    loading.value = false;
});
</script>
