import moment from 'moment';

App.Geoloc = class {
    constructor(bureaux) {
        this.bureaux = bureaux;

        this.timeout = null;
        this.modalUrl = null;

        this.points = [];
        this.itineraires = [];

        this._initWebSocket();
    }

    _initWebSocket() {
        if (GEOLOC_CONST.droits['ROLE_GEOLOCALISATION_VEHICULE']) {
            App.webSocket.subscribe('geoloc/vehicules', (uri, data) => {
                $('.js-geoloc-vehicules').html(data);
                App.Layout.enableShuffle();
            });

            App.webSocket.subscribe('geoloc/vehicules/geojson', (uri, data) => {
                try {
                    let json = JSON.parse(data);
                    this.xmap.clearLayer('vehicule');
                    this.xmap.layers['vehicule'].addData(json);
                    for(let i in json.features){
                        if(json.features[i].properties.popup) {
                            this.getPoint(json.features[i].properties.id).getMarker().getPopup().openOn(this.xmap.map);
                        }
                    }
                } catch (e) {}
            });

            App.webSocket.publish('geoloc/vehicules', { bureaux: this.bureaux });
            App.webSocket.publish('geoloc/vehicules/geojson', { bureaux: this.bureaux });
        }
    }

    enable() {
        App.Layout.closeRightSide();

        try {
            this.xmap = new App.Xmap(this);
            this.xlocate = new App.Xlocate(this);
            this.xroute = new App.Xroute(this);
        } catch(e) {
            this.modeErreur();
            return false;
        }

        this.xmap.enable();
        this.xlocate.enable();
        this.xroute.enable();

        $('body')
            .on('change', '.js-geoloc-bureaux', () => {
                $('.js-geoloc-vehicules').html('<div class="spinner bg-theme"></div>');
                let bureaux = $('.js-geoloc-bureaux').val();

                App.webSocket.publish('geoloc/vehicules', { bureaux: bureaux, sendData: true });
                App.webSocket.publish('geoloc/vehicules/geojson', { bureaux: bureaux, sendData: true });

                this.xmap.fetchBureauxGeojson();
                this.xmap.fetchVehiculesGeojson();
            })
            .on('click', '.js-geoloc-bureau-toggle', (e) => {
                let $el = $(e.currentTarget);
                let $block = $el.closest('.js-geoloc-bureau-block');

                if($block.find('.js-geoloc-bureau-vehicules').is(':visible')) {
                    App.webSocket.publish('geoloc/vehicules', { hideBureau: $block.find('.js-geoloc-bureau').data('point') });
                } else {
                    App.webSocket.publish('geoloc/vehicules', { showBureau: $block.find('.js-geoloc-bureau').data('point') });
                }

                let title = $el.data('title');
                $el
                    .data('title', $el.prop('title'))
                    .prop('title', title);

                $block.find('.js-geoloc-bureau-vehicules').slideToggle('fast');
            })
            .on('click', '.js-geoloc-bureau, .js-geoloc-vehicule', (e) => {
                let point = this.getPoint($(e.currentTarget).data('point'));
                if(point) {
                    this.xmap.centerPoint(point);
                }
            })
            .on('click', '.js-geoloc-vehicule', (e) => {
                let $el = $(e.currentTarget);
                let point = this.getPoint($el.data('point'));
                let $detail = $el.parent().find('.js-geoloc-vehicule-detail');

                if(point) {
                    this.xmap.centerPoint(point);
                }

                if(!$detail.is(':visible')) {
                    $('.js-geoloc-vehicule-detail').hide();
                    $detail.show();
                    App.Layout.updateShuffle();

                    App.webSocket.publish('geoloc/vehicules', { showVehicule: $el.data('point') });
                } else {
                    $('.js-geoloc-vehicule-detail').hide();
                }
                $detail.closest('.js-shuffle').data('shuffle').update();
            })
            .on('click', '.js-geoloc-vehicule-trajets', (e) => {
                let $el = $(e.currentTarget);
                let id_vehicule = $el.data('id');
                let vehicule = $el.data('vehicule');

                this.xmap.clearLayer('itineraire');
                this.xmap.clearLayer('position');
                positionsBounds = [];

                let now = moment();
                $('.js-geoloc-modal-date').val(now.format('DD/MM/YYYY'));
                this.fetchInModal(GEOLOC_CONST.urls.urlTrajets + '?id_vehicule=' + id_vehicule, vehicule + ' - ' + GEOLOC_CONST.libelles.trajetCollection);
            })
            .on('click', '.js-geoloc-vehicule-positions', (e) => {
                let $el = $(e.currentTarget);
                let id_vehicule = $el.data('id');
                let vehicule = $el.data('vehicule');

                this.xmap.clearLayer('itineraire');
                this.xmap.clearLayer('position');
                positionsBounds = [];

                let now = moment();
                $('.js-geoloc-modal-date').val(now.format('DD/MM/YYYY'));
                this.fetchInModal(GEOLOC_CONST.urls.urlPositions + '?id_vehicule=' + id_vehicule, vehicule + ' - ' + GEOLOC_CONST.libelles.positionCollection);
            })
            .on('click', '.js-geoloc-position', (e) => {
                let $el = $(e.currentTarget),
                    point = this.createPoint({
                        'id': $el.data('lat')+'-'+$el.data('lng'),
                        'lat': $el.data('lat'),
                        'lng': $el.data('lng'),
                        'type': 'recherche'
                    });

                this.xmap.clearLayer('recherche');
                this.xmap.showPoint('recherche', point);
                this.xmap.centerPoint(point);
                this.reduireModal();
            })
            .on('click', '.js-geoloc-trajets-positions', (e) => {
                let $el = $(e.currentTarget);
                let positions = $el.data('positions');
                let date = $el.data('date') ? moment($el.data('date'), 'DD/MM/YYYY HH:mm') : null;
                let bounds = [], trace = [];

                if(positions) {
                    $.ajax({
                        url: GEOLOC_CONST.urls.urlPositionsJson,
                        method: 'GET',
                        data: {positions: positions},
                        cache: false
                    }).done((data) => {
                        this.reduireModal();
                        this.xmap.clearLayer('itineraire');
                        this.xmap.clearLayer('position');

                        for(let position of data) {
                            let point = this.createPoint({
                                'lat': position.latitudeDegre,
                                'lng': position.longitudeDegre,
                                'type': 'position'
                            });
                            bounds.push([point.lat, point.lng]);
                            trace.push({y: point.lat, x: point.lng});

                            this.xmap.showPoint('position', point);
                        }

                        let polyline = new L.Polyline(trace, {
                            color: '#00b3fd',
                            weight: 10,
                            opacity: 0.5,
                            smoothFactor: 1
                        });
                        this.xmap.layers['itineraire'].addLayer(polyline);

                        let arrowHead = L.polylineDecorator(polyline,{
                            patterns: [
                                {offset: 25, repeat: 50, symbol: L.Symbol.arrowHead({pixelSize: 7, polygon: false, pathOptions: {stroke: true, opacity: 0.6, color: 'white', weight: 2}})}
                            ]
                        });
                        this.xmap.layers['itineraire'].addLayer(arrowHead);

                        this.xmap.setReferenceTime(date.format());

                        this.xmap.map.fitBounds(bounds);
                    });
                }
            })
            .on('click', '.js-geoloc-reset-infotrafic', () => {
                this.xmap.resetReferenceTime();
            })
            .on('leftPanel.open', App.Layout.updateShuffle)
        ;

        $('a[data-toggle="tab"]').on('shown.bs.tab', () => {
            App.Layout.updateShuffle();
        });

        $('.js-geoloc-modal').
            on('click', '.js-geoloc-modal-display', () => {
                if($('.js-geoloc-modal .panel-wrapper').is(':visible')) {
                    this.reduireModal();
                } else {
                    this.agrandirModal();
                }
            })
            .on('click', '.js-geoloc-modal-close', () => {
                $('.js-geoloc-modal').hide();
            })
            .on('click', '.js-geoloc-modal-search', () => {
                this.fetchInModal();
            })
            .on('change', '.js-geoloc-modal-date, .js-geoloc-modal-debut, .js-geoloc-modal-fin', () => {
                this.fetchInModal();
            })
        ;

        $('.js-geoloc-modal-content').on('click', 'a', (e) => {
            if(!$(e.currentTarget).hasClass('js-geoloc-outlink')) {
                e.preventDefault();
                let url = $(e.currentTarget).prop('href');
                if(url) {
                    this.fetchInModal(url);
                }
            }
        });

        $('#js-geoloc-right-panel').html($('#js-geoloc-right-panel-placeholder').html());
    }

    createItineraire(params) {
        params['assets'] = GEOLOC_CONST.urls.urlAssetDirection;

        let itineraire = new App.Geoloc.Itineraire(params, GEOLOC_CONST.libelles, GEOLOC_CONST.droits);
        this.itineraires[itineraire.id] = itineraire;

        let $element = $('.js-xroute-trajets .js-xroute-trajet').eq(2);
        if($element.length) {
            this.removeItineraire($element.data('itineraire'));
            $element.remove();
        }

        return itineraire;
    }

    getItineraire(id) {
        return this.itineraires[id];
    }

    removeItineraire(id) {
        let itineraire = this.getItineraire(id);
        if(itineraire && itineraire.params === this.xroute.lastRecherche) {
            this.xroute.lastRecherche = null;
        }

        if(this.xmap.currentItineraire && id === this.xmap.currentItineraire.id) {
            this.xmap.clearLayer('itineraire');
        }

        if(this.xroute.currentManeouvres && id === this.xroute.currentManeouvres.id) {
            App.Layout.closeRightSide();
            $('#js-geoloc-right-panel').html($('#js-geoloc-right-panel-placeholder').html());
        }

        delete this.itineraires[id];
    }

    createPoint(params) {
        let point = new App.Geoloc.Point(params, GEOLOC_CONST.libelles);

        if(this.points[point.id]) {
            delete this.points[point.id];
        }

        this.points[point.id] = point;

        return point;
    }

    getPoint(id) {
        return this.points[id];
    }

    attributePoint(element, point) {
        element
            .val(point.getLibelle())
            .data('point', point.id);
        element
            .closest('.input-group')
                .removeClass('border-secondary')
                .removeClass('border-danger')
                .addClass('border-success')
                .find('.input-group-text')
                    .removeClass('text-secondary')
                    .removeClass('text-danger')
                    .addClass('text-success')
                    .find('i')
                        .prop('title', GEOLOC_CONST.libelles['centrer-emplacement'])
                        .prop('class', (point.type === 'bureau' || point.type === 'vehicule' ? point.iconClass : 'im-checkmark4'));
    }

    discardPoint(element) {
        if(element.data('point') !== '') {
            element.data('point', '');
            element
                .closest('.input-group')
                    .removeClass('border-success')
                    .removeClass('border-danger')
                    .addClass('border-secondary')
                    .find('.input-group-text')
                        .removeClass('text-success')
                        .removeClass('text-danger')
                        .addClass('text-secondary')
                        .find('i')
                            .prop('title', '')
                            .prop('class', 'im-search');
        }
    }

    invalidPoint(element, message) {
        element
            .closest('.input-group')
                .removeClass('border-success')
                .removeClass('border-secondary')
                .addClass('border-danger')
                .find('.input-group-text')
                    .removeClass('text-success')
                    .removeClass('text-secondary')
                    .addClass('text-danger')
                    .find('i')
                        .prop('title', (message ? message : GEOLOC_CONST.libelles['choix-invalide']))
                        .prop('class', 'im-warning2');
    }

    getBounds() {
        let bounds = [];

        for(let id in this.points) {
            bounds.push([this.points[id].lat, this.points[id].lng]);
        }

        return bounds;
    }

    modeErreur() {
        $('#map').parent().append('<div class="js-geoloc-error"><i class="im-notification"></i> ' + GEOLOC_CONST.libelles['erreur-carto'] + '</div>');

        $('#map').remove();
        $('.js-geoloc-recherche').remove();

    }

    showModal() {
        $('.js-geoloc-modal').draggable({containment: "parent"}).show();
        $('.js-geoloc-modal-content').html('<div class="spinner bg-theme"></div>');
        this.agrandirModal();
    }

    reduireModal() {
        let $modal = $('.js-geoloc-modal-display');

        $modal
            .prop('title', $modal.data('title-agrandir'))
            .find('i')
            .removeClass('im-minus2')
            .addClass('im-arrow-resize8')
        ;

        $('.js-geoloc-modal .panel-wrapper').hide();
    }

    agrandirModal() {
        let $modal = $('.js-geoloc-modal-display');

        $modal
            .prop('title', $modal.data('title-reduire'))
            .find('i')
            .addClass('im-minus2')
            .removeClass('im-arrow-resize8')
        ;

        $('.js-geoloc-modal .panel-wrapper').show();
    }

    fetchInModal(url, titre) {
        if(titre) {
            $('.js-geoloc-modal-titre').text(titre);
        }

        let $modalDate = $('.js-geoloc-modal-date');

        if($modalDate.val() === '') {
            $modalDate.addClass('is-invalid');
        } else {
            $modalDate.removeClass('is-invalid');
            if(!url) {
                url = this.modalUrl;
            }

            if(url) {
                let data = [];

                if(url.indexOf('http') === -1) {
                    this.modalUrl = url;
                    data = {
                        'date': $modalDate.val(),
                        'debut': $('.js-geoloc-modal-debut').val(),
                        'fin': $('.js-geoloc-modal-fin').val()
                    };
                }

                this.showModal();
                $.ajax({
                    url: url,
                    method: 'GET',
                    data: data,
                    cache: false
                }).done(function(data) {
                    $('.js-geoloc-modal-content').html(data);
                });
            }
        }
    }
};
