import icons from "trumbowyg/dist/ui/icons.svg";

L.Icon.FontAwesome = L.Icon.extend({

	options: {
		popupAnchor: [0, -50]
	},

	createIcon: function () {

		var div = document.createElement('div');
		var options = this.options;

		if(options.iconClasses) {
			div.appendChild(this._createIcon());
		}

		return div;
	},

	_createIcon: function () {

		var options = this.options;

		// container div
		var iconDiv = L.DomUtil.create('div', 'leaflet-fa-markers');

		// marker icon L.DomUtil doesn't seem to like svg, just append out html directly
		var markerSvg = document.createElement('div');
		markerSvg.className = "marker-icon-svg";
		var svg = '<svg ' +
                        'width="32px" ' +
                        'height="52px" ' +
                        'viewBox="0 0 32 52" ' +
			'version="1.1" ' +
			'xmlns="http://www.w3.org/2000/svg" ' +
			'xmlns:xlink="http://www.w3.org/1999/xlink">' +
			'<path d="'+options.markerPath+'" ';
                        if(options.markerColor) {
                            svg += 'fill="white" stroke="'+ options.markerColor + '" stroke-width="2" stroke-linejoin="miter"></path>';
                        } else {
                            svg += 'fill="#00a9ce"></path>';
                        }
                        svg += '</svg>';

                markerSvg.innerHTML = svg;
                
		iconDiv.appendChild(markerSvg);

		if (options.text) {
			var textSpan = L.DomUtil.create('span', 'feature-icon line-height-1 overflow-hidden text-center font-weight-bold text-nowrap');
			textSpan.title = options.text;
			textSpan.innerHTML = options.text;
			textSpan.style.color = options.iconColor;
			textSpan.style.top = '9px';
			textSpan.style.left = '5px';
			textSpan.style.width = '22px';
			textSpan.style.fontSize =  options.text.length > 3 ? '10px' : '11px';

			// XY position adjustments
			if(options.iconYOffset && options.iconYOffset != 0) iconSpan.style.marginTop = options.iconYOffset + 'px';
			if(options.iconXOffset && options.iconXOffset != 0) iconSpan.style.marginLeft = options.iconXOffset + 'px';

			iconDiv.appendChild(textSpan);
		}

		var iconSpan = L.DomUtil.create('span', options.iconClasses + ' feature-icon');
		iconSpan.style.color = options.iconColor;
		iconSpan.style.textAlign = 'center';

		if (options.text) {
			iconSpan.style.top = '25px';
			iconSpan.style.fontSize = '9px';
		}

		// XY position adjustments
		if(options.iconYOffset && options.iconYOffset != 0) iconSpan.style.marginTop = options.iconYOffset + 'px';
		if(options.iconXOffset && options.iconXOffset != 0) iconSpan.style.marginLeft = options.iconXOffset + 'px';

		iconDiv.appendChild(iconSpan);

		return iconDiv;
	}
});

L.icon.fontAwesome = function (options) {
	return new L.Icon.FontAwesome(options);
};

//L.Icon.FontAwesome.prototype.options.markerPath = 'M16,1 C7.7146,1 1,7.65636364 1,15.8648485 C1,24.0760606 16,51 16,51 C16,51 31,24.0760606 31,15.8648485 C31,7.65636364 24.2815,1 16,1 L16,1 Z';
//L.Icon.FontAwesome.prototype.options.markerPath = 'M19 1C10.715 1 4 7.656 4 15.865 4 24.076 19 51 19 51 19 51 35 24.076 35 15.865 35 7.656 27.282 1 19 1L19 1Z';
//L.Icon.FontAwesome.prototype.options.markerPath = 'M19 4C10.715 4 4 10.656 4 18.865 4 27.076 19 54 19 54 19 54 35 28.076 35 18.865 35 10.656 27.282 4 19 4L19 4Z';
//L.Icon.FontAwesome.prototype.options.markerPath = 'M15.5 1C7.215 1 .5 7.656.5 15.865.5 24.076 15.5 51 15.5 51 15.5 51 31.5 25.076 31.5 15.865 31.5 7.656 23.782 1 15.5 1L15.5 1Z';
//L.Icon.FontAwesome.prototype.options.markerPath = 'M19 4C10.715 4 4 10.656 4 18.865 4 27.076 19 54 19 54 19 54 35 28.076 35 18.865 35 10.656 27.282 4 19 4L19 4Z';
//L.Icon.FontAwesome.prototype.options.markerPath = 'M16 3.813C9.268 3.813 3.813 9.221 3.813 15.89 3.813 22.562 16 44.438 16 44.438 16 44.438 28.188 22.562 28.188 15.89 28.188 9.221 22.729 3.813 16 3.813L16 3.813Z';
//L.Icon.FontAwesome.prototype.options.markerPath = 'M16 3.813C9.268 3.813 3.813 9.221 3.813 15.89 3.813 22.562 16 32.438 16 44.438 16 32.438 28.188 22.562 28.188 15.89 28.188 9.221 22.729 3.813 16 3.813L16 3.813Z';
//L.Icon.FontAwesome.prototype.options.markerPath = 'M16 3.813 3.813 15.89 16 44.438 28.188 15.89 16 3.813 16 3.813Z';
//L.Icon.FontAwesome.prototype.options.markerPath = 'M16 4C9 4 4 9 4 16 4 26 16 24 16 44 16 24 28 26 28 16 28 9 23 4 16 4L16 4Z';
//L.Icon.FontAwesome.prototype.options.markerPath = 'M16 4C9 4 4 9 4 16 4 30 16 23 16 44 16 23 28 30 28 16 28 9 23 4 16 4L16 4Z';
L.Icon.FontAwesome.prototype.options.markerPath = 'M25.187 7.113c-5.076-5.076-13.298-5.076-18.373 0-5.076 5.073-5.076 13.304 0 18.374 0 0 9.181 9.003 9.181 19.399 0-10.396 9.192-19.399 9.192-19.399C30.261 20.416 30.261 12.186 25.187 7.113z';