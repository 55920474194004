<template>
    <div class="tableau-de-bord-tab" :class="{active}" @click="$emit('input', name)">
        <div class="text-center d-flex flex-column justify-content-center" style="height: 63px">
            <div>
                <i class="fa-2x" :class="{[icon]: true, [textClass]: active}"></i>
            </div>
            <div class="text-normal font-size-16px mt-n1" :class="{[textClass]: active}">{{ title }}</div>
            <div>
                <span class="font-size-12px badge badge-pill" :class="'badge-'+type" v-for="(count,type) in counters">
                    {{ count }}
                </span>
            </div>
        </div>
        <div class="tableau-de-bord-tab-border" :class="{[bgClass]: active}"></div>
    </div>
</template>

<script setup>
import {computed, toRefs} from "vue";

const props = defineProps({
    value: String,
    variant: String,
    name: String,
    title: String,
    icon: String,
    counters: Object,
});

const {variant, name, value} = toRefs(props);

const textClass = computed(() => 'text-'+variant.value);
const bgClass = computed(() => 'bg-'+variant.value);
const active = computed(() => name.value === value.value);
</script>