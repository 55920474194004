<template>
    <MapPin variant="blue" shape="square" :lng-lat="[statut.position.longitude,statut.position.latitude]">
        <template #content>
            <div><i class="fa-regular fa-mobile"></i></div>
            <div class="text-[9px] mb-1">{{ label }}</div>
        </template>
        <template #contentHover>
            {{ statut.top.time }}
        </template>
    </MapPin>
</template>

<script setup>
import MapPin from "@/Vue/Components/RegulationV2/Map/Base/Pin.vue";
import {computed, toRefs} from "vue";

const props = defineProps({
    statut: Object,
});

const {statut} = toRefs(props);

const label = computed(() => ({
    0: 'DEB',
    1: 'PEC',
    2: 'PECD',
    3: 'DST',
    4: 'DSTD',
    5: 'FIN',
}[statut.value.type]));

</script>