<template>
    <sefi-popup title="Rechercher l'état civil" :loading="loading" :error="error" :result="result" :init-form="true" @refresh="refresh">
        <template v-slot:initForm>
            <div class="mx-5">
                <sefi-form-row v-if="hasStructure" v-model="inputData.structure" :errors="errors.structure" component="sefi-form-structure" label="Société" required></sefi-form-row>
                <sefi-form-row v-model="inputData.numeroSecuriteSociale" :errors="errors.numeroSecuriteSociale" component="form-insee" label="Numéro de sécurité sociale" required></sefi-form-row>
                <sefi-form-row v-model="inputData.dateNaissance" :errors="errors.dateNaissance" component="form-date" :props="{picker: true, lunaire: true}" label="Date de naissance" required></sefi-form-row>
                <sefi-form-row v-model="inputData.rangNaissance" :errors="errors.rangNaissance" label="Rang de naissance" required></sefi-form-row>
                <sefi-form-row v-model="inputData.dateEvaluation" :errors="errors.dateEvaluation" component="form-date" :props="{picker: true}" label="Date d'évaluation" required></sefi-form-row>
            </div>
        </template>
        <template v-slot:initFormButtons>
            <b-btn variant="primary" @click="refresh">
                <i class="fa-solid fa-magnifying-glass fa-fw"></i> Rechercher
            </b-btn>
        </template>
        <template v-slot:resultButtons v-if="result">
            <sefi-result-reporter v-if="source === 'patientForm'" :enabled="canReporter" @click="reporter"></sefi-result-reporter>
        </template>
        <template v-slot:form>
            <div class="mb-3" v-if="hasStructure">
                <sefi-form-widget component="sefi-form-structure" v-model="inputData.structure" :errors="errors.type"></sefi-form-widget>
            </div>
            <div class="mb-3">
                <sefi-form-widget component="form-input-group" v-model="inputData.numeroSecuriteSociale" :props="{label: 'NIR', component: 'form-insee'}" :errors="errors.numeroSecuriteSociale"></sefi-form-widget>
            </div>
            <div class="mb-3">
                <sefi-form-widget component="form-input-group" v-model="inputData.dateNaissance" :props="{label: 'Date naissance', component: 'form-date', picker: true, lunaire: true}" :errors="errors.dateNaissance"></sefi-form-widget>
            </div>
            <div class="mb-3">
                <sefi-form-widget component="form-input-group" v-model="inputData.rangNaissance" :props="{label: 'Rang naissance'}" :errors="errors.rangNaissance"></sefi-form-widget>
            </div>
            <div>
                <sefi-form-widget component="form-input-group" v-model="inputData.dateEvaluation" :props="{label: 'Date évaluation', component: 'form-date', picker: true}" :errors="errors.dateEvaluation"></sefi-form-widget>
            </div>
        </template>
        <template v-slot:result v-if="result">
            <sefi-result-table ref="table" @canReporter="canReporter = $event">
                <sefi-result-row :label="Translator.trans('libelle.prenom')" :value="result.prenom" target="#patient_prenomUsuel"></sefi-result-row>
                <sefi-result-row :label="Translator.trans('libelle.nomUsuel')" :value="result.nomUsuel" target="#patient_nomUsuel"></sefi-result-row>
                <sefi-result-row :label="Translator.trans('libelle.nomNaissance')" :value="result.nomNaissance" target="#patient_nomNaissance"></sefi-result-row>
                <sefi-result-row :label="Translator.trans('libelle.caisse')" v-if="result.caisse" :value="result.caisse" target="#patient_caisse">
                    <div v-html="result.caisse.card"></div>
                </sefi-result-row>
                <sefi-result-row :label="Translator.trans('libelle.couverture')" v-else :value="result.couverture"></sefi-result-row>
            </sefi-result-table>
        </template>
    </sefi-popup>
</template>

<script setup>
import Moment from "moment";
import {onMounted, ref, toRefs, computed} from "vue";
import Request from "@/App/Request";
import Router from "@/App/Router";
import Translator from "@/App/Translator";

const props = defineProps({
    data: Object,
});
const {data} = toRefs(props);

const result = ref(null);
const canReporter = ref(null);
const inputData = ref({
    rangNaissance: '1',
    dateEvaluation: Moment().format('DD/MM/YYYY'),
});
const loading = ref(false);
const error = ref(null);
const table = ref();

const hasStructure = computed(() => {
    return source.value !== 'patientView' && source.value !== 'patientForm';
});
const source = computed(() => data.value && data.value.source ? data.value.source : null);
const errors = computed(() => {
    if(error.value) {
        return error.value.errors || [];
    }

    return [];
});

const reporter = () => {
    table.value.reporter();
};

const refresh = () => {
    error.value = null;
    loading.value = true;

    Request.postJson(Router.generate('facturation.sefi.bs.rechercher-etat-civil'), inputData.value).then((data) => {
        if(data.success) {
            result.value = data.data;
        } else {
            error.value = data.error;
        }

        loading.value = false;
    });
};

onMounted(() =>  {
    if(data.value) {
        if (data.value.inputData) {
            inputData.value = data.value.inputData;
        }
        if (source.value === 'patientForm') {
            inputData.value = {
                structure: {structure: App.Shared.getStructure()},
                numeroSecuriteSociale: $('#patient_numeroSecuriteSociale').data('assure') || $('#patient_numeroSecuriteSociale').val(),
                dateNaissance: $('#patient_naissance').val(),
                rangNaissance: $('#patient_rangNaissance').val(),
                dateEvaluation: Moment().format('DD/MM/YYYY'),
            };
        }
    }

    // todo better condition
    if(inputData.value.structure && inputData.value.numeroSecuriteSociale && inputData.value.dateNaissance && inputData.value.rangNaissance) {
        refresh();
    }
});

</script>
