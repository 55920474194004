'use strict';

App.Date = class {
    /**
     * Retourne le format de date au format MomentJS
     * @param format - Format PHP.
     */
    static getFormat(format) {
        let formats = {
            'dd/MM/yyyy': 'DD/MM/YYYY',
            'dd/MM/yyyy HH:mm': 'DD/MM/YYYY HH:mm'
        };
        
        if(format in formats) return formats[format];
        return 'DD/MM/YYYY HH:mm';
    }
};
