<template>
    <tr>
        <td>
            <i class="fa-solid fa-angle-right" v-if="sub"></i>
            <template v-if="'typeForfait' === field">
                Forfait <em>{{ item.entity ? item.entity.libelle.toLowerCase() : 'inconnu' }}</em>
            </template>
            <template v-else>
                {{ label }}
            </template>
        </td>
        <td class="width-100px text-right">
            <span class="text-muted" v-if="null === quantite">-</span>
            <template v-else-if="quantite !== undefined && quantite !== false">
                {{ quantite }}
            </template>
        </td>
        <td class="width-120px text-right">
            <span class="text-muted" v-if="null === montant">-</span>
            <template v-else-if="montant !== undefined && montant !== false">
                <template v-if="nagative">-</template>{{ Euro(montant) }}
            </template>
        </td>
    </tr>
</template>

<script setup>
import {toRefs, computed} from "vue";
import Euro from "@/Vue/Filters/Euro";

const props = defineProps(['field', 'label', 'item', 'sub', 'nagative']);
const {field, label, item, sub, nagative} = toRefs(props);
const quantite = computed(() => item.value ? item.value.quantite : (field.value ? null : false));
const montant = computed(() => item.value ? item.value.montant : (field.value ? null : false))
</script>
