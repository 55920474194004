'use strict';

import {createApp, nextTick, onBeforeMount, onMounted, ref, watch} from 'vue';
import VueChartkick from 'vue-chartkick'
import 'chartkick/chart.js'
import Request from "@/App/Request";
import Router from "@/App/Router";
import Translator from "@/App/Translator";

App.Shared.TableauDeBord = class {
    constructor(params) {
        const element = document.getElementById('app')

        createApp({
            delimiters: ['[[', ']]'],
            setup() {
                const structures = ref([]);
                const tab = ref(params.onglet);
                const refreshing = ref(true);
                const failed = ref(false);
                const webSocket = ref({server: {}, clients: []});
                const modeMontants = ref('ht');

                const refresh = () => {
                    // cheat douteux, une idée de mieux ?
                    const tabVal = tab.value;

                    tab.value = '';
                    nextTick(() => tab.value = tabVal);
                };

                const setOngletParDefaut = (onglet) => {
                    Request.getJson(Router.generate('shared.tableau-de-bord.default-onglet', {app: App.Constants.APPLICATION, onglet})).then(() => {
                        App.Notification.success('L\'onglet par défaut a bien été modifié.');
                    });
                };

                watch([tab, structures], () => {
                    refreshing.value = true;
                });

                watch(refreshing, () => {
                    if (refreshing.value) {
                        failed.value = false;
                    }
                });
                watch(failed, () => {
                    if (failed.value) {
                        refreshing.value = false;
                    }
                });

                onBeforeMount(() => {
                    const treeSelectInputSelector = 'input[name="tableau_de_bord_filter[structure][]"]';

                    $('body').on('change', treeSelectInputSelector, (e) => {
                        let $checkedInputs = $(treeSelectInputSelector + ':checked');

                        structures.value = $.map($checkedInputs, el => el.value);
                    }).one('treeselect.init', () => {
                        $(treeSelectInputSelector).filter(':checked').change();
                    }).on('change', 'select#form_structureCollection', (e) => {
                        structures.value = $(e.currentTarget).val();
                    });
                });

                onMounted(() => {
                    if ('administration' === App.Constants.APPLICATION) {
                        App.webSocket.subscribe('administration/websocket', (uri, data) => {
                            if (data.action === 'init') {
                                webSocket.value.server = data.server;
                            }
                            else if (data.action === 'list') {
                                webSocket.value.clients = data.clients;
                            }
                        });
                    }

                    document.dispatchEvent(new Event('app:vuejs:mounted'));
                });

                return {
                    Translator,
                    structures,
                    tab,
                    refreshing,
                    failed,
                    webSocket,
                    modeMontants,
                    refresh,
                    setOngletParDefaut,
                };
            },

        }).use(VueChartkick).use(App.Pinia).mount(element);
    }
}
