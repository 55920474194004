<template>
    <div class="panel panel-compact">
        <div class="panel-heading d-flex justify-content-between bg-light py-3 px-4">
            <div>Pièces à traiter</div>
        </div>
        <div class="panel-wrapper">
            <div class="panel-body">
                <div class="mb-4">
                    <piece-filter :groups="groups" :types="types" v-model="filter" :full="true"></piece-filter>
                </div>
                <piece-container id="aTraiterContainer" :index="true" :structures="structures" :loading="loading" :filter="filter" :groups="groups" :types="types" :type-presets="typePresets" :piece-per-page="15" @update="handleUpdate" :update-url="updateUrl" :batch-delete-url="batchDeleteUrl" :batch-update-url="batchUpdateUrl"></piece-container>
            </div>
        </div>
    </div>
</template>

<script setup>
import Router from "@/App/Router";
import Request from "@/App/Request";
import {onMounted, ref, toRefs, watch} from "vue";
import {useRequest} from "@/Vue/Composables/Request";

const {postJson, abort} = useRequest();

const emit = defineEmits(['refresh', 'error']);

const props = defineProps({
    structures: Array,
    tab: String,
});

const {structures, tab} = toRefs(props);

const loading = ref(false);
const groups = ref([]);
const types = ref([]);
const typePresets = ref({});
const filter = ref({
    search: '',
    type: [],
});
const updateUrl = ref(null);
const batchDeleteUrl = ref(null);
const batchUpdateUrl = ref(null);

const handleUpdate = (data) => {
    if (data.groups) {
        groups.value = data.groups;
    }
    if (data.types) {
        types.value = data.types;
    }
    if (data.typePresets) {
        typePresets.value = data.typePresets;
    }
    if (data.updateUrl) {
        updateUrl.value = data.updateUrl;
    }
    if (data.batchDeleteUrl) {
        batchDeleteUrl.value = data.batchDeleteUrl;
    }
    if (data.batchUpdateUrl) {
        batchUpdateUrl.value = data.batchUpdateUrl;
    }
};

const refresh = (silent = false) => {
    if (!structures.value.length) {
        abort();
        types.value = [];
        groups.value = [];
        loading.value = false;
        emit('refresh');

        return;
    }

    loading.value = !silent;
    Request.postJson(Router.generate('facturation.tableau-de-bord.piece'), {
        tableau_de_bord_filter: {
            structure: structures.value,
        }
    }).then((data) => {
        handleUpdate(data);
        emit('refresh');
    }, (err) => emit('error', err)).finally(() => {
        loading.value = false;
    });
};

watch(structures, () => refresh());
onMounted(() => refresh());
</script>
