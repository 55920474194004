<template>
    <div class="text-left">
        <h3 class="mt-0">
            <ressource-badge :ressource="ressource" show-type></ressource-badge> {{ ressource.text }}
        </h3>
        <div class="d-flex justify-content-between">
            <div>
                <ressource-badge v-if="equipage && equipage !== ressource" @click="$emit('change', equipage)" :ressource="equipage" show-type link></ressource-badge>
                <ressource-badge v-if="vehicule && vehicule !== ressource" @click="$emit('change', vehicule)" :ressource="vehicule" show-type link></ressource-badge>
                <ressource-badge v-if="personnel1 && personnel1 !== ressource" @click="$emit('change', personnel1)" :ressource="personnel1" show-type link></ressource-badge>
                <ressource-badge v-if="personnel2 && personnel2 !== ressource" @click="$emit('change', personnel2)" :ressource="personnel2" show-type link></ressource-badge>
            </div>
            <div>
            </div>
        </div>
        <hr>
        <div class="text-center">
            <type-horaire-badge class="badge-smooth" :type="ressource.typeCourant"></type-horaire-badge>
            <ressource-etat class="badge-smooth" :ressource="ressource"></ressource-etat>
            <template v-if="ressource._etat.section === 'disponible'">
                <div class="badge badge-white badge-smooth" title="Temps depuis la dernière mission" v-if="ressource._trajetInfo.minTrajet && (!ressource.pausePrecedente || ressource._trajetInfo.minTrajet._topFinReel.isAfter(ressource.pausePrecedente._fin))">
                    <ressource-timer type="disponible" :value="ressource._trajetInfo.minTrajet._topFinReel"></ressource-timer>
                </div>
                <div class="badge badge-white badge-smooth" title="Temps depuis la dernière pause" v-if="ressource.pausePrecedente && (!ressource._trajetInfo.minTrajet || ressource.pausePrecedente._fin.isAfter(ressource._trajetInfo.minTrajet._topFinReel))">
                    <ressource-timer type="disponible" :value="ressource.pausePrecedente._fin"></ressource-timer>
                </div>
                <div class="badge badge-white badge-smooth" title="Nombre de missions terminées">{{ ressource._trajetInfo.total }}</div>
            </template>
            <template v-else-if="ressource._etat.section === 'affecte'">
                <div class="badge badge-white badge-smooth" title="Temps avant prochaine mission" v-if="ressource._trajetInfo.minTrajet">
                    <ressource-timer type="affecte" :value="ressource._trajetInfo.minTrajet.debutMissionPrevu"><i class="im-stopwatch"></i></ressource-timer>
                </div>
            </template>
            <template v-else-if="ressource._etat.section === 'pause'">
                <div class="badge badge-white badge-smooth" title="Temps écoulé depuis le début de la pause" v-if="ressource.pauseCourante">
                    <ressource-timer type="pause" :value="ressource.pauseCourante.debut"></ressource-timer>
                    <trajet-etat-pause :pause="ressource.pauseCourante"></trajet-etat-pause>
                </div>&nbsp;
            </template>
            <template v-else-if="ressource._etat.section === 'mission'">
                <div class="badge badge-secondary badge-smooth">
                    <template v-if="ressource._trajetInfo.total === 1" :title="ressource._trajetInfo.minTrajet.date+' '+ressource._trajetInfo.minTrajet.topCourantValue.time">
                        {{ ressource._trajetInfo.minTrajet.topCourantValue.time }}&nbsp;
                    </template>
                    <template v-else>
                        {{ ressource._trajetInfo.count }} / {{ ressource._trajetInfo.total }}&nbsp;
                    </template>
                    <trajet-etat :trajet="ressource._trajetInfo.minTrajet"></trajet-etat>
                </div>
            </template>
        </div>
        <hr>
        <b-tabs align="center" pills no-fade content-class="m-0">
            <b-tab title="Activité">
                <div class="row">
                    <div :class="ressource._type === 'vehicule' ? 'col-12' : 'col-8'">
                        <h4>Trajets</h4>
                        <b-table @row-clicked="$emit('trajet-select', $event.id)" :tbody-tr-class="item => ({'cursor-pointer': null !== item, selected: item !== null && item === currentTrajet})" hover small responsive show-empty bordered :items="ressource._trajetCollection" :fields="trajetFields">
                            <template #empty="scope">
                                <div class="text-muted text-center">Aucun élément.</div>
                            </template>
                            <template #cell(etat)="{item}">
                                <trajet-etat :trajet="item"></trajet-etat>
                            </template>
                            <template #cell(type)="{item}">
                                <trajet-type :trajet="item"></trajet-type>
                            </template>
                            <template #cell(motif)="{item}">
                                <motif :motif="item.transport.motif"></motif>
                            </template>
                            <template #cell(rendezVous)="{item}">
                                {{ item.rendezVous.time }}
                            </template>
                            <template #cell(patient)="{item}">
                                <trajet-patient :trajet="item"></trajet-patient>
                            </template>
                        </b-table>
                    </div>
                    <div v-if="ressource._type !== 'vehicule'" class="col-4">
                        <h4>Pauses</h4>
                        <b-table hover small responsive show-empty bordered :items="pauses" :fields="pauseFields">
                            <template #empty="scope">
                                <div class="text-muted text-center">Aucun élément.</div>
                            </template>
                            <template #cell(etat)="{item}">
                                <trajet-etat-pause :pause="item"></trajet-etat-pause>
                            </template>
                            <template #cell(debut)="{item}">
                                {{ item._debut.format('HH:mm') }}
                            </template>
                            <template #cell(fin)="{item}">
                                {{ item._fin.format('HH:mm') }}
                            </template>
                            <template #cell(personnel)="{item}">
                                <ressource-badge block :ressource="item._personnel"></ressource-badge>
                            </template>
                        </b-table>
                    </div>
                </div>
            </b-tab>
            <b-tab title="Messagerie" v-if="appareilCollection.length" @click="onMessagerieTab">
                <div class="d-flex" style="max-height: 400px;">
                    <messagerie-chat ref="chat" :messages="messages" :destinataires="appareilCollection.map(appareil => appareil.id)" @send="sendMessage"></messagerie-chat>
                </div>
            </b-tab>
        </b-tabs>
        <div class="text-center mt-4">
            <b-button variant="secondary" class="js-close-swal">Fermer</b-button>
<!--            <b-button variant="dark" v-if="ressource._type === 'vehicule'">-->
<!--                <template v-if="ressource.estIndisponible">-->
<!--                    <i class="fa-solid fa-check"></i>-->
<!--                    Rendre disponible-->
<!--                </template>-->
<!--                <template v-else>-->
<!--                    <i class="fa-solid fa-xmark"></i>-->
<!--                    Rendre indisponible-->
<!--                </template>-->
<!--            </b-button>-->
            <b-button variant="secondary" v-if="canSendPause" @click="openEnvoiPauseSwal(ressource)">
                <i class="fa-solid fa-pause"></i>
                Envoyer une pause
            </b-button>
            <b-button variant="secondary" v-if="canSendFinService" @click="openEnvoiFinServiceSwal(ressource)">
                <i class="fa-solid fa-stop"></i>
                {{ Translator.trans('action.envoyer-fin-service') }}
            </b-button>
        </div>
    </div>
</template>

<script setup>
import _ from 'lodash';
import {computed, ref, toRefs, nextTick} from 'vue';
import {useRegulationStore} from "@/Vue/Stores/Regulation";
import {storeToRefs} from "pinia";

const props = defineProps({
    ressource: Object,
    currentTrajet: Object,
});

const chat = ref();
const {ressource, currentTrajet} = toRefs(props);

const store = useRegulationStore();

const {explodeRessource, getRessourcesAppareilCollection, openEnvoiPauseSwal, openEnvoiFinServiceSwal, onMessagerieSend, canSendFinService: canSendFinServiceBase, canSendPause: canSendPauseBase} = store;
const {messagesByRessourceId} = storeToRefs(store);

const appareilCollection = computed(() => getRessourcesAppareilCollection([ressource.value]));

const [personnel1, personnel2, vehicule, equipage] = explodeRessource(ressource.value);

const trajetFields = ref([
    {key: 'etat', label: 'E', tdClass: 'width-1px text-center', thClass: 'text-center', thAttr: {title: 'État'}},
    {key: 'type', label: 'Type', tdClass: 'width-1px text-center text-nowrap'},
    {key: 'motif', label: 'Motif', tdClass: 'width-1px text-nowrap'},
    {key: 'rendezVous', label: 'RDV', tdClass: 'width-1px text-center text-normal'},
    {key: 'patient', label: 'Patient', tdClass: 'text-ellipsis', tdAttr: (v,k,item) => ({title: item.transport.patient.text})},
    {key: 'villePriseEnCharge', label: 'Ville PEC', tdClass: 'text-ellipsis', tdAttr: (v,k,item) => ({title: item.codePostalPriseEnCharge + ' ' + item.villePriseEnCharge})},
    {key: 'villeDestination', label: 'Ville DEST', tdClass: 'text-ellipsis', tdAttr: (v,k,item) => ({title: item.codePostalDestination + ' ' + item.villeDestination})},
]);

const pauseFields = computed(() => {
    const fields = [
        {key: 'etat', label: 'E', tdClass: 'width-1px text-center', thClass: 'text-center', thAttr: {title: 'État'}},
        {key: 'debut', label: 'Début', tdAttr: (v,k,item) => ({title: item._debut.format('DD/MM/YYYY HH:mm')}), tdClass: (v,k,item) => ({'width-1px trajet-top': true, 'text-normal trajet-top-reel': item.debutReel !== null})},
        {key: 'fin', label: 'Fin', tdAttr: (v,k,item) => ({title: item._fin.format('DD/MM/YYYY HH:mm')}), tdClass: (v,k,item) => ({'width-1px trajet-top': true, 'text-normal trajet-top-reel': item.finReel !== null})},
        {key: 'lieu', label: 'Lieu'},
    ];

    if ('equipage' === ressource.value._type) {
        fields.push({key: 'personnel', label: 'P', tdClass: 'width-1px', thClass: 'text-center', thAttr: {title: 'Personnel'}});
    }

    return fields;
});

const messages = computed(() => messagesByRessourceId.value[ressource.value._type][ressource.value.id] ?? []);

// todo ne semble pas réactif
const pauses = computed(() => {
    const pauses = [];

    for (const personnel of [personnel1, personnel2]) {
        if (personnel && personnel.typeCourant) {
            for(const pause of personnel.typeCourant.pauseCollection) {
                pauses.push({'_personnel': personnel, ...pause});
            }
        }
    }

    return _.sortBy(pauses, '_debut');
});

const canSendPause = computed(() => ressource.value._type === 'personnel' && canSendPauseBase(ressource.value));
const canSendFinService = computed(() => ressource.value._type === 'personnel' && canSendFinServiceBase(ressource.value));

const sendMessage = (data) => {
    onMessagerieSend(data, (res) => {
        chat.value.confirm(res.success);
    });
}

const onMessagerieTab = () => {
    nextTick(() => chat.value.scrollBottom());
}
</script>
