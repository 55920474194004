<template>
    <div ref="mapContainer">
        <template v-if="mapLoaded">
            <slot></slot>
        </template>
    </div>
</template>

<script setup>
import {computed, nextTick, onMounted, provide, ref, shallowRef, toRefs, watch} from 'vue'
import {Map} from 'maplibre-gl';

const props = defineProps({
    center: Object,
    zoom: Number,
    bounds: [Object, Array],
    lock: Boolean,
    config: Object,
    padding: Object,
})
const {center, zoom, lock, config, padding, bounds} = toRefs(props)

const emit = defineEmits(['contextmenu', 'click', 'interract', 'update:center', 'update:zoom']);

const mapLoaded = ref(false);
const mapContainer = ref();
const mapRef = shallowRef(null);

const styleUrl = computed(() => {
    if (config.value.provider === 'tomtom') {
        // TODO
        const mapStyleVersion = '25.*';
        const key = App.Constants.TOMTOM_API_KEY;

        return 'https://api.tomtom.com/style/1/style/' + mapStyleVersion + '?key=' + key + '&map=' + config.value.map + '&poi=' + config.value.poi + '&traffic_incidents=' + config.value.trafficIncidents + '&traffic_flow=' + config.value.trafficFlow
    }
});

onMounted(() => {
    const map = new Map({
        container: mapContainer.value,
        style: styleUrl.value,
        center: center.value ?? undefined,
        bounds: bounds.value,
        zoom: zoom.value ?? 10,
        minZoom: 4,
        dragRotate: false,
        attributionControl: false,
        transformRequest: function(url, resourceType) {
            if (resourceType === 'Tile' && url.indexOf('incidents') > -1) {
                const additionalTags = [
                    'id',
                    'icon_category',
                    'description',
                    'delay',
                    'road_type',
                    'left_hand_traffic',
                    'magnitude',
                    'traffic_road_coverage',
                    'clustered',
                    'probability_of_occurrence',
                    'number_of_reports',
                    'last_report_time',
                    'end_date'
                ];

                const urlObject = new URL(url);
                const searchParams = new URLSearchParams(urlObject.search);
                const tagsString = searchParams.get('tags');
                const tags = tagsString !== null ?
                    tagsString
                        .substring(1, tagsString.length - 1)
                        .split(',')
                        .map(tag => tag.trim()) :
                    [];
                const tagsSet = new Set(tags);

                for (const tag of additionalTags) {
                    tagsSet.add(tag);
                }

                searchParams.set('tags', `[${Array.from(tagsSet).join(',')}]`);

                return {
                    url: `${urlObject.origin}${urlObject.pathname}?${searchParams.toString()}`
                };
            }
        }
    });
    map.setPadding(padding.value);

    if (bounds.value) {
        map.fitBounds(bounds.value, {padding: 50});
    }

    map.on('load', () => {
        mapLoaded.value = true;
    })
    map.on('dragstart', (e) => {
        emit('interract', e);
        nextTick(() => map.handlers.handleEvent(e.originalEvent, 'mousedown'));
    });
    map.on('click', (e) => {
        emit('click', e);
        emit('interract', e);
    });
    map.on('wheel', (e) => {
        emit('interract', e);
    });
    map.on('contextmenu', (e) => {
        emit('interract', e);
    });
    map.on('moveend', (e) => {
        emit('update:center', map.getCenter());
    })
    map.on('zoomend', (e) => {
        emit('update:zoom', map.getZoom());
    })

    map.on('contextmenu', (e) => emit('contextmenu', e));

    mapRef.value = map;


    updateLock();

    watch(styleUrl, () => {
        map.setStyle(styleUrl.value, {
            transformStyle: (previousStyle, nextStyle) => {
                const prefix = 'ambuerp_';
                const custom_layers = previousStyle.layers.filter(layer => layer.id.startsWith(prefix));
                const layers = nextStyle.layers.concat(custom_layers);

                const sources = nextStyle.sources;
                for (const [key, value] of Object.entries(previousStyle.sources)) {
                    if (key.startsWith(prefix)) {
                        sources[key] = value;
                    }
                }
                return {
                    ...nextStyle,
                    sources: sources,
                    layers: layers
                };
            }
        });
    });
});

const updateLock = () => {
    if (lock.value) {
        mapRef.value.scrollZoom.disable();
        mapRef.value.dragPan.disable();
        mapRef.value.doubleClickZoom.disable();
        mapRef.value.touchZoomRotate.disable();
        mapRef.value.boxZoom.disable();
    } else {
        mapRef.value.scrollZoom.enable();
        mapRef.value.dragPan.enable();
        mapRef.value.doubleClickZoom.enable();
        mapRef.value.touchZoomRotate.enable();
        mapRef.value.touchZoomRotate.disableRotation();
        mapRef.value.boxZoom.enable();
    }
};

watch(lock, updateLock);

watch(padding, () => {
    mapRef.value.setPadding(padding.value);
    // mapRef.value.easeTo({padding: padding.value});
})

provide('map', mapRef);
provide('mapPadding', padding);

defineExpose({map: mapRef});
</script>