<template>
    <div class="input-group">
        <template v-if="label !== undefined && label !== null && label !== ''">
            <div class="input-sm d-none"></div>
            <div class="input-group-prepend">
                <span class="input-group-text" :title="title">{{ label }}</span>
            </div>
        </template>
        <form-input v-bind="$attrs" :outlined="outlined && input1.name in value" :value="value[input1.name]" @reset="onReset(input1.name)" @input="onInputChange('input', input1.name, $event)" @change="onInputChange('change', input1.name, $event)" :placeholder="input1.placeholder" :title="input1.title"></form-input>
        <div class="input-group-append input-group-prepend" v-if="input1.suffix">
            <span class="input-group-text">{{ input1.suffix }}</span>
        </div>
        <form-input v-bind="$attrs" :outlined="outlined && input2.name in value" :class="{'ml-0': outlined && input1.name in value && !(input2.name in value)}" :value="value[input2.name]" @reset="onReset(input2.name)" @input="onInputChange('input', input2.name, $event)" @change="onInputChange('change', input2.name, $event)" :placeholder="input2.placeholder" :title="input2.title"></form-input>
        <div class="input-group-append" v-if="input2.suffix">
            <span class="input-group-text">{{ input2.suffix }}</span>
        </div>
    </div>
</template>

<script setup>
import {toRefs} from "vue";

defineOptions({
    inheritAttrs: false,
});

const emit = defineEmits(['reset', 'input', 'change']);

const props = defineProps({
    value: null,
    label: String,
    title: String,
    input1: Object,
    input2: Object,
    outlined: Boolean,
});

const {value, label, title, input1, input2, outlined} = toRefs(props);

const onInputChange = (event, field, data) => {
    emit(event, { ...value.value, [field]: data})
};

const onReset = (field) => {
    if(value.value[field] === '' || value.value[field] === undefined) {
        emit('reset', field);
    }
};
</script>