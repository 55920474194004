'use strict';

import Router from "@/App/Router";

App.Referentiel.Distancier = class {
    static _initMap(paths) {
        let layers = {
            background: new L.PtvLayer.Background(),
            foreground: new L.PtvLayer.Foreground(),
            lines: new L.FeatureGroup(),
            arrows: new L.FeatureGroup()
        };

        layers.background.options.backgroundProfile = 'carto-bg';
        layers.foreground.options.foregroundProfile = 'carto-fg';

        layers.background.options.profileSnippet = layers.foreground.options.profileSnippet = App.Xmap.getProfil();
        layers.background.options.errorTileUrl = App.Constants.GEOLOC_TRANSPARENT_PIXEL_PATH;

        const map = L.map('map', {
            layers: [layers.background, layers.foreground],
            center: [46.8, 1.7],
            crs: L.CRS.PTVMercator,
            zoom: 6.5,
            zoomControl: false,
            attributionControl: false
        });
        map.addLayer(layers.lines);
        map.addLayer(layers.arrows);

        for(const path of paths) {
            let polyline = new L.Polyline(path, {
                color: '#00b3fd',
                weight: 5,
                opacity: 0.7,
                smoothFactor: 1
            });

            // // TODO gérer affichage sens
            // let arrowHead = L.polylineDecorator(polyline, {
            //     patterns: [
            //         {
            //             offset: 25,
            //             repeat: 50,
            //             symbol: L.Symbol.arrowHead({
            //                 pixelSize: 3,
            //                 polygon: false,
            //                 pathOptions: {stroke: true, opacity: 0.9, color: 'white', weight: 2}
            //             })
            //         }
            //     ]
            // });

            layers.lines.addLayer(polyline);
            // layers.arrows.addLayer(arrowHead);
        }

        map.fitBounds(layers.lines.getBounds());
    }
};

App.Referentiel.Distancier.Index = class {
    constructor(params) {
        this._initSuggestions();
        if(params.paths.length) {
            $('#map').show();
            App.Referentiel.Distancier._initMap(params.paths);
        }
    }

    _initSuggestions() {
        $.get(Router.generate('referentiel.distancier.suggestions') + '?' + $('#searchForm').serialize()).done((data) => {
            if(data) {
                $('#suggestionsContent').html(data);
                $('#suggestions').show();
            }
        });

        $('body').on('click', '[data-suggestion]', (el) => {
            const suggestion = $(el.currentTarget).data('suggestion');

            for(let field in suggestion) {
                const $field = $('#distance_'+field);

                if ($field.hasClass('tt-input')) {
                    $field.typeahead('val', suggestion[field]);
                } else {
                    $field.val(suggestion[field]);
                }
            }
        });
    }
};

App.Referentiel.Distancier.View = class {
    constructor(params) {
        App.Referentiel.Distancier._initMap(params.paths);
    }
};