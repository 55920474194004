<template>
    <div v-if="ressource && etat" :class="['badge', 'badge-'+etat.variant]">{{ etat.title }}</div>
</template>

<script>
    export default {
        props: ['ressource'],
        computed: {
            etat() {
                return this.ressource._etat;
            }
        }
    }
</script>