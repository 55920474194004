<template>
    <regulation-envoi type-libelle="fin de service" :personnel1="personnel1" :personnel2="personnel2" :vehicule="vehicule" :equipage="equipage" :can-send="canSend" :loading="loading" @send="send" @appareils="onAppareilsChange">
        <template v-slot:title>Envoyer la fin de service</template>
        <template v-slot:content>
            <div class="section-title"><span>Envoi</span></div>
            <div class="mt-4 d-flex justify-content-center">
                <label class="value-picker__item" v-for="(modeLabel, modeValue) in modes">
                    <input type="radio" :value="modeValue" v-model="mode">
                    <span class="value-picker__item__content">{{ modeLabel }}</span>
                </label>
            </div>
            <div class="mt-2 mb-5">
                <small>
                    <template v-if="mode === 'immediat'">
                        Le message sera envoyé immédiatement, l'heure de fin de service sera calculée dès réception du message.
                    </template>
                    <template v-else-if="mode === 'envoiImmediatCalculTopFinTrajet'">
                        Le message sera envoyé immédiatement, l'heure de fin de service sera calculée à la fin du trajet.
                    </template>
                    <template v-else-if="mode === 'topFinTrajet'">
                        Le message sera envoyé à la fin du trajet, l'heure de fin de service sera calculée dès réception du message.
                    </template>
                    <i class="pl-1 fa-solid fa-circle-question text-info" v-b-tooltip.hover title="Calcul du temps de trajet entre la position GPS de l'appareil et le bureau de fin de service + délai fixe"></i>
                </small>
            </div>
            <div class="section-title"><span>Message</span></div>
            <div class="mt-4 px-5">
                <textarea class="form-control" v-model="message"></textarea>
            </div>
        </template>
    </regulation-envoi>
</template>

<script>
import Moment from 'moment';
import swal from "sweetalert2";
import _ from 'lodash';
import Router from '@/App/Router';
import Request from '@/App/Request';

export default {
    props: ['personnel1', 'personnel2', 'vehicule', 'equipage'],
    data() {
        return {
            mode: null,
            loading: false,
            message: '',
        };
    },
    mounted() {
        this.mode = _.last(Object.keys(this.modes));
    },
    computed: {
        canSend() {
            return this.mode !== null && this.message !== '';
        },
        modes() {
            if (['mission', 'fin-mission'].includes(this.personnel1._etat.section)) {
                return {
                    envoiImmediatCalculTopFinTrajet: 'Immédiat',
                    topFinTrajet: 'À la fin de la mission en cours',
                };
            }

            return {immediat: 'Immédiat'};
        }
    },
    methods: {
        onAppareilsChange(appareils) {
            let message = 'Fin de service';

            for (const appareil of appareils) {
                if (this.vehicule && this.vehicule.appareilCollection.includes(appareil)) {
                    message += ' pour '+this.personnel1.text;

                    if (this.personnel2) {
                        message += ' et ' + this.personnel2.text;
                    }

                    break;
                }
            }

            this.message = message;
        },
        send(appareils) {
            this.loading = true;

            Request.postJson(Router.generate('regulation.envoyer-fin-service.ajax'), {
                appareilCollection: appareils.map(appareil => appareil.id),
                personnel1: this.personnel1 ? this.personnel1.id : null,
                personnel2: this.personnel2 ? this.personnel2.id : null,
                message: this.message,
                mode: this.mode,
            }).then((data) => {
                if(data.success) {
                    swal.close();
                } else {
                    console.log(data.errors);
                    this.loading = false;
                }
            }, (err) => {
                this.loading = false;
            });

        }
    }
}
</script>
