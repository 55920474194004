<template>
    <div class="flex-1 d-flex flex-column overflow-hidden">
        <chat-scroll-view ref="chat" :messages="messages">
            <messagerie-liste :messages="messages" :hover="hover"></messagerie-liste>
        </chat-scroll-view>
        <div class="d-flex">
            <div class="flex-1">
                <input ref="messageInput" type="text" class="form-control" maxlength="140" placeholder="Message..." v-model="message" @keyup.enter="send" :disabled="sending">
            </div>
            <button type="button" class="btn btn-primary" @click="send" :disabled="sending || '' === message">
                <i class="fa-solid fa-spinner fa-spin" v-if="sending"></i>
                <i class="fa-solid fa-comment" v-else></i>
            </button>
        </div>
    </div>
</template>

<script setup>
import {nextTick, ref, toRefs, watch} from "vue";

const emit = defineEmits(['send']);

const props = defineProps({
    messages: Array,
    destinataires: Array,
    hover: Boolean,
});

const {messages, destinataires} = toRefs(props);
const chat = ref();
const messageInput = ref();
const message = ref('');
const sending = ref(false);

const scrollBottom = () =>  {
    chat.value.scrollBottom();
};

const confirm = (success) =>  {
    sending.value = false;

    if (success) {
        message.value = '';
    }
};

const send = () => {
    if('' === message.value) {
        return;
    }

    emit('send', {destinataires: destinataires.value, message: message.value});
    sending.value = true;
};

watch(destinataires, () => {
    messageInput.value.focus();
    nextTick(() => scrollBottom());
});

defineExpose({
    scrollBottom, confirm
});
</script>
