<template>
    <div>
        <h3 class="mt-0">
            <slot name="title"></slot>
        </h3>

        <div class="d-flex justify-content-center mb-5">
            <div v-for="(ressource, ressourceType) in ressources">
                <ressource-badge block full :ressource="ressource" class="mr-2"></ressource-badge>
                <div :title="(appareils.includes(appareil2) ? 'La '+typeLibelle+' sera envoyée sur l\'appareil ' : 'Cliquez ici pour envoyer la '+typeLibelle+' sur l\'appareil ')+appareil2.text" class="ressource-appareil" v-if="ressource.appareilCollection" v-for="appareil2 in ressource.appareilCollection" :class="{'text-success': appareils.includes(appareil2)}" @click="toggleAppareil(ressourceType, appareil2)">
                    <i class="fa-solid fa-mobile-screen-button"></i> {{ appareil2.text }}
                </div>
            </div>
        </div>

        <slot name="content"></slot>

        <div class="text-center mt-5">
            <button class="btn btn-secondary js-close-swal" type="button">Annuler</button>
            <button type="button" class="btn btn-info" :disabled="!appareils.length || !canSend" @click="$emit('send', appareils)" ref="sendBtn">
                <i class="fa-solid fa-check"></i>
                <span class="d-none d-md-inline">Envoyer</span>
            </button>
        </div>
    </div>
</template>

<script>
import Moment from 'moment';
import Ladda from "ladda";
import swal from "sweetalert2";

export default {
    props: ['personnel1', 'personnel2', 'vehicule', 'equipage', 'canSend', 'loading', 'typeLibelle'],
    data() {
        return {
            appareils: [],
        };
    },
    mounted() {
        for(const ressourceType in this.ressources) {
            const ressource = this.ressources[ressourceType];

            if(ressource.appareilCollection && ressource.appareilCollection.length) {
                this.appareils.push(ressource.appareilCollection[0]);

                if ('vehicule' === ressourceType) {
                    break;
                }
            }
        }

        this.sendBtnLadda = Ladda.create(this.$refs.sendBtn);
    },
    watch: {
        loading() {
            if(this.loading) {
                this.sendBtnLadda.start();
            } else {
                this.sendBtnLadda.stop();
            }
        },
        appareils() {
            this.$emit('appareils', this.appareils);
        }
    },
    computed: {
        ressources() {
            let ressources = {};

            if(this.equipage) {
                ressources.equipage = this.equipage;
            }
            if(this.vehicule) {
                ressources.vehicule = this.vehicule;
            }
            if(this.personnel1) {
                ressources.personnel1 = this.personnel1;
            }
            if(this.personnel2) {
                ressources.personnel2 = this.personnel2;
            }

            return ressources;
        },
    },
    methods: {
        toggleAppareil(ressourceType, appareil) {
            if (this.appareils.includes(appareil)) {
                this.appareils.splice(this.appareils.indexOf(appareil), 1);
            } else {
                const ressource = this.ressources[ressourceType];

                if ('vehicule' === ressourceType) {
                    this.appareils = [appareil];
                } else {
                    if (this.vehicule) {
                        for (const appareilVehicule of this.vehicule.appareilCollection) {
                            if (this.appareils.includes(appareilVehicule)) {
                                this.appareils.splice(this.appareils.indexOf(appareilVehicule), 1);
                            }
                        }
                    }

                    for (const appareilRessource of ressource.appareilCollection) {
                        if (this.appareils.includes(appareilRessource)) {
                            this.appareils.splice(this.appareils.indexOf(appareilRessource), 1);
                        }
                    }
                    this.appareils.push(appareil);
                }
            }
        },
    }
}
</script>
