<template>
    <div :class="{'line-height-1': hasMontantBase}">
        <small class="d-block text-line-through" v-if="hasMontantBase">
            <montant title="Montant calculé" :value="trajet._montantTouteTaxeBase"></montant>
        </small>
        <i class="fa-solid fa-cloud" title="Calcul SEFi" v-if="trajet._calculSefi"></i>
        <montant :value="trajet._montantTouteTaxe" title="Montant retenu"></montant>
    </div>
</template>

<script setup>
import {computed, toRefs} from "vue";

const props = defineProps({
    value: String,
    trajet: Object,
});

const {trajet} = toRefs(props);

const hasMontantBase = computed(() => {
    return null !== trajet.value._montantTouteTaxeBase && trajet.value._montantTouteTaxeBase !== trajet.value._montantTouteTaxe;
});

</script>
