<template>
    <div class="relative overflow-hidden flex min-h-0 w-full">
        <OverlayScrollbarsComponent :options="{scrollbars: {autoHide: 'move'}}" :style="{'padding-bottom': paddingBottom}" class="mb-2 w-full" ref="chat">
            <div class="flex flex-col gap-y-3 p-3" v-if="messages.length">
                <slot name="message">
                    <template v-for="(message, index) in messages">
                        <Section center variant="light" class="text-sm" v-if="index === 0 || message.horodatage.date !== messages[index-1].horodatage.date" :title="formatDateSeparator(message.horodatage.date)"></Section>
                        <ChatMessage :message="message" @hover="onHover(message, $event)" :focus="focusedMessages.length ? focusedMessages.includes(message) : null"></ChatMessage>
                    </template>
                </slot>
            </div>
            <EmptyPlaceholder v-else title="Aucun message" :description="$slots.form ? 'Envoyez un premier message pour débuter une conversation.' : 'Les messages reçus apparaîtront ici.'" icon="im-pencil"></EmptyPlaceholder>
        </OverlayScrollbarsComponent>
        <div class="absolute bottom-0 left-0 right-0 p-2" v-if="$slots.form">
            <Message v-if="errors && errors.length" severity="error" :sticky="false" :life="5000" class="!mt-0 !mb-2 backdrop-blur-lg" :pt="{wrapper: {class: '!py-1'}}">
                <div v-for="error in errors">{{ error }}</div>
            </Message>
            <div class="backdrop-blur-lg rounded-lg bg-gray-50 bg-opacity-80 dark:bg-black dark:bg-opacity-40 shadow-md border dark:border-surface-700">
                <slot name="form"></slot>
            </div>
        </div>
    </div>
</template>

<script setup>
import { OverlayScrollbarsComponent } from "overlayscrollbars-vue";
import {computed, nextTick, onMounted, ref, toRefs, useSlots, watch} from "vue";
import {useElementSize, useScroll} from "@vueuse/core";
import ChatMessage from "@/Vue/Components/RegulationV2/Layout/Chat/Message.vue";
import EmptyPlaceholder from "@/Vue/Components/RegulationV2/Layout/EmptyPlaceholder.vue";
import Message from "primevue/message";
import Moment from "moment/moment";
import Section from "@/Vue/Components/RegulationV2/Layout/Section";

const props = defineProps({
    messages: Array,
    formHeight: String,
    errors: Array,
    hover: Boolean,
})

const slots = useSlots();

const chat = ref();
const chatElement = computed(() => chat.value?.osInstance().elements().viewport);

const {messages, formHeight, errors, hover, canSend} = toRefs(props);
const { y, arrivedState } = useScroll(chatElement);
const { bottom } = toRefs(arrivedState)
const { height } = useElementSize(chatElement)

const paddingBottom = computed(() => slots.form ? formHeight.value : '0px');

const realBottom = computed(() => {
    return bottom.value || (chatElement.value && chatElement.value.scrollHeight <= height.value.toFixed());
})
const scrollBottom = () => {
    if (chatElement.value) {
        y.value = chatElement.value.scrollHeight;
    }
}

const formatDateSeparator = (date) => {
    const moment = Moment(date, 'DD/MM/YYYY');

    if(moment.isValid()) {
        if(moment.isSame(Moment(), 'day')) {
            return 'Aujourd\'hui';
        }
        if(moment.isSame(Moment().subtract(1, 'day'), 'day')) {
            return 'Hier';
        }
    }

    return date;
}

const hoveredAppareilsIds = ref([]);
const onHover = (message, hovered) => {
    if (hover.value) {
        if (hovered) {
            hoveredAppareilsIds.value = [message.appareilDestinataire, message.appareilDestinataire2, message.appareilEmetteur].filter(Boolean).map(a => a.id);
        } else {
            hoveredAppareilsIds.value = [];
        }
    }
};

const focusedMessages = computed(() => {
    return messages.value.filter(message => [message.appareilDestinataire, message.appareilDestinataire2, message.appareilEmetteur].filter(Boolean).map(a => a.id).some(id => hoveredAppareilsIds.value.includes(id)));
});

// todo : mettre en avant nouveaux messages (lastMessage id/date), set si pas iddl sur l'onglet)

watch(messages, () => {
    if (realBottom.value) {
        nextTick(() => scrollBottom());
    }
}, {deep: true})

onMounted(() => {
    scrollBottom();
});

defineExpose({scrollBottom});
</script>

<style scoped>
:deep(.os-scrollbar-vertical) {
    --os-padding-perpendicular: calc(v-bind('paddingBottom') + 4px) 2px;
    --os-padding-axis: 2px 2px;
}
</style>