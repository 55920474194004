<template>
    <Row v-bind="$attrs" v-if="isChild">
        <slot></slot>
    </Row>
    <Group v-else>
        <Row v-bind="$attrs">
            <slot></slot>
        </Row>
    </Group>
</template>

<script setup>
import Skeleton from "primevue/skeleton";
import Group from "@/Vue/Components/RegulationV2/Layout/Popup/Group";
import Row from "@/Vue/Components/RegulationV2/Layout/Popup/Row";
import {inject} from "vue";

defineOptions({
    inheritAttrs: false,
});

const isChild = inject('fromGroup', false);
</script>
