<template>
    <Popup @close="activeRessourcePopup = null">
        <template #headerLeft>
            <span :style="{background: ressource.regulation.couleur}" class="px-1 rounded-md mr-1 text-white">{{ ressource.regulation.shortString }}</span>
            {{ ressource.regulation.text }}
        </template>
        <template #footerLeft>
            <div class="px-2 flex flex-col justify-center leading-tight text-xs" v-if="ressource.geolocalisation.position">
                <UpdateIndicator :ressource="ressource.geolocalisation" precise></UpdateIndicator>
                <div class="text-slate-500 truncate" :title="ressource.geolocalisation.position.adresseCompleteUneLigne" v-if="ressource.geolocalisation.position.adresseCompleteUneLigne">
                    <i class="fa-solid fa-fw fa-location-dot"></i> {{ ressource.geolocalisation.position.adresseCompleteUneLigne }}
                </div>
            </div>
        </template>
        <template #footerRight>
            <div class="px-1 flex items-center justify-between">
                <Button icon="fa-solid fa-location-crosshairs" title="Suivre" severity="secondary" size="small" text/>
                <ItineraireButton></ItineraireButton>

                <Button icon="fa-solid fa-reply-clock" title="Replay" severity="secondary" size="small" text :disabled="!ressource.geolocalisation.position?.segment" :loading="activeSegmentLoading" @click="replay"/>
                <Button icon="fa-solid fa-gear" title="Options" severity="secondary" size="small" text/>
            </div>
        </template>
        <Tabs lazy v-model:value="activeRessourcePopupTab">
            <TabList class="font-semibold">
                <Tab value="ressource" class="!py-2">
                    <i class="fa-solid fa-home"></i>
                </Tab>
                <Tab value="historique" class="!py-2 flex-1 justify-center !px-5">
                    Historique
                </Tab>
                <Tab v-if="roles.ROLE_REGULATION_V2_TRAJET_LIST" value="trajets" class="!py-2 flex-1 justify-center !px-5">
                    Trajets
                </Tab>
                <Tab v-if="roles.ROLE_REGULATION_V2_MESSAGERIE_LIST" value="messagerie" class="!py-2 flex-1 justify-center !px-5">
                    <span :class="{'mr-1': messagerieBadge > 0}">Messagerie</span>
                    <NotificationBadge v-if="messagerieBadge > 0" :animate="false" absolute>{{ messagerieBadge }}</NotificationBadge>
                </Tab>
            </TabList>
            <TabPanels class="!p-0">
                <TabPanel value="ressource" class="h-[220px]" :pt="{header: 'flex', headerAction: '!py-2 flex-1', content: 'h-[220px]'}">
                    <RessourceTab :ressource="ressource"></RessourceTab>
                </TabPanel>
                <TabPanel value="historique" class="h-[220px]" :pt="{header: 'flex flex-1', headerAction: '!py-2 flex-1 justify-center !px-5', content: 'h-[220px]'}">
                    <HistoriqueTab :ressource="ressource"></HistoriqueTab>
                </TabPanel>
                <TabPanel v-if="roles.ROLE_REGULATION_V2_TRAJET_LIST" value="trajets" class="h-[220px]" :pt="{header: 'flex flex-1', headerAction: '!py-2 flex-1 justify-center !px-5', content: 'h-[220px]'}">
                    <TrajetsTab :ressource="ressource"></TrajetsTab>
                </TabPanel>
                <TabPanel v-if="roles.ROLE_REGULATION_V2_MESSAGERIE_LIST" value="messagerie" class="h-[220px] flex" :pt="{header: 'flex flex-1', headerAction: '!py-2 flex-1 justify-center !px-5', content: 'h-[220px] flex'}">
                    <MessagerieTab :ressource="ressource"></MessagerieTab>
                </TabPanel>
            </TabPanels>
        </Tabs>
    </Popup>
</template>

<script setup>
import Popup from "@/Vue/Components/RegulationV2/Layout/Popup";
import HistoriqueTab from "@/Vue/Components/RegulationV2/Map/Ressource/Tab/Historique";
import NotificationBadge from "@/Vue/Components/RegulationV2/Layout/NotificationBadge";
import Tabs from 'primevue/tabs';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabPanels from 'primevue/tabpanels';
import UpdateIndicator from "@/Vue/Components/RegulationV2/Map/Ressource/UpdateIndicator";
import TieredMenu from "primevue/tieredmenu";
import TabPanel from "primevue/tabpanel";
import TrajetsTab from "@/Vue/Components/RegulationV2/Map/Ressource/Tab/Trajets";
import Button from "primevue/button";
import RessourceTab from "@/Vue/Components/RegulationV2/Map/Ressource/Tab/Ressource";
import MessagerieTab from "@/Vue/Components/RegulationV2/Map/Ressource/Tab/Messagerie";
import ItineraireButton from "@/Vue/Components/RegulationV2/Layout/Popup/ItineraireButton";
import {computed, ref, toRefs} from "vue";
import {useGeolocalisationStore} from "@/Vue/Stores/RegulationV2/Geolocalisation";
import {storeToRefs} from "pinia";
import {useHistoriqueStore} from "@/Vue/Stores/RegulationV2/Historique";

const geolocalisationStore = useGeolocalisationStore();
const {activeRessourcePopup, activeRessourcePopupTab, roles} = storeToRefs(geolocalisationStore);

const historiqueStore = useHistoriqueStore();
const {activeSegmentLoading} = storeToRefs(historiqueStore);
const {loadSegment} = historiqueStore;

const props = defineProps({
    ressource: Object,
});

const {ressource} = toRefs(props);
const messagerieBadge = computed(() => ressource.value.notifications.filter(notification => notification.type === 'messagerie').length);

const replay = () => {
    loadSegment(ressource.value.geolocalisation.position.segment.id);
};

</script>