<template>
    <div ref="content">
        <slot v-if="popupOpen"></slot>
    </div>
</template>

<script setup>
import {inject, ref, onMounted, nextTick, watch, computed, toRefs, provide} from "vue";
import {Popup} from 'maplibre-gl';

const content = ref();
const map = inject('map')
const mapPadding = inject('mapPadding')
const marker = inject('marker');
const popupOpen = inject('popup', ref(true))
const shape = inject('shape', ref(null));

const popupOffset = computed(() => shape.value === 'square' ? 28 : 22);

const popup = new Popup({
    closeButton: false,
    offset: popupOffset.value,
    className: 'maplibregl-popup-custom',
    anchor: 'bottom',
});

const fitPopup = (smooth = true) => {
    const margin = 10;
    const mapRight = window.innerWidth - (mapPadding.value.right + margin);
    const mapBottom = window.innerHeight - (mapPadding.value.bottom + margin + popupOffset.value);
    const contentBounds = content.value.getBoundingClientRect();
    const mapLeft = mapPadding.value.left + margin;
    const mapTop = mapPadding.value.top + margin;

    let x = 0;
    if (contentBounds.left < mapLeft) {
        x = contentBounds.left - mapLeft;
    } else if (contentBounds.right > mapRight) {
        x = contentBounds.right - mapRight;
    }

    let yOffscreen;
    let y = 0;
    if (contentBounds.top < mapTop) {
        y = contentBounds.top - mapTop;
        yOffscreen = contentBounds.top + contentBounds.height + popupOffset.value < 0;
    } else if (contentBounds.bottom > mapBottom) {
        y = contentBounds.bottom - mapBottom;
        yOffscreen = contentBounds.bottom + popupOffset.value > window.innerHeight;
    }

    // todo gérer cas ou zone de travail trop petite pour accueillir popup au centre (view height < popup height * 1,5)
    if (x || y) {
        if (Math.abs(x) > contentBounds.width / 2 || yOffscreen) {
            map.value.flyTo({
                center: popup.getLngLat(),
                zoom: 15,
                curve: 0.5,
                speed: 2,
                maxDuration: 1500,
            });
        } else {
            map.value.panBy([x, y], smooth ? {} : {duration: 0});
        }
    }
}

watch(mapPadding, () => {
    if (popupOpen.value) {
        fitPopup(false);
    }
})

popup.on('open', () => {
    // Cas où popup initialisé pendant un mouvement de caméra vers le point, pas besoin de fit
    if (!ready.value && map.value.isMoving()) {
        return;
    }

    nextTick(() => {
        popupOpen.value = true;
        nextTick(() => fitPopup())
    });
})
popup.on('close', () => {
    popupOpen.value = false;
})

const ready = ref(false);

onMounted(() => {
    popup.setDOMContent(content.value);
    marker.value.setPopup(popup);
    if (popupOpen.value) {
        marker.value.togglePopup();
    }
    ready.value = true;
});

watch(popupOpen, () => {
    if (popup.isOpen() !== popupOpen.value) {
        marker.value.togglePopup();
    }
});

provide('shape', shape);

</script>