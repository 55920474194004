<template>
    <div>
        <div class="panel panel-compact">
            <div class="panel-heading d-flex justify-content-between bg-light py-3 px-4">
                <div class="d-flex align-items-center">
                    <div>
                        Chiffre d'affaires
                    </div>
                    <span class="my-n1 ml-3 mr-n3">
                        <span class="badge badge-secondary" v-if="'week' === dateMode">Semaine {{ date.format('W') }}</span>
                        <span class="badge badge-secondary" v-if="'month' === dateMode">{{ date.format('MMMM') }}</span>
                        <span class="badge badge-secondary text-transform-none">
                            {{ date.format('YYYY') }}
                        </span>
                    </span>
                </div>
                <div class="my-n2 text-transform-none d-flex">
                    <form-btn-group :disabled="loading" class="bg-white input-sm mx-3" v-model="mode" type="radio" :choices="{graph: 'Graphique', table: 'Tableau'}"></form-btn-group>
                    <b-btn size="sm" variant="outline-secondary" @click="step(-1)" :disabled="loading">
                        <i class="fa-solid fa-chevron-left"></i>
                    </b-btn>
                    <b-btn size="sm" variant="outline-secondary" @click="step(+1)" :disabled="loading">
                        <i class="fa-solid fa-chevron-right"></i>
                    </b-btn>
                    <b-btn size="sm" variant="outline-secondary" @click="reset()" :disabled="loading">
                        <i class="fa-solid fa-calendar-days"></i>
                    </b-btn>
                    <form-btn-group :disabled="loading" class="bg-white input-sm mx-3" v-model="dateMode" type="radio" @change="refresh" :choices="{week: 'Semaine', month: 'Mois', year: 'Année', exercice: 'Exercice'}"></form-btn-group>
                    <form-btn-group class="bg-white input-sm" :value="modeMontants" @input="emit('mode-montants', $event)" type="radio" :choices="{ht: 'HT', ttc: 'TTC'}"></form-btn-group>
                </div>
            </div>
            <div class="panel-wrapper">
                <div class="panel-body">
                    <b-tabs v-if="'table' === mode" :nav-wrapper-class="structures.length <= 1 ? 'd-none' : ''" content-class="m-0" nav-class="border-0 nav-tabs-sm overflow-hidden">
                        <b-tab v-for="(structureData, structureId) in tableData[modeMontants]" :title="structureData.libelle" :key="'caTab_'+structureId">
                            <div class="table-responsive">
                                <table class="table table-bordered table-compact text-right">
                                    <thead>
                                    <tr>
                                        <th class="font-size-10px py-1">
                                            Prestation
                                            <i class="fa-solid fa-arrow-right"></i>
                                        </th>
                                        <th rowspan="2" v-for="(montantsByIndexFacturation, indexPrestation) in structureData.data" class="text-right text-nowrap vertical-middle" :class="{'text-muted': indexPrestation > now.format('month' === dateMode ? 'YYYY-WW' : 'YYYY-MM-DD') || ('year' === dateMode && null !== structureData.clotureExercice && indexPrestation <= structureData.clotureExercice)}">
                                            {{ structureData.labels[indexPrestation] }}
                                        </th>
                                        <th rowspan="2" class="text-right text-nowrap vertical-middle">Total</th>
                                    </tr>
                                    <tr>
                                        <th class="font-size-10px py-1">
                                            Facturation
                                            <i class="fa-solid fa-arrow-down"></i>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="indexFacturation in Object.keys(Object.values(structureData.data)[0])">
                                        <th class="text-nowrap">{{ structureData.labels[indexFacturation] }}</th>
                                        <td
                                            v-for="(montantsByIndexFacturation, indexPrestation) in structureData.data"
                                            :class="{'bg-light': ('non_facture' !== indexFacturation && 'brouillon' !== indexFacturation && (indexFacturation < indexPrestation || indexFacturation > now.format('month' === dateMode ? 'YYYY-WW' : 'YYYY-MM-DD'))) || indexPrestation > now.format('month' === dateMode ? 'YYYY-W' : 'YYYY-MM-DD') || ('year' === dateMode && null !== structureData.clotureExercice && indexPrestation <= structureData.clotureExercice), 'text-normal': indexFacturation === indexPrestation}"
                                        >
                                            <montant :value="montantsByIndexFacturation[indexFacturation]"></montant>
                                        </td>
                                        <th>
                                            <montant :value="tableTotals[structureId].facturation[indexFacturation]"></montant>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th class="text-nowrap">Total</th>
                                        <th v-for="(montantsByIndexFacturation, indexPrestation) in structureData.data">
                                            <montant :value="tableTotals[structureId].prestation[indexPrestation]"></montant>
                                        </th>
                                        <th>
                                            <montant :value="tableTotals[structureId].total"></montant>
                                        </th>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </b-tab>
                    </b-tabs>
                    <column-chart v-else empty=" " :data="graphData[modeMontants]" :legend="false" :dataset="{borderWidth: 0}" :library="caChartConfig"></column-chart>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="panel panel-compact">
                    <div class="panel-heading d-flex justify-content-between bg-light py-3 px-4">
                        <div>Types de factures</div>
                    </div>
                    <div class="panel-wrapper">
                        <div class="panel-body">
                            <pie-chart empty=" " :data="typeFacture"></pie-chart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="panel panel-compact">
                    <div class="panel-heading d-flex justify-content-between bg-light py-3 px-4">
                        <div>Types de trajets facturés</div>
                    </div>
                    <div class="panel-wrapper">
                        <div class="panel-body">
                            <pie-chart empty=" " :data="typeTrajet"></pie-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Euro from "@/Vue/Filters/Euro";
import moment from "moment";
import {computed, onMounted, ref, toRefs, watch} from "vue";
import Router from "@/App/Router";
import {useRequest} from "@/Vue/Composables/Request";

const {postJson, abort} = useRequest();

const emit = defineEmits(['refresh', 'error', 'mode-montants']);
const props = defineProps({
    structures: Array,
    tab: String,
    modeMontants: String,
});

const {structures, modeMontants} = toRefs(props);

const loading = ref(false);
const graphData = ref([]);
const tableData = ref([]);
const typeFacture = ref([]);
const typeTrajet = ref([]);
const dateMode = ref('exercice');
const date = ref(moment());
const now = ref(moment());
const mode = ref('graph');

const refresh = () => {
    if(!structures.value.length) {
        abort();
        loading.value = false;
        emit('refresh');

        graphData.value = [];
        tableData.value = [];
        typeFacture.value = [];
        typeTrajet.value = [];

        return;
    }

    loading.value = true;

    postJson(Router.generate('facturation.tableau-de-bord.statistiques'), {
        tableau_de_bord_filter: {
            structure: structures.value,
        },
        mode: dateMode.value,
        date: date.value.format('YYYY-MM-DD'),
    }).then((data) => {
        graphData.value = data.ca.graph;
        tableData.value = data.ca.table;
        typeFacture.value = data.typeFacture;
        typeTrajet.value = data.typeTrajet;

        emit('refresh');
    }, (err) => emit('error', err)).finally(() => {
        loading.value = false;
    });
};

const tableTotals = computed(() => {
    const res = {};

    const currentTableData = tableData.value[modeMontants.value];
    for (const structureId in currentTableData) {
        const item = {
            prestation: {},
            facturation: {},
            total: null,
        };

        const structureData = currentTableData[structureId].data;

        item.facturation = {};

        for (const indexPrestation in structureData) {
            for (const indexFacturation in structureData[indexPrestation]) {
                const montant = structureData[indexPrestation][indexFacturation];

                if (!(indexPrestation in item.prestation)) {
                    item.prestation[indexPrestation] = null;
                }
                if (!(indexFacturation in item.facturation)) {
                    item.facturation[indexFacturation] = null;
                }
                if (null !== montant) {
                    item.facturation[indexFacturation] += parseFloat(montant);
                    item.prestation[indexPrestation] += parseFloat(montant);
                }
            }

            if (null !== item.prestation[indexPrestation]) {
                item.total += item.prestation[indexPrestation];
            }
        }

        res[structureId] = item;
    }

    return res;
});

const caChartConfig = {
    scales: {
        x: {
            ticks: {
                callback: function(value, index, ticks) {
                    return this.getLabelForValue(value).split(' ')[0];
                }
            }
        },
    },
    interaction: {
        mode: 'x',
    },
    plugins: {
        tooltip: {
            callbacks: {
                label: (context) => {
                    return context.dataset.label + ' : ' + Euro(context.raw);
                },
                title: (context, a, b) => {
                    if (context.length > 1) {
                        const total = context.reduce((sum, context2) => sum + context2.raw, 0);

                        return context[0].label + ' : ' + Euro(total);
                    }
                }
            }
        }
    },
}

const reset = () => {
    date.value = moment();
};

const step = (amount) => {
    date.value = moment(date.value).add(amount, dateMode.value === 'exercice' ? 'year' : dateMode.value);
};

watch(date, () => refresh());
watch(structures, () => refresh());

onMounted(() => refresh());

</script>
