<template>
    <Path v-if="polyline" :features="[polyline]"><slot></slot></Path>
</template>

<script setup>
import Path from '@/Vue/Components/RegulationV2/Map/Base/Path';
import {ref, toRefs, watch, onMounted} from "vue";
import Request from "@/App/Request";
import Router from "@/App/Router";
import _ from 'lodash';

const emit = defineEmits(['loading']);

const props = defineProps({
    steps: Array,
});
const {steps} = toRefs(props);

const polyline = ref(null);

watch(steps, (newSteps, oldSteps) => {
    if (_.isEqual(newSteps, oldSteps)) {
        return;
    }

    emit('loading', true);
    Request.getJson(Router.generate('map.routing', {steps: steps.value})).then(data => {
        polyline.value = {
            type: 'Feature',
            geometry: JSON.parse(data.polyline),
        };
        emit('loading', false);
    }).catch(error => {
        console.log(error)
    });
}, {immediate: true})
</script>