<template>
    <Custom>
        <ButtonGroup>
            <Button @click="resetMap" title="Vue d'ensemble">
                <i class="fa-solid fa-minimize fa-fw"></i>
            </Button>
<!--            <Button @click="goTo({lat: 45.7761938, lng:3.0922561})" title="Agence secondaire">-->
<!--                B2-->
<!--            </Button>-->
        </ButtonGroup>
    </Custom>
</template>

<script setup>
import Custom from '@/Vue/Components/RegulationV2/Map/Base/Control/Custom'
import ButtonGroup from '@/Vue/Components/RegulationV2/Map/Base/Control/Button/Group'
import Button from '@/Vue/Components/RegulationV2/Map/Base/Control/Button/Button'
import {inject} from 'vue';
import {storeToRefs} from "pinia";

const map = inject('map');
const resetMap = inject('resetMap');

// context menu

const goTo = (center) => {
    // jump if distance too long
    // map.value.jumpTo({
    //     center,
    //     zoom: 12,
    // })
    map.value.flyTo({
        center,
        zoom: 12,
        curve: 0.5,
        speed: 2,
        maxDuration: 1500,
    })
}
</script>