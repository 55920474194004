<template>
    <slot></slot>
</template>

<script setup>
import {computed, getCurrentInstance, inject, onMounted, onUnmounted, provide, ref, toRefs, watch} from "vue";
import {LngLatBounds} from "maplibre-gl";

const instance = getCurrentInstance();
const map = inject('map')

const props = defineProps({
    id: String,
    features: Array,
    mode: String,
    antSpeed: Number,
    color: String,
    animate: Boolean,
    autoFit: Boolean,
    fitOptions: Object,
})

const {features, mode, color, animate, antSpeed, autoFit, fitOptions} = toRefs(props);
const baseId = 'ambuerp_'+instance.uid+'_'+props.id;

let animationFrameRequestId = null;
let animationTimer = null;
const dashLength = 10;
const gapLength = 20;

const mapPadding = inject('mapPadding');

watch (mapPadding, () => {
   if (autoFit.value) {
       fit();
   }
});

const fadeDuration = 150;
const featuresData = animate.value ? ref([]) : features;

watch(features, () => {
    map.value.getSource(baseId).setData({
        type: 'FeatureCollection',
        features: features.value,
    });
});

const bounds = computed(() => {
    const coordinates = features.value.map((feature) => feature.geometry.coordinates).flat();

    return coordinates.reduce((bounds, coord) => bounds.extend(coord), new LngLatBounds(coordinates[0], coordinates[0]))
});

const fit = () => {
    map.value.fitBounds(bounds.value, {
        padding: 50,
        ...fitOptions.value,
    });
};

watch(bounds, () => {
    if (autoFit.value) {
        fit();
    }
}, {immediate: true});

onMounted(() => {
    if (animate.value) {
        const featureStack = JSON.parse(JSON.stringify(features.value));

        let currentFeatureStack = featureStack.shift();
        let currentFeature =  {type: 'Feature', geometry: {type: 'LineString', coordinates: []}};
        featuresData.value = [currentFeature];

        const step = () => {
            if (currentFeatureStack) {
                if (currentFeatureStack.geometry.coordinates.length) {
                    currentFeature.geometry.coordinates.push(currentFeatureStack.geometry.coordinates.shift());

                    return true;
                } else {
                    currentFeatureStack = featureStack.shift();

                    if (currentFeatureStack) {
                        currentFeature = {type: 'Feature', geometry: {type: 'LineString', coordinates: []}};
                        featuresData.value.push(currentFeature);
                    }
                    return step();
                }
            }

            return false;
        };

        animationTimer = setInterval(() => {
            if (step()) {
                map.value.getSource(baseId).setData({
                    type: 'FeatureCollection',
                    features: featuresData.value,
                });
            } else {
                clearInterval(animationTimer);
            }
        }, 3);
    }

    map.value.addSource(baseId, {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: featuresData.value,
        },
        lineMetrics: mode.value === 'trail',
    })


    if (mode.value === 'ant') {
        // todo mode ant fixe (pas de mouvement des dash, mais pulse) => quand véhicule a l'arrêt mais trajet a réaliser
        // => ajouter param speed : si 0, breath animation
        map.value.addLayer({
            'id': baseId,
            'type': 'line',
            'source': baseId,
            paint: {
                'line-color': color.value,
                'line-width': 6,
                'line-opacity': 0,
            },
        });
        map.value.addLayer({
            id: baseId+'-dash',
            type: 'line',
            source: baseId,
            // 'layout': {
            //     'line-join': 'round',
            //     'line-cap': 'round'
            // },
            paint: {
                'line-opacity': 0,
                'line-color': color.value,
                'line-width': 6,
                'line-dasharray': [0, gapLength, dashLength]
            },
        });
        map.value.setPaintProperty(baseId, 'line-opacity', .6, {
            duration: fadeDuration,
        });
        map.value.setPaintProperty(baseId+'-dash', 'line-opacity', 1, {
            duration: fadeDuration,
        });

        animateAnt();
    }
    else if (mode.value === 'trail') {
        map.value.addLayer({
            id: baseId,
            type: 'line',
            source: baseId,
            layout: {
                'line-join': 'round',
                'line-cap': 'round'
            },
            paint: {
                'line-opacity': 0,
                'line-width': 5,
                // 'line-gradient' must be specified using an expression
                // with the special 'line-progress' property
                'line-gradient': [
                    'interpolate',
                    ['linear'],
                    ['line-progress'],
                    0.7,
                    color.value,
                    1,
                    'transparent',
                ]
            },
        });
        map.value.setPaintProperty(baseId, 'line-opacity', 1, {
            duration: fadeDuration,
        });

    } else {
        map.value.addLayer({
            'id': baseId,
            'type': 'line',
            'source': baseId,
            'layout': {
                'line-join': 'round',
                'line-cap': 'round'
            },
            paint: {
                'line-opacity': 0,
                'line-width': 5,
                'line-color': color.value
            },
        });

        map.value.setPaintProperty(baseId, 'line-opacity', 1, {
            duration: fadeDuration,
        });
    }

});

const maxSteps = 300; // 0
const minSteps = 30; // 100

const antSteps = computed(() => {
    let steps;
    if (antSpeed.value <= 1) {
        steps = maxSteps;
    } else if (antSpeed.value >= 100) {
        steps = minSteps;
    } else {
        steps = (-((maxSteps-minSteps)/99)*(antSpeed.value-1))+maxSteps
    }

    return Math.round(steps);
});


const animateAnt = () => {
    if (antSpeed.value > 0) {
        if (!animationFrameRequestId) {
            let antStep = 0;

            function animateDashArray(timestamp) {
                const dashSteps = Math.round(antSteps.value * dashLength / (gapLength + dashLength));
                const gapSteps = antSteps.value - dashSteps;

                // +1 la step si au moins x ms passé depuis la tick
                const newStep = antSteps.value - parseInt((timestamp / 30) % antSteps.value);

                let dashStep, dash1, gap1, dash2, gap2;
                if (newStep < dashSteps) {
                    dashStep = newStep / dashSteps;
                    dash1 = (1 - dashStep) * dashLength;
                    gap1 = gapLength;
                    dash2 = dashStep * dashLength;
                    gap2 = 0;
                } else {
                    dashStep = (newStep - dashSteps) / (gapSteps);
                    dash1 = 0;
                    gap1 = (1 - dashStep) * gapLength;
                    dash2 = dashLength;
                    gap2 = dashStep * gapLength;
                }

                if (newStep !== antStep) {
                    map.value.setPaintProperty(
                        baseId+'-dash',
                        'line-dasharray',
                        [dash1, gap1, dash2, gap2]
                    );
                    antStep = newStep;
                }

                animationFrameRequestId = requestAnimationFrame(animateDashArray);
            }

            animateDashArray(0);
        }
    } else {
        if (animationFrameRequestId) {
            cancelAnimationFrame(animationFrameRequestId);
            animationFrameRequestId = null;
        }
        // todo pulsate

    }
};

watch(antSpeed, animateAnt);

onUnmounted(() => {
    map.value.setPaintProperty(baseId, 'line-opacity', 0, {
        duration: fadeDuration,
    });
    if (map.value.getLayer(baseId+'-dash')) {
        map.value.setPaintProperty(baseId+'-dash', 'line-opacity', 0, {
            duration: fadeDuration,
        });
    }

    setTimeout(() => {
        if (animationFrameRequestId) {
            cancelAnimationFrame(animationFrameRequestId);
        }

        if (animationTimer) {
            clearInterval(animationTimer);
        }

        if (map.value.getLayer(baseId)) {
            map.value.removeLayer(baseId);
        }
        if (map.value.getLayer(baseId+'-dash')) {
            map.value.removeLayer(baseId+'-dash');
        }

        if (map.value.getSource(baseId)) {
            map.value.removeSource(baseId);
        }

    }, fadeDuration);
});

provide('id', props.id);
defineExpose({fit})

</script>