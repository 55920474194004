<template>
    <sefi-form-widget :errors="errors"
                      component="form-select2-autocomplete"
                      :props="{placeholder: Translator.trans('libelle.societe'), template: 'sefi', route: 'facturation.sefi.structure'}"
                      :value="computedValue"
                      @input="onInput"
    ></sefi-form-widget>
</template>

<script setup>
import Translator from "@/App/Translator";
import {computed, toRefs} from "vue";

const emit = defineEmits(['input']);
const props = defineProps({
    value: Object,
    errors: Array,
});
const {value} = toRefs(props)

const onInput = (data) => {
    if(data) {
        const split = data.id.split('_')
        emit('input', {structure: split[0], agrement: split[1], text: data.text});
    } else {
        emit('input', null);
    }
}

const computedValue = computed(() => {
    if(value.value) {
        return {id: value.value.structure + '_' + value.value.agrement, text: value.value.text};
    }

    return null;
});
</script>
