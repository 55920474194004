<template>
    <api-popup>
        <template #title>
            <span class="badge badge-light btn-amelipro mr-3">
                <img src="../../../images/logo-ameli-pro.svg">
            </span> Recherche
        </template>
        <template #content>
            <template v-if="loading">
                <div class="mt-5 d-flex align-items-center justify-content-center">
                    <b-spinner class="mr-3"></b-spinner> Chargement…
                </div>
            </template>
            <template v-else-if="result">
                <b-tabs v-model="tabIndex" align="center" pills>
                    <b-tab :title="beneficiaire.nomUsuel + ' ' + beneficiaire.prenomUsuel" v-for="(beneficiaire, i) in result" :key="i">
                        <sefi-result-table ref="table" @canReporter="canReporter[i] = $event">
                            <sefi-result-row :label="Translator.trans('libelle.numeroSecuriteSociale')" :value="beneficiaire.numeroSecuriteSociale" :target="target('numeroSecuriteSociale')"></sefi-result-row>
                            <sefi-result-row :label="Translator.trans('libelle.nomUsuel')" :value="beneficiaire.nomUsuel" :target="target('nomUsuel')"></sefi-result-row>
                            <sefi-result-row :label="Translator.trans('libelle.nomNaissance')" :value="beneficiaire.nomNaissance" :target="target('nomNaissance')"></sefi-result-row>
                            <sefi-result-row :label="Translator.trans('libelle.prenomUsuel')" :value="beneficiaire.prenomUsuel" :target="target('prenomUsuel')"></sefi-result-row>
                            <sefi-result-row :label="Translator.trans('libelle.naissance')" :value="beneficiaire.dateNaissance" :target="target('naissance')"></sefi-result-row>
                            <sefi-result-row :label="Translator.trans('libelle.rangNaissance')" :value="beneficiaire.rangNaissance" :target="target('rangNaissance')"></sefi-result-row>
                        </sefi-result-table>
                    </b-tab>
                </b-tabs>
            </template>
            <template v-else>
                <div v-if="error" class="alert alert-danger mb-5">
                    <strong>Erreur :</strong> {{ error }}
                </div>
                <sefi-form-row v-model="inputData.numeroSecuriteSociale" component="form-insee" label="Numéro de sécurité sociale" required></sefi-form-row>
            </template>
        </template>
        <template #footer>
            <b-btn variant="primary" @click="search" :disabled="!canSearch" v-if="!loading && !result" class="mr-2">
                <i class="fa-solid fa-magnifying-glass fa-fw"></i> Rechercher
            </b-btn>
            <b-btn variant="secondary" @click="result = null" v-if="result" class="mr-2">
                <i class="fa-solid fa-chevron-left fa-fw"></i> Retour
            </b-btn>
            <sefi-result-reporter v-if="result" :enabled="canReporter[tabIndex]" @click="reporter"></sefi-result-reporter>
        </template>
    </api-popup>
</template>

<script setup>
import {computed, ref, toRefs} from "vue";
import Router from "@/App/Router";
import Request from "@/App/Request";

const props = defineProps({
    source: String,
})
const canReporter = ref({});

const table = ref();
const tabIndex = ref(null);
const loading = ref(false);
const result = ref(null);
const error = ref(null);
const inputData = ref({
    structure: null,
    numeroSecuriteSociale: '',
});

if (props.source === 'patient') {
    inputData.value = {
        structure: App.Shared.getStructure(),
        numeroSecuriteSociale: $('#patient_numeroSecuriteSociale').data('assure') || $('#patient_numeroSecuriteSociale').val(),
    };
} else if (props.source === 'transport') {
    inputData.value = {
        structure: App.Shared.getStructureElement().data('societe'),
        numeroSecuriteSociale: $('#transport_patientAssure_entity_numeroSecuriteSociale').val(),
    };
}

const target = (field) => {
    if (props.source === 'transport') {
        const base = $('#transport_patientBeneficiaire_enabled').is(':checked') ? 'patientBeneficiaire' : 'patientAssure';
        return '#transport_'+base+'_entity_'+field;
    }

    return '[data-field="'+field+'"]';
}

const canSearch = computed(() => inputData.value.structure && inputData.value.numeroSecuriteSociale);

const search = () => {
    error.value = null;
    loading.value = true;

    Request.postJson(Router.generate('amelipro.search'), inputData.value).then((data) => {
        if(data.success) {
            result.value = data.data;
        } else {
            error.value = data.error;
        }

        loading.value = false;
    });
}

if (canSearch.value) {
    search();
}

const reporter = () => {
    if (tabIndex.value !== null && table.value[tabIndex.value]) {
        table.value[tabIndex.value].reporter();
    }
}
</script>
