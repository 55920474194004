<template>
    <div class="btn-group btn-group-toggle d-flex overflow-x-auto">
        <button type="button" class="btn btn-block btn-secondary btn-filter btn-filter-toggle" @click="newValues = newValues.length === Object.keys(choices).length ? [] : Object.keys(choices)">
            <i class="im-checkbox-unchecked" v-if="!newValues.length"></i>
            <i class="im-checkbox-checked" v-else-if="newValues.length === Object.keys(choices).length"></i>
            <i class="im-checkbox-partial" v-else></i>
        </button>
        <label class="btn btn-block btn-outline btn-filter form-control" v-for="label, value in choices" :class="{ 'active': newValues.includes(value) }" @dblclick="newValues = [value]">
            <input type="checkbox" :value="value" v-model="newValues"> {{ label }}
        </label>
    </div>
</template>

<script>
    export default {
        props: ['values', 'choices'],
        data: function() {
            return {newValues: this.values};
        },
        watch: {
            newValues() {
              this.$emit('values', this.newValues);
            },
            values() {
              this.newValues = this.values;
            }
        }
    }
</script>