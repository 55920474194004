<template>
    <a class="trajet-mini-block flex-1 mx-4 text-center text-dark" :class="{'disabled': disabled, 'border-info': trajetBlock && trajetBlock.id === trajet.id}" @click="select($event)" :href="trajetBlock ? '#' : editUrl">
        <div class="text-muted font-size-11px text-uppercase" :title="disabled ? 'Non visible dans la liste' : ''">{{ type }}</div>
        <div v-if="trajetBlock">
            <trajet-etat :trajet="trajetBlock"></trajet-etat>
            <span :title="trajetBlock.rendezVous.date">{{ trajetBlock.rendezVous.time }}</span>

        </div>
        <div v-else><i class="fa-solid fa-plus text-muted"></i> Ajouter</div>
    </a>
</template>

<script>
    export default {
        props: ['trajet', 'type'],
        methods: {
            select(event) {
                if(this.trajetBlock) {
                    if(!this.disabled) {
                        this.$emit('select-trajet-id', this.trajetBlock.id);
                    }

                    event.preventDefault();
                }
            }
        },
        computed: {
            disabled: function() {
                return this.trajetBlock && !(this.trajetBlock.id in this.$root.trajetIndexById);
            },
            editUrl: function() {
                return this.path('shared.transport.edit', {app: 'regulation', id: this.trajet.transport.id, addRetour: true})
            },
            trajetBlock: function() {
                if(this.type === this.trajet.type) {
                    return this.trajet;
                }

                return this.trajet[this.type];
            }
        }
    }
</script>