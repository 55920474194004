import { defineStore } from 'pinia'
import {ref, computed} from 'vue';
import Request from "@/App/Request";
import Router from "@/App/Router";

export const useCpsStore = defineStore('cps', () => {
    const init = ref(false);
    const cartes = ref([]);
    const carteDefaut = ref(null);

    const setCarteDefaut = (carte) => {
        return new Promise((resolve, reject) => {
            Request.getJson(Router.generate("cps.select", { id: carte.id })).then(
                (data) => {
                    if (data.success) {
                        carteDefaut.value = carte;
                        resolve();
                    } else {
                        reject();
                    }
                }
            );
        });
    };
    const cartesById = computed(() => cartes.value.reduce((res, item) => (res[item.id] = item, res), {}));

    const update = (cpsData) => {
        init.value = true;
        cartes.value = cpsData.carteCpsCollection;
        carteDefaut.value = cartesById.value[cpsData.carteCpsDefaut] || null;
    };

    return { init, cartes, carteDefaut, setCarteDefaut, update }
})
