<template>
    <Map ref="map" :config="mapStyle" :zoom="mapInitialZoom" :bounds="mapFallbackBounds" @click="emit('click', $event)">
        <MapSource source="poiTiles" :visible="false"></MapSource>
        <MapControlZoom></MapControlZoom>
        <Custom placement="bottom-left">
            <ButtonGroup>
                <Button class="!p-0" @click="mapStyle = style" :title="style.name" v-for="style in mapStyles">
                    <img class="w-full h-8 object-cover" :src="style.preview"/>
                </Button>
            </ButtonGroup>
        </Custom>
        <MapControlAttribution></MapControlAttribution>

        <slot></slot>
    </Map>
</template>

<script setup>
import Map from "@/Vue/Components/RegulationV2/Map/Base/Map.vue";
import MapControlZoom from "@/Vue/Components/RegulationV2/Map/Base/Control/Zoom.vue";
import MapControlAttribution from "@/Vue/Components/RegulationV2/Map/Base/Control/Attribution.vue";
import MapSource from "@/Vue/Components/RegulationV2/Map/Base/Source.vue";
import Custom from "@/Vue/Components/RegulationV2/Map/Base/Control/Custom.vue";
import Button from "@/Vue/Components/RegulationV2/Map/Base/Control/Button/Button.vue";
import ButtonGroup from "@/Vue/Components/RegulationV2/Map/Base/Control/Button/Group.vue";
import streetLightPreview from "@/../images/map/preview/street-light.png";
import satellitePreview from "@/../images/map/preview/satellite.png";
import {ref} from "vue";

const emit = defineEmits(['click']);

const mapInitialZoom = 16;
const mapFallbackBounds = [
    {lng: -15, lat: 42},
    {lng: 20, lat: 51},
];

const map = ref();

const mapStyles = {
    light: {
        name: 'Couleur',
        provider: 'tomtom',
        map: '2/basic_street-light',
        poi: '2/poi_dynamic-light',
        trafficIncidents: '2/incidents_light',
        trafficFlow: '',
        preview: streetLightPreview,
    },
    satellite: {
        name: 'Satellite',
        provider: 'tomtom',
        map: '2/basic_street-satellite',
        poi: '2/poi_dynamic-satellite',
        trafficIncidents: '2/incidents_light',
        trafficFlow: '',
        preview: satellitePreview,
    },
};

const mapStyle = ref(mapStyles.light);

const centerMap = (center) => {
    map.value.map.flyTo({
        center,
        zoom: mapInitialZoom,
        curve: 0.5,
        speed: 2,
        maxDuration: 1500,
    });
}

defineExpose({centerMap});
</script>