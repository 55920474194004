<template>
    <span>{{ choice }}</span>
</template>

<script>
    export default {
        props: ['value', 'choices'],
        computed: {
            choice() {
                const item = this.choices[this.value];

                if (typeof item === 'object') {
                    return item.label;
                }

                return item;
            }
        }
    }
</script>