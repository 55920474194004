<template>
    <td
        @dblclick="editInPlaceField = field"
        class="trajet-top position-relative text-center" :class="{'text-normal trajet-top-reel': trajet.estTermine || (topCourant !== null && index <= topCourant), 'trajet-top-gps text-normal': showTopGps, 'trajet-top-warning': isWarning, 'trajet-top-danger': isDanger}"
    >
        <span>{{ time }}</span><small v-if="delta" class="position-absolute" style="bottom: -1px;right: 4px;font-size: 75%;" :class="delta.invert ? 'text-success' : 'text-danger'">{{ delta.invert ? '-' : '+'}}{{ delta.interval }}</small>
    </td>
</template>

<script setup>
import Moment from "moment";
import {inject, toRefs, computed} from "vue";

const editInPlaceField = inject('editInPlaceField');

const props = defineProps(['trajet', 'field', 'index']);
const {trajet, field, index} = toRefs(props);

const topCourant = computed(() => trajet.value.topCourant);
const topGps = computed(() => trajet.value[field.value+'Gps']);
const showTopGps = computed(() => index.value > topCourant.value && topGps.value);
const delta = computed(() => trajet.value[field.value+'Delta']);
const value = computed(() => showTopGps.value ? topGps.value : trajet.value[field.value]);
const date = computed(() => value.value ? value.value.date : null);
const time = computed(() => value.value ? value.value.time : null);
const deltaNow = computed(() => value.value ? Moment().diff(Moment(date.value + ' ' + time.value, 'DD/MM/YYYY HH:mm'), 'minutes') : 0);
const isDanger = computed(() => showTopGps.value && deltaNow.value >= 30);
const isWarning = computed(() => showTopGps.value && !isDanger.value && deltaNow.value >= 15);
</script>
