<template>
    <div class="row">
        <div class="col-10 mx-auto">
            <table class="table table-bordered m-0">
                <tbody>
                <slot></slot>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.$on('refresh', this.refresh)
        this.refresh();
    },
    data() {
        return {
            hasReporterColumn: false,
        };
    },
    methods: {
        refresh() {
            let hasReporterColumn = false;
            let canReporter = false;

            for(const child of this.$children) {
                if (child.targetObj) {
                    hasReporterColumn = true;
                }
                if (child.canReporter) {
                    canReporter = true;
                }
            }

            this.hasReporterColumn = hasReporterColumn;
            this.$emit('canReporter', canReporter);
        },
        async reporter() {
            for(const child of this.$children) {
                await child.reporter();
            }
        }
    },
}
</script>
