<template>
    <div class="input-group">
        <template v-if="origin === 'popover'">
            <div class="input-sm d-none"></div>
            <div class="input-group-prepend">
                <span class="input-group-text">Distance</span>
            </div>
        </template>
        <form-input v-bind="$attrs" :value="value" @input="$emit('input', $event)" @change="$emit('change', $event)" @reset="$emit('reset', $event)"></form-input>
        <div class="input-group-append">
            <span class="input-group-text">km</span>
            <template v-if="trajet">
                <b-btn variant="secondary" class="d-flex align-items-center px-2" title="Distancier" :id="id">
                    <i class="fa-solid fa-fw fa-map"></i>
                </b-btn>
                <Popover :target="id" ref="popover" placement="right" class="distancier-popover">
                    <distancier-popover-content @close="popover.close" @changes="$emit('changes', $event)" :trajet="trajet"></distancier-popover-content>
                </Popover>
            </template>
        </div>
    </div>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import Popover from '@/Vue/Components/Popover';

defineOptions({
    inheritAttrs: false,
});

const instance = getCurrentInstance();

const props = defineProps({
    value: Number,
    trajet: Object,
    origin: String,
});

const id = 'distancier_'+instance.uid;
const popover = ref();

</script>