<template>
    <div>
        <Teleport to="body">
            <transition name="piece-viewer" :duration="200">
            <div class="piece-viewer" v-if="piece" @click="onClick" @mousedown="onMouseDown" ref="viewer">
                <div class="piece-viewer-container" ref="container">
                    <div class="piece-viewer-content" ref="content">
                        <div class="piece-viewer-preview">
                            <div class="piece-viewer-preview-header">
                                <h3 class="text-theme m-0">
                                    <i class="fa-solid fa-grid-2"></i>
                                    Aperçu
                                </h3>
                                <div class="d-flex pt-1 ml-3 flex-1">
                                    <div v-if="piece.document.extension !== 'PDF'">
                                        <b-btn size="xs" @click.prevent="rotate(true)" title="Effectuer une rotation anti-horaire de 90deg" :disabled="piece._loading" class="px-2">
                                            <i class="fa-solid fa-rotate-left"></i>
                                        </b-btn>
                                        <b-btn size="xs" @click.prevent="rotate()" title="Effectuer une rotation horaire de 90deg" :disabled="piece._loading" class="px-2">
                                            <i class="fa-solid fa-rotate-right"></i>
                                        </b-btn>
                                    </div>
                                    <div class="ml-auto">
                                        <span class="badge badge-secondary" :title="Translator.trans('libelle.cree-le')">
                                            <i class="fa-regular fa-fw fa-clock"></i>
                                            {{ piece.createdAt }}
                                        </span>
                                        <span class="badge badge-secondary" v-if="piece.createdBy" :title="Translator.trans('libelle.cree-par')">
                                            <i class="fa-regular fa-fw fa-user"></i>
                                            {{ piece.createdBy.text }}
                                        </span>
                                        <span class="badge badge-secondary" v-if="piece.appareil" :title="Translator.trans('libelle.appareil-emetteur')">
                                            <i class="im-mobile"></i>
                                            {{ piece.appareil.text }}
                                        </span>
                                        <span class="badge badge-secondary">
                                            <i class="fa-regular fa-fw fa-file"></i>
                                            {{ piece.document.extension }}
                                        </span>
                                            <span class="badge badge-secondary">
                                            <i class="fa-regular fa-fw fa-floppy-disk"></i>
                                            {{ piece.document.humanFilesize }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <iframe class="piece-viewer-preview-content" :src="path('shared.piece.view', {id: piece.id})" frameborder="0" :key="piece.id" v-if="piece.document.extension === 'PDF'"></iframe>
                            <div class="piece-viewer-preview-content" v-else>
                                <div class="piece-viewer-preview-image-blur" :style="'background-image: url('+path('shared.piece.view', {id: piece.id, _: imageForceRefresh})+')'"></div>
                                <div class="piece-viewer-preview-image" :style="'background-image: url('+path('shared.piece.view', {id: piece.id, _: imageForceRefresh})+')'"></div>
                            </div>
                        </div>
                        <div class="piece-viewer-side" ref="side">
                            <div class="piece-viewer-form">
                                <div class="piece-viewer-form-header">
                                    <h3 class="text-theme m-0">
                                        <i class="fa-solid fa-grid-2"></i>
                                        Pièce
                                    </h3>
                                    <div class="pb-1">
                                        <a href="#" @click.prevent="close">
                                            <i class="fa-solid fa-xmark text-muted"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="piece-viewer-form-content">
                                    <template v-if="updateUrl">
                                        <h4>Lien</h4>
                                        <template v-if="liens">
                                            <sefi-form-widget component="form-btn-group" v-model="lien" :props="{choices: liensChoices, type: 'radio', disabled: piece._loading}"></sefi-form-widget>
                                        </template>
                                        <template v-else>
                                            <form-btn-group v-model="modeLien" type="radio" :choices="modesLienChoices" :disabled="piece._loading"></form-btn-group>
                                            <sefi-form-widget ref="modeValueInput" key="patient" v-if="modeLien === 'patient'" component="form-select2-autocomplete" :props="{disabled: piece._loading, placeholder: Translator.trans('libelle.patient'), template: 'patient', route: 'shared.piece.patient.ajax', routeParams: {id: piece.id}}" v-model="lienValue"></sefi-form-widget>
                                            <sefi-form-widget ref="modeValueInput" key="factureAbstract" v-else-if="modeLien === 'factureAbstract'" component="form-select2-autocomplete" :props="{disabled: piece._loading, placeholder: Translator.trans('libelle.facture'), template: 'facture', route: 'shared.piece.facture.ajax', routeParams: {id: piece.id}}" v-model="lienValue"></sefi-form-widget>
                                            <sefi-form-widget ref="modeValueInput" key="serie" v-else-if="modeLien === 'serie'" component="form-select2-autocomplete" :props="{disabled: piece._loading, placeholder: Translator.trans('libelle.serie'), template: 'serie', route: 'shared.piece.serie.ajax', routeParams: {id: piece.id}}" v-model="lienValue"></sefi-form-widget>
                                        </template>
                                        <h4>Libellé</h4>
                                        <sefi-form-widget :errors="errors.libelle" v-model="form.libelle" :props="{readonly: piece._loading}"></sefi-form-widget>
                                        <h4>Type</h4>
                                        <template v-if="typePresetChoices">
                                            <form-btn-group v-model="form.type" type="radio" :choices="typePresetChoices[preset]" :disabled="piece._loading" v-if="preset !== null"></form-btn-group>
                                        </template>
                                        <sefi-form-widget :errors="errors.type" component="form-select2" v-model="form.type" :props="{choices: typeChoices, disabled: piece._loading}"></sefi-form-widget>

                                        <h4>Contrôle</h4>
                                        <div class="row mb-2">
                                            <div class="col-3 pr-1">
                                                <div class="piece-guide-controle">
                                                    <div class="piece-guide-controle-icon"><i class="fa-regular fa-file text-danger"></i></div>
                                                    <div class="piece-guide-controle-text">Page vide</div>
                                                </div>
                                            </div>
                                            <div class="col-3 px-1">
                                                <div class="piece-guide-controle">
                                                    <div class="piece-guide-controle-icon"><i class="fa-regular fa-clone text-danger"></i></div>
                                                    <div class="piece-guide-controle-text">Doublons</div>
                                                </div>
                                            </div>
                                            <div class="col-3 px-1">
                                                <div class="piece-guide-controle">
                                                    <div class="piece-guide-controle-icon"><i class="fa-solid fa-redo text-success"></i></div>
                                                    <div class="piece-guide-controle-text">Orientation</div>
                                                </div>
                                            </div>
                                            <div class="col-3 pl-1">
                                                <div class="piece-guide-controle">
                                                    <div class="piece-guide-controle-icon"><i class="fa-solid fa-font text-success"></i></div>
                                                    <div class="piece-guide-controle-text">Texte lisible</div>
                                                </div>
                                            </div>
                                        </div>
                                        <sefi-form-widget :errors="errors.estControle" component="form-checkbox" v-model="form.estControle" :props="{disabled: piece._loading || piece.pieceScorCollection.filter(pieceScor2 => pieceScor2.lot).length > 0}">Marquer comme contrôlé</sefi-form-widget>
                                        <template v-if="standard">
                                            <h4>SCOR</h4>
                                            <sefi-form-widget component="form-checkbox" v-model="scor" :props="{disabled: piece._loading || !form.type || !form.estControle || lien === null}">Ajouter à l'envoi SCOR</sefi-form-widget>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <template v-if="pieceScor">
                                            <template v-if="pieceScor.standard">
                                                <h4>Facture</h4>
                                                <div>{{ pieceScor.standard.numero }}</div>
                                            </template>
                                            <template v-if="pieceScor.lot">
                                                <h4>Lot SCOR</h4>
                                                <div>{{ pieceScor.lot.text }}</div>
                                            </template>
                                            <h4>Libellé</h4>
                                            <div>{{ pieceScor.piece.libelle }}</div>
                                            <h4>Type</h4>
                                            <div>{{ pieceScor.type.libelle }}</div>
                                        </template>
                                        <template v-else>
                                            <template v-if="piece.lien">
                                                <h4>Lien</h4>
                                                <a v-if="piece.lien.url" :href="piece.lien.url" target="_blank" :title="piece.lien.title" class="badge badge-block badge-secondary label-outline text-nowrap">
                                                    {{ piece.lien.text }}
                                                </a>
                                                <span v-else  :title="piece.lien.title" class="badge badge-block badge-secondary text-nowrap">
                                                    {{ piece.lien.text }}
                                                </span>
                                            </template>
                                            <h4>Libellé</h4>
                                            <div>{{ piece.libelle }}</div>
                                            <h4>Type</h4>
                                            <div>{{ piece.type ? piece.type.libelle : '-' }}</div>
                                            <h4>Contrôlé</h4>
                                            <div>{{ piece.estControle ? 'Oui' : 'Non' }}</div>
                                        </template>
                                    </template>
                                </div>
                                <div class="piece-viewer-form-action">
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <template v-if="updateUrl">
                                                <b-btn title="Annuler les modifications" variant="secondary" :disabled="piece._loading || !isModified" @click="reset()">
                                                    <i class="fa-solid fa-undo"></i>
                                                </b-btn>

                                                <b-btn title="Sauvegarder" variant="info" @click="close" :disabled="piece._loading || !isModified">
                                                    <i class="fa-solid fa-check"></i>
                                                </b-btn>
                                            </template>
                                        </div>
                                        <div>
                                            <b-btn title="Imprimer" variant="secondary" @click="print" :disabled="printLoading || piece._loading">
                                                <i class="fa-solid fa-print"></i>
                                            </b-btn>
                                            <b-btn title="Télécharger" variant="primary" :href="path('shared.piece.download', {id: piece.id})" target="_blank" :disabled="piece._loading">
                                                <i class="fa-solid fa-download"></i>
                                            </b-btn>
                                            <b-btn title="Supprimer" variant="danger" @click="remove" :disabled="piece._loading" v-if="batchDeleteUrl">
                                                <i class="fa-regular fa-trash"></i>
                                            </b-btn>
                                        </div>
                                    </div>
                                    <div class="line-height-1 mt-2 text-muted" v-if="updateUrl">
                                        <small>Les modifications sont automatiquement sauvegardés à la fermeture.</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="piece-viewer-footer" ref="footer">
                        <div></div>
                        <div>
                            <piece-pagination :piece="piece" :piece-stack="pieceStack" v-model="currentPage"></piece-pagination>
                        </div>
                        <div></div>
                    </div>
                </div>
            </div>
        </transition>
        </Teleport>
    </div>
</template>

<script setup>
import {computed, ref, nextTick, onMounted, onUnmounted, toRefs, watch} from "vue";
import swal from "sweetalert2";
import Translator from "@/App/Translator";
import Router from "@/App/Router";
import Request from "@/App/Request";

const emit = defineEmits(['piece', 'update', 'delete', 'scor'])

const props = defineProps({
    piece: Object,
    pieceStack: Array,
    updateUrl: String,
    batchDeleteUrl: String,
    types: Array,
    typePresets: Object,
    liens: Array,
    pieceScor: Object,
    standard: Object,
    structures: Array,
    index: Boolean,
});

const {piece, pieceStack, batchDeleteUrl, updateUrl, pieceScor, structures, liens, types, typePresets, index} = toRefs(props);

const modeValueInput = ref();
const viewer = ref();
const container = ref();
const footer = ref();
const content = ref();
const side = ref();

const form = ref({});
const errors = ref({});
const mousedownOutside = ref(false);
const scor = ref(null);
const modeLien = ref(null);
const liensChoices = ref([]);
const initModeLien = ref(false);
const printLoading = ref(false);
const imageForceRefresh = ref(null);

const rotate = (reverse) => {
    piece.value._loading = true;

    const params = {id: piece.value.id};

    if (reverse) {
        params.reverse = 1;
    }

    Request.getJson(Router.generate('shared.piece.rotate', params)).then((data) => {
        if(data.success) {
            imageForceRefresh.value = Date.now();
            piece.value._loading = false;
        }
    });
};

const onKeydown = (e) => {
    const $target = $(e.target);

    if (piece.value) {
        if (!e.repeat && ((!$target.is(':input') && e.key === ' ') || (e.key === 'Escape' && !$target.is('.select2-search__field')))) {
            setTimeout(() => {
                close();
            });

            e.preventDefault();
        }
        else if (e.key === 'ArrowLeft') {
            if (currentPage.value > 1) {
                currentPage.value--
            }
        } else if (e.key === 'ArrowRight') {
            if (currentPage.value < pieceStack.value.length) {
                currentPage.value++;
            }
        }
        else if(e.key === 'Enter' && $target.is(':input:not(.select2-search__field)')) {
            save();
        }
    }
};

const print = () => {
    printLoading.value = true;

    App.Shared.Piece.print(piece.value, () => {
        printLoading.value = false;
    });
};


const close = () => {
    save().then(() => {
        emit('piece', null);
    }, () => {});
};

const estControle = computed(() => {
    return form.value.estControle;
});

const reset = (initial = false) => {
    errors.value = {};
    form.value = {
        libelle: piece.value.libelle,
        type: piece.value.type ? piece.value.type.id : null,
        estControle: (initial && !index.value) || piece.value.estControle,
        patientCollection: JSON.parse(JSON.stringify(piece.value.patientCollection)),
        factureAbstractCollection: JSON.parse(JSON.stringify(piece.value.factureAbstractCollection)),
        transportCollection: JSON.parse(JSON.stringify(piece.value.transportCollection)),
        serieCollection: JSON.parse(JSON.stringify(piece.value.serieCollection)),
    };

    scor.value = pieceScor.value !== null && pieceScor.value !== undefined;

    if (liens.value) {
        liensChoices.value = liens.value.map((lien2, key) => ({...lien2, key})).filter(lien2 => !lien2.hidden || lien.value === lien2.key);
    } else {
        let newModeLien = null;
        for (const modeLien in modesLien.value) {
            if (form.value[modeLien + 'Collection'].length) {
                newModeLien = modeLien;
                break;
            }
        }
        initModeLien.value = true;
        modeLien.value = newModeLien;
    }
};

const isOutside = (event) => {
    return event.target === viewer.value || event.target === container.value || event.target === footer.value || event.target === content.value || event.target === side.value;
};

const onMouseDown = (event) => {
    if (isOutside(event)) {
        mousedownOutside.value = true
    }
};

const onClick = (event) => {
    if (mousedownOutside.value && isOutside(event)) {
        close();
    }
    mousedownOutside.value = false;
};

const save = () => {
    return new Promise((resolve, reject) => {
        errors.value = {};

        if (!updateUrl.value) {
            resolve();
            return;
        }
        if (!isFormModified.value) {
            if(isScorModified.value) {
                emit('scor', {piece: piece.value, scor: scor.value});
            }
            resolve();
            return;
        }
        if (piece.value._loading) {
            reject();
            return;
        }

        piece.value._loading = true;

        const data = {
            piece: {
                libelle: form.value.libelle,
                type: form.value.type,
                estControle: form.value.estControle,
                patientCollection: form.value.patientCollection.filter(Boolean).map(item => item.id),
                factureAbstractCollection: form.value.factureAbstractCollection.filter(Boolean).map(item => item.id),
                transportCollection: form.value.transportCollection.filter(Boolean).map(item => item.id),
                serieCollection: form.value.serieCollection.filter(Boolean).map(item => item.id),
            },
        };

        if (structures.value) {
            data.tableau_de_bord_filter = {structure: structures.value};
        }

        Request.postJson(updateUrl.value.replace('_ID_', piece.value.id), data).then((data) => {
            if(data.success) {
                const scor2 = scor.value;
                const piece2 = piece.value;
                const scorModified = isScorModified.value;
                emit('update', data);
                if(scorModified) {
                    nextTick(() => {
                        piece2.libelle = form.value.libelle; // todo...
                        piece2.estControle = form.value.estControle; // todo...
                        piece2.type = typesById.value[form.value.type]; // todo recupérer instance à jour
                        emit('scor', {piece: piece2, scor: scor2});
                        resolve();
                    })
                } else {
                    resolve();
                }
            } else {
                piece.value._loading = false;

                errors.value = data.errors;
                reject();
            }
        });
    });
};

const remove = () => {
    swal({
        title: App.Constants.LIBELLE_ETES_VOUS_SUR,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'bg-danger',
        confirmButtonText: Translator.trans('action.supprimer'),
        cancelButtonText: Translator.trans('action.annuler'),
        onOpen: () => {
            let $content = $(swal.getContent());
            $content.removeClass('swal2-content');
            $content.closest('.swal2-container').addClass('z-index-1100');
        },
    }).then((result) => {
        if (result.value) {
            piece.value._loading = true;

            Request.postJson(batchDeleteUrl.value, {
                update: {
                    pieceCollection: [piece.value.id]
                },
            }).then((data) => {
                if(data.success) {
                    emit('update', data);
                    emit('delete');
                }
            });
        }
    });
};

const typeChoices =  computed(() => {
    return types.value.reduce((res, item) => (res[item.id] = item.libelle, res), {});
});

const typesById = computed(() => {
    return types.value.reduce((res, type) => (res[type.id] = type, res), {});
});

const typePresetChoices = computed(() => {
    return Object.keys(typePresets.value).reduce((res, key) => (res[key] = typePresets.value[key].map(id => typesById.value[id]).map(type => ({key: type.id, label: type.abreviation, title: type.libelle})), res), {});
});

const modesLien = computed(() => {
    return {patient: 'Patient', factureAbstract: 'Facture', serie: 'Série', transport: 'Transport'};
});

const modesLienChoices = computed(() => {
    const modes = {patient: 'Patient', factureAbstract: 'Facture', serie: 'Série'};

    if(modeLien.value === 'transport') {
        modes.transport = 'Transport';
    }

    return modes;
});

const preset = computed(() => {
    if(liens.value) {
        return lien.value !== null ? liens.value[lien.value].type : null;
    }

    return modeLien.value;
});

const lien = computed({
    get() {
        for(const lien of liens.value) {
            const field = lien.type+'Collection';

            if(form.value[field].map(item => item.id).includes(lien.id)) {
                return liens.value.indexOf(lien);
            }
        }

        return null;
    },
    set(i) {
        const newLien = liens.value[i];

        for(const lien of liens.value) {
            const field = lien.type+'Collection';

            if(lien.type === newLien.type && lien.id === newLien.id) {
                if(!form.value[field].map(item => item.id).includes(newLien.id)) {
                    form.value[field].push({id: newLien.id});
                }
            } else {
                form.value[field] = form.value[field].filter(item => item.id !== lien.id);
            }
        }
    },
});

const lienValue = computed ({
    get() {
        if (modeLien.value) {
            return form.value[modeLien.value+'Collection'][0] || null;
        }

        return null;
    },
    set(value) {
        if (modeLien.value) {
            form.value[modeLien.value+'Collection'] = [value];
        }
    },
});

const isModified = computed(() => {
    return isFormModified.value || isScorModified.value;
});

const isScorModified = computed(() => {
    return scor.value !== (pieceScor.value !== null && pieceScor.value !== undefined);
});

const isFormModified = computed(() => {
    if(!piece.value) {
        return false;
    }

    return form.value.libelle !== piece.value.libelle ||
        form.value.estControle !== piece.value.estControle ||
        form.value.type !== (piece.value.type ? piece.value.type.id : null) ||
        JSON.stringify(form.value.patientCollection) !== JSON.stringify(piece.value.patientCollection) ||
        JSON.stringify(form.value.transportCollection) !== JSON.stringify(piece.value.transportCollection) ||
        JSON.stringify(form.value.factureAbstractCollection) !== JSON.stringify(piece.value.factureAbstractCollection) ||
        JSON.stringify(form.value.serieCollection) !== JSON.stringify(piece.value.serieCollection)
    ;
});

const currentPage = computed({
    get() {
        if(!piece.value) {
            return null;
        }

        return pieceStack.value.indexOf(piece.value) + 1;
    },
    set(page) {
        save().then(() => {
            emit('piece', page ? pieceStack.value[page - 1] : null);
        }, () => {});
    }
});

onMounted(() => {
    window.addEventListener('keydown', onKeydown);
});
onUnmounted(() => {
    window.removeEventListener('keydown', onKeydown);
});

watch(estControle, () => {
    if (scor.value && !estControle.value) {
        scor.value = false;
    }
});

watch(pieceScor, () => {
    scor.value = pieceScor.value !== null && pieceScor.value !== undefined;
});

watch(piece, (newPiece, oldPiece) => {
    document.querySelector('html').classList.toggle('piece-viewer-shown', newPiece !== null);

    if(updateUrl.value && newPiece && newPiece !== oldPiece) {
        reset(true);
    }
});

watch(modeLien, () => {
    if (initModeLien.value) {
        initModeLien.value = false;
    } else {
        for (const modeLien2 in modesLien.value) {
            if (modeLien.value !== modeLien2) {
                form.value[modeLien.value + 'Collection'] = [];
            }
        }

        setTimeout(() => {
            if (modeValueInput.value) {
                $(modeValueInput.value.$el).find('select').select2('open');
            }
        })
    }
});

</script>
