'use strict';

App.Facturation.Contrat = class {};

App.Facturation.Contrat.Form = class {
    constructor(params) {
        App.Shared.initPatient();
        App.Shared.initAppelant(params.paths.ajaxAppelant);
        App.Shared.initPrescripteur(params.paths.ajaxPrescripteur, params.form.prescripteurLink, params.form.prescripteurForm);
        App.Shared.initTypeFacture(params.form.prescripteurForm);
        App.Shared.updateCategoriePreset();

        if (params.form.structureName) {
            $('body').on('change', 'input[name="' + params.form.structureName + '"]', () => {
                App.Shared.updateCategoriePreset();
            });
        }
    }
};
