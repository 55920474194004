<template>
    <sefi-popup title="Consulter la facture en ligne" :loading="loading" :error="error" :result="result" :no-form="true" @refresh="refresh">
        <template v-slot:result v-if="result">
            <div class="row">
                <div class="col-6">
                    <table class="table table-condensed table-bordered">
                        <tbody>
                        <tr>
                            <th>Numéro</th>
                            <td>{{ result.numero }}</td>
                        </tr>
                        <tr>
                            <th>Date</th>
                            <td>{{ result.date }}</td>
                        </tr>
                        <tr>
                            <th>Montant total</th>
                            <td>{{ Euro(result.montantTouteTaxe) }}</td>
                        </tr>
                        <tr>
                            <th>Part caisse</th>
                            <td>{{ Euro(result.partCaisseMontant) }}</td>
                        </tr>
                        <tr>
                            <th>Part mutuelle</th>
                            <td>{{ Euro(result.partMutuelleMontant) }}</td>
                        </tr>
                        <tr>
                            <th>Part patient</th>
                            <td>{{ Euro(result.partPatientMontant) }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-6">
                    <table class="table table-condensed table-bordered">
                        <tbody>
                        <tr>
                            <th>Prénom</th>
                            <td>{{ result.beneficiaire.prenom }}</td>
                        </tr>
                        <tr>
                            <th>Nom</th>
                            <td>{{ result.beneficiaire.nom }}</td>
                        </tr>
                        <tr>
                            <th>Date de naissance</th>
                            <td>{{ result.beneficiaire.dateNaissance }}</td>
                        </tr>
                        <tr>
                            <th>Rang de naissance</th>
                            <td>{{ result.beneficiaire.rangNaissance }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <b-tabs align="left" pills>
                <b-tab title="Total" active>
                    <sefi-ft-calculer-montants-prestation-recapitulatif :data="total"></sefi-ft-calculer-montants-prestation-recapitulatif>
                </b-tab>
                <b-tab :title="'Trajet n°'+(index+1)" v-for="(trajet, index) in result.trajets" :key="index">
                    <sefi-ft-calculer-montants-prestation-recapitulatif :data="trajet"></sefi-ft-calculer-montants-prestation-recapitulatif>
                </b-tab>
            </b-tabs>

        </template>
    </sefi-popup>
</template>

<script setup>
import Euro from "@/Vue/Filters/Euro";
import Router from '@/App/Router';
import Request from "@/App/Request";
import {onMounted, computed, toRefs, ref} from "vue";

const props = defineProps({data: Object});
const {data} = toRefs(props);

const result = ref(null);
const loading = ref(false);
const error = ref(null);

const errors = computed(() => {
    if(error.value) {
        return error.value.errors || [];
    }

    return [];
});

const total = computed(() => {
    const total = {};

    for(const trajet of result.value.trajets) {
        for(const field in trajet) {
            if(field in total) {
                if (typeof total[field] === 'object') {
                    total[field].quantite += trajet[field].quantite;
                    total[field].montant = parseFloat(total[field].montant) + parseFloat(trajet[field].montant);
                } else {
                    total[field] = parseFloat(total[field]) + parseFloat(trajet[field]);
                }
            } else {
                if (typeof trajet[field] === 'object') {
                    total[field] = {...trajet[field]};
                } else {
                    total[field] = trajet[field];
                }
            }
        }
    }

    return total;
});

const refresh = () => {
    error.value = null;
    loading.value = true;

    Request.postJson(Router.generate('facturation.sefi.ft.consulter'), {facture: data.value.id}).then((res) => {
        if(res.success) {
            result.value = res.data;
        } else {
            error.value = res.error;
        }

        loading.value = false;
    });
};

onMounted(() => {
    refresh();
});
</script>
