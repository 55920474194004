<template>
    <template v-if="ressource._etat.section === 'disponible'">
        <small title="Temps depuis la dernière mission" v-if="ressource._trajetInfo.minTrajet && (!ressource.pausePrecedente || ressource._trajetInfo.minTrajet._topFinReel.isAfter(ressource.pausePrecedente._fin))">
            <Timer type="disponible" :value="ressource._trajetInfo.minTrajet._topFinReel"></Timer>
        </small>
        <small title="Temps depuis la dernière pause" v-if="ressource.pausePrecedente && (!ressource._trajetInfo.minTrajet || ressource.pausePrecedente._fin.isAfter(ressource._trajetInfo.minTrajet._topFinReel))">
            <Timer type="disponible" :value="ressource.pausePrecedente._fin"></Timer>
        </small>
        <span class="badge badge-secondary" title="Nombre de missions terminées">{{ ressource._trajetInfo.total }}</span>
    </template>
    <template v-else-if="ressource._etat.section === 'affecte'">
        <small title="Temps avant prochaine mission" v-if="ressource._trajetInfo.minTrajet">
            <Timer type="affecte" :value="ressource._trajetInfo.minTrajet.debutMissionPrevu"><i class="im-stopwatch"></i></Timer>
        </small>
    </template>
    <template v-else-if="ressource._etat.section === 'pause'">
        <small title="Temps écoulé depuis le début de la pause" v-if="ressource.pauseCourante">
            <Timer type="pause" :value="ressource.pauseCourante.debut"></Timer>
        </small>&nbsp;
        <TrajetEtatPause :pause="ressource.pauseCourante"></TrajetEtatPause>
    </template>
    <template v-else-if="ressource._etat.section === 'mission'">
        <TrajetEtat :trajet="ressource._trajetInfo.minTrajet"></TrajetEtat>
        <small v-if="ressource._trajetInfo.total === 1" :title="ressource._trajetInfo.minTrajet.topCourantValue.date+' '+ressource._trajetInfo.minTrajet.topCourantValue.time">
            {{ ressource._trajetInfo.minTrajet.topCourantValue.time }}&nbsp;
        </small>
        <small v-else>
            {{ ressource._trajetInfo.count }} / {{ ressource._trajetInfo.total }}&nbsp;
        </small>
    </template>
</template>

<script setup>
import Timer from "@/Vue/Components/Ressource/Timer";
import TrajetEtat from "@/Vue/Components/Trajet/Etat";
import TrajetEtatPause from "@/Vue/Components/Trajet/EtatPause";
import {useRegulationStore} from "@/Vue/Stores/Regulation";
import {toRefs} from "vue";

const props = defineProps({
    ressource: Object,
})

const {ressource} = toRefs(props);
</script>