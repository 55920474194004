import {ref} from "vue";
import Moment from "moment/moment";
import Vue from "vue";
import {defineStore} from "pinia";

export const usePlanningStore = defineStore('planning', () => {
    const personnels = ref([]);
    const eventStates = ref({});

    const personnelsJson = ref(null);

    const emptyState  = () => {
        let parsed = JSON.parse(personnelsJson.value);

        // On doit transformer certains arrays en objets pour que la sérialisation fonctionne
        for (const key in parsed) {
            Array.isArray(parsed[key].evenements) && parsed[key].evenements.length === 0 ? parsed[key].evenements = {} : null;
        }

        personnels.value = parsed;
        eventStates.value = {};
    }

    const setPublication = (data) => {
        Vue.set(personnels.value[data.user], 'publication', data.day);
        let date = Moment(data.day);
        let validation = Moment(personnels.value[data.user].validation);

        if (!validation.isValid() || validation > date) {
            Vue.set(personnels.value[data.user], 'validation', data.day);
        }
    }

    const setValidation = (data) => {
        Vue.set(personnels.value[data.user], 'validation', data.day);
        let date = Moment(data.day);
        let publication = Moment(personnels.value[data.user].publication);

        if(!publication.isValid() || publication < date) {
            Vue.set(personnels.value[data.user], 'publication', data.day);
        }
    }

    const removeEvent = (data) => {
        if (!eventStates.value[data.user]) {
            eventStates.value[data.user] = {};
        }

        if ('new' === eventStates.value[data.user][data.day]) {
            Vue.delete(eventStates.value[data.user], data.day);
        } else if (personnels.value[data.user].evenements[data.day]) {
            Vue.set(eventStates.value[data.user], data.day, 'removed');
        }

        Vue.delete(personnels.value[data.user].evenements, data.day);
    }

    const addEvent = (data) => {
        if (!eventStates.value[data.user]) {
            eventStates.value[data.user] = {};
        }

        if ((personnels.value[data.user].evenements[data.day] && 'new' === eventStates.value[data.user][data.day]) || (!personnels.value[data.user].evenements[data.day] && !eventStates.value[data.user][data.day])) {
            Vue.set(eventStates.value[data.user], data.day, 'new');
        } else {
            Vue.set(eventStates.value[data.user], data.day, 'edited');
        }

        Vue.set(personnels.value[data.user].evenements, data.day, data.value);
    }

    const editEvent = (data) => {
        if (!eventStates.value[data.user]) {
            eventStates.value[data.user] = {};
        }
        
        if ('new' !== eventStates.value[data.user][data.day]) {
            Vue.set(eventStates.value[data.user], data.day, 'edited');
        }
    }

    const cutEvent = (data) => {
        Vue.delete(personnels.value[data.user].evenements, data.day);

        if (!eventStates.value[data.user]) {
            eventStates.value[data.user] = {};
        }

        if ('new' === eventStates.value[data.user][data.day]) {
            Vue.set(eventStates.value[data.user], data.day, 'tempCut');
        } else {
            Vue.set(eventStates.value[data.user], data.day, 'cut');
        }
    }

    const pasteEvent = (data) => {
        Vue.set(personnels.value[data.user].evenements, data.day, data.value);

        if (!eventStates.value[data.user]) {
            eventStates.value[data.user] = {};
        }

        if ('removed' === eventStates.value[data.user][data.day]) {
            Vue.set(eventStates.value[data.user], data.day, 'edited');
        } else {
            Vue.set(eventStates.value[data.user], data.day, 'new');
        }

        if (eventStates.value[data.oldUser]) {
            if ('cut' === eventStates.value[data.oldUser][data.oldDay]) {
                Vue.set(eventStates.value[data.oldUser], data.oldDay, 'removed');
            } else if ('tempCut' === eventStates.value[data.oldUser][data.oldDay]) {
                Vue.delete(eventStates.value[data.oldUser], data.oldDay);
            }
        }
    }

    return {eventStates, personnels, personnelsJson, emptyState, setPublication, setValidation, removeEvent, addEvent, editEvent, cutEvent, pasteEvent}
});