<template>
    <div class="tlt" v-b-visible="onVisibility" :style="{'--tlt-color': tailwind ? '#6366f1' : '#01c0c8'}">
        <div ref="timeline" class="tlt-events" :style="{'min-height': detailHeight+'px'}">
            <a ref="eventLink" href="#" class="js-timeline-event tlt-event" :class="{'!text-sm !py-2 !flex items-center group': tailwind, 'current': +eventIndex === data.currentEventIndex, 'passed': null !== data.currentEventIndex && eventIndex < data.currentEventIndex}" v-for="(event, eventIndex) in data.events" :key="eventIndex" @click.prevent="focusEventIndex = eventIndex">
                <span :class="tailwind ? ['rounded-md px-2 py-1 font-medium w-[60px] text-xs mr-2 flex items-center transition-colors', eventIndex === activeEventIndex ? 'bg-indigo-500 text-white' : 'bg-gray-100 group-hover:bg-gray-200'] : ['badge d-inline-flex width-50px pl-2 pr-1 mr-2', eventIndex === activeEventIndex ? 'badge-secondary': 'badge-light']">
                    <i :class="event.icon"></i>
                    <span class="flex-1 text-center">{{ Translator.trans(event.abreviation) }}</span>
                </span>
                 {{ event.time.substring(0, 5) }}
            </a>
        </div>
        <div class="tlt-line"><span class="fill" :style="'transform: scaleY('+currentEventPosition+')'"></span></div>
        <div ref="eventDetail" class="tlt-detail" :class="{'rounded-lg !bg-gray-100 !text-sm': tailwind}" v-if="activeEvent" :style="{'--arrow-position': arrowPosition !== null ? arrowPosition+'px' : undefined, top: detailPosition !== null ? detailPosition + 'px' : undefined}">
            <h6 :class="{'rounded-t-lg !bg-transparent !py-1 !font-medium': tailwind}">{{ Translator.trans(activeEvent.libelle) }}</h6>
            <div class="bg-white rounded-t-xl rounded-b-lg">
                <div class="px-2" :class="{'gap-y-1 flex flex-col py-2': tailwind}" v-if="(activeEvent.prevu || activeEvent.reel) || !activeEvent.statuts.length">
                    <template v-if="activeEvent.prevu || activeEvent.reel">
                        <div v-if="activeEvent.prevu" :class="tailwind ? 'flex' : 'd-flex my-2'">
                            <span :class="tailwind ? 'rounded-md bg-gray-200 text-gray-700 mr-2 px-2 font-medium w-[45px] text-center' : 'badge badge-secondary width-50px mr-2'">Prévu</span>
                            <span :title="activeEvent.prevu.date+' '+activeEvent.prevu.time">{{ activeEvent.prevu.time }}</span>
                        </div>
                        <div v-if="activeEvent.reel" :class="tailwind ? 'flex' : 'd-flex my-2'">
                            <span :class="tailwind ? 'rounded-md bg-green-500 text-white mr-2 px-2 font-medium w-[45px] text-center' : 'badge badge-success width-50px mr-2'">Réel</span>
                            <span :title="activeEvent.reel.date+' '+activeEvent.reel.time">{{ activeEvent.reel.time }}</span>
                        </div>
                    </template>
                    <div v-else-if="!activeEvent.statuts.length" :class="tailwind ? 'flex' : 'd-flex my-2'">
                        <span :class="tailwind ? 'rounded-md bg-green-500 text-white mr-2 px-2 font-medium text-center' : 'badge badge-success mr-2'">Réel</span>
                        <span :title="activeEvent.date+' '+activeEvent.time">{{ activeEvent.time }}</span>
                    </div>
                </div>
                <template v-if="activeEvent.statuts.length">
                    <template v-if="activeEvent.prevu || activeEvent.reel">
                        <div class="px-2 -mt-1" v-if="tailwind">
                            <Section title="Statuts"></Section>
                        </div>
                        <h5 v-else>Statuts</h5>
                    </template>
                    <div class="px-2" :class="{'gap-y-1 flex flex-col py-2': tailwind}">
                        <div v-for="statut in activeEvent.statuts" :class="tailwind ? 'flex' : 'd-flex my-2'">
                            <template v-if="statut.appareil">
                                <span :class="tailwind ? 'rounded-l-md bg-gray-200 text-gray-700 pl-1 pr-0.5' : 'badge badge-light'" v-if="statut.source === 'elisa'" :title="'Top Elisa Mobile (via '+statut.appareil.text+')'">
                                    <i class="fa-regular fa-fw fa-mobile"></i>
                                </span>
                                <span :class="tailwind ? 'rounded-l-md bg-gray-200 text-gray-700 pl-1 pr-0.5' : 'badge badge-light'" v-else-if="statut.source === 'gps'" :title="'Top GPS (via '+statut.appareil.text+')'">
                                    <i class="fa-solid fa-fw fa-location-arrow"></i>
                                </span>
                            </template>
                            <span v-if="statut.personnel" :class="tailwind ? (statut.appareil ? (statut.vehicule ? '' : 'rounded-r-md') : 'rounded-md')+' text-white px-2 font-medium text-center' : 'badge badge-dark'" :style="{background: statut.personnel.couleur}" :title="statut.personnel.text">
                                {{ statut.personnel.shortText }}
                            </span>
                            <span v-if="statut.vehicule" :class="tailwind ? (statut.appareil ? 'rounded-r-md' : 'rounded-md')+' text-white px-2 font-medium text-center' : 'badge badge-dark'" :style="{background: statut.vehicule.couleur}" :title="statut.vehicule.text">
                                {{ statut.vehicule.shortText }}
                            </span>
                            <span :title="'Top : '+statut.top.date+' '+statut.top.time+'\nReçu : '+statut.horodatage.date+' '+statut.horodatage.time" class="ml-2">{{ statut.top.time }}</span>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup>
import Translator from "@/App/Translator";
import {computed, nextTick, ref, toRefs, watch} from "vue";
import Section from "@/Vue/Components/RegulationV2/Layout/Section.vue";

const props = defineProps({
    data: Object,
    tailwind: Boolean,
});

const {data} = toRefs(props);

const timeline = ref();
const eventLink = ref();
const eventDetail = ref();

const focusEventIndex = ref(null);

const activeEventIndex = computed(() => {
    if (focusEventIndex.value !== null) {
        return focusEventIndex.value;
    }

    if (null !== data.value.currentEventIndex) {
        return data.value.currentEventIndex;
    }

    return null;
});
const activeEvent = computed(() => {
    if (activeEventIndex.value !== null) {
        return data.value.events[activeEventIndex.value];
    }

    return null;
});

const currentEventIndex = computed(() => data.value.currentEventIndex);

const currentEventPosition = computed(() => {
    if (data.value.currentEventIndex !== null) {
        const count = Object.keys(data.value.events).length;
        const x = data.value.currentEventIndex + 1;

        return (x - ((x >= 1 && x !== count) ? .5 : 0)) / count;
    }

    return 0;
});

const activeEventElement = computed(() => {
    if (eventLink.value && activeEventIndex.value !== null) {
        return eventLink.value[activeEventIndex.value];
    }

    return null;
});

const detailPosition = ref(null);
const detailHeight = ref(0);

const update = () => {
    if (activeEventElement.value) {
        nextTick(() => {
            detailHeight.value = $(eventDetail.value).outerHeight();
            const timelineHeight = $(timeline.value).height();

            let position = $(activeEventElement.value).position().top + 13 - detailHeight.value / 2;

            if (position + detailHeight.value > timelineHeight) {
                position = timelineHeight - detailHeight.value;
            }

            if (position < 0) {
                position = 0;
            }

            detailPosition.value = position;
        });
    } else {
        detailPosition.value = null;
        detailHeight.value = 0;
    }
}

const onVisibility = (isVisible) => {
    if (isVisible) {
        update();
    }
}

watch(activeEventElement, () => {
    update();
});

const trajetId = computed(() => data.value.id);

watch([trajetId, currentEventIndex], () => {
    focusEventIndex.value = null;
});

watch(data, () => {
    update();
});


const arrowPosition = computed(() => {
    if (null !== detailPosition.value && activeEventElement.value) {
        return $(activeEventElement.value).position().top - detailPosition.value + 9;
    }

    return null;
});

</script>
