<template>
    <button @click="emit('click', $event)" type="button" class="py-3 px-3 md:px-4 text-center font-medium bg-opacity-70 hover:bg-opacity-70 bg-white dark:bg-black dark:bg-opacity-30 hover:bg-gray-50 dark:hover:bg-black/50 text-gray-600 dark:text-white/80 flex-auto relative transition" :class="{'text-indigo-900': active, '': standalone, 'pb-5': !standalone && !bottom, 'pt-5': !standalone && bottom}">
        <slot></slot>
        <span aria-hidden="true" class="absolute inset-x-0 bg-indigo-500" :class="{'animate-pulse': loading, 'top-0': bottom, 'bottom-0': !bottom, 'rounded-l-lg': left, 'rounded-r-lg': right, 'h-0.5': standalone, 'h-3': !standalone}" v-if="active"></span>
        <NotificationBadge v-if="badge" absolute>{{ badge }}</NotificationBadge>
    </button>
</template>

<script setup>
import NotificationBadge from '@/Vue/Components/RegulationV2/Layout/NotificationBadge';

const emit = defineEmits(['click']);
const props = defineProps({
    active: Boolean,
    loading: Boolean,
    left: Boolean,
    right: Boolean,
    bottom: Boolean,
    standalone: {type: Boolean, default: true},
    badge: Number,
});

</script>