<template>
    <sefi-popup title="Lister les bénéficiaires" :loading="loading" :error="error" :result="result" :init-form="true" @refresh="refresh">
        <template v-slot:initForm>
            <div class="mx-5">
                <sefi-form-row v-if="!source" v-model="inputData.structure" :errors="errors.structure" component="sefi-form-structure" label="Société" required></sefi-form-row>
                <sefi-form-row v-model="inputData.numeroSecuriteSociale" :errors="errors.numeroSecuriteSociale" component="form-insee" label="Numéro de sécurité sociale" required></sefi-form-row>
                <sefi-form-row v-if="source !== 'transportForm'" component="form-btn-group" :props="{type: 'single_checkbox', choices: ['Avec état civil']}" v-model="inputData.full" :errors="errors.full" label="Option"></sefi-form-row>
            </div>
        </template>
        <template v-slot:initFormButtons>
            <b-btn variant="primary" @click="refresh">
                <i class="fa-solid fa-magnifying-glass fa-fw"></i> Rechercher
            </b-btn>
        </template>
        <template v-slot:form>
            <div class="mb-3" v-if="!source">
                <sefi-form-widget component="sefi-form-structure" v-model="inputData.structure" :errors="errors.type"></sefi-form-widget>
            </div>
            <div class="mb-3">
                <sefi-form-widget component="form-input-group" v-model="inputData.numeroSecuriteSociale" :props="{label: 'NIR', component: 'form-insee'}" :errors="errors.numeroSecuriteSociale"></sefi-form-widget>
            </div>
            <div v-if="source !== 'transportForm'">
                <sefi-form-widget component="form-btn-group" v-model="inputData.full" :props="{type: 'single_checkbox', choices: ['Avec état civil']}" :errors="errors.full"></sefi-form-widget>
            </div>
        </template>
        <template v-slot:result>
            <b-tabs v-if="source === 'transportForm'" v-model="tabIndex" align="center" pills>
                <b-tab :title="beneficiaire.etatCivil.nomUsuel + ' ' + beneficiaire.etatCivil.prenom" v-for="(beneficiaire, i) in result" :key="i">
                    <sefi-result-table ref="table" @canReporter="canReporter[i] = $event">
                        <sefi-result-row :label="Translator.trans('libelle.numeroSecuriteSociale')" :value="beneficiaire.numeroSecuriteSociale" :target="target('numeroSecuriteSociale')"></sefi-result-row>
                        <sefi-result-row :label="Translator.trans('libelle.nomUsuel')" :value="beneficiaire.etatCivil.nomUsuel" :target="target('nomUsuel')"></sefi-result-row>
                        <sefi-result-row :label="Translator.trans('libelle.nomNaissance')" :value="beneficiaire.etatCivil.nomNaissance" :target="target('nomNaissance')"></sefi-result-row>
                        <sefi-result-row :label="Translator.trans('libelle.prenomUsuel')" :value="beneficiaire.etatCivil.prenom" :target="target('prenomUsuel')"></sefi-result-row>
                        <sefi-result-row :label="Translator.trans('libelle.naissance')" :value="beneficiaire.dateNaissance" :target="target('naissance')"></sefi-result-row>
                        <sefi-result-row :label="Translator.trans('libelle.rangNaissance')" :value="beneficiaire.rangNaissance" :target="target('rangNaissance')"></sefi-result-row>
                        <sefi-result-row :label="Translator.trans('libelle.caisse')" v-if="beneficiaire.etatCivil.caisse" :value="beneficiaire.etatCivil.caisse" target="#transport_patientAssure_entity_caisse">
                            <div v-html="beneficiaire.etatCivil.caisse.card"></div>
                        </sefi-result-row>
                    </sefi-result-table>
                </b-tab>
            </b-tabs>
            <b-table v-else class="table-compact" responsive no-sort-reset show-empty bordered striped :items="result" :fields="fields" sort-by="numero">
                <template #empty="scope">
                    <span class="text-muted">{{ Translator.trans('libelle.aucun-element') }}</span>
                </template>
                <template #cell(etatCivil.caisse)="{value}">
                    <a :href="Router.generate('referentiel.organisme.caisse.view', {id: value.id})">
                        {{ value.text }}
                    </a>
                </template>
                <template #cell(action)="{item}">
                    <span class="text-nowrap">
                        <b-btn type="button" variant="outline-success" :title="Translator.trans('action.creer-patient')" class="btn-table" target="_blank" :href="newPatientPath(item)">
                            <i class="fa-solid fa-fw fa-plus"></i>
                        </b-btn>
                        <b-btn v-if="!hasEtatCivil" variant="primary" :title="Translator.trans('action.rechercher-etat-civil')" class="btn-table" @click="rechercherEtatCivil(item)">
                            <i class="fa-solid fa-fw fa-magnifying-glass"></i>
                        </b-btn>
                    </span>
                </template>
            </b-table>
        </template>
        <template v-slot:resultButtons v-if="result">
            <sefi-result-reporter v-if="source === 'transportForm'" :enabled="canReporter" @click="reporter"></sefi-result-reporter>
        </template>
    </sefi-popup>
</template>

<script setup>
import Moment from "moment";
import Translator from "@/App/Translator";
import {onMounted, ref, computed, toRefs} from "vue";
import Request from "@/App/Request";
import Router from "@/App/Router";

const props = defineProps({
    data: Object,
});
const {data} = toRefs(props);
const result = ref(null);
const inputData = ref({});
const loading = ref(false);
const error = ref(null);
const canReporter = ref({});
const tabIndex = ref(null);
const table = ref();

const source = computed(() => data.value && data.value.source ? data.value.source : null);
const errors = computed(() => {
    if (error.value) {
        return error.value.errors || [];
    }

    return [];
});
const hasEtatCivil = computed(() => result.value && result.value[0] && result.value[0].etatCivil);

const fields = computed(() => [
    {key: 'prenom', label: Translator.trans('libelle.prenom'), sortable: true},
    hasEtatCivil.value ? {key: 'etatCivil.nomUsuel', label: Translator.trans('libelle.nomUsuel'), sortable: true} : null,
    hasEtatCivil.value ? {key: 'etatCivil.nomNaissance', label: Translator.trans('libelle.nomNaissance'), sortable: true} : null,
    {key: 'dateNaissance', label: Translator.trans('libelle.naissance'), sortable: true},
    {key: 'rangNaissance', label: Translator.trans('libelle.rang'), sortable: true},
    hasEtatCivil.value ? {key: 'etatCivil.caisse', label: Translator.trans('libelle.caisse'), sortable: true} : null,
    {key: 'action', label: Translator.trans('libelle.actions'), thClass: 'width-1px text-center', tdClass: 'text-center text-nowrap'},
]);

const newPatientPath = (item) => {
    const params = {app: App.Constants.APPLICATION};

    if(data.value && data.value.assureId) {
        params.type = 'beneficiaire';
        params.assure = data.value.assureId;
    } else {
        params.type = 'assure';
    }

    params.numeroSecuriteSociale = inputData.value.numeroSecuriteSociale;
    params.prenomUsuel = item.prenom;
    params.naissance = item.dateNaissance;
    params.rangNaissance = item.rangNaissance;

    if(item.etatCivil) {
        params.nomUsuel = item.etatCivil.nomUsuel;
        params.nomNaissance = item.etatCivil.nomNaissance;
        if(item.etatCivil.caisse && params.type === 'assure') {
            params.caisse = item.etatCivil.caisse.id;
        }
    }

    return Router.generate('shared.patient.new', params);
}
const refresh = () => {
    error.value = null;
    loading.value = true;

    Request.postJson(Router.generate('facturation.sefi.bs.lister-beneficiaires'), inputData.value).then((data) => {
        if(data.success) {
            result.value = data.data;
        } else {
            error.value = data.error;
        }

        loading.value = false;
    });
}
const rechercherEtatCivil = (beneficiaire) => {
    App.Sefi.openRequestSwal('bs', 'rechercherEtatCivil', {
        source: 'patientView',
        inputData: {
            structure: inputData.value.structure,
            numeroSecuriteSociale: inputData.value.numeroSecuriteSociale,
            dateNaissance: beneficiaire.dateNaissance,
            rangNaissance: beneficiaire.rangNaissance,
            dateEvaluation: Moment().format('DD/MM/YYYY'),
        }
    })
}
const target = (field) => {
    if (source.value === 'transportForm') {
        const base = $('#transport_patientBeneficiaire_enabled').is(':checked') ? 'patientBeneficiaire' : 'patientAssure';
        return '#transport_'+base+'_entity_'+field;
    }

    return '[data-field="'+field+'"]';
}
const reporter = () => {
    if (tabIndex.value !== null && table.value[tabIndex.value]) {
        table.value[tabIndex.value].reporter();
    }
}

onMounted(() => {
    if (data.value) {
        if (data.value.inputData) {
            inputData.value = data.value.inputData;
        }
        if (source.value === 'transportForm') {
            inputData.value = {
                structure: {structure: App.Shared.getStructureElement().data('societe')},
                numeroSecuriteSociale: $('#transport_patientAssure_entity_numeroSecuriteSociale').val(),
                full: true,
            };
        }
    }

    if(inputData.value.structure && inputData.value.numeroSecuriteSociale) {
        refresh();
    }
});
</script>
