'use strict';

App.Facturation.Modele = class {};

App.Facturation.Modele.Form = class {
    constructor(params) {
        App.Facturation.initLignes(params.ligneAbstractCollection, true);
    }
};




