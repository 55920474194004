<template>
    <MapSource source="poiTiles" v-model:activeFeature="activeFeature" :selectable="true" :visible="mapLayers.poi.length > 0" :filter="mapLayers.poi.length > 0 ? {POI: ['in', ['get', 'category'], ['literal', mapLayers.poi]]} : undefined">
        <template #activeFeature="{feature, image}">
            <MapPin :lng-lat="feature.geometry.coordinates" shape="circle" variant="white" :pulse="true" :popup="true" :clickable="true">
                <template #content>
                    <img :src="image" class="absolute w-[34px] -top-[3px] max-w-none"/>
                </template>
                <MapPopup>
                    <Popup width="275px" @close="activeFeature = null">
                        <template #headerLeft>
                            <span class="px-1 rounded-md mr-1 text-white bg-indigo-500 text-white">POI</span>
                            {{ feature.properties.name }}
                        </template>
                        <template #footerRight>
                            <div class="px-1 flex items-center justify-end">
                                <ItineraireButton></ItineraireButton>
                            </div>
                        </template>
                        <div class="p-2">
                            <div class="flex flex-col gap-2">
                                <PopupItem label="Catégorie">
                                    <i :class="mapPoisCategories[feature.properties.category].icon"></i>
                                    {{ mapPoisCategories[feature.properties.category].label }}
                                </PopupItem>
                                <PopupLocation :lngLat="feature.geometry.coordinates"></PopupLocation>
                            </div>
                        </div>
                    </Popup>
                </MapPopup>
            </MapPin>
        </template>
    </MapSource>
</template>

<script setup>
import MapPopup from "@/Vue/Components/RegulationV2/Map/Base/Popup.vue";
import MapSource from "@/Vue/Components/RegulationV2/Map/Base/Source.vue";
import PopupItem from "@/Vue/Components/RegulationV2/Layout/Popup/Item.vue";
import MapPin from "@/Vue/Components/RegulationV2/Map/Base/Pin.vue";
import Popup from "@/Vue/Components/RegulationV2/Layout/Popup.vue";
import PopupLocation from "@/Vue/Components/RegulationV2/Layout/Popup/Location.vue";
import {useGeolocalisationStore} from "@/Vue/Stores/RegulationV2/Geolocalisation";
import {storeToRefs} from "pinia";
import {ref} from "vue";
import ItineraireButton from "@/Vue/Components/RegulationV2/Layout/Popup/ItineraireButton.vue";

const geolocalisationStore = useGeolocalisationStore();
const {mapLayers} = storeToRefs(geolocalisationStore)
const {mapPoisCategories} = geolocalisationStore;

const activeFeature = ref(null);
</script>