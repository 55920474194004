<template>
    <sefi-popup title="Consulter la prise en charge" :loading="loading" :error="error" :result="result" :init-form="true" @refresh="refresh">
        <template v-slot:initForm>
            <div class="mx-5">
                <sefi-form-row v-if="!source" v-model="inputData.structure" :errors="errors.structure" component="sefi-form-structure" label="Société" required></sefi-form-row>
                <sefi-form-row v-model="inputData.codeRegime" :errors="errors.codeRegime" label="Code régime" required></sefi-form-row>
                <sefi-form-row v-model="inputData.numeroSecuriteSociale" :errors="errors.numeroSecuriteSociale" component="form-insee" label="Numéro de sécurité sociale" required></sefi-form-row>
                <sefi-form-row v-model="inputData.dateNaissance" :errors="errors.dateNaissance" component="form-date" :props="{picker: true}" label="Date de naissance" required></sefi-form-row>
                <sefi-form-row v-model="inputData.rangNaissance" :errors="errors.rangNaissance" label="Rang de naissance" required></sefi-form-row>
                <sefi-form-row v-model="inputData.modeTransport" :errors="errors.type" :props="{type: 'radio', choices: modes}" component="form-btn-group" label="Mode de transport" required></sefi-form-row>
                <sefi-form-row v-model="inputData.motifs" :errors="errors.motifs" :props="{type: 'checkbox', choices: motifs}" component="form-btn-group" label="Motifs"></sefi-form-row>
                <sefi-form-row v-model="inputData.dateRealisation" :errors="errors.dateRealisation" component="form-date" :props="{picker: true}" label="Date de réalisation" required></sefi-form-row>
                <sefi-form-row v-model="inputData.datePrescription" :errors="errors.datePrescription" component="form-date" :props="{picker: true}" label="Date de prescription" required></sefi-form-row>
                <sefi-form-row v-model="inputData.dateAccidentTravail" :errors="errors.dateAccidentTravail" component="form-date" :props="{picker: true}" label="Date d'accident du travail"></sefi-form-row>
                <sefi-form-row v-model="inputData.numeroAccidentTravail" :errors="errors.numeroAccidentTravail" label="Numéro d'accident du travail"></sefi-form-row>
            </div>
        </template>
        <template v-slot:initFormButtons>
            <b-btn variant="primary" @click="refresh">
                <i class="fa-solid fa-magnifying-glass fa-fw"></i> Rechercher
            </b-btn>
        </template>
        <template v-slot:resultButtons v-if="result">
            <sefi-result-reporter :enabled="canReporter" @click="reporter"></sefi-result-reporter>
        </template>
        <template v-slot:form>
            <div class="mb-2" v-if="!source">
                <sefi-form-widget component="sefi-form-structure" v-model="inputData.structure" :errors="errors.type"></sefi-form-widget>
            </div>
            <div class="mb-2">
                <sefi-form-widget component="form-input-group" v-model="inputData.codeRegime" :props="{label: 'Code régime'}" :errors="errors.codeRegime"></sefi-form-widget>
            </div>
            <div class="mb-2">
                <sefi-form-widget component="form-input-group" v-model="inputData.numeroSecuriteSociale" :props="{label: 'NIR', component: 'form-insee'}" :errors="errors.numeroSecuriteSociale"></sefi-form-widget>
            </div>
            <div class="mb-2">
                <sefi-form-widget component="form-input-group" v-model="inputData.dateNaissance" :props="{label: 'Date naissance', component: 'form-date', picker: true, lunaire: true}" :errors="errors.dateNaissance"></sefi-form-widget>
            </div>
            <div class="mb-2">
                <sefi-form-widget component="form-input-group" v-model="inputData.rangNaissance" :props="{label: 'Rang naissance'}" :errors="errors.rangNaissance"></sefi-form-widget>
            </div>
            <div class="mb-2">
                <sefi-form-widget component="form-btn-group" v-model="inputData.modeTransport" :props="{type: 'radio', choices: modes}" :errors="errors.modeTransport"></sefi-form-widget>
            </div>
            <div class="mb-2">
                <sefi-form-widget component="form-btn-group" v-model="inputData.motifs" :props="{type: 'checkbox', choices: motifs}" :errors="errors.motifs"></sefi-form-widget>
            </div>
            <div class="mb-2">
                <sefi-form-widget component="form-input-group" v-model="inputData.dateRealisation" :props="{label: 'Date réalisation', component: 'form-date', picker: true}" :errors="errors.dateRealisation"></sefi-form-widget>
            </div>
            <div class="mb-2">
                <sefi-form-widget component="form-input-group" v-model="inputData.datePrescription" :props="{label: 'Date prescription', component: 'form-date', picker: true}" :errors="errors.datePrescription"></sefi-form-widget>
            </div>
            <div class="mb-2">
                <sefi-form-widget component="form-input-group" v-model="inputData.dateAccidentTravail" :props="{label: 'Date AT', component: 'form-date', picker: true}" :errors="errors.dateAccidentTravail"></sefi-form-widget>
            </div>
            <div>
                <sefi-form-widget component="form-input-group" v-model="inputData.numeroAccidentTravail" :props="{label: 'N° AT'}" :errors="errors.numeroAccidentTravail"></sefi-form-widget>
            </div>
        </template>
        <template v-slot:result v-if="result">
            <sefi-result-table ref="table" @canReporter="canReporter = $event">
                <tr>
                    <th colspan="3" class="bg-light py-1 text-center">AMO</th>
                </tr>
                <sefi-result-row :label="Translator.trans('libelle.contrat')" :value="result.caisse.contrat"></sefi-result-row>
                <sefi-result-row :label="Translator.trans('libelle.tiersPayantReglementaireAbreviation')" :title="Translator.trans('libelle.tiersPayantReglementaire')" :value="result.caisse.tiersPayant">
                    {{ result.caisse.tiersPayant ? 'Oui ': 'Non' }}
                </sefi-result-row>
                <sefi-result-row v-if="result.dateMaternite" :label="Translator.trans('libelle.dateMaternite')" :value="result.dateMaternite" :target="target('dateMaternite')"></sefi-result-row>
                <sefi-result-row :label="Translator.trans('libelle.nature')" :value="result.nature" :target="target('nature')">
                    {{ result.nature }} ({{ result.natureSefi }})
                </sefi-result-row>
                <sefi-result-row :label="Translator.trans('libelle.exoneration')" :value="result.exoneration" :target="target('exoneration')"></sefi-result-row>
                <sefi-result-row :label="Translator.trans('libelle.caisse')" v-if="result.caisse.entity" :value="result.caisse.entity" :target="target('caisse')">
                    <div v-html="result.caisse.entity.card"></div>
                </sefi-result-row>
                <sefi-result-row :label="Translator.trans('libelle.tauxCaisse')" :value="result.caisse.taux" :target="target('tauxCaisse')"></sefi-result-row>
                <template v-if="result.mutuelle">
                    <tr>
                        <th colspan="3" class="bg-light py-1 text-center">AMC</th>
                    </tr>
                    <sefi-result-row :label="Translator.trans('libelle.contrat')" :value="result.mutuelle.contrat"></sefi-result-row>
                    <sefi-result-row :label="Translator.trans('libelle.tiersPayantReglementaireAbreviation')" :title="Translator.trans('libelle.tiersPayantReglementaire')" :value="result.mutuelle.tiersPayant">
                        {{ result.mutuelle.tiersPayant ? 'Oui ': 'Non' }}
                    </sefi-result-row>
                    <sefi-result-row :label="Translator.trans('libelle.mutuelle')" v-if="result.mutuelle.entity" :value="result.mutuelle.entity" :target="target('mutuelle')">
                        <div v-html="result.mutuelle.entity.card"></div>
                    </sefi-result-row>
                </template>
            </sefi-result-table>
        </template>
    </sefi-popup>
</template>

<script>
import Moment from "moment";

export default {
    components: {},

    props: ['data'],
    data() {
        return {
            result: null,
            canReporter: null,
            inputData: {
                rangNaissance: '1',
                motifs: [],
            },
            loading: false,
            error: null,
            motifs: [
                {key: 'ALD', label: 'ALD'},
                {key: 'L115', label: 'L115'},
                {key: 'CDP100', label: 'CDP100'},
                {key: 'AT', label: 'AT'},
            ],
            modes: [
                {key: 'ABA', label: 'Ambu'},
                {key: 'VSL', label: 'VSL'},
                {key: 'ABA/VSL', label: 'Ambu / VSL'},
                {key: 'TXI', label: 'Taxi'},
            ],
        };
    },
    mounted() {
        if(this.data) {
            if (this.data.inputData) {
                this.inputData = this.data.inputData;
            }
            if (this.source === 'patientForm') {
                const codeRegime = ($('[data-field="assure"], [data-field="caisse"]').data('custom') || {}).codeRegime || '01';

                this.inputData = {
                    structure: {structure: App.Shared.getStructure()},
                    codeRegime,
                    numeroSecuriteSociale: $('#patient_numeroSecuriteSociale').data('assure') || $('#patient_numeroSecuriteSociale').val(),
                    dateNaissance: $('#patient_naissance').val(),
                    rangNaissance: $('#patient_rangNaissance').val() || 1,
                    dateRealisation: Moment().format('DD/MM/YYYY'),
                    datePrescription: Moment().format('DD/MM/YYYY'),
                    modeTransport: 'ABA/VSL',
                    motifs: [], // TODO
                };
            }
            else if (this.source === 'transportForm') {
                const codeRegime = ($('[data-field="caisse"]').data('custom') || {}).codeRegime || '01';
                const tarification = $('.js-tarification input:checked').val();
                let modeTransport = 'ABA/VSL';
                switch (tarification) {
                    case '1':
                        modeTransport = 'ABA';
                        break;
                    case '2':
                        modeTransport = 'VSL';
                        break;
                    case '3':
                        modeTransport = 'TXI'
                        break;
                }

                const exoneration = $('#transport_patientBeneficiaire_entity_exoneration').val() || $('#transport_patientAssure_entity_exoneration').val();
                const motifs = [];

                if (exoneration === '4') {
                    motifs.push('ALD');
                }

                if (($('#transport_patientBeneficiaire_entity_tauxCaisse').val() || $('#transport_patientAssure_entity_tauxCaisse').val()) === '100') {
                    motifs.push('CDP100');
                }

                this.inputData = {
                    structure: {structure: App.Shared.getStructureElement().data('societe')},
                    codeRegime,
                    numeroSecuriteSociale: $('#transport_patientAssure_entity_numeroSecuriteSociale').val(),
                    dateNaissance: $('#transport_patientBeneficiaire_entity_naissance').val() || $('#transport_patientAssure_entity_naissance').val(),
                    rangNaissance: $('#transport_patientBeneficiaire_entity_rangNaissance').val() || $('#transport_patientAssure_entity_rangNaissance').val() || 1,
                    dateRealisation: $('#transport_trajetAller_rendezVous_date').val() || Moment().format('DD/MM/YYYY'),
                    datePrescription: $('#transport_prescription').val() || Moment().format('DD/MM/YYYY'),
                    modeTransport,
                    motifs,
                };
            }
            else if (this.source === 'factureForm') {
                const codeRegime = ($('[data-field="caisse"]').data('custom') || {}).codeRegime || '01';
                const $assure = $('#facture_patientAssure');
                const $beneficiaire = $('#facture_patientBeneficiaire');

                const numeroSecuriteSociale = $assure.data('custom').numeroSecuriteSociale;
                const dateNaissance = $beneficiaire.val() ? $beneficiaire.data('custom').naissance : $assure.data('custom').naissance;
                const rangNaissance = $beneficiaire.val() ? $beneficiaire.data('custom').rangNaissance : $assure.data('custom').rangNaissance;

                const exoneration = $('#facture_exoneration').val();
                const motifs = [];

                if (exoneration === '4') {
                    motifs.push('ALD');
                } else if (exoneration === '5' && $('#facture_estArticleL115').is(':checked')) {
                    motifs.push('L115');
                }

                if ($('#facture_nature').val() === '41') {
                    motifs.push('AT');
                }
                if ($('#facture_partCaissePourcent').val() === '100') {
                    motifs.push('CDP100');
                }

                const trajet = App.module.vm.trajetsArray[0];

                this.inputData = {
                    structure: {structure: App.Shared.getStructure()},
                    codeRegime,
                    numeroSecuriteSociale,
                    dateNaissance,
                    rangNaissance,
                    dateRealisation: trajet ? trajet.topPriseEnChargeDepartReel.date : $('#facture_date').val() || Moment().format('DD/MM/YYYY'),
                    datePrescription: $('#facture_datePrescription').val() || Moment().format('DD/MM/YYYY'),
                    modeTransport: 'ABA/VSL', // TODO
                    motifs,
                    dateAccidentTravail: $('#facture_dateAccidentTravail').val(),
                    numeroAccidentTravail: $('#facture_numeroAccidentTravail').val(),
                };
            }
        }

        // todo better condition
        if(this.inputData.structure && this.inputData.numeroSecuriteSociale && this.inputData.dateNaissance && this.inputData.rangNaissance) {
            this.refresh();
        }
    },
    methods: {
        reporter() {
            this.$refs.table.reporter();
        },
        target(field) {
            if (this.source === 'transportForm') {
                const base = $('#transport_patientBeneficiaire_enabled').is(':checked') ? 'patientBeneficiaire' : 'patientAssure';
                return '#transport_'+base+'_entity_'+field;
            }

            return '[data-field="'+field+'"]';
        },
        refresh() {
            this.error = null;
            this.loading = true;

            this.postJsonRequest(this.path('facturation.sefi.pec.calculer'), this.inputData).then((data) => {
                if(data.success) {
                    this.result = data.data;

                    if (this.source === 'factureForm') {
                        $('#facture_naturePiecesJustificativesOuvertureDroitsAmo').val(9).change();
                        // todo AMC ?
                    }
                } else {
                    this.error = data.error;
                }

                this.loading = false;
            });
        },
    },
    computed: {
        source() {
            return this.data && this.data.source ? this.data.source : null;
        },
        errors() {
            if(this.error) {
                return this.error.errors || [];
            }

            return [];
        },
    }
}
</script>
