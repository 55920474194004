'use strict';
import Router from "@/App/Router";
import Request from "@/App/Request";

App.Ressources.Video = class {
    constructor() {
        this.initMarquerVu();
    }

    initMarquerVu() {
        const COEFFICIENT_POUR_MARQUER_VU = 0.05;

        $('.js-video').on('timeupdate', function () {
            const $this = $(this);
            if (!$this.data('video-vu')) {
                const id = $this.data('video-id');

                if ((this.duration * COEFFICIENT_POUR_MARQUER_VU) < this.currentTime) {
                    $this.data('video-vu', 'true');
                    Request.getJson(Router.generate('ressources.video.vu', {id}));
                }
            }
        })
    }
};
