<template>
    <div>
        <div class="panel panel-compact">
            <div class="panel-heading d-flex justify-content-between bg-light py-3 px-4">
                <div>{{ Translator.trans('administration.tableau-de-bord.arl.etat') }}</div>
                <div>
                    <span class="badge badge-secondary my-n1">1 an</span>
                </div>
            </div>
            <div class="panel-wrapper">
                <div class="panel-body">
                    <pie-chart :data="arlEtatData" :colors="arlEtatChartColors"></pie-chart>
                </div>
            </div>
        </div>
        <div class="panel panel-compact">
            <div class="panel-heading d-flex justify-content-between bg-light py-3 px-4">
                <div>{{ Translator.trans('administration.tableau-de-bord.arl.type') }}</div>
                <div>
                    <span class="badge badge-secondary my-n1">1 an</span>
                </div>
            </div>
            <div class="panel-wrapper">
                <div class="panel-body">
                    <pie-chart :data="arlTypeData" :colors="arlTypeChartColors"></pie-chart>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Router from "@/App/Router";
import {onMounted, ref, computed} from "vue";
import {useRequest} from "@/Vue/Composables/Request";
import Translator from "@/App/Translator";

const emit = defineEmits(['refresh', 'error']);

const {getJson} = useRequest();

const loading = ref(false);
const perPage = ref(12);
const currentPage = ref(0);
const arlEtatData = ref({});
const arlTypeData = ref({});
const arlEtatsColors = ref({
    'Traité': '#00c292',
    'Échoué': '#fb9678',
    'Ignoré': '#4c5667',
    'Nouveau': '#e4e7ea',
});
const arlTypesColors = ref({
    'SCOR': '#ab8ce4',
    'Chiffrement': '#fec107',
    'RSP': '#03a9f3',
});

const arlEtatChartColors = computed(() => {
    let colors = [];
    let arls = arlEtatData.value;

    for (let key in arls) {
        let color = '#414141';

        if (key in arlEtatsColors.value) {
            color = arlEtatsColors.value[key];
        }

        colors.push(color);
    }

    return colors;
});

const arlTypeChartColors = computed(() => {
    let colors = [];
    let arls = arlTypeData.value;

    for (let key in arls) {
        let color = '#414141';

        if (key in arlTypesColors.value) {
            color = arlTypesColors.value[key];
        }

        colors.push(color);
    }

    return colors;
});

const refresh = () => {
    loading.value = true;

    getJson(Router.generate('administration.tableau-de-bord.arlGraphData')).then((data) => {
        arlEtatData.value = data['arlEtatData'];
        arlTypeData.value = data['arlTypeData'];
        emit('refresh');
    }, (err) => emit('error', err)).finally(() => {
        loading.value = false;
    });
};

onMounted(() => refresh());
</script>
