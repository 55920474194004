'use strict';

App.Regulation.Serie = class {};
App.Regulation.Serie.Trajet = class {};

App.Regulation.Serie.Trajet.Index = class {
    constructor(params) {
        App.Shared.initContextMenu(params);
    }
};
