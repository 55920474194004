export default {
    install: (Vue) => {
        Vue.mixin({
            methods: {
                getCurrentApp() {
                    return App.Constants.APPLICATION;
                },
            }
        })

    }
};
