'use strict'

import {createApp} from "vue";

App.Shared.Message = class {}

App.Shared.Message.Index = class {
    constructor(params = {}) {
        this.params = params;

        createApp({
            delimiters: ['[[', ']]'],
            data: () => ({
                ...params.vueData,
            }),
            mounted() {
                document.dispatchEvent(new Event('app:vuejs:mounted'));
            }
        }).mount('#app');
    }
}