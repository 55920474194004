<template>
    <td class="position-relative"
        v-droppable:ressource
        @dblclick="editInPlaceField = field"
        @v-drag-enter="(ressource, valid, event) => onRessourceDragEnter(trajet, field, ressource, valid, event)"
        @v-drag-leave="onRessourceDragLeave"
        @v-drag-drop="onRessourceDrop(trajet, field, $event)"
    >
        <b-overlay :show="ressourceDropSaving && ressourceDropSaving.trajet === trajet && ressourceDropSaving.field === field" no-wrap></b-overlay>
        <div class="d-flex align-items-center" v-if="ressource">
            <div class="mr-1 ml-n1" v-if="statut || ressource.appareilCollection?.length">
                <template v-if="statut">
                    <i v-if="statut.type === -1" class="fa-fw fa-solid fa-check text-success" :title="Translator.trans('regulation.trajet.etat.libelleComplet.tlaLu', {'time': statut.top.time})"></i>
                    <i v-else-if="statut.type === -2" class="fa-fw fa-regular fa-envelope-open text-success" :title="Translator.trans('regulation.trajet.etat.libelleComplet.tlaAcquittement', {'time': statut.top.time})"></i>
                    <i v-else-if="statut.type === -3" class="fa-fw fa-regular fa-paper-plane text-success" :title="Translator.trans('regulation.trajet.etat.libelleComplet.tlaEnvoi', {'time': statut.top.time})"></i>
                    <i v-else-if="statut.type === -4" class="fa-fw fa-solid fa-triangle-exclamation text-danger" :title="Translator.trans('regulation.trajet.etat.libelleComplet.tlaEnvoiEchec', {'time': statut.top.time})"></i>
                </template>
                <template v-else-if="trajet.topTlaLu || trajet.topTlaAcquittement || trajet.topTlaEnvoi || trajet.topTlaEnvoiEchec">
                    <i v-if="trajet.topTlaLu" class="fa-fw fa-solid fa-check text-success" :title="Translator.trans('regulation.trajet.etat.libelleComplet.tlaLu', {'time': trajet.topTlaLu.time})"></i>
                    <i v-else-if="trajet.topTlaAcquittement" class="fa-fw fa-regular fa-envelope-open text-success" :title="Translator.trans('regulation.trajet.etat.libelleComplet.tlaAcquittement', {'time': trajet.topTlaAcquittement.time})"></i>
                    <i v-else-if="trajet.topTlaEnvoi" class="fa-fw fa-regular fa-paper-plane text-success" :title="Translator.trans('regulation.trajet.etat.libelleComplet.tlaEnvoi', {'time': trajet.topTlaEnvoi.time})"></i>
                    <i v-else-if="trajet.topTlaEnvoiEchec" class="fa-fw fa-solid fa-triangle-exclamation text-danger" :title="Translator.trans('regulation.trajet.etat.libelleComplet.tlaEnvoiEchec', {'time': trajet.topTlaEnvoiEchec.time})"></i>
                </template>
                <template v-else>
                    <i v-if="trajet.interfaceMobileMissionId && trajet.topMissionEnvoiEchec" class="fa-fw fa-solid fa-triangle-exclamation text-warning" :title="Translator.trans('regulation.trajet.etat.libelleComplet.topMissionEnvoiEchec', {'time': trajet.topMissionEnvoiEchec.time})"></i>
                    <i v-else-if="trajet.interfaceMobileMissionId" class="fa-fw fa-solid fa-spinner fa-pulse" :title="Translator.trans('regulation.trajet.etat.libelleComplet.tlaEnvoiEnCours')"></i>
                    <i v-else-if="trajet.canFirstSendTla" class="fa-fw fa-solid fa-paper-plane text-warning" :title="Translator.trans('regulation.trajet.etat.libelleComplet.tlaNonEnvoye')"></i>
                    <i v-else class="fa-fw fa-solid fa-paper-plane text-danger" :title="Translator.trans('regulation.trajet.etat.libelleComplet.tlaNonEnvoye')"></i>
                </template>
            </div>
            <div class="flex-1 d-block badge badge-dark badge-shadow user-select-none" :style="'background: '+ressource.couleur+';'" :title="ressource.text" v-draggable:ressource="ressource" @v-drag-start="onRessourceDragStart(ressource, trajet)" @v-drag-end="onRessourceDragEnd(ressource)">
                {{ ressource.shortString }}
            </div>
        </div>
    </td>
</template>

<script setup>
import Translator from "@/App/Translator";
import {computed, toRefs, inject, provide} from "vue";
import _ from "lodash";

const props = defineProps({
    field: String,
    trajet: Object,
    ressourceDropSaving: Object,
});

const {trajet, field} = toRefs(props);
const editInPlaceField = inject('editInPlaceField');
const onRessourceDrop = inject('onRessourceDrop');
const onRessourceDragStart = inject('onRessourceDragStart');
const onRessourceDragEnter = inject('onRessourceDragEnter');
const onRessourceDragLeave = inject('onRessourceDragLeave');
const onRessourceDragEnd = inject('onRessourceDragEnd');

const ressource = computed(() => trajet.value['_'+field.value]);
const type = ['personnel1', 'personnel2'].includes(field.value) ? 'personnel' : field.value;

const topsTla = [-1, -2, -3, -4];

const statuts = computed(() => null !== trajet.value[field.value] ? trajet.value.statutCollection.filter(statut => statut[type] && statut[type].id === trajet.value[field.value]) : []);
const statut = _.last(_.orderBy(statuts.value.filter(statut => topsTla.includes(statut.type)), ['type'], ['asc']));
</script>
