<template>
    <div class="h-full flex items-center gap-x-1" v-if="ressource">
        <div v-if="statut || ressource.appareilCollection?.length">
            <template v-if="statut">
                <i v-if="statut.type === -1" class="fa-fw fa-solid fa-check text-green-500" :title="Translator.trans('regulation.trajet.etat.libelleComplet.tlaLu', {'time': statut.top.time})"></i>
                <i v-else-if="statut.type === -2" class="fa-fw fa-regular fa-envelope-open text-green-500" :title="Translator.trans('regulation.trajet.etat.libelleComplet.tlaAcquittement', {'time': statut.top.time})"></i>
                <i v-else-if="statut.type === -3" class="fa-fw fa-regular fa-paper-plane text-green-500" :title="Translator.trans('regulation.trajet.etat.libelleComplet.tlaEnvoi', {'time': statut.top.time})"></i>
                <i v-else-if="statut.type === -4" class="fa-fw fa-solid fa-triangle-exclamation text-red-500" :title="Translator.trans('regulation.trajet.etat.libelleComplet.tlaEnvoiEchec', {'time': statut.top.time})"></i>
            </template>
            <template v-else-if="trajet.topTlaLu || trajet.topTlaAcquittement || trajet.topTlaEnvoi || trajet.topTlaEnvoiEchec">
                <i v-if="trajet.topTlaLu" class="fa-fw fa-solid fa-check text-green-500" :title="Translator.trans('regulation.trajet.etat.libelleComplet.tlaLu', {'time': trajet.topTlaLu.time})"></i>
                <i v-else-if="trajet.topTlaAcquittement" class="fa-fw fa-regular fa-envelope-open text-green-500" :title="Translator.trans('regulation.trajet.etat.libelleComplet.tlaAcquittement', {'time': trajet.topTlaAcquittement.time})"></i>
                <i v-else-if="trajet.topTlaEnvoi" class="fa-fw fa-regular fa-paper-plane text-green-500" :title="Translator.trans('regulation.trajet.etat.libelleComplet.tlaEnvoi', {'time': trajet.topTlaEnvoi.time})"></i>
                <i v-else-if="trajet.topTlaEnvoiEchec" class="fa-fw fa-solid fa-triangle-exclamation text-red-500" :title="Translator.trans('regulation.trajet.etat.libelleComplet.tlaEnvoiEchec', {'time': trajet.topTlaEnvoiEchec.time})"></i>
            </template>
            <template v-else>
                <i v-if="trajet.interfaceMobileMissionId && trajet.topMissionEnvoiEchec" class="fa-fw fa-solid fa-triangle-exclamation text-yellow-500" :title="Translator.trans('regulation.trajet.etat.libelleComplet.topMissionEnvoiEchec', {'time': trajet.topMissionEnvoiEchec.time})"></i>
                <i v-else-if="trajet.interfaceMobileMissionId" class="fa-fw fa-solid fa-spinner fa-pulse" :title="Translator.trans('regulation.trajet.etat.libelleComplet.tlaEnvoiEnCours')"></i>
                <i v-else-if="trajet.canFirstSendTla" class="fa-fw fa-solid fa-paper-plane text-yellow-500" :title="Translator.trans('regulation.trajet.etat.libelleComplet.tlaNonEnvoye')"></i>
                <i v-else class="fa-fw fa-solid fa-paper-plane text-red-500" :title="Translator.trans('regulation.trajet.etat.libelleComplet.tlaNonEnvoye')"></i>
            </template>
        </div>
        <div style="text-shadow: 0 0 3px rgba(0, 0, 0, 0.42);" class="flex-1 rounded-md text-white font-medium self-stretch flex items-center justify-center text-sm px-2" :style="'background: '+ressource.couleur+';'" :title="ressource.text">
            {{ ressource.shortString }}
        </div>
    </div>
</template>

<script setup>
import {computed, toRefs} from "vue";
import _ from "lodash";
import Translator from "@/App/Translator";

const props = defineProps({
    trajet: Object,
    field: String,
});

const {trajet, field} = toRefs(props);

const ressource = computed(() => trajet.value['_'+field.value]);
const type = ['personnel1', 'personnel2'].includes(field.value) ? 'personnel' : field.value;

const topsTla = [-1, -2, -3, -4];

const statuts = computed(() => null !== trajet.value[field.value] ? trajet.value.statutCollection.filter(statut => statut[type] && statut[type].id === trajet.value[field.value]) : []);
const statut = _.last(_.orderBy(statuts.value.filter(statut => topsTla.includes(statut.type)), ['type'], ['asc']));

</script>