<template>
    <div class="input-group">
        <form-input v-bind="$attrs" :class="inputClass" :value="computedValue" @input="onInputChange('input', $event)" @change="onInputChange('change', $event)" @reset="$emit('reset')"></form-input>
        <div class="input-group-append">
            <button class="btn btn-secondary" type="button" tabindex="-1" @click="toggleMode()" :title="modeTitle">{{ modeSymbol }}</button>
            <button class="btn btn-secondary" type="button" tabindex="-1" @click="toggleRemise()" :title="remiseTitle">
                <span class="text-muted" :class="{'font-weight-bold': remise === 1, 'text-theme': hasRemise1}">1</span><span class="text-muted ml-2" :class="{'font-weight-bold': remise === 2, 'text-theme': hasRemise2}">2</span>
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        inheritAttrs: false,
        props: ['value'],
        data() {
            return {
                inputClass: null,
                remise: 1,
                mode: null,
            };
        },
        mounted() {
            this.initMode();

            // fix car class/style exclu de inheritAttrs, voir avec vue v3...
            this.inputClass = this.$el.classList.toString();
            this.$el.setAttribute('class', 'input-group');
        },
        computed: {
            opositeMode() {
                return this.mode === 'pourcent' ? 'montant' : 'pourcent';
            },
            field() {
                return this.mode + this.remise;
            },
            field2() {
                return this.opositeMode + this.remise;
            },
            hasRemise1() {
                return (this.value.montant1 !== '' && this.value.montant1 !== null && this.value.montant1 !== undefined) ||
                    (this.value.pourcent1 !== '' && this.value.pourcent1 !== null && this.value.pourcent1 !== undefined);
            },
            hasRemise2() {
                return (this.value.montant2 !== '' && this.value.montant2 !== null && this.value.montant2 !== undefined) ||
                    (this.value.pourcent2 !== '' && this.value.pourcent2 !== null && this.value.pourcent2 !== undefined);
            },
            computedValue() {
                return this.value[this.field];
            },
            modeSymbol() {
                return this.mode === 'pourcent' ? '%' : '€';
            },
            modeTitle() {
                return this.mode === 'pourcent' ? 'Saisir un montant' : 'Saisir un pourcentage';
            },
            remiseTitle() {
                return this.remise === 1 ? 'Saisir une 2ème remise' : 'Saisir la 1ère remise';
            }
        },
        methods: {
            initMode() {
                const pourcent = this.value['pourcent' + this.remise];
                const montant = this.value['montant' + this.remise];

                this.mode = (pourcent !== null && pourcent !== '' && pourcent !== undefined) || montant === null || montant === '' || montant === undefined ? 'pourcent' : 'montant';
            },
            onInputChange(event, value) {
                this.$emit(event, {...this.value, [this.field2]: '', [this.field]: value})
            },
            toggleMode() {
                this.mode = this.opositeMode;
            },
            toggleRemise() {
                this.remise = 1 === this.remise ? 2 : 1;
                this.initMode();
            }
        }
    }
</script>