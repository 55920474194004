export default function filesize(value) {
    if (null === value) {
        return null;
    }

    const i = Math.floor(Math.log(value || 1) / Math.log(1024));
    const precision = [0,0,1,1,2][i];


    const res = (value / (1024 ** i)).toFixed(precision);

    return Intl.NumberFormat('fr-FR', { style: 'decimal', useGrouping: false, minimumFractionDigits: precision}).format(res) +' '+['o','Ko','Mo','Go','To'][i];
}
