<template>
    <div class="d-flex flex-column overflow-hidden" style="height: calc(100vh - 104px);">
        <h3 class="title-heading d-flex justify-content-between align-items-center">
            <span>Ressources</span>
            <regulation-badge-date :date="rendezVousInput"></regulation-badge-date>
<!--            <b-dropdown size="lg" variant="link" toggle-class="p-0" no-caret>-->
<!--                <template #button-content>-->
<!--                        <i class="fa-solid fa-gear"></i>-->
<!--                    </template>-->
<!--                <b-dropdown-item link-class="p-0"><i class="fa-solid fa-check text-success"></i> Alertes sonores</b-dropdown-item>-->
<!--            </b-dropdown>-->
        </h3>
        <div class="row no-gutters form-horizontal form-mini-filter">
            <div class="col-4">
                <div class="form-group">
                    <input type="text" class="form-control input-sm js-shuffle-search" v-model="searchQueryRessources" placeholder="Code">
                </div>
            </div>
            <div class="col-8">
                <div class="form-group">
                    <form-btn-group class="input-sm" v-model="mode" type="radio" :htmlLabels="true" :choices="modeChoices"></form-btn-group>
                </div>
            </div>
        </div>
        <div class="js-perfect-scrollbar" id="ressources" ref="scrollView">
            <div class="text-center pt-5 mt-5" v-if="!ressources.length">
                <i class="fa-4x im-link"></i>
                <h3>Aucune ressource</h3>
                <p class="text-muted">Aucune ressource ne correspond aux filtres sélectionnés.</p>
            </div>
            <template v-else v-for="(section, sectionName) in sections">
                <template v-if="sectionName in ressourcesBySection">
                    <h3 class="title-heading py-2 d-flex justify-content-between align-items-center cursor-pointer user-select-none" @click="section.visible = !section.visible">
                    <span class="d-flex align-items-center" :class="{'text-muted': !section.visible}">
                        {{ section.label }}
                        <i class="fa-solid fa-eye-slash ml-2" v-if="!section.visible"></i>
                    </span>
                        <span>
                        <span class="badge badge-pill ml-2" v-for="(count, etat) in countRessourcesByEtatBySection[sectionName]" :key="etat" :class="'badge-'+etats[etat].variant" :title="etats[etat].title">{{ count }}</span>
                    </span>
                    </h3>
                    <b-collapse visible v-model="section.visible">
                        <transition-group tag="div" class="row mt-3 mx-1" :data-section="sectionName" name="ressource">
                            <div :key="ressource.id" class="col-6" v-for="ressource in ressourcesBySection[sectionName]">
                                <div :key="ressource.id" v-draggable:ressource="ressource" @v-drag-start="onRessourceDragStart(ressource)" @v-drag-end="onRessourceDragEnd(ressource)" class="equipage" :class="{['equipage-'+ressource._etat.variant]: true, 'equipage-click': sectionName !== 'disponible' && ressource._trajetCollection.length, 'equipage-drag': sectionName === 'disponible'}" @click="emit('ressource-click', ressource)" @mouseenter="onRessourceMouseEnter(ressource)" @mouseleave="onRessourceMouseLeave(ressource)">
                                    <div class="equipage-progress" :style="getProgressStyle(ressource)" ></div>
                                    <div class="d-flex justify-content-between position-relative">
                                        <div>
                                            <i v-if="ressource.icone" :title="ressource.icone.title" :class="[ressource.icone.classeCouleur, ressource.icone.classe]"></i>
                                            <span class="text-normal" :title="ressource.text">{{ ressource.shortString }}</span>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <template v-if="sectionName === 'disponible'">
                                                <small title="Temps depuis la dernière mission" v-if="ressource._trajetInfo.minTrajet && (!ressource.pausePrecedente || ressource._trajetInfo.minTrajet._topFinReel.isAfter(ressource.pausePrecedente._fin))">
                                                    <ressource-timer v-if="rendezVousIsToday" type="disponible" :value="ressource._trajetInfo.minTrajet._topFinReel"></ressource-timer>
                                                </small>&nbsp;
                                                <small title="Temps depuis la dernière pause" v-if="ressource.pausePrecedente && (!ressource._trajetInfo.minTrajet || ressource.pausePrecedente._fin.isAfter(ressource._trajetInfo.minTrajet._topFinReel))">
                                                    <ressource-timer v-if="rendezVousIsToday" type="disponible" :value="ressource.pausePrecedente._fin"></ressource-timer>
                                                </small>&nbsp;
                                                <span class="badge badge-secondary" title="Nombre de missions terminées">{{ ressource._trajetInfo.total }}</span>
                                            </template>
                                            <template v-else-if="sectionName === 'affecte'">
                                                <small title="Temps avant prochaine mission" v-if="ressource._trajetInfo.minTrajet">
                                                    <ressource-timer v-if="rendezVousIsToday" type="affecte" :value="ressource._trajetInfo.minTrajet.debutMissionPrevu"><i class="im-stopwatch"></i></ressource-timer>
                                                </small>
                                            </template>
                                            <template v-else-if="sectionName === 'pause'">
                                                <small title="Temps écoulé depuis le début de la pause" v-if="ressource.pauseCourante">
                                                    <ressource-timer v-if="rendezVousIsToday" type="pause" :value="ressource.pauseCourante.debut"></ressource-timer>
                                                </small>&nbsp;
                                                <trajet-etat-pause :pause="ressource.pauseCourante"></trajet-etat-pause>
                                            </template>
                                            <template v-else-if="sectionName === 'mission'">
                                                <small v-if="ressource._trajetInfo.total === 1" :title="ressource._trajetInfo.minTrajet.topCourantValue.date+' '+ressource._trajetInfo.minTrajet.topCourantValue.time">
                                                    {{ ressource._trajetInfo.minTrajet.topCourantValue.time }}&nbsp;
                                                </small>
                                                <small v-else>
                                                    {{ ressource._trajetInfo.count }} / {{ ressource._trajetInfo.total }}&nbsp;
                                                </small>
                                                <trajet-etat :trajet="ressource._trajetInfo.minTrajet"></trajet-etat>
                                            </template>

                                            <template v-if="false/*sectionName === 'disponible' || sectionName === 'pause'*/">
                                                <div class="equipage-secret">
                                                    <template v-if="ressource.equipage">
                                                        <span class="ml-1 badge badge-dark" :style="'background: '+ressource._equipage.couleur+';'" :title="ressource._equipage.text">{{ ressource._equipage.shortString }}</span>
                                                    </template>
                                                    <template v-else>
                                                    <span v-if="ressource.vehicule" class="ml-1 badge badge-info" :style="'background: '+ressource._vehicule.couleur+';'" :title="ressource._vehicule.text+(ressource._vehicule.icone ? ' - '+ressource._vehicule.icone.title : '')">
                                                        <i v-if="ressource._vehicule.icone" :class="ressource._vehicule.icone.classe"></i>
                                                        {{ ressource._vehicule.shortString }}
                                                    </span>
                                                        <span v-if="ressource.personnel1" class="ml-1 badge badge-primary" :style="'background: '+ressource._personnel1.couleur+';'" :title="ressource._personnel1.text">{{ ressource._personnel1.shortString }}</span>
                                                        <span v-if="ressource.personnel2" class="ml-1 badge badge-primary" :style="'background: '+ressource._personnel2.couleur+';'" :title="ressource._personnel2.text">{{ ressource._personnel2.shortString }}</span>
                                                    </template>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                    <div v-if="sectionName !== 'disponible' && ressource._trajetInfo.minTrajet">
                                        <span v-if="ressource._trajetInfo.minTrajet.equipage && ressource._trajetInfo.minTrajet.equipage !== ressource.id && ressource._trajetInfo.minTrajet._equipage" class="badge badge-dark" :style="'background: '+ressource._trajetInfo.minTrajet._equipage.couleur+';'" :title="ressource._trajetInfo.minTrajet._equipage.text">
                                            {{ ressource._trajetInfo.minTrajet._equipage.shortString }}
                                        </span>
                                        <span v-if="ressource._trajetInfo.minTrajet.vehicule && ressource._trajetInfo.minTrajet.vehicule !== ressource.id && ressource._trajetInfo.minTrajet._vehicule" class="badge badge-info" :style="'background: '+ressource._trajetInfo.minTrajet._vehicule.couleur+';'" :title="ressource._trajetInfo.minTrajet._vehicule.text">
                                            <i v-if="ressource._trajetInfo.minTrajet._vehicule.icone" :class="ressource._trajetInfo.minTrajet._vehicule.icone.classe"></i>
                                            {{ ressource._trajetInfo.minTrajet._vehicule.shortString }}
                                        </span>
                                        <span v-if="ressource._trajetInfo.minTrajet.personnel1 && ressource._trajetInfo.minTrajet.personnel1 !== ressource.id && ressource._trajetInfo.minTrajet._personnel1" class="badge badge-primary" :style="'background: '+ressource._trajetInfo.minTrajet._personnel1.couleur+';'" :title="ressource._trajetInfo.minTrajet._personnel1.text">{{ ressource._trajetInfo.minTrajet._personnel1.shortString }}</span>
                                        <span v-if="ressource._trajetInfo.minTrajet.personnel2 && ressource._trajetInfo.minTrajet.personnel2 !== ressource.id && ressource._trajetInfo.minTrajet._personnel2" class="badge badge-primary" :style="'background: '+ressource._trajetInfo.minTrajet._personnel2.couleur+';'" :title="ressource._trajetInfo.minTrajet._personnel2.text">{{ ressource._trajetInfo.minTrajet._personnel2.shortString }}</span>
                                    </div>
                                </div>
                            </div>
                        </transition-group>
                    </b-collapse>
                </template>
            </template>
        </div>
    </div>
</template>

<script setup>
import Moment from "moment";
import {storeToRefs} from "pinia";
import {useRegulationStore} from "@/Vue/Stores/Regulation";
import {onMounted, watch, inject, ref} from "vue";
import Translator from "@/App/Translator";
import Router from '@/App/Router';
import Request from '@/App/Request';

const emit = defineEmits(['ressource-dispo', 'ressource-click'])

const canDropRessource = inject('canDropRessource');
const onRessourceDrop = inject('onRessourceDrop');
const getMessageItem = inject('getMessageItem');
const onRessourceDragStart = inject('onRessourceDragStart');
const onRessourceDragEnd = inject('onRessourceDragEnd');

const store = useRegulationStore();
const {rendezVousInput, modeRessources: mode, currentTrajet, ressources, ressourcesBySection, vehiculesById, personnelsById, countRessourcesByEtatBySection, rendezVousIsToday, searchQueryRessources} = storeToRefs(store);
const {sectionsRessources: sections, etatsRessources: etats, refreshRessource, openRessourceDetailSwal, openEnvoiPauseSwal,
    openEnvoiFinServiceSwal, explodeRessource, cancelPause, canSendFinService, canSendPause} = store;


const modeChoices = {
    mixte: {label: '<i class="fa-solid fa-shuffle"></i>', title: 'Mixte'},
    equipage: {label: '<i class="fa-solid fa-link"></i>', title: 'Équipages'},
    vehicule: {label: '<i class="fa-solid fa-cars"></i>', title: 'Véhicules'},
    personnel: {label: '<i class="fa-solid fa-people"></i>', title: 'Personnels'},
};

const getProgressStyle = (ressource) => {
    let style = {};

    if(ressource._etat.name === 'en-mission') {
        style.background = 'rgba(251, 150, 120, 0.15)';
        style.width = ressource._progress*100 + '%';
    }
    else if(ressource._etat.name === 'fin-mission' || ressource._etat.name === 'pause') {
        style.background = 'rgba(254, 193, 7, 0.15)';
        style.width = ressource._progress*100 + '%';
    }

    return style;
}

const onRessourceMouseEnter = (ressource) => {
    if (ressource._trajetInfo.minTrajet) {
        for (const trajet of ressource._trajetCollection) {
            const el = document.querySelector('[data-id="'+trajet.id+'"]');
            if(el) {
                el.classList.add('highlighted');
            }
        }
    }
};

const onRessourceMouseLeave = (ressource) => {
    document.querySelectorAll('.highlighted').forEach(e => e.classList.remove('highlighted'));
};

const scrollView = ref();

watch(mode, () => {
    scrollView.value.scrollTop = 0;
});

watch(ressources, (newRessources, oldRessources) => {
    const oldRessourcesById = oldRessources.reduce((res, item) => ((res[item.id] = item ), res), {});

    for(const ressource of newRessources) {
        const oldRessource = oldRessourcesById[ressource.id];

        if(oldRessource && oldRessource._etat.name !== 'disponible' && ressource._etat.name === 'disponible') {
            emit('ressource-dispo', ressource);
        }
    }
});

onMounted(() => {
    setInterval(() => {
        for(const ressource of ressources.value) {
            refreshRessource(ressource);
        }
    }, 1000);

    $.contextMenu({
        selector: '#ressources .equipage',
        build: ($trigger, event) => {
            const index = $trigger.parent().index();
            const section = $trigger.parent().parent().attr('data-section');
            const ressource = ressourcesBySection.value[section][index];
            const icons = {equipage: 'fa-link', 'personnel': 'fa-user', 'vehicule': 'fa-car'};

            if(!ressource) {
                return false;
            }

            let items = {};

            items.ressource = {name: ressource.text, disabled: true, icon: icons[ressource._type]};
            items.sep0 = '';
            items.detail = {name: 'Détail', icon: 'fa-solid fa-eye', callback: () => openRessourceDetailSwal(ressource)};
            items.sep1 = '';


            const fields = {vehicule: vehiculesById.value, personnel1: personnelsById.value, personnel2: personnelsById.value};

            if(ressource._type === 'equipage') {
                const ressources2 = [];

                for(const field in fields) {
                    if(ressource[field] && fields[field][ressource[field]]) {
                        ressources2.push(fields[field][ressource[field]]);
                    }
                }

                items.message = getMessageItem(ressources2);
            } else {
                items.message = getMessageItem([ressource]);
            }

            // ENVOI DE PAUSE
            const [fullPersonnel1, fullPersonnel2, fullVehicule, fullEquipage] = explodeRessource(ressource);

            if('pause' === section) {
                // Annulation de pause
                let personnelPause1 = fullPersonnel1 && fullPersonnel1.pauseCourante ? fullPersonnel1 : null;
                let personnelPause2 = fullPersonnel2 && fullPersonnel2.pauseCourante ? fullPersonnel2 : null;

                if (!personnelPause1 && personnelPause2) {
                    personnelPause1 = personnelPause2;
                    personnelPause2 = null;
                }

                if (personnelPause1) {
                    const now = Moment();

                    let minDelaiAnnulationPause = personnelPause1.delaiAnnulationPause;
                    const debutPrevu1 = Moment(personnelPause1.pauseCourante.debutPrevu, 'DD/MM/YYYY HH:mm');
                    let canCancelPause = now.diff(debutPrevu1, 'minutes') < personnelPause1.delaiAnnulationPause;

                    if (personnelPause2) {
                        const debutPrevu2 = Moment(personnelPause2.pauseCourante.debutPrevu, 'DD/MM/YYYY HH:mm');
                        if (now.diff(debutPrevu2, 'minutes') >= personnelPause2.delaiAnnulationPause) {
                            canCancelPause = false;
                        }
                        if(minDelaiAnnulationPause > personnelPause2.delaiAnnulationPause) {
                            minDelaiAnnulationPause = personnelPause2.delaiAnnulationPause
                        }
                    }

                    items.annulerPause = {
                        name: '<span'+ (!canCancelPause ? ' title="Annulation impossible car pause débutée il y a plus de ' + minDelaiAnnulationPause + ' minute'+(minDelaiAnnulationPause > 1 ?'s':'')+'"' : '') + '>Annuler cette pause</span>',
                        isHtmlName: true,
                        icon: 'fa-regular fa-circle-xmark',
                        disabled: !canCancelPause,
                        callback: () => cancelPause(personnelPause1 ? personnelPause1.pauseCourante : null, personnelPause2 ? personnelPause2.pauseCourante : null)
                    };
                }
            } else {
                items.envoyerPause = {name: Translator.trans('action.envoyer-pause'), icon: 'fa-solid fa-pause', disabled: !canSendPause(fullPersonnel1, fullPersonnel2, fullVehicule), callback: () =>
                    openEnvoiPauseSwal(fullPersonnel1, fullPersonnel2, fullVehicule, fullEquipage)
                };
                items.envoyerFinService = {name: Translator.trans('action.envoyer-fin-service'), icon: 'fa-solid fa-stop', disabled: !canSendFinService(fullPersonnel1, fullPersonnel2, fullVehicule), callback: () =>
                    openEnvoiFinServiceSwal(fullPersonnel1, fullPersonnel2, fullVehicule, fullEquipage)
                };
            }

            if(currentTrajet.value) {
                items.sep2 = '';
                items.affecter = {name: 'Affecter au trajet sélectionné', icon: 'fa-solid fa-arrow-pointer', disabled: !canDropRessource(currentTrajet.value, null, ressource)};

                if(!items.affecter.disabled) {
                    if (ressource._type === 'personnel') {
                        items.affecter.items = [
                            {name: 'Personnel 1', callback: () => onRessourceDrop(currentTrajet.value, 'personnel1', ressource)},
                            {name: 'Personnel 2', callback: () => onRessourceDrop(currentTrajet.value, 'personnel2', ressource)},
                        ];
                    } else {
                        items.affecter.callback = () => onRessourceDrop(currentTrajet.value, ressource._type, ressource);
                    }
                }

            }

            if(ressource._type === 'vehicule') {
                items.sep3 = '';
                items.indisponible = {
                    name: ressource.estIndisponible ? 'Rendre disponible' : 'Rendre indisponible',
                    icon: ressource.estIndisponible ? 'fa-solid fa-check' : 'fa-solid fa-xmark',
                    callback: () => {
                        Request.getJson(Router.generate('regulation.trajet.vehicule.indisponible', {id: ressource.id}));
                    }
                };
            }

            return {
                items
            };
        }
    });
});
</script>
