import {defineStore} from 'pinia'
import {computed, nextTick, ref, watch} from 'vue'
import moment from "moment/moment";
import Router from "@/App/Router";
import Request from "@/App/Request";

moment.locale('fr');

export const useNotificationsStore = defineStore('notifications', () => {

  const hasMore = ref(false);
  const allNotifications = ref([]);
  const isInit = ref(false);

  const notifications = computed(() => {
    return allNotifications.value.sort((notificationA, notificationB) => {
      let val = 0;

      if (notificationA.debutEvenement || notificationA.finEvenement || notificationB.debutEvenement || notificationB.finEvenement) {
        if (notificationA._enCours) {
          if (!notificationB._enCours) {
            val = -1;
          } else {
            if (notificationA.finEvenement) {
              if (!notificationB.finEvenement) {
                val = -1;
              } else {
                val = moment(notificationA.finEvenement, 'DD/MM/YYYY HH:mm').isBefore(moment(notificationB.finEvenement, 'DD/MM/YYYY HH:mm')) ? -1 : 1;
              }
            } else if (notificationB.finEvenement) {
              val = 1;
            }
          }
        } else if (notificationB._enCours) {
          val = 1;
        }
      }

      if (0 === val) {
        if (notificationA.pin) {
          if (!notificationB.pin) {
            val = -1;
          }
        } else if (notificationB.pin) {
          val = 1;
        }
      }

      if (0 === val) {
        if (notificationA._dateLecture) {
          if (!notificationB._dateLecture) {
            val = 1;
          }
        } else if (notificationB._dateLecture) {
          val = -1;
        }
      }

      if (0 === val) {
        let dateA = moment(notificationA.envoi, 'DD/MM/YYYY HH:mm');
        let dateB = moment(notificationB.envoi, 'DD/MM/YYYY HH:mm');
        if (dateA.isBefore(dateB)) {
          val = 1;
        } else if (dateA.isAfter(dateB)) {
          val = -1;
        }
      }

      return val;
    })
  });

  const notificationsById = computed(() => notifications.value.reduce((res, item) => (res[item.id] = item, res), {}));

  const newNotifications = ref([]);
  watch(notificationsById, (newItems, oldItems) => {
    if (isInit.value) {
      const items = Object.values(newItems).filter(item => !Object.keys(oldItems).includes(item.id));
      newNotifications.value.push(...items);
    }
  });

  const consumeNewNotification = (notification) => {
    newNotifications.value.splice(newNotifications.value.indexOf(notification), 1);
  };

  const computeNotification = item => ({
    ...item.notification,
    _dateLecture: item.dateLecture,
    _enCours: calcEnCours(moment(), item.notification),
  });

  const handleUpdate = (data) => {
    hasMore.value = data.hasMore;
    allNotifications.value = data.notifications.map(item => computeNotification(item));
    if (!isInit.value) {
      nextTick(() => isInit.value = true);
    }
  }

  const init = (data) => handleUpdate(data);

  const refresh = () => {
    Request.getJson(Router.generate('notification.list.ajax')).then(handleUpdate);
  }

  const markingAsRead = ref(false);

  const markAsRead = () => {
    if (notificationsNonLu.value.length) {
      markingAsRead.value = true;
      Request.postJson(Router.generate('notification.read.ajax'), {notificationIdCollection: notificationsNonLu.value.map(notification => notification.id)}).then(handleUpdate).then(() => markingAsRead.value = false);
    }
  }

  const updateEnCours = () => {
    const now = moment();
    for (const notification of notifications.value) {
      notification._enCours = calcEnCours(now, notification)
    }
  }

  setTimeout(updateEnCours, 1000 * 30);

  const calcEnCours = (now, notification) => {
    if ((notification.debutEvenement || notification.finEvenement)) {
      let dateDebutEvt = notification.debutEvenement ? moment(notification.debutEvenement, 'DD/MM/YYYY HH:mm') : moment(notification.envoi, 'DD/MM/YYYY HH:mm');
      return dateDebutEvt.isSameOrBefore(now) && (!notification.finEvenement || moment(notification.finEvenement, 'DD/MM/YYYY HH:mm').isAfter(now));
    }
    return false;
  }

  const notificationsNonLu = computed(() => notifications.value.filter(notification => !notification._dateLecture));
  const notificationsEnCours = computed(() => notifications.value.filter(notification => notification._enCours));
  const notificationsBandeau = computed(() => notifications.value.filter(notification => notification.pinBandeau));

  const countNonLu = computed(() => notificationsNonLu.value.length);

  const criticiteEnCours = computed(() => notificationsEnCours.value.reduce((res, notification) => notification.criticite > res ? notification.criticite : res, -1));
  const criticiteNonLu = computed(() => notificationsNonLu.value.reduce((res, notification) => notification.criticite > res ? notification.criticite : res, -1));

  App.webSocket.subscribe('notification/update', (uri, data) => {
    if (data.type === 'read' && markingAsRead.value) {
      markingAsRead.value = false;
    } else {
      refresh();
    }
  });

  return { init, refresh, markAsRead, countNonLu, criticiteEnCours, criticiteNonLu, hasMore, notifications, notificationsById, notificationsBandeau, newNotifications, consumeNewNotification, isInit};
})
