<template>
  <li class="w-100 bg-light p-1 shadow-sm" :class="style.border" style="border-radius: 6px" :style="'border-width: '+ style.borderWidth +'!important;' + (isReadAndNotPinnedAndNotEnCours ? 'opacity: 0.75;' : '')" v-if="!previewMode || notification.titre || notification.contenu">
    <div>
      <div class="px-3 d-flex align-items-center" :class="notification.contenu ? 'pt-1 pb-2 mb-1 border-bottom' : (datesRefs.dateDebut || datesRefs.dateFin ? 'pt-1 pb-2 mb-n1' : 'py-2')">
        <span class="font-weight-600 flex-grow-1" :class="style.text"><i class="fa-solid fa-triangle-exclamation mr-1" v-if="style.showIcon"></i>{{ notification.titre }}</span>
        <span class="small" :title="datesRefs.dateEnvoi ? datesRefs.dateEnvoi.format('DD/MM/YYYY HH:mm:ss') : null">
          {{ dureesRefs.fromEnvoi ? (dureesRefs.fromEnvoi.asHours() > 20 ? Math.max(Math.floor(dureesRefs.fromEnvoi.asDays()), 1)+'j' : (dureesRefs.fromEnvoi.asSeconds() < 60 ? 'à l\'instant' : datesRefs.dateEnvoi.format('HH:mm'))) : null }}
        </span>
      </div>
      <p class="mb-0 px-3 py-1" v-if="notification.contenu">{{ notification.contenu }}</p>
    </div>

    <div class="d-flex align-items-stretch justify-content-between font-weight-500 pb-1 pt-2 px-2 border-top text-dark mt-1" style="border-radius: 0  0 4px 4px" v-if="datesRefs.dateDebut || datesRefs.dateFin">
      <template v-if="evenementEnCours" class="d-flex">
        <div class="d-flex align-items-center bg-white border px-2" style="border-radius: 4px">
          <span v-if="datesRefs.dateDebut" :title="datesRefs.dateDebut.format('dddd DD/MM/YYYY à HH:mm:ss')">
            {{ contextualize(datesRefs.dateDebut) }}
          </span>
          <template v-if="datesRefs.dateFin && (!datesRefs.dateDebut || !datesRefs.dateDebut.isSame(datesRefs.dateFin))">
            <i class="fa-solid fa-arrow-right mx-2"></i>
            <span :title="datesRefs.dateFin.format('dddd DD/MM/YYYY à HH:mm:ss')">
                {{ contextualize(datesRefs.dateFin) }}
              </span>
          </template>
        </div>
        <div class="flex-grow-1 mb-0 ml-2">
          <div class="progress h-100" style="border-radius: 4px; padding: 1px">
            <div class="progress-bar" style="border-radius: 3px" :class="style.progress + (100 === progressValue ? ' loop' : '')" role="progressbar" :style="'width: '+progressValue+'%'" :aria-valuenow="progressValue" aria-valuemin="0" aria-valuemax="100">
              <span v-if="progressValue > 14">
                {{ datesRefs.dateFin ? progressValue+'%' : 'En cours' }}
              </span>
            </div>
            <span v-if="progressValue < 15" class="align-self-center ml-2">
                {{ progressValue + '%' }}
            </span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="d-flex align-items-center bg-white border px-2" style="border-radius: 4px">
          <span v-if="datesRefs.dateDebut" :title="datesRefs.dateDebut.format('dddd DD/MM/YYYY à HH:mm:ss')">
            {{ contextualize(datesRefs.dateDebut) }}
          </span>
            <template v-if="datesRefs.dateFin && (!datesRefs.dateDebut || !datesRefs.dateDebut.isSame(datesRefs.dateFin))">
            <i class="fa-solid fa-arrow-right mx-2" v-if="datesRefs.dateFin"></i>
            <span v-if="datesRefs.dateFin" :title="datesRefs.dateFin.format('dddd DD/MM/YYYY à HH:mm:ss')">
              {{ contextualize(datesRefs.dateFin) }}
            </span>
          </template>
        </div>
        <i class="fa-solid fa-check-circle mr-2 align-self-center" :class="isReadAndNotPinnedAndNotEnCours ? 'text-muted' : 'text-success'" v-if="datesRefs.dateFin && datesRefs.dateFin.isSameOrBefore(datesRefs.now)"></i>
      </template>
    </div>
  </li>
  <span v-else-if="previewMode" class="text-muted text-center d-block">Aucun aperçu disponible.</span>
</template>

<script setup>
import moment from 'moment'
import {computed, reactive, ref, toRefs, watch} from 'vue'
import {useNotificationsStore} from "@/Vue/Stores/Notifications";

moment.locale('fr')

const props = defineProps({
  notification: Object,
  nowOverride: moment,
  previewMode: Boolean,
  indexMode: Boolean,
})

const {notification, indexMode, previewMode, nowOverride} = toRefs(props);

const store = previewMode.value || indexMode.value ? undefined : useNotificationsStore();
const evenementEnCours = ref(false);
const progressValue = ref(0);

const isReadAndNotPinnedAndNotEnCours = computed(() => {
  return !indexMode.value && !notification.value.pin && notification.value._dateLecture && !evenementEnCours.value;
})

let datesRefs = {
  dateDebut: undefined,
  dateFin: undefined,
  dateEnvoi: undefined,
  now: undefined,
}

let dureesRefs = {
  fromDebut: undefined,
  fromFin: undefined,
  fromEnvoi: undefined,
  evenement: undefined,
}

if (previewMode.value) {
  datesRefs = reactive(datesRefs);
  dureesRefs = reactive(dureesRefs);
}

if (!previewMode.value) {
  initDates()
} else {
  watch(nowOverride, () => {
    updateDatesAndDurees();
  })
}

let style = reactive({
  border: 'border',
  text: '',
  showIcon: false,
  borderWidth: '',
})

const contextualize = (date) => {
  let dateFormat = date.format('DD/MM/YY')
  let timeFormat = date.format('HH:mm')

  if (datesRefs.now.format('DD/MM/YY') === dateFormat) {
    return timeFormat;
  } else {
    return dateFormat+' '+timeFormat;
  }
}

function initDates() {
  datesRefs.now = nowOverride.value ? nowOverride.value : moment();
  datesRefs.dateEnvoi = (props.notification.envoi) ? moment(props.notification.envoi, 'DD/MM/YYYY HH:mm') : undefined;
  datesRefs.dateFin = (props.notification.finEvenement) ? moment(props.notification.finEvenement, 'DD/MM/YYYY HH:mm') : undefined;
  datesRefs.dateDebut = (props.notification.debutEvenement) ? moment(props.notification.debutEvenement, 'DD/MM/YYYY HH:mm') : (datesRefs.dateFin ? datesRefs.dateEnvoi : undefined);

  dureesRefs.evenement = (datesRefs.dateDebut && datesRefs.dateFin) ? moment.duration(datesRefs.dateFin.diff(datesRefs.dateDebut)) : undefined;
}

function updateDatesAndDurees() {
  datesRefs.now = nowOverride.value ? nowOverride.value : moment();
  const now = datesRefs.now;

  if (previewMode.value) {
    initDates()

    datesRefs.dateDebut = undefined !== datesRefs.dateDebut && datesRefs.dateDebut.isValid() ? datesRefs.dateDebut : undefined;
    datesRefs.dateFin = undefined !== datesRefs.dateFin && datesRefs.dateFin.isValid() ? datesRefs.dateFin : undefined;
    datesRefs.dateEnvoi = undefined !== datesRefs.dateEnvoi && datesRefs.dateEnvoi.isValid() ? datesRefs.dateEnvoi : undefined;
  }

  if (datesRefs.dateEnvoi) {
    dureesRefs.fromEnvoi = moment.duration(now.diff(datesRefs.dateEnvoi));
  }

  if (datesRefs.dateDebut) {
    dureesRefs.fromDebut = moment.duration(now.diff(datesRefs.dateDebut));
  }

  if (datesRefs.dateFin) {
    dureesRefs.fromFin = moment.duration(now.diff(datesRefs.dateFin));
  }

  if (undefined !== dureesRefs.fromDebut) {
    dureesRefs.fromDebut.add(1, 'seconds')
  }

  if (undefined !== dureesRefs.fromFin) {
    dureesRefs.fromFin.add(1, 'seconds')
  }

  let apresDebut = dureesRefs.fromDebut ? (dureesRefs.fromDebut.asSeconds() >= 0) : false;
  let avantFin = dureesRefs.fromFin ? (dureesRefs.fromFin.asSeconds() < 0) : false;
  evenementEnCours.value = dureesRefs.fromDebut && dureesRefs.fromFin ? (apresDebut && avantFin) : ((dureesRefs.fromDebut && apresDebut) || (dureesRefs.fromFin && avantFin));

  progressValue.value = evenementEnCours ? (dureesRefs.evenement ? Math.floor((dureesRefs.fromDebut.asSeconds() / dureesRefs.evenement.asSeconds()) * 100) : 100) : undefined;

  updateStyle();
}

function updateStyle() {
  let criticiteStr = notification.value.criticite + '';

  style.border = 'border';
  style.text = '';
  style.showIcon = false;
  style.borderWidth = '';

  if (isReadAndNotPinnedAndNotEnCours.value) {
    style.border = 'border border-muted';
    style.progress = 'notification-progress-info';
    style.text = 'text-muted';
  } else {
    if (dureesRefs.fromFin === undefined || dureesRefs.fromFin.asMilliseconds() < 0) {
      if (criticiteStr === '2') {
        style.border = 'border border-danger';
        style.progress = 'notification-progress-danger';
        style.text = 'text-danger';
        style.showIcon = true;
        style.borderWidth = '2px';
      } else if (criticiteStr === '1') {
        style.border = 'border border-warning';
        style.progress = 'notification-progress-warning';
          style.text = 'text-warning';
          style.borderWidth = '2px';
      } else {
        style.progress = 'notification-progress-info';
      }
    }
  }
}

updateDatesAndDurees()

if (!previewMode.value) {
  setInterval(() => {
    updateDatesAndDurees()
  }, 1000)
} else {
  watch(notification, () => {
    updateStyle()
  })
}

</script>
