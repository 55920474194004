<template>
    <div>
        <div class="section-title my-3"><span>{{ title }}</span></div>
        <div>
            <div class="my-2" v-for="error in errors">
                <span class="badge badge-secondary mr-2">{{ error.code }}</span> {{ error.message }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['title', 'errors'],
}
</script>
