<template></template>

<script setup>
import {computed, inject, onMounted, onUnmounted, ref, watch} from "vue";
import distance from '@turf/distance';

const map = inject('map')

const coordinates = ref([]);
const lineData = computed(() => ({
    type: 'Feature',
    properties: {},
    geometry: {
        type: 'LineString',
        coordinates: coordinates.value,
    }
}));

const formatLabel = (value) => value < 1 ? `${(value * 1000).toFixed()} m` : `${value.toFixed(2)} km`;

const pointData = computed(() => {
    let sum = 0;

    return {
        type: 'FeatureCollection',
        features: coordinates.value.map((coordinate, index) => {
            if (index > 0) {
                sum += distance(coordinates.value[index - 1], coordinate, { units: 'kilometers' });
            }
            return {
                type: 'Feature',
                id: String(index),
                properties: {
                    distance: formatLabel(sum),
                },
                geometry: {
                    type: 'Point',
                    coordinates: coordinate,
                },
            };
        }),
    };
});

const onClick = (event) => {
    const features = map.value.queryRenderedFeatures(event.point, {
        layers: ['ambuerp_ruler_points'],
    });

    if (!features.length) {
        coordinates.value.push([event.lngLat.lng, event.lngLat.lat]);
    }
};


const onMouseEnterPoint = () => {
    map.value.getCanvas().style.cursor = 'move';
}

const onMouseLeavePoint = () => {
    map.value.getCanvas().style.cursor = 'crosshair';
}

const onMouseDownPoint = (event) => {
    // do not block multitouch actions
    if (event.type === 'touchstart' && event.points.length !== 1) {
        return;
    }

    event.preventDefault();
    const features = event.features;
    if (!features) {
        return;
    }

    const markerIndex = Number(features[0].id);

    map.value.getCanvas().style.cursor = 'grabbing';

    const onMove = (event) => {
        const coords = event.lngLat;
        map.value.getCanvas().style.cursor = 'grabbing';
        coordinates.value[markerIndex] = [coords.lng, coords.lat];
    };

    const onUp = () => {
        map.value.getCanvas().style.cursor = 'move';
        map.value.off('mousemove', onMove);
    };

    map.value.on('mousemove', onMove);
    map.value.once('mouseup', onUp);
}

onMounted(() => {
    map.value.addSource('ambuerp_ruler_lines', {
        type: 'geojson',
        data: lineData.value,
    });
    map.value.addSource('ambuerp_ruler_points', {
        type: 'geojson',
        data: pointData.value,
    });

    map.value.addLayer({
        id: 'ambuerp_ruler_lines',
        type: 'line',
        source: 'ambuerp_ruler_lines',
        paint: {
            'line-color': '#263238',
            'line-width': 2,
        },
    });
    map.value.addLayer({
        id: 'ambuerp_ruler_points',
        type: 'circle',
        source: 'ambuerp_ruler_points',
        paint: {
            'circle-radius': 5,
            'circle-color': '#fff',
            'circle-stroke-width': 2,
            'circle-stroke-color': '#000',
        },
    });
    map.value.addLayer({
        id: 'ambuerp_ruler_labels',
        type: 'symbol',
        source: 'ambuerp_ruler_points',
        layout: {
            'text-field': '{distance}',
            'text-font': ['Noto-Regular'],
            'text-anchor': 'top',
            'text-size': 12,
            'text-offset': [0, 0.8],
        },
        paint: {
            'text-color': '#263238',
            'text-halo-color': '#fff',
            'text-halo-width': 1,
        },
    });

    map.value.getCanvas().style.cursor = 'crosshair';
    map.value.on('click', onClick);
    map.value.on('mouseenter', 'ambuerp_ruler_points', onMouseEnterPoint);
    map.value.on('mouseleave', 'ambuerp_ruler_points', onMouseLeavePoint);
    map.value.on('mousedown', 'ambuerp_ruler_points', onMouseDownPoint);


});

onUnmounted(() => {
    map.value.removeLayer('ambuerp_ruler_lines');
    map.value.removeLayer('ambuerp_ruler_points');
    map.value.removeLayer('ambuerp_ruler_labels');

    map.value.removeSource('ambuerp_ruler_lines');
    map.value.removeSource('ambuerp_ruler_points');

    map.value.getCanvas().style.cursor = '';
    map.value.off('click', onClick);
    map.value.off('mouseenter', 'ambuerp_ruler_points', onMouseEnterPoint);
    map.value.off('mouseleave', 'ambuerp_ruler_points', onMouseLeavePoint);
    map.value.off('mousedown', 'ambuerp_ruler_points', onMouseDownPoint);

});

watch(lineData, () => map.value.getSource('ambuerp_ruler_lines').setData(lineData.value), {deep: true});
watch(pointData, () => map.value.getSource('ambuerp_ruler_points').setData(pointData.value), {deep: true});
</script>