<template>
    <span class="mb-0 d-block">
        <span class="initialism form-error-icon badge" :class="{'badge-warning': 'warning' === item.type, 'badge-info': 'info' === item.type, 'badge-danger': 'error' === item.type}">
            <template v-if="'info' === item.type">
                <i class="fa-solid fa-circle-info" title="Info"></i>
            </template>
            <template v-else-if="'warning' === item.type">
                <i class="fa-solid fa-triangle-exclamation" title="Alerte"></i>
            </template>
            <template v-else>
                Erreur
            </template>
        </span>
        <span class="form-error-message" :class="type !== 'root' ? {'text-warning': 'warning' === item.type, 'text-info': 'info' === item.type} : {}" v-html="item.message"></span>
    </span>
</template>

<script>
    export default {
        props: ['item', 'type'],
    }
</script>