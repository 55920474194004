'use strict';

App.Profil = class {
    constructor(params) {
      this.params = params;

      this.structureSelector = '[name="' + $('#' + this.params.form.structure).attr('name') + '"]';
      this.ajaxRequest = null;

      this._initProfil();
    }

    _updateRoles() {
        let structure = $(this.structureSelector + ':checked').val();
        let estGlobal = $('#' + this.params.form.estGlobal).is(':checked');

        if(this.ajaxRequest) {
            this.ajaxRequest.abort();
            this.ajaxRequest = null;
        }

        $('#role-collection').html('<div class="spinner bg-theme" style="margin:0;"></div>');

        this.ajaxRequest = $.ajax({
            dataType: 'html',
            url: this.params.ajaxRole.replace('/_STRUCTURE_', (structure ? '/' + structure : '')),
            data: {
                estGlobal: estGlobal
            },
            method: 'GET',
            cache: false
        }).done((data) => {
            $('#role-collection').html(data);
        }).fail(() => {
            $('#role-collection').html('<div class="text-danger mt-3"><i class="fa-solid fa-triangle-exclamation"></i> ' + this.params.erreur + '</div>');
        });
    }

    _initProfil() {
        $('body')
            .on('change', this.structureSelector, () => {
                this._updateRoles();
            })
            .on('change', '#' + this.params.form.estGlobal, (event) => {
                let $estGlobal = $(event.currentTarget);

                if($estGlobal.is(':checked')) {
                    $('#structure-row').hide();
                } else {
                    $('#structure-row').show().trigger('resize');
                }

                this._updateRoles();
            })
        ;

        if(this.params.isNew) {
            this._updateRoles();
        }
    }
};