<template>
    <sefi-popup title="Recherche patient" :loading="loading" :result="result" :error="error" :init-form="true" @refresh="refresh">
        <template v-slot:initForm>
            <div class="mx-5">
                <sefi-form-row v-if="!source" v-model="inputData.structure" :errors="errors.structure" component="sefi-form-structure" label="Société" required></sefi-form-row>
                <sefi-form-row v-model="inputData.numeroSecuriteSociale" :errors="errors.numeroSecuriteSociale" component="form-insee" label="Numéro de sécurité sociale" required></sefi-form-row>
                <sefi-form-row v-model="inputData.dateNaissance" :errors="errors.dateNaissance" component="form-date" :props="{picker: true}" label="Date de naissance"></sefi-form-row>
                <sefi-form-row v-model="inputData.rangNaissance" :errors="errors.rangNaissance" label="Rang de naissance" required></sefi-form-row>
                <sefi-form-row v-model="inputData.codeRegime" :errors="errors.codeRegime" label="Code régime"></sefi-form-row>
                <sefi-form-row v-model="inputData.modeTransport" :errors="errors.type" :props="{type: 'radio', choices: modes}" component="form-btn-group" label="Mode de transport" required></sefi-form-row>
                <sefi-form-row v-model="inputData.motifs" :errors="errors.motifs" :props="{type: 'checkbox', choices: motifs}" component="form-btn-group" label="Motifs"></sefi-form-row>
                <sefi-form-row v-model="inputData.dateRealisation" :errors="errors.dateRealisation" component="form-date" :props="{picker: true}" label="Date de réalisation" required></sefi-form-row>
                <sefi-form-row v-model="inputData.datePrescription" :errors="errors.datePrescription" component="form-date" :props="{picker: true}" label="Date de prescription" required></sefi-form-row>
                <sefi-form-row v-model="inputData.dateAccidentTravail" :errors="errors.dateAccidentTravail" component="form-date" :props="{picker: true}" label="Date d'accident du travail"></sefi-form-row>
                <sefi-form-row v-model="inputData.numeroAccidentTravail" :errors="errors.numeroAccidentTravail" label="Numéro d'accident du travail"></sefi-form-row>
            </div>
        </template>
        <template v-slot:initFormButtons>
            <b-btn variant="primary" @click="refresh">
                <i class="fa-solid fa-magnifying-glass fa-fw"></i> Rechercher
            </b-btn>
        </template>
        <template v-slot:resultButtons v-if="success">
            <sefi-result-reporter :enabled="canReporter" :loading="reporterLoading" @click="reporter"></sefi-result-reporter>
        </template>
        <template v-slot:form>
            <div class="mb-2" v-if="!source">
                <sefi-form-widget component="sefi-form-structure" v-model="inputData.structure" :errors="errors.type"></sefi-form-widget>
            </div>
            <div class="mb-2">
                <sefi-form-widget component="form-input-group" v-model="inputData.numeroSecuriteSociale" :props="{label: 'NIR', component: 'form-insee'}" :errors="errors.numeroSecuriteSociale"></sefi-form-widget>
            </div>
            <div class="mb-2">
                <sefi-form-widget component="form-input-group" v-model="inputData.dateNaissance" :props="{label: 'Date naissance', component: 'form-date', picker: true, lunaire: true}" :errors="errors.dateNaissance"></sefi-form-widget>
            </div>
            <div class="mb-2">
                <sefi-form-widget component="form-input-group" v-model="inputData.rangNaissance" :props="{label: 'Rang naissance'}" :errors="errors.rangNaissance"></sefi-form-widget>
            </div>
            <div class="mb-2">
                <sefi-form-widget component="form-input-group" v-model="inputData.codeRegime" :props="{label: 'Code régime'}" :errors="errors.codeRegime"></sefi-form-widget>
            </div>
            <div class="mb-2">
                <sefi-form-widget component="form-btn-group" v-model="inputData.modeTransport" :props="{type: 'radio', choices: modes}" :errors="errors.modeTransport"></sefi-form-widget>
            </div>
            <div class="mb-2">
                <sefi-form-widget component="form-btn-group" v-model="inputData.motifs" :props="{type: 'checkbox', choices: motifs}" :errors="errors.motifs"></sefi-form-widget>
            </div>
            <div class="mb-2">
                <sefi-form-widget component="form-input-group" v-model="inputData.dateRealisation" :props="{label: 'Date réalisation', component: 'form-date', picker: true}" :errors="errors.dateRealisation"></sefi-form-widget>
            </div>
            <div class="mb-2">
                <sefi-form-widget component="form-input-group" v-model="inputData.datePrescription" :props="{label: 'Date prescription', component: 'form-date', picker: true}" :errors="errors.datePrescription"></sefi-form-widget>
            </div>
            <div class="mb-2">
                <sefi-form-widget component="form-input-group" v-model="inputData.dateAccidentTravail" :props="{label: 'Date AT', component: 'form-date', picker: true}" :errors="errors.dateAccidentTravail"></sefi-form-widget>
            </div>
            <div>
                <sefi-form-widget component="form-input-group" v-model="inputData.numeroAccidentTravail" :props="{label: 'N° AT'}" :errors="errors.numeroAccidentTravail"></sefi-form-widget>
            </div>
        </template>
        <template v-slot:result v-if="result">
            <b-tabs v-model="tabIndex" align="center" pills>
                <b-tab :title="tab.title" v-for="(tab, i) in tabs" :key="'tab_'+i">
                    <sefi-result-table v-if="tab.bs" :ref="el => { bsTable[i] = el }" @report="onReport" @canReporter="canReporterBs = {...canReporterBs, [i]: $event}">
                        <tr>
                            <th colspan="3" class="bg-light py-1 text-center">{{ trans('libelle.'+tab.bs.type) }}</th>
                        </tr>
                        <sefi-result-row v-if="source === 'transportForm' && tab.bs.entity" :label="Translator.trans('libelle.patient-existant')" :value="tab.bs.entity.id" :target="tab.bs?.type === 'beneficiaire' ? '#transport_patientBeneficiaireLinkForm' : '#transport_patientAssureLinkForm'" :report="() => reporterPatientLink(tab)">
                            <a :href="path('shared.patient.view', {app, id: tab.bs.entity.id})" target="_blank">
                                <i class="fa-solid fa-link"></i>
                                {{ tab.bs.entity.text }}
                            </a>
                        </sefi-result-row>
                        <sefi-result-row :label="Translator.trans('libelle.numeroSecuriteSociale')" :value="tab.bs.numeroSecuriteSociale" :target="target(tab, 'numeroSecuriteSociale')" @report="reporterPatientIdentite(tab)"></sefi-result-row>
                        <sefi-result-row :label="Translator.trans('libelle.nomUsuel')" :value="tab.bs.etatCivil.nomUsuel" :target="target(tab, 'nomUsuel')" @report="reporterPatientIdentite(tab)"></sefi-result-row>
                        <sefi-result-row :label="Translator.trans('libelle.nomNaissance')" :value="tab.bs.etatCivil.nomNaissance" :target="target(tab, 'nomNaissance')"></sefi-result-row>
                        <sefi-result-row :label="Translator.trans('libelle.prenomUsuel')" :value="tab.bs.etatCivil.prenom" :target="target(tab, 'prenomUsuel')" @report="reporterPatientIdentite(tab)"></sefi-result-row>
                        <sefi-result-row :label="Translator.trans('libelle.naissance')" :value="tab.bs.dateNaissance" :target="target(tab, 'naissance')"></sefi-result-row>
                        <sefi-result-row :label="Translator.trans('libelle.rangNaissance')" :value="tab.bs.rangNaissance" :target="target(tab, 'rangNaissance')"></sefi-result-row>
                        <sefi-result-row :label="Translator.trans('libelle.caisse')" v-if="tab.bs.etatCivil.caisse && (!tab.pec?.[0]?.success || !tab.pec?.[0]?.data?.caisse)" :value="tab.bs.etatCivil.caisse" target="#transport_patientAssure_entity_caisse">
                            <div v-html="tab.bs.etatCivil.caisse.card"></div>
                        </sefi-result-row>
                    </sefi-result-table>
                    <sefi-error v-else-if="!bs.success" :error="bs.error"></sefi-error>

                    <b-tabs v-model="pecTabIndex[i]" v-if="tab.pec?.length" class="mt-4" align="center" :nav-wrapper-class="tab.pec.length <= 1 ? 'd-none' : ''" content-class="m-0" nav-class="border-0 nav-tabs-sm">
                        <b-tab :title="tabPec.context" v-for="(tabPec, j) in tab.pec" :key="'pecTab_'+j">
                            <sefi-result-table v-if="tabPec.success" :ref="el => { pecTable[i+'_'+j] = el }" @canReporter="canReporterPec = {...canReporterPec, [i]: {...(canReporterPec[i] || {}), [j]: $event}}">
                                <tr>
                                    <th colspan="3" class="bg-light py-1 text-center">Droits AMO au {{ tabPec.data.dateContexte }}</th>
                                </tr>
                                <sefi-result-row :label="Translator.trans('libelle.contrat')" :value="tabPec.data.caisse.contrat"></sefi-result-row>
                                <sefi-result-row :label="Translator.trans('libelle.tiersPayantReglementaireAbreviation')" :title="Translator.trans('libelle.tiersPayantReglementaire')" :value="tabPec.data.caisse.tiersPayant">
                                    {{ tabPec.data.caisse.tiersPayant ? 'Oui ': 'Non' }}
                                </sefi-result-row>
                                <sefi-result-row v-if="tabPec.data.dateMaternite" :label="Translator.trans('libelle.dateMaternite')" :value="tabPec.data.dateMaternite" :target="target(tab, 'dateMaternite')"></sefi-result-row>
                                <sefi-result-row :label="Translator.trans('libelle.nature')" :value="tabPec.data.nature" :target="target(tab, 'nature')">
                                    {{ tabPec.data.nature }} ({{ tabPec.data.natureSefi }})
                                </sefi-result-row>
                                <sefi-result-row :label="Translator.trans('libelle.exoneration')" :value="tabPec.data.exoneration" :target="target(tab, 'exoneration')"></sefi-result-row>
                                <sefi-result-row :label="Translator.trans('libelle.caisse')" v-if="tabPec.data.caisse.entity" :value="tabPec.data.caisse.entity" :target="target(tab, 'caisse')">
                                    <div v-html="tabPec.data.caisse.entity.card"></div>
                                </sefi-result-row>
                                <sefi-result-row :label="Translator.trans('libelle.tauxCaisse')" :value="tabPec.data.caisse.taux" :target="target(tab, 'tauxCaisse')"></sefi-result-row>
                                <template v-if="tabPec.data.mutuelle">
                                    <tr>
                                        <th colspan="3" class="bg-light py-1 text-center">Droits AMC au {{ tabPec.data.dateContexte }}</th>
                                    </tr>
                                    <sefi-result-row :label="Translator.trans('libelle.contrat')" :value="tabPec.data.mutuelle.contrat"></sefi-result-row>
                                    <sefi-result-row :label="Translator.trans('libelle.tiersPayantReglementaireAbreviation')" :title="Translator.trans('libelle.tiersPayantReglementaire')" :value="tabPec.data.mutuelle.tiersPayant">
                                        {{ tabPec.data.mutuelle.tiersPayant ? 'Oui ': 'Non' }}
                                    </sefi-result-row>
                                    <sefi-result-row :label="Translator.trans('libelle.mutuelle')" v-if="tabPec.data.mutuelle.entity" :value="tabPec.data.mutuelle.entity" :target="target(tab, 'mutuelle')">
                                        <div v-html="tabPec.data.mutuelle.entity.card"></div>
                                    </sefi-result-row>
                                </template>
                            </sefi-result-table>
                            <sefi-error v-else :error="tabPec.error"></sefi-error>
                        </b-tab>
                    </b-tabs>
                </b-tab>
            </b-tabs>
        </template>
    </sefi-popup>
</template>

<script setup>
import Moment from "moment";
import Capitalize from "@/Vue/Filters/Capitalize";
import {ref, toRefs, computed, onMounted} from "vue";
import Router from "@/App/Router";
import Request from "@/App/Request";
import Translator from "@/App/Translator";

const props = defineProps({data: Object});
const {data} = toRefs(props);

const app = App.Constants.APPLICATION; // todo virer apres merge espace

const result = ref(null);
const canReporterBs = ref({});
const canReporterPec = ref({});
const reporterLoading = ref(false);
const inputData = ref({
    rangNaissance: '1',
    motifs: [],
});
const loading = ref(false);
const error = ref(null);
const tabIndex = ref(null);
const pecTabIndex = ref({})
const motifs = ref([
    {key: 'ALD', label: 'ALD'},
    {key: 'L115', label: 'L115'},
    {key: 'CDP100', label: 'CDP100'},
    {key: 'AT', label: 'AT'},
]);
const modes = ref([
    {key: 'ABA', label: 'Ambu'},
    {key: 'VSL', label: 'VSL'},
    {key: 'ABA/VSL', label: 'Ambu / VSL'},
    {key: 'TXI', label: 'Taxi'},
]);
const bsTable = ref({});
const pecTable = ref({});
const transportBeneficiaireEnabled = ref(null);

const tabs = computed(() => {
    const tabs = [];

    if (('input' in pec.value) || !bs.value.success) {
        tabs.push({
            title: inputData.value.numeroSecuriteSociale + (inputData.value.dateNaissance ? ' (' + inputData.value.dateNaissance+')' : ''),
            bs: null,
            pec: pec.value.input,
        });
    }

    if (bs.value.success) {
        for (const i in bs.value.data) {
            const bsData = bs.value.data[i];
            tabs.push({
                title: bsData.etatCivil.nomUsuel + ' ' + bsData.etatCivil.prenom,
                bs: bsData,
                pec: pec.value[i] || null,
            })
        }
    }

    return tabs;
});
const currentTab = computed(() => tabs.value[tabIndex.value]);

const canReporter = computed(() => null !== tabIndex.value && (canReporterBs.value[tabIndex.value] || (null !== pecTabIndex.value[tabIndex.value] && canReporterPec.value[tabIndex.value]?.[pecTabIndex.value[tabIndex.value]])));
const pec = computed(() => result.value?.pec || {});
const bs = computed(() => result.value?.bs || {});

const success = computed(() => {
    if (bs.value.success) {
        return true;
    }

    for (const i in pec.value) {
        if (pec.value[i]?.[0]?.success) {
            return true;
        }
    }

    return false;
});

const source = computed(() => data.value && data.value.source ? data.value.source : null);

const errors = computed(() => {
    if(error.value) {
        return error.value.errors || [];
    }

    return [];
});

const onReport = () => {
    if (source.value === 'transportForm') {
        const $beneficiaireEnabled = $('#transport_patientBeneficiaire_enabled');
        const type = currentTab.value.bs?.type || 'assure';

        if ('beneficiaire' === type && !$beneficiaireEnabled.is(':checked')) {
            $('#transport_patientBeneficiaire_enabled').prop('checked', true).change();
        }
    }
};

const reporterPatientIdentite = (tab) => {
    if (source.value === 'transportForm') {
        const type = tab.bs?.type || 'assure';
        const $patientLinkForm = $('#transport_patient'+Capitalize(type)+'LinkForm');
        let patientId = tab.bs?.entity?.id;

        if (patientId && patientId !== $patientLinkForm.val()) {
            App.module._unlinkPatient(type);
        }
    }
};

const reporterPatientLink = async (tab) => {
    const patientId = tab.bs?.entity?.id;

    if (patientId) {
        await App.module.fillPatientById(patientId);
    }
};

const reporter = async () => {
    reporterLoading.value = true;
    if (source.value === 'transportForm') {
        const type = currentTab.value.bs?.type || 'assure';

        if (type === 'beneficiaire') {
            const assureIndex = tabs.value.findIndex(tab => tab.bs?.type === 'assure');

            if (-1 !== assureIndex) {
                await bsTable.value[assureIndex]?.reporter();
                await pecTable.value[assureIndex + '_'+pecTabIndex.value[assureIndex]]?.reporter();
            }
        }
    }

    await bsTable.value[tabIndex.value]?.reporter();
    await pecTable.value[tabIndex.value+'_'+pecTabIndex.value[tabIndex.value]]?.reporter();

    reporterLoading.value = false;
};

const target = (tab, field) => {
    if (source.value === 'transportForm') {
        const estBeneficiaire = tab.bs?.type === 'beneficiaire';
        const base = estBeneficiaire ? 'patientBeneficiaire' : 'patientAssure';

        return '#transport_'+base+'_entity_'+field;
    }

    return '[data-field="'+field+'"]';
};

const refresh = () => {
    result.value = null;
    error.value = null;
    loading.value = true;

    Request.postJson(Router.generate('facturation.sefi.patient', {source: source.value}), inputData.value).then((data) => {
        if(data.success) {
            result.value = data.data;

            if (bs.value.success && null !== bs.value.patientIndex) {
                tabIndex.value = bs.value.patientIndex;
            }

            const newPecTabIndex = {};

            for (const index in tabs.value) {
                const tab = tabs.value[index];
                newPecTabIndex[index] = tab.pec && tab.pec.length ? tab.pec.length-1 : null;
            }

            pecTabIndex.value = newPecTabIndex;

            if (source.value === 'factureForm') {
                const $natureJodAmo = $('#facture_naturePiecesJustificativesOuvertureDroitsAmo');

                if ($natureJodAmo.val() !== '9') {
                    $natureJodAmo.val(9).change();
                }
                // todo AMC ?
            }
        } else {
            error.value = data.error;
        }

        loading.value = false;
    });
};

onMounted(() => {
    if(data.value) {
        if (data.value.inputData) {
            inputData.value = data.value.inputData;
        }
        if (source.value === 'patientForm') {
            const codeRegime = ($('[data-field="assure"], [data-field="caisse"]').data('custom') || {}).codeRegime || null;

            inputData.value = {
                structure: {structure: App.Shared.getStructure()},
                codeRegime,
                numeroSecuriteSociale: $('#patient_numeroSecuriteSociale').data('assure') || $('#patient_numeroSecuriteSociale').val(),
                dateNaissance: $('#patient_naissance').val(),
                rangNaissance: $('#patient_rangNaissance').val(),
                dateRealisation: Moment().format('DD/MM/YYYY'),
                datePrescription: Moment().format('DD/MM/YYYY'),
                modeTransport: 'ABA/VSL',
                motifs: [], // TODO
            };
        }
        else if (source.value === 'transportForm') {
            const codeRegime = ($('[data-field="caisse"]').data('custom') || {}).codeRegime || null;
            const tarification = $('.js-tarification input:checked').val();
            let modeTransport = 'ABA/VSL';
            switch (tarification) {
                case '1':
                    modeTransport = 'ABA';
                    break;
                case '2':
                    modeTransport = 'VSL';
                    break;
                case '3':
                    modeTransport = 'TXI'
                    break;
            }

            const exoneration = $('#transport_patientBeneficiaire_entity_exoneration').val() || $('#transport_patientAssure_entity_exoneration').val();
            const motifs = [];

            if (exoneration === '4') {
                motifs.push('ALD');
            }

            if (($('#transport_patientBeneficiaire_entity_tauxCaisse').val() || $('#transport_patientAssure_entity_tauxCaisse').val()) === '100') {
                motifs.push('CDP100');
            }

            inputData.value = {
                structure: {structure: App.Shared.getStructureElement().data('societe')},
                codeRegime,
                numeroSecuriteSociale: $('#transport_patientAssure_entity_numeroSecuriteSociale').val(),
                dateNaissance: $('#transport_patientBeneficiaire_entity_naissance').val() || $('#transport_patientAssure_entity_naissance').val(),
                rangNaissance: $('#transport_patientBeneficiaire_entity_rangNaissance').val() || $('#transport_patientAssure_entity_rangNaissance').val() || 1,
                dateRealisation: $('#transport_trajetAller_rendezVous_date').val() || Moment().format('DD/MM/YYYY'),
                datePrescription: $('#transport_prescription').val() || $('#transport_trajetAller_rendezVous_date').val() || Moment().format('DD/MM/YYYY'),
                modeTransport,
                motifs,
            };
        }
        else if (source.value === 'factureForm') {
            const codeRegime = ($('[data-field="caisse"]').data('custom') || {}).codeRegime || null;
            const $assure = $('#facture_patientAssure');
            const $beneficiaire = $('#facture_patientBeneficiaire');

            const numeroSecuriteSociale = $assure.data('custom').numeroSecuriteSociale;
            const dateNaissance = $beneficiaire.val() ? $beneficiaire.data('custom').naissance : $assure.data('custom').naissance;
            const rangNaissance = $beneficiaire.val() ? $beneficiaire.data('custom').rangNaissance : $assure.data('custom').rangNaissance;

            const exoneration = $('#facture_exoneration').val();
            const motifs = [];

            if (exoneration === '4') {
                motifs.push('ALD');
            } else if (exoneration === '5' && $('#facture_estArticleL115').is(':checked')) {
                motifs.push('L115');
            }

            if ($('#facture_nature').val() === '41') {
                motifs.push('AT');
            }
            if ($('#facture_partCaissePourcent').val() === '100') {
                motifs.push('CDP100');
            }

            const trajet = App.module.vm.trajetsArray[0];
            const dateRealisation = trajet ? trajet.topPriseEnChargeDepartReel.date : $('#facture_date').val() || Moment().format('DD/MM/YYYY');

            inputData.value = {
                structure: {structure: App.Shared.getStructure()},
                codeRegime,
                numeroSecuriteSociale,
                dateNaissance,
                rangNaissance,
                dateRealisation,
                datePrescription: $('#facture_datePrescription').val() || dateRealisation,
                modeTransport: App.module.factureData.codePrestationPec || 'ABA/VSL',
                motifs,
                dateAccidentTravail: $('#facture_dateAccidentTravail').val(),
                numeroAccidentTravail: $('#facture_numeroAccidentTravail').val(),
            };
        }
    }

    if (inputData.value.structure && inputData.value.numeroSecuriteSociale) {
        refresh();
    }
});

</script>
