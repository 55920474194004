'use strict';

import swal from "sweetalert2";
import Moment from "moment";
import Ladda from "ladda";
import Translator from "@/App/Translator";

App.Salaries = class {

    static createType(modele, personnelId, date) {
        let data = {
            modeleTypeId: modele.id,
            personnel: personnelId,
        };

        for(let key in modele) {
            if(!(['id', 'debut', 'fin'].includes(key))) {
                data[key] = modele[key];
            }
        }

        let debut = Moment(modele.debut, 'HH:mm');
        let fin = Moment(modele.fin, 'HH:mm');

        data.debutPrevu = {
            date: date.format('DD/MM/YYYY'),
            time: modele.debut,
        };

        data.finPrevu = {
            date: (fin <= debut ? date.clone().add(1, 'day') : date).format('DD/MM/YYYY'),
            time: modele.fin,
        };

        data._date = date;

        return data;
    }

    static normalizeType(type, blacklist2 = []) {
        let blacklist = ['couleurTexte', 'acquittementAt', 'acquittementDebutPrevu', 'estTravailAbstract', 'indemniteCasseCrouteCalculee', 'hash', '_date', '_index'].concat(blacklist2);
        let ajaxData = {};
        for(let key in type) {
            if(!(blacklist.includes(key))) {
                let value = type[key];
                if(value === false) {
                    continue;
                }
                else if(typeof value === 'object') {
                    value = App.Salaries.normalizeType(value, ['id']);
                }
                ajaxData[key] = value;
            }
        }

        return ajaxData;
    }

    static openTypeSwal(mainType, url, edit, modeles, onSave, onClose, onOpen, structure) {
        swal({
            text: 'Chargement...',
            allowOutsideClick: false,
            onClose: () => {
                $('.clockpicker-popover').remove(); // Clockpicker fix
                if(onClose) {
                    onClose();
                }
            },
            onOpen: () => {
                swal.showLoading();

                if(edit) {
                    $.ajax(mainType ? {url: url+'?initial=true', method: 'POST', data: {
                        data: App.Salaries.normalizeType(mainType, ['id', 'type'])
                    }} : {url, method: 'GET'}).done((data) => {
                        swal.hideLoading();
                        if (undefined !== data.alreadyExists) {
                            swal({
                                text: Translator.trans('salaries.planning.type-existe-deja'),
                                type: 'error',
                                confirmButtonClass: 'bg-danger',
                                confirmButtonText: Translator.trans('action.fermer'),
                            });
                        } else {
                            swal({
                                showConfirmButton: false,
                                showCancelButton: false,
                                html: data.html,
                                width: '800px',
                                onOpen: () => {
                                    let $content = $(swal.getContent());
                                    $content.removeClass('swal2-content');

                                    let handle = () => {
                                        App.Layout.enableTreeSelect();
                                        App.Layout.enableSelect2();
                                        App.Layout.enableEmbed();
                                        App.Layout.enableCustomInputs();
                                        App.Layout.enableTooltip();
                                        App.Layout.enableVisibilityDelayed();

                                        // Dates
                                        let $baseDebutDate = $('#data_debutPrevu_date');
                                        let $baseFinDate = $('#data_finPrevu_date');
                                        let $baseDebutTime = $('#data_debutPrevu_time');
                                        let $baseFinTime = $('#data_finPrevu_time');
                                        let baseDate = Moment($baseDebutDate.val(), 'DD/MM/YYYY');

                                        let updateDates = () => {
                                            $('[id$="debutPrevu_time"], [id$="debutReel_time"]').each(function () {
                                                let $debutTime = $(this);

                                                let debutTimeId = $debutTime.attr('id');
                                                let finTimeId = debutTimeId.replace('debut', 'fin');

                                                let $finTime = $('#' + finTimeId);

                                                let $debutDate = $('#' + debutTimeId.replace('_time', '_date'));
                                                let $finDate = $('#' + finTimeId.replace('_time', '_date'));

                                                let debutTime = Moment($debutTime.val(), 'HH:mm');
                                                let finTime = Moment($finTime.val(), 'HH:mm');

                                                let debutIsNextDay = false;
                                                let finIsNextDay = false;

                                                $debutDate.val(debutTime.isValid() ? baseDate.format('DD/MM/YYYY') : '');
                                                $finDate.val(finTime.isValid() ? baseDate.format('DD/MM/YYYY') : '');

                                                if (debutTime.isValid() && finTime.isValid()) {
                                                    finIsNextDay = finTime.isSameOrBefore(debutTime);
                                                    $finDate.val((finIsNextDay ? baseDate.clone().add(1, 'day') : baseDate).format('DD/MM/YYYY'));
                                                }

                                                // ~ Gestion pause ou tâche sur le second jour uniquement
                                                let baseDebut = Moment($baseDebutDate.val() + ' ' + $baseDebutTime.val(), 'DD/MM/YYYY HH:mm');
                                                let baseFin = Moment($baseFinDate.val() + ' ' + $baseFinTime.val(), 'DD/MM/YYYY HH:mm');
                                                let debut = Moment($debutDate.val() + ' ' + $debutTime.val(), 'DD/MM/YYYY HH:mm');
                                                let fin = Moment($finDate.val() + ' ' + $finTime.val(), 'DD/MM/YYYY HH:mm');

                                                if (baseDebut.isValid() && baseFin.isValid() && debut.isValid() && fin.isValid() && baseDebut.format('DD/MM/YYYY') !== baseFin.format('DD/MM/YYYY') && debut.format('DD/MM/YYYY') === fin.format('DD/MM/YYYY') && fin.isSameOrBefore(baseDebut)) {
                                                    debutIsNextDay = true;
                                                    finIsNextDay = true;
                                                    $debutDate.val(baseDate.clone().add(1, 'day').format('DD/MM/YYYY'));
                                                    $finDate.val(baseDate.clone().add(1, 'day').format('DD/MM/YYYY'));
                                                }

                                                $debutTime.next().find('.js-type-date-sup').toggle(debutIsNextDay);
                                                $finTime.next().find('.js-type-date-sup').toggle(finIsNextDay);
                                            });
                                        };

                                        $('.js-type-time').change(updateDates);

                                        // Pauses & Taches
                                        for (let field of ['pause', 'tache']) {
                                            let selector = '#data_' + field + 'Collection';
                                            let $collection = $(selector);
                                            if ($collection.length && modeles[field] && modeles[field].length) {
                                                let $dropdown = $(selector + 'Dropdown');
                                                for (let typeModele of modeles[field]) {
                                                    if (!structure || typeModele.structure === structure) {
                                                        let type = App.Salaries.createType(typeModele, $('#data_personnel').val(), baseDate);
                                                        let $item = $('<a class="dropdown-item" data-modele="' + typeModele.id + '" href="#"><span style="vertical-align: middle;border-radius: 10px;background-color: #' + type.couleur + ';height: 16px;width: 25px;margin-right: 5px;display: inline-block;"></span>' + type.libelle + '</a>');
                                                        $item.click(function (e) {
                                                            let $pause = App.Layout.addEmbed($collection);
                                                            $pause.find('.js-embed-libelle').text(type.libelle);
                                                            $pause.find('.js-embed-code').text(type.code).css({
                                                                color: '#' + type.couleurTexte,
                                                                background: '#' + type.couleur
                                                            });
                                                            $pause.find('[data-field]').each(function () {
                                                                let value = type[$(this).data('field')];
                                                                let subfield = $(this).data('subfield');

                                                                if (value && subfield && subfield in value) {
                                                                    value = value[subfield];
                                                                }
                                                                $(this).val(value).change();
                                                            });

                                                            if (type.duree !== null) {
                                                                $pause.find('[data-field="debutPrevu"][data-subfield="time"]').val(Moment().format('HH:mm')).change();
                                                                $pause.find('[data-field="finPrevu"][data-subfield="time"]').val(Moment().add(type.duree, 'minutes').format('HH:mm')).change();
                                                            }

                                                            if (type.estPauseRepas) {
                                                                $pause.find('.js-pause-repas-indemnite').show();
                                                            }

                                                            e.preventDefault();
                                                        });
                                                        $dropdown.append($item);
                                                    }
                                                }
                                            } else {
                                                $('#' + field + 'Collection').hide();
                                            }
                                        }

                                        // Indemnite
                                        let $indemniteCollection = $('#data_indemniteCollection');
                                        if ($indemniteCollection.length) {
                                            $('#data_indemniteCollectionDropdown .dropdown-item').click(function (e) {
                                                let indemnite = $(this).data('indemnite');

                                                let $inedmnite = App.Layout.addEmbed($indemniteCollection);
                                                $inedmnite.find('.js-embed-libelle').text(indemnite.libelle);
                                                $inedmnite.find('.js-embed-abreviation').text(indemnite.abreviation);
                                                $inedmnite.find('[data-field]').each(function () {
                                                    let value = indemnite[$(this).data('field')];
                                                    $(this).val(value).change();
                                                });

                                                e.preventDefault();
                                            });
                                        }

                                        // Submit
                                        $('#typeForm').submit((e) => {
                                            let ladda = Ladda.create($('#typeForm [type="submit"]')[0]);
                                            ladda.start();

                                            updateDates();

                                            $.ajax({
                                                url,
                                                method: 'POST',
                                                data: $(e.currentTarget).serialize(),
                                            }).done((data) => {
                                                if (data.html) {
                                                    $content.html(data.html);
                                                    handle();
                                                } else {
                                                    if (data.data) {
                                                        onSave(data.data);
                                                    }
                                                    swal.clickCancel();
                                                }
                                            }).fail((r, status) => {
                                            });

                                            return false;
                                        });
                                    };

                                    handle();

                                    if (onOpen) {
                                        onOpen();
                                    }
                                },
                                onClose: () => {
                                    if (onClose) {
                                        onClose();
                                    }
                                }
                            });
                        }
                    });
                } else {
                    $.get(url).done((data) => {
                        swal.hideLoading();
                        swal({
                            showConfirmButton: false,
                            html: data.html,
                            showCancelButton: false,
                            width: '800px',
                            onOpen: () => {
                                $(swal.getContent()).removeClass('swal2-content');
                                App.Layout.enableTimeline();
                            },
                            onClose: () => {
                                if(onClose) {
                                    onClose();
                                }
                            }
                        });
                    });
                }
            },
        });
    }
};
