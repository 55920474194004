<template>
    <div v-if="trajet">
        <span v-if="origin === 'popover'" title="Calculatrice TAXI" :id="id" class="btn btn-sm p-0 mt-n1 px-2 cursor-pointer js-vue-popover-btn">
            <i class="fa-solid fa-calculator text-theme-poil-de-chameau font-size-14px"></i>
        </span>
        <b-btn v-else variant="secondary" class="d-flex align-items-center input-sm px-2" title="Calculatrice TAXI" :id="id">
            <i class="fa-solid fa-fw fa-calculator font-size-14px"></i>
        </b-btn>
        <Popover :target="id" :ref="id" placement="right" class="calculatrice-taxi-popover" @hide="showRemises = false">
            <template #title>
                <div class="d-flex justify-content-between">
                    <div>Calculatrice TAXI</div>
                    <div>
                        <form-btn-group class="input-sm" v-model="trajet.taxiModeCalcul" :disabled="showRemises || trajet._loading" @change="update( {taxiModeCalcul: $event})" type="radio" :choices="modes"></form-btn-group>
                    </div>
                </div>
            </template>
            <div v-if="error" class="text-danger text-center p-5 m-5">
                <i class="fa-solid fa-triangle-exclamation"></i>
                {{ error }}
            </div>
            <div v-else class="d-flex flex-column justify-content-between flex-1 w-100">
                <div>
                    <div class="p-2 border-top" :class="{'border-bottom': trajet.taxiModeCalcul === 0}">
                        <div class="row">
                            <div :class="hasRemisesBtn ? 'col-11' : 'col-12'">
                                <div class="row">
                                    <div class="col-2" v-if="trajet.taxiModeCalcul === 1">
                                        <b-btn size="sm" block @click="recompute" :disabled="showRemises || trajet._loading">
                                            <i class="fa-solid fa-arrows-rotate"></i>
                                        </b-btn>
                                    </div>
                                    <div class="px-1" :class="trajet.taxiModeCalcul === 1 ? 'col-5 pr-1' : 'col-6'" v-if="forfaitBtn">
                                        <div class="btn-group btn-group-toggle w-100 input-sm">
                                            <form-btn-group-item type="single_checkbox" :disabled="showRemises" :value="trajet.taxiForfait !== null" @change="toggleForfaitBtn" :title="!trajet.taxiForfait && trajet._taxiForfaitSuggestion ? 'Forfait suggéré' : ''" :class="!trajet.taxiForfait && trajet._taxiForfaitSuggestion ? 'text-info' : ''">
                                                <template v-if="!trajet.taxiForfait && trajet._taxiForfaitSuggestion">
                                                    <i class="fa-solid fa-star"></i>
                                                </template>
                                                {{ grilleDetailTaxi.forfaitCollection[forfaitBtn] }}
                                            </form-btn-group-item>
                                        </div>
                                    </div>
                                    <div class="pl-1" :class="trajet.taxiModeCalcul === 1 ? (forfaitBtn ? 'col-5' : 'col-10') : (forfaitBtn ? 'col-6' : 'col-12')">
                                        <div class="btn-group btn-group-toggle w-100 input-sm">
                                            <form-btn-group-item type="single_checkbox" :disabled="showRemises || trajet.taxiForfait" :value="trajet.supplementTaxiPriseEnCharge" @change="update({supplementTaxiPriseEnCharge: !trajet.supplementTaxiPriseEnCharge})">Prise en charge</form-btn-group-item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-1 pl-1" v-if="hasRemisesBtn">
                                <div class="btn-group btn-group-toggle w-100 input-sm">
                                    <form-btn-group-item type="single_checkbox" v-model="showRemises" title="Gérer les remises">
                                        <i class="fa-solid fa-percent"></i>
                                    </form-btn-group-item>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="showRemises" class="row py-5">
                        <div class="col-6 mx-auto">
                            <table class="table table-bordered table-sm m-0 mb-3">
                                <tr v-for="remise in grilleDetailTaxi.remiseCollection">
                                    <td>
                                        <div class="checkbox">
                                            <input :id="'remise_'+remise.id" type="checkbox" class="form-check-input" :disabled="!trajet.taxiForcerRemise" v-model="trajet.taxiRemiseCollection" :value="remise.id" @change="update( {taxiRemiseCollection: trajet.taxiRemiseCollection})" >
                                            <label :for="'remise_'+remise.id">{{ remise.libelle }} (<pourcent :value="remise.remisePourcent"></pourcent>)</label>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <div class="btn-group btn-group-toggle w-100 input-sm">
                                <form-btn-group-item type="single_checkbox" :value="trajet.taxiForcerRemise" @change="update({taxiForcerRemise: $event})">
                                    <i class="fa-solid fa-fw fa-pencil"></i>
                                    Modifier
                                </form-btn-group-item>
                            </div>
                        </div>
                    </div>
                    <template v-else>
                        <template v-if="trajet.taxiModeCalcul === 0">
                            <div class="row py-5">
                                <div class="col-6 mx-auto">
                                    <facture-trajet-field
                                        mode="form"
                                        :field="getField('taxiCompteurMontant')"
                                        :value="trajet.taxiCompteurMontant"
                                        :validation="getValidation('taxiCompteurMontant')"
                                        key="taxiCompteurMontant"
                                        @change="update($event)"
                                    ></facture-trajet-field>
                                    <facture-trajet-field
                                        class="mt-3"
                                        mode="form"
                                        :field="getField('taxiTarif')"
                                        :value="trajet.taxiTarif"
                                        :validation="getValidation('taxiTarif')"
                                        key="taxiTarif"
                                        @change="update($event)"
                                    ></facture-trajet-field>
                                </div>
                            </div>
                        </template>
                        <template v-if="trajet.taxiModeCalcul === 1">
                            <table class="table table-sm m-0 position-relative" :class="{'calculatrice-table-disabled': trajet.taxiForfait}">
                                <tbody>
                                <tr>
                                    <th class="text-center width-1px px-4">Tarif</th>
                                    <template v-if="hasApproche">
                                        <th>Approche</th>
                                        <th class="width-1px"></th>
                                    </template>
                                    <th>Distance</th>
                                    <th class="width-1px"></th>
                                    <th v-if="hasHoraire">Horaire</th>
                                    <th class="width-100px text-right pr-4">Montant</th>
                                </tr>
                                <tr v-for="tarif in tarifs">
                                    <td class="vertical-middle text-center font-size-16px" :class="{'font-weight-bold text-warning': taxiCalculData[tarif].kilometre || taxiCalculData[tarif].kilometreApproche}">{{ tarif }}</td>
                                    <template v-if="hasApproche">
                                        <td>
                                            <form-field component="form-input-group" :props="{class: 'input-sm', suffix: 'km'}" :value="taxiCalculData[tarif].kilometreApproche" @change="updateCalculData(tarif, 'kilometreApproche', $event)"></form-field>
                                        </td>
                                        <td>
                                            <div class="input-group">
                                                <div class="input-sm p-0"></div>
                                                <div class="input-group-append">
                                                    <span class="input-group-text">+</span>
                                                </div>
                                            </div>
                                        </td>
                                    </template>
                                    <td>
                                        <form-field component="form-input-group" :props="{class: 'input-sm', suffix: 'km'}" :value="taxiCalculData[tarif].kilometre" @change="updateCalculData(tarif, 'kilometre', $event)"></form-field>
                                    </td>
                                    <td>
                                        <div class="input-group">
                                            <div class="input-sm p-0"></div>
                                            <div class="input-group-append flex-1">
                                                <span class="input-group-text flex-1">x {{ Euro(grilleDetailTaxi['tarifKilometrique'+tarif+'PrixUnitaire']) }}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td v-if="hasHoraire">
                                        <form-field component="form-input-group" :props="{class: 'input-sm', suffix: 'min'}" :value="taxiCalculData[tarif].horaire" @change="updateCalculData(tarif, 'horaire', $event)"></form-field>
                                    </td>
                                    <td class="vertical-middle text-right text-normal pr-4">
                                        {{ Euro(montantByTarif[tarif]) }}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </template>
                        <template v-if="trajet.taxiModeCalcul === 2">
                            <table class="table table-sm m-0 position-relative" :class="{'calculatrice-table-disabled': trajet.taxiForfait}">
                                <tbody>
                                <tr>
                                    <th class="text-center width-60px">Tarif</th>
                                    <th title="Prix unitaire">P.U.</th>
                                    <th title="Prix unitaire remisé">P.U.R.</th>
                                    <th title="Prix unitaire remisé et abattu">P.U.R.A.</th>
                                    <th class="width-1px">Quantité</th>
                                    <th class="text-center width-60px">Type</th>
                                    <th class="text-right">Montant</th>
                                    <th class="pl-3">
                                        <b-btn variant="success" block @click="addTarifDetailItem" size="xs">
                                            <i class="fa-solid fa-plus"></i>
                                        </b-btn>
                                    </th>
                                </tr>
                                <tr v-for="(item, index) in trajet.taxiTarifDetail" :key="index">
                                    <td>
                                        <form-field component="form-select2" :validation="getDetailValidation(index, 'tarif')" :props="{class: 'input-sm', required: true, choices: {A:'A', B: 'B', C: 'C', D: 'D'}, placeholder: Translator.trans('libelle.tarif')}" :value="item.tarif" @change="updateTarifDetail(index, 'tarif', $event)"></form-field>
                                    </td>
                                    <td>
                                        <form-field component="form-input" :validation="getDetailValidation(index, 'prixUnitaireBase')" :props="{class: 'input-sm'}" :value="item.prixUnitaireBase" @change="updateTarifDetail(index, 'prixUnitaireBase', $event)"></form-field>
                                    </td>
                                    <td>
                                        <form-field component="form-input" :validation="getDetailValidation(index, 'prixUnitaireRemise')" :props="{class: 'input-sm', placeholder: item.prixUnitaireBase}" :value="item.prixUnitaireRemise" @change="updateTarifDetail(index, 'prixUnitaireRemise', $event)"></form-field>
                                    </td>
                                    <td>
                                        <form-field component="form-input" :validation="getDetailValidation(index, 'prixUnitaireRemiseAbattement')" :props="{class: 'input-sm', placeholder: item.prixUnitaireRemise || item.prixUnitaireBase}" :value="item.prixUnitaireRemiseAbattement" @change="updateTarifDetail(index, 'prixUnitaireRemiseAbattement', $event)"></form-field>
                                    </td>
                                    <td>
                                        <form-field component="form-input" :validation="getDetailValidation(index, 'quantite')" :props="{class: 'input-sm'}" :value="item.quantite" @change="updateTarifDetail(index, 'quantite', $event)"></form-field>
                                    </td>
                                    <td>
                                        <form-field component="form-select2" :validation="getDetailValidation(index, 'type')" :props="{class: 'input-sm', required: true, choices: {KM:'KM', HOR: 'H'}, placeholder: Translator.trans('libelle.type')}" :value="item.type" @change="updateTarifDetail(index, 'type', $event)"></form-field>
                                    </td>
                                    <td class="vertical-middle text-right text-normal">
                                        {{ Euro(item._montant) }}
                                    </td>
                                    <td class="pl-3">
                                        <b-btn variant="outline-danger" @click.stop="removeTarifDetailItem(index)" size="sm">
                                            <i class="fa-solid fa-minus"></i>
                                        </b-btn>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </template>
                    </template>
                </div>
                <div class="border-top">
                    <div class="row">
                        <div class="text-center pr-0" v-if="hasApproche" :class="trajet._montantAbattement ? 'col-2' : 'col-3'">
                            <div class="p-2">
                                <div class="font-size-10px">Approche</div>
                                <div class="font-size-16px text-normal line-height-1 text-ellipsis">
                                    <span v-if="trajet.taxiKilometreApproche !== null">
                                        {{ trajet.taxiKilometreApproche }}<small> km</small>
                                    </span>
                                    <span class="text-muted" v-else>-</span>
                                </div>
                            </div>
                        </div>
                        <div class="text-center" :class="{'px-0': hasApproche, 'pr-0': !hasApproche, 'col-2': hasApproche && trajet._montantAbattement, 'col-3': (hasApproche && !trajet._montantAbattement) || (!hasApproche && trajet._montantAbattement), 'col-4': !hasApproche && !trajet._montantAbattement}">
                            <div class="p-2">
                                <div class="font-size-10px">Distance</div>
                                <div class="font-size-16px text-normal line-height-1 text-ellipsis">
                                    <span v-if="trajet.kilometre !== null">
                                        {{ trajet.kilometre }}<small> km</small>
                                    </span>
                                    <span class="text-muted" v-else>-</span>
                                </div>
                            </div>
                        </div>
                        <div class="text-center px-0" :class="hasApproche || trajet._montantAbattement ? 'col-3' : 'col-4'">
                            <div class="p-2">
                                <template v-if="trajet.taxiForfait">
                                    <div class="font-size-10px" :title="grilleDetailTaxi.forfaitCollection[trajet.taxiForfait]">Forfait</div>
                                    <div class="font-size-16px text-normal line-height-1 text-ellipsis">
                                    <span v-if="trajet._taxiForfaitMontant !== null">
                                        {{ Euro(trajet._taxiForfaitMontant) }}
                                    </span>
                                        <span class="text-muted" v-else>-</span>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="font-size-10px">Compteur</div>
                                    <div class="font-size-16px text-normal line-height-1 text-ellipsis">
                                    <span v-if="trajet.taxiCompteurMontant !== null">
                                        {{ Euro(trajet.taxiCompteurMontant) }}
                                    </span>
                                        <span class="text-muted" v-else>-</span>
                                    </div>

                                </template>
                            </div>
                        </div>
                        <div class="text-center" :class="{'px-0': trajet._montantAbattement, 'pl-0': !trajet._montantAbattement, 'col-2-5': hasApproche && trajet._montantAbattement, 'col-3': (hasApproche && !trajet._montantAbattement) || (!hasApproche && trajet._montantAbattement), 'col-4': !hasApproche && !trajet._montantAbattement}">
                            <div class="p-2" v-b-tooltip.hover.html="remiseTooltip">
                                <div class="font-size-10px">Remise</div>
                                <div class="font-size-16px text-normal line-height-1 text-ellipsis">
                                    <span v-if="trajet._taxiRemiseMontant !== null">
                                        -{{ Euro(trajet._taxiRemiseMontant) }}
                                    </span>
                                    <span class="text-muted" v-else>-</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 text-center pl-0" :class="hasApproche ? 'col-2-5' : 'col-3'" v-if="trajet._montantAbattement">
                            <div class="p-2">
                                <div class="font-size-10px">Abattement</div>
                                <div class="font-size-16px text-normal line-height-1 text-ellipsis">
                                    -{{ Euro(trajet._montantAbattement) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Popover>
    </div>
    <div v-else></div>
</template>

<script setup>
import Euro from "@/Vue/Filters/Euro";
import {computed, ref, toRefs, getCurrentInstance, inject, watch} from "vue";
import Popover from '@/Vue/Components/Popover';
import Translator from "@/App/Translator";

const instance = getCurrentInstance();

const emit = defineEmits(['changes']);

const props = defineProps({
    modes: Object,
    trajet: Object,
    origin: String,
});

const {modes, trajet, origin} = toRefs(props);

const tarifs = ['A', 'B', 'C', 'D'];
const showRemises = ref(false);

const hasRemisesBtn = computed(() => {
    return showRemises.value || trajet.value.taxiForcerRemise || grilleDetailTaxi.value.remiseCollection.length > 0;
});
const forfaitBtn = computed(() => {
    return trajet.value.taxiForfait || trajet.value._taxiForfaitSuggestion || grilleDetailTaxi.value.forfaitMinimumPerception || null;
});
const remiseTooltip = computed(() => {
    let html = '<div class="text-left font-size-12px">';

    if (trajet.value._taxiRemiseDetail && Object.keys(trajet.value._taxiRemiseDetail).length) {
        for (const libelle in trajet.value._taxiRemiseDetail) {
            const montant = Euro(trajet.value._taxiRemiseDetail[libelle]);
            html += '<div><strong>'+libelle+'</strong> : '+montant+'</div>'
        }
    } else {
        html += 'Pas de remise';
    }

    html += '</div>';

    return html;
});
const montantByTarif = computed(() => {
    const montantByTarif = {A: null, B: null, C: null, D: null};

    for (const item of trajet.value.taxiTarifDetail || []) {
        montantByTarif[item.tarif] += parseFloat(item._montant);
    }

    return montantByTarif;
});
const taxiCalculData = computed(() => {
    const data = {};

    for (const tarif of tarifs) {
        data[tarif] = (trajet.value.taxiCalculData || {})[tarif] || {};
    }

    return data;
});
const grilleDetailTaxi = computed(() => trajet.value._grilleDetail.taxi || {});
const hasApproche = computed(() => grilleDetailTaxi.value.modeTarifKilometriqueApproche);
const hasHoraire = computed(() => grilleDetailTaxi.value.modeTarifHoraire);
const error = computed(() => {
    if (!trajet.value._grilleDetail.taxi) {
        return 'Aucune tarification n\'est définie à la date du trajet dans la grille Taxi sélectionnée.';
    }

    return null;
});
const id = 'calculatriceTaxi_'+instance.uid;

const getTrajetValidation = inject('getTrajetValidation');

const toggleForfaitBtn = (value) => {
    update({taxiForfait: value ? forfaitBtn.value : null})
};
const getField = (field) => {
    return inject('getField')(field, {origin: origin.value, trajet: trajet.value});
};
const getValidation = (field) => {
    return getTrajetValidation(trajet.value, field);
};
const getDetailValidation = (index, field) => {
    return getTrajetValidation('taxiTarifDetail.'+index+'.'+field);
};
const addTarifDetailItem = () => {
    const taxiTarifDetail = [...trajet.value.taxiTarifDetail, {
        tarif: null,
        type: 'KM',
        prixUnitaireBase: null,
        prixUnitaireRemise: null,
        prixUnitaireRemiseAbattement: null,
        quantite: null,
        _montant: null,
        _montantRetenu: null,
    }];

    update({taxiTarifDetail});
};
const removeTarifDetailItem = (index) => {
    const taxiTarifDetail = [...trajet.value.taxiTarifDetail];
    taxiTarifDetail.splice(index, 1);

    update({taxiTarifDetail});
};
const update = (data) => {
    emit('changes', {...data});
};
const updateTarifDetail = (index, field, value) => {
    const taxiTarifDetail = [...trajet.value.taxiTarifDetail];
    taxiTarifDetail[index] = {
        ...trajet.value.taxiTarifDetail[index],
        [field]: value,
    };

    update({taxiTarifDetail});
};
const updateCalculData = (tarif, field, value) => {
    const data = {...taxiCalculData.value};
    data[tarif][field] = value;

    update({taxiCalculData: data});
};
const recompute = () => {
    update({taxiComputeTarifDetail: true});
};
</script>
