<template>
    <div class="row">
        <tableau-de-bord-widget-compteurs-item icon="icon-directions" variant="info">
            <template #title>{{ Translator.trans('facturation.tableau-de-bord.statistiques.trajetsNonFactures') }}</template>
            <template #content v-if="data">
                {{ data.trajetsNonFactures }} <small v-if="data.montantTotalTrajetsNonFactures[modeMontants] !== null">({{ euro(data.montantTotalTrajetsNonFactures[modeMontants]) }} <small>{{ modeStr}}</small>)</small>
            </template>
        </tableau-de-bord-widget-compteurs-item>
        <tableau-de-bord-widget-compteurs-item icon="ti-files" variant="danger">
            <template #title>{{ Translator.trans('facturation.tableau-de-bord.statistiques.facturesNonSoldees') }}</template>
            <template #content v-if="data">
                {{ data.facturesNonSoldees }} <small v-if="data.montantTotalFacturesNonSoldees !== null">({{ euro(data.montantTotalFacturesNonSoldees) }}<small> TTC</small>)</small>
            </template>
        </tableau-de-bord-widget-compteurs-item>
        <tableau-de-bord-widget-compteurs-item icon="icon-cloud-upload" variant="primary">
            <template #title>{{ Translator.trans('facturation.tableau-de-bord.statistiques.lotsTransmis') }}</template>
            <template #content v-if="data">
                {{ data.lotsTransmis }} <small title="Montant caisse • Montant mutuelle">({{ euro(data.montantTotalCaisseLotsTransmis) }}<small v-if="data.montantTotalCaisseLotsTransmis !== null"> TTC</small> • {{ euro(data.montantTotalMutuelleLotsTransmis) }}<small v-if="data.montantTotalMutuelleLotsTransmis !== null"> TTC</small>)</small>
            </template>
        </tableau-de-bord-widget-compteurs-item>
        <tableau-de-bord-widget-compteurs-item icon="icon-cloud-download" variant="primary">
            <template #title>{{ Translator.trans('facturation.tableau-de-bord.statistiques.retoursNonImpute') }}</template>
            <template #content v-if="data">
                {{ euro(data.montantTotalRetoursNonImpute) }}<small v-if="data.montantTotalRetoursNonImpute !== null"> TTC</small>
            </template>
        </tableau-de-bord-widget-compteurs-item>
        <div class="col-12">
            <div class="white-box mb-2 mt-lg-3 p-2">
                <form-btn-group class="input-sm" v-model="periode" type="radio" :choices="{mois: 'Mois', annee: 'Année'}"></form-btn-group>
            </div>
        </div>
        <tableau-de-bord-widget-compteurs-item icon="icon-drawer" variant="dark" v-if="'mois' === periode">
            <template #title>{{ Translator.trans('facturation.tableau-de-bord.statistiques.ca') }}</template>
            <template #content v-if="data?.caMois[modeMontants]">
                {{ euro(data.caMois[modeMontants]) }}<small> {{ modeStr}}</small>
            </template>
        </tableau-de-bord-widget-compteurs-item>
        <tableau-de-bord-widget-compteurs-item icon="icon-drawer" variant="dark" v-if="'annee' === periode">
            <template #title>{{ Translator.trans('facturation.tableau-de-bord.statistiques.ca') }}</template>
            <template #content v-if="data?.caAnnee[modeMontants]">
                {{ euro(data.caAnnee[modeMontants]) }}<small> {{ modeStr}}</small>
            </template>
        </tableau-de-bord-widget-compteurs-item>

        <tableau-de-bord-widget-compteurs-item icon="ti-wallet" variant="success" v-if="'mois' === periode">
            <template #title>{{ Translator.trans('facturation.tableau-de-bord.statistiques.reglementsMois') }}</template>
            <template #content v-if="data">
                {{ euro(data.reglementsMois) }} <small v-if="data.reglementsMois !== null">TTC</small>
            </template>
        </tableau-de-bord-widget-compteurs-item>
        <tableau-de-bord-widget-compteurs-item icon="ti-wallet" variant="success" v-if="'annee' === periode">
            <template #title>{{ Translator.trans('facturation.tableau-de-bord.statistiques.reglementsAnnee') }}</template>
            <template #content v-if="data">
                {{ euro(data.reglementsAnnee) }}<small v-if="data.reglementsAnnee !== null"> TTC</small>
            </template>
        </tableau-de-bord-widget-compteurs-item>

        <tableau-de-bord-widget-compteurs-item icon="icon-directions" variant="info" v-if="'mois' === periode">
            <template #title>{{ Translator.trans('facturation.tableau-de-bord.statistiques.trajetsMois') }}</template>
            <template #content v-if="data">
                {{ data.trajetsRealisesMois }}
            </template>
        </tableau-de-bord-widget-compteurs-item>
        <tableau-de-bord-widget-compteurs-item icon="icon-directions" variant="info" v-if="'annee' === periode">
            <template #title>{{ Translator.trans('facturation.tableau-de-bord.statistiques.trajetsAnnee') }}</template>
            <template #content v-if="data">
                {{ data.trajetsRealisesAnnee }}
            </template>
        </tableau-de-bord-widget-compteurs-item>

        <tableau-de-bord-widget-compteurs-item icon="icon-people" variant="primary" v-if="'mois' === periode">
            <template #title>{{ Translator.trans('facturation.tableau-de-bord.statistiques.patientsMois') }}</template>
            <template #content v-if="data">
                {{ data.patientsTransportesMois }}
            </template>
        </tableau-de-bord-widget-compteurs-item>
        <tableau-de-bord-widget-compteurs-item icon="icon-people" variant="primary" v-if="'annee' === periode">
            <template #title>{{ Translator.trans('facturation.tableau-de-bord.statistiques.patientsAnnee') }}</template>
            <template #content v-if="data">
                {{ data.patientsTransportesAnnee }}
            </template>
        </tableau-de-bord-widget-compteurs-item>
    </div>
</template>

<script setup>
import {computed, onMounted, ref, toRefs, watch} from "vue";
import Router from "@/App/Router";
import Translator from "@/App/Translator";
import {useRequest} from "@/Vue/Composables/Request";
import euro from "@/Vue/Filters/Euro";

const {postJson, abort} = useRequest();

const props = defineProps({
    structures: Array,
    tab: String,
    modeMontants: String,
});

const {structures, tab, modeMontants} = toRefs(props);


const data = ref(null);
const periode = ref('annee');

const modeStr = computed(() => modeMontants.value.toUpperCase());

const refresh = () => {
    data.value = null;

    if(!structures.value.length) {
        abort();
        return;
    }

    postJson(Router.generate('facturation.tableau-de-bord.compteurs'), {
        tableau_de_bord_filter: {
            structure: structures.value,
        }
    }).then((res) => {
        data.value = res;
    });
};

watch(structures, () => refresh());

onMounted(() => {
    refresh()
});

</script>
