<template>
    <div class="form-group row">
        <label class="col-form-label col-sm-3 form-control-label text-right" :for="id">{{ label }}<template v-if="required !== undefined && required !== false">&nbsp;<span class="text-danger" title="Ce champ est obligatoire.">*</span></template></label>
        <div class="col-sm-9">
            <sefi-form-widget :id="id" :props="props" :component="component" :errors="errors" :value="value" @input="$emit('input', $event)"></sefi-form-widget>
        </div>
    </div>
</template>

<script setup>
import {computed, getCurrentInstance, toRefs} from "vue";

const instance = getCurrentInstance();

const allProps = defineProps(['component', 'value', 'errors', 'props', 'label', 'required']);
const {component, value, errors, props, label, required} = toRefs(allProps);

const isInvalid = computed(() => errors.value && errors.value.length);
const id = 'form_row_'+instance.uid;
</script>
