'use strict';

import Router from "@/App/Router";
import Translator from "@/App/Translator";

App.Grille = class {
    constructor() {
        this.ajaxRequest = null;

        this._initGrille();
    }

    _updateDetails() {
        let structure = App.Shared.getStructure();

        if (this.ajaxRequest) {
            this.ajaxRequest.abort();
            this.ajaxRequest = null;
        }

        $('#js-detail-collection').html('<div class="spinner bg-theme m-0"></div>');

        this.ajaxRequest = $.ajax({
            dataType: 'html',
            url: Router.generate('salaries.coefficient.grille.detail.ajax', {structure}),
            method: 'GET',
            cache: false
        }).done((data) => {
            $('#js-detail-collection').html(data);
        }).fail(() => {
            $('#js-detail-collection').html('<div class="text-danger mt-3"><i class="fa-solid fa-triangle-exclamation"></i> ' + Translator.trans('salaries.coefficient.grille.erreur-details') + '</div>');
        });
    }

    _initGrille() {
        $('body')
            .on('change', 'select[name="grille[structure]"], input[name="grille[structure]"]', () => {
                this._updateDetails();
            })
        ;
    }
};