<template>
    <div>
        <div class="panel panel-compact">
            <div class="panel-heading d-flex justify-content-between bg-light py-3 px-4">
                <div>Comptes clients</div>
            </div>
            <div class="panel-wrapper">
                <div class="panel-body">
                    <b-table table-class="table-compact" ref="clientsTable" :busy="loading" responsive no-sort-reset show-empty bordered striped :items="clients" :fields="clientsFields" :per-page="perPage" :current-page="currentPage" sort-by="libelle" primary-key="id">
                        <template #empty>
                            <span class="text-muted">{{ Translator.trans('libelle.aucun-element') }}</span>
                        </template>
                        <template #table-busy>
                            <div class="p-3">
                                <b-spinner class="mr-2"></b-spinner> Chargement…
                            </div>
                        </template>
                        <template #cell(createdAt)="{value}">
                            {{ value.format('DD/MM/YYYY HH:mm') }}
                        </template>
                        <template #cell(action)="{item}">
                            <a :href="Router.generate('administration.compte.view', {id: item.id})" target="_blank" :role="item._roles.ROLE_ADMINISTRATION_COMPTE_VIEW" :title="Translator.trans('action.voir')" class="btn btn-table btn-info">
                                <i class="fa-solid fa-fw fa-eye"></i>
                            </a>
                        </template>
                    </b-table>
                    <table-pagination :count="clients.length" :per-page="perPage" v-model="currentPage"></table-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import Moment from "moment";
import Translator from "@/App/Translator";
import Request from "@/App/Request";
import Router from "@/App/Router";
import {useRequest} from "@/Vue/Composables/Request";

const {getJson} = useRequest();

const loading = ref(false);
const perPage = ref(15);
const currentPage = ref(0);
const clients = ref([]);

const emit = defineEmits(['refresh', 'error']);

const clientsFields = [
    {key: 'libelle', label: Translator.trans('libelle.libelle'), sortable: true},
    {key: 'createdAt', label: Translator.trans('libelle.debut'), sortable: true, formatter: (value) => Moment(value, 'YYYY-MM-DD HH:mm')},
    {key: '_counts.societes', label: Translator.trans('libelle.societeCollection'), sortable: true, thClass: 'width-1px'},
    {key: '_counts.vehicules', label: Translator.trans('libelle.vehiculeCollection'), sortable: true, thClass: 'width-1px'},
    {key: '_counts.tlas', label: Translator.trans('libelle.tla'), sortable: true, thClass: 'width-1px'},
    {key: '_counts.geolocs', label: Translator.trans('libelle.geoloc'), sortable: true, thClass: 'width-1px'},
    {key: '_counts.personnels', label: Translator.trans('libelle.personnelCollection'), sortable: true, thClass: 'width-1px'},
    {key: '_counts.creditsSms', label: 'Crédits SMS', sortable: true, thClass: 'text-nowrap width-1px'},
    {key: '_counts.requetesViaMichelin', label: 'Requêtes ViaMichelin', sortable: true, thClass: 'text-nowrap width-1px'},
    {key: 'action', label: Translator.trans('libelle.actions'), thClass: 'width-1px', tdClass: 'text-center'}
];

const refresh = () => {
    loading.value = true;

    getJson(Router.generate('administration.tableau-de-bord.clients')).then((data) => {
        clients.value = data;
        emit('refresh');
    }, (err) => emit('error', err)).finally(() => {
        loading.value = false;
    });
};

onMounted(() => refresh());

</script>
