<template>
    <Geocodage :data="data" @changes="onChanges" placement="bottom-start" :organismes="true" :small="context.origin === 'popover'"></Geocodage>
</template>

<script setup>
import Geocodage from '@/Vue/Components/Geocodage.vue';
import {toRefs, computed} from "vue";

const emit = defineEmits(['changes']);

const props = defineProps({
    context: Object,
    suffix: String,
});
const {context, suffix} = toRefs(props);
const data = computed(() => {
    const data = context.value.trajet ?? context.value.data;

    if (data) {
        return {
            adresse: data['adresse' + suffix.value],
            ville: data['ville' + suffix.value],
            codePostal: data['codePostal' + suffix.value],
            codeInsee: data['codeInsee' + suffix.value],
            latitudeDegre: data['latitudeDegre' + suffix.value],
            longitudeDegre: data['longitudeDegre' + suffix.value],
            precision: data['precision' + suffix.value],
        };
    }

    return {};
});

const onChanges = (data) => {
    emit('changes', Object.fromEntries(Object.entries(data).map(([k, v]) => [k+suffix.value, v])));
}
</script>