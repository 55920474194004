'use strict';

App.Referentiel.Assistant = class {};

App.Referentiel.Assistant.AppareilVehicule = class {
    constructor(params) {
        $('.js-assistant-appareil-action input').change(function(){
            const $action = $(this).closest('.js-assistant-appareil-action');
            const bouton = $action.find('input:checked').val();
            const index = $action.data('index');
            $('#js-assistant-appareil-appareil-' + index).toggle(bouton === '' + params.actions.associer);
            $('#js-assistant-appareil-bureau-' + index).toggle(bouton === '' + params.actions.creer);
        })

        $('.js-assistant-vehicule-action input').change(function(){
            const $action = $(this).closest('.js-assistant-vehicule-action');
            const bouton = $action.find('input:checked').val();
            const index = $action.data('index');
            $('#js-assistant-vehicule-vehicule-' + index).toggle(bouton === '' + params.actions.associer);
            $('#js-assistant-vehicule-bureau-' + index).toggle(bouton === '' + params.actions.creer);
        })
    }
};

App.Referentiel.Assistant.Structure = class {
    constructor(params) {
        $('.js-assistant-structure-action input').change(function() {
            const $action = $(this).closest('.js-assistant-structure-action');
            const bouton = $action.find('input:checked').val();
            const index = $action.data('index');
            $('#js-assistant-structure-' + index).toggle(bouton === '' + params.actions.associercreer);
        })
    }
}