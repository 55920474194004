'use strict';

App.Referentiel.Personnel = class {};

App.Referentiel.Personnel.Utilisateur = class {
    constructor(params) {
        const $bienvenue = $('#utilisateur_personnel_envoyerBienvenue');

        $bienvenue.change(() => {
            $('#envoyerBienvenueHelp').toggle($bienvenue.is(':checked'));
        });

        $('#utilisateur_personnel_definirMotDePasse').change((e) => {
            const checked = $(e.currentTarget).is(':checked');

            $('#envoyerBienvenueNoPassword').toggle(!checked);
            $('#envoyerBienvenuePassword').toggle(checked);

            $('#plainPassword').toggle(checked);
            $('#utilisateur_personnel_plainPassword_first').prop('required', checked);
            $('#utilisateur_personnel_plainPassword_second').prop('required', checked);

            if (!params.id) {
                $bienvenue.prop('disabled', !checked);
            }

            if (!checked && !params.id) {
                $bienvenue.prop('checked', true).change();
            }
        });
    }
};

App.Referentiel.Personnel.Appareil = class {};

App.Referentiel.Personnel.Appareil.Index = class  {
    constructor(params) {
        App.Referentiel.Sync.checkSync('appareil', params.ids);
    }
};
