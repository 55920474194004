<template>
    <div ref="el" v-if="visible" class="edit-in-place" :style="style">
        <div class="edit-in-place-arrow"></div>
        <div class="edit-in-place-content">
            <div v-if="loading" class="height-38px d-flex align-items-center justify-content-center">
                <span class="spinner-border" role="status">
                    <span class="sr-only">Chargement...</span>
                </span>
            </div>
            <template v-else>
                <time-picker event="input" v-if="params.type === 'top'" type="text" class="form-control" :class="{'is-invalid': error}" v-model="newValue" @input="onInput"></time-picker>
                <form-select2-autocomplete :key="field" :ref="field" v-else-if="params.type === 'autocomplete'" :placeholder="Translator.trans('libelle.'+field)" :value="value" @input="$emit('input', $event)" :route="params.route" :route-params="autocompleteParams"></form-select2-autocomplete>
                <span class="invalid-feedback d-block" v-if="error">{{ error }}</span>
            </template>
        </div>
    </div>
</template>

<script setup>
import Translator from "@/App/Translator";
import {inject, computed, nextTick, onMounted, ref, toRefs, watch} from "vue";
import {useRegulationStore} from "@/Vue/Stores/Regulation";
import {storeToRefs} from "pinia";

const regulationStore = useRegulationStore();
const {currentTrajet, currentTrajetId} = storeToRefs(regulationStore);

const emit = defineEmits(['input']);
const props = defineProps(['field', 'element', 'value', 'error', 'loading']);

const {field, element, value, errors, loading} = toRefs(props);

const el = ref();
const position = ref(null);
const style = ref({});
const select2Timeout = ref(null);
const editStarted = ref(false);
const newValue = ref(null);

const editInPlaceFields = inject('editInPlaceFields');

const params = computed(() => {
    if(field.value) {
        return editInPlaceFields[field.value];
    }

    return {};
});

const visible = computed(() => field.value && element.value);
const width = computed(() => params.value.type === 'autocomplete' ? 242 : 90);

const autocompleteParams = computed(() => {
    const params = {id: currentTrajetId.value};

    if (['equipage', 'personnel1', 'personnel2'].includes(field.value)) {
        params.rendezVousDate = currentTrajet.value.rendezVous.date;
        params.rendezVousTime = currentTrajet.value.rendezVous.time;
    }

    if (['equipage', 'vehicule'].includes(field.value)) {
        params.categorie = currentTrajet.value.transport.categorie.id;
    }

    if (['equipage', 'personnel1', 'personnel2'].includes(field.value)) {
        params.tarification = currentTrajet.value.tarification;
    }

    if ('personnel1' === field.value) {
        params.otherPersonnelId = currentTrajet.value.personnel2;
    } else if ('personnel2' === field.value) {
        params.otherPersonnelId = currentTrajet.value.personnel1;
    }

    return params;
});

const onInput = (event) => {
    editStarted.value = true;
    emit('input', event);
};

const update = () => {
    editStarted.value = false;

    nextTick(() => {
        if(visible.value) {
            const target = element.value.$refs[field.value].$el;

            if(target) {
                const elPosition = $(target).position();
                position.value = {
                    left: (elPosition.left - width.value/2 + target.offsetWidth/2).toFixed(),
                    top: (elPosition.top + target.offsetHeight).toFixed(),
                };
            }

            if(!loading.value) {
                nextTick(() => {
                    if(params.value.type === 'autocomplete') {
                        if(select2Timeout.value) {
                            clearTimeout(select2Timeout.value);
                            select2Timeout.value = null;
                        }

                        select2Timeout.value = setTimeout(() => {
                            $(el.value).find('select').select2('open');
                        }, 150);
                    } else if(params.value.type === 'top') {
                        $(el.value).find('input').focus();
                    }
                });
            }
        } else {
            position.value = null;
        }
    });
};

watch(value, () => {
    if(!editStarted.value) {
        newValue.value = value.value;
    }
});

watch([loading, visible, field], () => update());

watch(position, (newPosition, oldPosition) => {
    let newStyle = {
        width: width.value + 'px'
    };
    if (newPosition) {
        newStyle.transform = `translate3d(${newPosition.left}px, ${newPosition.top}px, 0px)`;

        if (oldPosition) {
            newStyle.transition = '.15s';
        }
    }

    style.value = newStyle;
});

onMounted(() => {
    window.addEventListener('resize', update);
});

</script>
