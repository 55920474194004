<template>
    <Marker :lngLat="lngLat">
        <template #content>
            <transition name="cluster" appear duration="1000">
                <div class="cluster leading-none text-base marker-ripple bg-white bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-full border-[3px] border-gray-700 text-gray-700 cursor-pointer w-[38px] h-[38px] flex items-center justify-center font-medium" @click="onClick">
                    {{ count }}
                </div>
            </transition>
        </template>
    </Marker>
</template>

<script setup>
import Marker from '@/Vue/Components/RegulationV2/Map/Base/Marker';
import {toRefs, inject} from "vue";

const props = defineProps({
    count: Number,
    id: Number,
    lngLat: [Array, Object],
});

const {count, id, lngLat} = toRefs(props);
const map = inject('map');
const sourceId = inject('id');

const onClick = async () => {
    const zoom = await map.value.getSource('ambuerp_'+sourceId.value).getClusterExpansionZoom(id.value);

    map.value.easeTo({
        center: lngLat.value,
        zoom,
    });
};
</script>
