<template>
    <div class="row">
        <div class="col">
            <div class="box-lot-facture-nombre">
                {{ lot.dossierCollection.length }}
            </div>
            <div class="box-lot-facture-libelle text-muted">{{ Translator.trans(lot.dossierCollection.length === 1 ? 'libelle.facture':'libelle.factures') }}</div>
        </div>
        <div class="col">
            <div class="box-lot-facture-nombre">{{ totalPieceCount }}</div>
            <div class="box-lot-facture-libelle text-muted">Pièces</div>
        </div>
    </div>
</template>

<script setup>
import Translator from "@/App/Translator";
import {computed, toRefs} from "vue";

const props = defineProps({
    lot: Object,
});

const {lot} = toRefs(props);

const totalPieceCount = computed(() => lot.value.dossierCollection.reduce((res, item) => (res += item.pieceCollection.length, res), 0));
</script>
