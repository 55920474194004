<template>
    <span>
        <pourcent v-if="value.pourcent1 !== null" :value="value.pourcent1"></pourcent>
        <montant v-else :value="value.montant1"></montant>
        <template v-if="value.montant2 !== null || value.pourcent2 !== null">
             +
            <pourcent v-if="value.pourcent2 !== null" :value="value.pourcent2"></pourcent>
            <montant v-else :value="value.montant2"></montant>
        </template>
    </span>
</template>

<script>
    export default {
        props: ['value'],
    }
</script>