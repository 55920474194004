<template>
    <div>
        <tableau-de-bord-panel title="Factures brouillon">
            <b-table table-class="table-compact" ref="facturesTable" :busy="loading" responsive no-sort-reset show-empty bordered striped :items="factures" :fields="facturesFields" :per-page="perPage" :current-page="facturesCurrentPage" sort-by="date" primary-key="id">
                <template #empty>
                    <span class="text-muted">{{ Translator.trans('libelle.aucun-element') }}</span>
                </template>
                <template #table-busy>
                    <div class="p-3">
                        <b-spinner class="mr-2"></b-spinner> Chargement…
                    </div>
                </template>
                <template #cell(date)="{value}">
                    {{ value.format('DD/MM/YYYY') }}
                </template>
                <template #cell(_type)="{value}">
                    <span v-html="value"></span>
                </template>
                <template #cell(montantTouteTaxe)="{value}">
                    {{ Euro(value) }}
                </template>
                <template #cell(patient)="{item, value}">
                    <fallback-link v-if="value" target="_blank" :role="item._roles.VOTE_SHARED_PATIENT_VIEW" :url="Router.generate('shared.patient.view', {app: 'facturation', id: value.id})">
                        {{ value.text }}
                    </fallback-link>
                </template>
                <template #cell(structure)="{value}">
                    <a target="_blank" :href="Router.generate('referentiel.structure.view', {id: value.id})" :title="value.libelleComplet" class="badge badge-block badge-secondary label-outline text-nowrap">
                        {{ value.libelle }}
                    </a>
                </template>
                <template #cell(action)="{item}">
                    <a target="_blank" v-if="item._roles.VOTE_FACTURATION_FACTURE_VIEW" :href="Router.generate('facturation.facture.view', {id: item.id})" :title="Translator.trans('action.voir')" class="btn btn-table btn-info">
                        <i class="fa-solid fa-fw fa-eye"></i>
                    </a>
                    <a target="_blank" v-if="item._roles.VOTE_FACTURATION_FACTURE_EDIT" :href="Router.generate('facturation.facture.edit', {id: item.id})" :title="Translator.trans('action.modifier')" class="btn btn-table btn-success">
                        <i class="fa-solid fa-fw fa-pencil"></i>
                    </a>
                </template>
            </b-table>
            <table-pagination :count="factures.length" :per-page="perPage" v-model="facturesCurrentPage"></table-pagination>
        </tableau-de-bord-panel>
        <tableau-de-bord-panel title="Séries à facturer">
            <b-table table-class="table-compact" ref="seriesTable" :busy="loading" responsive no-sort-reset show-empty bordered striped :items="series" :fields="seriesFields" :per-page="perPage" :current-page="seriesCurrentPage" sort-by="_estimation" sort-desc primary-key="id">
                <template #empty>
                    <span class="text-muted">{{ Translator.trans('libelle.aucun-element') }}</span>
                </template>
                <template #table-busy>
                    <div class="p-3">
                        <b-spinner class="mr-2"></b-spinner> Chargement…
                    </div>
                </template>
                <template #cell(_text)="{item, value}">
                    <fallback-link target="_blank" :role="item._roles.VOTE_SHARED_SERIE_VIEW" :url="Router.generate('shared.serie.view', {app: 'facturation', id: item.id})">
                        {{ value }}
                    </fallback-link>
                </template>
                <template #cell(_estimation)="{value}">
                    {{ Euro(value) }}
                </template>
                <template #cell(motif)="{value}">
                    <motif :motif="value"></motif>
                </template>
                <template #cell(patient)="{item, value}">
                    <fallback-link target="_blank" :role="item._roles.VOTE_SHARED_PATIENT_VIEW" :url="Router.generate('shared.patient.view', {app: 'facturation', id: value.id})">
                        {{ value.text }}
                    </fallback-link>
                </template>
                <template #cell(_etat)="{value}">
                    <span v-html="value"></span>
                </template>
                <template #cell(structure)="{value}">
                    <a target="_blank" :href="Router.generate('referentiel.structure.view', {id: value.id})" :title="value.libelleComplet" class="badge badge-block badge-secondary label-outline text-nowrap">
                        {{ value.libelle }}
                    </a>
                </template>
                <template #cell(action)="{item}">
                    <a target="_blank" v-if="item._roles.VOTE_SHARED_SERIE_TRAJET_LIST" :href="Router.generate('shared.serie.trajet.index', {app: 'facturation', serie: item.id})" title="Lister les trajets à facturer" class="btn btn-table btn-secondary">
                        <i class="fa-solid fa-fw fa-list"></i>
                    </a>
                    <a target="_blank" v-if="item._roles.ROLE_FACTURATION_SERIE_FACTURER" :href="Router.generate('shared.serie.facturer', {app: 'facturation', id: item.id})" :title="Translator.trans('action.facturer')" class="btn btn-table btn-primary">
                        <i class="fa-solid fa-fw fa-euro"></i>
                    </a>
                </template>
            </b-table>
            <table-pagination :count="series.length" :per-page="perPage" v-model="seriesCurrentPage"></table-pagination>
        </tableau-de-bord-panel>
        <tableau-de-bord-panel title="Contrats à facturer">
            <b-table table-class="table-compact" ref="contratsTable" :busy="loading" responsive no-sort-reset show-empty bordered striped :items="contrats" :fields="contratsFields" :per-page="perPage" :current-page="contratsCurrentPage" sort-by="_estimation" sort-desc primary-key="id">
                <template #empty>
                    <span class="text-muted">{{ Translator.trans('libelle.aucun-element') }}</span>
                </template>
                <template #table-busy>
                    <div class="p-3">
                        <b-spinner class="mr-2"></b-spinner> Chargement…
                    </div>
                </template>
                <template #cell(_text)="{item}">
                    <fallback-link target="_blank" :role="item._roles.ROLE_FACTURATION_CONTRAT_VIEW" :url="Router.generate('facturation.contrat.view', {id: item.id})">
                        {{ item.libelle }}<template v-if="item.lot"> > {{ item.lot.libelle }}</template>
                    </fallback-link>
                </template>
                <template #cell(_estimation)="{value}">
                    {{ Euro(value) }}
                </template>
                <template #cell(structure)="{value}">
                    <a target="_blank" :href="Router.generate('referentiel.structure.view', {id: value.id})" :title="value.libelleComplet" class="badge badge-block badge-secondary label-outline text-nowrap">
                        {{ value.libelle }}
                    </a>
                </template>
                <template #cell(action)="{item}">
                    <a target="_blank" v-if="item._roles.ROLE_FACTURATION_CONTRAT_TRAJET_LIST" :href="Router.generate('facturation.contrat.trajet.index', item.lot ? {contrat: item.id, trajet_filter: {etat: ['a_facturer', 'realise'], lot: item.lot.id}} : {contrat: item.id})" title="Lister les trajets à facturer" class="btn btn-table btn-secondary">
                        <i class="fa-solid fa-fw fa-list"></i>
                    </a>
                    <a target="_blank" v-if="item._roles.ROLE_FACTURATION_CONTRAT_FACTURER" :href="Router.generate('facturation.contrat.facturer', {id: item.id, lot: item.lot ? item.lot.id : undefined})" :title="Translator.trans('action.facturer')" class="btn btn-table btn-primary">
                        <i class="fa-solid fa-fw fa-euro"></i>
                    </a>
                </template>
            </b-table>
            <table-pagination :count="contrats.length" :per-page="perPage" v-model="contratsCurrentPage"></table-pagination>
        </tableau-de-bord-panel>
    </div>
</template>
<script setup>
import {computed, onMounted, ref, toRefs, watch} from "vue";
import Router from "@/App/Router";
import Translator from "@/App/Translator";
import Moment from "moment";
import Euro from "@/Vue/Filters/Euro";
import {useRequest} from "@/Vue/Composables/Request";

const {postJson, abort} = useRequest();

const emit = defineEmits(['refresh', 'error']);
const props = defineProps({
    structures: Array,
    tab: String,
});
const loading = ref(false);

const {structures, tab} = toRefs(props);

const factures = ref([]);
const contrats = ref([]);
const series = ref([]);

const refresh = () => {
    if(!structures.value.length) {
        abort();
        loading.value = false;
        emit('refresh');

        return;
    }

    loading.value = true;
    postJson(Router.generate('facturation.tableau-de-bord.facture'), {
        tableau_de_bord_filter: {
            structure: structures.value,
        }
    }).then((data) => {
        contrats.value = data.contrats;
        series.value = data.series;
        factures.value = data.factures;

        loading.value = false;
        emit('refresh');
    }, (err) => emit('error', err));
}

const perPage = 15;
const facturesCurrentPage = ref(1);
const contratsCurrentPage = ref(1);
const seriesCurrentPage = ref(1);

const facturesFields = computed(() => [
    {key: 'numero', label: Translator.trans('libelle.numero'), thClass: 'width-1px', tdClass: 'text-normal', sortable: true},
    {key: 'date', label: Translator.trans('libelle.date'), thClass: 'width-1px', sortable: true, formatter: (value) => Moment(value, 'YYYY-MM-DD')},
    {key: 'commentaire', label: Translator.trans('libelle.commentaire'), sortable: true},
    {key: 'patient', label: Translator.trans('libelle.patient'), sortable: true},
    {key: 'montantTouteTaxe', label: Translator.trans('libelle.montant-total'), sortable: true, tdClass: 'text-right', thClass: 'width-1px text-nowrap'},
    {key: '_type', label: Translator.trans('libelle.type'), thClass: 'width-1px', tdClass: 'text-nowrap'},
    structures.value.length > 1 ? {key: 'structure', label: Translator.trans('libelle.structure'), sortable: true, thClass: 'width-1px'} : null,
    {key: 'action', label: Translator.trans('libelle.actions'), thClass: 'width-1px text-center', tdClass: 'text-right text-nowrap'},
]);

const contratsFields = computed(() => [
    {key: '_text', label: Translator.trans('libelle.libelle'), sortable: true},
    {key: '_count', label: Translator.trans('libelle.nombre-de-trajets'), tdClass: 'text-right', thClass: 'width-1px text-nowrap'},
    {key: '_estimation', label: Translator.trans('libelle.estimationMontantTouteTaxe'), sortable: true, tdClass: 'text-right', thClass: 'width-1px text-nowrap'},
    structures.value.length > 1 ? {key: 'structure', label: Translator.trans('libelle.structure'), sortable: true, thClass: 'width-1px'} : null,
    {key: 'action', label: Translator.trans('libelle.actions'), thClass: 'width-1px text-center', tdClass: 'text-center text-nowrap'},
]);

const seriesFields = computed(() => [
    {key: '_text', label: Translator.trans('libelle.libelle'), sortable: true, thClass: 'width-1px', tdClass: 'text-nowrap'},
    {key: 'patient', label: Translator.trans('libelle.patient'), sortable: true},
    {key: 'motif', label: Translator.trans('libelle.motif'), sortable: true},
    {key: '_etat', label: Translator.trans('libelle.etat'), thClass: 'width-1px', tdClass: 'text-nowrap'},
    {key: '_count', label: Translator.trans('libelle.nombre-de-trajets'), tdClass: 'text-right', thClass: 'width-1px text-nowrap'},
    {key: '_estimation', label: Translator.trans('libelle.estimationMontantTouteTaxe'), sortable: true, tdClass: 'text-right', thClass: 'width-1px text-nowrap'},
    structures.value.length > 1 ? {key: 'structure', label: Translator.trans('libelle.bureau'), sortable: true, thClass: 'width-1px'} : null,
    {key: 'action', label: Translator.trans('libelle.actions'), thClass: 'width-1px text-center', tdClass: 'text-center text-nowrap'},
]);


onMounted(() => refresh());

watch(structures, refresh);
</script>
