<template>
    <slot></slot>
</template>

<script setup>
import {computed, inject, onMounted, onUnmounted, provide, toRefs, watch} from "vue";
import arrowLocationGraySvg from '@/../images/arrow-location-gray.svg';
import arrowLocationOrangeSvg from '@/../images/arrow-location-orange.svg';
import arrowLocationRedSvg from '@/../images/arrow-location-red.svg';
import bbox from "@turf/bbox";

const emit = defineEmits(['click']);
const map = inject('map')

const props = defineProps({
    id: String,
    data: [String, Object],
    autoFit: Boolean,
    fitOptions: Object,
})

const {id, data, autoFit, fitOptions} = toRefs(props);

provide('id', id);

const mapPadding = inject('mapPadding');

const fit = () => {
    map.value.fitBounds(bounds.value, {
        padding: 50,
        ...fitOptions.value
    })
}

watch (mapPadding, () => {
    if (autoFit.value) {
        fit();
    }
});

const bounds = computed(() => bbox(data.value));

watch(bounds, () => {
    if (autoFit.value) {
        fit();
    }
}, {immediate: true});

onMounted(() => {
    map.value.addSource('ambuerp_'+id.value, {
        type: 'geojson',
        data: data.value,
        generateId: true,
    })

    const icons = {
        arrowGray: arrowLocationGraySvg,
        arrowOrange: arrowLocationOrangeSvg,
        arrowRed: arrowLocationRedSvg,
    };

    for (const icon in icons) {
        if (!map.value.hasImage(icon)) {
            const svgImage = new Image(30, 40);
            svgImage.crossOrigin = "Anonymous";
            svgImage.onload = () => {
                map.value.addImage(icon, svgImage)
            }
            svgImage.src = icons[icon];
        }
    }

    map.value.addLayer({
        'id': 'ambuerp_'+id.value+'_arrow',
        'type': 'symbol',
        'source': 'ambuerp_'+id.value,
        'layout': {
            'icon-image': ['get', 'icon'],
            'icon-size': .5,
            'icon-offset': [0, -5],
            'icon-rotate': ['get', 'angle'],
            'icon-allow-overlap': true,
        },
        'filter': ['has', 'angle'],
    });

    map.value.addLayer({
        'id': 'ambuerp_'+id.value+'_dot',
        'type': 'circle',
        'source': 'ambuerp_'+id.value,
        'paint': {
            'circle-radius': 5,
            'circle-color': "#fff",
            'circle-stroke-color': "#64748b",
            'circle-stroke-width': 3,
        },
        'filter': ['!', ['has', 'angle']],
    });

    for (const layer of ['ambuerp_'+id.value+'_dot', 'ambuerp_'+id.value+'_arrow']) {
        map.value.on('click', layer, (e) => {
            emit('click', e.features[0].properties.index);
        });

        map.value.on('mouseenter', layer, () => {
            map.value.getCanvas().style.cursor = 'pointer';
        });

        map.value.on('mouseleave', layer, () => {
            map.value.getCanvas().style.cursor = '';
        });
    }
});

watch(data, () => map.value.getSource('ambuerp_'+id.value).setData(data.value));

onUnmounted(() => {
    if (map.value.getLayer('ambuerp_'+id.value+'_dot')) {
        map.value.removeLayer('ambuerp_'+id.value+'_dot');
    }
    if (map.value.getLayer('ambuerp_'+id.value+'_arrow')) {
        map.value.removeLayer('ambuerp_'+id.value+'_arrow');
    }

    if (map.value.getSource('ambuerp_'+id.value)) {
        map.value.removeSource('ambuerp_'+id.value);
    }
})

</script>