<template>
    <slot name="items" :features="items"></slot>
    <slot name="clusters" :features="clusters">
        <template v-for="(feature, key) in clusters" :key="key" >
            <MapCluster :id="feature.properties.cluster_id" :count="feature.properties.point_count" :lng-lat="feature.geometry.coordinates"></MapCluster>
        </template>
    </slot>
</template>

<script setup>
import {inject, ref, onMounted, onUnmounted, toRefs, watch, provide, computed} from "vue";
import MapCluster from "@/Vue/Components/RegulationV2/Map/Base/Cluster.vue";

const emit = defineEmits(['items']);
const map = inject('map')

const props = defineProps({
    id: String,
    data: [String, Object],
    activeFeature: Object,
    minZoom: {type: Number, default: 0},
})

const {id, data, activeFeature, minZoom} = toRefs(props);

provide('id', id);

const features = ref({});
const items = computed(() => Object.fromEntries(Object.entries(features.value).filter(([id, feature]) => !feature.properties.cluster)));
const clusters = computed(() => Object.fromEntries(Object.entries(features.value).filter(([id, feature]) => feature.properties.cluster)));

const update = (dataUpdate = false) => {
    const newFeatures = map.value.querySourceFeatures('ambuerp_'+id.value).reduce((res, item) => (res[item.properties.key ?? item.id] = item, res), {});

    if (activeFeature.value) {
        const key = activeFeature.value.properties.key;

        if (!(key in newFeatures)) {
            newFeatures[key] = activeFeature.value;
        }
    }

    for (const key in features.value) {
        if (!(key in newFeatures)) {
            delete features.value[key];
        }
    }

    for (const key in newFeatures) {
        if (!(key in features.value) || dataUpdate) {
            features.value[key] = newFeatures[key];
        }
    }
}

watch(items, () => emit('items', items.value));

onMounted(() => {
    map.value.on('data', (e) =>  {
        if (e.sourceId === 'ambuerp_'+id.value && e.sourceDataType !== 'metadata' && map.value.isSourceLoaded('ambuerp_'+id.value)) {
            update(true);
        }
    });
    map.value.on('move', () => update);
    map.value.on('moveend', () => update);
    map.value.on('zoomend', () => {
        update();
        setTimeout(update);
    });
    map.value.on('idle', () => update); // todo, workaround car au moveend querySourceFeatures ne retourne pas les features a jour

    map.value.addSource('ambuerp_'+id.value, {
        type: 'geojson',
        data: data.value,
        generateId: true,
        cluster: true,
        clusterMaxZoom: 12, // todo trouver un moyen de faire un radius dynamique en fonction du niveau de zoom
        // 'clusterRadius': 30,
    })

    map.value.addLayer({
        id: 'ambuerp_'+id.value,
        type: 'circle',
        source: 'ambuerp_'+id.value,
        minzoom: minZoom.value,
        paint: {
            'circle-radius': 0
        }
    });


});

watch(data, () => map.value.getSource('ambuerp_'+id.value).setData(data.value));
watch(activeFeature, () => update());

onUnmounted(() => {
    if (map.value.getLayer('ambuerp_'+id.value)) {
        map.value.removeLayer('ambuerp_'+id.value);
    }

    if (map.value.getSource('ambuerp_'+id.value)) {
        map.value.removeSource('ambuerp_'+id.value);
    }
})

</script>