'use strict';

import {createApp} from 'vue';

App.MonProfil.Notification = class {}

App.MonProfil.Notification.Index = class {
    constructor(params = {}) {
        this.params = params;

        createApp({
            delimiters: ['[[', ']]'],
            data: () => params.vueData,
            mounted() {
                document.dispatchEvent(new Event('app:vuejs:mounted'));
                $('#js-notifications-loader').remove();
            }
        }).mount('#app');
    }
}
