'use strict';

App.Administration = class {};

App.Administration.applications = {
    regulation: {
        name: 'Régulation',
        icon: 'fa-solid fa-calendar-days',
        color: '#66c4ed',
    },
    geoloc: {
        name: 'Géolocalisation',
        icon: 'fa-regular fa-compass',
        color: '#884DA7',
    },
    salaries: {
        name: 'Espace Salariés',
        icon: 'fa-solid fa-users',
        color: '#9dd037',
    },
    facturation: {
        name: 'Facturation',
        icon: 'fa-regular fa-credit-card',
        color: '#BA9662',
    },
    referentiel: {
        name: 'Référentiel',
        icon: 'fa-solid fa-database',
        color: '#ff8c01',
    },
    administration: {
        name: 'Administration',
        icon: 'fa-solid fa-user-secret',
        color: '#5A5A5A',
    },
    ressources: {
        name: 'Ressources',
        icon: 'fa-solid fa-box-archive',
        color: '#edac00',
    },
};