<template>
    <MapGeojson id="search" :data="searchGeoJson">
        <template #items="{features}">
            <template v-for="feature in features" :key="feature.properties.id" >
                <MapSearchPin :lng-lat="feature.geometry.coordinates" :item="searchFlatResults[feature.properties.index]" v-if="feature.properties.index in searchFlatResults"></MapSearchPin>
            </template>
        </template>
    </MapGeojson>
</template>

<script setup>
import MapGeojson from "@/Vue/Components/RegulationV2/Map/Base/Geojson";
import MapSearchPin from "@/Vue/Components/RegulationV2/Map/Search/Pin";
import {useGeolocalisationStore} from "@/Vue/Stores/RegulationV2/Geolocalisation";
import {storeToRefs} from "pinia";
import {watch, inject, computed} from "vue";
import {LngLatBounds} from "maplibre-gl";

const map = inject('map');

const geolocalisationStore = useGeolocalisationStore();
const {searchGeoJson, searchFlatResults, searchActiveResult, searchQuery, searchActive, searchPreviousCamera} = storeToRefs(geolocalisationStore);

const snapshotCamera = () => {
    searchPreviousCamera.value = {
        center:  map.value.getCenter(),
        zoom:  map.value.getZoom(),
    };
};

watch(searchActiveResult, () => {
    if (searchActiveResult.value) {
        if ('' === searchQuery.value) {
            snapshotCamera();
        }

        map.value.flyTo({
            center: [searchActiveResult.value.longitude, searchActiveResult.value.latitude],
            zoom: 15,
            curve: 0.5,
            speed: 2,
            maxDuration: 1500,
        })
    }
});

const bounds = computed(() => {
    const coordinates = searchGeoJson.value.features.map((feature) => feature.geometry.coordinates);

    if (!coordinates.length) {
        return null;
    }

    return coordinates.reduce((bounds, coord) => bounds.extend(coord), new LngLatBounds(coordinates[0], coordinates[0]))
});

watch(bounds, () => {
    if (bounds.value && searchQuery.value !== '') {
        snapshotCamera();
        map.value.fitBounds(bounds.value, {
            padding: 50,
        })
    }
});

watch(searchActive, () => {
    if (!searchActive.value && searchPreviousCamera.value) {
        map.value.flyTo(searchPreviousCamera.value);
        searchPreviousCamera.value = null;
    }
})

</script>