'use strict';

import {createApp} from 'vue';
import Request from "@/App/Request";
import Router from "@/App/Router";

App.Facturation.Trajet = class {};

App.Facturation.Trajet.Index = class {
    constructor(params) {
        this.params = params;

        this._initPanier();
        this._initMontantCols();
        this.params.vm = this.vm;
        App.Shared.initContextMenu(params);
        App.Shared.initHoverCards();
    }

    _initMontantCols() {
        $('.js-montant-col-toggle').click(() => {
            $('.js-montant-ttc').toggle();
            $('.js-montant-ht').toggle();
        })
    }

    _initPanier() {
        this.vm = createApp({
            delimiters: ['[[', ']]'],
            data: () => ({
                groups: {
                    0: {label: 'Libre', icon: 'fa-solid fa-star', color: 'dark'},
                    1: {label: 'AMBU', icon: 'im-ambulance', color: 'info'},
                    2: {label: 'VSL', icon: 'im-car', color: 'info'},
                    3: {label: 'TAXI', icon: 'im-car2', color: 'warning'},
                    4: {label: 'TPMR', icon: 'im-accessibility2', color: 'info'},
                },
                trajets: [],
                societesById: {},
                loading: false,
                redirecting: false,
            }),
            mounted() {
                this.trajets = JSON.parse(window.localStorage.getItem('ambuerp_trajet_panier')) || [];

                document.dispatchEvent(new Event('app:vuejs:mounted'));
            },
            watch: {
                trajets() {
                    window.localStorage.setItem('ambuerp_trajet_panier', JSON.stringify(this.trajets));
                }
            },
            methods: {
                onPopoverShow(event) {
                    if(this.count) {
                        this.loading = true;

                        return Request.postJson(Router.generate('facturation.trajet.panier.ajax'), {trajets: this.trajets}).then((data) => {
                            this.trajets = data.trajets;
                            this.societesById = data.societes;

                            this.loading = false;
                        });
                    }
                },
                add(id, tarification, societe) {
                    if(!(id in this.trajetsById)) {
                        this.trajets.push({id, tarification, societe});
                    }
                },
                remove(id) {
                    const index = this.trajetIds.indexOf(id);
                    this.trajets.splice(index, 1);
                },
                removeSociete(id, $event) {
                    delete this.societesById[id];
                    this.trajets = this.trajets.filter((trajet) => trajet.societe !== id);
                    $event.stopPropagation();
                },
                clear() {
                    this.trajets = [];
                },
                facturer(type) {
                    this.redirecting = true;

                    return Request.postJson(Router.generate('facturation.trajet.panier.facturer.ajax', {type}), {trajets: this.trajetIds}).then(() => {
                        window.localStorage.setItem('ambuerp_trajet_panier', JSON.stringify([]));
                        window.location.href = Router.generate('facturation.facture.new', {type});
                    });
                },
                addSearch() {
                    this.loading = true;

                    Request.getJson(Router.generate('facturation.trajet.panier.ajouter-recherche.ajax')+window.location.search).then((data) => {
                        this.trajets = data.trajets;
                        this.societesById = data.societes;

                        this.loading = false;
                    });
                },
            },
            computed: {
                trajetIds() {
                    return Object.keys(this.trajetsById);
                },
                trajetsById() {
                    return this.trajets.reduce((res, item) => (res[item.id] = item, res), {});
                },
                trajetsByTarification() {
                    return this.trajets.reduce((res, item) => ((res[item.tarification] = res[item.tarification] || []).push(item), res), {});
                },
                trajetsBySociete() {
                    return this.trajets.reduce((res, item) => ((res[item.societe] = res[item.societe] || []).push(item), res), {});
                },
                count() {
                    return this.trajets.length;
                },
                valid() {
                    return Object.keys(this.trajetsBySociete).length === 1;
                }
            }
        }).mount('#app');
    }
};
