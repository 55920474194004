'use strict';

import Vue, {createApp} from "vue";
import moment from 'moment';
import Router from "@/App/Router";
import Translator from "@/App/Translator";

App.Administration.Notification = class {};

App.Administration.Notification.Form = class {
    constructor(params) {
        const formId = params.formId;
        moment.locale('fr')

        createApp({
            data: () => ({
                notificationData: {
                    titre: params.data.titre ?? '',
                    contenu: params.data.contenu ?? '',
                    envoi: params.data.envoi ?? '',
                    pin: params.data.pin ?? '',
                    pinBandeau: params.data.pinBandeau ?? '',
                    criticite: params.data.criticite ?? '',
                    debutEvenement: params.data.debutEvenement ?? '',
                    finEvenement: params.data.finEvenement ?? '',
                },
                nowOverrideInput: 0,
                formId: params.formId,
            }),
            computed: {
                nowOverride() {
                    let inputval = this.nowOverrideInput;

                    let debutEvt = this.notificationData.debutEvenement ? moment(this.notificationData.debutEvenement, 'DD/MM/YYYY HH:mm') : (this.notificationData.envoi && this.notificationData.finEvenement ?  moment(this.notificationData.envoi, 'DD/MM/YYYY HH:mm') : undefined);
                    let finEvt = this.notificationData.finEvenement ? moment(this.notificationData.finEvenement, 'DD/MM/YYYY HH:mm') : undefined;
                    let envoi = this.notificationData.envoi ? moment(this.notificationData.envoi, 'DD/MM/YYYY HH:mm') : undefined;

                    return nowOverrideCalc(inputval, debutEvt, finEvt, envoi);
                }
            },
            mounted() {
                for (let field in this.notificationData) {
                    $('input[name="'+formId+'[' + field + ']"], textArea[name="'+formId+'[' + field + ']"]').on('input', (event) => {
                        let $inputElt = $(event.target);
                        this.notificationData[field] =  $inputElt.attr('type') === 'checkbox' ? $inputElt.is(':checked') : $inputElt.val();
                    });
                }

                document.dispatchEvent(new Event('app:vuejs:mounted'));
            }
        }).mount('form[name='+formId+']');
    }
}

App.Administration.Notification.View = class {
    constructor(params) {
        moment.locale('fr')

        createApp({
            data: () => ({
                notificationData: {
                    titre: params.data.titre,
                    contenu: params.data.contenu,
                    envoi: params.data.envoi,
                    pin: params.data.pin,
                    pinBandeau: params.data.pinBandeau,
                    criticite: params.data.criticite,
                    debutEvenement: params.data.debutEvenement,
                    finEvenement: params.data.finEvenement,
                },
                nowOverrideInput: 0,
                nowOverride: null,
            }),
            watch: {
                nowOverrideInput(newOverrideInput) {
                    let debutEvt = this.notificationData.debutEvenement ? moment(this.notificationData.debutEvenement, 'DD/MM/YYYY HH:mm') : (this.notificationData.envoi && this.notificationData.finEvenement ?  moment(this.notificationData.envoi, 'DD/MM/YYYY HH:mm') : undefined);
                    let finEvt = this.notificationData.finEvenement ? moment(this.notificationData.finEvenement, 'DD/MM/YYYY HH:mm') : undefined;
                    let envoi = this.notificationData.envoi ? moment(this.notificationData.envoi, 'DD/MM/YYYY HH:mm') : undefined;

                    this.nowOverride = nowOverrideCalc(newOverrideInput, debutEvt, finEvt, envoi);
                }
            },
            mounted() {
                let debutEvt = this.notificationData.debutEvenement ? moment(this.notificationData.debutEvenement, 'DD/MM/YYYY HH:mm') : (this.notificationData.envoi && this.notificationData.finEvenement ?  moment(this.notificationData.envoi, 'DD/MM/YYYY HH:mm') : undefined);
                let finEvt = this.notificationData.finEvenement ? moment(this.notificationData.finEvenement, 'DD/MM/YYYY HH:mm') : undefined;
                let envoi = this.notificationData.envoi ? moment(this.notificationData.envoi, 'DD/MM/YYYY HH:mm') : undefined;

                this.nowOverride = nowOverrideCalc(this.nowOverrideInput, debutEvt, finEvt, envoi);
            }
        }).mount('#js-notification-preview');

        $('#destinataires-modal').on('shown.bs.modal', function () {
            showDestinataires(params.data.id);
        });
    }
}

function nowOverrideCalc(inputval, debutEvt, finEvt, envoi) {
    if (!debutEvt && !envoi && !finEvt) {
        return null;
    }

    let isBeforeEVt = inputval < 0;
    let isAfterEvt = inputval >= 100;
    let rangePercentage = (isBeforeEVt ? 100 - (inputval * -2) : (isAfterEvt ? (inputval - 100) * 2 : inputval)) / 100;

    let duration2d = moment.duration(2, 'd');
    let duration1d = moment.duration(1, 'd');

    if (!debutEvt && !finEvt) {
        debutEvt = envoi.clone().add(duration2d);
        finEvt = debutEvt.clone().add(duration1d);
    }

    let dateDebutRange = isBeforeEVt ? debutEvt.clone().subtract(duration2d) : (isAfterEvt ? (finEvt ? finEvt : debutEvt.clone().add(duration1d)) : debutEvt);
    let dateFinRange = isBeforeEVt ? debutEvt : (isAfterEvt ? (finEvt ? finEvt.clone().add(duration2d) : debutEvt.clone().add(duration2d)) : (finEvt ?? debutEvt.clone().add(duration1d)));

    return dateDebutRange.add(Math.floor(dateFinRange.diff(dateDebutRange) * rangePercentage), 'ms');
}

function showDestinataires(id) {
    let elt = $('#js-previsualiser-destinataires-content');
    elt.empty();
    elt.append($.parseHTML('<div class="text-center"><i class="fa-duotone fa-loader fa-spin-pulse fa-lg"></i></div>'));

    $.ajax({
        url: Router.generate('administration.notification.destinataires.ajax', {id}),
        method: 'GET',
    }).done((data) => {
        elt.empty();
        if (data.length) {
            let tableElt = $.parseHTML('<table class="w-100"></table>');
            elt.append(tableElt);
            for (let item of data) {
                let route = undefined;
                let routeParams = undefined;
                let theme = undefined;
                if ('personnel' === item.type) {
                    route = 'referentiel.personnel.utilisateur.view';
                    routeParams = {personnel: item.id};
                    theme = 'citrouille';
                } else {
                    route = 'administration.utilisateur.view'
                    routeParams = {id: item.id};
                    theme = 'anthracite';
                }

                let destinataireEltStr = '<tr class="border-bottom"><td class="p-1" ">'+item.string+'</td><td class="width-1px text-nowrap"><a href="'+Router.generate(route, routeParams)+'" target="_blank" class="btn btn-sm btn-theme-'+theme+' text-white">Voir<i class="ml-2 fa-solid fa-arrow-up-right-from-square"></i></a></td></tr>';
                elt.find('table').append($.parseHTML(destinataireEltStr))
            }
        } else {
            elt.append($.parseHTML('<span class="text-muted">'+Translator.trans('libelle.aucun-element')+'</span>'))
        }
    }).fail(() => {
        elt.append($.parseHTML('<span class="text-muted">'+Translator.trans('erreur.titre.500')+'</span>'))
    });
}