<template>
    <div>
        <div class="panel panel-compact">
            <div class="panel-heading d-flex justify-content-between bg-light py-3 px-4">
                <div>Websocket</div>
            </div>
            <div class="panel-wrapper">
                <div class="panel-body">
                    <div class="d-flex">
                        <div class="supervision-status">
                            <div class="supervision-status-value">
                                <div class="text-info" v-if="null === webSocketOnline">
                                    <i class="fa-solid fa-spinner fa-pulse"></i>
                                </div>
                                <div class="text-success" v-else-if="webSocketOnline">
                                    <i class="fa-solid fa-check" :title="websocketUptime"></i>
                                </div>
                                <div class="text-danger" v-else>
                                    <i class="fa-solid fa-xmark"></i>
                                </div>
                            </div>
                            <div class="supervision-status-label">Statut</div>
                        </div>
                        <div class="supervision-status">
                            <div class="supervision-status-value">{{ webSocketClients.length }}</div>
                            <div class="supervision-status-label">Connexions</div>
                        </div>
                        <div class="supervision-status">
                            <div class="supervision-status-value">{{ Object.keys(webSocketClientsByUser).length }} </div>
                            <div class="supervision-status-label">Utilisateurs</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="panel panel-compact">
            <div class="panel-heading d-flex justify-content-between bg-light py-3 px-4">
                <div>Serveur</div>
            </div>
            <div class="panel-wrapper">
                <div class="panel-body">
                    <div class="table-responsive mb-0">
                        <table class="table table-bordered table-compact">
                            <tbody>
                            <tr>
                                <th class="width-1px text-nowrap">Hostname</th>
                                <td>{{ server.hostname }}</td>
                            </tr>
                            <tr>
                                <th class="width-1px text-nowrap">Version AmbuERP</th>
                                <td>{{ server.version }}</td>
                            </tr>
                            <tr>
                                <th class="width-1px text-nowrap">Version Symfony</th>
                                <td>
                                    <i v-if="server.symfony_latest !== null && server.symfony_version !== server.symfony_latest" class="fa-solid fa-triangle-exclamation text-warning" :title="'Nouvelle version disponible : '+ server.symfony_latest"></i>
                                    <span>{{ server.symfony_version }}</span>
                                    <span v-if="server.symfony_eol">(EOL {{ server.symfony_eol }})</span>
                                </td>
                            </tr>
                            <tr>
                                <th class="width-1px text-nowrap">Version PHP</th>
                                <td>{{ server.php_version }}</td>
                            </tr>
                            <tr>
                                <th class="width-1px text-nowrap">Stage</th>
                                <td>{{ server.stage }}</td>
                            </tr>
                            <tr>
                                <th class="width-1px text-nowrap">Git</th>
                                <td class="text-ellipsis">[{{ server.branch }}] <span :title="server.commit">{{ server.commit }}</span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import moment from "moment";
import {ref, toRefs, watch, computed, onMounted} from "vue";

const props = defineProps(['webSocket', 'server']);
const {webSocket, server} = toRefs(props);

const clients = ref([]);
const websocketUptime = ref(0);
const webSocketOnline = ref(null);

const webSocketServer = computed(() => webSocket.value.server || {});
const webSocketClients = computed(() => webSocket.value.clients || []);
const webSocketClientsByUser = computed(() => {
    let users = {};

    webSocketClients.value.forEach((client) => {
        if(!(client.user.id in users)) {
            users[client.user.id] = [];
        }

        users[client.user.id].push(client);
    });

    return users;
});

const updateWebSocketUptime = () => {
    if(webSocket.value.server.startTime) {
        websocketUptime.value = moment(webSocket.value.server.startTime * 1000, 'x').fromNow();
    } else {
        websocketUptime.value = null;
    }
};

watch(webSocketServer, () => updateWebSocketUptime());

onMounted(() => {
    if (App.webSocket.isInit()) {
        webSocketOnline.value = App.webSocket.isOnline();
    }

    document.addEventListener('websocket:status', (e) => {
        webSocketOnline.value = e.detail.online;
    });
});

</script>
