<template></template>

<script setup>
import {ScaleControl} from "maplibre-gl";
import {inject, onMounted, onUnmounted} from 'vue';

const map = inject('map');
const control = new ScaleControl({
    // maxWidth: 80,
    unit: 'metric'
});

onMounted(() => map.value.addControl(control, 'bottom-right'));
onUnmounted(() => map.value.removeControl(control));
</script>