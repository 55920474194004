<template>
    <div v-if="type === 'root' && items && items.length">
        <div class="p-3 my-3 alert js-vue-validation" :class="{'alert-danger': group === 'error', 'alert-warning': group === 'warning', 'alert-info': group === 'info'}" v-for="groupItems, group in itemsByGroup">
            <form-validation-item v-for="item, index in groupItems" :key="index" :item="item" :type="type"></form-validation-item>
        </div>
    </div>
    <span class="invalid-feedback d-block js-vue-validation" v-else-if="items && items.length">
        <form-validation-item v-for="item, index in items" :key="index" :item="item" :type="type"></form-validation-item>
    </span>
</template>

<script>
    export default {
        props: ['items', 'type'],
        computed: {
            itemsByGroup() {
                return this.items.reduce((res, item) => ((res[item.type] = res[item.type] || []).push(item), res), {});
            }
        }
    }
</script>
