<template>
    <div class="marker-pin-container"
         :class="{'marker-pin-square': shape === 'square', 'marker-pin-container-hover': !!$slots.contentHover, 'marker-pin-selected': selected, 'opacity-50': dimmed}"
         @click="emit('click', $event)"
         @dblclick="emit('dblclick', $event)"
    >
        <div class="marker-pulse after:shadow-[0_0_1px_2px]" :class="variantClass.pulse" v-if="pulse"></div>
        <div class="marker-pin-item" @contextmenu="emit('contextmenu', $event)" :class="{'cursor-pointer': clickable}">
            <div class="marker-pin before:bg-surface-0 dark:before:bg-surface-900" :class="variantClass.pin"></div>
            <div class="marker-pin-content bg-surface-0 dark:bg-surface-900">
                <div class="marker-pin-content-primary">
                    <slot name="content"></slot>
                </div>
                <div class="marker-pin-content-secondary">
                    <slot name="contentHover"></slot>
                </div>
            </div>
        </div>
        <Transition name="marker-bubble">
            <div v-if="!selected && notifications.length" class="marker-bubble flex flex-col cursor-pointer text-sm bg-white rounded-md divide-y px-1 font-medium">
                <TransitionGroup name="marker-bubble-message">
                    <div class="marker-bubble-message p-1 truncate flex items-center" v-for="(notification, index) in notifications" :key="index">
                        <i class="fa-solid fa-fw fa-comment mr-1" :class="notification.icon"></i>{{ notification.text }}
                    </div>
                </TransitionGroup>
            </div>
        </Transition>
        <Transition name="marker-badge" appear>
            <div class="marker-badge marker-badge-left" v-if="!selected && $slots.indicator">
                <slot name="indicator"></slot>
            </div>
        </Transition>
        <Transition name="marker-badge" appear>
            <div class="marker-badge marker-badge-right" v-if="!selected && badge">{{ badge }}</div>
        </Transition>
    </div>
</template>

<script setup>
import {computed, toRefs} from "vue";

const emit = defineEmits(['contextmenu', 'click', 'dblclick']);

const variants = computed(() => ({
    white: {pulse: 'after:shadow-gray-500', pin: 'bg-white border-gray-500 '+(shape.value === 'square' ? 'border-2' : 'border')},
    dark: {pulse: 'after:shadow-gray-900', pin: 'bg-gray-900'},
    orange: {pulse: 'after:shadow-orange-500', pin: 'bg-orange-500'},
    yellow: {pulse: 'after:shadow-yellow-400', pin: 'bg-yellow-400'},
    green: {pulse: 'after:shadow-green-500', pin: 'bg-green-500'},
    red: {pulse: 'after:shadow-red-500', pin: 'bg-red-500'},
    purple: {pulse: 'after:shadow-purple-500', pin: 'bg-purple-500'},
    indigo: {pulse: 'after:shadow-indigo-500', pin: 'bg-indigo-500'},
    blue: {pulse: 'after:shadow-blue-500', pin: 'bg-blue-500'},
    gray: {pulse: 'after:shadow-gray-500', pin: 'bg-gray-500'},
    'gray-100': {pulse: 'after:shadow-gray-100', pin: 'bg-gray-100'},
    'gray-200': {pulse: 'after:shadow-gray-200', pin: 'bg-gray-200'},
    'indigo-50': {pulse: 'after:shadow-indigo-50', pin: 'bg-indigo-50'},
    'indigo-100': {pulse: 'after:shadow-indigo-100', pin: 'bg-indigo-100'},
    'orange-50': {pulse: 'after:shadow-orange-50', pin: 'bg-orange-50'},
    'orange-100': {pulse: 'after:shadow-orange-100', pin: 'bg-orange-100'},
    'radar': {pulse: 'after:shadow-yellow-100', pin: 'before:border before:border-gray-900 dark:before:border-yellow-200 border border-gray-900 dark:border-yellow-200 bg-[repeating-linear-gradient(45deg,#fef08a_0%,#fef08a_10%,#1f2937_10%,#1f2937_20%)]'}
}));

const props = defineProps({
    pulse: Boolean,
    clickable: Boolean,
    variant: {default: 'black', type: String},
    notifications: {default: [], type: Array},
    badge: Number,
    shape: {default: 'circle', type: String},
    dimmed: Boolean,
    selected: Boolean,
})
const {variant, shape, badge, notifications, dimmed} = toRefs(props)

const variantClass = computed(() => variants.value[variant.value ?? 'dark']);
</script>