<template>
    <div class="input-group">
        <template v-if="origin === 'popover'">
            <div class="input-sm d-none"></div>
            <div class="input-group-prepend">
                <span class="input-group-text">Attente</span>
            </div>
        </template>
        <form-time v-bind="$attrs" :class="inputClass" :value="value" @input="$emit('input', $event)" @change="$emit('change', $event)" @reset="$emit('reset', $event)"></form-time>
        <div class="input-group-append">
            <span class="input-group-text" v-if="origin === 'popover'">hh:mm</span>
            <template v-if="trajet && (origin !== 'popover' || trajetPrecedent)">
                <b-btn :disabled="!priseEnChargeTrajet || !destinationTrajetPrecedent || destinationTrajetPrecedent.isAfter(priseEnChargeTrajet)" variant="secondary" class="d-flex align-items-center px-2" :title="trajetPrecedent ? 'Calculer le temps d\'attente avec le trajet précédent' : 'Pas de trajet précédent'" @click="calculerAttente">
                    <i class="fa-regular fa-fw fa-clock"></i>
                </b-btn>
            </template>
        </div>
    </div>
</template>

<script>
import Moment from "moment";

export default {
    props: ['value', 'trajet', 'trajetPrecedent', 'origin'],
    data() {
        return {
            inputClass: null,
        };
    },
    mounted() {
        // fix car class/style exclu de inheritAttrs, voir avec vue v3...
        this.inputClass = this.$el.classList.toString();
        this.$el.setAttribute('class', 'input-group');
    },
    computed: {
        priseEnChargeTrajet() {
            if(this.trajet) {
                const date = Moment(this.trajet.topPriseEnChargeDepartReel.date + ' ' + this.trajet.topPriseEnChargeDepartReel.time, 'DD/MM/YYYY HH:mm');

                if(date.isValid()) {
                    return date;
                }
            }

            return null;
        },
        destinationTrajetPrecedent() {
            if(this.trajetPrecedent) {
                const date = Moment(this.trajetPrecedent.topDestinationArriveeReel.date + ' ' + this.trajetPrecedent.topDestinationArriveeReel.time, 'DD/MM/YYYY HH:mm');

                if(date.isValid()) {
                    return date;
                }
            }

            return null;
        },
    },
    methods: {
        calculerAttente() {
            this.$emit('change', Moment.utc(this.priseEnChargeTrajet.diff(this.destinationTrajetPrecedent, 'milliseconds')).format('HH:mm'));
        },
    }
}
</script>