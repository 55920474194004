export default {
    request(url, options = {}) {
        const fetchOptions = options;
        fetchOptions.headers = fetchOptions.headers ?? {};
        fetchOptions.headers['X-Requested-With'] = 'XMLHttpRequest';
        if (options.method !== 'GET') {
            fetchOptions.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        }

        return fetch(url, fetchOptions);
    },
    getJson(url, options = {}) {
        return new Promise((resolve, reject) => {
            this.request(url, {method: 'GET', ...options}).then(response => response.json()).then(data => resolve(data)).catch(error => reject(error));
        });
    },
    postJson(url, data, options = {}) {
        return new Promise((resolve, reject) => {
            this.request(url, {method: 'POST', body: $.param(data), ...options}).then(response => response.json()).then(data => resolve(data)).catch(error => reject(error));
        });
    },
    patchJson(url, data, options = {}) {
        return new Promise((resolve, reject) => {
            this.request(url, {method: 'PATCH', body: $.param(data), ...options}).then(response => response.json()).then(data => resolve(data)).catch(error => reject(error));
        });
    },
}