<template>
    <Pin
        :badge="ressource.notifications.length"
        :notifications="notificationsStack"
        :clickable="true"
        :focus="isActive"
        :pulse="isActive"
        :dimmed="activeRessource && activeRessource.value !== ressource.value"
        :variant="ressource.variant"
        v-model:popup="popup"
        @hover="isActive ? (activeRessourceHover = $event ? ressource : null) : null"
        @longHover="activeRessourceHover = $event ? ressource : null"
    >
        <template #content>
            <Text :style="{color: ressource.regulation.couleur}" :value="ressource.regulation.shortString"></Text>
        </template>
        <template #contentHover v-if="segment">
            <i class="fa-solid fa-road fa-fw text-orange-500" title="En route" v-if="segment.enMouvement"></i>
            <i class="fa-solid fa-square-parking fa-fw text-blue-500" title="À l'arrêt" v-else></i>
        </template>
        <template #contentPopup>
            <i :class="[ressource.regulation.categorie.classeIcone, ressource.regulation.categorie.classeCouleur]" :title="ressource.regulation.categorie.text"></i>
        </template>
        <template #indicator v-if="isOffline">
            <i class="fa-solid fa-signal-stream-slash"></i>
        </template>
        <Popup>
            <RessourcePopup :ressource="ressource" v-if="!debug.forceDetachRessourcePopup"></RessourcePopup>
        </Popup>
        <RessourcePopup :ressource="ressource" v-if="!popup && debug.forceDetachRessourcePopup"></RessourcePopup>
        <template v-if="(ressourcesShowAllTrajets || isActive) && activeTrajet">
            <Route :id="'trajet_'+ressource.key" mode="ant" :ant-speed="50" :animate="true" color="#3b82f6" :steps="[[ressource.geolocalisation.position.latitudeDegre, ressource.geolocalisation.position.longitudeDegre], activeTrajet.topPriseEnChargeArriveeReel ? [activeTrajet.latitudeDegreDestination, activeTrajet.longitudeDegreDestination] : [activeTrajet.latitudeDegrePriseEnCharge, activeTrajet.longitudeDegrePriseEnCharge]]">
                <Tooltip>Tooltip</Tooltip>
            </Route>
            <TrajetPins :trajet="activeTrajet" v-if="isActive"></TrajetPins>
        </template>
        <template v-if="ressourcesShowAllTraces || isActive">
            <Radius v-if="segment && !segment.enMouvement" :id="'radius_'+ressource.key" :radius="Math.max(2, segment.rayon)" :lng-lat="[segment.longitudeDegre, segment.latitudeDegre]"></Radius>
            <Path v-else-if="traceFeatures" :id="'trace_'+ressource.key" mode="trail" color="#64748b" :features="traceFeatures"></Path>
        </template>
    </Pin>
</template>

<script setup>
import Pin from '@/Vue/Components/RegulationV2/Map/Base/Pin';
import RessourcePopup from '@/Vue/Components/RegulationV2/Map/Ressource/Popup';
import Text from '@/Vue/Components/RegulationV2/Map/Base/Pin/Text';
import Popup from '@/Vue/Components/RegulationV2/Map/Base/Popup';
import Tooltip from '@/Vue/Components/RegulationV2/Map/Base/Tooltip';
import Route from '@/Vue/Components/RegulationV2/Map/Base/Route';
import Path from '@/Vue/Components/RegulationV2/Map/Base/Path';
import {computed, onMounted, onUnmounted, ref, toRefs, watch} from "vue";
import {useGeolocalisationStore} from "@/Vue/Stores/RegulationV2/Geolocalisation";
import {storeToRefs} from "pinia";
import Router from '@/App/Router';
import Request from '@/App/Request';
import TrajetPins from "@/Vue/Components/RegulationV2/Map/Trajet/Pins";
import {useDocumentVisibility, useIdle, useIntervalFn} from "@vueuse/core";
import moment from "moment";
import Radius from "@/Vue/Components/RegulationV2/Map/Base/Radius.vue";

const geolocalisationStore = useGeolocalisationStore();

const {activeRessource, activeRessourceHover, activeRessourcePopup, ressourcesShowAllTrajets, ressourcesShowAllTraces, debug} = storeToRefs(geolocalisationStore);

const props = defineProps({
    ressource: Object,
});

const {idle} = useIdle(30 * 1000);
const visibility = useDocumentVisibility()

const {ressource} = toRefs(props);

const activeTrajet = computed(() => {
    if (ressource.value.regulation) {
        if (ressource.value.regulation._etat.section === 'mission') {
            return ressource.value.regulation._trajetInfo.minTrajet;
        }
    }

    return null;
});

const isActive = computed(() => activeRessource.value && activeRessource.value.key === ressource.value.key)

const traceFeatures = ref(null);
const traceFeaturesLoading = ref(false);

const popup = computed({
    get: () => null !== activeRessourcePopup.value && activeRessourcePopup.value.key === ressource.value.key,
    set: (popupBool) => {
        if (popupBool || popup.value) {
            activeRessourcePopup.value = popupBool ? ressource.value : null
        }
    },
});

const segment = computed(() => ressource.value.geolocalisation.position?.segment);

const updateTrace = () => {
    if (!ressource.value.geolocalisation._trace && !traceFeaturesLoading.value) {
        traceFeaturesLoading.value = true;
        Request.getJson(Router.generate('regulation-v2.trace.vehicule', {id: ressource.value.geolocalisation.id})).then(data => {
            ressource.value.geolocalisation._trace = data.routes;
            traceFeatures.value = ressource.value.geolocalisation._trace;
            traceFeaturesLoading.value = false;
        });
    }
};

watch(ressource, () => {
    if (isActive.value) {
        updateTrace();
    }
})

watch(ressourcesShowAllTraces, () => {
    if (ressourcesShowAllTraces.value) {
        updateTrace();
    }
}, {immediate: true});

watch(isActive, () => {
    if (isActive.value) {
        updateTrace();
    }
});

const notificationsInterval = useIntervalFn(() => {
    if (notificationsStack.value.length) {
        notificationsStack.value[0].pinAlerted = true;
    }
}, 5000, {immediate: false});

const notificationsStack = computed(() => ressource.value.notifications.filter(notification => !notification.pinAlerted).slice(0, 3));
const notificationsIntervalActive = computed(() => debug.value.pinNotificationInterval && notificationsStack.value.length > 0 && !idle.value && visibility.value === 'visible');

watch(notificationsIntervalActive, () => {
    if (notificationsIntervalActive.value) {
        notificationsInterval.resume();
    } else {
        notificationsInterval.pause();
    }
});

watch(popup, () => {
    if (popup.value) {
        for (const notification of ressource.value.notifications) {
            notification.pinAlerted = true;
        }
    }
});

let updateIndicatorTimeout = null;
const isOffline = ref(false);

const updateIndicator = () => {
    if (ressource.value.geolocalisation.position) {
        const diff = moment().diff(moment(ressource.value.geolocalisation.position.horodatage, 'YYYY-MM-DD HH:mm:ss'));
        isOffline.value = diff / 1000 > 60 * 60;
    }
}

onMounted(() => {
    updateIndicatorTimeout = setTimeout(updateIndicator, 1000 * 60);
    updateIndicator();
})

onUnmounted(() => {
    if (null !== activeRessourcePopup.value && activeRessourcePopup.value.key === ressource.value.key) {
        activeRessourcePopup.value = null;
    }
    if (null !== activeRessourceHover.value && activeRessourceHover.value.key === ressource.value.key) {
        activeRessourceHover.value = null;
    }

    if (updateIndicatorTimeout) {
        clearTimeout(updateIndicatorTimeout);
    }
});

</script>