'use strict';

import {createApp, onMounted} from 'vue';
import Module from '../../Vue/Components/Piece/Module.vue';
import Router from "@/App/Router";
import Dropzone from "dropzone";
import {ref} from "vue";
import Translator from "@/App/Translator";

App.Shared.Piece = class {
    static dropzone(id, params = {}) {
        return new Dropzone('#dropzone', {
            dictFileTooBig: "Le fichier est trop volumineux ({{filesize}} Mo). Poids maximum autorisé : "+App.Constants.PIECE_MAX_FILE_SIZE+" Ko. Adaptez vos paramètres de numérisation.",
            dictInvalidFileType: "Vous ne pouvez pas envoyer de fichiers de ce type.",
            dictResponseError: "Le serveur a répondu avec un code {{statusCode}}.",
            dictMaxFilesExceeded: "Vous ne pouvez pas envoyer plus de {{maxFiles}} fichier(s).",
            uploadMultiple: true,
            maxFilesize: App.Constants.PIECE_MAX_FILE_SIZE / 1024,
            acceptedFiles: 'image/*,application/pdf',
            error: (file, response) => {
                $(file.previewElement).addClass('dz-error').find('[data-dz-errormessage]').text(typeof response === 'object' ? response.errors.join("\n") : response);
            },
            ... params,
        });
    }

    static print(piece, cb = null) {
        App.Utils.print(Router.generate('shared.piece.view', {id: piece.id}), piece.libelle).then(() => {
            if(cb) {
                cb();
            }
        });
    }
}

App.Shared.Piece.Index = class {
    constructor(params) {
        createApp({
            delimiters: ['[[', ']]'],
            setup() {
                const previewPiece = ref(null);

                const print = (piece) => {
                    App.Shared.Piece.print(piece.value);
                };

                const handleUpdate = (data) => {
                    Object.assign(previewPiece.value, data.piece, {_loading: false});
                };

                const openPiece = (piece) => {
                    previewPiece.value = piece;
                };

                const onDelete = () => {
                    previewPiece.value = null;
                    document.location.reload();
                };

                onMounted(() => {
                    document.dispatchEvent(new Event('app:vuejs:mounted'));
                });

                const res = {
                    previewPiece, print, handleUpdate, openPiece, onDelete, Translator
                };

                for(const field in params.vueData) {
                    res[field] = ref(params.vueData[field]);
                }

                return res;
            },
        }).mount('#app');
    }
}

App.Shared.Piece.Module = class {
    constructor(params) {
        createApp({
            render: createElement => createElement(Module, {
                props: {
                    initData: params.vueData,
                },
            }),
        }).mount('#app');
    }
}
