<template>
    <div class="maplibregl-ctrl" ref="content">
        <slot></slot>
    </div>
</template>

<script setup>
import {inject, onMounted, onUnmounted, ref} from 'vue';

const props = defineProps({
    placement: {type: String, default: 'bottom-right'}
})

const map = inject('map');
const content = ref();

const CustomControl = class {
    onAdd() {
        return content.value;
    }

    onRemove() {}
};


const control = new CustomControl();

onMounted(() => map.value.addControl(control, props.placement));
onUnmounted(() => map.value.removeControl(control));
</script>