'use strict';

import Translator from "@/App/Translator";
import swal from "sweetalert2";
import Cookies from "js-cookie";
import Router from "@/App/Router";
import Ladda from "ladda";

App.Shared.Patient = class {};

App.Shared.Patient.View = class {
    constructor(params) {
        this.params = {};
        this.params.exportPopoverTemplate = params.exportPopoverTemplate;
        this.params.exportPopoverTitle = params.exportPopoverTitle;

        this.initTransformToBeneficiairePopover(params.transformToBeneficiaireContent);
        this.initExport(params.entityId)
    }

    initTransformToBeneficiairePopover(content) {
        const $transformToBeneficiaireBtn = $('#transformToBeneficiaireBtn');

        $('body').click((e) => {
            if (!$(e.target).is($transformToBeneficiaireBtn) && !$(e.target).parent('#transformToBeneficiaireBtn').length && !$(e.target).parents('.popover.show').length && !$(e.target)[0].classList.value.includes('swal2')) {
                $transformToBeneficiaireBtn.popover('hide');
            }
        });

       $transformToBeneficiaireBtn.popover({
            placement: 'top',
            content,
            sanitize: false,
            html: true,
            title: Translator.trans('action.transformer-beneficiaire')
        }).on('show.bs.popover', function() {
            $($(this).data('bs.popover').tip).css('min-width', '30%').css('z-index', '50');
        }).on('shown.bs.popover', () => {
            App.Layout.enableSelect2();
            const $transformToBeneficiaireFormBtn = $('#transformToBeneficiaireFormBtn');
            const $transformToBeneficiaireForm = $('#transformToBeneficiaireForm');

            $transformToBeneficiaireFormBtn.click(() => {
                if ($('select#transformer_to_beneficiaire_assure').val()) {
                    swal({
                       title: App.Constants.LIBELLE_ETES_VOUS_SUR,
                       text: Translator.trans('shared.patient.transformer.warning'),
                       type: 'question',
                       showCancelButton: true,
                       confirmButtonClass: 'bg-primary',
                       confirmButtonText: Translator.trans('action.transformer-beneficiaire'),
                       cancelButtonText: Translator.trans('action.annuler')
                    }).then((result) => {
                       if (result.value) {
                           $transformToBeneficiaireForm.submit();
                       } else {
                           $transformToBeneficiaireBtn.popover('hide');
                       }
                    });
                } else {

                }
            });
        });
    }

    initExport(id) {
        $('#exportBtn').popover({
            placement: 'top',
            fallbackPlacement: [],
            content: this.params.exportPopoverTemplate,
            html: true,
            sanitize: false,
            title: this.params.exportPopoverTitle,
        }).on('show.bs.popover', function () {
            $($(this).data('bs.popover').tip).css('min-width', '20%');
        }).on('shown.bs.popover', () => {
            Cookies.remove('patientExportDownloadFinished');

            let $exportPrintBtn = $('#exportPrintBtn');
            let $exportDownloadBtn = $('#exportDownloadBtn');

            let exportPrintBtnLadda = Ladda.create($exportPrintBtn[0]);
            let exportDownloadBtnLadda = Ladda.create($exportDownloadBtn[0]);

            let printUrl = Router.generate('shared.patient.export', {app: App.Constants.APPLICATION, id, type: 'html'});
            let downloadUrl = Router.generate('shared.patient.export', {app: App.Constants.APPLICATION, id, type: 'pdf'});

            $exportPrintBtn.on('click', (event) => {
                exportPrintBtnLadda.start();
                $exportDownloadBtn.prop('disabled', true);

                App.Utils.print(printUrl).then(() => {
                    exportPrintBtnLadda.stop();
                    $exportDownloadBtn.prop('disabled', false);
                });
            })

            $exportDownloadBtn.on('click', (event) => {
                exportDownloadBtnLadda.start();
                $exportPrintBtn.prop('disabled', true);

                App.Utils.download(downloadUrl).then(() => {
                    let checkFinish = () => {
                        if (Cookies.get('patientExportDownloadFinished')) {
                            exportDownloadBtnLadda.stop();
                            $exportPrintBtn.prop('disabled', false);
                            Cookies.remove('patientExportDownloadFinished');
                        } else {
                            setTimeout(checkFinish, 1000);
                        }
                    };

                    checkFinish();
                });

            })
        });
    }
}

App.Shared.Patient.Form = class {
    constructor(params) {
        this._initPecPlus(params.pecPlusAjax);
        this._initExoneration(params.form);
    }

    _initExoneration(form) {
        App.Shared.initExoneration(
            form.id+'_typeExonerationBtn',
            form.nature,
            form.exoneration,
            form.caisse,
            form.tauxCaisse,
            form.mutuelle,
            form.tauxMutuelle
        );
    }

    _initPecPlus(pecPlusAjax) {
        let pecPlus = new App.PecPlus(pecPlusAjax);

        $('#pecPlusBtn').click(() => {
            pecPlus.call($('#form').serialize());
        });
    }
};
