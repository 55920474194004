<template>
    <sefi-popup title="Valider" :loading="false" :result="result" :error="error" :no-form="true" :no-refresh="true">
        <template v-slot:result v-if="result">
            <template v-if="result.success">
                <div class="alert alert-success">
                    La facture <strong>{{ result.numero }}</strong> a été validée et intégrée au lot <strong>{{ result.lot }}</strong><template v-if="result.dateTraitement !== null"> en date du <strong>{{ result.dateTraitement }}</strong></template>.
                </div>
                <div class="row" v-if="result.adresseOrganisme !== null">
                    <div class="col-6 d-flex flex-column mx-auto">
                        <h3 class="mt-3">{{ Translator.trans('libelle.adresseOrganisme') }}</h3>
                        <div class="sefi-adresse sefi-adresse-pre flex-1 ">{{ result.adresseOrganisme }}</div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="alert alert-danger"><strong>La facture n'a pas pu être validée.</strong> Veuillez corriger les erreurs suivantes : </div>
                <div class="mx-5">
                    <template v-if="result.errors.facture">
                        <sefi-result-errors title="Facture" :errors="result.errors.facture"></sefi-result-errors>
                    </template>
                    <template v-if="result.errors.trajets">
                        <sefi-result-errors v-for="errors, index in result.errors.trajets" :key="index" :title="'Trajet n°'+index" :errors="errors"></sefi-result-errors>
                    </template>
                </div>
            </template>
        </template>
    </sefi-popup>
</template>

<script setup>
import Translator from "@/App/Translator";
import {onMounted, ref, toRefs} from "vue";

const result = ref(null);
const error = ref(null);

const props = defineProps({
    response: Object,
});
const {response} = toRefs(props);

onMounted(() => {
    if (response.value) {
        if(response.value.success) {
            result.value = response.value.data;
        } else {
            error.value = response.value.error;
        }
    }
});

</script>
