<template>
    <div class="search-form">
        <b-btn title="Voir les pièces non affectées" variant="primary" id="aTraiterBtn" class="js-vue-popover-btn font-size-13px">
            <i class="fa-solid fa-inbox"></i> {{ buttonLabel }}
        </b-btn>
        <Popover target="aTraiterBtn" placement="bottom-end" class="piece-popover" @show="refresh">
            <template v-slot:title>
                <div class="row search-form">
                    <div class="col-5 d-flex align-items-center">Pièces non affectées</div>
                    <div class="col-6">
                        <piece-filter :groups="groups" :types="types" v-model="filter"></piece-filter>
                    </div>
                    <div class="col-1 pr-0">
                        <b-btn block variant="secondary" @click="refresh" :disabled="loading" title="Rafraichir">
                            <i class="fa-solid fa-arrows-rotate"></i>
                        </b-btn>
                    </div>
                </div>
            </template>
            <piece-container id="piecesNonAffecte" :loading="loading" @update="handleUpdate" @scor="emit('scor', $event)" :groups="groups" :piece-per-page="5" :update-url="updateUrl" :types="types" :type-presets="typePresets" :liens="liens" :standard="standard" :batch-delete-url="batchDeleteUrl" :batch-update-url="batchUpdateUrl" :filter="filter"></piece-container>
        </Popover>
    </div>
</template>

<script setup>
import {toRefs, ref, computed} from 'vue';
import Request from '@/App/Request';
import Popover from '@/Vue/Components/Popover';

const emit = defineEmits(['update', 'scor']);

const props = defineProps({
    url: String,
    updateUrl: String,
    batchDeleteUrl: String,
    batchUpdateUrl: String,
    types: Array,
    typePresets: Object,
    liens: Array,
    standard: Object,
});

const {url} = toRefs(props);
const loading = ref(true);
const groups = ref([]);
const filter = ref({
    self: true,
});

const allPieces = computed(() => groups.value.map(group => group.pieces).flat());
const pieceSelfCount = computed(() => allPieces.value.filter(piece => piece.createdBy?.id === App.Constants.USER_ID).length);
const buttonLabel = computed(() => groups.value.length ? pieceSelfCount.value+(allPieces.value.length !== 0 ? ('/'+allPieces.value.length) : '') : '');

const refresh = () => {
    loading.value = true;
    Request.getJson(url.value).then((data) => {
        loading.value = false;
        groups.value = data.groups;
    });
};

const handleUpdate = (data) => {
    if (data.groups) {
        groups.value = data.groups;
    }

    emit('update');
}

refresh();
document.addEventListener('piece:refresh', () => refresh());
</script>
