'use strict';

App.Job = class {};

App.Job.Assistant = class {
    constructor(params) {
        App.Job.Assistant.handleJob(params.jobId);
    }

    static handleJob(id) {
        App.webSocket.subscribe('job/'+id, (uri, data) => {
            $('#generatePending').hide();
            $('#generateSuccess').show();
            $('#generateMessage').text(data.message);

            if(data.html) {
                $('#generateContent').html(data.html);
            }
        });
    }
};