<template>
    <span v-if="!!value">{{ value }}</span>
    <span class="text-muted" v-else>-</span>
</template>

<script>
    export default {
        props: ['value'],
    }
</script>
