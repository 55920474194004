'use strict';

App.Administration.Courrier = class {};

App.Administration.Courrier.Modele = class {
    constructor(params) {
        this.params = params;

        this._initAjax();
    }

    _initAjax() {
        let $form = $('#form');
        let ajaxRequest = null;

        $('body').on('change', '#' + this.params.layout + ' input', (e) => {
            if(!$(e.currentTarget).val()) {
                $('#layoutData').html('');
                return;
            }

            if(ajaxRequest) {
                ajaxRequest.abort();
                ajaxRequest = null;
            }

            ajaxRequest = $.ajax({
                url: this.params.ajax,
                method: 'POST',
                data: $form.serialize(),
            }).done((data) => {
                $('#layoutData').replaceWith(data);
                App.Layout.enableSelect2();
                App.Layout.enableEmbedInline();
                App.Layout.enableTrumbowyg();
            }).fail((r, status) => {
                if('abort' !== status) {
                    $('#layoutData').html('<div class="row"><div class="col-sm-9 ml-auto text-danger"><i class="fa-solid fa-triangle-exclamation"></i> ' + this.params.erreur + '</div></div>');
                }
            });
        });
    }
};