<template>
  <div>
    <div class="panel panel-compact">
      <div class="panel-heading d-flex justify-content-between bg-light py-3 px-4">
        <div>{{ Translator.trans('referentiel.tableau-de-bord.consommations.sms.credits') }} - {{ year }}</div>
      </div>
      <div class="panel-wrapper">
        <div class="panel-body">
          <column-chart empty=" " suffix="" :data="consommationSms" :dataset="{borderWidth: 0}"></column-chart>
        </div>
      </div>
    </div>
    <div class="panel panel-compact">
      <div class="panel-heading d-flex justify-content-between bg-light py-3 px-4">
        <div>{{ Translator.trans('referentiel.tableau-de-bord.consommations.viaMichelin.requetes') }} - {{ year }}</div>
      </div>
      <div class="panel-wrapper">
        <div class="panel-body">
          <column-chart empty=" " suffix="" :data="consommationViaMichelin" :dataset="{borderWidth: 0}"></column-chart>
        </div>
      </div>
    </div>
    <div class="panel panel-compact">
      <div class="panel-heading d-flex justify-content-between bg-light py-3 px-4">
        <div>{{ Translator.trans('referentiel.tableau-de-bord.consommations.loxane.requetes') }} - {{ year }}</div>
      </div>
      <div class="panel-wrapper">
        <div class="panel-body">
          <column-chart empty=" " suffix="" :data="consommationLoxane" :dataset="{borderWidth: 0}"></column-chart>
        </div>
      </div>
    </div>
    <div class="panel panel-compact">
      <div class="panel-heading d-flex justify-content-between bg-light py-3 px-4">
        <div>{{ Translator.trans('referentiel.tableau-de-bord.consommations.ptv.requetes') }} - {{ year }}</div>
      </div>
      <div class="panel-wrapper">
        <div class="panel-body">
          <column-chart empty=" " suffix="" :data="consommationPtv" :dataset="{borderWidth: 0}"></column-chart>
        </div>
      </div>
    </div>
    <div class="panel panel-compact">
      <div class="panel-heading d-flex justify-content-between bg-light py-3 px-4">
        <div>{{ Translator.trans('referentiel.tableau-de-bord.consommations.ameliPro.requetes') }} - {{ year }}</div>
      </div>
      <div class="panel-wrapper">
        <div class="panel-body">
          <column-chart empty=" " suffix="" :data="consommationAmeliPro" :dataset="{borderWidth: 0}"></column-chart>
        </div>
      </div>
    </div>
    <div class="panel panel-compact">
      <div class="panel-heading d-flex justify-content-between bg-light py-3 px-4">
        <div>{{ Translator.trans('referentiel.tableau-de-bord.consommations.immatriculationApi.requetes') }} - {{ year }}</div>
      </div>
      <div class="panel-wrapper">
        <div class="panel-body">
          <column-chart empty=" " suffix="" :data="consommationImmatriculationApi" :dataset="{borderWidth: 0}"></column-chart>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
import {onMounted, ref, toRefs, watch} from "vue";
import Router from "@/App/Router";
import Translator from "@/App/Translator";
import {useRequest} from "@/Vue/Composables/Request";

const {postJson, abort} = useRequest();

const emit = defineEmits(['refresh', 'error']);

const props = defineProps({
    structures: Array,
    tab: String,
});

const {structures, tab} = toRefs(props);

const data = ref({});

const loading = ref(false);
const consommationAmeliPro = ref([]);
const consommationLoxane = ref([]);
const consommationPtv = ref([]);
const consommationSms = ref([]);
const consommationViaMichelin = ref([]);
const consommationImmatriculationApi = ref([]);
const year = ref(null);

const refresh = () => {
    if(!structures.value.length) {
        abort();
        consommationAmeliPro.value = [];
        consommationLoxane.value = [];
        consommationPtv.value = [];
        consommationSms.value = [];
        consommationViaMichelin.value = [];
        loading.value = false;
        emit('refresh');

        return;
    }

    loading.value = true;

    postJson(Router.generate('referentiel.tableau-de-bord.consommations'), {
        tableau_de_bord_filter: {
            structure: structures.value,
        },
    }).then((data) => {
        consommationAmeliPro.value = data.consommationAmeliPro;
        consommationLoxane.value = data.consommationLoxane;
        consommationPtv.value = data.consommationPtv;
        consommationSms.value = data.consommationSms;
        consommationViaMichelin.value = data.consommationViaMichelin;
        consommationImmatriculationApi.value = data.consommationImmatriculationApi;
        year.value = data.year;

        emit('refresh');
    }, (err) => emit('error', err)).finally(() => {
        loading.value = false;
    });
}

watch(structures, () => refresh());
onMounted(() => refresh());

</script>
