<template>
    <label class="btn btn-block btn-outline btn-filter font-size-13px form-control" :class="{active, disabled, 'border-primary': outlined && active}" @click="onClick">
        <input ref="input" :type="inputType" :value="itemValue" v-model="checked" @change="onChange" :disabled="disabled">
        <slot></slot>
    </label>
</template>

<script setup>
import {computed, ref, toRefs, watch} from "vue";

const emit = defineEmits(['input', 'change', 'reset']);
const props = defineProps(['value', 'type', 'itemValue', 'disabled', 'allowClear', 'outlined']);

const {value, type, itemValue, disabled, allowClear, outlined} = toRefs(props);

const input = ref();
const checkedProxy = ref(false);

const inputType = computed(() => {
    switch (type.value) {
        case 'single_checkbox':
        case 'fields':
            return 'checkbox';
    }

    return type.value;
});

const active = computed(() => {
    if (type.value === 'checkbox') {
        return Array.isArray(value.value) && value.value.includes(itemValue.value);
    }
    else if(type.value === 'single_checkbox') {
        return true === value.value;
    }
    else if(type.value === 'fields') {
        return value.value;
    }

    return itemValue.value === value.value;
});

const checked = computed({
    get () {
        return value.value;
    },
    set (val) {
        checkedProxy.value = val;
    }
});

watch(active, () => {
    if (inputType.value === 'checkbox' && !active.value) {
        emit('reset');
    }
});

const onClick = (event) => {
    if (allowClear.value && inputType.value === 'radio' && event.target === input.value && checkedProxy.value) {
        checkedProxy.value = false;
        onChange();

        emit('reset');
    }
};

const onChange = () => {
    emit('input', checkedProxy.value);
    emit('change', checkedProxy.value);
};

</script>
