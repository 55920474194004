<template>
    <component :is="'tableau-de-bord-widget-'+name" :structures="structures" :tab="tab" @refresh="emit('refresh')" @error="emit('error', $event)" @mode-montants="emit('mode-montants', $event)"></component>
</template>

<script setup>
const emit = defineEmits(['refresh', 'error', 'mode-montants']);

defineProps({
    name: String,
    structures: Array,
    tab: String
})
</script>
