<template>
    <div class="flex gap-x-3 p-2">
        <div class="w-1/2 flex flex-col gap-y-2 min-w-0">
            <div class="flex items-center gap-x-2">
                <div class="flex-1 h-[4px] rounded bg-[#884da7]"></div>
                <div class="font-normal leading-none text-[#884da7]">Véhicule</div>
                <div class="flex-1 h-[4px] rounded bg-[#884da7]"></div>
            </div>

            <div class="bg-gray-100 dark:bg-gray-800 rounded-lg p-1 gap-y-2 flex flex-col" v-if="segment || hasCompteurs">
                <div class="rounded-md bg-white dark:bg-gray-900 flex overflow-hidden leading-none" v-if="segment">
                    <div class="px-2 py-1 text-white rounded-r-md flex items-center min-h-[26px]" :class="segment.enMouvement ? 'bg-orange-500': 'bg-blue-500'">
                        <i class="fa-solid fa-road fa-fw" v-if="segment.enMouvement"></i>
                        <i class="fa-solid fa-square-parking fa-fw" v-else></i>
                    </div>
                    <div class="px-2 py-1 font-medium flex-1 flex items-center">
                        <span v-if="segment.enMouvement" class="text-orange-500">En route</span>
                        <span v-else class="text-blue-500">À l'arrêt</span>
                    </div>
                    <div class="text-right text-xs text-slate-500 dark:text-surface-0/80 p-1 leading-none flex flex-col justify-center">
                        <div :title="'Depuis le '+segment.positionDebut.horodatage.date + ' ' + segment.positionDebut.horodatage.time">
                            <i class="fa-regular fa-clock"></i> {{ duration(segment.positionDebut.horodatage) }}
                        </div>
                        <div v-if="segment.enMouvement">
                            <i class="fa-regular fa-arrow-progress"></i> {{ segment.kilometreVehicule ?? segment.kilometreGps }} km
                        </div>
                    </div>
                </div>
                <div class="flex divide-x flex-1 text-slate-700 dark:text-surface-0/80" v-if="hasCompteurs">
                    <div class="text-center flex-1 flex item-center justify-center flex-col text-nowrap px-1" v-if="ressource.geolocalisation.position.vitesse">
                        <i class="fa-regular fa-gauge-high"></i>
                        <span class="text-xs">{{ ressource.geolocalisation.position.vitesse }} km/h</span>
                    </div>
                    <div class="text-center flex-1 flex item-center justify-center flex-col text-nowrap px-1" v-if="ressource.geolocalisation.kilometrage">
                        <i class="fa-regular fa-road"></i>
                        <span class="text-xs">{{ ressource.geolocalisation.kilometrage.valeur }} km</span>
                    </div>
                    <div class="text-center flex-1 flex item-center justify-center flex-col text-nowrap px-1" v-if="ressource.geolocalisation.essenceVolumePourcent || ressource.geolocalisation.essenceVolumeLitre">
                        <i class="fa-regular fa-gas-pump"></i>
                        <span class="text-xs" :title="(ressource.geolocalisation.essenceVolumePourcent ? (ressource.geolocalisation.essenceVolumePourcent.valeur+'%') : '') + (ressource.geolocalisation.essenceVolumeLitre ? (' (' +ressource.geolocalisation.essenceVolumeLitre.valeur + 'L)') : '')">
                            <template v-if="ressource.geolocalisation.essenceVolumePourcent">{{ ressource.geolocalisation.essenceVolumePourcent.valeur }}%</template>
                            <template v-else-if="ressource.geolocalisation.essenceVolumeLitre">{{ ressource.geolocalisation.essenceVolumeLitre.valeur }}L</template>
                        </span>
                    </div>
                </div>
            </div>
            <div class="bg-gray-100 dark:bg-gray-800 rounded-lg p-1 flex gap-x-1 leading-none truncate h-[33px]" v-for="appareil in ressource.geolocalisation.appareilCollection">
                <div class="bg-white dark:bg-gray-900 rounded-md p-1 pr-2 flex items-center truncate" :title="appareil.text +' ('+appareil.imei+')'">
                    <i class="fa-regular fa-fw" v-if="appareil.type" :class="{'fa-microchip': appareil.type.estGeoloc, 'fa-mobile': appareil.type.estTla}"></i>
                    <span class="font-medium truncate">{{ appareil.text }}</span>
                </div>
                <div class="flex divide-x flex-1 text-slate-700 dark:text-surface-0/80">
                    <div class="text-center flex-1 flex item-center justify-center flex-col px-1">
                        <i class="fa-duotone" :class="'fa-signal-'+(((appareil.simReseauSignalPourcent.valeur/25)+1).toFixed())" v-if="appareil.simReseauSignalPourcent" :title="appareil.simReseauSignalPourcent.valeur+'%'"></i>
                        <i class="fa-duotone fa-signal-slash" v-else></i>
                        <div class="text-xs" v-if="appareil.simReseauType" :title="appareil.simReseauType.valeur">
                            {{ appareil.simReseauType.valeur.split(' ')[0] }}
                        </div>
                    </div>
                    <div class="text-center flex-1 flex item-center justify-center flex-col px-1">
                        <i class="fa-solid" :class="'fa-battery-'+((appareil.boitierBatterieChargePourcent.valeur/20).toFixed())" v-if="appareil.boitierBatterieChargePourcent"></i>
                        <i class="fa-solid fa-battery-slash" v-else></i>
                        <div class="text-xs" v-if="appareil.boitierBatterieChargePourcent">
                            {{ appareil.boitierBatterieChargePourcent.valeur }}%
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-1/2 flex flex-col gap-y-2 min-w-0">
            <template v-if="ressource.regulation">
                <div class="flex items-center gap-x-2">
                    <div class="flex-1 h-[4px] rounded bg-[#66c4ed]"></div>
                    <div class="font-normal leading-none text-[#66c4ed]">Régulation</div>
                    <div class="flex-1 h-[4px] rounded bg-[#66c4ed]"></div>
                </div>
                <div class="bg-gray-100 dark:bg-gray-800 rounded-lg p-1 flex flex-col gap-y-1 leading-none">
                    <div class="rounded-md bg-white dark:bg-gray-900 flex overflow-hidden">
                        <div class="px-2 text-white rounded-r-md flex items-center" :class="{'success': 'bg-green-500', 'info': 'bg-blue-500', 'warning': 'bg-orange-500', 'danger': 'bg-red-500', 'secondary': 'bg-gray-500'}[ressource.regulation._etat.variant]">
                            <i class="fa-solid fa-fw" :class="{'disponible': 'fa-snooze', 'affecte': 'fa-timer', 'en-mission': 'fa-hourglass-start', 'fin-mission': 'fa-hourglass-half', 'pause': 'fa-pause'}[ressource.regulation._etat.name]"></i>
                        </div>
                        <div class="p-2 pr-1 flex-1 flex items-center justify-between">
                            <div class="font-medium" :title="ressource.regulation._etat.title" :class="{'success': 'text-green-500', 'info': 'text-blue-500', 'warning': 'text-orange-500', 'danger': 'text-red-500', 'secondary': 'text-gray-500'}[ressource.regulation._etat.variant]">
                                {{ sectionsRessources[ressource.regulation._etat.section].label }}
                            </div>
                            <div>
                                <EtatInfo :ressource="ressource.regulation"></EtatInfo>
                            </div>
                        </div>
                    </div>
                    <template v-if="ressource.regulation._etat.section !== 'disponible' && ressource.regulation._trajetInfo.minTrajet">
                        <Trajet :trajet="ressource.regulation._trajetInfo.minTrajet"></Trajet>
                    </template>
                </div>
                <template v-if="ressource.regulation._etat.section !== 'disponible' && ressource.regulation._trajetInfo.minTrajet">
                    <template v-for="field in ['_personnel1', '_personnel2']">
                        <div v-if="personnel = ressource.regulation._trajetInfo.minTrajet[field]" class="bg-gray-100 dark:bg-gray-800 rounded-lg p-1 flex flex-col gap-y-1 leading-none">
                            <div class="rounded-md bg-white dark:bg-gray-900 flex overflow-hidden font-medium">
                                <div class="px-2 py-1 text-white rounded-r-md" :style="'background: '+personnel.couleur+';'">{{ personnel.shortString }}</div>
                                <div class="p-1 flex-1 truncate">
                                    {{ personnel.text }}
                                </div>
                            </div>
                            <div class="flex items-center text-xs" v-if="personnel.typeCourant">
                                <div :title="personnel.typeCourant.libelle" class="rounded px-1 mr-1" :style="{background: '#'+personnel.typeCourant.couleur, color: '#'+personnel.typeCourant.couleurTexte}">{{ personnel.typeCourant.code }}</div>
                                <div class="text-slate-700 dark:text-surface-0/80 flex">
                                    <span :class="{'font-medium text-green-500': personnel.typeCourant.debutReel !== null}">{{ personnel.typeCourant._debut.format('HH:mm') }}</span>
                                    <span class="mx-1">-</span>
                                    <span :class="{'font-medium text-green-500': personnel.typeCourant.finReel !== null}">{{ personnel.typeCourant._fin.format('HH:mm') }}</span>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
            </template>
        </div>
    </div>
</template>

<script setup>
import {toRefs, computed} from "vue";
import Trajet from '@/Vue/Components/RegulationV2/Map/Ressource/Tab/Ressource/Trajet';
import EtatInfo from "@/Vue/Components/RegulationV2/Sidebar/Ressources/EtatInfo.vue";
import {duration} from "@/Vue/Filters/Horodatage"
import {useRegulationStore} from "@/Vue/Stores/Regulation";

const props = defineProps({
    ressource: Object,
});
const {ressource} = toRefs(props);

const store = useRegulationStore();
const {sectionsRessources} = store;

const segment = computed(() => ressource.value.geolocalisation.position?.segment);
const hasCompteurs = computed(() => ressource.value.geolocalisation.position?.vitesse || ressource.value.geolocalisation.kilometrage || ressource.value.geolocalisation.essenceVolumePourcent || ressource.value.geolocalisation.essenceVolumeLitre);
</script>