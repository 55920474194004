<template>
    <th :title="title" :aria-sort="ariaSort" @click="onClick">
        <slot></slot>
    </th>
</template>

<script setup>
import {computed, toRefs} from "vue";

const emit = defineEmits(['update:sort']);
const props = defineProps({
    title: String,
    sort: Object,
    sortColumn: String,
});

const {title, sort, sortColumn} = toRefs(props);

const ariaSort = computed(() => {
    if (sortColumn.value) {
        if (sortColumn.value === sort.value.column) {
            return sort.value.direction === 'asc' ? 'ascending' : 'descending';
        }
        return 'none';
    }
});

const onClick = () => {
    if (sort.value && sortColumn.value) {
        emit('update:sort', {
            column: sortColumn.value,
            direction: sortColumn.value !== sort.value.column || sort.value.direction === 'desc' ? 'asc' : 'desc',
        });
    }
};
</script>
