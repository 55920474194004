<template>
    <template v-if="carteDefaut !== null">
        <CpsMiniCard :card="carteDefaut" :btn="true" @click="toggle" class="z-10 !rounded-xl shadow-md hover:shadow-lg transition cursor-pointer h-[55px]"></CpsMiniCard>
        <Popover ref="popover" :pt="{root: 'mt-2', content: ''}" :ptOptions="{mergeProps: false}">
            <div class="max-w-[350px] flex-auto overflow-hidden rounded-xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div class="border-b border-gray-900/5 flex justify-between py-2 px-4">
                    <div class="font-semibold text-gray-900">Mes cartes CPS</div>
                    <div>
                        <Badge :value="cpsStore.cartes.length"></Badge>
                    </div>
                </div>
                <div class="p-2">
                    <div v-for="carte in cartes" class="group flex items-center gap-x-4 rounded-lg px-3 py-2 hover:bg-gray-50">
                        <div @click="selectCard(carte)" class="flex h-8 w-8 items-center justify-center rounded-full" :class="carte === carteDefaut ? 'bg-green-500' : 'bg-gray-100 group-hover:bg-gray-200 cursor-pointer'">
                            <i class="fa-solid fa-spin fa-arrows-rotate text-dark-700" v-if="carte === cardLoading"></i>
                            <i class="fa-solid fa-check text-white" v-else-if="carte === carteDefaut"></i>
                        </div>
                        <div class="flex-1 overflow-hidden">
                            <CpsMiniCard @click="selectCard(carte)" :class="{'cursor-pointer': carte !== carteDefaut}" :title="carte !== carteDefaut ? 'Sélectionner cette carte' : ''" :card="carte" :active="carte === carteDefaut"></CpsMiniCard>
                        </div>
                        <template v-if="hasOnlineCard">
                            <Button title="Verrouiller la carte" icon="fa-solid fa-lock" type="button" severity="warning" :disabled="cardLoading === carte" @click="lockCard(carte)" v-if="canLockCard(carte)"></Button>
                            <Button type="button" severity="secondary" icon="fa-solid fa-lock" v-else disabled></Button>
                        </template>
                        <Button :disabled="cardLoading === carte" :title="Translator.trans('libelle.actions')" type="button" severity="secondary" icon="fa-solid fa-ellipsis-vertical" @click="toggleMenu($event, carte)"/>
                    </div>
                </div>
                <div class="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50 text-sm">
                    <a :href="Router.generate('mon-profil.view')" target="_blank" class="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100">
                        <i class="fa-solid fa-list"></i>
                        Gérer mes cartes
                    </a>
                    <a :href="Router.generate('cps.add')" target="_blank" class="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100">
                        <i class="fa-solid fa-plus"></i>
                        Ajouter une carte
                    </a>
                </div>
            </div>
        </Popover>
    </template>
    <Menu ref="menu" @hide="menuCarte = null" :model="menuItems" :popup="true" />
</template>

<script setup>
import {useCpsStore} from '@/Vue/Stores/Cps'
import {computed, ref, toRefs} from 'vue'
import {storeToRefs} from 'pinia'
import Router from '@/App/Router';
import Request from '@/App/Request';
import {onClickOutside} from '@vueuse/core'
import Translator from "@/App/Translator";
import Button from "primevue/button";
import Menu from 'primevue/menu';
import Badge from 'primevue/badge';
import CpsMiniCard from '@/Vue/Components/Cps/MiniCard';
import Popover from 'primevue/popover';

const cpsStore = useCpsStore()

const {cartes, carteDefaut, count} = storeToRefs(cpsStore)

const props = defineProps({
    cpsData: Object
})
const {cpsData} = toRefs(props)

const popover = ref();

const cardLoading = ref(null);

const toggle = (event) => {
    popover.value.toggle(event);
};

const canLockCard = (card) => card.info && card.info.clientData && !card.info.clientData.locked;

const selectCard = async (card) => {
    if (card === carteDefaut.value) {
        return;
    }

    cardLoading.value = card;
    cpsStore.setCarteDefaut(card).finally(() => cardLoading.value = null);
};

const lockCard = (card) => {
    cardLoading.value = card;
    Request.getJson(Router.generate('cps.lock', {id: card.id})).then(() => cardLoading.value = null)
};

const hasOnlineCard = computed(() => {
    for (const card of cartes.value) {
        if (card.info && card.info.online) {
            return true;
        }
    }

    return false;
});

if (!cpsStore.init) {
    App.webSocket.subscribe('cps', (uri, data) => {
        cpsStore.update(data);
    });

    cpsStore.update(cpsData.value);
}

const menu = ref();
const menuCarte = ref(null);
const menuItems = computed(() => menuCarte.value ? [
    {label: 'Verrouiller', icon: 'fa-solid fa-fw fa-lock', disabled: !canLockCard(menuCarte.value), command: () => lockCard(menuCarte.value)}
] : []);


const toggleMenu = (event, carte) => {
    menuCarte.value = carte;
    menu.value.toggle(event);
}
</script>
