<template>
    <div class="flex-1 position-relative d-flex flex-column height-0">
        <transition name="chat-scroll-bottom">
            <div v-if="messages.length && !realBottom" class="chat-scroll-bottom">
                <div @click="scrollBottom">
                    <i class="fa-solid fa-arrow-down mr-2"></i> Retourner en bas
                </div>
            </div>
        </transition>
        <div ref="chat" class="js-perfect-scrollbar">
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
import {computed, nextTick, onMounted, ref, toRefs, watch} from "vue";
import {useElementSize, useScroll} from "@vueuse/core";

const props = defineProps({
    messages: Array,
})

const {messages} = toRefs(props);

const chat = ref();

const { y, arrivedState } = useScroll(chat);
const { bottom } = toRefs(arrivedState)
const { height } = useElementSize(chat)

const realBottom = computed(() => {
    return bottom.value || (chat.value && chat.value.scrollHeight <= height.value.toFixed());
})
const scrollBottom = () => {
    if (chat.value) {
        y.value = chat.value.scrollHeight;
    }
}

watch(messages, () => {
    if (realBottom.value) {
        nextTick(() => {
            scrollBottom();
        });
    }
}, {deep: true})

defineExpose({
    scrollBottom
});

onMounted(() => {
    App.Layout.enablePerfectScrollbar();
    scrollBottom();
});
</script>
