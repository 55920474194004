<template>
    <tr class="trajet-row cursor-context-menu"
        :class="{'separator': trajet.separator}"
        :data-id="trajet.id"
        @click="emit('click')"
        ref="el"
    >
        <td class="text-center">
            <trajet-etat :trajet="trajet"></trajet-etat>
        </td>
        <td class="text-center text-nowrap">
            <trajet-type :trajet="trajet" class="ml-n2"></trajet-type>
        </td>
        <td class="text-nowrap">
            <motif :motif="trajet.transport.motif"></motif>
        </td>
        <td class="text-nowrap text-center px-2">
            <i class="fa-solid fa-calendar-days text-muted" v-if="trajet.serie" :title="trajet.serie.libelle"></i>
            <i class="fa-solid fa-handshake text-muted" v-if="trajet.transport.contrat" :title="trajet.transport.contrat.libelle"></i>
            <i class="fa-fw im-syringe2 text-muted" v-if="trajet.transport.avecInfirmier" :title="Translator.trans('libelle.avecInfirmier')"></i>
            <i class="fa-fw im-stethoscope text-muted" v-if="trajet.transport.avecMedecin" :title="Translator.trans('libelle.avecMedecin')"></i>
            <i class="im-alarm2 text-info" v-if="trajet.transport.estUrgent" :title="trajet.transport.numeroMission"></i>
            <span v-if="trajet.transport.estGarde" :title="Translator.trans('libelle.estGarde')">
                <i class="fa-stack text-muted">
                    <i class="fa-regular fa-calendar fa-stack-1x"></i>
                    <i class="fa-solid fa-truck-medical fa-stack-05x"></i>
                </i>
            </span>
            <i class="fa-solid fa-circle-info" v-if="trajet.transport.note" :title="trajet.transport.note"></i>
        </td>
        <td :style="{'background': simultane && (simultane.trajet === trajet.id || simultaneTrajetIds.includes(trajet.id)) ? '#'+simultane.couleur : (trajet.simultane && (!simultane || simultane.simultane !== trajet.simultane.id) ? '#'+trajet.simultane.couleur : null)}">
            <template v-if="!simultaneLoading && simultane && simultane.trajet === trajet.id">
                <b-btn @click="emit('simultane-cancel')" :title="Translator.trans('action.annuler')" variant="secondary" class="btn-table"><i class="fa-solid fa-fw fa-xmark"></i></b-btn>
                <b-btn @click="emit('simultane-save')" :title="Translator.trans('action.valider')" variant="success" class="btn-table"><i class="fa-solid fa-fw fa-check"></i></b-btn>
            </template>
            <div class="checkbox" v-if="!simultaneLoading && simultane && (simultane.simultane || simultane.trajet !== trajet.id) && (!trajet.simultane || trajet.simultane.id === simultane.simultane)">
                <input type="checkbox" @input="emit('simultane-toggle', $event.target.checked)" :checked="simultaneTrajetIds.includes(trajet.id)" :id="'trajet_simultane_'+trajet.id">
                <label :for="'trajet_simultane_'+trajet.id"></label>
            </div>
            <span class="spinner-border text-white" role="status" v-if="simultaneLoading && simultane && simultane.trajet === trajet.id"></span>
        </td>
        <td class="text-center text-normal" :class="{'text-line-through': trajet.estAnnule}">
            <div class="d-flex">
                <div class="text-center flex-1" v-for="(letter, index in trajet.rendezVous.time.split('')" :class="{'font-weight-500': index <= 1}">
                    {{ letter }}
                </div>
            </div>
        </td>
        <td class="text-ellipsis" :title="trajet.transport.patient.text">
            <trajet-patient :trajet="trajet"></trajet-patient>
        </td>
        <td class="text-center text-muted">
            {{ trajet.kilometre }}
        </td>
        <td class="text-ellipsis" :title="[trajet.codePostalPriseEnCharge, trajet.villePriseEnCharge].join(' ')">
            <indicateur-geocodage :small="true" :value="trajet.precisionPriseEnCharge"></indicateur-geocodage>
            {{ trajet.villePriseEnCharge }}
        </td>
        <td class="text-muted text-ellipsis" :title="[trajet.adressePriseEnCharge, trajet.complementAdressePriseEnCharge].join('\n')">
            {{ trajet.adressePriseEnCharge }} {{ trajet.complementAdressePriseEnCharge }}
        </td>
        <td class="text-ellipsis" :title="[trajet.codePostalDestination, trajet.villeDestination].join(' ')">
            <indicateur-geocodage :small="true" :value="trajet.precisionDestination"></indicateur-geocodage>
            {{ trajet.villeDestination }}
        </td>
        <td class="text-muted text-ellipsis" :title="[trajet.adresseDestination, trajet.complementAdresseDestination].join('\n')">
            {{ trajet.adresseDestination }} {{ trajet.complementAdresseDestination }}
        </td>
        <td is="vue:ressource-column" ref="equipage" field="equipage" :trajet="trajet" :ressourceDropSaving="ressourceDropSaving"></td>
        <td is="vue:ressource-column" ref="vehicule" field="vehicule" :trajet="trajet" :ressourceDropSaving="ressourceDropSaving"></td>
        <td is="vue:ressource-column" ref="personnel1" field="personnel1" :trajet="trajet" :ressourceDropSaving="ressourceDropSaving"></td>
        <td is="vue:ressource-column" ref="personnel2" field="personnel2" :trajet="trajet" :ressourceDropSaving="ressourceDropSaving"></td>
        <td is="vue:top-column" :trajet="trajet" ref="topDebut" field="topDebut" :index="tops.TOP_DEBUT"></td>
        <td is="vue:top-column" :trajet="trajet" ref="topPriseEnChargeArrivee" field="topPriseEnChargeArrivee" :index="tops.TOP_PRISE_EN_CHARGE_ARRIVEE"></td>
        <td is="vue:top-column" :trajet="trajet" ref="topDestinationArrivee" field="topDestinationArrivee" :index="tops.TOP_DESTINATION_ARRIVEE"></td>
        <td is="vue:top-column" :trajet="trajet" ref="topFin" field="topFin" :index="tops.TOP_FIN"></td>
        <td class="text-center text-nowrap">
            <a v-if="trajet.roles.ROLE_REGULATION_TRANSPORT_VIEW" :target="openTarget" :href="Router.generate('shared.transport.view', {app: 'regulation', id: trajet.transport.id, trajet: trajet.type})" :title="Translator.trans('action.voir')" class="btn btn-table btn-info mr-1">
                <i class="fa-solid fa-fw fa-eye"></i>
            </a>
            <a v-if="trajet.roles.ROLE_REGULATION_TRANSPORT_EDIT" :target="openTarget" :href="Router.generate('shared.transport.edit', {app: 'regulation', id: trajet.transport.id})" :title="Translator.trans('action.modifier')" class="btn btn-table btn-success mr-1">
                <i class="fa-solid fa-fw fa-pencil"></i>
            </a>
            <a href="#" :title="Translator.trans('libelle.autres-actions')" class="btn btn-table btn-secondary js-context-menu-btn">
                <i class="fa-solid fa-chevron-down"></i>
            </a>
        </td>
    </tr>
</template>

<script setup>
import Translator from "@/App/Translator";
import Router from "@/App/Router";
import {ref, toRefs} from "vue";

const emit = defineEmits(['click', 'simultane-save', 'simultane-cancel', 'simultane-toggle']);

const props = defineProps({
    trajet: Object,
    openTarget: String,
    tops: Object,
    ressourceDropSaving: Object,
    simultane: Object,
    simultaneTrajetIds: Array,
    simultaneLoading: Boolean,
});

const el = ref();

const {trajet, openTarget, tops, simultane, simultaneTrajetIds, simultaneLoading} = toRefs(props);

defineExpose({
    el,
})
</script>
