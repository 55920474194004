import {defineStore} from "pinia";
import {ref} from "vue";
import moment from "moment";

export const usePaieStore = defineStore('paie', () => {

    let types = ref([]);

    let typesJson = JSON.stringify([]);

    const addType = (type) => {
        for (let i in types.value) {
            if (type._date.isSame(types.value[i]._date)) {
                types.value.splice(i, 1);
            }
        }
        type._index = types.value.length;
        
        types.value.push(type);
    }

    const removeType = (index) => {
        types.value.splice(index, 1);

        for (let index in types.value) {
            types.value[index]._index = index;
        }
    }

    function emptyState () {
        this.disableAndClearHistory();
        let parsedTypes = JSON.parse(typesJson);

        for (let index in parsedTypes) {
            let type = parsedTypes[index];

            type._index = index;
            type._date = moment(type.debutPrevu.date, 'DD/MM/YYYY');
        }

        types.value = parsedTypes;
    }

    const setTypesJson = (_typesJson) => {
        typesJson = _typesJson;
    }

    function enablePaieHistory () {
        this.enableHistory({types: types.value});
    }

    return {addType, removeType, emptyState, setTypesJson, types, enablePaieHistory};
})
