<template>
    <Marker anchor="bottom" v-model:popup="popup" @update:lngLat="emit('update:lngLat', $event)">
        <template #content>
            <Transition name="marker-pin-container" appear :duration="badge ? 450 : 200">
                <Pin
                    :pulse="pulse"
                    :clickable="clickable"
                    :variant="variant"
                    :notifications="notifications"
                    :badge="badge"
                    :shape="shape"
                    :dimmed="dimmed"
                    :selected="popup"
                    @click="emit('click', $event)"
                    @dblclick="emit('dblclick', $event)"
                >
                    <template #content>
                        <slot name="content"></slot>
                    </template>
                    <template #contentHover v-if="(popup && $slots.contentPopup) || $slots.contentHover">
                        <slot name="contentPopup" v-if="popup && $slots.contentPopup"></slot>
                        <slot name="contentHover" v-else></slot>
                    </template>
                    <template #indicator v-if="$slots.indicator">
                        <slot name="indicator"></slot>
                    </template>
                </Pin>
            </Transition>
        </template>
        <template><slot></slot></template>
    </Marker>
</template>

<script setup>
import Marker from '@/Vue/Components/RegulationV2/Map/Base/Marker';
import Pin from '@/Vue/Components/RegulationV2/Layout/Pin';
import {provide, ref, toRefs, watch} from "vue";

const emit = defineEmits(['click', 'dblclick', 'contextmenu', 'update:popup', 'update:lngLat']);

const props = defineProps({
    pulse: Boolean,
    clickable: Boolean,
    variant: {default: 'black', type: String},
    notifications: {default: [], type: Array},
    badge: Number,
    shape: {default: 'circle', type: String},
    dimmed: Boolean,
    popup: Boolean,
})
const {variant, shape, badge, notifications, dimmed, popup: popupInput} = toRefs(props)

const popup = ref(popupInput.value);
watch(popupInput, () => popup.value = popupInput.value);
watch(popup, () => emit('update:popup', popup.value));

provide('shape', shape);
</script>