<template>
<transition name="notifications-bandeau" duration="500">
  <div v-if="isOpen" class="notifications-bandeau p-2 position-absolute d-none d-lg-flex" :class="{'force': force}" @click="emit('click', $event)">
    <div class="bandeau-container" :class="{'large': !badgeIsLarge}">
        <transition name="notification" duration="2000">
        <div v-if="notification" :key="notification.id" class="notification" :class="notification.criticite === 1 ? 'warning' : notification.criticite === 2 ? 'danger' : ( notification.criticite === 0 ? 'info' : '')">
            <div class="bg-light border border-white text-muted py-2 px-3 font-weight-600 d-flex align-items-center line-height-1 cursor-pointer">
                <i class="fa-solid fa-triangle-exclamation mr-1" v-if="notification.criticite === 2"></i>
                {{ notification.titre }}
            </div>
        </div>
        </transition>
    </div>
  </div>
</transition>
</template>

<script setup>
import {computed, onMounted, ref, toRefs, watch} from 'vue';
import {useNotificationsStore} from "@/Vue/Stores/Notifications";
import {storeToRefs} from "pinia";
import {useDocumentVisibility, useIntervalFn, useTimeoutFn} from "@vueuse/core";

const emit = defineEmits(['click']);
const props = defineProps({
  'dropdownOpen': Boolean,
  'badgeIsLarge' : Boolean,
})

const {dropdownOpen, badgeIsLarge} = toRefs(props);

const store = useNotificationsStore();
const {notificationsBandeau, newNotifications, notificationsById, isInit} = storeToRefs(store);

const force = ref(true);
watch(isInit, (value) => {
    if (value) {
        setTimeout(() => force.value = false, 100);
    }
}, {immediate: true})



const intervalActive = computed(() => isOpen.value && (newNotifications.value.length > 0 || notificationsBandeau.value.length > 1) && visibility.value === 'visible');

const visibility = useDocumentVisibility();
const notificationStack = computed(() => [...newNotifications.value, ...notificationsBandeau.value]);
const notificationBandeauIndex = ref(null);
const notificationId = ref(null);
const notification = computed(() => notificationsById.value[notificationId.value] ?? null);

const isOpen = ref(notification.value && !dropdownOpen.value);

const openTimeout = useTimeoutFn(() => isOpen.value = true, 600, {immediate: false});

watch(notification, () => {
    isOpen.value = notification.value && !dropdownOpen.value;
});
watch(dropdownOpen, () => {
    if (!dropdownOpen.value && notification.value) {
        openTimeout.start();
    } else {
        isOpen.value = false;
    }
});



const next = () => {
    if (!dropdownOpen.value) {
        if (notification.value && newNotifications.value.includes(notification.value)) {
            store.consumeNewNotification(notification.value);
        }

        if (newNotifications.value.length) {
            notificationId.value = newNotifications.value[0].id;
        } else if (notificationsBandeau.value.length) {
            if (null !== notificationBandeauIndex.value && notificationBandeauIndex.value < notificationsBandeau.value.length - 1) {
                notificationBandeauIndex.value++;
            } else {
                notificationBandeauIndex.value = 0;
            }
            notificationId.value = notificationsBandeau.value[notificationBandeauIndex.value].id;
        } else {
            notificationId.value = null;
        }
    }
}

const interval = useIntervalFn(next, 5000, {immediate: false});

watch(notificationStack, () => {
    // pas de notif actuellement dans le bandeau ou notif en cours est pin bandeau
    if (notificationStack.value.length && (!notification.value || (notificationsBandeau.value.includes(notification.value)) )) {
        next();
    }
}, {deep: true});

watch(notification, (value, oldValue) => {
    // si notification courante n'a plus lieu d'être dans le bandeau
    if (notification.value && !notificationStack.value.includes(notification.value)) {
        next();
    }
}, {deep: true});

watch(intervalActive, () => {
    if (intervalActive.value) {
        interval.resume();
    } else {
        interval.pause();
    }
});

onMounted(() => next());
</script>
