<template>
    <button type="button" @click="emit('click', $event)" :class="disabled ? 'text-gray-300 pointer-events-none' : 'text-gray-600 dark:text-white/80'" class="py-1 px-2 text-center font-semibold !bg-opacity-70 hover:!bg-opacity-70 !bg-white dark:!bg-black dark:!bg-opacity-30 hover:!bg-gray-50 dark:hover:!bg-black/50 flex-auto relative transition">
        <slot></slot>
    </button>
</template>

<script setup>
defineProps({
    disabled: Boolean,
});
const emit = defineEmits(['click']);
</script>