'use strict';

App.Regulation.Equipage = class {};

App.Regulation.Equipage.New = class {
    constructor() {
        this._initEmbed();
    }

    _initEmbed() {
        let handleItem = (e, item) => {
            const $newItem = $(item);
            const $previousItem = $newItem.prev();

            if($previousItem.length) {
                // Couleurs
                for(const couleurField of ['couleur1', 'couleur2']) {
                    const value = $previousItem.find('[data-field="'+couleurField+'"]').next().find('input:checked').val();
                    $newItem.find('[data-field="'+couleurField+'"]').next().find('input[value="'+value+'"]').prop('checked', true).change();
                }

                // Dates
                for(const dateField of ['debut', 'fin']) {
                    const value = $previousItem.find('[data-field="'+dateField+'"]').val();
                    $newItem.find('[data-field="'+dateField+'"]').val(value).change();
                }

                // Structure
                const structure = $previousItem.find('.tree-select input:checked').val();
                $newItem.find('.tree-select input[value="'+structure+'"]').prop('checked', true).change();
            }
        };

        $('.js-embed-add').on('embed.new', handleItem);
        $('.js-embed-item').each(handleItem);
    }
};