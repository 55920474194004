<template>
    <div class="checkbox min-height-auto">
        <input type="checkbox" :id="id" class="form-check-input" :checked="value" :disabled="disabled" @change="$emit('change', $event.target.checked)" @input="$emit('input', $event.target.checked)">
        <label :for="id">
            <slot></slot>
        </label>
    </div>
</template>

<script>
    export default {
        props: ['value', 'disabled'],
        computed: {
            id() {
                return 'vue_form_checkbox_'+this._uid;
            }
        },
    }
</script>
