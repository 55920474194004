<template>
    <ul class="chat-list mt-4" v-if="messages.length">
      <template v-for="(message, index) in messages">
        <li class="chat-separator" v-if="index === 0 || message.horodatage.date !== messages[index-1].horodatage.date"><span :title="message.horodatage.date">{{ formatDateSeparator(message.horodatage.date) }}</span></li>
        <messagerie-message :index="index" :message="message" :is-index="isIndex" @hover="onHover(message, $event)" :focus="focusedMessages.length ? focusedMessages.includes(message) : null"/>
      </template>
    </ul>
    <div class="text-center pt-5 mt-5" v-else>
      <i class="fa-4x im-pencil"></i>
      <h3>Aucun message trouvé</h3>
      <p class="text-muted" v-if="!isIndex">Envoyez un premier message pour débuter une conversation.</p>
    </div>
</template>

<script setup>
import Moment from "moment";
import {computed, ref, toRefs} from "vue";

const props = defineProps({
    messages: Array,
    isIndex: Boolean,
    hover: Boolean,
});

const {messages, isIndex, hover} = toRefs(props);

const hoveredAppareilsIds = ref([]);
const onHover = (message, hovered) => {
    if (hover.value) {
        if (hovered) {
            hoveredAppareilsIds.value = [message.appareilDestinataire, message.appareilDestinataire2, message.appareilEmetteur].filter(Boolean).map(a => a.id);
        } else {
            hoveredAppareilsIds.value = [];
        }
    }
};

const focusedMessages = computed(() => {
    return messages.value.filter(message => [message.appareilDestinataire, message.appareilDestinataire2, message.appareilEmetteur].filter(Boolean).map(a => a.id).some(id => hoveredAppareilsIds.value.includes(id)));
});

const formatDateSeparator = (date) => {
    const moment = Moment(date, 'DD/MM/YYYY');

    if(moment.isValid()) {
        if(moment.isSame(Moment(), 'day')) {
            return 'Aujourd\'hui';
        }
        if(moment.isSame(Moment().subtract(1, 'day'), 'day')) {
            return 'Hier';
        }
    }

    return date;
}
</script>
