import Translator from '../../App/Translator';

export default {
    install: (Vue) => {
        Vue.mixin({
            methods: {
                trans(id, parameters, domain, locale) {
                    return Translator.trans(id, parameters, domain, locale);
                },
            }
        })

    }
};
