<template>
    <div class="flex">
        <div v-if="hasDepartReel" class="w-1 py-1 bg-green-200 font-medium border-r-2 border-dashed border-green-500"></div>
        <div class="transition-all bg-[length:200%_100%] bg-gradient-to-l from-transparent from-50% to-50% to-green-200/70 py-1 text-center flex-1 px-1 bg-right-bottom" :class="{
            '!bg-left-bottom font-medium text-surface-700': estReel,
            'rounded-l-md ml-2 ': top === 'debut',
            'rounded-r-md mr-2 !pl-2 ': top === 'fin',
            'border-r-2 border-dashed border-green-500 !pl-2 mr-1': estCourant,
        }">{{ time }}</div>
    </div>
</template>

<script setup>

import {computed, ref, toRefs, watch} from "vue";

const props = defineProps({
    trajet: Object,
    top: String,
});
const {trajet, top} = toRefs(props);

const index = computed(() => ({
    debut: 0,
    priseEnCharge: 1,
    destination: 3,
    fin: 5,
}[top.value]));
const field = computed(() => ({
    debut: 'topDebut',
    priseEnCharge: 'topPriseEnChargeArrivee',
    destination: 'topDestinationArrivee',
    fin: 'topFin',
}[top.value]));
const topCourant = computed(() => trajet.value.topCourant);
const value = computed(() => trajet.value[field.value]);
const date = computed(() => value.value ? value.value.date : null);
const time = computed(() => value.value ? value.value.time : null);
const estCourant = computed(() => !trajet.value.estTermine && topCourant.value !== null && index.value === topCourant.value);
const estReel = computed(() => trajet.value.estTermine || (topCourant.value !== null && index.value <= topCourant.value));
const hasDepartReel = computed(() => top.value !== 'debut' && top.value !== 'priseEnCharge' && topCourant.value === index.value - 1);

// todo: retards, popover / click
</script>