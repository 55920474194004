<template></template>

<script setup>
import {inject, onMounted, onUnmounted} from 'vue';
import {GeolocateControl} from "maplibre-gl";

const map = inject('map');
const control = new GeolocateControl({
    positionOptions: {
        enableHighAccuracy: true
    },
    trackUserLocation: true
});

onMounted(() => map.value.addControl(control, 'bottom-right'));
onUnmounted(() => map.value.removeControl(control));
</script>