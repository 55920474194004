<template>
    <div>
        <h3 class="mt-0">Annuler le trajet</h3>
        <div class="section-title"><span>{{ motif.libelle }}</span></div>
        <div class="mt-4 px-5">
            <textarea ref="commentaireInput" class="form-control" v-model="commentaire" :disabled="loading" :required="commentaireRequired" :placeholder="commentaireRequired ? 'Commentaire requis' : 'Commentaire'"></textarea>
        </div>
        <form-validation :items="errors"></form-validation>
        <div class="text-center mt-5">
            <button class="btn btn-secondary js-close-swal" type="button">Annuler</button>
            <button type="button" class="btn btn-info" :disabled="!canSubmit" @click="submit">
                <i class="fa-solid fa-check"></i>
                <span class="d-none d-md-inline">Confirmer</span>
            </button>
        </div>
    </div>
</template>

<script setup>
import Router from "@/App/Router";
import {computed, toRefs, ref, onMounted} from "vue";
import Request from "@/App/Request";
import swal from "sweetalert2";

const emit = defineEmits(['data']);
const props = defineProps({
    trajet: Object,
    motif: Object,
});

const {trajet, motif} = toRefs(props);
const commentaire = ref('');
const commentaireInput = ref();
const loading = ref(false);
const errors = ref([]);

const commentaireRequired = computed(() => motif.value.modeCommentaire === 2);

const canSubmit = computed(() => {
    return !loading.value && (!commentaireRequired.value || commentaire.value.length > 0);
});

const submit = () => {
    loading.value = true;
    errors.value = [];

    Request.postJson(Router.generate('regulation.trajet.annuler.ajax', {id: trajet.value.id}), {
        motif: motif.value.id, commentaire: commentaire.value,
    }).then((data) => {
        if (data.success) {
            swal.close();
            emit('data', data.data);
        } else {
            loading.value = false;
            errors.value = data.errors;
        }
    });
}

onMounted(() => commentaireInput.value.focus());

</script>
