<template>
    <Custom>
        <ButtonGroup>
            <Button @click="rulerActive = !rulerActive">
                <i class="fa-solid fa-ruler" :class="{'text-blue-500': rulerActive}"></i>
            </Button>
<!--            <Button @click="(event) => helpOverlay.toggle(event)">-->
<!--                <i class="fa-regular fa-circle-info"></i>-->
<!--            </Button>-->
            <Button @click="refresh" :disabled="refreshLoading">
                <i class="fa-regular fa-refresh" :class="{'fa-spin': refreshLoading}"></i>
            </Button>
        </ButtonGroup>
    </Custom>
    <Popover ref="helpOverlay">
        <div class="w-[300px]">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid, explicabo, itaque. Adipisci eum, ex exercitationem fuga ipsa iusto, laboriosam modi natus nemo non obcaecati optio quod soluta voluptas voluptate voluptates.
        </div>
    </Popover>
    <Ruler v-if="rulerActive"></Ruler>
</template>

<script setup>
import Custom from '@/Vue/Components/RegulationV2/Map/Base/Control/Custom'
import ButtonGroup from '@/Vue/Components/RegulationV2/Map/Base/Control/Button/Group'
import Button from '@/Vue/Components/RegulationV2/Map/Base/Control/Button/Button'
import Ruler from '@/Vue/Components/RegulationV2/Map/Base/Ruler'
import Popover from 'primevue/popover';
import {ref, computed} from 'vue';
import {useGeolocalisationStore} from "@/Vue/Stores/RegulationV2/Geolocalisation";
import {storeToRefs} from "pinia";
import {useRegulationStore} from "@/Vue/Stores/Regulation";

const helpOverlay = ref();

const geolocalisationStore = useGeolocalisationStore();
const {rulerActive, refreshing} = storeToRefs(geolocalisationStore);
const {refresh: refreshGeolocalisation} = geolocalisationStore;

const regulationStore = useRegulationStore();
const {refresh: refreshRegulation} = regulationStore;
const {isLoading} = storeToRefs(regulationStore);

const refreshLoading = computed(() => refreshing.value || isLoading.value);
const refresh = () => {
    refreshGeolocalisation();
    refreshRegulation();
};
</script>