'use strict';

App.Geoloc.Itineraire = class {
    constructor(infos) {
        for (let i in infos) {
            this[i] = infos[i];
        }
        this.id = App.Utils.generateUid();

        this.formatDistance();
        this.formatDuree();
    }

    getResume () {
        let html = '';

        html += '<div class="mb-1"><i class="' + this.params.depart.iconClass + (this.params.depart.colorClass ? ' ' + this.params.depart.colorClass : '') + '"' + (this.params.depart.iconColor ? ' style="color:' + this.params.depart.iconColor + '"': '') + '></i> ' + GEOLOC_CONST.libelles['depart'] + ' : ' + this.params.depart.getLibelle(true) + '</div>';
        html += '<div class="mb-1"><i class="' + this.params.arrivee.iconClass + (this.params.arrivee.colorClass ? ' ' + this.params.arrivee.colorClass : '') + '"' + (this.params.arrivee.iconColor ? ' style="color:' + this.params.arrivee.iconColor + '"': '') + '></i> ' + GEOLOC_CONST.libelles['arrivee'] + ' : ' + this.params.arrivee.getLibelle(true) + '</div>';
        html += '<div class="mb-2">';
            html += '<i class="im-road" title="' + GEOLOC_CONST.libelles['distance'] + '"></i> ' + this.distance;
            html += '<i class="im-clock ml-4" title="' + GEOLOC_CONST.libelles['duree'] + '"></i> ' + this.duree;
        html += '</div>';

        return html;
    }

    showResumeTrajet() {
        $('.js-xroute-trajet.active').removeClass('active');

        let html = '<div class="js-xroute-trajet m-3 p-3 mb-5 border border-secondary active" style="display:none;" data-itineraire="' + this.id + '">';
                html += '<small class="js-xroute-trajet-remove im-cross text-muted float-right" title="' + GEOLOC_CONST.libelles['supprimer'] + '"></small>';
                html += '<div class="mb-1 text-primary">' + GEOLOC_CONST.libelles['resume-trajet'] + '</div>';
                html += this.getResume();
                html += '<div class="float-right">';
                    if(GEOLOC_CONST.droits['ROLE_GEOLOCALISATION_ITINERAIRE_RETOUR']) {
                        html += '<button type="button" class="js-xroute-trajet-retour btn btn-outline-secondary btn-sm mr-2"><i class="im-rotate-ccw2"></i> ' + GEOLOC_CONST.libelles['trajet-retour'] + '</button>';
                    }
                    if(GEOLOC_CONST.droits['ROLE_GEOLOCALISATION_ITINERAIRE_FEUILLE_ROUTE']) {
                        html += '<button type="button" class="js-xroute-trajet-manoeuvres btn btn-outline-secondary btn-sm"><i class="im-compass6"></i> ' + GEOLOC_CONST.libelles['feuille-de-route'] + '</button>';
                    }
                html += '</div>';
            html += '</div>';

        $('.js-xroute-trajets').prepend(html);
        $('.js-xroute-trajet.active').show('fast');
    }

    showManoeuvres() {
        let html = '';

        html += '<div class="m-3">';
            html += '<div class="mb-1 text-primary">' + GEOLOC_CONST.libelles['feuille-de-route'] + '</div>';
            html += this.getResume();
        html += '</div>';

        for (let i in this.manoeuvres) {
            html += '<div class="clearfix m-3 px-3 pt-2 border border-secondary border-bottom-0 border-left-0 border-right-0">';
                html += '<img class="float-left" src="' + this.assets.replace('maneuvers-2x', this.manoeuvres[i].manoeuvreType + '_' + this.manoeuvres[i].turnOrient + '_' + this.manoeuvres[i].turnWeight) + '"/>';
                html += this.manoeuvres[i].manoeuvreDesc;
            html += '</div>';
        }

        App.Layout.openRightSide();
        $('#js-geoloc-right-panel').html(html);
    }

    formatDistance() {
        if(this.distance && this.distance !== '') {
            let distance = this.distance;
            this.distance = (Math.round(distance >= 1000 ? distance / 100 : distance * 10) / 10) + ' ' + (distance >= 1000 ? 'Km' : 'm');
        } else {
            this.distance = '?';
        }
    }

    formatDuree() {
        if(this.duree && this.duree !== '') {
            let duree = this.duree;

            let minutes = Math.floor(duree / 60);
            let hours = Math.floor(minutes / 60);
            let seconds = duree % 60;
            minutes = minutes % 60;

            this.duree = (hours > 0 ? ("0" + hours).slice(-2) + "h " : '')
                       + (minutes > 0 ? ("0" + minutes).slice(-2) + "min " : '')
                       + (seconds > 0 ? ("0" + seconds).slice(-2) + "s " : '');
        } else {
            this.duree = '?';
        }
    }
};
