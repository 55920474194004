<template>
    <a v-if="route && value && value.id" v-bind="attributes" :href="path(route, {...routeParams, id: value.id})">{{ text }}</a>
    <span v-else-if="value" v-bind="attributes">{{ text }}</span>
</template>

<script>
    export default {
        props: ['value', 'route', 'routeParams', 'textFields'],
        computed: {
            attributes() {
                return {
                    class: this.value && this.value.couleur ? 'badge badge-primary' : null,
                    style: this.value && this.value.couleur ? 'background: '+this.value.couleur+';' : null,
                };
            },
            text() {
                if(this.textFields) {
                    return this.textFields.map((field) => this.value[field]).join(' ');
                }

                return this.value.text;
            },
        }
    }
</script>
