<template>
    <div class="d-flex align-items-center justify-content-between" v-if="count">
        <div></div>
        <b-pagination
            :value="value"
            @input="$emit('input', $event)"
            :total-rows="count"
            :per-page="perPage"
        ></b-pagination>
        <div v-if="noText"></div>
        <div class="mr-3" v-else>
            <template v-if="!short">Éléments </template>{{ ((value-1) * perPage) + 1 }} à {{ Math.min(count, value * perPage) }} sur <b>{{ count }}</b>
        </div>
    </div>
</template>

<script>
export default {
    props: ['value', 'perPage', 'count', 'short', 'noText'],
    watch: {
        value() {
            this.fixCurrentPage();
        },
        count() {
            this.fixCurrentPage();
        },
    },
    methods: {
        fixCurrentPage() {
            if(this.value < 1) {
                this.$emit('input', 1);
            } else if(this.value !== 1) {
                const maxPage = Math.ceil(this.count / this.perPage);

                if(this.value > maxPage) {
                    this.$emit('input', maxPage);
                }

            }
        }
    }
}
</script>
