<template>
    <ChatBox v-focustrap formHeight="44px" :messages="ressource.messages" :errors="errors">
        <template #form>
            <div class="flex gap-x-2 p-2">
                <InputText type="text" placeholder="Message…" maxlength="140" v-model="message" class="flex-1" autofocus ref="messageInput" @keyup.enter="send" :disabled="sending"/>
                <Button :icon="sending ? 'fa-solid fa-spinner fa-spin' : 'fa-solid fa-paper-plane-top'" title="Envoyer" @click="send" :disabled="sending || '' === message"/>
            </div>
        </template>
    </ChatBox>
</template>

<script setup>
import ChatBox from "@/Vue/Components/RegulationV2/Layout/Chat/Box.vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import {computed, nextTick, onMounted, ref, toRefs, watch} from "vue";
import {useRegulationStore} from "@/Vue/Stores/Regulation";
import {useGeolocalisationStore} from "@/Vue/Stores/RegulationV2/Geolocalisation";

const props = defineProps({
    ressource: Object,
});
const {ressource} = toRefs(props);
const message = ref('');
const errors = ref([]);
const sending = ref(false);
const messageInput = ref();

const regulationStore = useRegulationStore();
const {getRessourcesAppareilCollection, onMessagerieSend} = regulationStore;
const destinataires = computed(() => getRessourcesAppareilCollection([ressource.value.regulation]).map(appareil => appareil.id));

const geolocalisationStore = useGeolocalisationStore();

const {clearNotifications} = geolocalisationStore;
const notifications = computed(() => ressource.value.notifications.filter(notification => notification.type === 'messagerie'));

watch(notifications, () => {
    if (notifications.value.length) {
        clearNotifications(notifications.value);
    }
}, {immediate: true});

const send = () => {
    if('' === message.value) {
        return;
    }

    sending.value = true;
    errors.value = [];

    onMessagerieSend({
        message: message.value,
        destinataires: destinataires.value,
    }, (res) => {
        sending.value = false;
        if (res.success) {
            message.value = '';
        } else {
            errors.value = res.errors;
        }
        nextTick(() => messageInput.value.$el.focus());
    })
};
</script>