<template>
    <slot name="result" :result="result"></slot>
</template>

<script setup>
import {ref, toRefs, onMounted} from "vue";
import Router from "@/App/Router";
import Request from "@/App/Request";

const props = defineProps({
    lngLat: Array,
});
const {lngLat} = toRefs(props);

const result = ref(null);

onMounted(() => {
    Request.getJson(Router.generate('geocodage.reverse', {latitude: lngLat.value[1], longitude: lngLat.value[0]})).then((data) => {
        if (data.success) {
            result.value = data.data;
        } else {
            result.value = false;
        }
    });
});
</script>