<template>
    <div>
        <div class="panel panel-compact">
            <div class="panel-heading d-flex justify-content-between bg-light py-3 px-4">
                <div>Incidents</div>
            </div>
            <div class="panel-wrapper">
                <div class="panel-body">
                    <b-table table-class="table-compact" ref="incidentsTable" :busy="loading" responsive no-sort-reset show-empty bordered striped :items="incidents" :fields="incidentsFields" :per-page="perPage" :current-page="currentPage" sort-by="date" sort-desc>
                        <template #empty>
                            <span class="text-muted">{{ Translator.trans('libelle.aucun-element') }}</span>
                        </template>
                        <template #table-busy>
                            <div class="p-3">
                                <b-spinner class="mr-2"></b-spinner> Chargement…
                            </div>
                        </template>
                        <template #cell(date)="{value}">
                            {{ value.format('DD/MM/YYYY') }}
                        </template>
                        <template #cell(type)="{item, value}">
                            <span class="badge" :class="getBadgeClass(item)">
                                {{ value }}
                            </span>
                        </template>
                    </b-table>
                    <table-pagination :count="incidents.length" :per-page="perPage" v-model="currentPage"></table-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Router from "@/App/Router";
import Translator from "@/App/Translator";
import {onMounted, ref} from "vue";
import Moment from "moment";
import {useRequest} from "@/Vue/Composables/Request";

const {getJson} = useRequest();

const emit = defineEmits(['refresh', 'error']);

const loading = ref(true);
const incidents = ref([]);
const perPage = ref(12);
const currentPage = ref(0);

const incidentsFields = [
    {key: 'date', label: Translator.trans('libelle.date'), thClass: 'width-1px', tdClass: 'text-nowrap', sortable: true, formatter: (value) => Moment(value, 'DD/MM/YYYY')},
    {key: 'type', label: Translator.trans('libelle.type'), thClass: 'width-1px', sortable: true},
    {key: 'produit', label: Translator.trans('libelle.produit'), thClass: 'width-1px', sortable: true},
    {key: 'message', label: Translator.trans('libelle.message-s')}
]

const getBadgeClass = (item) => {
    const type = item.type.toLowerCase();
    const message = item.message.toLowerCase();

    const mapping = {
        'danger': ['indisponib', 'incident'],
        'warning': ['maintenance'],
    };

    for (const severity in mapping) {
        for (const keyword of mapping[severity]) {
            if (type.includes(keyword) || message.includes(keyword)) {
                return 'badge-'+severity;
            }
        }
    }

    return 'badge-light';
};


const refresh = () => {
    loading.value = true;

    getJson(Router.generate('sesam-vitale')).then((data) => {
        incidents.value = data;
        emit('refresh');
    }, (err) => emit('error', err)).finally(() => {
        loading.value = false;
    });
};

onMounted(() => refresh());

</script>