<template>
    <div class="item item-personnel " v-if="estPremierPersonnelInactif" style="height:30px; padding: inherit;">
        <span class="text-muted ml-1">Personnels inactifs</span>
    </div>
    <div class="item item-personnel" :class="{'opacity-80': !personnel.estActif}">
        <div class="d-flex align-items-center">
            <route v-if="roleView" name="referentiel.personnel.view" :params="{id: personnel.id}"  class="badge badge-dark badge-shadow min-width-32px px-1" :style="'background: '+personnel.couleur+';'">{{ personnel.code }}</route>
            <span v-else class="badge badge-dark badge-shadow min-width-32px px-1" :style="'background: '+personnel.couleur+';'">{{ personnel.code }}</span>
            <span class="ml-2 text-ellipsis flex-1" :title="personnel.text">{{ personnel.text }}</span>
            <small class="d-flex" v-if="personnel.indicateurs.length">
                <span v-for="(indicateur, i) in personnel.indicateurs" :title="indicateur.libelle" class="line-height-1 px-2 badge badge-pill ml-1" :style="{ 'border-radius': '6px', 'color': '#'+indicateur.couleurTexte, 'background-color': '#'+indicateur.couleur }">
                    {{ indicateur.abreviation || indicateur.libelle }}
                </span>
            </small>
        </div>
    </div>
</template>

<script>
export default {
    props: ['personnel', 'roleView', 'estPremierPersonnelInactif']
}
</script>