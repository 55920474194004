<template>
    <Custom>
        <ButtonGroup vertical>
            <Button @click="zoomIn" :disabled="isMax">
                <i class="fa-solid fa-plus fa-fw"></i>
            </Button>
            <Button @click="zoomOut" :disabled="isMin">
                <i class="fa-solid fa-minus fa-fw"></i>
            </Button>
        </ButtonGroup>
    </Custom>
</template>

<script setup>
import Custom from '@/Vue/Components/RegulationV2/Map/Base/Control/Custom'
import ButtonGroup from '@/Vue/Components/RegulationV2/Map/Base/Control/Button/Group'
import Button from '@/Vue/Components/RegulationV2/Map/Base/Control/Button/Button'
import {inject, ref} from 'vue';

const map = inject('map');
const zoomIn = (event) => {
    map.value.zoomIn({}, {originalEvent: event});
}
const zoomOut = (event) => {
    map.value.zoomOut({}, {originalEvent: event});
}

const isMin = ref(false);
const isMax = ref(false);
const updateZoom = () => {
    const zoom = map.value.getZoom();
    isMax.value = zoom === map.value.getMaxZoom();
    isMin.value = zoom === map.value.getMinZoom();
}
map.value.on('zoom', updateZoom);

updateZoom();
</script>