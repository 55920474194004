<template>
    <span v-if="trajet.transport.estTpmr">
        <i class="im-accessibility2" :class="categorie ? categorie.classeCouleur : ''" :title="(categorie ? categorie + ' - ' : '') + Translator.trans('libelle.estTpmr')"></i>
        <template v-if="full">{{ (categorie ? categorie.libelle + ' - ' : '') + Translator.trans('libelle.estTpmr') }}</template>
    </span>
    <span v-else-if="categorie">
        <i :class="[categorie.classeIcone, categorie.classeCouleur]" :title="categorie.libelle"></i>
        <template v-if="full">{{ categorie.libelle }}</template>
    </span>
    <span v-else>
        <i class="im-question6 text-dark" :title="Translator.trans('libelle.categorieInconnue')"></i>
        <template v-if="full">{{ Translator.trans('libelle.categorieInconnue') }}</template>
    </span>
</template>

<script setup>
import Translator from "@/App/Translator";
import {computed, toRefs} from "vue";

const props = defineProps({
    trajet: Object,
    full: Boolean
})
const {trajet} = toRefs(props);
const categorie = computed(() => trajet.value.transport.categorie);
</script>
