<template>
    <MapPin variant="gray" shape="square"
            :clickable="true"
            :focus="isActive"
            :pulse="isActive"
            v-model:popup="popup"
    >
        <template #content>
            <Badge :value="item.number" :severity="precisions[item.precision].severity"/>
        </template>
        <MapPopup>
            <Popup width="275px">
                <template #headerLeft>
                    Recherche
                </template>
                <template #footerRight>
                    <div class="px-1 flex items-center justify-end">
                        <Button icon="fa-solid fa-route" title="Itinéraire" severity="secondary" size="small" text @click="(event) => itineraireMenu.toggle(event)"/>
                        <TieredMenu ref="itineraireMenu" :model="itineraireMenuItems" popup></TieredMenu>
                    </div>
                </template>

                <div class="p-2">
                    <PopupGroup :loading="null === result">
                        <PopupItem label="Adresse">
                            {{ item.name }}
                        </PopupItem>
                        <PopupItem label="Ville">
                            {{ item.city }}
                        </PopupItem>
                        <PopupItem label="Code postal">
                            {{ item.postcode }}
                        </PopupItem>
                        <PopupItem label="Département">
                            {{ item.departement }}
                        </PopupItem>
                    </PopupGroup>
                </div>
            </Popup>
        </MapPopup>
    </MapPin>
</template>

<script setup>
import MapPin from "@/Vue/Components/RegulationV2/Map/Base/Pin";
import {useGeolocalisationStore} from "@/Vue/Stores/RegulationV2/Geolocalisation";
import {storeToRefs} from "pinia";
import {toRefs, computed, ref} from 'vue'
import MapPopup from "@/Vue/Components/RegulationV2/Map/Base/Popup";
import Popup from "@/Vue/Components/RegulationV2/Layout/Popup";
import Badge from "primevue/badge";
import Button from "primevue/button";
import TieredMenu from "primevue/tieredmenu";
import PopupGroup from "@/Vue/Components/RegulationV2/Layout/Popup/Group.vue";
import PopupItem from "@/Vue/Components/RegulationV2/Layout/Popup/Item.vue";

const props = defineProps({
    index: Number,
    item: Object,
});

const {item} = toRefs(props);

const itineraireMenu = ref();
const itineraireMenuItems = [
    {label: 'Itinéraire', disabled: true},
    {separator: true},
    {label: 'Depuis ce lieu', icon: 'fa-solid fa-plane-departure'},
    {label: 'Vers ce lieu', icon: 'fa-solid fa-plane-arrival'},
];

const geolocalisationStore = useGeolocalisationStore();
const {searchActiveResult} = storeToRefs(geolocalisationStore);
const {precisions} = geolocalisationStore;

const isActive = computed(() => searchActiveResult.value === item.value);
const popup = computed({
    get: () => isActive.value,
    set: (popupBool) => {
        if (popupBool || popup.value) {
            searchActiveResult.value = popupBool ? item.value : null
        }
    },
});
</script>