<template>
    <tr>
        <td class="p-1 font-medium text-nowrap align-top w-px">
            {{ label }}
        </td>
        <td>
            <Skeleton v-if="parentLoading || loading" height="1.375rem"></Skeleton>
            <div v-else class="bg-white dark:bg-gray-900 rounded-md py-1 px-2 flex-1 font-semibold">
                <slot></slot>
            </div>
        </td>
    </tr>
</template>

<script setup>
import Skeleton from "primevue/skeleton";
import {inject} from "vue";

const parentLoading = inject('loading');

const props = defineProps({
    label: String,
    loading: Boolean,
});
</script>