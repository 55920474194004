import Bloodhound from 'bloodhound-js'

App.Vehicule = class {
    constructor(params) {
        this.params = params;

        this._initTypehead();
    }

    _initTypehead() {
        $('.js-vehicule-typehead:not(.js-vehicule-typehead-active)').addClass('js-vehicule-typehead-active').each((index, element) => {
            let $element = $(element);

            $element.typeahead(
                {
                    minLength: 2,
                    highlight: true,
                    hint: false,
                    tabAutocomplete: false,
                },
                {
                    source: new Bloodhound({
                        datumTokenizer: Bloodhound.tokenizers.whitespace,
                        queryTokenizer: Bloodhound.tokenizers.whitespace,
                        remote: {
                            url: $element.data('url'),
                            wildcard: '_QUERY_',
                            prepare: function (query, settings) {
                                const urlAlt = $element.data('url-alt');
                                const marque = $('#' + $element.data('field-value')).val();

                                if (marque) {
                                    settings.url = $element.data('url').replace('_QUERY_', encodeURIComponent(query)).replace('_MARQUE_', encodeURIComponent(marque));
                                } else {
                                    settings.url = $element.data(urlAlt ? 'url-alt' : 'url').replace('_QUERY_', encodeURIComponent(query));
                                }

                                return settings;
                            }
                        }
                    }),
                    limit: 400,
                    templates: {
                        pending: () => {
                            return '<div class="tt-suggestion text-muted">Recherche en cours…</div>';
                        },
                        notFound: '<div></div>',
                        suggestion: (context) => {
                            if (context.marque) {
                                return '<div>' +
                                    '<div>' +
                                    '<small>' + context.marque + '</small>' +
                                    '<div class="float-right">' + (context.debut ? context.debut + ' - ' + (context.fin ? context.fin : this.params.aujourdhui) : '') + '</div>' +
                                    '</div>' +
                                    '<div>' + context.libelle + '</div>' +
                                    '</div>';
                            }

                            return '<div>' + context.libelle + '</div>';
                        }
                    },
                    displayKey: 'libelle'
                });
        });
    }
};