'use strict';

App.Shared.Doublon = class {};

App.Shared.Doublon.Form = class {
    constructor() {
        this._initBtnCancel();
        this._initBtnCheckbox();
        this._initBtnEdit();
        this._initBtnRadio();

        $('#form').submit(() => {
            $('#form').addClass('slideOutRight');
        })
    }

    _initBtnCancel() {
        let that = this;

        $('.js-doublon-btn-cancel').not('.js-doublon-btn-cancel-active').addClass('js-doublon-btn-cancel-active').each(function () {
            let btn = $(this);
            let zone = btn.closest('.js-doublon-zone');
            let btnRadioCollection = zone.find('.js-doublon-btn-radio');

            if (btnRadioCollection.length) {
                let hasBothValueAndDifferent = null;

                btnRadioCollection.each(function () {
                    let valueText = $(this).parent().text().trim();
                    let valueId = $(this).data('value');
                    let value = valueId ? valueId : valueText;

                    hasBothValueAndDifferent = null === hasBothValueAndDifferent ? value : '' !== hasBothValueAndDifferent && '' !== value && hasBothValueAndDifferent !== value;
                });

                if (true === hasBothValueAndDifferent) {
                    btn.prop('disabled', false).click((e) => {
                        let zone = $(e.currentTarget).closest('.js-doublon-zone');
                        let btn = zone.find('.js-doublon-btn-radio:checked');
                        that._setValue(zone, btn.parent().text().trim(), btn.data('value'));

                        zone.find('.js-doublon-zone-radio').show();
                        zone.find('.js-doublon-zone-input').hide();
                    });
                }
            } else {
                btn.prop('disabled', false).click((e) => {
                    let zone = $(e.currentTarget).closest('.js-doublon-zone');

                    zone.find('.js-doublon-zone-checkbox, .js-doublon-zone-radio').show();
                    zone.find('.js-doublon-zone-input').hide();
                    zone.find('.js-doublon-btn-checkbox:first').change();
                    zone.find('.js-doublon-btn-radio2:checked').click();
                });
            }
        });
    }

    _initBtnEdit() {
        $('.js-doublon-btn-edit').not('.js-doublon-btn-edit-active').prop('disabled', false).addClass('js-doublon-btn-edit-active').click((e) => {
            let btn = $(e.currentTarget);
            let zone = btn.closest('.js-doublon-zone');
            zone.find('.js-doublon-zone-radio, .js-doublon-zone-checkbox').hide();
            zone.find('.js-doublon-zone-input').show();

            if (btn.hasClass('js-doublon-btn-edit-adresse')) {
                $('#patient_doublon_codeInsee').val('');
                $('#patient_doublon_latitudeDegre').val('');
                $('#patient_doublon_longitudeDegre').val('');
                $('#patient_doublon_precision').val('');
            }
        });
    }

    _initBtnRadio() {
        $('.js-doublon-btn-radio').not('.js-doublon-btn-radio-active').prop('disabled', false).addClass('js-doublon-btn-radio-active').click((e) => {
            let btn = $(e.currentTarget);
            this._setValue(btn.closest('.js-doublon-zone'), btn.parent().text().trim(), btn.data('value'));
        });

        $('.js-doublon-btn-radio2').not('.js-doublon-btn-radio2-active').prop('disabled', false).addClass('js-doublon-btn-radio2-active').click((e) => {
            let btn = $(e.currentTarget);

            $('#patient_doublon_adresse').val(btn.data('adresse'));
            $('#patient_doublon_codeInsee').val(btn.data('codeInsee'));
            $('#patient_doublon_codePostal').val(btn.data('codePostal'));
            $('#patient_doublon_latitudeDegre').val(btn.data('latitudeDegre'));
            $('#patient_doublon_longitudeDegre').val(btn.data('longitudeDegre'));
            $('#patient_doublon_precision').val(btn.data('precision'));
            $('#patient_doublon_ville').val(btn.data('ville'));
        });
    }

    _setValue(zone, valueText, valueId) {
        let zoneInput = zone.find('.js-doublon-zone-input');

        if (valueId) {
            zoneInput.find('input[type="radio"][value="' + valueId + '"]').prop('checked', true).click();
            zoneInput.find('select').not('.js-select2-autocomplete').val(valueId).change();
            zoneInput.find('select.js-select2-autocomplete').empty().append('<option value="' + valueId + '">' + valueText + '</option>').val(valueId).change();
        } else {
            zoneInput.find('input[type="text"], textarea').val(valueText).change();
        }
    }

    _initBtnCheckbox() {
        let that = this;

        $('.js-doublon-btn-checkbox').not('.js-doublon-btn-checkbox-active').addClass('js-doublon-btn-checkbox-active').change((e) => {
            let btn = $(e.currentTarget);
            let zoneCheckbox = btn.closest('.js-doublon-zone-checkbox');
            let checked = zoneCheckbox.find(':checked');

            if (checked.length > 2) {
                btn.prop('checked', false);
                btn.parent().removeClass('active');
                return;
            }

            for (let i of [0, 1]) {
                $('#patient_doublon_telephoneCollection_' + i + '_ordre').val(i + 1);
                $('#patient_doublon_telephoneCollection_' + i + '_libelle').val('');
                $('#patient_doublon_telephoneCollection_' + i + '_telephoneSms_telephone').val('');
                $('#patient_doublon_telephoneCollection_' + i + '_telephoneSms_envoyerSms').prop('checked', false);
            }

            checked.each(function (i) {
                $('#patient_doublon_telephoneCollection_' + i + '_ordre').val(i + 1);
                $('#patient_doublon_telephoneCollection_' + i + '_libelle').val($(this).data('libelle'));
                $('#patient_doublon_telephoneCollection_' + i + '_telephoneSms_telephone').val($(this).data('telephone'));
                $('#patient_doublon_telephoneCollection_' + i + '_telephoneSms_envoyerSms').prop('checked', 'true' === $(this).data('envoyer-sms'));
            });

            that._evalDisabledBtnCheckbox();
        });

        that._evalDisabledBtnCheckbox();
    }

    _evalDisabledBtnCheckbox() {
        $('.js-doublon-btn-checkbox').each(function () {
            let btn = $(this);
            let disabled = !(btn.is(':checked') || btn.closest('.js-doublon-zone-checkbox').find(':checked').length < 2);
            btn.prop('disabled', disabled).parent().css('cursor', disabled ? 'not-allowed' : 'pointer');
        });
    }
};
