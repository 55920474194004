<template>
    <span v-if="timer !== null" :class="variant !== null ? ['text-'+variant, {'success': 'text-green-500', 'info': 'text-blue-500', 'warning': 'text-orange-500', 'danger': 'text-red-500', 'secondary': 'text-gray-500'}[variant]] : []">
        {{ timer }} <slot></slot>
    </span>
</template>

<script>
    import moment from 'moment';

    export default {
        props: ['value', 'type'],
        data() {
            return {
                timer: null,
                variant: null,
            };
        },
        mounted() {
            setInterval(this.updateTimer, 1000);
            this.updateTimer();
        },
        computed: {
            date() {
                if (this.value) {
                    if (this.value instanceof moment) {
                        return this.value;
                    }

                    const str = typeof this.value === 'object' ? this.value.date + ' ' + this.value.time : this.value;
                    const date = moment(str, 'DD/MM/YYYY HH:mm');

                    if (date.isValid()) {
                        return date;
                    }
                }

                return null;
            },
            inverse() {
                return ['disponible', 'pause'].includes(this.type);
            }
        },
        methods: {
            updateTimer() {
                if(this.date) {
                    const diff = this.date.diff(moment());
                    const duration = moment.duration(diff).abs();
                    this.timer = (!this.inverse && diff < 0 && Math.floor(duration.asMinutes()) > 0 ? '-':'') +  (''+Math.floor(duration.asHours())).padStart(2, '0')+':' + (''+Math.floor(duration.minutes())).padStart(2, '0');
                    if(this.type === 'disponible') {
                        this.variant = duration.asMinutes() <= 15 ? 'info' : (duration.asMinutes() <= 30 ? 'warning' : 'danger');
                    } else if(this.type === 'affecte') {
                        this.variant = diff < 0 ? 'danger' : (duration.asMinutes() <= 15 ? 'warning' : (duration.asHours() <= 1 ? 'info' : null));
                    }
                } else {
                    this.timer = null;
                    this.variant = null;
                }
            }
        }
    }
</script>
