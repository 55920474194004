'use strict';

App.Xroute = class {
    constructor(parent) {
        this.parent = parent;
        this.xhr = null;
        this.lastRecherche = null;
        this.currentManeouvres = null;
    }

    enable() {
        // Dynamic

        $('body')
            .on('click', '.js-xroute-trajet', (e) => {
                let $el = $(e.currentTarget);
                let itineraire = this.parent.getItineraire($el.data('itineraire'));

                if($(e.target).hasClass('js-xroute-trajet-manoeuvres')) {
                    itineraire.showManoeuvres();
                    this.currentManeouvres = itineraire;
                } else if($(e.target).hasClass('js-xroute-trajet-retour')) {
                    let params = $.extend(true, {}, itineraire.params);

                    params.depart = itineraire.params.arrivee;
                    params.arrivee = itineraire.params.depart;

                    this._calculateRoute(params);
                } else if($(e.target).hasClass('js-xroute-trajet-remove')) {
                    $el.remove();
                    this.parent.removeItineraire(itineraire.id);
                } else {
                    $('.js-xroute-trajet.active').removeClass('active');
                    $el.addClass('active');
                    this.parent.xmap.showItineraire(itineraire);

                    if($('body').hasClass('has-right-panel')) {
                        itineraire.showManoeuvres();
                        this.currentManeouvres = itineraire;
                    }
                }
            })
            .on('click', '.js-xroute-depuis', (e) => {
                this.parent.xmap.closePopup();
                $('[href="#itineraire"]').click();
                App.Layout.openLeftSide();

                let point = this.parent.getPoint($(e.currentTarget).data('point'));
                this.parent.attributePoint($('[data-role="depart"]'), point);
            })
            .on('click', '.js-xroute-vers', (e) => {
                this.parent.xmap.closePopup();
                $('[href="#itineraire"]').click();
                App.Layout.openLeftSide();

                let point = this.parent.getPoint($(e.currentTarget).data('point'));
                this.parent.attributePoint($('[data-role="arrivee"]'), point);
            })
        ;

        // Fix

        $('#infotraffic_active').on('click', (e) => {
            let checked = $(e.currentTarget).is(':checked');
            let $parent = $('#infotraffic_bouchons').parent();

            if(!checked) {
                $('#infotraffic_bouchons').prop('disabled', true).prop('checked', false);
                $parent.prop('title', $parent.data('title'));
            } else {
                $('#infotraffic_bouchons').prop('disabled', false);
                $parent.prop('title', '');
            }
        });

        $('.js-xroute').not('.js-xroute-active').addClass('js-xroute-active').on('click', () => {
            let $depart = $('[data-role="depart"]');
            let $arrivee = $('[data-role="arrivee"]');

            let params = {
                'depart': this.parent.getPoint($depart.data('point')),
                'arrivee': this.parent.getPoint($arrivee.data('point')),
                'optimization': $('[name^="optimization"]:checked').val(),
                'infotraffic_active': $('#infotraffic_active').is(':checked'),
                'infotraffic_bouchons': $('#infotraffic_bouchons').is(':checked'),
                'avoid_toll': $('#avoid_toll').is(':checked'),
                'avoid_highway': $('#avoid_highway').is(':checked')
            };

            if(!params['depart']) {
                this.parent.invalidPoint($depart);
            }

            if(!params['arrivee']) {
                this.parent.invalidPoint($arrivee);
            }

            let delta = true;
            if(params['depart'] && params['arrivee'] && params['depart'].lat === params['arrivee'].lat && params['depart'].lng === params['arrivee'].lng){
               this.parent.invalidPoint($arrivee, GEOLOC_CONST.libelles['delta-nul']);
               delta = false;
            }

            if(params['depart'] && params['arrivee'] && delta && JSON.stringify(this.lastRecherche) !== JSON.stringify(params)) {
                this._calculateRoute(params);
                this.lastRecherche = params;
            }
        });
    }

    _calculateRoute(params) {
        if (this.xhr) {
            this.xhr.abort();
        }

        this.xhr = $.ajax({
            dataType: 'json',
            url: App.Constants.XROUTE_URL + '/xroute/rs/XRoute/calculateRoute',
            data: JSON.stringify({
                waypoints: App.Xroute.getWaypoints(params),
                options: App.Xroute.getOptions(params),
                exceptionPaths: null,
                details: App.Xroute.getDetails(),
                callerContext : App.Xroute.getCallerContext(params)
            }),
            headers: { 'Content-Type' : 'application/json' },
            method: 'POST',
            cache: false
        }).done((data) => {
            let itineraire = this.parent.createItineraire({
                'distance': data.info.distance,
                'duree': data.info.time,
                'manoeuvres': data.manoeuvres,
                'trace': data.polygon ? data.polygon.lineString.points : null,
                'params': params
            });

            this.parent.xmap.showItineraire(itineraire);
            itineraire.showResumeTrajet();
        });
    }

    static getWaypoints(params) {
        return [
            {
                linkType: 'NEXT_SEGMENT',
                coords: [{
                    point: {
                        x: params['depart'].lng,
                        y: params['depart'].lat
                    }
                }]
            },
            {
                linkType: 'NEXT_SEGMENT',
                coords: [{
                    point: {
                        x: params['arrivee'].lng,
                        y: params['arrivee'].lat
                    }
                }]
            }
        ];
    }

    static getOptions(params) {
        return [
            {
                parameter: 'ROUTE_LANGUAGE',
                value: 'FR'
            },
            {
                parameter: 'OPTIMIZATION',
                value: (params['optimization'] ? params['optimization'] : 90)
            },
            {
                parameter: 'AVOID_TOLLROADS',
                value: (params['avoid_toll'] ? 'BAN' : 0)
            },
            {
                parameter: 'AVOID_HIGHWAYS',
                value: (params['avoid_highway'] ? 'BAN' : 0)
            }
        ];
    }

    static getDetails() {
        return {
            manoeuvres: true,
            polygon: true,
            detailLevel: 'STANDARD',
            dynamicInfo: false,
            tollManoeuvres: true,
            speedLimits: true,
            segments: true,
            urbanManoeuvres: true,
            featureDescriptions: true
        };
    }

    static getCallerContext(params) {
        return {
            properties: [
                {
                    key: 'Profile',
                    value: 'carfast'
                },
                {
                    key: 'ProfileXMLSnippet',
                    value: App.Xroute.getProfile(params)
                },
                {
                    key: 'CoordFormat',
                    value: 'OG_GEODECIMAL'
                }
            ]
        };
    }

    static getProfile(params) {
        return '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>' +
        '<Profile>' +
            '<FeatureLayer majorVersion="1" minorVersion="0">' +
                '<GlobalSettings enableTimeDependency="' + params['infotraffic_active'] + '"/>' +
                '<Themes>' +
                    '<Theme id="PTV_TrafficIncidents" enabled="true">' +
                        '<FeatureDescription includeTimeDomain="true" includeLanguageCode="true">' +
                            '<Property id="*" included="true"/>	' +
                            '<Property id="message" included="true"/>' +
                        '</FeatureDescription>' +
                    '</Theme>' +
                '</Themes>' +
            '</FeatureLayer>' +
            '<Routing majorVersion="2" minorVersion="0">' +
                '<Course>' +
                    '<AdditionalDataRules enabled="true"/>' +
                    (params['infotraffic_active'] ? '<DynamicRouting dynamicTimeOnStaticRoute="' + !params['infotraffic_bouchons'] + '"/>' : '') +
                '</Course>' +
            '</Routing>' +
        '</Profile>';
    }
};



