'use strict';

App.Xlocate = class {
    constructor(parent) {
        this.parent = parent;
    }

    enable() {
        $('.js-xlocate').not('.js-xlocate-active').addClass('js-xlocate-active').each((index, element) => {
            let $element = $(element);
            let timeout;

            $element.val('').closest('.input-group').find('i').on('click', () => {
                if($element.data('point') && $element.data('point') !== '') {
                    let point = this.parent.getPoint($element.data('point'));
                    this.parent.xmap.centerPoint(point);
                }
            });
            
            $element.on('keyup', (e) => {
                // 9 => Tab // 13 => Entrée // 37 => Gauche // 38 => Haut // 39 => Droite // 40 => Bas
                if($.inArray(e.keyCode, [9, 13, 37, 38, 39, 40]) === -1) { 
                    this.parent.discardPoint($element);
                    
                    if($element.data('role') === 'recherche') {
                        this.parent.xmap.clearLayer('recherche');
                    }
                }
            });

            $element.typeahead({
                minLength: 3,
                highlight: true,
                hint: false,
                tabAutocomplete: false,
            },{
                source: (query, syncResults, asyncResults) => {
                    if (timeout) {
                        clearTimeout(timeout);
                    }

                    timeout = setTimeout(() => {
                        $.ajax({
                            dataType: 'json',
                            url: App.Constants.XLOCATE_URL + '/xlocate/rs/XLocate/findAddressByText',
                            data: JSON.stringify({
                                address: query,
                                country: 'F',
                                options: [],
                                sorting: [],
                                callerContext: {
                                  properties: [{
                                    key:"Profile",
                                    value:"default"
                                  },{
                                    key:"CoordFormat",
                                    value:"OG_GEODECIMAL"
                                  }]
                                },
                                additionalFields: []
                            }),
                            headers: { 'Content-Type' : 'application/json' },
                            method: 'POST',
                            cache: false,
                            success: function (data) {
                                return asyncResults(data.resultList);
                            }
                        });
                    }, 300);

                    return true;
                },
                limit: 30,
                templates: {
                    suggestion: (item) => {
                        let html = '';

                        if (item.street !== '') {
                            html += '<div>' + (item.houseNumber !== '' ? item.houseNumber + ' ' : '') + item.street + '</div>';
                        } else {
                            html += '<div>' + item.city + (item.city2 !== '' ? ', ' + item.city2 : '') + '</div>';
                        }

                        html += '<small>';
                            html += (item.street !== '' ? item.city + ', ' : '') + item.state;
                            html += '<span class="float-right">' + item.postCode + '</span>';
                        html += '</small>';

                        return '<div>' + html + '</div>';
                    }
                },
                display: (item) => {
                    return (item.street !== '' ? (item.houseNumber !== '' ? item.houseNumber + ' ' : '') + item.street + ', ' : '') + item.city + (item.city2 !== '' ? ', ' + item.city2 : '');
                }
            }).bind('typeahead:select', (e, item) => {
                let point = this.parent.createPoint({
                    'lat': item.coordinates.point.y,
                    'lng': item.coordinates.point.x,
                    'adresse': (item.houseNumber !== '' ? item.houseNumber + ' ' : '') + item.street,
                    'ville': item.city + (item.city2 !== '' ? ', ' + item.city2 : ''),
                    'codePostal': item.postCode,
                    'type': $element.data('role')
                });

                this.parent.attributePoint($element, point);

                if($element.data('role') === 'recherche') {
                    this.parent.xmap.showPoint('recherche', point);
                    this.parent.xmap.centerPoint(point);
                }
            });
        });
    }
    
    reverseGeocode(point, $element) {
        $.ajax({
            dataType: 'json',
            url: App.Constants.XLOCATE_URL + '/xlocate/rs/XLocate/findLocation',
            data: JSON.stringify({
                location: {
                    coordinate: {
                        point: {
                            x: point.lng,
                            y: point.lat
                        }
                    }
                },
                /*options: {
                    'ENGINE_TOLERANCE': {
                        value: '100'
                    },
                    'ENGINE_TARGETSIZE': {
                        value: '250'
                    },
                    'ENGINE_SEARCHRANGE': {
                        value: '1000'
                    },
                    'ENGINE_FILTERMODE': {
                        value: '2'
                    }
                },*/
                options: [],
                sorting: [],
                callerContext: {
                    properties: [{
                        key:"Profile",
                        value:"default"
                    },{
                        key:"CoordFormat",
                        value:"OG_GEODECIMAL"
                    }]
                },
                additionalFields: []
            }),
            headers: { 'Content-Type' : 'application/json' },
            method: 'POST',
            cache: false,
            success: (data) => {
                if(data.resultList[0]) {
                    let address = data.resultList[0].street + ', ' + data.resultList[0].postCode + ' ' + data.resultList[0].city;
                    let block = $element.find('.js-geoloc-vehicule-adresse');
                    if(block.length) {
                        block.first().html('<i class="im-location mr-2" title="' + point.horodatage + '"></i>' + address);
                        App.Layout.updateShuffle();
                    }
                }
            }
        });
    }
};


