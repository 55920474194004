<template>
    <div class="bg-gray-100 dark:bg-gray-800 rounded-lg leading-none">
        <table class="border-separate border-spacing-1 w-full">
            <slot></slot>
        </table>
    </div>
</template>

<script setup>
import {toRefs, provide} from "vue";

const props = defineProps({
    loading: Boolean,
});
const {loading} = toRefs(props);

provide('loading', loading);
provide('fromGroup', true);
</script>