<template>
    <div class="cps-menu" :class="{'open': open}" v-if="carteDefaut !== null">
        <cps-mini-card ref="btn" :card="carteDefaut" :btn="true" @click="toggle"></cps-mini-card>
        <transition name="cps-dropdown">
            <div class="cps-dropdown" v-if="open" ref="dropdown">
                <div class="d-flex justify-content-between align-items-center py-2 px-3">
                    <h3>Mes cartes CPS</h3>
                    <div>
                        <span class="badge badge-secondary">{{ cpsStore.cartes.length }}</span>
                    </div>
                </div>
                <div>
                    <div v-for="carte in cartes" class="cps-dropdown-item">
                        <div class="px-3" @click="selectCard(carte)">
                            <span class="fa-stack">
                              <i class="fa-solid fa-circle fa-stack-2x" :class="{'text-secondary': carte !== carteDefaut, 'text-success': carte === carteDefaut}"></i>
                              <i class="fa-solid fa-spin fa-arrows-rotate fa-stack-1x text-white" v-if="carte === cardLoading"></i>
                              <i class="fa-solid fa-check fa-stack-1x text-white" v-else-if="carte === carteDefaut"></i>
                            </span>
                        </div>
                        <cps-mini-card @click="selectCard(carte)" :class="{'cursor-pointer': carte !== carteDefaut}" :title="carte !== carteDefaut ? 'Sélectionner cette carte' : ''" :card="carte" :active="carte === carteDefaut"></cps-mini-card>
                        <div class="px-3 d-flex">
                            <template v-if="hasOnlineCard">
                                <button title="Verrouiller la carte" type="button" class="btn btn-warning mr-2 px-3" :disabled="cardLoading === carte" @click="lockCard(carte)" v-if="canLockCard(carte)">
                                    <i class="fa-solid fa-lock"></i>
                                </button>
                                <button type="button" class="btn btn-outline-secondary mr-2 px-3" v-else disabled>
                                    <i class="fa-solid fa-lock"></i>
                                </button>
                            </template>
                            <b-dropdown right :title="Translator.trans('libelle.actions')" variant="secondary" :disabled="cardLoading === carte">
                                <template #button-content>
                                    <i class="fa-solid fa-ellipsis-vertical"></i>
                                </template>
                                <b-dropdown-item href="#" @click="lockCard(carte)" :link-class="{'disabled': !canLockCard(carte)}"><i class="fa-solid fa-fw fa-lock"></i> Verrouiller</b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>
                </div>
                <div class="row p-3">
                    <div class="col-6 pr-2">
                        <a :href="Router.generate('mon-profil.view')" class="cps-dropdown-btn py-2 px-3 text-center">
                            <i class="fa-solid fa-2x fa-list"></i>
                            <span>Gérer mes cartes</span>
                        </a>
                    </div>
                    <div class="col-6 pl-2">
                        <a :href="Router.generate('cps.add')" class="cps-dropdown-btn py-2 px-3 text-center">
                            <i class="fa-solid fa-2x fa-plus"></i>
                            <span>Ajouter une carte</span>
                        </a>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script setup>
import { useCpsStore } from '@/Vue/Stores/Cps'
import {ref, toRefs, computed } from 'vue'
import { storeToRefs } from 'pinia'
import Router from '@/App/Router';
import Request from '@/App/Request';
import { onClickOutside } from '@vueuse/core'
import Translator from "@/App/Translator";

const cpsStore = useCpsStore()

const { cartes, carteDefaut, count } = storeToRefs(cpsStore)

const props = defineProps({
    cpsData: Object
})
const {cpsData} = toRefs(props)

const btn = ref();
const dropdown = ref();
onClickOutside(dropdown, () => open.value = false, {ignore: [btn]});

const open = ref(false);
const cardLoading = ref(null);

const toggle = () => open.value = !open.value;

const canLockCard = (card) => card.info && card.info.clientData && !card.info.clientData.locked;

const selectCard = async (card) => {
    if (card === carteDefaut.value) {
        return;
    }

    cardLoading.value = card;
    cpsStore.setCarteDefaut(card).finally(() => cardLoading.value = null);
};

const lockCard = (card) => {
    cardLoading.value = card;
    Request.getJson(Router.generate('cps.lock', {id: card.id})).then(() => cardLoading.value = null)
};

const hasOnlineCard = computed(() => {
    for(const card of cartes.value) {
        if(card.info && card.info.online) {
            return true;
        }
    }

    return false;
});

if (!cpsStore.init) {
    App.webSocket.subscribe('cps', (uri, data) => {
        cpsStore.update(data);
    });

    cpsStore.update(cpsData.value);
}
</script>
