'use strict';

import {createApp} from "vue";
import Map from '@/Vue/Components/Referentiel/Appareil/Map';

App.Referentiel.Appareil = class {
    static initMiniMap() {
        $('.js-appareil-map').each((i, el) => {
            createApp(Map, {
                appareils: JSON.parse(el.dataset.appareils)
            }).use(App.Pinia).mount(el);
        });
    }
};

App.Referentiel.Appareil.View = class {
    constructor(params) {
        if (params.checkSync) {
            App.Referentiel.Sync.checkSync('appareil', params.id ? [params.id] : []);
        }
        App.Referentiel.Appareil.initMiniMap();
    }
};

App.Referentiel.Appareil.Index = class {
    constructor(params) {
        App.Referentiel.Sync.checkSync('appareil', params.ids);
    }
};

App.Referentiel.Appareil.Form = class {
    constructor() {
        this.val = null;
        this.btnElt = $('.js-imei-btn');
        this.inputElt = $('#appareil_imei');
        this.enableImeiInputCheckAndBtnLink();
        this.inputCheck();
    }

    luhnCheck = (number) => {
        number = number.toString()
        let sum = 0;
        let numDigits = number.length;
        let parity = numDigits % 2;

        for (let i = numDigits - 1; i >= 0; --i) {
            let digit = parseInt(number.substring(i, i+1));
            if (parity === (i % 2)) {
                digit <<= 1;

                if (9 < digit) {
                    digit -= 9;
                }
            }

            sum += digit;
        }

        return 0 === (sum % 10);
    };

    enableImeiInputCheckAndBtnLink() {
        this.inputElt.on('input', () => {
            this.inputCheck();
        });

        this.btnElt.click(() => {
            const res = window.open('https://www.imei.info/?imei=' + this.val, '_blank');

            if (res) {
                res.focus();
            }
        });
    }

    inputCheck() {
        this.val = this.inputElt.val();
        this.btnElt.prop('disabled', !((this.val + '').length === 15 && this.luhnCheck(this.val)));
    }
}