<template>
    <regulation-envoi type-libelle="pause" :personnel1="personnel1" :personnel2="personnel2" :vehicule="vehicule" :equipage="equipage" :can-send="canSend" :loading="loading" @send="send">
        <template v-slot:title>Envoyer une pause</template>
        <template v-slot:content>
            <div class="section-title"><span>Type</span></div>
            <div class="p-4">
                <label class="tool-picker__item" v-for="type in filteredModeles" :title="type.libelle">
                    <input type="radio" :value="type" v-model="modele">
                    <span class="tool-picker__item__content" :style="{ 'background-color': '#' + type.couleur }">
                    <span class="tool-picker__item__check">
                        <i class="fa-solid fa-check"></i>
                    </span>
                    <span class="tool-picker__item__label">
                        {{ type.code }}
                    </span>
                </span>
                </label>
            </div>

            <div class="section-title"><span>Lieu</span></div>
            <div class="p-4 d-flex justify-content-center">
                <label class="value-picker__item" v-for="lieu2 in lieux">
                    <input type="radio" :value="lieu2.id" v-model="lieu">
                    <span class="value-picker__item__content">{{ lieu2.libelle }}</span>
                </label>
            </div>

            <template v-if="modele?.estPauseRepas">
                <div class="section-title"><span>Indemnité</span></div>
                <div class="p-4 d-flex justify-content-center">
                    <label class="value-picker__item" v-for="indemnite in ['indemniteRepas', 'indemniteRepasUnique', 'indemniteSpeciale']" :title="Translator.trans('libelle.'+indemnite)">
                        <input type="checkbox" true-value="1" false-value="0" v-model="indemnites[indemnite]">
                        <span class="value-picker__item__content">{{ Translator.trans('libelle.'+indemnite+'Abreviation') }}</span>
                    </label>
                </div>
            </template>
            <div class="section-title"><span>Durée</span></div>
            <div class="p-4 d-flex justify-content-center">
                <div class="input-group width-150px" v-if="customDuree">
                    <input type="text" class="form-control text-center" v-model="duree" ref="customDureeRef">
                    <div class="input-group-append">
                        <span class="input-group-text">min</span>
                    </div>
                </div>
                <template v-else>
                    <label class="value-picker__item" v-for="duree2 in durees">
                        <input type="radio" :value="duree2" v-model="duree">
                        <span class="value-picker__item__content">{{ duree2 }} min</span>
                    </label>
                    <label class="value-picker__item">
                        <input type="radio" @change="customDuree = true">
                        <span class="value-picker__item__content">Autre</span>
                    </label>
                </template>
            </div>

            <div class="section-title"><span>Début</span></div>
            <div class="p-4 d-flex justify-content-center align-items-center">
                <label class="value-picker__item" v-for="(libelle, delai2) in delais">
                    <input type="radio" :value="delai2" v-model="delai">
                    <span class="value-picker__item__content">{{ libelle }}</span>
                </label>
                <div class="mx-3">
                    <i class="fa-solid fa-2x fa-arrow-right"></i>
                </div>
                <div>
                    <time-picker :clockpicker="true" class="width-100px text-center" v-model="debutStr" @input="delai = null"></time-picker>
                </div>
            </div>
        </template>
    </regulation-envoi>
</template>

<script setup>
import Moment from 'moment';
import swal from "sweetalert2";
import {ref, toRefs, computed, watch, nextTick} from "vue";
import Router from '@/App/Router';
import Request from '@/App/Request';
import Translator from "@/App/Translator";

const props = defineProps(['modeles', 'lieux', 'personnel1', 'personnel2', 'vehicule', 'equipage']);
const {modeles, lieux, personnel1, personnel2, vehicule, equipage} = toRefs(props);

const modele = ref(null);
const lieu = ref(null);
const duree = ref('');
const delai = ref(0);
const customDuree = ref(false);
const customDureeRef = ref();
const delais = {0: 'Maintenant', 5: '5 min', 15: '15 min', 30: '30 min'};
const loading = ref(false);
const debutStr = ref('');
const indemnites = ref({});

const filteredModeles = computed(() => {
    return modeles.value.filter((modele) => {
        for(const personnel of [personnel1.value, personnel2.value]) {
            if(personnel && personnel.structureRacine !== modele.structure) {
                return false;
            }
        }

        return true;
    });
});
const durees = computed(() => {
    let durees = [20, 30, 45, 60];

    if(modele.value && modele.value.duree !== null) {
        durees.push(modele.value.duree)
    }

    return [...new Set(durees)].sort((a, b) => a - b);
});
const canSend = computed(() => {
    return null !== lieu.value && null !== modele.value && '' !== duree.value && null !== debut.value;
});
const debut = computed(() => {
    const debut = Moment(debutStr.value, 'HH:mm');

    if (debut.isValid()) {
        return debut;
    }

    return null;
});

watch(modele, () => {
    if(modele.value) {
        if (modele.value.duree !== null) {
            duree.value = modele.value.duree;
        }
        if (modele.value.lieu !== null) {
            lieu.value = modele.value.lieu;
        }
    }
});
watch(delai, () => {
    if (null !== delai.value) {
        const debut = Moment();
        debut.add(delai.value, 'minutes');
        debutStr.value = debut.format('HH:mm');
    }
}, {immediate: true});
watch(customDuree, () => {
    if(customDuree.value) {
        duree.value = '';
        nextTick(() => customDureeRef.value.focus());
    }
});

const send = (appareils) => {
    loading.value = true;

    let newDebut = debut.value;

    if (!newDebut) {
        newDebut = Moment();
        newDebut.add(delai.value, 'minutes');
    }

    const fin = newDebut.clone().add(duree.value, 'minutes');

    let data = {
        appareilCollection: appareils.map(appareil => appareil.id),
        modele: modele.value.id,
        lieu: lieu.value,
        personnel1: personnel1.value ? personnel1.value.id : null,
        personnel2: personnel2.value ? personnel2.value.id : null,
        debutPrevu: newDebut.format('DD/MM/YYYY HH:mm'),
        finPrevu: fin.format('DD/MM/YYYY HH:mm'),
    };

    if (modele.value.estPauseRepas) {
        data = {...data, ...indemnites.value};
    }

    Request.postJson(Router.generate('regulation.envoyer-pause.ajax'), data).then((res) => {
        if(res.success) {
            swal.close();
        } else {
            console.log(res.errors);
            loading.value = false;
        }
    }, () => {
        loading.value = false;
    });

}
</script>
