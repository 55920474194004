<template>
    <div class="d-flex flex-column overflow-hidden" style="height: calc(100vh - 104px);">
        <h3 class="title-heading d-flex justify-content-between align-items-center">
            <span>{{ Translator.trans('regulation.trajet.tabs.messagerie') }}</span>
            <a v-if="messagerie._roles?.ROLE_REGULATION_MESSAGE_LIST" class="text-transform-none btn btn-outline-secondary py-1 px-2 mr-n2" target="_blank" :href="path('shared.message.index', {app: 'regulation'})" :title="Translator.trans('libelle.historique')">
              <i class="mx-1 fa-solid fa-clock-rotate-left"></i>
            </a>
        </h3>
        <messagerie-chat ref="chat" :messages="messages" :hover="true" :destinataires="destinataires" @send="send"></messagerie-chat>
        <div>
            <form-select2 ref="destinatairesInput" multiple placeholder="Tous les TLAs" class="form-control" v-model="destinataires" :required="true" :disabled="sending">
                <optgroup label="Personnels" v-if="Object.keys(allDestinataires.personnels).length">
                    <option v-for="(personnel, id) in allDestinataires.personnels" :value="id" :key="id">{{ personnel.longText }}</option>
                </optgroup>
                <optgroup label="Véhicules" v-if="Object.keys(allDestinataires.vehicules).length">
                    <option v-for="(vehicule, id) in allDestinataires.vehicules" :value="id" :key="id">{{ vehicule.longText }}</option>
                </optgroup>
                <optgroup label="Appareils non affectés" v-if="Object.keys(allDestinataires.appareils).length">
                    <option v-for="(appareil, id) in allDestinataires.appareils" :value="id" :key="id">{{ appareil.text }}</option>
                </optgroup>
            </form-select2>
        </div>
    </div>
</template>

<script setup>
import {computed, onMounted, onUnmounted, ref, toRefs, watch, getCurrentInstance, inject} from 'vue';
import Request from "@/App/Request";
import Router from "@/App/Router";
import Translator from "@/App/Translator";
import {storeToRefs} from "pinia";
import {useRegulationStore} from "@/Vue/Stores/Regulation";

const store = useRegulationStore();
const {messagerie, personnelsById, vehiculesById} = storeToRefs(store);
const {canSendPause, canSendFinService, onMessagerieSend} = store;

const destinatairesInput = ref();
const chat = ref();
const destinataires = ref([]);
const sending = ref(false);

const messages = computed(() => messagerie.value.messages || []);

const allDestinataires = computed(() => {
    let appareils = {};
    let personnels = {};
    let vehicules = {};

    for(const appareil of messagerie.value.appareils || []) {
        if(appareil.personnel) {
            personnels[appareil.id] = appareil.personnel;
        }
        else if(appareil.vehicule) {
            vehicules[appareil.id] = appareil.vehicule;
        }
        else {
            appareils[appareil.id] = appareil;
        }
    }

    return {appareils, personnels, vehicules};
});

const allDestinatairesIds = computed(() => {
    return messagerie.value.appareils.map(appareil => appareil.id);
});

const send = (data) => {
    sending.value = true;

    if (!data.destinataires.length) {
        data.destinataires = allDestinatairesIds.value;
    }

    onMessagerieSend(data, (res) => {
        sending.value = false;
        chat.value.confirm(res.success);
    })
}

const setDestinataires = (appareils) => {
    destinataires.value = appareils.map(appareil => appareil.id);
}

const scrollBottom = () =>  {
    chat.value.scrollBottom();
};

const refresh = () =>  {
    $(destinatairesInput.value.$el).trigger('change.select2');
};

watch(destinataires, () => {
    $(destinatairesInput.value.$el).select2('close');
});

const instance = getCurrentInstance();

const openEnvoiPauseSwal = inject('openEnvoiPauseSwal');
const openEnvoiFinServiceSwal = inject('openEnvoiFinServiceSwal');
const openRessourceDetailSwal = inject('openRessourceDetailSwal');

onMounted(() => {
    $.contextMenu({
        selector: '.js-messagerie-message',
        build: ($trigger, event) => {
            const index = $trigger.parent().parent().attr('data-index');

            const message = messages.value[index];

            if (!message) {
                return false;
            }

            let items = {};

            const personnelEmetteur = message.personnelEmetteur ? personnelsById.value[message.personnelEmetteur.id] : null;
            const personnelDestinataire = message.personnelDestinataire ? personnelsById.value[message.personnelDestinataire.id] : null;
            const personnelDestinataire2 = message.personnelDestinataire2 ? personnelsById.value[message.personnelDestinataire2.id] : null;

            for (const personnel of [personnelEmetteur, personnelDestinataire, personnelDestinataire2]) {
                if (personnel) {
                    const key = 'personnel_'+personnel.id;
                    items[key] = {name: personnel.text, icon: 'fa-user', disabled: true};
                    items[key + 'separator'] = '';
                    items[key + 'detail'] = {name: 'Détail', icon: 'fa-solid fa-eye', callback: () => openRessourceDetailSwal(personnel)};
                    items[key + 'envoyerPause'] = {name: Translator.trans('action.envoyer-pause'), icon: 'fa-solid fa-pause', disabled: !canSendPause(personnel), callback: () =>
                        openEnvoiPauseSwal(personnel)
                    };
                    items[key + 'envoyerFinService'] = {name: Translator.trans('action.envoyer-fin-service'), icon: 'fa-solid fa-stop', disabled: !canSendFinService(personnel), callback: () =>
                        openEnvoiFinServiceSwal(personnel)
                    };
                    items[key + 'separator'] = '';
                }
            }

            const vehiculeEmetteur = message.vehiculeEmetteur ? vehiculesById.value[message.vehiculeEmetteur.id] : null;
            const vehiculeDestinataire = message.vehiculeDestinataire ? vehiculesById.value[message.vehiculeDestinataire.id] : null;
            const vehiculeDestinataire2 = message.vehiculeDestinataire2 ? vehiculesById.value[message.vehiculeDestinataire2.id] : null;

            for (const vehicule of [vehiculeEmetteur, vehiculeDestinataire, vehiculeDestinataire2]) {
                if (vehicule) {
                    const key = 'vehicule_'+vehicule.id;
                    items[key] = {name: vehicule.text, icon: 'fa-car', disabled: true};
                    items[key + 'separator'] = '';
                    items[key + 'detail'] = {
                        name: 'Détail',
                        icon: 'fa-solid fa-eye',
                        callback: () => openRessourceDetailSwal(vehicule)
                    };
                    items[key + 'separator2'] = '';
                }
            }

            items.message = {name: 'Message', icon: 'fa-message', disabled: true};
            items.sep2 = '';

            items.detail = {
                name: 'Détail', icon: 'fa-solid fa-eye', callback: () => {
                    instance.proxy._bv__modal.show('details-modal-'+message.id);
                }
            };

            if (message.appareilEmetteur) {
                if (!message.traiteBy && !message.traiteAt) {
                    items.traiter = {
                        name: 'Marquer comme traité', icon: 'fa-solid fa-square-check', callback: () => {
                            Request.getJson(Router.generate('regulation.trajet.message.traiter', {id: message.id})).then(() => {
                                App.Notification.success('Message marqué comme traité.');
                            });
                        }
                    };
                } else if (message.traiteBy && message.traiteBy.id === App.Constants.USER_ID) {
                    items.annulerTraiter = {
                        name: 'Marquer comme non traité', icon: 'fa-solid fa-square', callback: () => {
                            Request.getJson(Router.generate('regulation.trajet.message.annuler-traite', {id: message.id})).then(() => {
                                App.Notification.success('Message marqué comme non traité.');
                            });
                        }
                    };

                }
                items.repondre = {
                    name: 'Répondre', icon: 'fa-solid fa-reply', callback: () => {
                        setDestinataires([message.appareilEmetteur]);
                    }
                };
            }

            if (message.estLieAmplitudeFinService && null === message.topTlaQuantite) {
                items.annuler = {
                    name: 'Annuler l\'envoi', icon: 'fa-solid fa-xmark', callback: () => {
                        Request.getJson(Router.generate('regulation.annuler-fin-service.ajax', {id: message.id})).then(() => {
                            App.Notification.success('Message automatique annulé.');
                        });
                    }
                };
            }

            return Object.keys(items).length ? {
                items: items
            } : false;
        },
    });
});

onUnmounted(() => {
    $.contextMenu('destroy', '.js-messagerie-message');
});

defineExpose({
    setDestinataires, scrollBottom, refresh
});
</script>
