'use strict';

import {createApp, configureCompat} from "vue";
import Aura from '@/presets/aura';
import Container from "@/Vue/Components/RegulationV2/Container.vue";
import PrimeVue from 'primevue/config';
import DialogService from 'primevue/dialogservice';
import VueChartkick from 'vue-chartkick'
import 'chartkick/chart.js'
import FocusTrap from 'primevue/focustrap';
import {fr} from 'primelocale/fr.json';
import BadgeDirective from 'primevue/badgedirective';

App.RegulationV2 = class {};

App.RegulationV2.Regulation = class {
    constructor(params) {
        configureCompat({
            MODE: 3,
        });

        createApp(Container, {
            initData: params.vueData,
        }).use(App.Pinia).use(PrimeVue, {
            unstyled: true,
            pt: Aura,
            ptOptions: {
                mergeProps: true
            },
            locale: fr,
        }).use(DialogService).use(VueChartkick).directive('focustrap', FocusTrap).directive('badge', BadgeDirective).mount('#app');

        // const API_KEY = 'RVVfMWQwNjQzNDM0YWQzNGY1MjllMjlhMTk0YjMzYmU2ZTg6Yjg0NTA0MzUtNDcwMC00ODE5LWI1MDQtYTdjOWM1YjU4OWVk';
        //
        // const map = new maplibregl.Map({
        //     container: 'map',
        //     style: "https://vectormaps-resources.myptv.com/styles/latest/standard.json",
        //     center: [5.0793781, 47.3104322], // starting position [lng, lat]
        //     antialias: true,
        //     zoom: 5, // starting zoom
        //     transformRequest: (url, resourceType) => {
        //         if (resourceType === 'Tile' && url.startsWith('https://api.myptv.com')) {
        //             const urlObj = new URL(url);
        //             urlObj.searchParams.append('apiKey', API_KEY);
        //
        //             return {
        //                 url: urlObj.toString(),
        //             }
        //         }
        //     },
        // });
        //
        // function _base64ToArrayBuffer(base64) {
        //     var binary_string = window.atob(base64);
        //     var len = binary_string.length;
        //     var bytes = new Uint8Array(len);
        //     for (var i = 0; i < len; i++) {
        //         bytes[i] = binary_string.charCodeAt(i);
        //     }
        //     return bytes.buffer;
        // }
        //
        // maplibregl.addProtocol('ptv', (params, callback) => {
        //     const url = `https://${params.url.split("://")[1]}`;
        //     const urlObj = new URL(url);
        //     urlObj.searchParams.append('apiKey', API_KEY);
        //
        //     console.log(params);
        //     fetch(urlObj.toString())
        //         .then(t => {
        //             if (t.status === 200) {
        //                 t.json().then(data => {
        //                     callback(null, _base64ToArrayBuffer(data.image), null, null);
        //                 });
        //             } else {
        //                 callback(new Error(`Tile fetch error: ${t.statusText}`));
        //             }
        //         })
        //         .catch(e => {
        //             callback(new Error(e));
        //         });
        //     return { cancel: () => { } };
        // });
        //
        // map.on('load', () => {
        //     map.addSource('satellite', {
        //         'type': 'raster',
        //         'tiles': [
        //             'https://api.myptv.com/rastermaps/v1/satellite-tiles/{z}/{x}/{y}'
        //         ],
        //         'tileSize': 256,
        //     });
        //     map.addSource('data', {
        //         'type': 'raster',
        //         'tiles': [
        //             'ptv://api.myptv.com/rastermaps/v1/data-tiles/{z}/{x}/{y}?layers=trafficIncidents'
        //         ],
        //         'tileSize': 256,
        //     });
        //     map.addSource('tomtom', {
        //         "maxzoom": 22,
        //         "minzoom": 0,
        //         "tiles": [
        //             "https://a.api.tomtom.com/traffic/map/4/tile/incidents/{z}/{x}/{y}.pbf?key=RaLZK5ddm1VhAkQhQuuMGvWPjd8NbJEB&tags=[road_category,road_subcategory,icon_category,magnitude,delay,left_hand_traffic]",
        //             "https://b.api.tomtom.com/traffic/map/4/tile/incidents/{z}/{x}/{y}.pbf?key=RaLZK5ddm1VhAkQhQuuMGvWPjd8NbJEB&tags=[road_category,road_subcategory,icon_category,magnitude,delay,left_hand_traffic]",
        //             "https://c.api.tomtom.com/traffic/map/4/tile/incidents/{z}/{x}/{y}.pbf?key=RaLZK5ddm1VhAkQhQuuMGvWPjd8NbJEB&tags=[road_category,road_subcategory,icon_category,magnitude,delay,left_hand_traffic]",
        //             "https://d.api.tomtom.com/traffic/map/4/tile/incidents/{z}/{x}/{y}.pbf?key=RaLZK5ddm1VhAkQhQuuMGvWPjd8NbJEB&tags=[road_category,road_subcategory,icon_category,magnitude,delay,left_hand_traffic]"
        //         ],
        //         "type": "vector"
        //     });
        //     // map.addLayer({
        //     //     'id': 'trafficIncidents',
        //     //     'type': 'vector',
        //     //     'source': 'tomtom',
        //     // })
        //     // map.addLayer({
        //     //     'id': 'trafficIncidents',
        //     //     'type': 'raster',
        //     //     'source': 'data',
        //     // })
        // })
        // map.addControl(new maplibregl.NavigationControl());
        // map.addControl(new MapboxStyleSwitcherControl([
        //     {
        //         title: 'Standard',
        //         uri: 'https://vectormaps-resources.myptv.com/styles/latest/standard.json',
        //     },
        //     {
        //         title: 'Hybride',
        //         uri: 'https://vectormaps-resources.myptv.com/styles/latest/hybrid.json',
        //     },
        //     {
        //         title: 'Monochrome',
        //         uri: 'https://vectormaps-resources.myptv.com/styles/latest/monochrome.json',
        //     },
        //     {
        //         title: 'TomTom Satellite',
        //         uri: 'https://api.tomtom.com/style/1/style/23.1.0-17?map=2/basic_street-satellite&poi=2/poi_satellite&traffic_incidents=2/incidents_light&traffic_flow=2/flow_relative-light&key=RaLZK5ddm1VhAkQhQuuMGvWPjd8NbJEB',
        //     },
        //     {
        //         title: 'TomTom',
        //         uri: 'https://api.tomtom.com/style/1/style/23.1.0-17?map=2/basic_street-light&poi=2/poi_light&traffic_incidents=2/incidents_light&traffic_flow=2/flow_relative-light&key=RaLZK5ddm1VhAkQhQuuMGvWPjd8NbJEB',
        //     },
        //     {
        //         title: 'Test2',
        //         uri: 'https://api.tomtom.com/traffic/map/4/style/*/2/incidents_light.json?key=RaLZK5ddm1VhAkQhQuuMGvWPjd8NbJEB',
        //     },
        // ], {
        //     defaultStyle: 'Standard',
        // }));
    }
}
