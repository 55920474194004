<template>
    <api-popup>
        <template #title>
            <span class="badge mr-3" style="background: #0d46a8">
                <img src="../../../images/logo-pappers-white.png" style="height: 14px;">
            </span> Recherche
        </template>
        <template #content>
            <template v-if="loading">
                <div class="mt-5 d-flex align-items-center justify-content-center">
                    <b-spinner class="mr-3"></b-spinner> Chargement…
                </div>
            </template>
            <template v-else-if="result">
                <div class="alert alert-danger" v-if="result.radiation">
                    <i class="fa-solid fa-exclamation-triangle"></i> Cette entreprise est radiée depuis le {{ result.radiation }}.
                </div>
                <div class="alert alert-secondary" v-if="!result.diffusable">
                    <i class="fa-solid fa-exclamation-triangle"></i> Cette entreprise a exercé son droit à une diffusion partielle de ses données auprès de l'INSEE.
                </div>
                <sefi-result-table ref="table" @canReporter="canReporter = $event">
                    <tr>
                        <th colspan="3" class="bg-light py-1 text-center">{{ Translator.trans('chapitre.informations') }}</th>
                    </tr>
                    <sefi-result-row :label="Translator.trans('libelle.libelle')" :value="result.libelle" :report-visible-only="true" target="#structure_libelle"></sefi-result-row>
                    <sefi-result-row :label="Translator.trans('libelle.code')" :value="result.code" :report-visible-only="true" target="#structure_code"></sefi-result-row>
                    <tr>
                        <th colspan="3" class="bg-light py-1 text-center">{{ Translator.trans('chapitre.informations-juridiques') }}</th>
                    </tr>
                    <sefi-result-row :label="Translator.trans('libelle.siret')" :value="result.siret" :report-visible-only="true" target="#structure_siret"></sefi-result-row>
                    <sefi-result-row :label="Translator.trans('libelle.ape')" :value="result.ape" :report-visible-only="true" target="#structure_ape" :no-report-reason="result.ape === '00.00Z' ? 'Cette valeur est temporaire.' : undefined"></sefi-result-row>
                    <sefi-result-row :label="Translator.trans('libelle.formeJuridique')" :value="result.formeJuridique" :report-visible-only="true" target="#structure_formeJuridique"></sefi-result-row>
                    <sefi-result-row :label="Translator.trans('libelle.activite')" :value="result.activite" :report-visible-only="true" target="#structure_activite" :no-report-reason="result.activite === '00.00Z' ? 'Cette valeur est temporaire.' : undefined"></sefi-result-row>
                    <sefi-result-row :label="Translator.trans('libelle.categorie')" :value="result.categorie" :report-visible-only="true" target="#structure_categorie" :no-report-reason="result.categorie === '00.00Z' ? 'Cette valeur est temporaire.' : undefined"></sefi-result-row>
                    <sefi-result-row :label="Translator.trans('libelle.rcs')" :value="result.rcs" :report-visible-only="true" target="#structure_rcs"></sefi-result-row>
                    <sefi-result-row :label="Translator.trans('libelle.capital')" :value="result.capital" :report-visible-only="true" target="#structure_capital">
                        {{ Euro(result.capital) }}
                    </sefi-result-row>
                    <sefi-result-row :label="Translator.trans('libelle.immatriculation')" :value="result.immatriculation" :report-visible-only="true" target="#structure_immatriculation"></sefi-result-row>
                    <sefi-result-row :label="Translator.trans('libelle.dirigeant')" :value="result.dirigeant" :report-visible-only="true" target="#structure_dirigeant"></sefi-result-row>
                    <sefi-result-row :label="Translator.trans('libelle.gerant')" :value="result.gerant" :report-visible-only="true" target="#structure_gerant"></sefi-result-row>
                    <sefi-result-row :label="Translator.trans('libelle.clotureExercice')" :value="result.clotureExercice" :report-visible-only="true" target="#structure_clotureExercice"></sefi-result-row>
                    <tr>
                        <th colspan="3" class="bg-light py-1 text-center">{{ Translator.trans('chapitre.coordonnees') }}</th>
                    </tr>
                    <sefi-result-row :label="Translator.trans('libelle.adresse')" :value="result.adresse" :report-visible-only="true" target="#structure_adresse"></sefi-result-row>
                    <sefi-result-row :label="Translator.trans('libelle.codePostal')" :value="result.codePostal" :report-visible-only="true" target="#structure_codePostal"></sefi-result-row>
                    <sefi-result-row :label="Translator.trans('libelle.ville')" :value="result.ville" :report-visible-only="true" target="#structure_ville"></sefi-result-row>
                </sefi-result-table>
            </template>
            <template v-else>
                <div v-if="error" class="alert alert-danger mb-5">
                    <strong>Erreur :</strong> {{ error }}
                </div>
                <sefi-form-row v-model="mode" :props="{type: 'radio', choices: {siret: 'SIRET'}}" component="form-btn-group" label="Mode" required></sefi-form-row>
                <sefi-form-row v-if="mode === 'siret'" v-model="inputData.siret" label="SIRET" required></sefi-form-row>
                <sefi-form-row v-if="mode === 'libelle'" v-model="inputData.libelle" label="Libellé" required></sefi-form-row>
            </template>
        </template>
        <template #footer>
            <b-btn variant="primary" @click="search" :disabled="!canSearch" v-if="!loading && !result">
                <i class="fa-solid fa-magnifying-glass fa-fw"></i> Rechercher
            </b-btn>
            <b-btn variant="secondary" @click="result = null" v-if="result">
                <i class="fa-solid fa-chevron-left fa-fw"></i> Retour
            </b-btn>
            <sefi-result-reporter v-if="result" :enabled="canReporter" @click="reporter"></sefi-result-reporter>
        </template>
    </api-popup>
</template>

<script setup>
import {computed, ref, toRefs} from "vue";
import Router from "@/App/Router";
import Request from "@/App/Request";
import Translator from "@/App/Translator";
import Euro from "@/Vue/Filters/Euro";

const props = defineProps({
    siret: String,
    libelle: String,
});

const canReporter = ref(false);

const table = ref();
const mode = ref('siret'); // todo libelle if not siret
const loading = ref(false);
const result = ref(null);
const error = ref(null);
const inputData = ref({
    siret: props.siret,
    libelle: props.libelle,
});

const {siret, libelle} = toRefs(props);

const search = () => {
    error.value = null;
    loading.value = true;

    Request.postJson(Router.generate('structure.pappers'), {
        [mode.value]: inputData.value[mode.value],
    }).then((data) => {
        if(data.success) {
            result.value = data.data;
        } else {
            error.value = data.error;
        }

        loading.value = false;
    });
}

const reporter = () => {
    table.value.reporter();
}

const canSearch = computed(() => {
    if (mode.value === 'siret' && inputData.value.siret) {
        return true;
    }
    if (mode.value === 'libelle' && inputData.value.libelle) {
        return true;
    }

    return false;
});
</script>
