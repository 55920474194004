<template>
    <span>
        <template v-for="html, field in choices">
            <span v-if="has(field)" v-html="typeof html === 'object' ? html.label : html" class="mr-2" :title="typeof html === 'object' ? html.title : ''"></span>
        </template>
    </span>
</template>

<script>
    export default {
        props: ['value', 'choices'],
        methods: {
            has(field) {
                if(Array.isArray(this.value)) {
                    return this.value.includes(field);
                }

                return this.value[field];
            }
        }
    }
</script>