<template>
    <MapGeojson id="ressources" :data="ressourcesGeoJson" @items="visibleRessources = Object.values($event).map(feature => ressourcesByKey[feature.properties.key])" :activeFeature="activeRessource?.geolocalisation.position ? ressourceToFeature(activeRessource) : null">
        <template #items="{features}">
            <template v-for="(feature, key) in features" :key="key" >
                <MapRessourcePin :lng-lat="feature.geometry.coordinates" :ressource="ressourcesByKey[key]" v-if="key in ressourcesByKey"></MapRessourcePin>
            </template>
        </template>
    </MapGeojson>
</template>

<script setup>
import MapRessourcePin from "@/Vue/Components/RegulationV2/Map/Ressource/Pin";
import MapGeojson from "@/Vue/Components/RegulationV2/Map/Base/Geojson";
import {useGeolocalisationStore} from "@/Vue/Stores/RegulationV2/Geolocalisation";
import {storeToRefs} from "pinia";
import {inject, watch} from "vue";

const geolocalisationStore = useGeolocalisationStore();
const {ressourcesGeoJson, activeRessource, visibleRessources, ressourcesByKey} = storeToRefs(geolocalisationStore);
const {ressourceToFeature} = geolocalisationStore;

const map = inject('map');

watch(activeRessource, (newRessource, oldRessource) => {
    if (newRessource && newRessource.key !== oldRessource?.key && newRessource.geolocalisation.position && !visibleRessources.value.includes(newRessource)) {
        map.value.flyTo({
            center: [newRessource.geolocalisation.position.longitudeDegre, newRessource.geolocalisation.position.latitudeDegre],
            zoom: 15,
            curve: 0.5,
            speed: 2,
            maxDuration: 1500,
        });
    }
}, {immediate: true});
</script>