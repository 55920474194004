<template>
    <div class="md:absolute flex-col md:flex-row mt-3 bottom-0 left-0 right-0 flex justify-between items-end md:p-3 pointer-events-none flex-1">
        <div class="pointer-events-auto w-full md:w-auto order-1" :class="{'hidden md:block': detailMode !== null}">
            <Window class="md:w-[350px] ">
                <template #header>
                    <div class="flex justify-between items-center">
                        <div>
                            <span :style="{background: activeSegmentRessource.regulation.couleur}" class="px-1 rounded-md mr-1 text-white">{{ activeSegmentRessource.regulation.shortString }}</span>
                            Historique
                        </div>
                        <div>
                            <i class="fa-solid fa-times cursor-pointer text-surface-600 hover:text-surface-800" @click="close"></i>
                        </div>
                    </div>
                </template>
                <HistoriqueTab ref="historiqueTabRef" class="text-sm" :ressource="activeSegmentRessource"></HistoriqueTab>
            </Window>
        </div>
        <div class="w-full md:w-auto order-3 md:order-2">
            <div class="flex justify-center mb-2 gap-x-2 z-10 relative">
                <div class="rounded-md px-3 text-white bg-indigo-600 gap-x-2 shadow flex items-center tabular-nums text-sm pointer-events-auto">
                    <span class="font-semibold">{{ activeSegmentPosition.horodatage.date }}</span>
                    <span class="font-medium">{{ activeSegmentPosition.horodatage.time }}</span>
                </div>
                <IndicateurGroup class="pointer-events-auto">
                    <Indicateur title="Vitesse" icon="fa-regular fa-gauge-high" :active="detailMode === 'chart' && chartMode === 'vitesse'" @click="toggleDetail('chart', 'vitesse')">
                        <template #side>
                            <VitesseLimite :position="activeSegmentPosition" class="-mr-2 ml-1"></VitesseLimite>
                        </template>
                        <div class="min-w-[50px]">
                            <template v-if="activeSegmentPosition.vitesse !== null">
                                {{ activeSegmentPosition.vitesse }} km/h
                            </template>
                            <template v-else>-</template>
                        </div>
                    </Indicateur>
                    <Indicateur title="Altitude" icon="fa-regular fa-mountains" :active="detailMode === 'chart' && chartMode === 'altitude'" @click="toggleDetail('chart', 'altitude')">
                        <template v-if="activeSegmentPosition.altitude !== null">
                            {{ activeSegmentPosition.altitude }} m
                        </template>
                        <template v-else>-</template>
                    </Indicateur>
                    <Indicateur title="Kilométrage véhicule" icon="fa-regular fa-road" :active="detailMode === 'chart' && chartMode === 'vehicule-kilometrage'" @click="toggleDetail('chart', 'vehicule-kilometrage')" v-if="'vehicule-kilometrage' in evenementByPositionByType">
                        <template v-if="evenement = evenementByPositionByType['vehicule-kilometrage'][activeSegmentPositionIndex]">{{ evenement.valeur }} km</template>
                        <template v-else>-</template>
                    </Indicateur>
                    <Indicateur title="Niveau de carburant" icon="fa-regular fa-gas-pump" :active="detailMode === 'chart' && chartMode === 'vehicule-essence-volume-pourcent'" @click="toggleDetail('chart', 'vehicule-kilometrage')" v-if="'vehicule-essence-volume-pourcent' in evenementByPositionByType || 'vehicule-essence-volume-litre' in evenementByPositionByType">
                        <template v-if="'vehicule-essence-volume-pourcent' in evenementByPositionByType">
                            <template v-if="evenement = evenementByPositionByType['vehicule-essence-volume-pourcent'][activeSegmentPositionIndex]">{{ evenement.valeur }}%</template>
                            <template v-else>-</template>
                        </template>
                        <template v-if="evenement = evenementByPositionByType['vehicule-essence-volume-litre'][activeSegmentPositionIndex]"> ({{ evenement.valeur }} L)</template>
                    </Indicateur>
                </IndicateurGroup>
                <IndicateurGroup class="pointer-events-auto">
                    <Indicateur title="Positions" icon="fa-regular fa-location-dot" :active="detailMode === 'list'" @click="toggleDetail('list')">
                        {{ activeSegment.positionCollection.length }}
                    </Indicateur>
                </IndicateurGroup>
            </div>
            <div class="flex gap-2 pointer-events-auto flex-wrap justify-center">
                <ButtonGroup class="order-1">
                    <Button class="min-h-10" title="Passer à l'élément précédent" icon="fa-solid fa-backward-step" :disabled="!activeSegment.segmentPrecedent || activeSegmentLoading" :loading="segmentPrecedentLoading" @click="precedent"></Button>
                </ButtonGroup>
                <div class="order-0 md:order-2 min-w-full md:min-w-0 flex-1 md:flex-initial md:w-[400px] z-10 backdrop-blur-lg rounded-xl shadow-md py-2 px-3 bg-white bg-opacity-50 dark:bg-black dark:bg-opacity-40 flex items-center gap-x-3">
                    <div>{{ activeSegment.positionDebut.horodatage.time }}</div>
                    <Slider v-model="index" :min="0" :max="100" class="w-full" :pt="{root: '!h-9', handle: '!top-0 !bottom-0 !h-auto !mt-0 !bg-transparent before:!w-2 before:!h-5/6'}"/>
                    <div>{{ activeSegment.positionFin.horodatage.time }}</div>
                </div>
                <ButtonGroup class="order-3">
                    <Button class="min-h-10" title="Passer à l'élément suivant" icon="fa-solid fa-forward-step" :disabled="!activeSegment.segmentSuivant || activeSegmentLoading" :loading="segmentSuivantLoading" @click="suivant"></Button>
                </ButtonGroup>
                <ButtonGroup class="order-4">
                    <Button class="min-h-10" title="Calculer un itinéraire" icon="fa-solid fa-route" :disabled="!activeSegment.enMouvement" :active="activeSegmentShowRoute" :loading="activeSegmentRouteLoading" @click="activeSegmentShowRoute = !activeSegmentShowRoute"></Button>
                    <Button class="min-h-10" title="Fermer" icon="fa-solid fa-times" @click="close"></Button>
                </ButtonGroup>
            </div>
        </div>
        <div class="pointer-events-auto mb-[65px] w-full md:w-[350px] order-2 md:order-3" :class="{'hidden md:block': detailMode === null}">
            <Window v-if="detailMode !== null">
                <template #header>
                    <div class="flex justify-between items-center">
                        <div>
                            <template v-if="detailMode === 'list'">
                                <i class="fa-solid mr-1 fa-list"></i>
                                Positions
                            </template>
                            <template v-else-if="detailMode === 'chart'">
                                <i class="fa-solid mr-1 fa-chart-line"></i>
                                {{ {vitesse: 'Vitesse', altitude: 'Altitude', 'vehicule-essence-volume-pourcent': 'Niveau de carburant', 'vehicule-kilometrage': 'Kilométrage du véhicule'}[chartMode] }}
                            </template>
                        </div>
                        <div>
                            <i class="fa-solid fa-times cursor-pointer text-surface-600 hover:text-surface-800" @click="detailMode = null"></i>
                        </div>
                    </div>
                </template>
                <template v-if="detailMode === 'list'">
                    <DataTable :value="activeSegment.positionCollection"
                               class="text-sm"
                               stripedRows
                               scrollable scrollHeight="flex"
                               paginator
                               :rows="itemNumberPerPage"
                               v-model:first="first"
                               size="small"
                               rowGroupMode="subheader"
                               @row-click="onPositionClick"
                               groupRowsBy="horodatage.date"
                               :expandedRows="{[activeSegmentPosition.id]: true}"
                               sortField="horodatage" :sortOrder="1"
                               dataKey="id"
                               :pt="{root: '!p-0', rowGroupHeaderCell: 'py-1 px-2 bg-gray-100 leading-none', pcPaginator: {root: '!p-0 mt-1', content: 'flex', first: '!h-7 !min-w-7', last: '!h-7 !min-w-7', prev: '!h-7 !min-w-7', next: '!h-7 !min-w-7', page: '!h-7 !min-w-7'}}"
                    >
                        <template #empty>Aucun élément.</template>
                        <template #groupheader="{data}">
                            <div class="font-semibold text-slate-500">
                                {{ data.horodatage.date }}
                            </div>
                        </template>
                        <Column field="horodatage" header="Heure" bodyClass="w-px font-medium text-nowrap tabular-nums" sortable>
                            <template #body="{data}">
                                <div class="flex">
                                    <div class="w-5 text-center">
                                        <i class="fa-solid fa-location-dot text-primary" v-if="data === activeSegmentPosition"></i>
                                    </div>
                                    {{ data.horodatage.time }}
                                </div>
                            </template>
                        </Column>
                        <Column field="vitesse" header="Vitesse" bodyClass="text-nowrap" sortable>
                            <template #body="{data}">
                                <div class="flex justify-end items-center -my-1">
                                    {{ data.vitesse }} km/h
                                    <VitesseLimite class="ml-2" :position="data"></VitesseLimite>
                                </div>
                            </template>
                        </Column>
                        <Column field="altitude" header="Altitude" bodyClass="text-nowrap text-right" sortable>
                            <template #body="{data}">
                                <template v-if="null !== data.altitude">
                                    {{ data.altitude }} m
                                </template>
                            </template>
                        </Column>
                        <Column field="direction" header="Dir." bodyClass="text-center">
                            <template #body="{data}">
                                {{ data.direction }}
                            </template>
                        </Column>
                        <template #expansion="{data}">
                            <div class="-my-2 -mx-1 text-slate-500">
                                <i class="fa-solid fa-arrow-turn-down-right mr-2"></i>{{ data.adresseCompleteUneLigne }}
                            </div>
                        </template>

                        <Column style="display: none;"></Column>
                    </DataTable>
                </template>
                <template v-else-if="detailMode === 'chart'">
                    <area-chart :data="chartData" :legend="false" :library="{scales: {x: {ticks: {display: false}}}}" height="200px" :colors="['#6366f1']"></area-chart>
                </template>
            </Window>
        </div>
    </div>
</template>

<script setup>
import Slider from 'primevue/slider';
import {useGeolocalisationStore} from "@/Vue/Stores/RegulationV2/Geolocalisation";
import {storeToRefs} from "pinia";
import {computed, nextTick, ref, watch} from "vue";
import HistoriqueTab from "@/Vue/Components/RegulationV2/Map/Ressource/Tab/Historique.vue";
import Window from "@/Vue/Components/RegulationV2/Layout/Window";
import ButtonGroup from "@/Vue/Components/RegulationV2/Layout/Button/Group";
import Button from "@/Vue/Components/RegulationV2/Layout/Button/Button";
import Indicateur from "@/Vue/Components/RegulationV2/Overlay/Segment/Indicateur";
import IndicateurGroup from "@/Vue/Components/RegulationV2/Overlay/Segment/IndicateurGroup";
import VitesseLimite from "@/Vue/Components/RegulationV2/Overlay/Segment/VitesseLimite";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import {useHistoriqueStore} from "@/Vue/Stores/RegulationV2/Historique";

const geolocalisationStore = useGeolocalisationStore();
const historiqueStore = useHistoriqueStore();
const {activeRessourcePopup} = storeToRefs(geolocalisationStore);
const {activeSegment, activeSegmentLoading, activeSegmentRessource, activeSegmentPositionIndex, activeSegmentPosition, activeSegmentShowRoute, activeSegmentRouteLoading, ressourceHistoriqueCurrentPage, ressourceHistoriqueSortDirection} = storeToRefs(historiqueStore);
const {loadSegment, close} = historiqueStore;

const historiqueTabRef = ref();
const index = ref(0);
const fromIndex = ref(false);

watch(index, () => {
    fromIndex.value = true;
    activeSegmentPositionIndex.value = Math.round((index.value / 100) * (activeSegment.value.positionCollection.length - 1));
    nextTick(() => fromIndex.value = false);
});

watch(activeSegmentPositionIndex, () => {
    if (!fromIndex.value) {
       index.value = Math.round(activeSegmentPositionIndex.value / activeSegment.value.positionCollection.length * 100);
    }

    first.value = Math.floor(activeSegmentPositionIndex.value/10) * 10;
});

const itemNumberPerPage = 10;
const first = ref(0);
const expandedRows = ref({});

const onPositionClick = (event) => {
    expandedRows.value = {[activeSegmentPositionIndex]: true};
    activeSegmentPositionIndex.value = activeSegment.value.positionCollection.indexOf(event.data);
}

const evenementByPositionByType = computed(() => {
   const evenements = {};

   for (const index in activeSegment.value.positionCollection) {
       const position = activeSegment.value.positionCollection[index];

       for (const type in position.evenementByTypeCollection) {
           if (!(type in evenements)) {
               evenements[type] = {};
           }
           evenements[type][index] = position.evenementByTypeCollection[type];
       }
   }

   for (const type in evenements) {
       let lastEvenement = null;

       for (const index in activeSegment.value.positionCollection) {
           if (index in evenements[type]) {
               lastEvenement = evenements[type][index];
           } else {
               evenements[type][index] = lastEvenement;
           }
       }
   }

   return evenements;
});

const segmentPrecedentLoading = ref(false);
const segmentSuivantLoading = ref(false);

const precedent = () => {
    segmentPrecedentLoading.value = true;

    if(!(activeSegment.value.segmentPrecedent.id in historiqueTabRef.value.itemsById)) {
        ressourceHistoriqueCurrentPage.value -= ressourceHistoriqueSortDirection.value;
        historiqueTabRef.value.refresh();
    }

    loadSegment(activeSegment.value.segmentPrecedent.id).then(() => {
        segmentPrecedentLoading.value = false;
    });
};

const suivant = () => {
    segmentSuivantLoading.value = true;

    if(!(activeSegment.value.segmentSuivant.id in historiqueTabRef.value.itemsById)) {
        ressourceHistoriqueCurrentPage.value += ressourceHistoriqueSortDirection.value;
        historiqueTabRef.value.refresh();
    }

    loadSegment(activeSegment.value.segmentSuivant.id).then(() => {
        segmentSuivantLoading.value = false;
    });
};

const detailMode = ref(null);
const chartMode = ref(null);

const toggleDetail = (mode, chart) => {
    if ((mode === 'list' && detailMode.value === mode) || (detailMode.value === 'chart' && chartMode.value === chart)) {
        detailMode.value = null;
        chartMode.value = null;
    } else {
        detailMode.value = mode;
        chartMode.value = chart;
    }
}

const getChartItemValue = (position, index) => {
    if ('vitesse' === chartMode.value) {
        return position.vitesse;
    }

    if ('altitude' === chartMode.value) {
        return position.altitude;
    }

    return evenementByPositionByType.value[chartMode.value][index]?.valeur;
}
const chartData = computed(() => chartMode ? activeSegment.value.positionCollection.map((position, index) => [position.horodatage.date+' '+position.horodatage.time, getChartItemValue(position, index)]) : null);
</script>