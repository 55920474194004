<template>
    <Section title="Itinéraire"></Section>
    <div class="my-2 gap-y-2 flex flex-col">
        <div class="flex gap-x-2">
            <Button icon="fa fa-calendar" :severity="false ? null : 'secondary'" size="small" @click="(event) => dateOverlay.toggle(event)"/>
            <Popover ref="dateOverlay" :pt="{content: ''}" :ptOptions="{mergeProps: false}">
                <DatePicker v-model="date" showTime showButtonBar :manualInput="false" inline>
                    <template #header>
                        <div>
                            lol
                        </div>
                    </template>
                </DatePicker>
            </Popover>
            <Select v-model="vehicule" :options="Object.values(ressourcesByIdByType.vehicule)" showClear optionLabel="regulation.text" placeholder="Véhicule" class="w-full" />
            <FilterButton v-model="options" multiple :choices="{noTolls: {icon: 'fa-solid fa-handshake-slash', title: 'Éviter les péages'}, ev: {icon: 'fa-solid fa-charging-station', title: 'Planificateur véhicule éléctrique'}}"/>
        </div>
        <div>
            <div class="bg-white rounded-md p-2 border border-surface-100 flex gap-x-2">
                <div class="flex-1 flex flex-col gap-y-2" ref="stepsContainer">
                    <div v-for="(step, i) in steps" class="flex gap-x-2" :key="step.id">
                        <InputGroup>
                            <InputGroupAddon class="!py-1 !min-w-none transition-colors !bg-surface-200 hover:!bg-surface-300 font-bold cursor-grab">
                                <Badge :value="i+1" severity="secondary" class="!bg-white" :class="0 === i ? '!text-green-500' : (steps.length-1 === i ? '!text-red-500' :'!text-indigo-500')"/>
                            </InputGroupAddon>
                            <IconField iconPosition="left" class="!w-full">
                                <InputIcon class="z-20 fa-solid fa-search"></InputIcon>
                                <InputText v-model="step.text" :placeholder="0 === i ? 'Départ' : (steps.length-1 === i ? 'Arrivée' :'Étape')" class="!rounded-l-none w-full"/>
                            </IconField>
                        </InputGroup>
                        <Button title="Inverser" icon="fa-solid fa-trash" severity="danger" outlined v-if="steps.length > 2" @click="removeStep(i)"></Button>
                    </div>
                </div>
                <div v-if="steps.length === 2">
                    <Button title="Inverser" icon="fa-solid fa-swap" severity="secondary" class="h-full !w-7" :disabled="!steps[0].text && !steps[1].text" @click="swapSteps"></Button>
                </div>
            </div>
            <div class="ml-12">
                <Button label="Ajouter une étape" icon="fa-solid fa-plus" severity="secondary" class="!rounded-t-none !py-1" :disabled="steps.length >= 5" @click="addStep"></Button>
            </div>

        </div>
    </div>

    <Section title="Historique" v-if="false"></Section>
</template>

<script setup>
import Section from "@/Vue/Components/RegulationV2/Layout/Section";
import Select from 'primevue/select';
import FilterButton from "@/Vue/Components/RegulationV2/Layout/FilterButton.vue";
import {ref, watch} from "vue";
import Popover from "primevue/popover";
import Button from "primevue/button";
import DatePicker from "primevue/datepicker";
import InputText from 'primevue/inputtext';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import InputIcon from "primevue/inputicon";
import IconField from "primevue/iconfield";
import Badge from 'primevue/badge';
import { useSortable } from '@vueuse/integrations/useSortable'
import {useGeolocalisationStore} from "@/Vue/Stores/RegulationV2/Geolocalisation";
import {storeToRefs} from "pinia";

const geolocalisationStore = useGeolocalisationStore();
const {ressourcesByIdByType} = storeToRefs(geolocalisationStore);

const date = ref(null);
const dateOverlay = ref();
const vehicule = ref(null);
const options = ref([]);

watch(vehicule, () => {
    if (vehicule.value) {
        steps.value[0].text = vehicule.value.text; // todo link
    } else {
        // clear step that has vehicule linked
    }
});

const steps = ref([
    {id: 0}, {id: 1}
]);

const stepsContainer = ref();

useSortable(stepsContainer, steps, {
    handle: '.cursor-grab',
});

let id = 1;
const addStep = () => steps.value.splice(steps.value.length - 1, 0, {id: ++id});
const removeStep = (index) => steps.value.splice(index, 1);
const swapSteps = () => steps.value.reverse();

</script>