<template>
    <MapPin v-if="lngLat" :lng-lat="lngLat" :variant="estReel ? 'green' : 'blue'" shape="square">
        <template #content>
            {{ label }}
        </template>
        <template #contentHover v-if="time !== null">
            {{ time }}
        </template>
    </MapPin>
</template>

<script setup>
import MapPin from "@/Vue/Components/RegulationV2/Map/Base/Pin.vue";
import {toRefs, computed} from "vue";

const props = defineProps({
    trajet: Object,
    top: String,
});

const {trajet, top} = toRefs(props);

const label = computed(() => {
    switch (top.value) {
        case 'PriseEnCharge': return 'PEC';
        case 'Destination': return 'DEST';
    }
})

const time = computed(() => trajet.value['top'+top.value+'Arrivee']?.time)
const estReel = computed(() => trajet.value['top'+top.value+'ArriveeReel'] !== null)

const lngLat = computed(() => {
    const latitude = trajet.value['latitudeDegre'+top.value];
    const longitude = trajet.value['longitudeDegre'+top.value];

    if (null !== latitude && null !== longitude) {
        return [longitude, latitude];
    }

    return null;
})
</script>