<template>
  <div class="row">
    <tableau-de-bord-widget-compteurs-item icon="fa-regular fa-message-sms" variant="danger">
      <template #title>{{ Translator.trans('referentiel.tableau-de-bord.consommations.sms.creditsConsommesMois') }}</template>
      <template #content v-if="'creditsSmsCountMonth' in data">
        {{ data.creditsSmsCountMonth }}
      </template>
    </tableau-de-bord-widget-compteurs-item>
    <tableau-de-bord-widget-compteurs-item icon="fa-regular fa-message-sms" variant="danger">
      <template #title>{{ Translator.trans('referentiel.tableau-de-bord.consommations.sms.creditsConsommesAnnee') }}</template>
      <template #content v-if="'creditsSmsCountYear' in data">
        {{ data.creditsSmsCountYear }}
      </template>
    </tableau-de-bord-widget-compteurs-item>
    <tableau-de-bord-widget-compteurs-item icon="fa-regular fa-tire" variant="info">
      <template #title>{{ Translator.trans('referentiel.tableau-de-bord.consommations.viaMichelin.requetesConsommeesMois') }}</template>
      <template #content v-if="'requetesViaMichelinCountMonth' in data">
        {{ data.requetesViaMichelinCountMonth }}
      </template>
    </tableau-de-bord-widget-compteurs-item>
    <tableau-de-bord-widget-compteurs-item icon="fa-regular fa-tire" variant="info">
      <template #title>{{ Translator.trans('referentiel.tableau-de-bord.consommations.viaMichelin.requetesConsommeesAnnee') }}</template>
      <template #content v-if="'requetesViaMichelinCountYear' in data">
        {{ data.requetesViaMichelinCountYear }}
      </template>
    </tableau-de-bord-widget-compteurs-item>
    <tableau-de-bord-widget-compteurs-item icon="fa-regular fa-id-card" variant="amelipro">
        <template #title>{{ Translator.trans('referentiel.tableau-de-bord.consommations.ameliPro.requetesConsommeesMois') }}</template>
        <template #content v-if="'creditsAmeliProCountMonth' in data">
            {{ data.creditsAmeliProCountMonth }}
        </template>
    </tableau-de-bord-widget-compteurs-item>
    <tableau-de-bord-widget-compteurs-item icon="fa-regular fa-id-card" variant="amelipro">
        <template #title>{{ Translator.trans('referentiel.tableau-de-bord.consommations.ameliPro.requetesConsommeesAnnee') }}</template>
        <template #content v-if="'creditsAmeliProCountYear' in data">
            {{ data.creditsAmeliProCountYear }}
        </template>
    </tableau-de-bord-widget-compteurs-item>
    <tableau-de-bord-widget-compteurs-item icon="fa-regular fa-truck-medical fa-sm" variant="primary">
        <template #title>{{ Translator.trans('referentiel.tableau-de-bord.consommations.immatriculationApi.requetesConsommeesMois') }}</template>
        <template #content v-if="'creditsImmatriculationApiCountMonth' in data">
            {{ data.creditsImmatriculationApiCountMonth }}
        </template>
    </tableau-de-bord-widget-compteurs-item>
    <tableau-de-bord-widget-compteurs-item icon="fa-regular fa-truck-medical fa-sm" variant="primary">
        <template #title>{{ Translator.trans('referentiel.tableau-de-bord.consommations.immatriculationApi.requetesConsommeesAnnee') }}</template>
        <template #content v-if="'creditsImmatriculationApiCountYear' in data">
            {{ data.creditsImmatriculationApiCountYear }}
        </template>
    </tableau-de-bord-widget-compteurs-item>
  </div>
</template>

<script setup>
import {onMounted, ref, toRefs, watch} from "vue";
import Router from "@/App/Router";
import Translator from "@/App/Translator";
import {useRequest} from "@/Vue/Composables/Request";

const {postJson, abort} = useRequest();

const emit = defineEmits(['refresh', 'error']);

const props = defineProps({
    structures: Array,
    tab: String,
});

const {structures, tab} = toRefs(props);

const data = ref({});
const loading = ref(false);

const refresh = () => {
    if(!structures.value.length) {
        abort();
        loading.value = false;
        emit('refresh');

        return;
    }

    data.value = {};
    loading.value = true;

    postJson(Router.generate('referentiel.tableau-de-bord.consommations'), {
        tableau_de_bord_filter: {
            structure: structures.value,
        }
    }).then((res) => {
        data.value = res;
        emit('refresh');
    }, (err) => emit('error', err)).finally(() => {
        loading.value = false;
    });
}

watch(structures, () => refresh());
onMounted(() => refresh());

</script>