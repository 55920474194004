<template>
    <transition name="marker-popup-container" appear>
        <div class="marker-popup-container drop-shadow-md">
            <div class="marker-popup overflow-hidden rounded-lg text-sm" :style="{width}" :class="{'marker-popup-square': shape === 'square', 'marker-popup-circle': shape === 'circle'}">
                <div class="bg-white/80 dark:bg-black/40">
                    <div class="marker-popup-header font-medium py-1 px-3">
                        <slot name="header">
                            <div class="flex justify-between items-center">
                                <div>
                                    <slot name="headerLeft"></slot>
                                </div>
                                <div>
                                    <i class="fa-solid fa-times cursor-pointer text-surface-600 hover:text-surface-800" @click="emit('close')"></i>
                                </div>
                            </div>
                        </slot>
                    </div>
                    <div class="marker-popup-content relative bg-surface-0 dark:bg-surface-900 overflow-hidden rounded-t-xl z-10">
                        <slot></slot>
                    </div>
                </div>
                <div class="marker-popup-footer">
                    <div class="marker-popup-footer-left flex flex-col justify-center overflow-hidden bg-surface-0 dark:bg-surface-900 flex-1 h-[32px]" :class="{'rounded-br-lg': shape !== null}">
                        <slot name="footerLeft"></slot>
                    </div>
                    <div class="marker-popup-footer-center relative before:!border-surface-0 dark:before:!border-surface-900 before:shadow-surface-0 dark:before:shadow-surface-900" v-if="shape !== null"></div>
                    <div class="marker-popup-footer-right flex flex-col justify-center overflow-hidden bg-surface-0 dark:bg-surface-900 flex-1 h-[32px]" :class="{'rounded-bl-lg': shape !== null}">
                        <slot name="footerRight"></slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup>
import {ref, toRefs, computed, inject} from "vue";

const props = defineProps({
    width: {default: '350px', type: String},
    shape: {default: null, type: String},
});

const shapeInject = inject('shape', ref(null));

const {width, shape: shapeProp} = toRefs(props);
const shape = computed(() => shapeProp.value ?? shapeInject.value);
const emit = defineEmits(['close'])
</script>