<template>
    <div class="flex w-full">
        <div :style="{'width': left+'%'}"></div>
        <div :class="active ? 'border-indigo-700' : 'hover:border-gray-400 cursor-pointer'" @click="onClick" class="border bg-white py-1 px-2 rounded-md shadow transition-colors min-w-fit" :style="{'width': width+'%'}">
            <span class="text-white rounded px-1 font-medium" :class="{aller: 'bg-blue-500', simultane: 'bg-orange-500', retour: 'bg-indigo-500'}[type]">{{ {aller: 'A', simultane: 'S', retour: 'R'}[type] }}</span> {{ trajet.topPriseEnChargeArrivee.time }}
        </div>
    </div>
</template>

<script setup>
import {computed, toRefs} from "vue";
import {useRegulationStore} from "@/Vue/Stores/Regulation";

const props = defineProps({
    active: Boolean,
    type: String,
    trajet: Object,
    total: Number,
    period: Array,
});

const regulationStore = useRegulationStore();
const {selectTrajetById} = regulationStore;

const {active, type, trajet, total, period} = toRefs(props);

// todo computed clickable, si dans liste visible

const onClick = () => {
    if (!active.value) {
        selectTrajetById(trajet.value.id);
    }
}

// todo fallback
const duration = computed(() => trajet.value._topDestinationArrivee.diff(trajet.value._topPriseEnChargeArrivee, 'seconds'));
const left = computed(() => trajet.value._topPriseEnChargeArrivee.diff(period.value[0], 'seconds') / total.value * 100);

const width = computed(() => (duration.value / total.value) * 100);

console.log(trajet.value, width.value);
</script>