<template>
    <i :title="title" :class="{[classes]: true, 'font-size-16px': !small, 'font-size-12px': small, 'fa-solid fa-location-check': 4 === value, 'fa-solid fa-location-dot': 4 !== value}"></i>
</template>

<script setup>
import Translator from "@/App/Translator";
import {toRefs, computed, watch} from "vue";

const emit = defineEmits(['update:title']);

const props = defineProps({
    value: Number,
    small: Boolean,
    tailwind: Boolean,
});

const {value, small, tailwind} = toRefs(props);

const title = computed(() => {
    switch (value.value) {
        case -1: return Translator.trans('libelle.precision.insee');
        case -2: return Translator.trans('libelle.precision.auto');
        case 1: return Translator.trans('libelle.precision.faible');
        case 2: return Translator.trans('libelle.precision.moyenne');
        case 3: return Translator.trans('libelle.precision.bonne');
        case 4: return Translator.trans('libelle.precision.manuelle');
        default: return Translator.trans('libelle.precision.aucune');
    }
});
watch(title, () => emit('update:title', title.value), {immediate: true});

const classes = computed(() => {
    switch (value.value) {
        case -1: return tailwind.value ? 'text-gray-800' : 'text-dark';
        case -2: return tailwind.value ? 'text-purple-600' : 'text-primary';
        case 1: return tailwind.value ? 'text-red-600' : 'text-danger';
        case 2: return tailwind.value ? 'text-yellow-500' : 'text-warning';
        case 3: return tailwind.value ? 'text-green-600' : 'text-success';
        case 4: return tailwind.value ? 'text-green-600' : 'text-success';
        default: return tailwind.value ? 'text-gray-500' : 'text-lightgrey';
    }
});

</script>
