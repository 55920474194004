<template>
    <label class="flex justify-between items-center rounded px-2 py-1 transition-colors select-none font-medium" :class="{'hover:bg-surface-100 dark:hover:bg-surface-950': !choices}">
        <slot>
            <span>{{ label }}</span>
            <SelectButton v-if="choices" class="flex" v-model="model" :allowEmpty="false" optionLabel="label" optionValue="value" dataKey="value" :options="[{value: false, label: 'Off'}, {value: true, label: 'On'}, {value: null, label: 'Auto'}]"></SelectButton>
<!--            <FilterButton v-if="choices" v-model="model" :choices="choices"/>-->
            <InputSwitch v-else v-model="model" />
        </slot>
    </label>
</template>

<script setup>
import InputSwitch from "primevue/inputswitch";
import FilterButton from "@/Vue/Components/RegulationV2/Layout/FilterButton.vue";
import SelectButton from "primevue/selectbutton";

defineProps({
    label: String,
    choices: Object,
});

const model = defineModel();
</script>