<template>
    <MapSource source="vectorTilesIncidents" v-model:activeFeature="activeFeature" :visible="mapLayers.traffic.incidents" :selectable="['Traffic incident POI']" oldFilterStyle>
        <template #activeFeature="{feature, image}">
            <MapPin :key="feature.properties.id" :lng-lat="feature.geometry.coordinates" shape="square" variant="white" :pulse="true" :popup="true" :clickable="true">
                <template #content>
                    <img :src="image" class="absolute w-[38px] -top-[5px] max-w-none"/>
                </template>
                <MapPopup>
                    <Popup width="275px" @close="activeFeature = null">
                        <template #headerLeft>
                            <span class="px-1 rounded-md mr-1 text-white text-white" :class="magnitudes[feature.properties.magnitude].badge">Incident</span>
                            {{ libellesDescription[feature.properties.description_0.toLowerCase()] ?? feature.properties.description_0 }}
                        </template>
                        <div class="p-2 flex flex-col gap-2">
                            <PopupGroup>
                                <PopupItem label="Durée" v-if="feature.properties.delay">
                                    {{ Math.round(feature.properties.delay/60) }} minutes
                                </PopupItem>
                                <PopupItem label="Impact" v-if="feature.properties.traffic_road_coverage">
                                    {{ libellesRoadCoverage[feature.properties.traffic_road_coverage] ?? feature.properties.traffic_road_coverage }}
                                </PopupItem>
                                <PopupItem label="Fin estimée" v-if="feature.properties.end_date">
                                    {{ moment(feature.properties.end_date).format('DD/MM/YYYY HH:mm') }}
                                </PopupItem>
                            </PopupGroup>
                            <PopupLocation :lngLat="feature.geometry.coordinates"></PopupLocation>
                        </div>
                    </Popup>
                </MapPopup>
            </MapPin>
        </template>
    </MapSource>
    <MapSource source="vectorTilesFlow" :visible="mapLayers.traffic.flow"></MapSource>
</template>

<script setup>
import PopupGroup from "@/Vue/Components/RegulationV2/Layout/Popup/Group.vue";
import MapSource from "@/Vue/Components/RegulationV2/Map/Base/Source.vue";
import MapPopup from "@/Vue/Components/RegulationV2/Map/Base/Popup.vue";
import PopupItem from "@/Vue/Components/RegulationV2/Layout/Popup/Item.vue";
import MapPin from "@/Vue/Components/RegulationV2/Map/Base/Pin.vue";
import Popup from "@/Vue/Components/RegulationV2/Layout/Popup.vue";
import PopupLocation from "@/Vue/Components/RegulationV2/Layout/Popup/Location.vue";
import {useGeolocalisationStore} from "@/Vue/Stores/RegulationV2/Geolocalisation";
import {storeToRefs} from "pinia";
import {ref} from "vue";
import moment from 'moment';

const geolocalisationStore = useGeolocalisationStore();
const {mapLayers} = storeToRefs(geolocalisationStore);

const activeFeature = ref(null);
const magnitudes = {
    0: {badge: 'bg-yellow-500'},
    1: {badge: 'bg-yellow-500'},
    2: {badge: 'bg-orange-500'},
    3: {badge: 'bg-red-500'},
    4: {badge: 'bg-red-500'},
};
const libellesRoadCoverage = {
    one_side: 'Une voie',
    full: 'Dans les deux sens',
};
const libellesDescription = {
    'closed': 'Route fermée',
    'tunnel closed': 'Tunnel fermé',
    'roadworks': 'Travaux',
    'stationary traffic': 'Bouchons',
    'queuing traffic': 'Ralentissements importants',
    'slow traffic': 'Ralentissements',
    'bridge closed': 'Pont fermé',
    'major event' : 'Évenement important',
    'international sports meeting': 'Événement sportif international',
};

</script>