<template>
    <div class="flex flex-col gap-y-1 items-start">
        <Badge v-for="item in items" :trajet="item[0]" :type="item[1]" :active="currentTrajet === item[0]" :total="total" :period="period"></Badge>
    </div>
</template>

<script setup>
import Badge from '@/Vue/Components/RegulationV2/Sidebar/Trajet/TrajetBadge';
import {computed, toRefs} from "vue";
import {useRegulationStore} from "@/Vue/Stores/Regulation";
import {storeToRefs} from "pinia";

const regulationStore = useRegulationStore();
const {currentTrajet} = storeToRefs(regulationStore);

const props = defineProps({
    items: Array,
});

const {items} = toRefs(props);


const period = computed(() => {
    let min = null;
    let max = null;

    for (const item of items.value) {
        const trajet = item[0];
        const newMin = trajet._topPriseEnChargeArrivee;
        const newMax = trajet._topDestinationArrivee;
        // todo fallbacks (shared field?)

        if (null === min || newMin.isBefore(min)) {
            min = newMin;
        }
        if (null === max || newMax.isAfter(max)) {
            max = newMax;
        }
    }

    return [min, max];
});

const total = computed(() => period.value[1].diff(period.value[0], 'seconds'));

console.log(period.value, total.value);

</script>