<template>
    <div class="bg-gray-100 dark:bg-gray-800 rounded-lg overflow-hidden flex border-l-4 min-h-8 hover:ring-1 transition cursor-pointer" :class="{'success': 'ring-green-500/20 border-green-500', 'info': 'ring-blue-500/20 border-blue-500', 'warning': 'ring-orange-500/20 border-orange-500', 'danger': 'ring-red-500/20 border-red-500', 'secondary': 'ring-gray-500/20 border-gray-500'}[ressource._etat.variant]">
        <div class="flex-1 min-w-0 flex items-center">
            <div class="p-1 flex flex-1 flex-col gap-y-1 overflow-hidden">
                <template v-if="ressource._type === 'vehicule'">
                    <div class="flex gap-x-1 leading-tight">
                        <div class="px-1 flex-1 flex items-center overflow-hidden">
                            <i v-if="ressource.icone" :title="ressource.icone.title" class="mr-1 fa-fw" :class="[ressource.icone.classeCouleur, ressource.icone.classe]"></i>
                            <span class="font-medium truncate" :title="ressource.text">{{ ressource.shortString }}</span>
                        </div>
                        <NotificationBadge v-if="ressourceGeoloc && ressourceGeoloc.notifications.length > 0">{{ ressourceGeoloc.notifications.length }}</NotificationBadge>
                        <div @click.stop="onTrajetClick(ressource._trajetInfo.minTrajet)" class="rounded-md bg-white font-medium px-2 relative z-0 overflow-hidden" v-if="rendezVousIsToday">
                            <div class="absolute inset-0 pointer-events-none -z-10 transition-all rounded" :style="getProgressStyle(ressource)" ></div>
                            <EtatInfo :ressource="ressource"></EtatInfo>
                        </div>
                        <template v-if="ressource._etat.section !== 'disponible' && ressource._trajetInfo.minTrajet">
                            <template v-for="field in ['_personnel1', '_personnel2']">
                                <div v-if="ressource._trajetInfo.minTrajet[field]" @click="" class="rounded-md text-white font-medium px-1 flex items-center text-sm" :style="'background: '+ressource._trajetInfo.minTrajet[field].couleur+';'" :title="ressource._trajetInfo.minTrajet[field].text">
                                    {{ ressource._trajetInfo.minTrajet[field].shortString }}
                                </div>
                            </template>
                        </template>
                    </div>
                    <template v-if="ressourceGeoloc">
                        <div v-if="ressourceGeoloc.geolocalisation.position" class="flex text-xs px-1 gap-x-2 justify-between text-slate-500 text-nowrap">
                            <div v-if="segment = ressourceGeoloc.geolocalisation.position.segment">
                                <template v-if="segment.enMouvement">
                                    <i class="fa-solid fa-fw fa-road"></i> {{ duration(segment.positionDebut.horodatage) }} • {{ segment.kilometreVehicule ?? segment.kilometreGps }} km
                                </template>
                                <template v-else>
                                    <i class="fa-solid fa-fw fa-square-parking"></i> {{ duration(segment.positionDebut.horodatage) }}
                                </template>
                            </div>
                            <div class="truncate" :title="ressourceGeoloc.geolocalisation.position.adresseCompleteUneLigne">
                                <i class="fa-solid fa-fw fa-location-dot"></i> {{ ressourceGeoloc.geolocalisation.position.adresseCompleteUneLigne }}
                            </div>
                            <div>
                                <UpdateIndicator :ressource="ressourceGeoloc.geolocalisation" :color="false"></UpdateIndicator>
                            </div>
                        </div>
                    </template>
                </template>
                <template v-else-if="ressource._type === 'personnel'">
                    <div class="flex gap-x-1">
                        <div class="rounded-md text-white font-medium px-2 py-1" style="background: rgb(171, 140, 228);">
                            {{ ressource.shortString }}
                        </div>
                        <div class="flex-1 overflow-hidden flex items-center" style="color: rgb(171, 140, 228);">
                            <div class="truncate">
                                {{ ressource.text }}
                            </div>
                        </div>
                        <div>
                            <i class="fa-solid fa-check text-green-500"></i>
                        </div>
                        <div title="Amplitude de jour de 12h" class="rounded-md font-medium py-1 px-2" style="background: #87CEFA; color: #FFF;">AJ12</div>
                        <div class="rounded-md bg-white font-medium flex divide-x">
                            <div class="py-1 px-2">12:00</div>
                            <div class="py-1 px-2">16:00</div>
                        </div>
                    </div>
                    <div class="bg-slate-50 rounded-md overflow-hidden flex text-sm" v-if="false">
                        <div class="bg-slate-500 text-white rounded-md px-1">
                            <i class="fa-solid fa-info-square fa-fw"></i>
                        </div>
                        <div class="px-1 text-slate-800">
                            Ceci est un commentaire
                        </div>
                    </div>
                </template>

                <!--                        <div class="bg-red-50 rounded-md overflow-hidden flex text-sm">-->
                <!--                            <div class="bg-red-500 text-white rounded-md px-1">-->
                <!--                                <i class="fa-solid fa-exclamation-triangle fa-fw"></i>-->
                <!--                            </div>-->
                <!--                            <div class="px-1 text-red-800">-->
                <!--                                Fin prévue dans 15 min-->
                <!--                            </div>-->
                <!--                        </div>-->
            </div>
        </div>
        <div class="p-1 pl-0 flex">
            <div class="rounded px-2 text-center flex items-center hover:bg-white dark:hover:bg-gray-900 transition">
                <i class="fa-solid fa-ellipsis-vertical"></i>
            </div>
        </div>
    </div>
</template>

<script setup>
import UpdateIndicator from "@/Vue/Components/RegulationV2/Map/Ressource/UpdateIndicator.vue";
import EtatInfo from "@/Vue/Components/RegulationV2/Sidebar/Ressources/EtatInfo";
import {toRefs, computed} from "vue";
import {useGeolocalisationStore} from "@/Vue/Stores/RegulationV2/Geolocalisation";
import {storeToRefs} from "pinia";
import {useRegulationStore} from "@/Vue/Stores/Regulation";
import {duration} from "@/Vue/Filters/Horodatage"
import NotificationBadge from "@/Vue/Components/RegulationV2/Layout/NotificationBadge.vue";

const props = defineProps({
    ressource: Object,
});
const {ressource} = toRefs(props);
const regulationStore = useRegulationStore();
const {rendezVousIsToday} = storeToRefs(regulationStore);
const {selectTrajetById} = regulationStore;

const geolocalisationStore = useGeolocalisationStore();
const {ressourcesByIdByType, tab, sidebarTab} = storeToRefs(geolocalisationStore);

const ressourceGeoloc = computed(() => ressourcesByIdByType.value[ressource.value._type][ressource.value.id]);

const getProgressStyle = (ressource) => {
    let style = {};

    if(ressource._etat.name === 'en-mission') {
        style.background = 'rgba(251, 150, 120, 0.35)';
        style.width = ressource._progress*100 + '%';
    }
    else if(ressource._etat.name === 'fin-mission' || ressource._etat.name === 'pause') {
        style.background = 'rgba(254, 193, 7, 0.35)';
        style.width = ressource._progress*100 + '%';
    }

    return style;
}

const onTrajetClick = (trajet) => {
    selectTrajetById(trajet.id);
    sidebarTab.value = 'trajet';

    if (tab.value === 'trajets') {
        // cycle trajets of ressource
    }
};
</script>