'use strict';

App.Regulation.Patient = class {};
App.Regulation.Patient.Trajet = class {};

App.Regulation.Patient.Trajet.Index = class {
    constructor(params) {
        App.Shared.initContextMenu(params);
    }
};
