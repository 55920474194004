<template>
    <div class="text-left">
        <div class="row">
            <div class="col-6">
                <h3 class="text-theme mt-0">
                    <i class="fa-solid fa-grid-2"></i>
                    Ajouter une pièce
                </h3>
            </div>
            <div class="col-6">
                <div class="d-flex mb-3 mt-n1">
                    <div class="flex-1">
                        <div v-html="structureHtml"></div>
                    </div>
                    <b-btn variant="info" class="ml-3" @click="upload" :disabled="!structure || !hasFile || loading">
                        <i class="fa-solid fa-check"></i>
                        Ajouter
                    </b-btn>
                </div>
            </div>
        </div>
        <div id="dropzone" class="dropzone" ref="dropzone">
            <div class="dz-message">
                <div class="font-size-24px text-normal">Cliquez ici</div>
                <div class="text-muted">ou</div>
                <div class="font-size-16px text-normal mt-2">déposez directement les fichiers</div>
            </div>
        </div>
    </div>
</template>

<script>
import swal from "sweetalert2";

export default {
    data() {
        return {
            structureHtml: '',
            structure: null,
            dropzone: null,
            loading: true,
            hasFile: false,
        };
    },
    beforeMount() {
        const treeSelectInputSelector = 'input[name="global_piece[structure]"]';

        $('body').on('change', treeSelectInputSelector, () => {
            this.structure = $(treeSelectInputSelector + ':checked').val();
        }).one('treeselect.init', () => {
            $(treeSelectInputSelector).filter(':checked').change();
        }).on('change', 'select#global_piece_structure', (e) => {
            this.structure = $(e.currentTarget).val();
        });
    },
    mounted() {
        this.dropzone = App.Shared.Piece.dropzone('#dropzone', {
            url: '/',
            hiddenInputContainer: '#globalUploadInput',
            autoProcessQueue: false,
        });

        this.dropzone.on('error', () => {
            this.loading = false;
            this.hasFile = false;
        });

        this.dropzone.on('addedfile', () => {
            this.hasFile = true;
        });

        this.dropzone.on('success', (file, data) => {
            this.loading = false;

            setTimeout(() => swal.close(), 1000);
            // todo open viewer
        });

        this.dropzone.handleFiles(document.getElementById('globalUploadInput').files);

        this.getJsonRequest(this.path('piece.structure')).then((data) => {
            this.structureHtml = data.html;
            this.$nextTick(() => {
                App.Layout.enableTreeSelect();
                this.loading = false;
            })
        });
    },
    methods: {
        upload() {
            if(this.dropzone.getQueuedFiles().length) {
                this.loading = true;
                this.hasFile = false;
                this.dropzone.options.url = this.path('piece.upload', {structure: this.structure});
                this.dropzone.processQueue();
            }
        },
    }
}
</script>
