<template>
    <div>
        <h3 class="mt-0">Annuler le trajet</h3>
        <div class="section-title"><span>{{ motif.libelle }}</span></div>
        <div class="mt-4 px-5">
            <textarea ref="commentaireInput" class="form-control" v-model="commentaire" :required="commentaireRequired" :placeholder="commentaireRequired ? 'Commentaire requis' : 'Commentaire'"></textarea>
        </div>
        <div class="text-center mt-5">
            <button class="btn btn-secondary js-close-swal" type="button">Annuler</button>
            <button type="button" class="btn btn-info" :disabled="!canSubmit" @click="submit">
                <i class="fa-solid fa-check"></i>
                <span class="d-none d-md-inline">Confirmer</span>
            </button>
        </div>
    </div>
</template>

<script setup>
import {computed, onMounted, ref, toRefs} from "vue";
import swal from "sweetalert2";

const emit = defineEmits(['data']);
const props = defineProps({
    motif: Object,
});

const {motif} = toRefs(props);
const commentaire = ref('');
const commentaireInput = ref();

const commentaireRequired = computed(() => motif.value.modeCommentaire === 2);

const canSubmit = computed(() => {
    return !commentaireRequired.value || commentaire.value.length > 0;
});

const submit = () => {
    swal.close();
    emit('data', {
        commentaire: commentaire.value,
    });
}

onMounted(() => commentaireInput.value.focus());

</script>
