import {defineStore, storeToRefs} from "pinia";
import {computed, onMounted, ref, watch} from "vue";
import Request from "@/App/Request";
import Router from "@/App/Router";
import {useRegulationStore} from "@/Vue/Stores/Regulation";
import Translator from "@/App/Translator";
import bbox from "@turf/bbox";
import {useUrlSearchParams, usePreferredDark, useBreakpoints, breakpointsTailwind} from "@vueuse/core";
import _ from "lodash";
import streetLightPreview from '@/../images/map/preview/street-light.png';
import streetDarkPreview from '@/../images/map/preview/street-dark.png';
import monoLightPreview from '@/../images/map/preview/mono-light.png';
import monoDarkPreview from '@/../images/map/preview/mono-dark.png';
import satellitePreview from '@/../images/map/preview/satellite.png';

export const useGeolocalisationStore = defineStore('geolocalisation', () => {
    const regulationStore = useRegulationStore();
    const {structureCollection, initDataLoaded, vehiculesById, messages, messagesById, messagesByRessourceId} = storeToRefs(regulationStore);
    const {groupMessagesByRessource, urlParams} = regulationStore;

    const tab = ref(urlParams.tab ?? null);
    const sidebarTab = ref(urlParams.sidebarTab ?? null);

    watch(tab, () => {
        urlParams.tab = tab.value;
    });
    watch(sidebarTab, () => {
        urlParams.sidebarTab = sidebarTab.value;
    });

    const breakpoints = useBreakpoints(breakpointsTailwind);
    const isDesktop = breakpoints.greaterOrEqual('md');

    const roles = ref({});

    const allMapStyles = {
        light: {
            provider: 'tomtom',
            map: '2/basic_street-light',
            poi: '2/poi_dynamic-light',
            trafficIncidents: '2/incidents_light',
            trafficFlow: '2/flow_relative-light',
            preview: streetLightPreview,
        },
        dark: {
            provider: 'tomtom',
            map: '2/basic_street-dark',
            poi: '2/poi_dynamic-dark',
            trafficIncidents: '2/incidents_dark',
            trafficFlow: '2/flow_relative-dark',
            preview: streetDarkPreview,
        },
        monoLight: {
            provider: 'tomtom',
            map: '2/basic_mono-light',
            poi: '2/poi_dynamic-mono-light',
            trafficIncidents: '2/incidents_light',
            trafficFlow: '2/flow_relative-light',
            preview: monoLightPreview,
        },
        monoDark: {
            provider: 'tomtom',
            map: '2/basic_mono-dark',
            poi: '2/poi_dynamic-mono-dark',
            trafficIncidents: '2/incidents_dark',
            trafficFlow: '2/flow_relative-dark',
            preview: monoDarkPreview,
        },
        satellite: {
            provider: 'tomtom',
            map: '2/basic_street-satellite',
            poi: '2/poi_dynamic-satellite',
            trafficIncidents: '2/incidents_light',
            trafficFlow: '2/flow_relative-light',
            preview: satellitePreview,
        },
    }

    const mapVisible = ref(true);
    const mapInitialZoom = 10;
    const mapZoom = ref(urlParams.mapZoom ?? mapInitialZoom);
    watch(mapZoom, () => {
        urlParams.mapZoom = mapZoom.value;
    });

    const mapCenter = ref(urlParams.mapCenter ? JSON.parse(urlParams.mapCenter) : null);
    watch(mapCenter, () => {
        urlParams.mapCenter = JSON.stringify(mapCenter.value);
    });

    const mapStyles = computed(() => ({
        street: {name: 'Couleur', config: mapDarkMode.value ? allMapStyles.dark : allMapStyles.light},
        mono: {name: 'Mono', config: mapDarkMode.value ? allMapStyles.monoDark : allMapStyles.monoLight},
        satellite: {name: 'Satellite', config: allMapStyles.satellite},
    }));

    const mapDarkMode = ref(false);
    const systemDark = usePreferredDark();
    const darkMode = ref(null);
    const isDark = computed(() => true === darkMode.value || (null === darkMode.value && systemDark.value));

    watch(isDark, () => {
        document.documentElement.classList.toggle('dark', isDark.value);
        mapDarkMode.value = isDark.value;
    }, {immediate: true});
    watch(mapDarkMode, () => {
        if (!mapDarkMode.value && darkMode.value) {
            darkMode.value = false;
        }
    });

    const mapStyleName = ref('street');
    const mapStyle = computed({
        get: () => mapStyles.value[mapStyleName.value],
        set: (style) => mapStyleName.value = Object.keys(mapStyles.value).find(key => mapStyles.value[key] === style),
    });

    const mapLayers = ref({
        traffic: {incidents: true, flow: false},
        ressources: true,
        bureaux: true,
        organismes: false,
        radars: false,
        poi: ["electric_vehicle_station","gas_station","toll_gate","business_park","industrial_building","dentist","doctor","emergency_room","emergency_medical_service","health_care_service","hospital","pharmacy","veterinarian","access_gateway","airport","checkpoint","ferry_terminal","frontier_crossing","helipad","mountain_pass","port_or_warehouse_facility","public_transportation_stop","railroad_station","geographic_feature"],
    });

    const mapPois = [
        {
            name: "Carburant",
            icon: 'fa-fw fa-solid fa-bolt',
            categories: {
                electric_vehicle_station: "Borne de recharge pour véhicules électriques",
                gas_station: "Station-service",
            }
        },
        {
            name: "Services automobiles",
            icon: 'fa-fw fa-solid fa-garage',
            categories: {
                automotive_dealer: "Concessionnaire automobile",
                car_wash: "Station de lavage",
                motoring_organization_office: "Club automobile",
                rent_a_car_facility: "Agence de location de voitures",
                rent_a_car_parking: "Parking de location de voitures",
                repair_facility: "Atelier de réparation",
                truck_stop: "Relais routier",
                weigh_station: "Station de pesage"
            }
        },
        {
            name: "Stationnement",
            icon: 'fa-fw fa-solid fa-square-parking',
            categories: {
                open_parking_area: "Aire de stationnement",
                parking_garage: "Parking couvert"
            }
        },
        {
            name: "Services routiers",
            icon: 'fa-fw fa-solid fa-route-highway',
            categories: {
                rest_area: "Aire de repos",
                toll_gate: "Péage"
            }
        },
        {
            name: "Finance",
            icon: 'fa-fw fa-solid fa-coins',
            categories: {
                bank: "Banque",
                atm: "Distributeur automatique",
                exchange: "Bureau de change"
            }
        },
        {
            name: "Entreprise et industrie",
            icon: 'fa-fw fa-solid fa-industry-windows',
            categories: {
                agriculture_business: "Entreprise agricole",
                business_park: "Parc d'activité",
                commercial_building: "Bâtiment commercial",
                company: "Entreprise",
                courier_drop_box: "Boîte de dépôt de courrier",
                exhibition_and_convention_center: "Centre d'exposition et de congrès",
                industrial_building: "Bâtiment industriel",
                manufacturing_facility: "Site de production",
                media_facility: "Presse",
                research_facility: "Centre de recherche"
            }
        },
        {
            name: "Restauration",
            icon: 'fa-fw fa-solid fa-plate-utensils',
            categories: {
                cafe_or_pub: "Café ou pub",
                restaurant: "Restaurant",
                restaurant_area: "Zone de restaurants",
            }
        },
        {
            name: "Éducation et gouvernement",
            icon: 'fa-fw fa-solid fa-school',
            categories: {
                college_or_university: "Université",
                courthouse: "Palais de justice",
                embassy: "Ambassade",
                fire_station_or_brigade: "Caserne de pompiers",
                government_office: "Bureau gouvernemental",
                library: "Bibliothèque",
                military_installation: "Base militaire",
                native_reservation: "Réserve indigène",
                non_governmental_organization: "Organisation non gouvernementale",
                police_station: "Commissariat de police",
                post_office: "Bureau de poste",
                primary_resource_or_utility: "ressource primaire ou utilité",
                prison_or_correctional_facility: "Prison ou établissement correctionnel",
                public_amenity: "Équipement public",
                school: "École",
                traffic_service_center: "Centre de contrôle routier",
                transport_authority_or_vehicle_registration: "Autorité de transport ou immatriculation des véhicules",
                welfare_organization: "Aide sociale"
            }
        },
        {
            name: "Services de Santé",
            icon: 'fa-fw fa-solid fa-hospital',
            categories: {
                dentist: "Dentiste",
                doctor: "Médecin",
                emergency_room: "Salle d'urgence",
                emergency_medical_service: "Service médical d'urgence",
                health_care_service: "Service de santé",
                hospital: "Hôpital",
                pharmacy: "Pharmacie",
                veterinarian: "Vétérinaire"
            }
        },
        {
            name: "Loisirs",
            icon: 'fa-fw fa-solid fa-gamepad',
            categories: {
                community_center: "Centre communautaire",
                cultural_center: "Centre culturel",
                entertainment: "Divertissement",
                leisure_center: "Centre de loisirs",
                marina: "Port de plaisance",
                museum: "Musée",
                nightlife: "Vie nocturne",
                park_and_recreation_area: "Parc et aire de loisirs",
                theater: "Théâtre",
                trail_system: "Réseau de sentiers",
                winery: "vignoble",
                zoo_arboreta_and_botanical_garden: "Zoo, arboretum et jardin botanique",
                amusement_park: "Parc d'attractions",
                casino: "Casino",
                movie_theater: "Cinéma",
                nightclub: "Boîte de nuit",
                club_and_association: "Club et association"
            }
        },
        {
            name: "Hébergement",
            icon: 'fa-fw fa-solid fa-bed',
            categories: {
                camping_ground: "Terrain de camping",
                vacation_rental: "Location de vacances",
                hotel_or_motel: "Hôtel ou motel",
                residential_accommodations: "Hébergements résidentiels"
            }
        },
        {
            name: "Commerces",
            icon: 'fa-fw fa-solid fa-bag-shopping',
            categories: {
                department_store: "Grand magasin",
                market: "Marché",
                shop: "Magasin",
                shopping_center: "Centre commercial"
            }
        },
        {
            name: "Sports",
            icon: 'fa-fw fa-solid fa-tennis-ball',
            categories: {
                golf_course: "Terrain de golf",
                ice_skating_rink: "Patinoire",
                sports_center: "Centre sportif",
                stadium: "Stade",
                swimming_pool: "Piscine",
                tennis_court: "Court de tennis",
                water_sport: "Sport nautique",
                adventure_sports_venue: "Site de sports d'aventure",
            }
        },
        {
            name: "Tourisme",
            icon: 'fa-fw fa-solid fa-monument',
            categories: {
                tourist_attraction: "Attraction touristique",
                place_of_worship: "Lieu de culte",
                scenic_or_panoramic_view: "Vue panoramique",
                tourist_information_office: "Office de tourisme",
                beach: "Plage"
            }
        },
        {
            name: "Transport",
            icon: 'fa-fw fa-solid fa-taxi-bus',
            categories: {
                access_gateway: "Porte d'accès",
                airport: "Aéroport",
                checkpoint: "Poste de contrôle",
                ferry_terminal: "Terminal de ferry",
                frontier_crossing: "Poste frontière",
                helipad: "Hélisurface",
                mountain_pass: "Col de montagne",
                port_or_warehouse_facility: "Port ou installation d'entreposage",
                public_transportation_stop: "Arrêt de transport en commun",
                railroad_station: "Gare ferroviaire"
            }
        },
        {
            name: "Autres",
            icon: 'fa-fw fa-solid fa-list',
            categories: {
                geographic_feature: "Caractéristique géographique"
            }
        }
    ];
    const mapPoisCategories = mapPois.reduce((acc, item) => ({...acc, ... Object.fromEntries(
        Object.entries(item.categories).map(
            ([key, label]) => [key, { label, icon: item.icon }]
        )
    )}), {});

    const configurationUtilisateur = computed({
        get: () => ({
            darkMode: darkMode.value,
            mapStyle: mapStyleName.value,
            mapLayers: {...mapLayers.value},
        }),
        set: (data) => {
            if (data) {
                darkMode.value = data.darkMode;
                mapStyleName.value = data.mapStyle;
                mapLayers.value = data.mapLayers;
            } else {
                configurationUtilisateurLoaded = true;
            }
        }
    });

    let configurationUtilisateurLoaded = false;
    watch(configurationUtilisateur, () => {
        if (configurationUtilisateurLoaded) {
            Request.postJson(Router.generate('regulation-v2.configuration'), {
                configuration: configurationUtilisateur.value,
            });
        } else {
            configurationUtilisateurLoaded = true;
        }
    });

    const structures = ref([]);
    const vehicules = ref([]);
    const appareils = ref([]);

    const ressources = computed(() => [
        ...vehicules.value.filter(value => value.id in vehiculesById.value).map(value => {
            const regulation = vehiculesById.value[value.id];

            let variant;

            if (true) {
                variant = {
                    success: 'green',
                    warning: 'orange',
                    info: 'blue',
                    danger: 'red',
                }[regulation._etat.variant] ?? 'gray';
            } else {
                variant = value.position?.segment?.enMouvement ? 'red' : 'green';
            }

            return {
                key: 'vehicule_'+value.id,
                type: 'vehicule',
                geolocalisation: value,
                variant,
                notifications: notificationsByRessource.value.vehicule[value.id] ?? [],
                messages: messagesByRessourceId.value.vehicule[value.id] ?? [],
                regulation,
            };
        }),
        // ...appareils.value.map(value => ({type: 'appareil', value, notifications: notificationsByRessource.value.appareil[value.id] ?? [], messages: messagesByRessourceId.value.appareil[value.id] ?? [], })),
    ]);

    const visibleRessources = ref([]);

    const activeRessourcePopupKey = ref(null);
    const activeRessourcePopup = computed({
        get() {
            if (activeRessourcePopupKey.value === null) {
                return null;
            }

            return ressourcesByKey.value[activeRessourcePopupKey.value] ?? null;
        },
        set(value) {
            if(value === null) {
                activeRessourcePopupKey.value = null;
            } else {
                activeRessourcePopupKey.value = value.key;
            }
        }
    });


    const activeRessourcePopupTab = ref('ressource');

    watch(activeRessourcePopup, (oldRessource, newRessource) => {
        if (oldRessource?.key !== newRessource?.key) {
            activeRessourcePopupTab.value = 'ressource';
        }
    })

    const activeRessourceHoverKey = ref(null);
    const activeRessourceHover = computed({
        get() {
            if (activeRessourceHoverKey.value === null) {
                return null;
            }

            return ressourcesByKey.value[activeRessourceHoverKey.value] ?? null;
        },
        set(value) {
            if(value === null) {
                activeRessourceHoverKey.value = null;
            } else {
                activeRessourceHoverKey.value = value.key;
            }
        }
    });

    const activeRessource = computed(() => activeRessourcePopup.value ?? activeRessourceHover.value);
    const refreshing = ref(false);

    const refresh = () => {
        if (!structureCollection.value.length) {
            vehicules.value = [];
            return;
        }

        refreshing.value = true;
        Request.getJson(Router.generate('regulation-v2.geolocalisation', {
            geolocalisation_filter: {
                structureCollection: structureCollection.value,
            },
        })).then(data => {
            vehicules.value = data.vehicules;
            refreshing.value = false;
        });
    };

    const refreshThrottled = _.throttle(refresh, 2000);
    const refreshDebounced =_.debounce(refresh, 500);

    watch(structureCollection, refreshDebounced, {deep: true});


    const ressourceToFeature = (ressource) => ({
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [ressource.geolocalisation.position.longitudeDegre, ressource.geolocalisation.position.latitudeDegre],
        },
        properties: {
            key: ressource.key,
            type: ressource.type,
            id: ressource.geolocalisation.id,
        },
    });

    const ressourcesGeoJson = computed(() => ({
        type: 'FeatureCollection',
        features: ressources.value.filter(ressource => ressource.geolocalisation.position).map(ressource => ressourceToFeature(ressource)),
    }));

    const ressourcesByIdByType = computed(() => ressources.value.reduce((res, ressource) => (res[ressource.type][ressource.geolocalisation.id] = ressource, res), {vehicule: {}, appareil: {}}));
    const ressourcesByKey = computed(() => ressources.value.reduce((res, ressource) => (res[ressource.key] = ressource, res), {}));

    const structuresGeoJson = computed(() => ({
        type: 'FeatureCollection',
        features: structures.value.filter(structure => structure.longitudeDegre && structure.latitudeDegre).map(structure => ({
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [structure.longitudeDegre, structure.latitudeDegre],
            },
            properties: {
                id: structure.id,
                active: structureCollection.value.includes(structure.id),
                text: structure.code ?? (structure.libelle.length > 5 ? structure.libelle.split(' ').map(word => word[0]).join('') : structure.libelle),
            },
        })),
    }));
    const activeStructuresGeoJson = computed(() => ({
        type: 'FeatureCollection',
        features: structuresGeoJson.value.features.filter(feature => feature.properties.active),
    }));

    const mapFallbackBounds = [
        {lng: -15.717417675741132, lat: 42.188567604195384},
        {lng: 19.29459694617117, lat: 51.76181003108451},
    ];

    const structuresGeoJsonInit = ref(false);
    const mapBounds = computed(() => null === mapCenter.value && structuresGeoJsonInit.value ? (activeStructuresGeoJson.value.features.length > 1 ? bbox(activeStructuresGeoJson.value) : (structuresGeoJson.value.features.length > 1 ? bbox(structuresGeoJson.value) : mapFallbackBounds)) : undefined);
    watch(structuresGeoJson, () => {
        if (null === mapCenter.value) {
            resetMapCenter();
        }
        structuresGeoJsonInit.value = true;
    }, {once: true});

    const resetMapCenter = () => {
        if (activeStructuresGeoJson.value.features.length === 1) {
            const center = activeStructuresGeoJson.value.features[0].geometry.coordinates;
            mapCenter.value = {lng: center[0], lat: center[1]};
        } else {
            mapCenter.value = null;
        }
    };

    // Recherche
    const searchGroups = {
        historique: {
            label: 'Récents',
            textClass: 'text-slate-500',
            bgClass: 'bg-slate-500',
        },
        organisme: {
            label: 'Organismes',
            textClass: 'text-orange-500',
            bgClass: 'bg-orange-500',
        },
        adresse: {
            label: 'Adresses',
            textClass: 'text-indigo-500',
            bgClass: 'bg-indigo-500',
        },
    };

    const searchResults = ref({});
    const searchResultsGroupped = computed(() => {
        const res = [];
        let number = 1;

        for (const key in searchGroups) {
            const items = searchResults.value[key] ?? [];

            if (items.length) {
                const itemsBySection = items.reduce((res, item) => ((res[item._section ?? ''] = res[item._section ?? ''] || []).push(item), res), {});
                let first = true;

                for (const section in itemsBySection) {
                    for (const item of itemsBySection[section]) {
                        item.number = number++;
                    }

                    const group = {
                        key: key+'_'+section,
                        section,
                        items: itemsBySection[section],
                    };

                    if (first) {
                        group.group = searchGroups[key];
                        first = false;
                    }
                    res.push(group);
                }
            }
        }

        return res;
    });
    const searchQuery = ref('');
    const searchLoadingAdresse = ref(false);
    const searchLoadingOrganisme = ref(false);
    const searchLoading = computed(() => searchLoadingAdresse.value && searchLoadingOrganisme.value);
    const searchInput = ref();

    const searchActiveResult = ref(null);

    watch(searchActiveResult, () => {
        if (searchActiveResult.value) {
            const exists = searchHistory.value.indexOf(searchActiveResult.value);

            if (exists !== -1) {
                searchHistory.value.splice(exists, 1);
            }
            searchHistory.value.unshift(searchActiveResult.value);

            if (searchHistory.value.length > 5) {
                searchHistory.value.pop();
            }
        } else if ('' === searchQuery.value) {
            clearSearch();
        }
    });

    const searchFocusResult = computed(() => {
        if (searchFlatResults.value.length && searchInput.value) {
            return searchInput.value.visibleOptions[searchInput.value.focusedOptionIndex];
        }

        return null;
    });

    const searchFlatResults = computed(() => Object.values(searchResults.value).flat());
    const searchHistory = ref([]);

    const search = (event) => {
        if (event.query === '') {
            searchResults.value = {
                historique: searchHistory.value,
            };
        } else {
            searchResults.value = {};
            searchLoadingAdresse.value = true;
            searchLoadingOrganisme.value = true;

            Request.getJson(Router.generate('geocodage.ajax', {query: event.query, lat: mapCenter.value.lat, lon: mapCenter.value.lng})).then(data => {
                searchResults.value.adresse = data;
                searchLoadingAdresse.value = false;
            });
            Request.getJson(Router.generate('geocodage.ajax', {query: event.query, lat: mapCenter.value.lat, lon: mapCenter.value.lng, type: 'organisme', structure: structures.value[0].id})).then(data => {
                searchResults.value.organisme = data;
                searchLoadingOrganisme.value = false;
            });
        }
    }

    // todo cancel request on search/clear

    const clearSearch = () => {
        searchActiveResult.value = null;
        searchQuery.value = '';
        searchResults.value = {};
    }

    const searchPreviousCamera = ref(null);
    const searchActive = computed(() => searchQuery.value !== '' || searchFlatResults.value.length);

    const searchGeoJson = computed(() => ({
        type: 'FeatureCollection',
        features: searchFlatResults.value.map((result, index) => ({
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [result.longitude, result.latitude],
            },
            properties: {
                index
            },
        })),
    }));


    const debug = ref({
        mapPadding: false,
        pinNotificationInterval: true,
        forceDetachRessourcePopup: false,
    });

    // save / sync notifications in localstorage

    const notifications = ref([]);
    const notificationsByType = computed(() => notifications.value.reduce((res, notification) => ((res[notification.type] = res[notification.type] ?? []).push(notification), res), {}));
    const notificationsByRessource = computed(() => {
        return notifications.value.reduce((res, notification) => {
            const ressources = notification.ressources ?? [];

            for (const ressource of ressources) {
                if (!res[ressource.type][ressource.id]) {
                    res[ressource.type][ressource.id] = [];
                }
                res[ressource.type][ressource.id].push(notification);
            }

            return res;
        }, {
            vehicule: {},
            appareil: {},
            personnel: {},
        });
    });

    const clearNotifications = (items) => {
        _.remove(notifications.value, (notification => items.includes(notification)));
    };

    watch(messages, (newMessages, oldMessages) => {
        if (!initDataLoaded.value || sidebarTab.value === 'messagerie') {
            return;
        }

        const diff = groupMessagesByRessource(_.differenceBy(newMessages, oldMessages, 'id').filter(message => !message.estEmetteurRegulation));

        const messageRessources = {};

        for (const type in diff) {
            for (const id in diff[type]) {
                for (const message of diff[type][id]) {
                    if (!(message.id in messageRessources)) {
                        messageRessources[message.id] = [];
                    }
                    messageRessources[message.id].push({id, type});
                }
            }
        }

        for (const id in messageRessources) {
            const message = messagesById.value[id];

            notifications.value.push({
                type: 'messagerie',
                ressources: messageRessources[id],
                text: message.message,
                icon: 'fa-message',
            });
        }
    });


    const ressourcesShowAllTraces = ref(false);
    const ressourcesShowAllTrajets = ref(false);

    const precisions = {
        '0': {
            severity: 'secondary',
            title: Translator.trans('libelle.precision.aucune')
        },
        '-2': {
            severity: 'primary',
            title: Translator.trans('libelle.precision.auto')
        },
        '-1': {
            severity: 'dark',
            title: Translator.trans('libelle.precision.insee')
        },
        '1': {
            severity: 'danger',
            title: Translator.trans('libelle.precision.faible')
        },
        '2': {
            severity: 'warn',
            title: Translator.trans('libelle.precision.moyenne')
        },
        '3': {
            severity: 'success',
            title: Translator.trans('libelle.precision.bonne')
        },
    };

    const rulerActive = ref(false);

    onMounted(() => {
        App.webSocket.subscribe('regulation/v2/geolocalisation', (uri, data) => {
            if (data.action === 'refresh') {
                refreshThrottled();
            }
        });
    })

    const mapContextMenuLngLat = ref(null);

    return {
        rulerActive,
        precisions,
        mapCenter,
        mapZoom,
        mapBounds,
        mapVisible,
        mapDarkMode,
        ressourcesShowAllTraces,
        activeRessourcePopupTab,
        ressourcesShowAllTrajets,
        tab,
        sidebarTab,
        search,
        clearSearch,
        searchActive,
        searchPreviousCamera,
        searchGeoJson,
        searchResults,
        searchResultsGroupped,
        searchQuery,
        searchHistory,
        searchFlatResults,
        searchInput,
        searchActiveResult,
        searchFocusResult,
        searchLoading,
        darkMode,
        mapStyles,
        mapStyle,
        mapLayers,
        mapPois,
        mapPoisCategories,
        structures,
        vehicules,
        appareils,
        ressources,
        visibleRessources,
        activeRessource,
        activeRessourcePopup,
        activeRessourceHover,
        ressourcesGeoJson,
        ressourcesByIdByType,
        ressourcesByKey,
        structuresGeoJson,
        debug,
        ressourceToFeature,
        notificationsByType,
        notificationsByRessource,
        clearNotifications,
        roles,
        refresh,
        refreshing,
        resetMapCenter,
        mapInitialZoom,
        isDesktop,
        configurationUtilisateur,
        mapContextMenuLngLat
    };
});