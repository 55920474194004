<template>
    <div class="input-group">
        <template>
            <div class="input-sm d-none"></div>
            <div class="input-group-prepend">
                <span class="input-group-text">Patient</span>
            </div>
        </template>
        <form-input :disabled="disabled" ref="inputNom" :class="{'input-sm': true, 'border-theme': !outlined && value.id, 'border-right-0': value.id || (outlined && 'prenomUsuel' in value), 'border-primary': outlined && 'nomUsuel' in value}" :value="value.nomUsuel" @reset="onReset('nomUsuel')" @input="onInputChange('input', 'nomUsuel', $event)" @change="onInputChange('change', 'nomUsuel', $event)" placeholder="Nom" title="Nom"></form-input>
        <form-input :disabled="disabled" ref="inputPrenom" :class="{'input-sm': true, 'border-theme': !outlined && value.id, 'border-primary': outlined && 'prenomUsuel' in value}" :value="value.prenomUsuel" @reset="onReset('prenomUsuel')" @input="onInputChange('input', 'prenomUsuel', $event)" @change="onInputChange('change', 'prenomUsuel', $event)" placeholder="Prénom" title="Prénom"></form-input>
        <div class="input-group-append">
            <a v-if="value.id" class="btn d-flex align-items-center" :class="outlined ? 'btn-primary' : 'btn-theme'" title="Voir le patient" :href="path('shared.patient.view', {app :'facturation', id: value.id})" target="_blank">
                <i class="im-link mb-1"></i>
            </a>
            <button v-if="$root.patient && $root.patient.id !== value.id" @click="setPatient($root.patient)" type="button" class="btn d-flex align-items-center btn-outline-info" title="Patient du trajet différent du patient de la facture. Cliquez pour récupérer le patient de la facture.">
                <i class="fa-solid fa-circle-info"></i>
            </button>
        </div>
    </div>
</template>

<script>
    import Bloodhound from "bloodhound-js";

    export default {
        inheritAttrs: false,
        props: ['value', 'outlined', 'disabled'],
        watch: {
            value() {
                $(this.$refs.inputPrenom.$el).typeahead('val', this.value.prenomUsuel);
                $(this.$refs.inputNom.$el).typeahead('val', this.value.nomUsuel);
            }
        },
        mounted() {
            let config =  {
                minLength: 2,
                highlight: true,
                hint: false,
                tabAutocomplete: false,
            };
            const inputs = {prenomUsuel: this.$refs.inputPrenom.$el, nomUsuel: this.$refs.inputNom.$el};

            for(let field in inputs) {
                $(inputs[field])
                    .typeahead(config, {
                        source: new Bloodhound({
                            datumTokenizer: Bloodhound.tokenizers.whitespace,
                            queryTokenizer: Bloodhound.tokenizers.whitespace,
                            remote: {
                                url: '#',
                                prepare: (query, settings) => {
                                    settings.url = this.path('facturation.facture.trajet.patient.ajax', {
                                        structure: App.Shared.getStructure(),
                                        query
                                    })
                                    return settings;
                                },
                                cache: false
                            },
                        }),
                        templates: {
                            suggestion: App.Utils.getAutocompleteTemplate('patient', true),
                            pending: () => '<div class="tt-suggestion text-muted">Recherche en cours…</div>',
                            notFound: '<div></div>',
                        },
                        limit: 400,
                        displayKey: field,
                    })
                    .on('typeahead:select', (e, data) => {
                        this.$emit('change', {
                            id: data.id,
                            prenomUsuel: data.prenomUsuel,
                            nomUsuel: data.nomUsuel,
                        });
                    })
                ;
            }
        },
        methods: {
            setPatient(patient) {
                this.$emit('change', {id: patient.id, prenomUsuel: patient.prenomUsuel, nomUsuel: patient.nomUsuel});
            },
            onInputChange(event, field, data) {
                if('input' === event && this.value.id) {
                    this.$emit('change', {...this.value, [field]: data, id: null});
                } else {
                    this.$emit(event, { ...this.value, [field]: data})
                }
            },
            onReset(field) {
                if((this.value[field] === '' || this.value[field] === undefined)) {
                    this.$emit('reset', field);
                }
            }
        }
    }
</script>