'use strict';

App.Administration.Job = class {};

App.Administration.Job.View = class {
    constructor(params) {
        for(let field of ['output', 'errorOutput']) {
            this._scrollBottom(document.getElementById(field));
        }

        App.webSocket.subscribe('administration/job/'+params.jobId, (uri, data) => {
            if(data.type === 'data') {
                let $el = $('#'+data.field);
                $el.append($('<span class="job-output-flash"></span>').text(data.chunk));
                this._scrollBottom($el[0]);
            }
        });
    }

    _scrollBottom(el)
    {
        el.scrollTop = el.scrollHeight;
    }
};