'use strict';

App.Facturation.Grille = class {};

App.Facturation.Grille.Form = class {
    constructor() {
        this._updateTabs();

        $('.tab-content').change('change', () => this._updateTabs());

        $('.js-embed-add-detail').on('embed.new', (e, $el) => this._focusLastTab($el.parents('.js-detail-form').find('.js-detail-tabs')));
    }

    _focusLastTab($tabs) {
        $tabs.find('li:last-child a').click();
    }

    _updateTabs() {
        $('.js-detail-form').each((i, el) => {
            const $form = $(el);
            const $tabs = $form.find('.js-detail-tabs');

            $tabs.html('');
            let hasActive = false;
            let $previousTab = null;

            $form.find('.js-detail-tab').each((i, el) => {
                const $el = $(el);
                const debut = $el.find('[data-field="debut"]').val();
                const fin = $el.find('[data-field="fin"]').val();
                const active = $el.hasClass('active');

                if (active) {
                    hasActive = true;
                }

                $tabs.append('<li class="nav-item"><a href="#'+el.id+'" class="nav-link'+(active ? ' active':'')+'" data-toggle="pill">'+(debut ? debut+' <i class="fa-light fa-circle-small"></i>':'')+'<i class="fa-light fa-dash"></i>'+(fin ? '<i class="fa-light fa-circle-small"></i> '+fin : '')+'</a></li>');

                if ($previousTab) {
                    $el.find('[data-field]').each((i, field) => {
                        const $field = $(field);
                        const $previousField = $previousTab.find('[data-field="'+$field.data('field')+'"]')
                        const diffValue = $previousField.val() !== $field.val();

                        $field.toggleClass('text-info', diffValue);
                        $field.tooltip('dispose');
                        if (diffValue) {
                            $field.tooltip({
                                title: 'Avant : '+$previousField.val(),
                            });
                        }
                    });
                }

                $previousTab = $el;
            });

            if (!hasActive) {
                this._focusLastTab($tabs);
            }
        });
    }
}
