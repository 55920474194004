<template>
    <div ref="content">
        <slot></slot>
    </div>
</template>

<script setup>
import {inject, ref, onMounted, onUnmounted} from "vue";
import {Popup} from 'maplibre-gl';

const content = ref();
const id = inject('id');
const map = inject('map');

const popup = ref(null);

const onEnter = (e) => {
    map.value.getCanvas().style.cursor = 'pointer';
    popup.value.setLngLat(e.lngLat).addTo(map.value);
};

const onMove = (e) => {
    popup.value.setLngLat(e.lngLat);
};

const onLeave = () => {
    map.value.getCanvas().style.cursor = '';
    popup.value.remove();
};

onMounted(() => {
    popup.value = new Popup({
        closeButton: false,
        closeOnClick: false,
    });
    popup.value.setDOMContent(content.value);

    map.value.on('mouseenter', id.value, onEnter);
    map.value.on('mousemove', id.value, onMove);
    map.value.on('mouseleave', id.value, onLeave);
});

onUnmounted(() => {
    map.value.off('mouseenter', id.value, onEnter);
    map.value.off('mousemove', id.value, onMove);
    map.value.off('mouseleave', id.value, onLeave);

    onLeave();
});

</script>