<template>
    <div class="flex gap-x-2 mb-2">
        <InputText v-model="searchQueryRessources" class="!py-1 w-full my-0.5" placeholder="Code" />
        <FilterButton v-model="modeRessources" :choices="{vehicule: {icon: 'fa-solid fa-cars', label: 'Véhicules'}}"/>
    </div>
    <OverlayScrollbarsComponent v-if="ressources.length" :options="{scrollbars: {autoHide: 'move'}}">
        <Accordion :multiple="true" :value="activeTabs" :key="modeRessources">
            <AccordionPanel v-for="(sectionRessources, sectionName) in ressourcesBySection" :key="sectionName" :value="sectionName" class="!border-0">
                <AccordionHeader class="!p-2 !bg-inherit">
                    <Section :title="sectionsRessources[sectionName].label" class="mr-2">
                        <template #badges>
                            <Badge v-for="(count, etat) in countRessourcesByEtatBySection[sectionName]" :key="etat" :severity="etatsRessources[etat].variant" :title="etatsRessources[etat].title" :value="count"></Badge>
                        </template>
                    </Section>
                </AccordionHeader>
                 <AccordionContent pt:content="!p-2 !bg-inherit">
                     <div class="flex flex-col gap-y-2">
                         <Item @click="onRessourceClick(ressource)" :ressource="ressource" v-for="ressource in sectionRessources" :key="ressource.id"></Item>
                     </div>
                </AccordionContent>
            </AccordionPanel>
        </Accordion>
    </OverlayScrollbarsComponent>
    <EmptyPlaceholder v-else title="Aucune ressource" description="Aucune ressource ne correspond aux filtres sélectionnés." icon="im-link"></EmptyPlaceholder>
</template>

<script setup>
import InputText from "primevue/inputtext";
import {useRegulationStore} from "@/Vue/Stores/Regulation";
import {useGeolocalisationStore} from "@/Vue/Stores/RegulationV2/Geolocalisation";
import {storeToRefs} from "pinia";
import EmptyPlaceholder from "@/Vue/Components/RegulationV2/Layout/EmptyPlaceholder";
import Accordion from 'primevue/accordion';
import AccordionPanel from 'primevue/accordionpanel';
import AccordionHeader from 'primevue/accordionheader';
import AccordionContent from 'primevue/accordioncontent';
import {OverlayScrollbarsComponent} from "overlayscrollbars-vue";
import {computed, onMounted} from 'vue'
import Badge from 'primevue/badge';
import Item from "@/Vue/Components/RegulationV2/Sidebar/Ressources/Item";
import Section from "@/Vue/Components/RegulationV2/Layout/Section.vue";
import FilterButton from "@/Vue/Components/RegulationV2/Layout/FilterButton.vue";

const geolocalisationStore = useGeolocalisationStore();
const regulationStore = useRegulationStore();
const {searchQueryRessources, modeRessources, ressources, ressourcesBySection, countRessourcesByEtatBySection, rendezVousIsToday} = storeToRefs(regulationStore);
const {activeRessourcePopup, ressourcesByIdByType} = storeToRefs(geolocalisationStore);
const {sectionsRessources, etatsRessources} = regulationStore;

const activeTabs = computed(() => {
    const res = [];

    for (const sectionName in ressourcesBySection.value) {
        if (sectionsRessources[sectionName].visible) {
            res.push(sectionName);
        }
    }

    return res;
});
const onRessourceClick = (ressource) => {
    // TODO
    if (ressource._type === 'vehicule') {
        activeRessourcePopup.value = ressourcesByIdByType.value[ressource._type][ressource.id] ?? null;
    }
};

// modes mixte/equipage non géré
onMounted(() => {
    if (modeRessources.value !== 'vehicule' && modeRessources.value !== 'personnel') {
        modeRessources.value = 'vehicule';
    }
})
</script>