<template>
    <Route id="trajet" mode="ant" :ant-speed="50" autoFit :fitOptions="{animate: false, padding: 20}" color="#3b82f6" :steps="steps"></Route>
    <Pin :variant="item.estDisponible ? 'green' : 'red'" v-for="item in positions"
         :lng-lat="[item.longitude, item.latitude]"
         clickable @dblclick.stop="emit('select-vehicule', item.id)"
    >
        <template #content>
            <Text :style="{color: item.couleur}" :value="item.shortText" :title="item.longText"></Text>
        </template>
        <template #contentHover>
            <i class="fa-solid" :class="[item.classeIcone, item.classeCouleur]"></i>
        </template>
    </Pin>
</template>

<script setup>
import Pin from "@/Vue/Components/RegulationV2/Map/Base/Pin.vue";
import Text from "@/Vue/Components/RegulationV2/Map/Base/Pin/Text.vue";
import {inject} from 'vue';
import Route from "@/Vue/Components/RegulationV2/Map/Base/Route.vue";

const emit = defineEmits(['select-vehicule']);

const steps = inject('steps');
const positions = inject('positions');
</script>