(function ($) {

    $.fn.treeSelect = function(options) {
        let settings = $.extend({
            type: 'generic',
        }, options);

        let messages = {
            'generic': {
                items_selected_short_single: 'élément',
                items_selected_long_single: 'élément sélectionné',
                items_selected_short_multiple: 'éléments',
                items_selected_long_multiple: 'éléments sélectionnés',
                items_checkbox_required_multiple: 'Au moins un élément doit être sélectionné'
            },
            'structure': {
                items_selected_short_single: 'structure',
                items_selected_long_single: 'structure sélectionnée',
                items_selected_short_multiple: 'structures',
                items_selected_long_multiple: 'structures sélectionnées',
                items_checkbox_required_multiple: 'Au moins une structure doit être sélectionnée'
            },
            'bureau': {
                items_selected_short_single: 'bureau',
                items_selected_long_single: 'bureau sélectionné',
                items_selected_short_multiple: 'bureaux',
                items_selected_long_multiple: 'bureaux sélectionnés',
                items_checkbox_required_multiple: 'Au moins un bureau doit être sélectionné'
            },
            'societe': {
                items_selected_short_single: 'société',
                items_selected_long_single: 'société sélectionnée',
                items_selected_short_multiple: 'sociétés',
                items_selected_long_multiple: 'sociétés sélectionnées',
                items_checkbox_required_multiple: 'Au moins une société doit être sélectionnée'
            },
        };

        let $selector = $(this);

        function getMessage(key) {
            return messages[settings.type][key];
        }

        function getTree(array) {
            let levels = [{}];

            array.forEach(function (a) {
                levels.length = a.level;
                levels[a.level - 1].nodes = levels[a.level - 1].nodes || [];
                levels[a.level - 1].nodes.push(a);
                levels[a.level] = a;
            });

            return levels[0].nodes;
        }

        function updateCheckboxRequired(name) {
            let $checkboxs = $('input[name^="' + name + '"]');
            $checkboxs.prop('required', !$checkboxs.is(':checked'));
            $checkboxs.each(function(index, element) {
                element.setCustomValidity(!$checkboxs.is(':checked') ? getMessage('items_checkbox_required_multiple') : '');
            });
        }

        $selector.each(function() {
            let $elem = $(this);
            let name = $elem.attr('name');
            let value = $elem.val();
            let expanded = $elem.data('expanded');
            let societeUniqueGroupeSansPartage = $elem.data('treeselectSocieteUniqueGroupeSansPartage');
            let id = $elem.attr('id');
            let multiple = $elem.is('[multiple]');
            let required = $elem.data('required');
            let data = [];
            let offset = null;
            let placeholder = false;
            let $clearBtn = false;
            let small = $elem.hasClass('tree-select-small');
            let invalid = $elem.hasClass('is-invalid');

            const LEVEL_GROUPE = 1;
            const LEVEL_SOCIETE = 2;
            const LEVEL_BUREAU = 3;

            let bureauxOnly = true;

            $elem.find('option').each(function(){
                let $e = $(this);
                let dataAttr = $.extend({}, this.dataset);
                delete dataAttr.level;
                delete dataAttr.label;

                if($e.val() === '' && required) {
                    $e.remove();
                } else if($e.val() === '') {
                    placeholder = $e.text();
                } else {
                    let level = $(this).data('level') + 1;
                    if(offset === null) {
                        offset = level - 1;
                    }

                    data.push({
                        label: $e.data('label'),
                        racinePartage: $e.data('racinePartage'),
                        value: $e.val(),
                        selected: $e.is(':selected'),
                        disabled: $e.is(':disabled'),
                        level: level - offset,
                        dataset: dataAttr
                    });
                }
            });

            let $container = $('<div class="tree-select"></div>');
            $container.attr('id', id);
            if(invalid) $container.addClass('is-invalid');
            if(small) $container.addClass('tree-select-small');

            if(data.length) {
                let tree = getTree(data);

                let updateButtonLabel = function () {
                    if(expanded) {
                        return;
                    }

                    let $label = $button.find('.tree-select-btn-label');
                    $label.html('');

                    let label = '';
                    let i = 0;
                    $rootTree.find('input[data-action="input"]:checked').each(function () {
                        let text = $(this).parent().parent().find('label').text();

                        if (multiple) {
                            let $item = $('<span class="tree-select-btn-label-item"></span>');
                            $item.text(text);

                            let $remove = $('<span>×</span>');
                            $remove.click((e) => {
                                $(this).prop('checked', false).trigger('change');

                                e.stopPropagation();
                            });

                            $item.prepend($remove);
                            $label.append($item);
                        } else {
                            $label.text(text);
                        }
                        i++;
                    });

                    if (i === 0) {
                        if (placeholder) {
                            label = placeholder;
                        } else {
                            label = '...';
                        }
                        $label.text(label);
                    }

                    if ($label.width() > $button.width()) {
                        let prefix = (i > 1) ? 'multiple' : 'single';

                        if ($button.width() > 160) {
                            $label.text(i + ' ' + getMessage('items_selected_long_' + prefix));
                        } else {
                            $label.text(i + ' ' + getMessage('items_selected_short_' + prefix));
                        }
                    }

                    if ($clearBtn) {
                        if (i === 0) {
                            $clearBtn.hide();
                        } else {
                            $clearBtn.show();
                        }
                    }
                };

                let refreshToggleCheckboxes = function () {
                    $rootTree.find('input[data-action="toggle"]').each(function () {
                        let count = $(this).parent().parent().parent().find('input[data-action="input"]:checkbox:not(":checked")').length;
                        let countTotal = $(this).parent().parent().parent().find('input[data-action="input"]:checkbox').length;

                        if (count === 0) {
                            $(this).prop('checked', true);
                            $(this).prop('indeterminate', false);
                        }
                        else if (count !== countTotal) {
                            $(this).prop('indeterminate', true);
                        } else {
                            $(this).prop('checked', false);
                            $(this).prop('indeterminate', false);
                        }
                    });
                };

                let uncheckAll = function () {
                    $rootTree.find('input[data-action="input"]:checked').prop('checked', false);
                }

                let uncheckAllExceptChildrenOfSociete = function (node) {
                    $rootTree.find('.tree-select-group[data-level="'+LEVEL_SOCIETE+'"]').not(node).find('input[data-action="input"]').prop('checked', false);
                }

                let uncheckAllExceptChildrenOfGroupe = function (node) {
                    $rootTree.find('.tree-select-group[data-level="'+LEVEL_GROUPE+'"]').not(node).find('input[data-action="input"]').prop('checked', false);
                }

                let disableAllExceptChildrenOfGroupe = function (node) {
                    $rootTree.find('.tree-select-group[data-level="'+LEVEL_GROUPE+'"]').not(node).find('input[data-action="input"]').prop('disabled', true);
                }

                let setEnabledAll = function (value) {
                    $rootTree.find('input[data-action="input"]').each(function () {
                        if (doesGroupeParentHasDonneesPartagees($(this), this) === 'true') {
                            $(this).prop('disabled', false);
                        } else {
                            $(this).prop('disabled', !value);
                        }
                    });
                }

                let enableAllChildrenOf = function ($node) {
                    $node.find('input[data-action="input"]').prop('disabled', false);
                }

                let checkAllChildrenOf = function ($node) {
                    $node.find('input[data-action="input"]').prop('checked', true).change();
                }

                let areNoneChecked = function () {
                    return 0 === $rootTree.find('input[data-action="input"]:checked').length;
                }

                let getParentOfStructure = function ($item) {
                    return $item.parent().parent().parent()
                }

                let getGroupeParentOf = function ($item) {
                    let groupeParent = $item;
                    while (groupeParent[0] !== undefined && groupeParent[0].dataset['level'] !== LEVEL_GROUPE.toString()) {
                        groupeParent = groupeParent.parent();
                    }
                    return groupeParent;
                }

                let doesGroupeParentHasDonneesPartagees = function($item, item) {
                    let donneesPartagees = item.donneesPartagees;
                    if (donneesPartagees === undefined) {
                        donneesPartagees = item.dataset['donneesPartagees'];
                    }
                    if (donneesPartagees === undefined) {
                        if(item.level !== LEVEL_GROUPE) {
                            let groupeParent = $item[0];
                            while (groupeParent.dataset['level'] !== LEVEL_GROUPE.toString()) {
                                groupeParent = groupeParent.parentNode
                            }
                            item.dataset['donneesPartagees'] = (groupeParent.dataset['racinePartage'] === 'true');
                            donneesPartagees = item.dataset['donneesPartagees'];
                        } else {
                            item.donneesPartagees = item.racinePartage;
                            donneesPartagees = item.donneesPartagees;
                        }
                    }

                    return donneesPartagees;
                }

                let i = 0;
                let renderTree = function (subTree) {
                    subTree = Array.isArray(subTree) ? subTree : [subTree];
                    let $tree = $('<div class="tree-select-tree"></div>');

                    bureauxOnly = subTree.filter(obj => {return obj.level === 1 }).length === subTree.length;

                    subTree.forEach(function (item) {
                        i++;

                        let $group = $('<div class="tree-select-group" data-level="'+item.level+'" data-racine-partage="' + item.racinePartage + '"></div>');
                        let $item = $('<div class="tree-select-item"></div>');
                        let $label = $('<label for="' + id + '_' + i + '"></label>');
                        $label.text(item.label);

                        $item.css('padding-left', (item.level - 1) * 15 + 10 + 'px');
                        $item.click(function (e) {
                            if (!bureauxOnly) {
                                if (societeUniqueGroupeSansPartage && !doesGroupeParentHasDonneesPartagees($item, item)) {
                                    if (item.level === LEVEL_GROUPE) {
                                        uncheckAll();
                                        setEnabledAll(true);
                                        updateButtonLabel();
                                        refreshToggleCheckboxes();
                                        e.stopPropagation();
                                        return;
                                    } else if (item.level === LEVEL_SOCIETE) {
                                        uncheckAll();
                                    }
                                } else if (societeUniqueGroupeSansPartage && doesGroupeParentHasDonneesPartagees($item, item)) {
                                    let groupeParent = $item;
                                    if (item.level === LEVEL_BUREAU) {
                                        groupeParent = getGroupeParentOf($item)[0]
                                    } else if (item.level === LEVEL_SOCIETE) {
                                        groupeParent = getGroupeParentOf($item)[0]
                                    }
                                    uncheckAllExceptChildrenOfGroupe(groupeParent)
                                    setEnabledAll(false)
                                    disableAllExceptChildrenOfGroupe(groupeParent)
                                }
                            }

                            if (e.target === this) {
                                let labelId = '#' + $(this).find('> label').attr('for');
                                let input = $(labelId);
                                input.click();
                            }
                            if (multiple) {
                                e.stopPropagation();
                            }

                            if (!bureauxOnly) {
                                if (societeUniqueGroupeSansPartage && !doesGroupeParentHasDonneesPartagees($item, item)) {
                                    if (areNoneChecked()) {
                                        setEnabledAll(true);
                                    } else {
                                        setEnabledAll(false);
                                        if (item.level === LEVEL_SOCIETE) {
                                            enableAllChildrenOf($item.parent());
                                        } else if (item.level === LEVEL_BUREAU) {
                                            let societeParentOfClickedBureau = getParentOfStructure($item);
                                            uncheckAllExceptChildrenOfSociete(societeParentOfClickedBureau);
                                            enableAllChildrenOf(societeParentOfClickedBureau);
                                        }
                                    }
                                }
                            } else {
                                setEnabledAll(true);
                            }
                        });

                        if (item.nodes && item.nodes.length && multiple && item.disabled) {
                            let toggleId = item.disabled ? id + '_' + i : id + '_' + i + '_toggle';
                            let $toggleCheckbox = $('<div class="checkbox checkbox-custom d-none"><label for="' + toggleId + '"></label></div>');

                            let $itemSelect = $('<input data-action="toggle" type="checkbox">');

                            $itemSelect.attr('id', toggleId);
                            $itemSelect.change(function () {
                                $group.find('input').prop('checked', this.checked).last().change();
                                refreshToggleCheckboxes();
                                updateButtonLabel();

                                if (societeUniqueGroupeSansPartage && doesGroupeParentHasDonneesPartagees($item, item)) {
                                    setEnabledAll(areNoneChecked());
                                    if (item.level === LEVEL_SOCIETE) {
                                        enableAllChildrenOf($item.parent());
                                    }
                                }
                            });

                            $toggleCheckbox.prepend($itemSelect);
                            $item.append($toggleCheckbox);
                            $item.addClass('toggle');
                        }

                        if(multiple && (LEVEL_GROUPE !== item.level || doesGroupeParentHasDonneesPartagees($item, item))) {
                            $item.dblclick(function () {
                                uncheckAll();

                                checkAllChildrenOf($item.parent())
                                updateButtonLabel();
                                refreshToggleCheckboxes();
                            });
                        }

                        if (!item.disabled || item.selected) {
                            let type = multiple ? 'checkbox' : 'radio';
                            let $inputCheckbox = $('<div class="' + type + ' ' + type + '-info"><label for="' + id + '_' + i + '"></label></div>');

                            let $itemInput = $('<input data-action="input" type="' + type + '" id="' + id + '_' + i + '" name="' + name + '" value="' + item.value + '">');
                            for (let key in item.dataset) {
                                $itemInput.data(key, item.dataset[key]);
                            }
                            if (item.selected) $itemInput.prop('checked', true);

                            $itemInput.change(function () {
                                if (invalid) {
                                    $container.removeClass('is-invalid');
                                    invalid = false;
                                }
                                if (multiple) {
                                    refreshToggleCheckboxes();
                                }
                                if (!expanded) {
                                    updateButtonLabel();
                                }
                                if (multiple && required) {
                                    updateCheckboxRequired(name);
                                }
                                if (!bureauxOnly) {
                                    if (societeUniqueGroupeSansPartage && !doesGroupeParentHasDonneesPartagees($item, item)) {
                                        if (areNoneChecked()) {
                                            setEnabledAll(true);
                                        } else {
                                            setEnabledAll(false);
                                            if (item.level === LEVEL_SOCIETE) {
                                                enableAllChildrenOf($item.parent());
                                            } else if (item.level === LEVEL_BUREAU) {
                                                let societeParentOfClickedBureau = $item.parent().parent().parent();
                                                uncheckAllExceptChildrenOfSociete(societeParentOfClickedBureau);
                                                enableAllChildrenOf(societeParentOfClickedBureau);
                                            }
                                        }
                                    }
                                }
                            });

                            if(multiple) {
                                $item.dblclick(function () {
                                    uncheckAll();
                                    $itemInput.prop('checked', true).change();

                                    enableAllChildrenOf($item.parent().parent().parent())
                                });
                            }

                            $inputCheckbox.prepend($itemInput);
                            $item.append($inputCheckbox);
                        } else {
                            $item.addClass('disabled');
                        }

                        $item.append($label);

                        $group.append($item);

                        if (item.nodes) {
                            $group.append(renderTree(item.nodes));
                        }

                        $tree.append($group);
                    });

                    return $tree;
                };

                let $rootTree = renderTree(tree);
                refreshToggleCheckboxes();

                if(!expanded) {
                    var $button = $('<div data-toggle="dropdown" class="tree-select-btn"><span class="tree-select-btn-label"></span><span class="tree-select-btn-arrow" role="presentation"><b role="presentation"></b></span></div>');

                    if(!required && !multiple) {
                        $clearBtn = $('<span class="tree-select-btn-clear">×</span>');
                        $clearBtn.click(function() {
                            $rootTree.find('input[type="radio"]:checked').prop('checked', false).change();
                            updateButtonLabel();

                            return false;
                        });

                        if(!value) {
                            $clearBtn.hide();
                        }
                        $button.append($clearBtn);
                    }

                    let $dropdownMenu = $('<div class="dropdown-menu"></div>');

                    $dropdownMenu.append($rootTree);

                    $container.append($button);
                    $container.append($dropdownMenu);

                    $(window).resize(function() {
                        updateButtonLabel();
                    });
                } else {
                    $container.addClass('expanded');
                    $container.append($rootTree);
                }

                $elem.replaceWith($container);

                updateButtonLabel();

                if(multiple && required) {
                    updateCheckboxRequired(name);
                }

            } else {
                $container.append('<div class="tree-select-btn disabled"><span class="tree-select-btn-label">Aucun élément</span></div>');

                $elem.replaceWith($container);
            }

        });

        $('body').trigger('treeselect.init');
    };

}(jQuery));
