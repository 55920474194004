<template>
    <div>
        <b-btn :id="id" variant="secondary" size="sm" class="day-counter" :disabled="total === 0">
            {{ total }}
        </b-btn>
        <Popover :target="id" placement="top" trigger="hover focus" v-if="total > 0">
            <template v-slot:title>
                <div class="text-center">Récapitulatif</div>
            </template>
            <div class="text-center">
                <span v-for="count in counts" :title="count.type.libelle">
                    <label class="tool-picker__item pointer-events-none m-2">
                        <span class="tool-picker__item__content" :style="'color: #'+count.type.couleurTexte+'; background-color:#'+count.type.couleur">
                            <span class="mt-1">{{ count.count }}</span>
                            <span class="tool-picker__item__label"> {{ count.type.code }}</span>
                        </span>
                    </label>
                </span>
            </div>
        </Popover>
    </div>
</template>

<script setup>
import {getCurrentInstance} from "vue";
import Popover from '@/Vue/Components/Popover';

const instance = getCurrentInstance();
const id = 'distancier_'+instance.uid;
defineProps(['total', 'counts']);
</script>