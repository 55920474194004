<template>
    <div class="position-relative tailwind" @wheel.stop :style="style">
        <Map v-if="hasMap" :config="{
            provider: 'tomtom',
            map: '2/basic_street-light',
            poi: '2/poi_light',
            trafficIncidents: '2/incidents_light',
            trafficFlow: '',
        }">
            <Layer @select-vehicule="emit('select-vehicule', $event)"></Layer>
        </Map>
        <div class="trajet-map-placeholder" :style="style" v-if="!hasMap">
            <i class="fa-regular fa-map trajet-map-placeholder-icon"></i>
            <div class="trajet-map-placeholder-title">Cartographie indisponible</div>
            <div class="trajet-map-placeholder-message">Trajet non géocodé</div>
        </div>
        <div class="trajet-map-overlay"></div>
    </div>
</template>

<script setup>
import Map from "@/Vue/Components/RegulationV2/Map/Base/Map.vue";
import {computed, onMounted, provide, ref, toRefs} from "vue";
import Layer from "@/Vue/Components/Trajet/Map/Layer.vue";

const emit = defineEmits(['select-vehicule']);
const props = defineProps(['height', 'structures', 'latitudeDegrePriseEnCharge', 'longitudeDegrePriseEnCharge', 'latitudeDegreDestination', 'longitudeDegreDestination']);

const {height, structures, latitudeDegrePriseEnCharge, longitudeDegrePriseEnCharge, latitudeDegreDestination, longitudeDegreDestination} = toRefs(props);

const style = computed(() => height.value ? {height: height.value} : {height: '100%', 'min-height': '150px'});

const hasMap = computed(() => {
    return latitudeDegrePriseEnCharge.value && longitudeDegrePriseEnCharge.value && latitudeDegreDestination.value && longitudeDegreDestination.value;
});

const positions = ref([]);
const steps = computed(() => [[latitudeDegrePriseEnCharge.value, longitudeDegrePriseEnCharge.value], [latitudeDegreDestination.value, longitudeDegreDestination.value]]);

provide('steps', steps);
provide('positions', positions);

onMounted(() => {
    if(structures.value) {
        App.webSocket.subscribe('shared/position', (uri, data) => {
            positions.value = data.positions;
        });
        App.webSocket.publish('shared/position', {
            structureCollection: structures.value,
        });
    }
})
</script>
