'use strict';

App.Regulation = class {
    static triggerLoader() {
        $('.js-trajets-loader').addClass('flip-loader');

        setTimeout(function() {
            $('.js-trajets-loader').removeClass('flip-loader');
        }, 1000);
    }
};
