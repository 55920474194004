'use strict';

App.String = class {
    static euro(number, withSymbol = true) {
        if(null === number) {
            return null;
        }

        const fixedNumber = String(number).replace(',', '.');

        if (withSymbol) {
            return Intl.NumberFormat('fr-FR', { style: 'currency', useGrouping: true, currency: 'EUR'}).format(fixedNumber);
        } else {
            return Intl.NumberFormat('fr-FR', { style: 'decimal', useGrouping: false, minimumFractionDigits: '2'}).format(fixedNumber);
        }
    }
};
