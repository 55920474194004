<template>
    <div class="rounded-md bg-white dark:bg-gray-900 flex overflow-hidden font-medium" @click="selectTrajetById(trajet.id)">
        <div class="p-1 truncate flex-1">
            <i class="fa-solid fa-mars"></i> {{ trajet.transport.patient.text }}
        </div>
        <div class="px-2 py-1 text-white rounded-l-md bg-slate-600 flex" :title="trajet.transport.motif.text">
            {{ trajet.transport.motif.abreviation }}
        </div>
    </div>
<!--    <div>-->
<!--        <div class="flex items-center">-->
<!--            <div class="size-[12px] rounded-full border-2 border-surface-300 bg-surface-0 dark:border-surface-700 dark:bg-surface-950"></div>-->
<!--            <div class="grow bg-surface-300 dark:bg-surface-700 h-[2px]"></div>-->
<!--            <div class="size-[12px] rounded-full border-2 border-surface-300 bg-surface-0 dark:border-surface-700 dark:bg-surface-950"></div>-->
<!--            <div class="grow bg-surface-300 dark:bg-surface-700 h-[2px]"></div>-->
<!--            <div class="size-[12px] rounded-full border-2 border-surface-300 bg-surface-0 dark:border-surface-700 dark:bg-surface-950"></div>-->
<!--            <div class="grow bg-surface-300 dark:bg-surface-700 h-[2px]"></div>-->
<!--            <div class="size-[12px] rounded-full border-2 border-surface-300 bg-surface-0 dark:border-surface-700 dark:bg-surface-950"></div>-->
<!--        </div>-->
<!--    </div>-->
</template>

<script setup>
import {useRegulationStore} from "@/Vue/Stores/Regulation";

const props = defineProps({
    trajet: Object,
});

const regulationStore = useRegulationStore();
const {selectTrajetById} = regulationStore;

</script>