'use strict';

import swal from "sweetalert2";
import Vue, {createApp} from "vue";
import Capitalize from '../Vue/Filters/Capitalize';
import Translator from "@/App/Translator";

App.Utils = class {
    static openVueSwal(component, props= {}, listeners = {}, width = '800px') {
        return new Promise((resolve) => {
            let vue;
            let propsStr = '';
            for (const prop in props) {
                propsStr += ' :' + prop + '="' + prop + '"';
            }

            const methods = {};

            let listenersStr = '';
            for (const event in listeners) {
                const method = 'on' + Capitalize(event);
                listenersStr += ' @' + event + '="' + method + '"';
                methods[method] = listeners[event];
            }

            swal({
                showConfirmButton: false,
                showCancelButton: false,
                width,
                onClose: () => {
                    if (vue) {
                        vue.unmount();
                    }
                },
                onOpen: () => {
                    vue = createApp({
                        template: '<component is="' + component + '"' + propsStr + listenersStr + '></component>',
                        data: () => props,
                        methods: methods,
                    }).use(App.Pinia);

                    resolve(vue.mount(swal.getContent().parentElement));
                },
            })
        });
    }

    static print(url, title = null) {
        return new Promise((resolve, reject) => {
            const oldTitle = document.title;

            function closePrint() {
                document.body.removeChild(this.__container__);
                document.title = oldTitle
            }

            function setPrint() {
                if (title) {
                    document.title = title;
                }

                resolve();

                this.contentWindow.__container__ = this;
                this.contentWindow.onbeforeunload = closePrint;
                this.contentWindow.onafterprint = closePrint;
                this.contentWindow.focus(); // Required for IE
                this.contentWindow.print();
            }

            const oHiddFrame = document.createElement("iframe");
            oHiddFrame.onload = setPrint;
            oHiddFrame.style.visibility = "hidden";
            oHiddFrame.style.position = "fixed";
            oHiddFrame.style.right = "0";
            oHiddFrame.style.bottom = "0";
            oHiddFrame.src = url;
            document.body.appendChild(oHiddFrame);
        });
    }

    static download(url, filename) {
        return new Promise((resolve, reject) => {
            let link = document.createElement('a');
            link.href = url;
            link.download = filename;
            link.dispatchEvent(new MouseEvent('click'));

            resolve();
        });
    }

    static submitForm($form) {
        $form[0].requestSubmit();
    }

    static toggleBtnGroupItem($el, value) {
        if($el.prop('checked') !== value) {
            if ($el.attr('type') === 'radio') {
                $el.parents('.btn-group').find('label.active').removeClass('active');
            }

            $el.prop('checked', value).change().parent().toggleClass('active', value);
        }
    }

    static setField(field, value, label, custom) {
        const $field = $('[data-field="'+field+'"]');

        if($field.is('select')) {
            if($field.val() !== value) {
                if ($field.hasClass('js-select2-autocomplete')) {
                    $field.html(new Option(label, value, false, false));
                } else {
                    $field.val(value);
                }
                if (custom) {
                    $field.data('custom', custom);
                }
                $field.change();
            }
        } else if($field.hasClass('btn-group')) {
            const $input = $field.find('input[value="'+value+'"]');
            const $firstInput = $field.find('input').first();
            const multiple = $firstInput.attr('type') === 'checkbox';

            if($input.length) {
                App.Utils.toggleBtnGroupItem($input, true);
            } else if(!multiple) {
                if($firstInput.val()) {
                    App.Utils.toggleBtnGroupItem($field.find('input:checked'), false);
                } else {
                    App.Utils.toggleBtnGroupItem($firstInput, true);
                }
            }
        }
    }

    static getAutocompleteTemplate(name, typeahead = false) {
        const templates = {
            categorie: function (item) {
                let undefined;
                if ($(item.element).data('description') !== undefined && $(item.element).data('description').length > 0) {
                    let abreviation = ($(item.element).data('abreviation') !== undefined && $(item.element).data('abreviation').length > 0 ? '[' + $(item.element).data('abreviation') + '] ' : '')
                    return $('<div class="select2-custom-item">' + item.text + '<span>' + abreviation + $(item.element).data('description') + '</span></div>');
                }
                return item.text;
            },
            caisse: function (item) {
                if (item.id) {
                    const regime = item.regime ? item.regime : 'Régime non précisé';
                    const organismeRattachement = item.organismeRattachement ? item.organismeRattachement.substring(0, 2) + ' ' + item.organismeRattachement.substring(2, 5) + (item.organismeRattachement.length > 5 ? ' ' + item.organismeRattachement.substring(5, 9) : '') : '-- ---';

                    const html = '<div>'
                               +     '<div class="d-flex align-items-center justify-content-between">'
                               +         '<small title="Régime">' + regime + '</small>'
                               +         '<span class="text-monospace text-nowrap" style="font-size:13px;" title="Organisme de rattachement">' + organismeRattachement + '</span>'
                               +     '</div>'
                               +     '<div class="d-flex align-items-center justify-content-between">'
                               +         '<b>' + item.text + '</b>'
                               +     '</div>'
                               + '</div>';

                    return $(html);
                }

                return item.text;
            },
            mutuelle: function (item) {
                if (item.id) {
                    const contratAbreviation = item.contratAbreviation ? item.contratAbreviation : null;
                    const contratLibelle = item.contratLibelle ? item.contratLibelle : 'Contrat non précisé';
                    const numero = item.numero ? item.numero : '---------';

                    const html = '<div>'
                               +     '<div class="d-flex align-items-center justify-content-between">'
                               +         '<small title="Contrat' + (contratAbreviation ? ' : ' + contratLibelle : '') + '">' + (contratAbreviation ? contratAbreviation : contratLibelle) + '</small>'
                               +         '<span class="text-monospace text-nowrap" style="font-size:13px;" title="Numéro">' + numero + '</span>'
                               +     '</div>'
                               +     '<div class="d-flex align-items-center justify-content-between">'
                               +         '<b>' + item.text + '</b>'
                               +     '</div>'
                               + '</div>';

                    return $(html);
                }

                return item.text;
            },
            patient: function (item) {
                if (item.id) {
                    const genreIcon = item.genreIcon ? item.genreIcon : 'fa-solid fa-question';
                    const numeroSecuriteSociale = item.numeroSecuriteSociale ? item.numeroSecuriteSociale.substring(0, 1) + ' ' + item.numeroSecuriteSociale.substring(1, 3) + ' ' + item.numeroSecuriteSociale.substring(3, 5) + ' ' + item.numeroSecuriteSociale.substring(5, 7) + ' ' + item.numeroSecuriteSociale.substring(7, 10) + ' ' + item.numeroSecuriteSociale.substring(10, 13) + (15 === item.numeroSecuriteSociale.length ? ' ' + item.numeroSecuriteSociale.substring(13, 15) : '') : '- -- -- -- --- --- --';
                    const naissance = item.naissance ? item.naissance : '--/--/----';
                    const naissanceApproximative = item.naissanceApproximative ? item.naissanceApproximative : null;
                    const present = item.present ? item.present : false;

                    const html = '<div class="d-flex align-items-center">'
                               +     '<div class="fa-2x pr-2">'
                               +         '<i class="fa-fw ' + genreIcon + '"></i>'
                               +     '</div>'
                               +     '<div class="flex-1">'
                               +         '<div class="d-flex align-items-center justify-content-between">'
                               +             '<small title="Numéro de sécurité sociale">' + numeroSecuriteSociale + '</small>'
                               +             '<span class="text-monospace text-nowrap" style="font-size:13px;" title="' + (naissanceApproximative ? 'Date de naissance approximative' : 'Date de naissance') + '">' + (naissanceApproximative ? naissanceApproximative : naissance) + '</span>'
                               +         '</div>'
                               +         '<div class="d-flex align-items-center justify-content-between line-height-1">'
                               +             '<div>'
                               +                '<b>' + item.nomUsuel + '</b> ' + (item.prenomUsuel || '')
                               +             '</div>'
                               +             (item.ville ? '<div style="margin-top: 1px" class="text-ellipsis max-width-78px"><small title="'+[item.adresse,[item.codePostal, item.ville].join(' ')].join('\n')+'">'+item.ville+'</small></div>' : '')
                               +             (present ? '<i class="fa-regular fa-list" title="Présent dans la liste"></i>' : '')
                               +         '</div>'
                               +     '</div>'
                               + '</div>';

                    return $(html);
                }

                return item.text;
            },
            prescripteur: function (item) {
                if (item.id) {
                    let html = '<div>';

                    if (typeahead && item._section) {
                        html += '<div class="tt-subheader bg-theme">'+Translator.trans('libelle.'+item._section)+'</div>';
                    }
                    const icon = item.icon ? item.icon : 'fa-light fa-house-medical';

                    html += '<div class="d-flex align-items-center">'
                             +     '<div class="pr-2">'
                             +         '<i class="fa-fw ' + icon + '"></i>'
                             +     '</div>'
                             +     '<div class="flex-1 min-width-0">';

                    const amFiness = item.amFiness ? item.amFiness : '---------';

                    const libelle = item.libelle ?? '-';

                    if (item._praticien) {
                        const rpps = item._praticien.rpps ? item._praticien.rpps : '-----------';

                        html = html
                             + '<div class="d-flex align-items-center justify-content-between">'
                             +     '<small class="text-ellipsis" title="Lieu : '+libelle+'">' + libelle + '</small>'
                             +     '<span class="text-monospace text-nowrap ml-1" style="font-size:13px;" title="AM / FINESS">' + amFiness + '</span>'
                             + '</div>'
                             + '<div class="d-flex align-items-center justify-content-between">'
                             +     '<b class="text-ellipsis" title="' + item._praticien.text + '">' + item._praticien.text + '</b>'
                             +     '<span class="text-monospace text-nowrap ml-1" style="font-size:13px;" title="RPPS">' + rpps + '</span>'
                             + '</div>';
                    } else {
                        const ville = item.localisation ? item.localisation.ville : null;
                        const siret = item.siret ? item.siret.substring(0, 9) + ' ' + item.siret.substring(9, 14) : null;

                        if (ville) {
                            html = html
                                 + '<div class="d-flex align-items-center justify-content-between">'
                                 +     '<small title="Ville">' + ville + '</small>'
                                 +     '<span class="text-monospace text-nowrap ml-1" style="font-size:13px;" title="' + (amFiness ? 'FINESS' : 'Siret') + '">' + (amFiness ? amFiness : siret) + '</span>'
                                 + '</div>';
                        }

                        html = html
                            + '<div class="d-flex align-items-center justify-content-between">'
                            +     '<b class="text-ellipsis" title="' + libelle + '">' + libelle + '</b>'
                            +     '<span class="text-monospace text-nowrap ml-1" style="font-size:13px;" title="' + (amFiness ? 'FINESS' : 'Siret') + '">' + (ville ? '' : (amFiness ? amFiness : siret)) + '</span>'
                            + '</div>';
                    }

                    html = html
                         +     '</div>'
                         +     '</div>'
                         + '</div>';

                    return $(html);
                }

                return item.text;
            },
            serie: function (item) {
                if (item.id) {
                    const numeroSecuriteSociale = item.numeroSecuriteSociale ? item.numeroSecuriteSociale.substring(0, 1) + ' ' + item.numeroSecuriteSociale.substring(1, 3) + ' ' + item.numeroSecuriteSociale.substring(3, 5) + ' ' + item.numeroSecuriteSociale.substring(5, 7) + ' ' + item.numeroSecuriteSociale.substring(7, 10) + ' ' + item.numeroSecuriteSociale.substring(10, 13) + (15 === item.numeroSecuriteSociale.length ? ' ' + item.numeroSecuriteSociale.substring(13, 15) : '') : '- -- -- -- --- --- --';
                    const naissance = item.naissance ? item.naissance : '--/--/----';
                    const naissanceApproximative = item.naissanceApproximative ? item.naissanceApproximative : null;

                    let html = '<div>'
                    +         '<div class="d-flex align-items-center justify-content-between">'
                    +             '<small>' + numeroSecuriteSociale + ' - <span class="text-nowrap">' + item.patient +'</span></small>'
                    +             '<span class="text-monospace text-nowrap" style="font-size:13px;" title="' + (naissanceApproximative ? 'Date de naissance approximative' : 'Date de naissance') + '">' + (naissanceApproximative ? naissanceApproximative : naissance) + '</span>'
                    +         '</div>';
                    html += '<div class="d-flex">' +
                        '<b>' + item.text + '</b>' +
                        (item.motif ? (
                            '<span title="'+item.motif.libelle+'" class="ml-auto font-small-caps text-nowrap">' +
                            '    <div class="mr-2" style="background-color: #'+item.motif.couleur+';width: 12px;height: 12px;border-radius: 100%;vertical-align: middle;display: inline-block;"></div>'+item.motif.abreviation +
                            '</span>') : '') +
                        '</div>';
                    if (item.commentaire) {
                        html += '<div class="text-ellipsis line-height-1 font-italic" title="'+item.commentaire+'"><small>'+item.commentaire+'</small></div>';
                    }
                    html += '</div>';

                    return $(html);
                }

                return item.text;
            },
            facture: function (item) {
                if (item.id) {
                    let html = '<div>';
                    html += '<div><small>' + item.date + '</small><span class="float-right badge badge-secondary">' + item.societe + '</span></div>';
                    html += '<div><b>' + item.numero + '</b><div class="float-right">' + (item.montant !== null ? (App.String.euro(item.montant)) : '-') + '</div></div>';
                    html += '<div>' + item.patient + '</div>';
                    html += '</div>';

                    return $(html);
                }

                return item.text;
            },
            libelleStructure: function (item) {
                if (item.id) {
                    let html = '<div>' + item.text + (item.structure ? '<span class="float-right badge badge-secondary">' + item.structure + '</span>' : '') + '</div>';
                    return $(html);
                }

                return item.text;
            },
            motif: function(item) {
                if(item.id) {
                    return $('<div class="d-flex align-items-center font-size-16px" style="line-height: 1.1;"><div class="mr-2" style="background-color: #' + item.couleur + ';width: 8px;height: 30px;border-radius: 2px;vertical-align: middle;display: inline-block;"></div><div><div class="font-small-caps font-bold">' + item.abreviation.toLowerCase().replace(/^\w/, c => c.toUpperCase()) + '</div><div class="font-size-12px">' + item.libelle+'</div></div></div>');
                }
                return item.text;
            },
            equipage: function(item) {
                if(item.id) {
                    return $('<div class="d-flex align-items-center font-size-16px" style="line-height: 1.1;"><div class="mr-2" style="background: ' + item.couleur + ';width: 8px;height: 30px;border-radius: 2px;vertical-align: middle;display: inline-block;"></div><div><div class="font-small-caps font-bold">' + item.text +'</div><div class="font-size-12px">' +item.description.toLowerCase().replace(/^\w/, c => c.toUpperCase()) +'</div></div></div>');
                }
                return item.text;
            },
            sefi: function(item) {
                if(item.id) {
                    return $('<div class="line-height-1"><div><b>' + item.libelle + '</b><span class="float-right badge badge-secondary">' + item.agrement + '</span></div><small>' + item.numero + '</small></div>');
                }

                return item.text;
            },
            indicateur: function(item) {
                if(item.id) {
                    return $('<div class="d-flex align-items-center font-size-16px" style="line-height: 1.1;"><div class="mr-2" style="background-color: #' + item.couleur + ';width: 8px;height: 30px;border-radius: 2px;vertical-align: middle;display: inline-block;"></div>' + (item.abreviation ? '<div class="font-bold">'+item.abreviation+' <small>' : '')+item.libelle+'</small></div></div>');
                }

                return item.text;
            },
            default: function (item) {
                if (item.children) {
                    let html = '<div class="d-flex align-items-center justify-content-center '+(item.class || '')+'">';
                    html += '<div class="flex-1">';
                    if (item.leftIcon) {
                        html += '<i class="mr-2 '+item.leftIcon.class+'" title="'+(item.leftIcon.title ?? '')+'"></i> ';
                    }
                    html += (item.longText !== undefined ? item.longText : item.text) + '</div>';
                    if (item.rightIcon) {
                        html += '<div><i class="'+item.rightIcon.class+'" title="'+(item.rightIcon.title ?? '')+'"></i></div>';
                    }

                    html += '</div>'

                    return $(html);
                } else {
                    const colorPoint = item.couleur !== undefined && item.couleur.length > 0 ? '<div class="mr-2" style="background: ' + item.couleur + ';width: 8px;border-radius: 2px;vertical-align: middle;display: inline-block;"></div>' : '';
                    const description = item.description !== undefined && item.description.length > 0 ? item.description : '';

                    if (colorPoint || description || item.code || item.icon) {
                        let html = '<div class="select2-custom-item">';
                        const text = item.longText !== undefined ? item.longText : item.text;
                        const $text = $('<div class="text-ellipsis"></div>').text(text).attr('title', text);

                        let codeHtml = '';
                        if (item.code) {
                            codeHtml = $('<div class="font-weight-500 mr-1"></div>').text('['+item.code+']')[0].outerHTML;
                        } else {
                            $text.addClass('font-weight-normal');
                        }

                        html += '<div class="d-flex">';

                        if (colorPoint) {
                            html += colorPoint + '<div class="flex-1 overflow-hidden"><div class="d-flex text-ellipsis">'
                        }
                        if (item.icon) {
                            html += '<i class="mr-2 '+item.icon.class+'" title="'+(item.icon.title ?? '')+'"></i> ';
                        }

                        html += codeHtml + $text[0].outerHTML;

                        if (colorPoint) {
                            html += '</div>';
                        }

                        if (description) {
                            const $description = $('<div class="font-size-10px line-height-1-3 d-flex"></div>');
                            if (item.descriptionHtml) {
                                $description.html(description);
                            } else {
                                $description.text(description);
                            }
                            html += $description[0].outerHTML;

                        }

                        if (colorPoint) {
                            html += '</div>';
                        }
                        html += '</div></div>';
                        return $(html);
                    }
                }
                return item.longText !== undefined ? item.longText : item.text;
            }
        };

        return name in templates ? templates[name]: templates.default;
    }

    static flashField($field, color='success') {
        if(!$field.is('[type="hidden"]')) {
            let $element = $field;

            if($field.hasClass('js-select2-active') || $field.hasClass('js-select2-autocomplete-active')) {
                $element = $field.next().find('.select2-selection');
            }

            $element.addClass('flash-border-'+color).one('animationend', function () {
                $element.removeClass('flash-border-'+color);
            });
        }
    }

    static flashTooltip($el, title, timeout = 2500) {
        $el.tooltip('dispose').tooltip({
            title,
            trigger: 'manual',
            template: '<div class="tooltip font-size-12px" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
        }).tooltip('show');
        setTimeout(() => {
            $el.tooltip('hide');
        }, timeout);
    }

    static getItineraireViaMichelinUrl(form) {
        const mapping = {
            '_DEPART_': 'PriseEnCharge',
            '_ARRIVEE_': 'Destination',
        };

        let url = App.Constants.VIA_MICHELIN_ITINERAIRE_URL;
        for(let placeholder in mapping) {
            let type = mapping[placeholder];
            let adresse = $('#'+form['adresse'+type]).val().replace('\n', '').replace('\r','').trim();
            let codePostal = $('#'+form['codePostal'+type]).val().trim();
            let ville = $('#'+form['ville'+type]).val().trim();

            if (adresse && (codePostal || ville)) {
                adresse += ', ';
            }

            url = url.replace(placeholder, adresse+(codePostal+' '+ville).trim());
        }

        return url;
    }

    static handleFormErrors($form, errors) {
        let name = $form.attr('name');

        $form.find('.is-invalid:not(.js-vue-validation)').removeClass('is-invalid');
        $form.find('.invalid-feedback:not(.js-vue-validation)').remove();
        $form.find('.alert:not(.js-vue-validation)').remove();

        for(let id in errors) {
            let error = errors[id];
            if(id === name) {
                $('#form').prepend(error.html);
            } else {
                let $el = $('#' + id);

                if(error.invalid) {
                    $el.closest('.collapse').collapse('show');
                    $el.addClass('is-invalid');
                }

                let $parent = $el;

                if($el.hasClass('js-input-link')) {
                    if($el.hasClass('tt-input')) {
                        $parent = $parent.parent();
                    }
                    $parent = $parent.parent().parent();
                }
                else if($el.parent('.btn-filter').length) {
                    $parent = $el.parent().parent().parent().parent();
                }
                else if($el.parent('.input-group').length || $el.parent('.input-group-content').length || $el.parent('.checkbox').length || $el.parent('.twitter-typeahead-absolute').length) {
                    $parent = $el.parent();

                    if ($parent.parent('.input-group').length) {
                        $parent = $parent.parent();
                    }
                }
                else if($el.hasClass('js-select2-active') || $el.hasClass('js-select2-autocomplete-active')) {
                    $parent = $parent.next();
                }
                else if ($el.hasClass('js-top-input')) {
                    $parent = $el.parent();
                }

                $parent.after(error.html);
            }
        }
    }

    /**
     * Vide les champs enfants de l'élément.
     */
    static clearAllInputs($el) {
        $el.find(':input:not(.js-no-clear)').each(function() {
            if(this.type === 'checkbox' || this.type === 'radio') {
                this.checked = false;
                if($(this).parent().hasClass('btn-filter')) {
                    $(this).parent().removeClass('active');
                }
            }
            else if(this.type === 'file') {
                $(this).replaceWith($(this).clone(true));
            }
            else if(this.type !== 'submit') {
                if($(this).hasClass('tt-input')) {
                    $(this).typeahead('val', null);
                } else {
                    if ($(this).data('clear-value')) {
                        $(this).val($(this).data('clear-value'));
                    } else {
                        $(this).val('');
                    }

                    if($(this).is('[type="hidden"]') || $(this).hasClass('js-select2-autocomplete-active') || $(this).hasClass('js-age') || $(this).hasClass('js-telephone')) {
                        $(this).trigger('change');
                    }
                }
            }
        });
    }

    /**
     * Vide les champs enfants du sélecteur.
     */
    static clearAllInputsSelector(selector) {
        App.Utils.clearAllInputs($(selector));

    }

    static deparam(params) {
        if(!params || !/([^?#]*)(#.*)?$/.test(params)) {
            return {};
        }

        let plus = /\+/g;
        let data = {};
        let pairs = params.split('&');
        let current;

        for(let i=0; i < pairs.length; i++){
            current = data;
            let pair = pairs[i].split('=');

            // if we find foo=1+1=2
            if(pair.length !== 2) {
                pair = [pair[0], pair.slice(1).join("=")];
            }

            let key = decodeURIComponent(pair[0].replace(plus, " ")),
            value = decodeURIComponent(pair[1].replace(plus, " ")),
            parts = key.match(/([^\[\]]+)|(\[\])/g);

            for(let j = 0; j < parts.length - 1; j++) {
                let part = parts[j];
                if (!current[part] ) {
                    // if what we are pointing to looks like an array
                    current[part] = /^\d+$/.test(parts[j+1]) || parts[j+1] === "[]" ? [] : {};
                }
                current = current[part];
            }
            let lastPart = parts[parts.length - 1];

            if(lastPart === "[]"){
                current.push(value);
            } else {
                current[lastPart] = value;
            }
        }
        return data;
    }

    static validateAjaxResponse(data) {
        return (typeof data !== 'string') || !data.includes('class="login-register"');
    }

    static Ajax(options) {
        let jqXhr = oldAjax(options);

        let deferred = jqXhr.pipe(function(data) {
            if (App.Utils.validateAjaxResponse(data)) {
                return data;
            } else {
                document.location.reload();
                return $.Deferred().promise();
            }
        });

        return $.extend({}, jqXhr, deferred);
    }

    static generateUid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    static isPhoneMobile(phoneNumber) {
        return phoneNumber.startsWith('06') || phoneNumber.startsWith('07');
    }
};

var oldAjax = $.ajax;

$.ajax = App.Utils.Ajax;

$.fn.html_animate = function(html, cb){
    return this.each(function(){
        let el = $(this);
        el.stop(true, true, false);
        let finish = {width: this.style.width, height: this.style.height};
        let cur = {width: el.outerWidth() + "px", height: el.outerHeight() + "px"};
        el.html(html);
        let next = {width: el.outerWidth() + "px", height: el.outerHeight() + "px"};
        el.css(cur).animate(next, 300, function(){
            el.css(finish);
            if(cb) cb();
        });
    });
};
