<template>
    <api-popup name="SEFi" :title="title">
        <template #title>
            <span class="badge badge-success mr-3">SEFi</span> {{ title }}
        </template>
        <template #header>
            <b-btn variant="secondary" size="sm" title="Accéder à la documentation" target="_blank" :href="path('ressources.documentation.chapitre', {chapitre: 'sefi'})+(error.code ? '#sefi-liste-des-erreurs-sefi-'+(true ? 'transport' : 'taxi')+'-'+error.code.toUpperCase() : '')" v-if="error && error.type === 'sefi'">
                <i class="fa-fw fa-regular fa-circle-question"></i>
            </b-btn>
            <template v-if="showForm">
                <b-btn variant="secondary" size="sm" id="inputs" :disabled="loading" class="mr-2">
                    <i class="fa-solid fa-fw fa-list"></i>
                </b-btn>
                <popover target="inputs" placement="bottom" title="Données" @hide="$emit('refresh')">
                    <slot name="form"></slot>
                </popover>
            </template>
            <b-btn variant="secondary" size="sm" @click="$emit('refresh')" :disabled="loading" v-if="showRefresh">
                <i class="fa-solid fa-fw fa-arrows-rotate"></i>
            </b-btn>
        </template>
        <template #content>
            <template v-if="loading || (error !== null && !showInitForm)">
                <sefi-loader :error="loading ? null : error"></sefi-loader>
            </template>
            <slot name="initForm" v-else-if="showInitForm"></slot>
            <slot name="result" v-else-if="result !== null"></slot>
        </template>
        <template #footer>
            <slot name="initFormButtons" v-if="showInitForm && !loading"></slot>
            <slot name="resultButtons" v-else-if="!loading && null === error && result !== null"></slot>
        </template>
    </api-popup>
</template>

<script>
export default {
    props: ['title', 'loading', 'error', 'result', 'noForm', 'noRefresh', 'initForm'],
    computed: {
        showInitForm() {
            return this.initForm && null === this.result && (null === this.error || this.error.type === 'form');
        },
        showRefresh() {
            return !this.noRefresh && !this.showInitForm;
        },
        showForm() {
            return !this.noForm && !this.showInitForm;
        },
    },
}
</script>
