<template>
    <form-input :is-invalid="isInvalid" :type="type" :value="value" :outlined="outlined" @input="$emit('input', $event)" @change="$emit('change', $event)" @reset="onReset"/>
</template>

<script>
    import Bloodhound from "bloodhound-js";
    import autosize from "autosize";

    export default {
        emits: ['input', 'change', 'changes', 'data', 'reset', 'resets'],
        props: ['value', 'type', 'geocodageType', 'geocodageSuffix', 'geocodageField', 'isInvalid', 'outlined', 'sourceOrganisme'],
        watch: {
            value() {
                $(this.$el).typeahead('val', this.value);
            }
        },
        mounted () {
            let config =  {
                minLength: 2,
                highlight: true,
                hint: false,
                tabAutocomplete: false,
                classNames: {
                    wrapper: $(this.$el).data('absolute') ? 'twitter-typeahead-absolute' : 'twitter-typeahead'
                },
            };

            if(!this.geocodageField) {
                this.autocompletedValue = this.value;
            }

            const suggestion = App.Layout.getGeocodageSuggestion(this.geocodageType);

            const prepareRemote = (query, settings) => {
                settings.url = settings.url.replace('_QUERY_', encodeURIComponent(query)).replace('_STRUCTURE_', App.Shared.getStructure() ? App.Shared.getStructure() : '');
                return settings;
            };

            const adresseSource = {
                source: new Bloodhound({
                    datumTokenizer: Bloodhound.tokenizers.whitespace,
                    queryTokenizer: Bloodhound.tokenizers.whitespace,
                    remote: {
                        url: App.Constants.GEOCODAGE_PATH.replace('_TYPE_', this.geocodageType ?? ''),
                        prepare: prepareRemote,
                        cache: false
                    },
                }),
                templates: {
                    header: () => {
                        if(this.sourceOrganisme) {
                            return '<div class="tt-header bg-theme">Adresses</div>';
                        }
                    },
                    suggestion,
                    pending: function(context, options) {
                        return '<div class="tt-suggestion text-muted">Recherche en cours…</div>';
                    },
                    notFound: '<div></div>',
                },
                limit: 400,
                displayKey: this.geocodageField || 'value'
            };

            if (this.sourceOrganisme) {
                const organismeSource = {
                    source: new Bloodhound({
                        datumTokenizer: Bloodhound.tokenizers.whitespace,
                        queryTokenizer: Bloodhound.tokenizers.whitespace,
                        remote: {
                            url: App.Constants.GEOCODAGE_PATH.replace('_TYPE_', 'organisme'),
                            prepare: prepareRemote,
                            cache: false
                        },
                    }),
                    limit: 400,
                    templates: {
                        header: '<div class="tt-header bg-theme">Organismes</div>',
                        suggestion,
                        notFound: '<div></div>',
                    },
                    displayKey: this.geocodageField || 'value'
                };

                const annuaireSanteSource = {
                    source: new Bloodhound({
                        datumTokenizer: Bloodhound.tokenizers.whitespace,
                        queryTokenizer: Bloodhound.tokenizers.whitespace,
                        remote: {
                            url: App.Constants.GEOCODAGE_PATH.replace('_TYPE_', 'annuaire-sante'),
                            prepare: prepareRemote,
                            cache: false
                        },
                    }),
                    limit: 400,
                    templates: {
                        header: '<div class="tt-header bg-theme">Annuaire Santé</div>',
                        suggestion,
                        notFound: '<div></div>',
                    },
                    displayKey: this.geocodageField || 'value'
                };

                $(this.$el).typeahead(config, organismeSource, annuaireSanteSource, adresseSource);
            } else {
                $(this.$el).typeahead(config, adresseSource);
            }

            $(this.$el)
                .on('typeahead:select', (e, data) => {
                    if($(this).hasClass('js-autosize') || $(this).hasClass('js-autosize-focus')) {
                        autosize.update(this.$el);
                    }

                    if(!this.geocodageField) {
                        this.autocompletedValue = data.name;
                    }

                    let values = {
                        ['ville'+this.geocodageSuffix]: data.city,
                        ['codePostal'+this.geocodageSuffix]: data.postcode,
                        ['codeInsee'+this.geocodageSuffix]: data.citycode,
                        ['latitudeDegre'+this.geocodageSuffix]: data.latitude,
                        ['longitudeDegre'+this.geocodageSuffix]: data.longitude,
                        ['precision'+this.geocodageSuffix]: data.precision,
                    };

                    if(data.type !== 'municipality') {
                        values['adresse'+this.geocodageSuffix] = data.name;
                    }

                    this.$emit('changes', values);
                    this.$emit('data', data);
                })
                .on('input', this.onInput)
            ;
        },
        methods: {
            onInput(event) {
                const value = event.target.value;

                if(this.geocodageField || value.indexOf(this.autocompletedValue) === -1) {
                    this.$emit('changes', {
                        ['codeInsee'+this.geocodageSuffix]: '',
                        ['latitudeDegre'+this.geocodageSuffix]: '',
                        ['longitudeDegre'+this.geocodageSuffix]: '',
                        ['precision'+this.geocodageSuffix]: 0,
                    });
                }
            },
            onReset($event) {
                this.$emit('reset', $event);
                this.$emit('resets', [
                    'codeInsee'+this.geocodageSuffix,
                    'latitudeDegre'+this.geocodageSuffix,
                    'longitudeDegre'+this.geocodageSuffix,
                    'precision'+this.geocodageSuffix,
                ]);

            },
        },
        unmounted() {
            $(this.$el).off().typeahead('destroy')
        },
    }
</script>