<template>
    <DataTable :value="items"
               stripedRows
               lazy
               scrollable scrollHeight="flex"
               paginator
               :pageLinkSize="3"
               :rows="itemNumberPerPage"
               :totalRecords="totalItemCount"
               :first="(ressourceHistoriqueCurrentPage - 1) * itemNumberPerPage"
               :loading="loading || activeSegmentLoading"
               @page="onPage"
               @row-click="onClick"
               selectionMode="single"
               dataKey="id"
               :selection="activeSegment ? (itemsById[activeSegment.id] ?? null) : null"
               size="small"
               rowGroupMode="subheader"
               groupRowsBy="positionDebut.horodatage.date"
               :pt="{root: '!p-0 z-10', rowGroupHeaderCell: 'py-1 px-2 bg-gray-100 leading-none', headerRow: 'hidden', pcPaginator: {root: '!p-0 mt-1', content: 'flex', first: '!h-7 !min-w-7', last: '!h-7 !min-w-7', prev: '!h-7 !min-w-7', next: '!h-7 !min-w-7', page: '!h-7 !min-w-7'}}"
    >
        <template #empty>Aucun élément.</template>
        <template #groupheader="{data}">
            <div class="font-semibold text-slate-500">
                <i class="fa-solid fa-arrow-down-wide-short mr-1"></i>{{ data.positionDebut.horodatage.date }}
            </div>
        </template>

        <template #paginatorstart>
            <Button class="ml-2 !h-7" icon="fa fa-calendar" :severity="filter.debut ? null : 'secondary'" size="small" @click="(event) => dateOverlay.toggle(event)"/>
            <Popover ref="dateOverlay" :pt="{content: ''}" :ptOptions="{mergeProps: false}">
                <DatePicker v-model="dateFilter" selectionMode="range" :manualInput="false" inline>
                    <template #footer>
                        <div class="flex mt-2">
                            <Button size="small" severity="secondary" icon="fa-solid fa-arrows-rotate" label="Réinitialiser" :disabled="!dateFilter" @click="resetDateFilter"></Button>
                            <Button size="small" class="ml-auto" icon="fa-solid fa-filter" label="Filtrer" :disabled="!dateFilter" @click="applyDateFilter"></Button>
                        </div>
                    </template>
                </DatePicker>
            </Popover>
        </template>

        <template #paginatorend>
            <SelectButton class="mr-2" :pt="{pcButton: {root: '!h-7'}}" v-model="filter.type" :options="[{title: 'Boitier de géolocalisation', icon: 'fa-microchip', value: 'geoloc'}, {title: 'TLA', icon: 'fa-mobile', value: 'tla'}]" optionLabel="title" optionValue="value" dataKey="value">
                <template #option="slotProps">
                    <i class="fa-regular fa-sm" :class="slotProps.option.icon"></i>
                </template>
            </SelectButton>
        </template>

        <Column field="appareil" bodyClass="w-px">
            <template #body="{data}">
                <i v-if="data.appareil.type" :class="{'fa-microchip': data.appareil.type.estGeoloc, 'fa-mobile': data.appareil.type.estTla}" class="fa-regular fa-fw" :title="data.appareil.text +' ('+data.appareil.imei+')'"></i>
            </template>
        </Column>
        <Column field="type" bodyClass="w-px !p-0">
            <template #body="{data}">
                <i class="fa-solid fa-lg fa-road fa-fw text-orange-500" title="En route" v-if="data.enMouvement"></i>
                <i class="fa-solid fa-lg fa-square-parking fa-fw text-blue-500" title="À l'arrêt" v-else></i>
            </template>
        </Column>

        <Column field="heure" bodyClass="w-px !py-1 leading-none font-medium whitespace-nowrap tabular-nums">
            <template #body="{data}">
                <div>
                    <i class="fa-regular fa-arrow-right-to-line mr-1"></i> {{ data.positionFin.horodatage.time.split(':').slice(0, -1).join(':') }}
                </div>
                <div>
                    <i class="fa-regular fa-arrow-right-from-line mr-1"></i>
                    {{ data.positionDebut.horodatage.time.split(':').slice(0, -1).join(':') }}
                </div>
            </template>
        </Column>
        <Column field="lieu" bodyClass="max-w-0 !py-1 leading-none !pl-0 text-slate-500">
            <template #body="{data}">
                <div class="truncate" :title="data.positionFin.adresseCompleteUneLigne" v-if="data.positionFin.adresseCompleteUneLigne !== data.positionDebut.adresseCompleteUneLigne">{{ data.positionFin.adresseCompleteUneLigne }}</div>
                <div class="truncate" :title="data.positionDebut.adresseCompleteUneLigne">{{ data.positionDebut.adresseCompleteUneLigne }}</div>
            </template>
        </Column>

        <Column field="duree" bodyClass="w-px whitespace-nowrap tabular-nums">
            <template #body="{data}">
                <i class="fa-regular fa-stopwatch"></i>
                {{ Duree(data.duree, 'hhmm') }}
            </template>
        </Column>
        <Column field="distance" bodyClass="w-px whitespace-nowrap">
            <template #body="{data}">
                <template v-if="null !== data.kilometreVehicule">
                    <i class="fa-regular fa-gauge" title="Kilomètre véhicule"></i> {{ data.kilometreVehicule }} km
                </template>
                <template v-else-if="null !== data.kilometreGps">
                    <i class="fa-regular fa-route" title="Kilomètre GPS"></i> {{ data.kilometreGps }} km
                </template>
                <span class="text-slate-500" v-else>
                    -
                </span>
            </template>
        </Column>

        <Column style="display: none;"></Column>
    </DataTable>
</template>

<script setup>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {computed, onMounted, ref, toRefs, watch} from "vue";
import Router from "@/App/Router";
import Request from "@/App/Request";
import Duree from "@/Vue/Filters/Duree";
import {storeToRefs} from "pinia";
import {useHistoriqueStore} from "@/Vue/Stores/RegulationV2/Historique";
import SelectButton from "primevue/selectbutton";
import DatePicker from "primevue/datepicker";
import Popover from 'primevue/popover';
import Button from 'primevue/button';
import moment from "moment";

const props = defineProps({
    ressource: Object,
});
const historiqueStore = useHistoriqueStore();
const {loadSegment, resetFilter} = historiqueStore;
const {activeSegment, ressourceHistoriqueCurrentPage, ressourceHistoriqueSortDirection, activeSegmentLoading, filter} = storeToRefs(historiqueStore);
const {ressource} = toRefs(props);

const loading = ref(false);
const itemNumberPerPage = ref(null);
const totalItemCount = ref(0);
const items = ref([]);
const itemsById = computed(() => items.value.reduce((res, item) => (res[item.id] = item, res), {}));

const dateOverlay = ref();

const debut = filter.value.debut ? moment(filter.value.debut, 'DD/MM/YYYY').toDate(): null;
const fin = filter.value.fin ? moment(filter.value.fin, 'DD/MM/YYYY').toDate(): null;

const dateFilter = ref(debut ? [debut, fin] : null);

const applyDateFilter = () => {
    filter.value.debut = moment(dateFilter.value[0]).format('DD/MM/YYYY');
    filter.value.fin = moment(dateFilter.value[1] ?? dateFilter.value[0]).format('DD/MM/YYYY');

    dateOverlay.value.hide();
}

const resetDateFilter = () => {
    filter.value.debut = null;
    filter.value.fin = null;
    dateFilter.value = null;

    dateOverlay.value.hide();
}

const onPage = (event) => {
    ressourceHistoriqueCurrentPage.value = event.page + 1;
    refresh();
}
const refresh = () => {
    loading.value = true;

    Request.getJson(Router.generate('regulation-v2.historique.vehicule', {id: ressource.value.geolocalisation.id, page: ressourceHistoriqueCurrentPage.value, filter: filter.value})).then(data => {
        items.value = data.items;
        ressourceHistoriqueCurrentPage.value = data.currentPage;
        totalItemCount.value = data.totalItemCount;
        itemNumberPerPage.value = data.itemNumberPerPage;

        loading.value = false;
    });
}

const onClick = (event) => {
    loadSegment(event.data.id);
}

watch(filter, () => {
    refresh();
}, {deep: true});

onMounted(() => {
    refresh();
});

defineExpose({refresh, itemsById});
</script>