<template>
    <div class="flex h-dvh standalone:h-screen overflow-hidden relative" id="container">
        <Map :style="{'--controls-padding': tabs.length ? '55px' : '0px', '--controls-display': tab === 'historique' && !isDesktop ? 'none' : ''}" v-if="roles.ROLE_REGULATION_V2_CARTOGRAPHIE && mapVisible && (mapBounds || mapCenter)" ref="map" v-model:center="mapCenter" v-model:zoom="mapZoom" :bounds="mapBounds" @contextmenu="showMapContextMenu" :lock="mapLock" :config="mapStyle.config" :padding="mapPadding" @interract="onMapInterract" @click="onMapClick">
            <MapLayerStructures v-if="mapLayers.bureaux"></MapLayerStructures>
            <MapLayerPOI></MapLayerPOI>
            <MapLayerTraffic></MapLayerTraffic>
            <MapLayerRadars v-if="mapLayers.radars"></MapLayerRadars>
            <MapLayerSearchResults></MapLayerSearchResults>
            <MapLayerSegment v-if="tab === 'historique' && activeSegment"></MapLayerSegment>
            <template v-else>
                <MapLayerRessources v-if="mapLayers.ressources"></MapLayerRessources>
                <MapLayerCurrentTrajet></MapLayerCurrentTrajet>
            </template>
            <MapControlZoom></MapControlZoom>
            <MapControlAttribution></MapControlAttribution>
            <MapControlPresets></MapControlPresets>
            <MapControlGeolocate v-if="!isDesktop"></MapControlGeolocate>
            <MapControlTools></MapControlTools>
            <MapControlScale></MapControlScale>
            <MapPin v-if="mapContextMenuLngLat" :key="'context_pin_'+mapContextMenuLngLat[0]+'_'+mapContextMenuLngLat[1]" variant="indigo" shape="square" :pulse="true" :popup="true" @update:popup="mapContextMenuLngLat = null" :clickable="true" :lngLat="mapContextMenuLngLat">
                <template #content>
                    <i class="fa-solid fa-map-pin text-lg"></i>
                </template>
                <MapPopup>
                    <Popup width="275px" @close="mapContextMenuLngLat = null">
                        <template #headerLeft>
                            Marqueur
                        </template>
                        <template #footerRight>
                            <div class="px-1 flex items-center justify-end">
                                <ItineraireButton></ItineraireButton>
                            </div>
                        </template>
                        <div class="p-2">
                            <div class="flex flex-col gap-2">
                                <PopupLocation :lngLat="mapContextMenuLngLat"></PopupLocation>
                            </div>
                        </div>
                    </Popup>
                </MapPopup>
            </MapPin>
        </Map>
        <div class="p-3 flex flex-col flex-1 w-full">
            <header class="flex justify-between items-start mb-3">
                <div class="flex items-center gap-x-4">
                    <nav class="z-20 relative backdrop-blur-lg rounded-xl flex dark:bg-black dark:bg-opacity-70 bg-white bg-opacity-80 shadow-md h-[55px] p-2">
                        <div class="px-1 mr-1 flex items-center">
                            <a class="flex items-center" :href="Router.generate('homepage')">
                                <img src="@/../images/logo-black.svg" class="block dark:hidden min-w-[35px] w-[35px]">
                                <img src="@/../images/logo.svg" class="hidden dark:block min-w-[35px] w-[35px]">
                                <span class="logo-text ml-2 hidden md:inline">Ambu<span>ERP</span></span>
                            </a>
                        </div>
                        <SpeedDial :model="utilisateur.applicationCollection.filter(app => app.cle !== 'regulation-v2')" direction="down" class="relative" pt:item="w-full last:mb-2" :pt:list="({state}) => ({class: {'absolute top-[55px] -left-2 w-[190px] px-2 before:rounded-xl before:block before:absolute before:inset-0 before:backdrop-blur-lg before:dark:bg-black before:dark:bg-opacity-70 before:bg-white before:bg-opacity-80 before:shadow-md': true, 'before:opacity-0 pointer-events-none': !state.d_visible, 'before:transition-opacity before:duration-500 before:opacity-1 pointer-events-auto': state.d_visible}})">
                            <template #button="{ toggleCallback }">
                                <div class="px-4 bg-opacity-80 rounded-lg text-white items-center flex text-lg font-medium btn-theme-regulation-v2 transition-all duration-200 cursor-pointer flex-1 select-none" @click="toggleCallback">
                                    <i class="fa-regular fa-arrow-progress mr-2"></i>
                                    {{ geolocalisationUniquement ? 'Géolocalisation' : 'Régulation' }}
                                    <span v-if="!geolocalisationUniquement" style="position: absolute;top: -4px;right: 6px;font-size: 10px;text-shadow: #fff 0 0 7px;">beta</span>
                                </div>
                            </template>
                            <template #item="{item}">
                                <a class="px-4 bg-opacity-80 rounded-lg text-white items-center flex text-lg font-medium py-1 shadow-lg transition-all duration-200" :class="'btn-theme-'+item.theme" :href="Router.generate(item.cle)">
                                    <i :class="item.classeIcone" class="mr-2"></i>
                                    {{ item.libelle }}
                                </a>
                            </template>
                        </SpeedDial>
                    </nav>
                    <template v-if="isDesktop">
                        <div class="z-10 backdrop-blur-lg rounded-xl shadow-md p-2 bg-white bg-opacity-50 dark:bg-black dark:bg-opacity-40">
                            <TreeSelect :pt="{label: {class: '!py-1.5'}}" class=" w-48" :modelValue="structureCollection.reduce((acc,curr)=> (acc[curr]={checked: true},acc),{})" @update:modelValue="updateStructures($event)" selection-mode="checkbox" :options="filter.structureCollection.tree" placeholder="Bureaux" />
                        </div>
                        <div class="z-10 backdrop-blur-lg rounded-xl shadow-md p-2 bg-white bg-opacity-50 dark:bg-black dark:bg-opacity-40 flex gap-x-2">
                            <InputGroup class="bg-surface-0 dark:bg-surface-900 rounded-md">
                                <Button outlined severity="secondary" icon="fa-solid fa-chevron-left" class="!w-8 !py-1" @click="step(-1)"/>
                                <InputGroupAddon class="!py-1 w-14">{{ rendezVousDay }}</InputGroupAddon>
                                <DatePicker v-model="rendezVousInput" dateFormat="dd/mm/yy" inputClass="text-center rounded-none !w-28 !px-1 !py-1.5"/>
                                <Button outlined severity="secondary" icon="fa-solid fa-chevron-right" class="!w-8 !py-1" @click="step(1)"/>
                            </InputGroup>
                            <InputGroup class="bg-surface-0 dark:bg-surface-900 rounded-md">
                                <Button outlined severity="secondary" icon="fa-solid fa-calendar" class="!py-1" :class="{'opacity-60 !text-indigo-500 pointer-events-none !border-indigo-500': rendezVousIsToday}" @click="today()"/>
                            </InputGroup>
                        </div>
                        <div class="z-10 backdrop-blur-lg rounded-xl shadow-md p-2 bg-white bg-opacity-50 dark:bg-black dark:bg-opacity-40 flex gap-x-2">
                            <SearchInput></SearchInput>
                        </div>
                    </template>
                </div>
                <div class="flex items-center gap-x-4">
<!--                    <notification-menu class="!m-0 z-10 relative" :init-data="initData.notifications"></notification-menu>-->
                    <CpsMenu :cpsData="utilisateur.cpsData" v-if="isDesktop"></CpsMenu>
                    <UserMenu :utilisateur="utilisateur"></UserMenu>
                </div>
            </header>
            <main class="flex flex-1 justify-between min-h-0 gap-3 md:gap-5 flex-col-reverse md:flex-row" ref="main">
                <Window ref="mainWindow" :bottomTabs="!isDesktop" :full="!isDesktop" class="w-full max-w-[1700px] min-w-0" :class="{'flex-1 md:flex-initial': tab !== null && tab !== 'historique'}" v-model:activeTab="tab" :tabs="tabs">
                    <PanelTrajets v-if="tab === 'trajets'"></PanelTrajets>
                    <PanelPatients v-else-if="tab === 'patients'"></PanelPatients>
                    <PanelSeries v-else-if="tab === 'series'"></PanelSeries>
                    <PanelTransport v-else-if="tab === 'transport'"></PanelTransport>
                    <template #afterTabs>
                        <div class="ml-auto flex items-center gap-x-2" v-if="false">
                            <Avatar label="LV" class="shadow-md !bg-blue-500/70 backdrop-blur-lg !h-10 !w-10"/>
                            <Avatar v-badge.danger="4" label="JV" class="shadow-md !bg-blue-500/70 backdrop-blur-lg !h-10 !w-10"/>
                        </div>
                    </template>
                </Window>
                <OverlaySegment v-if="tab === 'historique' && activeSegment"></OverlaySegment>
                <div class="flex flex-col md:w-[350px] md:min-w-[350px]" :class="sidebarTab ? 'min-h-0 flex-1 md:flex-initial' : 'min-h-fit'">
                    <Window ref="sidebarWindow" :full="true" :class="activeRessourcePopup !== null && (!isDesktop || tab !== null) ? 'max-h-[max(40%,_calc(100%_-_380px))]' : 'max-h-[max(55%,_calc(100%_-_380px))] transition-all'" v-model:activeTab="sidebarTab" :tabs="sidebarTabs">
                        <SidebarTrajet v-if="sidebarTab === 'trajet'"></SidebarTrajet>
                        <SidebarMessagerie v-else-if="sidebarTab === 'messagerie'"></SidebarMessagerie>
                        <SidebarRessources v-else-if="sidebarTab === 'ressources'"></SidebarRessources>
                        <SidebarItineraire v-else-if="sidebarTab === 'itineraire'"></SidebarItineraire>
                        <SidebarParametres v-else-if="sidebarTab === 'parametres'"></SidebarParametres>
                    </Window>
                    <div v-if="activeRessourcePopup && (!mapVisible || debug.forceDetachRessourcePopup || !activeRessourcePopup.geolocalisation.position)" class="mt-3 flex justify-center">
                        <RessourcePopup :ressource="activeRessourcePopup"></RessourcePopup>
                    </div>
                </div>
            </main>
        </div>
    </div>

    <div v-if="debug.mapPadding" class="absolute bg-red-600/50 z-10 pointer-events-none" :style="{top: mapPadding.top+'px', left: mapPadding.left+'px', right: mapPadding.right+'px', bottom: mapPadding.bottom+'px'}"></div>

    <ContextMenu :model="contextMenuItems" @show="contextMenuVisible = true" @hide="contextMenuVisible = false" ref="contextMenu"/>
    <DynamicDialog />
</template>

<style>
.sf-minitoolbar {
    border-top-right-radius: 4px;
    border-top-left-radius: 0!important;
    right: initial!important;
    left: 0;
}
</style>

<script setup>
import UserMenu from '@/Vue/Components/RegulationV2/Layout/UserMenu';
import SearchInput from '@/Vue/Components/RegulationV2/Layout/SearchInput';
import CpsMenu from '@/Vue/Components/RegulationV2/Layout/CpsMenu';
import Window from '@/Vue/Components/RegulationV2/Layout/Window';
import SidebarTrajet from '@/Vue/Components/RegulationV2/Sidebar/Trajet';
import SidebarMessagerie from '@/Vue/Components/RegulationV2/Sidebar/Messagerie';
import SidebarRessources from '@/Vue/Components/RegulationV2/Sidebar/Ressources';
import SidebarParametres from '@/Vue/Components/RegulationV2/Sidebar/Parametres';
import SidebarItineraire from '@/Vue/Components/RegulationV2/Sidebar/Itineraire';
import PanelTrajets from '@/Vue/Components/RegulationV2/Panel/Trajets';
import PanelPatients from '@/Vue/Components/RegulationV2/Panel/Patients';
import PanelSeries from '@/Vue/Components/RegulationV2/Panel/Series';
import PanelTransport from '@/Vue/Components/RegulationV2/Panel/Transport';
import OverlaySegment from '@/Vue/Components/RegulationV2/Overlay/Segment';
import RessourcePopup from '@/Vue/Components/RegulationV2/Map/Ressource/Popup';

import Map from '@/Vue/Components/RegulationV2/Map/Base/Map';
import MapLayerCurrentTrajet from '@/Vue/Components/RegulationV2/Map/Layer/CurrentTrajet';
import MapLayerSegment from '@/Vue/Components/RegulationV2/Map/Layer/Segment';
import MapLayerSearchResults from '@/Vue/Components/RegulationV2/Map/Layer/SearchResults';
import MapLayerRessources from '@/Vue/Components/RegulationV2/Map/Layer/Ressources';
import MapLayerStructures from '@/Vue/Components/RegulationV2/Map/Layer/Structures';
import MapLayerRadars from '@/Vue/Components/RegulationV2/Map/Layer/Radars';
import MapLayerPOI from '@/Vue/Components/RegulationV2/Map/Layer/POI';
import MapLayerTraffic from '@/Vue/Components/RegulationV2/Map/Layer/Traffic';

import MapControlZoom from '@/Vue/Components/RegulationV2/Map/Base/Control/Zoom';
import MapControlScale from '@/Vue/Components/RegulationV2/Map/Base/Control/Scale';
import MapControlAttribution from '@/Vue/Components/RegulationV2/Map/Base/Control/Attribution';
import MapControlPresets from '@/Vue/Components/RegulationV2/Map/Base/Control/Presets';
import MapControlGeolocate from '@/Vue/Components/RegulationV2/Map/Base/Control/Geolocate';
import MapControlTools from '@/Vue/Components/RegulationV2/Map/Base/Control/Tools';
import MapPopup from '@/Vue/Components/RegulationV2/Map/Base/Popup';
import MapSource from '@/Vue/Components/RegulationV2/Map/Base/Source';
import MapPin from '@/Vue/Components/RegulationV2/Map/Base/Pin';

import ReverseGeocodage from '@/Vue/Components/RegulationV2/Utils/ReverseGeocode.vue';

import Router from "@/App/Router";
import {computed, provide, ref, watch} from 'vue';
import DatePicker from 'primevue/datepicker';
import {useRegulationStore} from "@/Vue/Stores/Regulation";
import DynamicDialog from 'primevue/dynamicdialog';
import ContextMenu from 'primevue/contextmenu';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import TreeSelect from 'primevue/treeselect';
import Button from 'primevue/button';
import Avatar from 'primevue/avatar';
import {storeToRefs} from "pinia";
import {useGeolocalisationStore} from "@/Vue/Stores/RegulationV2/Geolocalisation";
import {onKeyStroke, useElementBounding, useWindowSize} from '@vueuse/core'
import {useHistoriqueStore} from "@/Vue/Stores/RegulationV2/Historique";
import Popup from "@/Vue/Components/RegulationV2/Layout/Popup.vue";
import PopupGroup from "@/Vue/Components/RegulationV2/Layout/Popup/Group.vue";
import PopupItem from "@/Vue/Components/RegulationV2/Layout/Popup/Item.vue";
import PopupLocation from "@/Vue/Components/RegulationV2/Layout/Popup/Location.vue";
import ItineraireButton from "@/Vue/Components/RegulationV2/Layout/Popup/ItineraireButton.vue";

const regulationStore = useRegulationStore();
const geolocalisationStore = useGeolocalisationStore();
const historiqueStore = useHistoriqueStore();
import SpeedDial from 'primevue/speeddial';

const {isLoading, rendezVousInput, rendezVousDay, rendezVousIsToday, structureCollection, structuresRacine, currentTrajet} = storeToRefs(regulationStore)
const {step, today, selectTrajet} = regulationStore;

const {mapContextMenuLngLat, isDesktop, configurationUtilisateur, roles, tab, sidebarTab, mapStyle, mapCenter, mapZoom, mapBounds, structures, mapLayers, debug, activeRessourcePopup, mapVisible, searchInput, rulerActive, notificationsByType} = storeToRefs(geolocalisationStore)
const {resetMapCenter, mapInitialZoom} = geolocalisationStore;

const {activeSegment, activeSegmentRessource} = storeToRefs(historiqueStore)
const {close: closeHistorique} = historiqueStore;

const props = defineProps({
    initData: Object,
});

const utilisateur = props.initData.utilisateur;
const filter = props.initData.filter;

structuresRacine.value = props.initData.structuresRacine;
rendezVousInput.value = filter.rendezVous.value;
structureCollection.value = filter.structureCollection.value;
structures.value = props.initData.structures;
roles.value = props.initData.roles;
configurationUtilisateur.value = utilisateur.configurationRegulation;

const geolocalisationUniquement = props.initData.geolocalisationUniquement;

const map = ref();

const resetMap = () => {
    resetMapCenter();
    if (mapBounds.value) {
        map.value.map.fitBounds(mapBounds.value, {
            padding: 50,
            speed: 2,
        })
    } else {
        map.value.map.flyTo({
            center: mapCenter.value,
            zoom: mapInitialZoom,
            curve: 0.5,
            speed: 2,
            maxDuration: 1500,
        })
    }
};

provide('resetMap', resetMap);

watch(structureCollection, resetMap, {deep: true});

watch(currentTrajet, () => {
    if (!sidebarTab.value && currentTrajet.value) {
        sidebarTab.value = 'trajet';
    }
});

watch([tab, sidebarTab], () => {
    if (tab.value !== 'trajets' && null === sidebarTab.value && currentTrajet.value) {
        selectTrajet(null);
    }
});

watch(sidebarTab, () => {
    if (sidebarTab.value && (!isDesktop.value || tab.value === 'historique')) {
        tab.value = null;
    }
}, {immediate: true});

watch(tab, () => {
    if ((tab.value && !isDesktop.value) || (sidebarTab.value && tab.value === 'historique')) {
        sidebarTab.value = null;
    }
}, {immediate: true});

onKeyStroke('Escape', (e) => {
    if (searchInput.value?.focused) {
        return;
    }

    if (rulerActive.value) {
        rulerActive.value = false;
    } else if (activeSegment.value !== null) {
        closeHistorique();
    } else if (currentTrajet.value !== null) {
        selectTrajet(null);
    } else if (activeRessourcePopup.value !== null) {
        activeRessourcePopup.value = null;
    } else if (tab.value !== null) {
        tab.value = null;
    } else if (sidebarTab.value !== null) {
        sidebarTab.value = null;
    }

    // todo fix focus issues
    e.preventDefault();
});

const mapLock = computed(() => contextMenuVisible.value);
const contextMenu = ref();
const contextMenuItems = ref([]);
const contextMenuVisible = ref(false);

const showContextMenu = (event, items) => {
    contextMenuItems.value = items;

    console.log(contextMenu.value);
    contextMenu.value.show(event);
}

const showMapContextMenu = (event) => {
    mapContextMenuLngLat.value = [event.lngLat.lng, event.lngLat.lat];
    // fond de carte => couleur, mono, satelllite
    // afficher => incidents, flux de trafic, poi)
    // showContextMenu(event.originalEvent, [
    //     {icon: 'fa-solid fa-copy', label: event.lngLat.lat.toFixed(5)+', '+event.lngLat.lng.toFixed(5), command: () => navigator.clipboard.writeText(event.lngLat.lat+', '+event.lngLat.lng)},
    //     {icon: 'fa-regular fa-route', label: 'Itinéraire', items: [
    //         {label: 'Depuis ce lieu', icon: 'fa-solid fa-plane-departure'},
    //         {label: 'Vers ce lieu', icon: 'fa-solid fa-plane-arrival'},
    //     ]},
    //     {icon: 'fa-solid fa-ruler', label: 'Mesurer une distance', class: rulerActive.value ? '[&_*]:text-blue-500' : '', command: () => rulerActive.value = !rulerActive.value}
    // ]);
}

const updateStructures = (event) => {
    structureCollection.value = Object.keys(event).filter(id => filter.structureCollection.ids.includes(id));
}

const main = ref();
const mainWindow = ref();
const sidebarWindow = ref();

const { top: mainTop, bottom: mainBottom } = useElementBounding(main);
const { right: mainWindowRight } = useElementBounding(mainWindow);
const { left: sidebarWindowLeft, bottom: sidebarWindowBottom } = useElementBounding(sidebarWindow);
const { height: windowHeight } = useWindowSize()

const mapPadding = computed(() => {
    const baseTop = mainTop.value + 42;

    if (isDesktop.value) {
        // si largeur xxxxl && sidebar && window => left/right entre window et sidebar, top: base, bottom :0
        const hasHistorique = tab.value === 'historique' && activeSegment.value;
        const hasWindow = tab.value && !hasHistorique;

        return {
            left: hasWindow || hasHistorique ? (hasHistorique ? 350 : mainWindowRight.value) + 8 : 0,
            top: tab.value && sidebarTab.value ? sidebarWindowBottom.value : baseTop,
            right: 0,
            // right: sidebarTab.value && !tab.value ? windowWidth.value - sidebarWindowLeft.value : 0,
            bottom: hasHistorique ? 50 : (hasWindow ? 30 : 0),
        };
    }

    return {
        left: 0,
        top: sidebarTab.value ? sidebarWindowBottom.value : baseTop,
        right: 0,
        bottom: windowHeight.value - mainBottom.value + 50,
    };
});

const onMapClick = () => {
    if (sidebarTab.value && !isDesktop.value) {
        sidebarTab.value = null;
    }
}
const onMapInterract = (e) => {
    if (tab.value) {
        const x = e.originalEvent.clientX;
        const y = e.originalEvent.clientY;

        if (y < mapPadding.value.top || x < mapPadding.value.left) {
            tab.value = null;
        }
    }
}

const tabs = computed(() => {
    const res = [];
    const base = [];

    if (roles.value.ROLE_REGULATION_V2_TRAJET_LIST) {
        base.push({key: 'trajets', label: 'Trajets', icon: 'fa-solid fa-list-timeline', class: 'md:min-w-36', loading: isLoading.value});
    }

    if (roles.value.ROLE_REGULATION_V2_PATIENT_LIST) {
        base.push({key: 'patients', label: 'Patients', icon: 'fa-regular fa-hospital-user', class: 'md:min-w-36'});
    }

    if (roles.value.ROLE_REGULATION_V2_SERIE_LIST) {
        base.push({key: 'series', label: 'Séries', icon: 'fa-regular fa-calendar-range', class: 'md:min-w-36'});
    }

    if (base.length) {
        res.push(base);
    }

    if (roles.value.ROLE_REGULATION_V2_TRANSPORT_NEW) {
        res.push([{key: 'transport', label: 'Nouveau transport', icon: 'fa-regular fa-circle-plus'}]);
    }

    if (activeSegment.value) {
        res.push([{key: 'historique', label: 'Historique', icon: 'fa-solid fa-history', noWindow: true}]);
    }

    return res;
});

const sidebarTabs = computed(() => {
    const left = [];
    const right = [];

    if (roles.value.ROLE_REGULATION_V2_TRAJET_LIST) {
        left.push({key: 'trajet', label: 'Trajet', icon: 'fa-regular fa-signs-post', labelActive: true});
    }

    if (roles.value.ROLE_REGULATION_V2_MESSAGERIE_LIST) {
        left.push({
            key: 'messagerie',
            label: 'Messagerie',
            icon: 'fa-regular fa-comments',
            badge: (notificationsByType.messagerie ?? []).length,
            labelActive: true
        });
    }

    if (roles.value.ROLE_REGULATION_V2_RESSOURCE_GEOLOCALISER || roles.value.ROLE_REGULATION_V2_TRAJET_LIST) {
        left.push({key: 'ressources', label: 'Ressources', icon: 'fa-regular fa-link', labelActive: true});
    }

    // if (roles.value.ROLE_REGULATION_V2_ITINERAIRE) {
    //     right.push({
    //         key: 'itineraire',
    //         icon: 'fa-regular fa-route',
    //         title: 'Itinéraire'
    //     });
    // }

    right.push({key: 'parametres', icon: 'fa-regular fa-gear', title: 'Paramètres'})

    return [left, right];
});
</script>