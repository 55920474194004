<template>
  <ul class="chat-list pt-3 px-4 pb-4" :style="indexMode ? 'max-width:500px; margin:auto;' : ''">
    <template v-for="(notification, index) in notifications" :key="notification.id">
      <template v-if="!indexMode">
        <li class="chat-separator " v-if="getNotificationPrecedente(index) ? (getNotificationPrecedente(index).pin || getNotificationPrecedente(index)._enCours) && (!notification.pin && !notification.estEnCours)  : false "><span>Autres notifications</span></li>
        <li class="chat-separator " v-else-if="getNotificationPrecedente(index) ? !getNotificationPrecedente(index).pin && notification.pin && !notification.estEnCours : (!notification._enCours && notification.pin)"><span><i class="fa-solid fa-thumbtack mr-2"></i>Notifications épinglées</span></li>
        <li class="chat-separator " v-else-if="getNotificationPrecedente(index) ? false : notification._enCours"><span><i class="fa-solid fa-hourglass mr-2"></i>Évènements en cours</span></li>
      </template>
      <notification-notification :notification="notification" :index-mode="indexMode"></notification-notification>
    </template>
    <div v-if="!indexMode && store.hasMore" class="text-center">
      <a :href="Router.generate('mon-profil.notifications')" class="text-transform-none btn btn-outline-secondary mt-2">Voir plus</a>
    </div>
  </ul>
</template>

<script setup>
import {toRefs} from 'vue';
import Router from "@/App/Router";
import {useNotificationsStore} from "@/Vue/Stores/Notifications";

const props = defineProps({
    notifications: Array,
    indexMode: Boolean,
});

const { indexMode, notifications } = toRefs(props);
const store = indexMode.value ? undefined : useNotificationsStore();

const getNotificationPrecedente = (index) => {
  if (indexMode.value || 0 === index) {
    return null;
  }

  return notifications.value[index-1];
}

</script>
