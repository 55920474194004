<template>
    <div class="flex flex-wrap gap-2 md:gap3">
        <FilterButton class="order-1" v-model="mode" :choices="{list: {icon: 'fa-solid fa-table', title: 'Mode liste'}, gantt: {icon: 'fa-solid fa-chart-gantt', title: 'Mode Gantt'}}"/>
        <div v-if="!isDesktop" class="order-2 flex-1 px-2 flex items-center justify-center gap-1">
            <div>
                {{ trajetCollection.length }}<small v-if="allTrajetCollection.length !== trajetCollection.length"> / {{ allTrajetCollection.length }}</small>
            </div>
            <div>
                •
            </div>
            <div>
                {{ Euro(estimationMontantTouteTaxeFiltre) }}
            </div>
        </div>
        <template v-if="isDesktop || showFiltersMobile">
            <FilterButton class="text-xs md:text-base order-6 md:order-2 flex-1" v-model="filter.categorie" :choices="filterCategorieChoices" multiple/>
            <FilterButton class="text-xs md:text-base order-7 md:order-3 flex-1" v-model="filter.etat" :choices="filterEtatChoices" multiple/>
        </template>
        <Button v-if="!isDesktop" class="order-3" severity="secondary" :icon="[showFiltersMobile ? 'fa-solid' : 'fa-regular', 'fa-filters']" @click="showFiltersMobile = !showFiltersMobile" />
        <Button class="order-4" severity="secondary" icon="fa-solid fa-bars" @click="(event) => menu.toggle(event)" />
        <Button class="order-5" title="Fermer la fenêtre" icon="fa-solid fa-times" severity="secondary"  @click="tab = null"/>
    </div>
    <div class="flex gap-3 mt-2" v-if="isDesktop">
        <div class="text-center py-2 px-3">
            <div class="text-xl">
                {{ trajetCollection.length }}<small v-if="allTrajetCollection.length !== trajetCollection.length"> / {{ allTrajetCollection.length }}</small>
            </div>
            <div class="text-slate-500">Trajet{{ allTrajetCollection.length > 1 ? 's' : '' }}</div>
        </div>
        <div class="text-center py-2 px-3">
            <div class="text-xl">{{ Euro(estimationMontantTouteTaxeFiltre) }}</div>
            <div class="text-slate-500">Estimation</div>
        </div>
        <div class="flex-1 min-w-0">
            <column-chart ref="chart" height="52px" :data="chartData" :legend="false" :colors="['#66c4ed', '#e4e7ea']" :dataset="{borderWidth: 0}" :library="chartOptions"></column-chart>
        </div>
    </div>
    <div class="flex-1 mt-2 min-h-0">
        <Liste v-if="mode === 'list'" :groupBy="groupBy"></Liste>
        <Gantt v-if="mode === 'gantt'"></Gantt>
    </div>
    <TieredMenu ref="menu" :model="menuItems" popup></TieredMenu>
</template>

<script setup>
import {useRegulationStore} from "@/Vue/Stores/Regulation";
import {storeToRefs} from "pinia";
import TieredMenu from 'primevue/tieredmenu';
import Button from "primevue/button";
import {computed, ref} from "vue";
import Euro from "@/Vue/Filters/Euro";
import FilterButton from "@/Vue/Components/RegulationV2/Layout/FilterButton";
import Liste from "@/Vue/Components/RegulationV2/Panel/Trajets/Liste";
import Gantt from "@/Vue/Components/RegulationV2/Panel/Trajets/Gantt";
import {FilterMatchMode} from '@primevue/core/api';
import {useGeolocalisationStore} from "@/Vue/Stores/RegulationV2/Geolocalisation";

const geolocalisationStore = useGeolocalisationStore();
const regulationStore = useRegulationStore();
const {ajaxLocked, chartOptions, chartData, trajetCollection, trajetCollectionPretEnvoiTla, allTrajetCollection, estimationMontantTouteTaxeFiltre, filterEtatChoices, filterCategorieChoices, filter} = storeToRefs(regulationStore);
const {tab, isDesktop} = storeToRefs(geolocalisationStore);

// todo move in regu store
const mode = ref('list');
const showFiltersMobile = ref(false);

const groupModes = {
    none: {label: 'Aucun', group: null},
    rendezVous: {label: 'RDV', group: (trajet) => trajet.rendezVous.time.split(':')[0]},
    vehicule: {label: 'Véhicule', group: (trajet) => trajet._vehicule?.id},
};

const groupMode = ref('none');
const groupBy = computed(() => groupModes[groupMode.value].group);

const menu = ref();
const menuItems = computed(() => [
    {
        label: trajetCollectionPretEnvoiTla.value.length === 1 ? 'Envoyer le trajet prêt au TLA' : 'Envoyer les trajets prêts aux TLAs ('+trajetCollectionPretEnvoiTla.value.length+')',
        disabled: ajaxLocked.value || !trajetCollectionPretEnvoiTla.value.length,
        icon: 'fa-solid fa-paper-plane',
    },
    {
        separator: true
    },
    {
        label: 'Grouper les trajets',
        icon: 'pi pi-share-alt',
        items: Object.keys(groupModes).map(mode => ({
            label: groupModes[mode].label,
            icon: groupMode.value === mode ? 'fa-solid fa-circle-check' : 'fa-regular fa-circle',
            disabled: groupMode.value === mode,
            command: () => groupMode.value = mode,
        }))
    }
]);

</script>