<template>
    <sefi-popup title="Rechercher un prescripteur" :loading="loading" :error="error" :result="result" :init-form="true" @refresh="refresh">
        <template v-slot:initForm>
            <div class="mx-5">
                <sefi-form-row v-model="inputData.structure" :errors="errors.structure" component="sefi-form-structure" label="Société" required></sefi-form-row>
                <sefi-form-row v-if="!data?.prescripteurForm" v-model="inputData.type" :errors="errors.type" :props="{type: 'radio', choices: typeChoices}" component="form-btn-group" label="Type" required></sefi-form-row>
                <sefi-form-row v-model="inputData.numero" :errors="errors.numero" :label="numeroLabel" required></sefi-form-row>
                <sefi-form-row v-model="inputData.dateEvaluation" :errors="errors.dateEvaluation" component="form-date" :props="{picker: true}" label="Date d'évaluation" required></sefi-form-row>
            </div>
        </template>
        <template v-slot:initFormButtons>
            <b-btn variant="primary" @click="refresh">
                <i class="fa-solid fa-magnifying-glass fa-fw"></i> Rechercher
            </b-btn>
        </template>
        <template v-slot:form>
            <div class="mb-3">
                <sefi-form-widget component="sefi-form-structure" v-model="inputData.structure" :errors="errors.structure"></sefi-form-widget>
            </div>
            <div class="mb-3" v-if="!data?.prescripteurForm">
                <sefi-form-widget component="form-btn-group" v-model="inputData.type" :props="{type: 'radio', choices: typeChoices}" :errors="errors.type"></sefi-form-widget>
            </div>
            <div class="mb-3">
                <sefi-form-widget component="form-input-group" v-model="inputData.numero" :props="{label: numeroLabel}" :errors="errors.numero"></sefi-form-widget>
            </div>
            <div>
                <sefi-form-widget component="form-input-group" v-model="inputData.dateEvaluation" :props="{label: 'Date évaluation', component: 'form-date', picker: true}" :errors="errors.dateEvaluation"></sefi-form-widget>
            </div>
        </template>
        <template v-slot:resultButtons v-if="result">
            <sefi-result-reporter v-if="data && data.prescripteurForm" :enabled="canReporter" @click="reporter"></sefi-result-reporter>
        </template>
        <template v-slot:result v-if="result">
            <sefi-result-table ref="table" @canReporter="canReporter = $event">
                <sefi-result-row :label="Translator.trans('libelle.prenom')" :value="result.prenom" target="#prescripteur_prescripteur_prenomUsuel"></sefi-result-row>
                <sefi-result-row :label="Translator.trans('libelle.nom')" :value="result.nom" target="#prescripteur_prescripteur_nomUsuel"></sefi-result-row>
                <sefi-result-row :label="Translator.trans('libelle.libelle')" :value="result.libelle" target="#prescripteur_libelle"></sefi-result-row>
                <sefi-result-row :label="Translator.trans('libelle.specialite')" :value="result.codeSpecialite" target="#prescripteur_specialiteCPAM">{{ result.specialite }}</sefi-result-row>
                <sefi-result-row :label="Translator.trans('libelle.adresse')" :value="result.adresse" target="#prescripteur_adresse"></sefi-result-row>
                <sefi-result-row :label="Translator.trans('libelle.complement')" :value="result.complementAdresse" target="#prescripteur_complementAdresse"></sefi-result-row>
                <sefi-result-row :label="Translator.trans('libelle.codePostal')" :value="result.codePostal" target="#prescripteur_codePostal"></sefi-result-row>
                <sefi-result-row :label="Translator.trans('libelle.ville')" :value="result.ville" target="#prescripteur_ville"></sefi-result-row>
                <sefi-result-row :label="Translator.trans('libelle.pays')" :value="result.pays"></sefi-result-row>
            </sefi-result-table>
        </template>
    </sefi-popup>
</template>

<script setup>
import Moment from "moment";
import {computed, ref, toRefs, onMounted} from "vue";
import Router from "@/App/Router";
import Request from "@/App/Request";
import Translator from "@/App/Translator";

const props = defineProps({
    data: Object,
});
const {data} = toRefs(props);

const table = ref();
const result = ref(null);
const canReporter = ref(null);
const inputData = ref({
    structure: null,
    numero: null,
    type: 'praticien',
    dateEvaluation: Moment().format('DD/MM/YYYY')
});
const typeChoices = [{key: 'praticien', label: 'Praticien'}, {key: 'etablissement', label: 'Établissement'}];
const loading = ref(false);
const error = ref(null);

const numeroLabel = computed(() => {
    let label = 'Numéro';

    if (inputData.value.type === 'praticien') {
        label += ' AM';
    }

    if (inputData.value.type === 'etablissement') {
        label += ' FINESS';
    }

    return label;
});

const errors = computed(() => {
    if(error.value) {
        return error.value.errors || [];
    }

    return [];
});

const reporter = () => {
    table.value.reporter();
};

const refresh = () => {
    error.value = null;
    loading.value = true;

    Request.postJson(Router.generate('facturation.sefi.ps.rechercher-prescripteur'), inputData.value).then((data) => {
        if(data.success) {
            result.value = data.data;

            if (data.value?.prescripteurForm) {
                $('#prescripteur_specialiteCPAMSefiAtForm').prop('checked', true).change();
            }

        } else {
            error.value = data.error;
        }

        loading.value = false;
    });
};

onMounted(() => {
    if(data.value) {
        if (data.value.inputData) {
            inputData.value = data.value.inputData;
        }
        if (data.value.prescripteurForm) {
            // inputData.value.structure = App.Shared.getStructure() ? {structure: App.Shared.getStructure()} : null;
            inputData.value.dateEvaluation = Moment().format('DD/MM/YYYY');

            if(inputData.value.type === 'praticien') {
                inputData.value.numero = $('#prescripteur_prescripteur_am').val();
            } else if(inputData.value.type === 'etablissement') {
                inputData.value.numero = $('#prescripteur_finess').val();
            }
        }
    }

    // todo better condition
    if(inputData.value.structure && inputData.value.numero && inputData.value.type) {
        refresh();
    }
});

</script>
