'use strict';

import {createApp} from 'vue'
import Translator from "@/App/Translator";

App.Administration.Utilisateur = class {};

App.Administration.Utilisateur.View = class {
    constructor(params) {
        createApp({
            delimiters: ['[[', ']]'],
            data() {
                return {
                    clients: [],
                    me: null,
                    applications: App.Administration.applications,
                    clientsPage: 1,
                    clientsPerPage: 10,
                    clientsFields: [
                        {key: 'id', label: Translator.trans('libelle.id'), sortable: true},
                        {key: 'device', label: Translator.trans('libelle.appareil'), sortable: true},
                        {key: 'ipAddress', label: Translator.trans('libelle.adresse-ip'), sortable: true, thClass: 'text-nowrap'},
                        {key: 'date', label: Translator.trans('libelle.date'), sortable: true},
                        {key: 'application', label: Translator.trans('libelle.application'), sortable: true, tdClass: 'text-nowrap'},
                        {key: 'page', label: Translator.trans('libelle.page-courante'), sortable: true},
                        {key: 'etat', label: Translator.trans('libelle.etat'), sortable: true, tdClass: 'text-center'},
                    ],
                }
            },
            mounted() {
                App.webSocket.subscribe('administration/websocket', (uri, data) => {
                    if (data.action === 'list') {
                        this.me = data.me;
                        this.clients = data.clients.filter(client => client.user?.id === params.utilisateurId);
                    }
                });

                document.dispatchEvent(new Event('app:vuejs:mounted'));
            }
        }).mount('#app');
    }
};

