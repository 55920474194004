'use strict';

import swal from "sweetalert2";
import ladda from "ladda";
import Translator from "@/App/Translator";

App.Courrier = class {
    static initCourrierPopover(content, url) {
        let ajaxRequest = null;

        $('#courrierBtn').popover({
            placement: 'bottom',
            content,
            sanitize: false,
            html: true,
            title: Translator.trans('action.generer-courrier')
        }).on('show.bs.popover', function() {
            $($(this).data('bs.popover').tip).css('min-width', '20%');
        }).on('shown.bs.popover', () => {
            App.Layout.enableSelect2();

            let update = (action, btnLadda = null) => {
                $('#courrier_popover_action').val(action);

                if(action === 'form') {
                    $('#courrierFields').html('');
                    $('#previewBtn, #generateBtn').prop('disabled', true);
                    $('#courrierBtn').popover('update');
                    if (!$('#courrier_popover_modele').val()) {
                        return;
                    }
                }

                if(ajaxRequest) {
                    ajaxRequest.abort();
                    ajaxRequest = null;
                }

                ajaxRequest = $.ajax({
                    url,
                    method: 'POST',
                    data: $('#courrierForm').serialize(),
                }).done((data) => {
                    if(data.form) {
                        $('#courrierForm').replaceWith(data.form);
                        App.Layout.enableSelect2();
                        $('#courrierBtn').popover('update');
                    }
                    else if(data.preview) {
                        swal({
                            showConfirmButton: false,
                            showCancelButton: true,
                            cancelButtonText: Translator.trans('action.fermer'),
                            html: '<iframe width="100%" height="750" frameborder="0" src="data:text/html,' + encodeURIComponent(data.preview) + '"></iframe>',
                            width: '1000px',
                            onOpen: () => {
                                let $content = $(swal.getContent());
                                $content.removeClass('swal2-content');
                                $content.closest('.swal2-container').addClass('z-index-1100');
                            }
                        });
                    }
                    else if(data.success) {
                        $('#courrierForm').replaceWith(data.success.html);
                        let $printBtn = $('#courrierPrintBtn');
                        let printBtnLadda = ladda.create($printBtn[0]);

                        $printBtn.click(() => {
                            printBtnLadda.start();

                            App.Utils.print(data.success.url, data.success.fileName).then(() => {
                                printBtnLadda.stop();
                            });
                        });
                    }
                }).fail((request) => {
                }).always(() => {
                    if(btnLadda) {
                        btnLadda.stop();
                    }
                });
            };

            $('body')
                .on('click', '#previewBtn, #generateBtn', (e) => {
                    let btnLadda = ladda.create(e.currentTarget);
                    btnLadda.start();

                    update(e.currentTarget.dataset.action, btnLadda);
                })
                .on('change', '#courrier_popover_modele', () => {
                    update('form');
                })
            ;
        });

        $('body').on('click', function (e) {
            if (!$(e.target).is('#courrierBtn') && $(e.target).parents('#courrierBtn').length === 0 && !$(e.target).is('.swal2-container') && $(e.target).parents('.swal2-container').length === 0 && $(e.target).parents('.popover.show').length === 0) {
                $('#courrierBtn').popover('hide');
            }
        });
    }
};
