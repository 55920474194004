'use strict';

import Translator from "@/App/Translator";

App.Shared.Courrier = class {

};

App.Shared.Courrier.Index = class {
    constructor(params) {
        this.params = params;

        this._initContextMenu();
    }


    /**
     * Active le menu contextuel
     */
    _initContextMenu() {
        let $form = $('#formBatch');
        let $action = $('#'+this.params.action);

        let contextMenuLinks = {
            view: this.params.viewUrl,
            edit: this.params.editUrl,
            send: this.params.sendUrl,
        };

        $.contextMenu({
            selector: '#courriers tr',
            callback: function(key, options, e) {
                let id = $(this).data('id');

                if(key in contextMenuLinks) {
                    let url = contextMenuLinks[key].replace('_ID_', id);

                    if(e.which === 1 && !e.ctrlKey) {
                        window.location.href = url;
                    }
                    else if(e.which === 2 || (e.which === 1 && e.ctrlKey)) {
                        let win = window.open(url, '_blank');
                        if(win) win.focus();
                    }
                }

            },
            build: ($trigger) => {
                let id = $trigger.data('id');
                if(!id) return false;

                let roleView = $trigger.data('roleView');
                let roleEdit = $trigger.data('roleEdit');

                $trigger.addClass('focus');

                let items = {};
                let count = $('#courriers .ui-selected').length;


                if(count <= 1) {
                    if (roleView) {
                        items.view = {name: Translator.trans('action.voir'), icon: 'fa-solid fa-eye'};
                    }
                    if (roleEdit) {
                        items.edit = {name: Translator.trans('action.modifier'), icon: 'fa-solid fa-pencil'};
                    }
                }

                if(1 === count && (roleView || roleEdit)) {
                    items.sep1 = '';
                }

                if(count) {
                    items.count = {name: this.params.titles[count], disabled: true};
                    items.sep2 = '';

                    if(this.params.canSend) {
                        items.send = {name: this.params.marquerEnvoye, icon: 'fa-solid fa-check', callback: () => {
                            $action.val('send');
                            $form.submit();
                        }};
                    }
                    if(this.params.canExport) {
                        items.print = {name: Translator.trans('action.imprimer'), icon: 'fa-solid fa-print', callback: () => {
                            $action.val('print');
                            let url = $(location).attr('href') + '?' + decodeURI($form.serialize());

                            App.Utils.print(url);
                        }};
                        items.download = {name: Translator.trans('action.telecharger'), icon: 'fa-solid fa-download', callback: () => {
                            $action.val('download');
                            $form.submit();
                        }};
                    }
                }

                return {
                    items: items
                };
            },
            events: {
                hide: () => {
                    $('#courriers tr.focus').removeClass('focus');
                }
            }
        });
    }

};

App.Shared.Courrier.Form = class {
    constructor(params) {
        this.params = params;

        this._initAjax();
    }

    _initAjax() {
        let $form = $('#form');
        let $preview = $('#preview');
        let ajaxRequest = null;

        let update = (e) => {
            let $el = $(e.currentTarget);
            let url = this.params.ajax;

            if($el.is('#'+this.params.modele)) {
                $('#layoutData').html('');
                $('#previewPlaceholder').show();
                $('#preview').hide();
                $('#courrier_destinataire').html('');

                if(!$el.val()) {
                    return;
                }

                url += '?updateForm';
            } else if($el.closest('[data-field="typeDestinataire"]').length) {
                url += '?updateDestinataireForm';
                $('#destinataireFields').remove();
            }

            if(ajaxRequest) {
                ajaxRequest.abort();
                ajaxRequest = null;
            }

            ajaxRequest = $.ajax({
                url: url,
                method: 'POST',
                data: $form.serialize(),
            }).done((data) => {
                $preview.attr('src', 'data:text/html,' + encodeURIComponent(data.preview));
                $('#previewPlaceholder').hide();
                $('#preview').show();

                if(data.destinataireForm) {
                    $('#courrier_destinataire').html(data.destinataireForm);
                    App.Layout.enableSelect2();
                }
                if(data.layoutDataForm) {
                    $('#layoutData').replaceWith(data.layoutDataForm);
                    App.Layout.enableSelect2();
                    App.Layout.enableEmbedInline();
                    App.Layout.enableAutosize();
                    App.Layout.enableTrumbowyg();
                }
            }).fail((r, status) => {
                if('abort' !== status) {
                    $('#layoutData').html('<div class="row"><div class="col-sm-9 ml-auto text-danger"><i class="fa-solid fa-triangle-exclamation"></i> ' + this.params.erreur + '</div></div>');
                }
            });
        };

        $('body').on('change', '#form [name]', update).on('tbwblur', '.js-trumbowyg', update).on('submit', '#form', function () {
            if (ajaxRequest) {
                ajaxRequest.abort();
                ajaxRequest = null;
            }
        });
    }

};
