<template>
    <a v-if="trajet.transport.patient.id && trajet.roles && trajet.roles.ROLE_REGULATION_PATIENT_VIEW" :title="trajet.transport.patient.text" :target="target" :href="path('shared.patient.view', {app: 'regulation', id: trajet.transport.patient.id})" target="_blank">
        {{ trajet.transport.patient.text }}
    </a>
    <span v-else :title="trajet.transport.patient.text">
        {{ trajet.transport.patient.text }}
    </span>
</template>

<script>
    export default {
        props: ['trajet', 'target'],
    }
</script>