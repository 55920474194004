<template>
    <div>
        <template v-if="newLot">
            <animation-success></animation-success>
            <div class="row">
                <div class="col-6 mx-auto">
                    <div class="alert alert-success text-center">
                        <template v-if="canSendNewLot === null">Lot envoyé.</template>
                        <template v-else-if="newLot.estEnvoye">Lot créé et envoyé.</template>
                        <template v-else>Lot créé.</template>
                    </div>
                    <tableau-de-bord-widget-lot-title :lot="newLot"></tableau-de-bord-widget-lot-title>
                    <tableau-de-bord-widget-lot-item :lot="newLot"></tableau-de-bord-widget-lot-item>
                </div>
            </div>
            <div class="mt-5 text-center">
                <b-btn :href="path('facturation.lot.b2-sefi.view', {id: newLot.id})" variant="info" :disabled="loading">
                    <i class="fa-solid fa-eye mr-1"></i> {{ Translator.trans('action.voir') }}
                </b-btn>
                <b-btn variant="primary" v-if="canSendNewLot" :disabled="loading" @click="envoyerLot(newLot.id)">
                    <i class="fa-solid fa-paper-plane mr-1"></i>
                    {{ Translator.trans('action.envoyer') }}
                </b-btn>
                <b-btn variant="secondary" v-if="canExportNewLot" :disabled="loading" @click="printLot(newLot)">
                    <i class="fa-solid fa-print"></i>
                    {{ Translator.trans('action.imprimer-bordereau') }}
                </b-btn>
                <b-btn variant="secondary" class="js-close-swal" :disabled="loading">{{ Translator.trans('action.fermer') }}</b-btn>
            </div>
        </template>
        <template v-else>
            <div class="row">
                <div class="col-5 text-left">
                    <h4 class="box-lot-titre m-0 mb-3 text-ellipsis" :title="lot.caisse.libelle">{{ lot.caisse.libelle }}</h4>
                    <div>
                        <span v-if="type === 'suggestion'" class="badge badge-info"><i class="fa-regular fa-lightbulb mr-1"></i> Suggestion</span>
                        <span v-if="lot.numero !== null" class="badge badge-dark">{{ lot.numero }}</span>
                        <span class="badge badge-secondary">{{ lot.caisse.organismeRattachement }}</span>
                        <span class="badge badge-secondary" :title="lot.structure.libelleComplet">{{ lot.structure.libelle }}</span>
                    </div>
                </div>
                <div class="col-7">
                    <div class="row">
                        <div class="col-8">
                            <div class="box-lot-facture-nombre">
                                <template v-if="lot.id === null">{{ Euro(factures.reduce((montant,facture) => montant + parseFloat(facture.partCaisseMontant || 0), 0)) }} / </template>
                                {{ Euro(lot.montantTotalCaisse) }}
                            </div>
                            <div class="box-lot-facture-libelle text-muted">{{ Translator.trans('libelle.total-caisse') }}</div>
                        </div>
                        <div class="col-4">
                            <div class="box-lot-facture-nombre">
                                <template v-if="lot.id === null">{{ factures.length }} / </template>
                                {{ lot.standardCollection.length }}
                            </div>
                            <div class="box-lot-facture-libelle text-muted">{{ Translator.trans('libelle.factures') }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <b-table class="mt-4 table-compact" ref="factureTable" responsive no-sort-reset show-empty bordered striped :items="lot.standardCollection" :fields="fields" :per-page="perPage" :current-page="currentPage" sort-by="numero" primary-key="id">
                <template #empty="scope">
                    <span class="text-muted">{{ Translator.trans('libelle.aucun-element') }}</span>
                </template>
                <template #cell(patient)="{item}">
                    {{ item.patient.text }}
                </template>
                <template #head(checkbox)="scope">
                    <div class="checkbox">
                        <input type="checkbox" id="facture_checkbox_all" class="form-check-input" :checked="factures.length" @input="toggleAll" v-indeterminate="factures.length && factures.length !== lot.standardCollection.length">
                        <label for="facture_checkbox_all"></label>
                    </div>
                </template>
                <template #cell(checkbox)="{item}">
                    <div class="checkbox">
                        <input type="checkbox" :id="'facture_checkbox_'+item.id" class="form-check-input" v-model="factures" :value="item" :disabled="!isCheckable(item)" :title="isCheckable(item) ? '' : ('La limite de '+lot.structure.facturesParLot+' factures par lot a été atteinte.')">
                        <label :for="'facture_checkbox_'+item.id"></label>
                    </div>
                </template>
                <template #cell(partCaisseMontant)="{item}">
                    {{ Euro(item.partCaisseMontant) }}
                </template>
                <template #cell(action)="{item}">
                    <span class="text-nowrap">
                        <a :title="Translator.trans('action.voir')" class="btn btn-table btn-info" target="_blank" :href="path('facturation.facture.view', {id: item.id})">
                            <i class="fa-solid fa-fw fa-eye"></i>
                        </a>
                    </span>
                </template>
            </b-table>
            <table-pagination :count="lot.standardCollection.length" :per-page="perPage" v-model="currentPage"></table-pagination>
            <div class="mt-4 row" v-if="lot.id === null">
                <div class="col-4">
                    <div class="checkbox mt-2" v-if="canSend">
                        <input type="checkbox" id="envoyer" class="form-check-input" v-model="envoyer" :disabled="loading">
                        <label for="envoyer">{{ Translator.trans('libelle.envoyer-automatiquement') }}</label>
                    </div>
                </div>
                <div class="col-4 text-center">
                    <b-btn variant="success" @click="creerLot" :disabled="loading || !factures.length">
                        <i class="fa-solid fa-plus mr-1"></i> {{ Translator.trans('action.creer-lot') }}
                    </b-btn>
                    <b-btn variant="secondary" class="js-close-swal" :disabled="loading">{{ Translator.trans('action.fermer') }}</b-btn>
                </div>
                <div class="col-4"></div>
            </div>
            <div class="mt-4 text-center" v-else>
                <b-btn :href="path('facturation.lot.b2-sefi.view', {id: lot.id})" variant="info" :disabled="loading">
                    <i class="fa-solid fa-eye mr-1"></i> {{ Translator.trans('action.voir') }}
                </b-btn>
                <b-btn variant="primary" v-if="canSend" :disabled="loading" @click="envoyerLot(lot.id)">
                    <i class="fa-solid fa-paper-plane mr-1"></i>
                    {{ Translator.trans('action.envoyer') }}
                </b-btn>
                <b-btn variant="secondary" class="js-close-swal" :disabled="loading">{{ Translator.trans('action.fermer') }}</b-btn>
            </div>
        </template>
    </div>
</template>

<script setup>
import swal from "sweetalert2";
import Translator from "@/App/Translator";
import Euro from "@/Vue/Filters/Euro";
import Request from "@/App/Request";
import Router from "@/App/Router";
import {toRefs, onMounted, ref, computed} from "vue";

const emit = defineEmits(['refresh']);
const props = defineProps(['lot', 'type', 'canSend']);

const {lot, type, canSend} = toRefs(props);
const perPage = 15;
const currentPage = ref(1);
const fields = computed(() => [
    lot.value.id === null ? {key: 'checkbox'} : null,
    {key: 'numero', label: Translator.trans('libelle.numero'), sortable: true},
    {key: 'date', label: Translator.trans('libelle.date'), sortable: true},
    {key: 'patient', label: Translator.trans('libelle.patient'), sortable: true},
    {key: 'partCaisseMontant', label: Translator.trans('libelle.montant-caisse'), sortable: true},
    {key: 'action', label: Translator.trans('libelle.actions')},
]);
const envoyer = ref(true);
const loading = ref(false);
const factures = ref([]);
const newLot = ref(null);
const canSendNewLot = ref(null);
const canExportNewLot = ref(null);
const factureTable = ref();

const vIndeterminate = (el, binding) => {
    el.indeterminate = Boolean(binding.value);
}

const isCheckable = (item) => !loading.value && (factures.value.includes(item) || factures.value.length !== lot.value.structure.facturesParLot);

const toggleAll = () => {
    if(factures.value.length) {
        factures.value = [];
    } else {
        factures.value = factureTable.value.sortedItems.slice(0, lot.value.structure.facturesParLot);
    }
};

const envoyerLot = (id) => {
    loading.value = true;

    Request.getJson(Router.generate('facturation.tableau-de-bord.lot.envoyer', {id})).then((data) => {
        emit('refresh');

        if(data.success) {
            loading.value = false;

            newLot.value = data.lot;
            canSendNewLot.value = null;
            canExportNewLot.value = data.canExport;
        } else {
            swal.close();
        }
    });
};

const printLot = (lot) => {
    loading.value = true;

    App.Utils.print(Router.generate('facturation.lot.b2-sefi.bordereau', {id: lot.id, format: 'html'}), lot.bordereauFileName).then(() => {
        loading.value = false;
    });
};

const creerLot = () => {
    loading.value = true;
    let lot = {
        factureCollection: factures.value.map((item) => item.id),
    };
    if(canSend.value) {
        lot.envoyer = envoyer.value;
    }

    Request.postJson(Router.generate('facturation.tableau-de-bord.lot.creer'), {lot}).then((data) => {
        emit('refresh');

        if(data.success) {
            loading.value = false;

            newLot.value = data.lot;
            canSendNewLot.value = data.canSend;
            canExportNewLot.value = data.canExport;
        } else {
            swal.close();
        }
    });
};

onMounted(() => {
    if(lot.value.id === null) {
        toggleAll();
    }
});

</script>
