<template>
    <div class="box-lot" @click="emit('click')">
        <div class="px-3 pt-3 js-lot">
            <slot name="content"></slot>
        </div>
        <div class="box-lot-footer">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script setup>
const emit = defineEmits(['click']);
</script>