<template>
    <div v-if="currentTrajet" class="flex flex-col gap-y-2">
        <div class="flex gap-x-2">
            <div class="flex-1 font-medium">
                <div v-if="currentTrajet.estAnnule" class="bg-red-500 text-white text-center p-1 rounded-md">
                    <i class="fa-regular fa-circle-xmark mr-2"></i>{{ Translator.trans('regulation.trajet.etat.libelleComplet.annule') }}
                </div>
                <div v-else-if="currentTrajet.estAFaire" class="bg-yellow-500 text-white text-center p-1 rounded-md">
                    <i class="fa-regular fa-pen-to-square mr-2"></i>{{ Translator.trans('regulation.trajet.etat.libelleComplet.a-faire') }}
                </div>
                <div v-else-if="currentTrajet.estPret" class="bg-blue-500 text-white text-center p-1 rounded-md">
                    <i class="fa-solid fa-check mr-2"></i>{{ Translator.trans('regulation.trajet.etat.libelleComplet.pret') }}
                </div>
                <div v-else-if="currentTrajet.estEnCours" class="bg-blue-500 text-white text-center p-1 rounded-md">
                    <i class="fa-solid fa-hourglass mr-2"></i>{{ Translator.trans('regulation.trajet.etat.libelleComplet.enCours') }}
                </div>
                <div v-else-if="currentTrajet.estTermine" class="bg-green-500 text-white text-center p-1 rounded-md">
                    <i class="fa-solid fa-check mr-2"></i>{{ Translator.trans('regulation.trajet.etat.libelleComplet.termine') }}
                </div>
            </div>
            <Button title="Fermer le trajet courrant" icon="fa-solid fa-times" severity="secondary" class="!py-1" @click="selectTrajet(null)"/>
        </div>
        <div class="bg-gray-100 rounded-lg p-2 text-sm flex gap-x-2">
            <Canvas :items="canvasTrajetsAller" class="flex-1"></Canvas>
            <Canvas :items="canvasTrajetsRetour" class="flex-1"></Canvas>
        </div>
        <div class="grid grid-cols-2 gap-1">
            <Information class="col-span-2" :title="Translator.trans('libelle.bureau')" icon="fa-solid fa-building">
                <span :title="currentTrajet.structure.libelleComplet">
                    {{ currentTrajet.structure.libelle }}
                </span>
            </Information>
            <Information :title="Translator.trans('libelle.categorie')" :icon="currentTrajet.transport.categorie ? ('fa-solid '+currentTrajet.transport.categorie.classeIcone) : 'im-question6'">
                <i class="im-accessibility2" v-if="currentTrajet.transport.estTpmr"></i>
                <template v-if="currentTrajet.transport.categorie">
                    {{ currentTrajet.transport.categorie.libelle }}
                </template>
                <template v-else>
                    {{ Translator.trans('libelle.categorieInconnue') }}
                </template>
            </Information>
            <Information :title="Translator.trans('libelle.distance')" icon="fa-solid fa-route">
                <template v-if="currentTrajet.kilometre">
                    {{ currentTrajet.kilometre }} km
                </template>
            </Information>
            <Information :title="Translator.trans('libelle.motif')" icon="fa-regular fa-clipboard-question">
                {{ currentTrajet.transport.motif.text }}
            </Information>
            <Information v-if="currentTrajet.montantFactureTouteTaxe !== null" :title="Translator.trans('libelle.montant-trajet-ttc')" icon="fa-solid fa-euro">
                {{ Euro(currentTrajet.montantFactureTouteTaxe) }}
            </Information>
            <Information v-else-if="currentTrajet.estimationMontantTouteTaxe !== null" :title="Translator.trans('libelle.estimationMontantTouteTaxe')"  icon="fa-solid fa-euro">
                ~ {{ Euro(currentTrajet.estimationMontantTouteTaxe) }}
            </Information>
        </div>
        <trajet-timeline :data="currentTrajet.timeline" tailwind class="m-2"></trajet-timeline>

    </div>
    <EmptyPlaceholder v-else title="Aucun trajet sélectionné" description="Sélectionnez un trajet dans la liste pour afficher plus d'informations." icon="im-select2"></EmptyPlaceholder>
</template>

<script setup>
import {useRegulationStore} from "@/Vue/Stores/Regulation";
import {storeToRefs} from "pinia";
import EmptyPlaceholder from "@/Vue/Components/RegulationV2/Layout/EmptyPlaceholder.vue";
import Translator from "@/App/Translator";
import Canvas from '@/Vue/Components/RegulationV2/Sidebar/Trajet/Canvas';
import Information from '@/Vue/Components/RegulationV2/Sidebar/Trajet/Information';
import {computed} from "vue";
import Moment from "moment/moment";
import Button from "primevue/button";
import Euro from "@/Vue/Filters/Euro";

const regulationStore = useRegulationStore();
const {selectTrajet} = regulationStore;
const {currentTrajet, allTrajetIndexById, allTrajetCollection} = storeToRefs(regulationStore);

const trajetsSimulane = computed(() => {
    const trajets = [];
    const simultanes = (currentTrajet.value.simultane?.trajetCollection ?? []).filter(id => id !== currentTrajet.value.id);

    for (const id of simultanes) {
        const index = allTrajetIndexById.value[id];
        if (index) {
            trajets.push(allTrajetCollection.value[index]);
        }
    }

    return trajets;
});

const canvasTrajetsAller = computed(() => {
    const res = [];

    if (currentTrajet.value.type === 'aller') {
        res.push([currentTrajet.value, 'aller'])

        for (const trajet of trajetsSimulane.value) {
            res.push([trajet, 'simultane']);
        }
    } else if (currentTrajet.value.aller) {
        const trajet = currentTrajet.value.aller;
        trajet._topPriseEnChargeArrivee = trajet.topPriseEnChargeArrivee !== null ? Moment(trajet.topPriseEnChargeArrivee.date+' '+trajet.topPriseEnChargeArrivee.time, 'DD/MM/YYYY HH:mm') : null;
        trajet._topDestinationArrivee = trajet.topDestinationArrivee !== null ? Moment(trajet.topDestinationArrivee.date+' '+trajet.topDestinationArrivee.time, 'DD/MM/YYYY HH:mm') : null;

        res.push([trajet, 'aller']);
    }


    return res;
});

const canvasTrajetsRetour = computed(() => {
    const res = [];

    if (currentTrajet.value.type === 'retour') {
        res.push([currentTrajet.value, 'retour'])

        for (const trajet of trajetsSimulane.value) {
            res.push([trajet, 'simultane']);
        }
    } else if (currentTrajet.value.retour) {
        const trajet = currentTrajet.value.retour;
        trajet._topPriseEnChargeArrivee = trajet.topPriseEnChargeArrivee !== null ? Moment(trajet.topPriseEnChargeArrivee.date+' '+trajet.topPriseEnChargeArrivee.time, 'DD/MM/YYYY HH:mm') : null;
        trajet._topDestinationArrivee = trajet.topDestinationArrivee !== null ? Moment(trajet.topDestinationArrivee.date+' '+trajet.topDestinationArrivee.time, 'DD/MM/YYYY HH:mm') : null;

        res.push([trajet, 'retour']);
    }

    return res;
});
</script>