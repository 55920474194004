<template>
    <div class="d-flex flex-column" style="height: 300px">
        <chat-scroll-view :messages="messages">
            <ul class="chat-list mt-4 px-0" v-if="messages.length">
                <template v-for="message in messages">
                    <li class="px-2" :class="{odd: message.user.id === selfId}">
                        <div class="chat-body">
                            <div class="chat-text">
                                <h4 class="text-ellipsis">
                                    {{ message.user.text }}
                                </h4>
                                <p class="chat-message">{{ message.message }}</p>
                                <div class="chat-info mt-1">
                                    <div class="text-nowrap">
                                        <b :title="message.date + ' ' + message.time">{{ message.time }}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </template>
            </ul>
            <div class="text-center mt-5 pt-5" v-else>
                <i class="fa-4x im-pencil"></i>
                <p class="text-muted mt-3">Envoyez un premier message pour débuter une conversation.</p>
            </div>
        </chat-scroll-view>
        <div class="d-flex p-3 border-top border-light">
            <div class="flex-1">
                <input ref="messageInput" type="text" class="form-control" maxlength="140" placeholder="Message..." v-model="message" @keyup.enter="send">
            </div>
            <button type="button" class="btn btn-primary" @click="send" :disabled="'' === message"><i class="fa-solid fa-comment"></i></button>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref, toRefs} from "vue";

const props = defineProps({
    messages: Array,
})
const {messages} = toRefs(props);
const emit = defineEmits(['send']);
const selfId = App.Constants.USER_ID;

const messageInput = ref();
const message = ref('');
const send = () => {
    emit('send', message.value);
    message.value = '';
}

onMounted(() => {
    messageInput.value.focus();
});

</script>
