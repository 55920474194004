import Noty from 'noty';

App.Notification = class {
    
    static _noty(type, text) {
        Noty.setMaxVisible(10);
        
        return new Noty({
            text: text,
            type: type,
            theme: 'mint',
            layout: 'topRight',
            timeout: 6000,
            visibilityControl: false,
            animation: {
                open: function (promise) {
                    if(!document.hidden) {
                        $(this.barDom).addClass('noty_effects_open');
                    }

                    promise(function(resolve) {
                        resolve();
                    });
                },
                close: function (promise) {
                    $(this.barDom).removeClass('noty_effects_open').addClass('noty_effects_close');

                    setTimeout(function() {
                        promise(function (resolve) {
                            resolve();
                        });
                    }, 500);
                }
            }
        }).show();
    }

    static alert(text) {
        this._noty('alert', text);
    }

    static success(text) {
        this._noty('success', text);
    }
    
    static error(text) {
        this._noty('error', text);
    }
    
    static info(text) {
        this._noty('info', text);
    }

    static piece(text) {
        this._noty('info', text);
        document.dispatchEvent(new Event('piece:refresh'));
    }
    
    static warning(text) {
        this._noty('warning', text);
    }
    
    static animatedSuccess(title, text) {
        this._noty('alert', '<h6 class="text-center font-weight-normal">' + title + '</h6><div class="swal2-icon swal2-success swal2-animate-success-icon"><div class="swal2-success-circular-line-left" style="background: rgb(255, 255, 255);"></div><span class="swal2-success-line-tip swal2-animate-success-line-tip"></span> <span class="swal2-success-line-long swal2-animate-success-line-long"></span> <div class="swal2-success-ring"></div> <div class="swal2-success-fix" style="background: rgb(255, 255, 255);"></div><div class="swal2-success-circular-line-right" style="background: rgb(255, 255, 255);"></div></div><div class="alert alert-success text-center">' + text + '</div>');
    }
};
