<template>
    <div class="mini-cps backdrop-blur-lg dark:bg-black dark:bg-opacity-40 dark:text-surface-0/80" :class="{'mini-cps-btn': btn, 'mini-cps-active': active}" @click="emit('click', $event)">
        <div class="mini-cps-type">
            <div>{{ card.type }}</div>
        </div>
        <div class="mini-cps-data">
            <div class="mini-cps-name" :title="card.libelle">
                {{ card.libelle }}
            </div>
            <div class="mini-cps-serial">
                {{ card.numeroSerie }}
            </div>
            <div class="mini-cps-status dark:bg-white/10">
                <template v-if="card.info" >
                    <template v-if="card.info.online">
                        <template v-if="card.info.clientData">
                            <template v-if="card.info.clientData.locked">
                                <i class="fa-solid fa-lock text-warning text-yellow-500 fa-fw"></i>
                                <span :title="statusTitle">Verrouillée<template v-if="!btn"> ({{ card.info.device.name }})</template></span>

                            </template>
                            <template v-else>
                                <i class="fa-solid fa-unlock-keyhole text-success text-green-500 fa-fw"></i>
                                <span :title="statusTitle">Déverrouillée<template v-if="!btn"> ({{ card.info.device.name }})</template></span>
                            </template>
                        </template>
                        <template v-else>
                            <i class="fa-solid fa-check text-success text-green-500 fa-fw"></i>
                            <span :title="statusTitle">Connectée<template v-if="!btn"> ({{ card.info.device.name }})</template></span>
                        </template>
                    </template>
                    <template v-else>
                        <i class="fa-solid fa-xmark text-danger fa-fw text-red-500"></i>
                        <span title="Carte non présente dans le lecteur">Déconnectée</span>
                    </template>
                </template>
                <div v-else>
                    <i class="fa-solid fa-spinner fa-spin fa-fw"></i> Chargement…
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, toRefs} from "vue";

const emit = defineEmits(['click']);
const props = defineProps({
    card: Object,
    btn: Boolean,
    active: Boolean,
})
const {card} = toRefs(props);

const statusTitle = computed(() => card.value.info && card.value.info.device ? 'Carte présente dans le lecteur sur ' + card.value.info.device.name : '');
</script>
