'use strict';

App.Shared.Tournee = class {};
App.Shared.Tournee.Serie = class {};

App.Shared.Tournee.Serie.Form = class {
    constructor() {
        App.Shared.Serie.initTrajets();
    }
}

App.Shared.Tournee.View = class {
    constructor(params) {
        App.Shared.Serie.initGeneratePopover(params.generateContent);
        App.Shared.Serie.initCleanPopover(params.cleanContent);
    }
}
