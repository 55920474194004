import swal from "sweetalert2";
import Translator from "@/App/Translator";

App.PecPlus = class {
    constructor(ajaxPath) {
        this.ajaxPath = ajaxPath;
        this.ajaxRequest = null;

        this._init();
    }

    _init() {
        $('body').on('click', '.js-reporter-champ', function() {
            let $field = App.PecPlus._reporterChamp($(this));
            App.Layout.focusField($field);
        });
    }

    static _reporterChamp($btn) {
        let target = $btn.data('target');
        let $field = $('#' + target);
        if ($field.hasClass('js-select2-autocomplete')) {
            let option = new Option($btn.data('value'), $btn.data('id'), false, false);
            $field.html(option).trigger({
                type: 'select2:select',
                params: {data: $btn.data('data')}
            });
        } else {
            $field.val($btn.data('value'));
        }
        $field.change();

        $('.js-reporter-champ[data-target="' + target + '"]').removeClass('btn-success').addClass('btn-secondary').prop('disabled', false).find('i').attr('class', 'fa-regular fa-clone');
        $btn.removeClass('btn-secondary').addClass('btn-success').prop('disabled', true).find('i').attr('class', 'fa-solid fa-check');

        return $field;
    }

    static _showError(message, data = null, autoClose = false) {
        let params = {
            type: 'error',
            title: message,
        };

        if(data) {
            console.log(data);
            params.text = data.error;
        }

        if(autoClose) {
            params.timer = 2000;
            params.showConfirmButton = false;
        }

        swal.close();
        swal(params);
    }

    call(data) {
        if(this.ajaxRequest) {
            this.ajaxRequest.abort();
            this.ajaxRequest = null;
        }

        swal({
            imageUrl: App.Constants.PEC_PLUS_LOGO_PATH,
            imageHeight: 120,
            text: 'Connexion au service d\'aide à la prise en charge de l\'Assurance Maladie...',
            allowOutsideClick: false,
            onOpen: () => {
                swal.showLoading();

                this.ajaxRequest = $.ajax({
                    url: this.ajaxPath,
                    method: 'POST',
                    data: data,
                    cache: false
                }).fail(() => {
                    App.PecPlus._showError('Erreur lors de l\'appel au service.');
                }).done((data) => {
                    if(data.error) {
                        App.PecPlus._showError('Erreur lors de l\'appel au service', data);
                    } else {
                        swal.close();
                        swal({
                            imageUrl: App.Constants.PEC_PLUS_LOGO_PATH,
                            imageHeight: 120,
                            showConfirmButton: false,
                            showCancelButton: true,
                            cancelButtonText: Translator.trans('action.fermer'),
                            html: data.html,
                            onOpen: () => {
                                let $btn = $('<button type="button" class="swal2-styled bg-info">' + Translator.trans('action.tout-reporter') + '</button>');
                                $btn.click(function() {
                                    $('.tab-pane.active .js-reporter-champ').each(function() {
                                        App.PecPlus._reporterChamp($(this));
                                    });
                                    return false;
                                });
                                $(swal.getButtonsWrapper()).append($btn);
                            }
                        });

                        const $factureNaturePiecesJustificativesOuvertureDroits = $('#facture_naturePiecesJustificativesOuvertureDroits');
                        if ($factureNaturePiecesJustificativesOuvertureDroits.length) {
                            $factureNaturePiecesJustificativesOuvertureDroits.val(9).change();
                        }
                    }
                });
            }
        }).then(() => {
            if(this.ajaxRequest) {
                this.ajaxRequest.abort();
                this.ajaxRequest = null;
            }

            App.PecPlus._showError('Appel au service annulé.', null, true);
        });

    }
};
