import Request from "@/App/Request";

export function useRequest() {
    let controller = null;

    const abort = () => {
        if (controller) {
            controller.abort();
        }
    }

    const initRequest = () => {
        abort();
        controller = new AbortController();
    };

    const handleError = (reject) => {
        return (err) => {
            if (err.name !== 'AbortError') {
                reject(err);
            }
        };
    };

    const getJson = (url, options = {}) => {
        initRequest();
        return new Promise((resolve, reject) => {
            Request.getJson(url, {signal: controller.signal, ...options}).then(data => resolve(data)).catch(handleError(reject));
        });

    };
    const postJson = (url, data, options = {}) => {
        initRequest();
        return new Promise((resolve, reject) => {
            Request.postJson(url, data, {signal: controller.signal, ...options}).then(data => resolve(data)).catch(handleError(reject));
        });
    };
    const patchJson = (url, data, options = {}) => {
        initRequest();
        return new Promise((resolve, reject) => {
            Request.patchJson(url, data, {signal: controller.signal, ...options}).then(data => resolve(data)).catch(handleError(reject));
        });
    };

    return { getJson, postJson, patchJson, abort }
}
