'use strict';

import {createApp} from "vue";

App.Notifications = class {
    static init() {
        const notifications = document.getElementById('notifications');

        if (notifications) {

            createApp({
                template: '<notification-menu :init-data="initData"></notification-menu>',
                data() {
                    return {
                        initData: JSON.parse(notifications.dataset.data),
                    };
                },
            }).use(App.Pinia).mount(notifications);
        }
    }
}
