'use strict';

import ladda from "ladda";
import Euro from "@/Vue/Filters/Euro";

App.Facturation.Reglement = class {
    static initSums() {
        $('[data-field="reglementCollection"]').change(() => App.Facturation.Reglement.updateSums());

        App.Facturation.Reglement.updateSums();
    }

    static updateSums() {
        let sums = {};

        $('.js-embed-item').each(function() {
            const $type = $(this).find('[data-field="type"]');

            let sens;

            if ($type.hasClass('js-select2')) {
               sens = $type.find('option:selected').data('sens');
            } else {
                const data = $type.select2('data');
                sens = data.length ? data[0].sens : null;
            }

            const montant = ('debit' === sens ? -1 : 1) * parseFloat($(this).find('[data-field="montant"]').val().replace(',','.'));
            const origine = $(this).find('[data-field="origine"] input:checked').val();

            if (!isNaN(montant)) {
                sums.total = (sums.total || 0) + montant;

                if (origine) {
                    sums[origine] = (sums[origine] || 0) + montant;
                }
            }
        });

        $('.js-reglement-sum').each(function() {
            const key = $(this).data('origine') || 'total';
            const montant = undefined === sums[key] ? null : sums[key];

            if(null === montant) {
                $(this).text('-').addClass('text-muted');
            } else {
                $(this).text(Euro(montant)).removeClass('text-muted');
            }
        });
    }
};

App.Facturation.Reglement.New = class {
    constructor() {
        this._initEmbed();
        App.Facturation.Reglement.initSums();
    }

    _initEmbed() {
        let clearOrigine = ($el) => {
            $el.find('.js-reglement-origine').removeClass('js-reglement-origine');
            $el.find('.js-reglement-montant').remove();
            $el.find('input').off('change');
        };

        $('body').on('select2:select', '[data-field="facture"]', (e) => {
            let data = e.params.data;
            let $el = $(e.currentTarget);
            let $embedItem = $el.closest('.js-embed-item');
            let $origine = $embedItem.find('[data-field="origine"]');
            let $montant = $embedItem.find('[data-field="montant"]');

            clearOrigine($origine);

            let selectedFirst = false;

            for(let part of data.parts) {
                let $item = $origine.find('input[value="' + part.id + '"]');
                if(part.du > 0 || part.regle > 0) {
                    let color = part.regle === part.du ? 'success' : (part.regle < part.du ? 'danger' : 'warning');

                    $item.parent().attr('title', part.text);
                    $item.parent().addClass('js-reglement-origine').append('<div class="js-reglement-montant font-size-10px mt-1 text-' + color + '">' + App.String.euro(part.regle ?? 0, !(part.du ?? 0)) + (part.du ? (' / ' + App.String.euro(part.du)) : '') + '</div>');

                    if(part.du) {
                        $item.on('change', function () {
                            $montant.val(App.String.euro(part.du - part.regle, false));
                        });

                        if(!selectedFirst) {
                            $item.click();
                            selectedFirst = true;
                        }
                    }
                }
            }
        }).on('change', '[data-field="facture"]', function() {
            if(!$(this).val()) {
                clearOrigine($(this).closest('.js-embed-item').find('[data-field="origine"]'));
            }
        }).on('change', '#form input, #form select', function () {
            let $embedItem = $(this).closest('.js-embed-item');

            if($embedItem.is(':last-child') && !$embedItem.find('input, select').not('[data-field="commentaire"]').filter(function() { return this.value === ''; }).length) {
                $('.js-embed-add').click();
            }
        });

        $('.js-embed-add').on('embed.new', function(e, $item) {
            let $prev = $item.prev();

            $item.find('[data-field="date"]').val($prev.find('[data-field="date"]').val());
        });

        $('#form').submit(function() {
            $(this).find('.js-embed-item').filter(function() {
                return !$(this).find('[data-field="facture"]').val();
            }).find('[name]').removeAttr('name');
        });
    }
};

App.Facturation.Reglement.Edit = class {
    constructor() {
        this._initEmbed();
        App.Facturation.Reglement.initSums();
    }

    _initEmbed() {
        let handleItem = (e, item) => {
            let $item = $(item);
            $item.find('[data-field="origine"] input').each(function() {
                let montantDu = $(this).data('montantDu');
                let montantRegle = $(this).data('montantRegle');
                if(montantDu > 0 || montantRegle > 0) {
                    let color = montantRegle === montantDu ? 'success' : (montantRegle < montantDu ? 'danger' : 'warning');

                    $(this).parent().addClass('js-reglement-origine').append('<div class="js-reglement-montant font-size-10px mt-1 text-' + color + '">' + App.String.euro(montantRegle, !montantDu) + (montantDu ? (' / ' + App.String.euro(montantDu)) : '') + '</div>');

                    if(montantDu) {
                        $(this).on('change', function () {
                            $item.find('[data-field="montant"]').val(App.String.euro(montantDu - montantRegle, false));
                        });

                    }
                }
            });
        };

        $('.js-embed-add').on('embed.new', handleItem);
        $('.js-embed-item').each(handleItem);
    }
};

App.Facturation.Reglement.New.Generate = class {
    constructor(params) {
        this.params = params;

        this._initPrint();
    }

    _initPrint() {
        let $printBtn = $('#printBtn');
        let printBtnLadda = ladda.create($printBtn[0]);

        $printBtn.click(() => {
            printBtnLadda.start();

            let url = this.params.exportUrl + '?' + decodeURI($('#exportForm').serialize());

            App.Utils.print(url, this.params.filename).then(() => {
                printBtnLadda.stop();
            });
        });
    }
};
