import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import jQuery from 'jquery';
import vDragDrop from 'v-drag-drop';
import VueResource from "vue-resource";
import {createPinia, PiniaVuePlugin} from 'pinia'
import { configureCompat } from 'vue'
import {piniaHistory} from "./Vue/Plugins/PiniaHistory";

global.$ = jQuery;
global.jQuery = jQuery;

// Vendors

require('popper.js');
require('bootstrap');
require('datatables.net');

require('script-loader!jquery.dirtyforms');

require('./lib/sidebar-nav.js');
require('./lib/waves.js');
require('./lib/modernizr-custom.js');

require('./lib/jquery-ui.js'); // build custom
require('./lib/bootstrap4-editable.js');
require('./lib/leaflet.js');
require('./lib/leaflet.ptv.js');
require('./lib/leaflet.polylinedecorator.js');
require('./lib/leaflet.fa-markers.js');

require('script-loader!../../vendor/gos/web-socket-bundle/public/js/vendor/autobahn.min.js');
require('script-loader!../../vendor/gos/web-socket-bundle/public/js/websocket');

// Require App js
const requireClass = require.context('./App', true, /\.js$/);
requireClass.keys().forEach(fileName => {
    requireClass(fileName);
});


configureCompat({
    INSTANCE_ATTRS_CLASS_STYLE: false,
    COMPILER_IS_ON_ELEMENT: false,
    ATTR_FALSE_VALUE: false,
})

// Require components vue js
Vue.use(BootstrapVue);
Vue.use(vDragDrop);
Vue.use(VueResource);
Vue.use(PiniaVuePlugin);


App.Pinia = createPinia();
App.Pinia.use(piniaHistory);

const requireComponent = require.context('./Vue/Components', true, /\.vue$/);
requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName);
    let parts = fileName.split('/');
    parts.shift();

    Vue.component(parts.join('').replace('.vue', ''),componentConfig.default || componentConfig);
});

const requirePlugin = require.context('./Vue/Plugins', true, /\.js$/);
requirePlugin.keys().forEach(fileName => {
    const pluginConfig = requirePlugin(fileName);
    Vue.use(pluginConfig.default || pluginConfig);
});

// Init

let initDone = false;

const initApp = () => {
    const $body = $('body');
    const layout = $body.data('appLayout');
    const module = $body.data('appModule');
    const params = $body.data('appParams');

    if(params && params.vueJs) {
        document.addEventListener('app:vuejs:mounted', () => App.Layout.initApp(layout));
    } else {
        App.Layout.initApp(layout);
    }

    if(!initDone) {
        document.dispatchEvent(new Event('app:init'));
        initDone = true;

        App.Notifications.init();
        App.Cps.init();

    }

    if(module) {
        let c = App;

        for (let key of module.split('.')) {
            c = c[key];
        }

        console.log('Autoloaded module "' + module + '"');
        App.module = new c(params);
    }

    document.dispatchEvent(new Event('app:load'));
};

if(TURBO_ENABLED) {
    require('@hotwired/turbo');

    document.addEventListener('turbo:render', (e) => {
        initApp();
        if(App.webSocket) {
            App.webSocket.publish('/', {
                action: 'update',
                page: {
                    title: document.title,
                    url: window.location.href,
                    application: App.Constants.APPLICATION,
                    version: App.Constants.VERSION,
                },
            });
        }
    });
}

$(initApp);
