<template>
    <span class="bage badge-info line-height-1 py-2 px-2 font-size-12px font-weight-normal text-transform-none" v-if="isToday">Aujourd'hui</span>
    <span class="bage badge-secondary line-height-1 py-2 px-2 font-size-12px font-weight-normal text-transform-none" v-else-if="isYesterday">Hier</span>
    <span class="bage badge-primary line-height-1 py-2 px-2 font-size-12px font-weight-normal text-transform-none" v-else-if="isTomorrow">Demain</span>
    <span class="bage badge-secondary line-height-1 py-2 px-2 font-size-12px font-weight-normal" v-else>{{ date }}</span>
</template>

<script setup>
import {computed, toRefs} from "vue";
import Moment from "moment";

const props = defineProps({
    date: String,
})

const {date} = toRefs(props);

const isToday = computed(() => date.value === Moment().format('DD/MM/YYYY'));
const isYesterday = computed(() => date.value === Moment().subtract(1, 'day').format('DD/MM/YYYY'));
const isTomorrow = computed(() => date.value === Moment().add(1, 'day').format('DD/MM/YYYY'));
</script>
