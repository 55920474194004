<template>
    <input ref="input" data-inputmask="'alias': 'custom-datetime'" data-inputmask-inputformat="HH:MM" type="text" class="form-control" :class="{'js-clockpicker': clockpicker}">
</template>

<script setup>
import {onMounted, onUnmounted, toRefs, ref, watch} from "vue";

const emit = defineEmits(['input'])
const props = defineProps(['value', 'event', 'clockpicker']);

const input = ref();
const {value, event, clockpicker} = toRefs(props);

watch(value, () => {
    $(input.value).val(value.value);
});

onMounted(() => {
    App.Layout.enableCustomInputs();

    $(input.value).on(event.value ? event.value : 'change', (e) => {
        emit('input', e.currentTarget.value)
    }).val(value.value);
});

onUnmounted(() => {
    if(clockpicker.value) {
        $(input.value).clockpicker('remove');
    }
})
</script>
