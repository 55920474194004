<template>
    <div @click="menu.toggle" :class="{'border border-red-500': null !== utilisateur.impersonator}" class="z-10 bg-white bg-opacity-50 dark:bg-black dark:bg-opacity-40 overflow-hidden backdrop-blur-lg flex flex-col rounded-xl shadow-md h-[55px] hover:shadow-lg hover:bg-opacity-70 transition cursor-pointer select-none">
        <div class="bg-red-500 px-1 bg-opacity-80 text-center text-xs text-white font-medium truncate w-0 min-w-full flex-none leading-tight" v-if="utilisateur.impersonator" :title="utilisateur.impersonator.text">
            <i class="fa-solid fa-user-secret fa-fw"></i>
            {{ utilisateur.impersonator.text }}
        </div>
        <div class="px-2 flex items-center flex-1">
            <img :src="utilisateur.avatar" referrerpolicy="no-referrer" :style="{background: 'url('+utilisateur.avatarBase+') center / 100%'}" class="rounded-full h-10 w-10">
            <div class="font-medium text-gray-700 dark:text-white/80 flex-col justify-center hidden md:flex ml-2">
                <div class="leading-tight">{{ utilisateur.prenom }}</div>
                <div class="text-sm leading-tight">{{ utilisateur.nom }}</div>
            </div>
            <div class="ml-3 text-gray-500 dark:text-gray-200">
                <i class="fa-solid fa-ellipsis-vertical"></i>
            </div>
        </div>
    </div>

    <TieredMenu :model="items" popup ref="menu"/>
</template>

<script setup>
import TieredMenu from 'primevue/tieredmenu';
import {ref, toRefs, computed} from "vue";
import Router from "@/App/Router";

const menu = ref();
const props = defineProps({
    utilisateur: Object,
});

const {utilisateur} = toRefs(props);
const items = computed(() => {
    const res = [
        {label: 'Mon profil', icon: 'fa-solid fa-user fa-fw', url: Router.generate('mon-profil.view'), target: '_blank'},
        {separator: true},
        {label: 'Assistance', icon: 'fa-regular fa-life-ring fa-fw', url: Router.generate('assistance'), target: '_blank'},
        {label: 'Documentation', icon: 'fa-solid fa-graduation-cap fa-fw', url: Router.generate('ressources.documentation'), target: '_blank'},
        {separator: true},
        {label: 'Se déconnecter', icon: 'fa-solid fa-right-from-bracket fa-fw', url: Router.generate('logout')},
    ];

    if (utilisateur.value.impersonator) {
        res.push({label: 'Quitter ce profil', icon: 'fa-solid fa-user-secret fa-fw', url: Router.generate('homepage', {'_switch_user': '_exit'})});
    }

    return res;
});
</script>