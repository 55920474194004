'use strict';

App.Geoloc.Point = class {
    constructor(infos) {
        this.lat = null;
        this.lng = null;
        this.adresse = null;
        this.ville = null;
        this.codePostal = null;
        this.name = null;
        this.bureau = null;
        this.direction = null;
        this.colorClass = null;
        this.code = null;

        for (let i in infos) {
            this[i] = infos[i];
        }

        if(!this.id) {
            this.id = this.type;
        }

        this.configureType();
    }

    getLibelle(expanded) {
        let libelle = '';

        switch(this.type){
            case 'bureau':
                libelle = this.name;
                break;
            case 'vehicule':
                libelle = this.name;
                break;
            case 'depart':
            case 'arrivee':
            case 'recherche':
                libelle = (this.adresse && this.adresse !== '' ? this.adresse + ', ' : '') + (this.ville && this.ville !== '' ? this.ville : '') + (expanded && this.codePostal && this.codePostal !== '' ? ' (' + this.codePostal + ')' : '');
                break;
        }

        if(libelle === '') {
            libelle = '[' + this.lat + ' - ' + this.lng + ']';
        }

        return libelle;
    }

    getPopup() {
        let html = '';

        switch(this.type){
            case 'bureau':
                html += '<div class="mb-3"><b>' + GEOLOC_CONST.libelles[this.type] + '</b> : ' + this.getLibelle() + '</div>';
                break;
            case 'vehicule':
                html += '<div><b>Libellé</b> : ' + this.getLibelle() + '</div>';
                html += '<div class="mb-3"><b>' + GEOLOC_CONST.libelles['bureau'] + '</b> : ' + this.bureau + '</div>';
                break;
            case 'depart':
            case 'arrivee':
            case 'recherche':
                if(this.adresse && this.adresse !== '') {
                    html += '<div><b>' + GEOLOC_CONST.libelles['adresse'] + '</b> : ' + this.adresse + '</div>';
                }
                if(this.ville && this.ville !== '') {
                    html += '<div><b>' + GEOLOC_CONST.libelles['ville'] + '</b> : ' + this.ville + '</div>';
                }
                if(this.codePostal && this.codePostal !== '') {
                    html += '<div class="mb-3"><b>' + GEOLOC_CONST.libelles['codePostal'] + '</b> : ' + this.codePostal + '</div>';
                }
                break;
        }

        if (this.type !== 'recherche' && this.adresse && this.adresse !== '') {
            html += '<div><b>' + GEOLOC_CONST.libelles['adresse'] + '</b> : ' + (this.adresse ? this.adresse : '') + (this.adresse && this.ville ? ', ' : '') + (this.codePostal ? this.codePostal + ' ' : '') + (this.ville ? this.ville : '') + '</div>';
        }
        html += '<div><b>' + GEOLOC_CONST.libelles['latitude'] + '</b> : ' + this.lat + '</div>';
        html += '<div class="mb-3"><b>' + GEOLOC_CONST.libelles['longitude'] + '</b> : ' + this.lng + '</div>';

        if(GEOLOC_CONST.droits['ROLE_GEOLOCALISATION_ITINERAIRE']) {
            html += '<div><a href="#" class="js-xroute-depuis" data-point="' + this.id + '">' + GEOLOC_CONST.libelles['itineraire-depuis'] + '</a></div>';
            html += '<div><a href="#" class="js-xroute-vers" data-point="' + this.id + '">' + GEOLOC_CONST.libelles['itineraire-vers'] + '</a></div>';
        }

        return L.popup()
            .setLatLng([this.lat, this.lng])
            .setContent(html);
    }

    getIcon() {
        if(this.type === 'position') {
            let icon = "circle2";
            if(this.direction && this.direction !== '') {
                let rose = {
                    'N': 'circle-up4',
                    'NE': 'circle-up-right2',
                    'E': 'circle-right4',
                    'SE': 'circle-down-right2',
                    'S': 'circle-down4',
                    'SW': 'circle-down-left2',
                    'W': 'circle-left4',
                    'NW': 'circle-up-left2'
                };

                icon = rose[this.direction];
            }

            return new L.DivIcon({
                className: 'text-dark fa-lg',
                html: '<i class="im-' + icon + '"></i><div class="bg-white"></div>'
            });
        }

        return new L.icon.fontAwesome({
            text: this.code,
            iconClasses: (this.iconClass ? this.iconClass + (this.colorClass ? ' ' + this.colorClass : '') : 'im-star-full'),
            markerColor: (this.markerColor ? this.markerColor : null),
            iconColor:  (this.iconColor ? this.iconColor : (this.iconClass ? null : '#FFF')),
        });
    }

    getMarker() {
        let marker = L.marker(
            [this.lat, this.lng],
            {icon: this.getIcon()}
        );

        if(this.type !== 'position') {
            marker.bindPopup(this.getPopup());
            let _this = this;
            marker
                .on('popupopen', function(){
                    App.webSocket.publish('geoloc/vehicules/geojson', { popup: _this.id });
                })
                .on('popupclose', function(){
                    App.webSocket.publish('geoloc/vehicules/geojson', { popup: '' });
                });
        }
        return marker;
    }

    configureType(type) {
        if(type) {
            this.type = type;
        }

        switch(this.type){
            case 'bureau':
                this.iconClass = 'im-office';
                this.markerColor = '#686868';
                break;
            case 'depart':
                this.iconClass = 'im-flag';
                this.markerColor = '#686868';
                this.iconColor = '#77baa4';
                break;
            case 'arrivee':
                this.iconClass = 'im-flag';
                this.markerColor = '#686868';
                this.iconColor = '#ea8484';
                break;
            case 'recherche':
                this.iconClass = 'im-star-full';
                this.markerColor = '#00a9ce';
                this.iconColor = '#00a9ce';
                break;
        }
    }
};