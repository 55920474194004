'use strict';

import Cookies from 'js-cookie';
import ladda from 'ladda';
import swal from 'sweetalert2';
import Moment from "moment";
import Fuse from "fuse.js";
import Router from "@/App/Router";
import 'form-serializer';
import queue from 'async/queue';
import _ from 'lodash';
import Translator from "@/App/Translator";
import Request from "@/App/Request";
import {useCpsStore} from "@/Vue/Stores/Cps";
import {computed, createApp, nextTick, provide, onMounted, ref, watch} from 'vue'
import Euro from "@/Vue/Filters/Euro";
import Capitalize from "@/Vue/Filters/Capitalize";

App.Facturation.Facture = class {
    static initSefiFlash(params) {5
        if(params.sefiFlash) {
            App.Sefi.openRequestSwal('ft', 'valider', {id: params.id}, params.sefiFlash);
        }
    }

    static initTrajets(_this) {
        const el = document.getElementById('app')

        _this.vm = createApp({
            delimiters: ['[[', ']]'],
            setup() {
                const initData = JSON.parse(el.dataset.data);

                const mode = initData.mode;
                const token = initData.token;
                const initMethod = initData.initMethod;
                const factureType = initData.factureType;
                const factureId = initData.factureId;
                const trajetSkeletonJSON = initData.trajetSkeletonJSON;
                const searchSkeletonJSON = initData.searchSkeletonJSON;
                const supplements = initData.supplements;
                const estFacturationAigAhg = initData.estFacturationAigAhg;
                const searchChoices = initData.searchChoices;
                const trajetChoices = initData.trajetChoices;
                let newId = initData.newId;

                const searchTable = ref();

                const structure = ref(null);
                const structureElement = ref(null);
                const patient = ref(null);
                const searchQuery = ref('');
                const trajets = ref({});
                const formValidation = ref({});
                const submitInProgress = ref(false);

                const groups = ref({
                    'libre': {
                        label: 'Libre', tarification: 0, tarificationLabel: 'Autre', icon: 'fa-fw fa-solid fa-star', color: 'dark', busy: false,
                            presets: {
                        default: {title: 'Trajet', cols: ['pec2', 'dest2', 'kilometre'], defaultSortColumn: 'pec2'},
                            equipage: {title: 'Équipage', cols: ['pec', 'vehicule', 'personnel1', 'personnel2'], defaultSortColumn: 'pec'},
                            caracteristiques: {title: 'Caractéristiques', cols: ['pec', 'patient', 'motif', 'attente', 'patientTransporte', 'caracteristiques'], defaultSortColumn: 'pec'},
                            tarification: {title: 'Tarification', cols: ['pec', 'forfaitMontant', 'attenteMontant', 'remise'], defaultSortColumn: 'pec'},
                            supplements: {title: 'Suppléments', cols: ['pec', 'supplementPeage', 'supplementRemboursable', 'supplementNonRemboursable'], defaultSortColumn: 'pec'},
                            modeTarification: {title: 'Mode', cols: ['pec', 'dest', 'tarification'], defaultSortColumn: 'pec'},
                        },
                        currentPreset: 'default',
                            cols: {
                            customAvailable: ['pec', 'pec2', 'dest', 'dest2', 'vehicule', 'personnel1', 'personnel2', 'tarification', 'motif', 'attente', 'caracteristiques'],
                                custom: [],
                        },
                    },
                    'ambu': {
                        label: 'AMBU', tarification: 1, tarificationLabel: 'AMBU', icon: 'fa-fw im-ambulance', color: 'info', busy: false,
                            presets: {
                        default: {title: 'Trajet', cols: ['pec2', 'dest2', 'kilometre'], defaultSortColumn: 'pec2'},
                            equipage: {title: 'Équipage', cols: ['pec', 'vehicule', 'personnel1', 'personnel2'], defaultSortColumn: 'pec'},
                            caracteristiques: {title: 'Caractéristiques', cols: ['pec', 'patient', 'motif', 'attente', 'patientTransporte', 'caracteristiques'], defaultSortColumn: 'pec'},
                            tarification: {title: 'Tarification', cols: ['pec', 'typeForfait', 'attenteMontant', 'remise'], defaultSortColumn: 'pec'},
                            supplements: {title: 'Suppléments', cols: ['pec', 'supplementPeage', 'supplements', 'supplementRemboursable'], defaultSortColumn: 'pec'},
                            modeTarification: {title: 'Mode', cols: ['pec', 'dest', 'tarification'], defaultSortColumn: 'pec'},
                        },
                        currentPreset: 'default',
                            cols: {
                            customAvailable: ['tarification'],
                                custom: [],
                        },
                    },
                    'vsl': {
                        label: 'VSL', tarification: 2, tarificationLabel: 'VSL', icon: 'fa-fw im-car', color: 'info', busy: false,
                            presets: {
                        default: {title: 'Trajet', cols: ['pec2', 'dest2', 'kilometre'], defaultSortColumn: 'pec2'},
                            equipage: {title: 'Équipage', cols: ['pec', 'vehicule', 'personnel1', 'personnel2']},
                            caracteristiques: {title: 'Caractéristiques', cols: ['pec', 'patient', 'motif', 'attente', 'patientTransporte', 'caracteristiques']},
                            tarification: {title: 'Tarification', cols: ['pec', 'typeForfait', 'attenteMontant', 'remise']},
                            supplements: {title: 'Suppléments', cols: ['pec', 'supplementPeage', 'supplements', 'supplementRemboursable', 'supplementTpmr']},
                            modeTarification: {title: 'Mode', cols: ['pec', 'dest', 'tarification'], defaultSortColumn: 'pec'},
                        },
                        currentPreset: 'default',
                            cols: {
                            customAvailable: ['tarification'],
                                custom: [],
                        },
                    },
                    'taxi': {
                        label: 'TAXI', tarification: 3, tarificationLabel: 'TAXI', icon: 'fa-fw im-car2', color: 'warning', busy: false,
                            presets: {
                        default: {title: 'Trajet', cols: ['pec2', 'dest2', 'taxiKilometreApproche', 'kilometre'], defaultSortColumn: 'pec2'},
                            equipage: {title: 'Équipage', cols: ['pec', 'vehicule', 'personnel1', 'personnel2'], defaultSortColumn: 'pec'},
                            caracteristiques: {title: 'Caractéristiques', cols: ['pec', 'patient', 'motif', 'attente', 'patientTransporte', 'caracteristiques'], defaultSortColumn: 'pec'},
                            tarification: {title: 'Tarification', cols: ['pec', 'taxiCalculatrice', 'taxiForfait', 'taxiCompteurMontant', 'taxiTarif'], defaultSortColumn: 'pec'},
                            supplements: {title: 'Suppléments', cols: ['pec', 'supplementPeage', 'attenteMontant', 'supplementTaxiPriseEnCharge', 'supplementTpmr'], defaultSortColumn: 'pec'},
                            autreSupplements: {title: 'Autre suppléments', cols: ['pec', 'supplementTaxiAnimalMontant', 'supplementTaxiBagageMontant', 'supplementTaxiPassagerMontant', 'supplementTaxiReservationMontant'], defaultSortColumn: 'pec'},
                            modeTarification: {title: 'Mode', cols: ['pec', 'dest', 'tarification'], defaultSortColumn: 'pec'},
                        },
                        currentPreset: 'default',
                            cols: {
                            customAvailable: ['tarification'],
                                custom: [],
                        },
                    },
                });
                const fields = ref({
                    '_etatValide': function(context) {
                        return {component: 'etat-valide', viewColProps: {class: 'width-1px'}, formColProps: {class: 'width-1px vertical-middle'}, props: {trajet: context.trajet}, formProps: {onClick: () => onEtatValideClick(context.trajet)}};
                    },
                    '_montantTouteTaxe': function(context) {
                        return  {component: 'trajet-montant', props: {trajet: context.trajet}, viewColProps: {class: 'width-1px text-right text-nowrap'}, formColProps: {class: 'width-1px text-right text-nowrap vertical-middle'}}
                    },
                    '_calculatriceTaxi': function(context) {
                        const trajet = context.trajet;
                        const origin = context.origin;

                        return {formComponent: 'calculatriceTaxi', formColProps: {class: 'width-1px'}, formProps: {trajet, origin, modes: trajetChoices.taxiModeCalcul}};
                    },
                    '_niveauCertificationGeolocalisation': function(context) {
                        const trajet = context.trajet;
                        const origin = context.origin;

                        return {component: 'niveauCertificationGeolocalisation', formColProps: {class: 'width-1px'}, formProps: {form: true}, props: {trajet, origin, standard: standard.value}};
                    },
                    'vehicule': function(context) {
                        return {viewComponent: 'entity', viewProps: {route: 'referentiel.vehicule.view'}, formColProps: {class: 'min-width-150px width-180px'}, formComponent: 'select2-autocomplete', formProps: {class: 'input-sm', 'placeholder': 'Véhicule', route: 'facturation.facture.vehicule.ajax', routeParams: {structure: structure.value, tarification: getTarificationForContext(context)}}};
                    },
                    'personnel1': function(context) {
                        const trajet = context.trajet;
                        return {viewComponent: 'entity', viewProps: {route: 'referentiel.personnel.view'}, formColProps: {class: 'min-width-150px width-180px'}, formComponent: 'select2-autocomplete', formProps: {class: 'input-sm', 'placeholder': 'Personnel 1', route: 'facturation.facture.personnel.ajax', routeParams: {structure: structure.value, tarification: getTarificationForContext(context), date: trajet ? trajet.topPriseEnChargeDepartReel.date+' '+trajet.topPriseEnChargeDepartReel.time : '', personnel2: trajet?.personnel2?.id ?? null}}};
                    },
                    'personnel2': function(context) {
                        const trajet = context.trajet;
                        return {viewComponent: 'entity', viewProps: {route: 'referentiel.personnel.view'}, formColProps: {class: 'min-width-150px width-180px'}, formComponent: 'select2-autocomplete', formProps: {class: 'input-sm', 'placeholder': 'Personnel 2', route: 'facturation.facture.personnel.ajax', routeParams: {structure: structure.value, tarification: getTarificationForContext(context), date: trajet ? trajet.topPriseEnChargeDepartReel.date+' '+trajet.topPriseEnChargeDepartReel.time : '', personnel1: trajet?.personnel1?.id ?? null}}};
                    },
                    'contrat': function() {
                        return {viewComponent: 'entity', viewProps: {route: 'facturation.contrat.view'}, formColProps: {class: 'min-width-150px width-180px'}, formComponent: 'select2-autocomplete', formProps: {clearFieldsUnselect: ['lot'], class: 'input-sm', 'placeholder': 'Contrat', route: 'facturation.facture.contrat.ajax', routeParams: {structure: structure.value}}};
                    },
                    'adressePriseEnCharge': function(context) {
                        return {formComponent: 'geocodage-input', formColProps: {class: 'min-width-110px'}, formProps: {disabled: isContextSefiPel(context), 'geocodageSuffix': 'PriseEnCharge', 'type': 'textarea', 'placeholder': 'Adresse', 'class': 'input-sm ' + ('popover' === context.origin ? 'resize-none height-65px pb-4px' : 'js-autosize-focus')}};
                    },
                    'complementAdressePriseEnCharge': function(context) {
                        return {formProps: {'disabled': isContextSefiPel(context), class: 'input-sm', placeholder: 'Complément'}};
                    },
                    'codePostalPriseEnCharge': function(context) {
                        return {formComponent: 'geocodage-input', formProps: {disabled: isContextSefiPel(context), class: 'input-sm', 'geocodageField': 'postcode', 'geocodageType': 'municipality', 'geocodageSuffix': 'PriseEnCharge', 'placeholder': 'CP'}};
                    },
                    'villePriseEnCharge': function(context) {
                        return {formComponent: 'geocodage-input', formColProps: {class: 'min-width-80px'}, formProps: {disabled: isContextSefiPel(context), class: 'input-sm', 'geocodageField': 'city', 'geocodageType': 'municipality', 'geocodageSuffix': 'PriseEnCharge', 'placeholder': 'Ville'}};
                    },
                    'digicodePriseEnCharge': function(context) {
                        return {formProps: {disabled: isContextSefiPel(context), class: 'input-sm', placeholder: 'Digicode'}};
                    },
                    'etagePriseEnCharge': function(context) {
                        return {formProps: {disabled: isContextSefiPel(context), class: 'input-sm', placeholder: 'Étage'}};
                    },
                    'precisionPriseEnCharge': function(context) {
                        return {viewComponent: 'indicateur-geocodage', formComponent: 'geocodage-button', formProps: {
                            context,
                            suffix: 'PriseEnCharge',
                        }, viewColProps: {class: 'width-1px'}, formColProps: {class: 'width-1px'}, props: {class: 'mt-2'}};
                    },
                    'adresseDestination': function(context) {
                        return {formComponent: 'geocodage-input', formColProps: {class: 'min-width-110px'}, formProps: {disabled: isContextSefiPel(context), 'geocodageSuffix': 'Destination', 'type': 'textarea', 'placeholder': 'Adresse', 'class': 'input-sm ' + ('popover' === context.origin ? 'resize-none height-65px pb-4px' : 'js-autosize-focus')}};
                    },
                    'complementAdresseDestination': function(context) {
                        return {formProps: {disabled: isContextSefiPel(context), class: 'input-sm', placeholder: 'Complément'}};
                    },
                    'codePostalDestination': function(context) {
                        return {formComponent: 'geocodage-input', formProps: {disabled: isContextSefiPel(context), class: 'input-sm', 'geocodageField': 'postcode', 'geocodageType': 'municipality', 'geocodageSuffix': 'Destination', 'placeholder': 'CP'}};
                    },
                    'villeDestination': function(context) {
                        return {formComponent: 'geocodage-input', formColProps: {class: 'min-width-80px'}, formProps: {disabled: isContextSefiPel(context), class: 'input-sm', 'geocodageField': 'city', 'geocodageType': 'municipality', 'geocodageSuffix': 'Destination', 'placeholder': 'Ville'}};
                    },
                    'digicodeDestination': function(context) {
                        return {formProps: {disabled: isContextSefiPel(context), class: 'input-sm', placeholder: 'Digicode'}};
                    },
                    'etageDestination': function(context) {
                        return {formProps: {disabled: isContextSefiPel(context), class: 'input-sm', placeholder: 'Étage'}};
                    },
                    'precisionDestination': function(context) {
                        return {viewComponent: 'indicateur-geocodage', formComponent: 'geocodage-button', formProps: {
                            context,
                            suffix: 'Destination',
                        }, viewColProps: {class: 'width-1px'}, formColProps: {class: 'width-1px'}, props: {class: 'mt-2'}};
                    },
                    'topPriseEnChargeDepartReel.date': function(context) {
                        const trajet = context.trajet;
                        return {onBatchApply: (data) => {
                                data['topDestinationArriveeReel.date'] = data['topPriseEnChargeDepartReel.date'];
                                computeTaxiTarifDetail(trajet, data);
                            }, onUpdate: (data) => {
                                data['topDestinationArriveeReel.date'] = data['topPriseEnChargeDepartReel.date'];
                                computeTaxiTarifDetail(trajet);
                            }, formComponent: 'date', formColProps: {class: 'width-110px min-width-110px'}, formProps: {picker: true, placeholder: 'Date', class: 'input-sm text-center'}};
                    },
                    'topPriseEnChargeDepartReel.time': function(context) {
                        const trajet = context.trajet;
                        return {onBatchApply: (data) => computeTaxiTarifDetail(trajet, data), onUpdate: () => computeTaxiTarifDetail(trajet), formComponent: 'time', formColProps: {class: 'width-80px min-width-80px'}, formProps: {placeholder: 'Heure', class: 'input-sm text-center'}};
                    },
                    'topDestinationArriveeReel.date': function(context) {
                        const trajet = context.trajet;
                        return {onBatchApply: (data) => computeTaxiTarifDetail(trajet, data), onUpdate: () => computeTaxiTarifDetail(trajet), formComponent: 'date', formColProps: {class: 'width-110px min-width-110px'}, formProps: {'picker': true, 'placeholder': 'Date', class: 'input-sm text-center'}};
                    },
                    'topDestinationArriveeReel.time': function(context) {
                        const trajet = context.trajet;
                        return {onBatchApply: (data) => computeTaxiTarifDetail(trajet, data), onUpdate: () => computeTaxiTarifDetail(trajet), formComponent: 'time', formColProps: {class: 'width-80px min-width-80px'}, formProps: {'placeholder': 'Heure', class: 'input-sm text-center'}};
                    },
                    'remise': {viewComponent: 'remise', formComponent: 'remise', formProps: {class: 'input-sm', placeholder: 'Remise'}},
                    'tarification': function(context) {
                        const choices = [{key: '1', label: 'AMBU'}, {key: '2', label: 'VSL'}, {key: '3', label: 'TAXI'}];
                        if (isFactureLibre || context.trajet.tarification === '0') {
                            choices.push({key: '0', label: 'Autre'});
                        }
                        return {onUpdate: (data) => {
                                data.tarificationLibre = data.tarification === '0';
                            }, formComponent: 'btn-group', formProps: {class: 'input-sm', type: 'radio', choices}, viewComponent: 'choice', viewProps: {choices}};
                    },
                    'tarificationLibre': function(context) {
                        const trajet = context.trajet;
                        return {viewComponent: 'oui-non', viewProps: {yesLabel: 'Libre', noLabel: ''},formComponent: 'btn-group', formProps: {class: 'input-sm', type: 'single_checkbox', choices: [{label: 'Libre', disabled: trajet && trajet.tarification === '0'}]}};
                    },
                    'patientTransporte': {formComponent: 'btn-group', formProps: {class: 'input-sm', type: 'radio', 'htmlLabels': true, 'choices': {
                        1: {label: '1 <i class="fa-solid fa-user"></i>', title: '1 patient transporté'},
                        2: {label: '2 <i class="fa-solid fa-users"></i>', title: '2 patients transportés'},
                        3: {label: '3 <i class="fa-solid fa-users"></i>', title: '3 patients transportés'},
                    }}},
                    'taxiTarif': function(context) {
                        const trajet = context.trajet;
                        const fullChoices = trajetChoices.taxiTarif;
                        const choices = {};
                        const estTaxiParisien = mode === 'view' || structureElement.value.data('taxiParisien') === 'true';
                        for (const id in fullChoices) {
                            const choice = fullChoices[id];
                            if ((estTaxiParisien && choice.attr['data-tarif-parisien']) || choice.attr['data-tarif-provincial']) {
                                choices[id] = choice.label;
                            }
                        }

                        return {onUpdate: (data) => {
                                if (trajet && trajet.taxiModeCalcul === 1) {
                                    if (data.taxiTarif) {
                                        const tarif = fullChoices[data.taxiTarif].label;
                                        const tarifs = ['A', 'B', 'C', 'D'];

                                        if (tarifs.includes(tarif)) {
                                            // Si tarif simple, on met les kms dans le bon tarif pour recalcul
                                            const calculData = {};
                                            for (const tarif2 of tarifs) {
                                                calculData[tarif2] = {};
                                                if (tarif2 === tarif) {
                                                    calculData[tarif2] = {
                                                        kilometre: trajet.kilometre,
                                                        kilometreApproche: trajet.taxiKilometreApproche,
                                                    }
                                                }
                                            }
                                            trajet.taxiCalculData = calculData;
                                        } else {
                                            // Sinon on passe en mode calcul simple
                                            trajet.taxiModeCalcul = 0;
                                        }
                                    } else {
                                        computeTaxiTarifDetail(trajet)
                                    }
                                }
                            }, viewComponent: 'choice', viewProps: {choices}, formColProps: {class: 'width-150px'}, formComponent: 'select2', formProps: {class: 'input-sm', placeholder: 'Tarif', choices}};
                    },
                    'taxiForfait': function(context) {
                        const trajet = context.trajet;
                        const choices = trajet && trajet._grilleDetail.taxi ? trajet._grilleDetail.taxi.forfaitCollection || {} : {};

                        return {viewComponent: 'choice', viewProps: {choices}, formColProps: {class: 'width-200px'}, formComponent: 'select2', formProps: {class: 'input-sm', placeholder: 'Forfait', choices}};
                    },
                    'taxiTarifNeigeVerglas': function(context) {
                        const trajet = context.trajet;

                        return {onBatchApply: (data) => computeTaxiTarifDetail(trajet, data), onUpdate: () => computeTaxiTarifDetail(trajet), viewComponent: 'oui-non', formComponent: 'btn-group', formColProps: {class: 'width-50px'}, formProps: {class: 'input-sm', type: 'single_checkbox', 'htmlLabels': true, 'choices': ['<i class="fa-regular fa-snowflake"></i>']}};
                    },
                    'supplementTaxiPriseEnCharge': function(context) {
                        return {viewComponent: 'oui-non', formComponent: 'btn-group', formProps: {class: 'input-sm', type: 'single_checkbox', 'htmlLabels': true, 'choices': ['popover' === context.origin ? 'Prise en charge' : '<span title="Prise en charge">PEC</span>']}};
                    },
                    'supplementTpmr': {viewComponent: 'oui-non', formComponent: 'btn-group', formProps: {class: 'input-sm', type: 'single_checkbox', 'htmlLabels': true, 'choices': ['TMR']}},
                    'caracteristiques': function() {
                        const choices = {
                            'estUrgent': {label: '<i class="im-alarm2"></i>', title: Translator.trans('libelle.estUrgent')},
                            'estTpmr': {label: '<i class="im-accessibility2"></i>', title: Translator.trans('libelle.estTpmr')},
                            'avecInfirmier': {label: '<i class="im-syringe2"></i>', title: Translator.trans('libelle.avecInfirmier')},
                            'avecMedecin': {label: '<i class="im-stethoscope"></i>', title: Translator.trans('libelle.avecMedecin')},
                            'estGarde': {label: '<span class="fa-stack"><i class="fa-regular fa-calendar-check fa-stack-1x"></i><i class="fa-solid fa-truck-medical fa-stack-05x"></i></span>', title: Translator.trans('libelle.estGarde')},
                        };

                        return {onUpdate: (data) => {
                                if (data.caracteristiques.estGarde) {
                                    setTimeout(() => _this._updateEstGarde(), 1000);
                                }
                            }, viewComponent: 'fields', viewProps: {choices}, formComponent: 'btn-group', formProps: {class: 'input-sm', type: 'fields', 'htmlLabels': true, choices}};
                    },
                    'supplementPeage': function(context) {
                        return {onUpdate: (data) => {
                                if(data.supplementPeage.montant && !data.supplementPeage.quantite) {
                                    data.supplementPeage.quantite = '1'
                                }
                            }, viewComponent: 'quantite-montant', formColProps: {class: 'min-width-180px'}, formComponent: 'dual-input', formProps: {class: 'input-sm', 'label': 'popover' === context.origin ? 'Péage' : null, 'input1': {name: 'quantite', title: 'Quantité', placeholder: 'Qte', suffix: '='}, 'input2': {name: 'montant', title: 'Montant', placeholder: 'Mt', suffix: '€'}}};
                    },
                    'supplementVoieEau': function(context) {
                        return {onUpdate: (data) => {
                                if(data.supplementVoieEau.montant && !data.supplementVoieEau.quantite) {
                                    data.supplementVoieEau.quantite = '1'
                                }
                            }, viewComponent: 'quantite-montant', formColProps: {class: 'min-width-180px'}, formComponent: 'dual-input', formProps: {class: 'input-sm', 'label': 'popover' === context.origin ? 'Voie d\'eau' : null, 'input1': {name: 'quantite', title: 'Quantité', placeholder: 'Qte', suffix: '='}, 'input2': {name: 'montant', title: 'Montant', placeholder: 'Mt', suffix: '€'}}};
                    },
                    'supplementRemboursable': function(context) {
                        return {viewComponent: 'libelle-montant', formColProps: {class: 'min-width-200px'}, formComponent: 'dual-input', formProps: {class: 'input-sm', 'label': 'popover' === context.origin ? 'R.' : null, 'title': 'Remboursable', 'input1': {name: 'libelle', title: 'Libellé', placeholder: 'Libellé'}, 'input2': {name: 'montant', title: 'Montant', placeholder: 'Montant', suffix: '€'}}};
                    },
                    'supplementNonRemboursable': function(context) {
                        return {viewComponent: 'libelle-montant', formColProps: {class: 'min-width-200px'}, formComponent: 'dual-input', formProps: {class: 'input-sm', 'label': 'popover' === context.origin ? 'N.R.' : null,  'title': 'Non remboursable', 'input1': {name: 'libelle', title: 'Libellé', placeholder: 'Libellé'}, 'input2': {name: 'montant', title: 'Montant', placeholder: 'Montant', suffix: '€'}}};
                    },
                    'supplements': function(context) {
                        const trajet = context.trajet;

                        return {viewComponent: 'fields', viewProps: {choices: {
                                    'supplementAeroportGarePort': '<span class="badge badge-secondary" title="Aéroport / Gare / Port"><i class="fa-solid fa-plane"></i> / <i class="fa-solid fa-train"></i> / <i class="fa-solid fa-anchor"></i></span>',
                                    'supplementIncubateurPremature': '<span class="badge badge-secondary" title="Incubateur / Prématuré"><i class="noun-baby"></i> I. / P.</span>',
                                    'supplementCentre15Samu': '<span class="badge badge-secondary" title="Centre 15 / SAMU">15 / <i class="im-alarm2"></i></span>',
                                }}, formComponent: 'btn-group', formProps: {class: 'input-sm', type: 'fields', htmlLabels: true, choices: {
                                    'supplementAeroportGarePort': {label: '<i class="fa-solid fa-plane"></i> / <i class="fa-solid fa-train"></i> / <i class="fa-solid fa-anchor"></i>', title: 'Aéroport / Gare / Port'},
                                    'supplementIncubateurPremature': {label: '<i class="noun-baby"></i> I. / P.', title: 'Incubateur / Prématuré', disabled: trajet && !trajet.supplements.supplementIncubateurPremature && isTrajetVsl(trajet)},
                                    'supplementCentre15Samu': {label: '15 / <i class="im-alarm2"></i>', title: 'Centre 15 / SAMU', disabled: trajet && !trajet.supplements.supplementCentre15Samu && isTrajetVsl(trajet)},
                                }}};
                    },
                    'typeForfait': function(context) {
                        return {viewComponent: 'choice', viewProps: {choices: {
                                    '0d6f2115-7d19-40a8-a77e-0e44d005e551': estFacturationAigAhg ? 'UPH' : 'Agglomération',
                                    'c99b8832-4c2d-4139-b5c4-e44890e727a9': estFacturationAigAhg ? 'UPH' : 'Départemental',
                                    '0c5a28eb-a78a-41e8-a435-caf200209fc8': estFacturationAigAhg ? 'UPH' : 'Prise en charge',
                                }}, formComponent: 'btn-group', formProps: {class: 'input-sm', type: 'radio', choices: {
                                    '0d6f2115-7d19-40a8-a77e-0e44d005e551': {label: 'Agglomération', disabled: context.trajet && isTrajetVsl(context.trajet)},
                                    'c99b8832-4c2d-4139-b5c4-e44890e727a9': {label: 'Départemental'},
                                    '0c5a28eb-a78a-41e8-a435-caf200209fc8': {label: 'Prise en charge'},
                                }}};
                    },
                    'patient': function() {
                        return {formComponent: 'patient', formProps: {disabled: 'standard' === factureType}, formColProps: {class: 'min-width-250px'}, viewComponent: 'entity', viewProps: {route: 'shared.patient.view', routeParams: {app: 'facturation'}, textFields: ['prenomUsuel', 'nomUsuel']}};
                    },
                    'kilometre': function(context) {
                        const trajet = context.trajet;
                        const origin = context.origin;

                        return {onBatchApply: (data) => computeTaxiTarifDetail(trajet, data), onUpdate: () => computeTaxiTarifDetail(trajet), formComponent: 'distance', formColProps: {class: 'width-120px min-width-120px'}, formProps: {class: 'input-sm', trajet, origin}};
                    },
                    'taxiKilometreApproche': function(context) {
                        const trajet = context.trajet;

                        return {onBatchApply: (data) => computeTaxiTarifDetail(trajet, data), onUpdate: () => computeTaxiTarifDetail(trajet), formComponent: 'input-group', formColProps: {class: 'width-100px min-width-100px'}, formProps: {class: 'input-sm', label: context.origin === 'popover' ? 'Approche' : null, suffix: 'km'}};
                    },
                    'taxiCompteurMontant': function(context) {
                        const trajet = context.trajet;

                        return {onBatchApply: (data) => {
                                data.taxiModeCalcul = 0;
                            }, onUpdate() {
                                if (trajet) {
                                    trajet.taxiModeCalcul = 0;
                                }
                            }, viewComponent: 'montant', formComponent: 'input-group', formColProps: {class: 'width-150px'}, formProps: {class: 'input-sm', label: 'popover' === context.origin ? 'Compteur' : null, placeholder: 'Montant', suffix: '€'}};
                    },
                    'attente': function(context) {
                        const origin = context.origin;
                        const trajet = context.trajet;
                        let trajetPrecedent = null;

                        if (trajet) {
                            const group = getTrajetGroup(trajet);
                            const trajets = trajetsByGroup.value[group];
                            const currentTrajetIndex = trajets.indexOf(trajet);
                            const newStep = currentTrajetIndex - 1;

                            trajetPrecedent = trajets[newStep] || null;
                        }

                        return {onUpdate: () => {
                                if(trajet) {
                                    trajet.attenteMontant = '';
                                }
                            }, formComponent: 'attente', formColProps: {class: 'width-100px min-width-100px'}, formProps: {class: 'input-sm', trajet, trajetPrecedent, origin}};
                    },
                    'forfaitMontant': function(context) {
                        const trajet = context.trajet;
                        const hasModeTarification = trajet && trajet._grilleDetail.libre ? trajet._grilleDetail.libre.modeTarifFixe || trajet._grilleDetail.libre.modeTarifKilometrique || trajet._grilleDetail.libre.modeTarifHoraire : false;

                        return {viewComponent: 'montant', formComponent: 'input-group', formProps: {class: 'input-sm', label: context.origin === 'popover' ? 'Prestation' : null, suffix: '€', placeholder: 'Montant', readonly: hasModeTarification}};
                    },
                    'supplementTaxiAnimalMontant': function(context) {
                        return {viewComponent: 'montant', formComponent: 'input-group', formProps: {class: 'input-sm', label: 'popover' === context.origin  ? 'Animaux' : null, suffix: '€', placeholder: 'Montant'}};
                    },
                    'supplementTaxiBagageMontant': function(context) {
                        return {viewComponent: 'montant', formComponent: 'input-group', formProps: {class: 'input-sm', label: 'popover' === context.origin  ? 'Bagages' : null, suffix: '€', placeholder: 'Montant'}};
                    },
                    'supplementTaxiPassagerMontant': function(context) {
                        return {viewComponent: 'montant', formComponent: 'input-group', formProps: {class: 'input-sm', label: 'popover' === context.origin  ? 'Passagers' : null, suffix: '€', placeholder: 'Montant'}};
                    },
                    'supplementTaxiReservationMontant': function(context) {
                        return {viewComponent: 'montant', formComponent: 'input-group', formProps: {class: 'input-sm', label: 'popover' === context.origin  ? 'Réservation' : null, suffix: '€', placeholder: 'Montant'}};
                    },
                    'supplementTaxiAccompagnementMontant': function(context) {
                        return {viewComponent: 'montant', formComponent: 'input-group', formProps: {class: 'input-sm', label: 'popover' === context.origin  ? 'Accompagnement' : null, suffix: '€', placeholder: 'Montant'}};
                    },
                    'supplementTaxiGareMontant': function(context) {
                        return {viewComponent: 'montant', formComponent: 'input-group', formProps: {class: 'input-sm', label: 'popover' === context.origin  ? 'Gare' : null, suffix: '€', placeholder: 'Montant'}};
                    },
                    'supplementTaxiPortMontant': function(context) {
                        return {viewComponent: 'montant', formComponent: 'input-group', formProps: {class: 'input-sm', label: 'popover' === context.origin  ? 'Port' : null, suffix: '€', placeholder: 'Montant'}};
                    },
                    'supplementTaxiAeroportMontant': function(context) {
                        return {viewComponent: 'montant', formComponent: 'input-group', formProps: {class: 'input-sm', label: 'popover' === context.origin  ? 'Aéroport' : null, suffix: '€', placeholder: 'Montant'}};
                    },
                    'attenteMontant': function(context) {
                        return {viewComponent: 'montant', formComponent: 'input-group', formProps: {class: 'input-sm', label: 'popover' === context.origin ? 'Attente' : null, suffix: '€', placeholder: 'Montant'}};
                    },
                    'motif': function(context) {
                        return {viewComponent: 'entity', viewProps: {textFields: ['libelle']}, formComponent: 'select2-autocomplete', formProps: {disabled: isContextSefiPel(context), class: 'input-sm', 'template': 'motif', 'placeholder': 'Motif', route: 'facturation.facture.motif.ajax', routeParams: {structure: structure.value}}};
                    },
                });

                const currentTrajet = ref(null);
                const searchForm = ref({});
                const searchCurrentPage = ref(1);
                const searchItemNumberPerPage = ref(null);
                const searchTotalItemCount = ref(0);

                const searchColumns = ref([
                    {
                        key: 'type',
                        sortable: false,
                    },
                    {
                        key: 'motif',
                        sortable: false,
                    },
                    {
                        key: 'rendezVous',
                        label: 'Rendez-vous',
                        sortable: true,
                    },
                    {
                        key: 'patient',
                        label: 'Patient',
                        sortable: false,
                    },
                    {
                        key: 'villePriseEnCharge',
                        label: 'Ville PEC',
                        sortable: true,
                    },
                    {
                        key: 'villeDestination',
                        label: 'Ville DEST',
                        sortable: true,
                    },
                    {
                        key: 'structure',
                        label: 'Bureau',
                        sortable: false,
                    },
                    {
                        key: 'etatHtml',
                        label: 'États',
                        sortable: false,
                    },
                    {
                        key: 'action',
                        label: 'Action',
                        sortable: false,
                    },
                ]);
                const isReady = ref(false);
                const busy = ref(false);
                const standard = ref(null);

                const factureTab = ref(null);

                if('view' !== mode) {
                    // Structure
                    let treeSelectInputSelector = 'input[name="facture[structure]"]';
                    $('body').on('change', treeSelectInputSelector, () => {
                        structureElement.value = $(treeSelectInputSelector + ':checked');
                        structure.value = structureElement.value.val();
                    }).one('treeselect.init', () => {
                        structureElement.value = $(treeSelectInputSelector + ':checked');
                        structure.value = structureElement.value.val();
                    }).on('change', 'select#facture_structure', (e) => {
                        structureElement.value = $(e.currentTarget);
                        structure.value = structureElement.value.val();
                    });

                    // Patient
                    const updatePatient = () => {
                        const patientData = $('#facture_patientBeneficiaire').data('custom') || $('#facture_patientAssure').data('custom') || {};
                        patient.value = Object.values(patientData).length ? patientData : null;
                    }

                    $('#facture_patientAssure, #facture_patientBeneficiaire').change(() => updatePatient());
                    updatePatient();

                    factureTab.value = $('#factureTabs a.active').data('tab');

                    $('#factureTabs a[data-toggle="tab"]').on('shown.bs.tab', (e) => {
                        factureTab.value = e.currentTarget.dataset.tab;
                        updateSidebar();
                    });
                }

                const requestQueue = queue((task, callback) => {
                    task.promise(task).finally(callback);
                }, 1);

                onMounted(() => {
                    // Init defaults
                    for(let name in groups.value) {
                        const group = groups.value[name];
                        const preset = group.presets[group.currentPreset];

                        group.pagination = 1;
                        group.batchApply = 'view' !== mode ? JSON.parse(trajetSkeletonJSON) : {};
                        group.batchApplyData = {};
                        group.sortColumn = preset.defaultSortColumn;
                        group.sortDirection = preset.defaultSortDirection || 'asc';
                    }

                    if(isFactureStandard.value) {
                        groups.value.libre.disabled = true;

                        for(const group in groups.value) {
                            delete groups.value[group].presets.modeTarification;

                            const caracteristiquesCols = groups.value[group].presets.caracteristiques.cols;
                            caracteristiquesCols.splice(caracteristiquesCols.indexOf('patient'), 1);

                            const tarificationCols = groups.value[group].presets.tarification.cols;

                            if(group !== 'libre' && group !== 'taxi') {
                                tarificationCols.splice(tarificationCols.indexOf('attenteMontant'), 1);
                                tarificationCols.splice(tarificationCols.indexOf('remise'), 1);
                            }

                            if (mode === 'view') {
                                tarificationCols.splice(tarificationCols.indexOf('taxiCalculatrice'), 1);
                            }
                        }
                    }

                    // Init ajax
                    let initRoute = 'facturation.facture.new.init.ajax';
                    let initParams = {};

                    if(token) {
                        initParams.token = token;
                    }

                    if('new' === mode) {
                        initParams.type = factureType;
                    } else if(factureId) {
                        initParams.id = factureId;
                        initRoute = 'view' === mode ? 'facturation.facture.view.init.ajax' : 'facturation.facture.edit.init.ajax';
                    }

                    const initSuccess = (data) => {
                        const trajet = data.trajets || {};
                        const validation = trajet.validation || {};

                        trajets.value = trajet.data || {};
                        formValidation.value = {
                            facture: validation.facture || {},
                            trajet: validation.trajet || {},
                        };

                        if ('view' !== mode && data.facture) {
                            handleUpdateFactureData(data.facture);
                        }

                        isReady.value = true;
                    };
                    const initFail = (reponse) => {
                        // TODO show err / retry btn
                    };
                    const url = Router.generate(initRoute, initParams);

                    if('post' === initMethod) {
                        const factureData = $('#form').serializeObject();

                        Request.postJson(url, factureData).then(initSuccess, initFail);
                    } else {
                        Request.getJson(url).then(initSuccess, initFail);
                    }

                    // Init stuff

                    window.addEventListener('keydown', (e) => {
                        if(e.shiftKey && ['ArrowUp', 'ArrowDown'].includes(e.key)) {
                            if(currentTrajet.value) {
                                const step = e.key === 'ArrowUp' ? -1 : 1;
                                if(canStepCurrentTrajet(step)) {
                                    stepCurrentTrajet(step);
                                }
                            } else {
                                const $activeElement = $(document.activeElement);
                                if($activeElement.is(':input')) {
                                    const $col = $(document.activeElement).closest('td');
                                    const $row = $col.parent();
                                    const $next = e.key === 'ArrowUp' ? $row.prev() : $row.next();

                                    if($next.length) {
                                        $next.children().eq($col.index()).find(':input').focus();
                                    }
                                }
                            }

                            e.preventDefault();
                        }
                    });

                    document.dispatchEvent(new Event('app:vuejs:mounted'));
                });

                const getTarificationForContext = (context) => {
                    const trajet = context.trajet;
                    let tarification = null;

                    if (trajet) {
                        tarification = trajet.tarification;
                    } else if (context.group) {
                        tarification = {ambu: 1, vsl: 2, taxi: 3}[context.group] ?? null;
                    }

                    return tarification;
                };
                const isContextSefiPel = (context) => {
                    if (context.trajet) {
                        return Boolean(context.trajet.sefiId);
                    }

                    if (context.origin === 'batchApply') {
                        for (const trajet of trajetsByGroup.value[context.group]) {
                            if (trajet.sefiId) {
                                return true;
                            }
                        }
                    }

                    return false;
                };
                const computeTaxiTarifDetail = (trajet, batchTarget) => {
                    if(trajet && isTrajetTaxi(trajet) && trajet.taxiModeCalcul === 1) {
                        if (batchTarget) {
                            batchTarget.taxiComputeTarifDetail = true;
                        } else if (trajet.taxiModeCalcul === 1) {
                            trajet.taxiComputeTarifDetail = true
                        }
                    }
                };
                const updateDateFacture = () => {
                    if (standard.value?.estFEL || 'false' === structureElement.value.data('daterFactureDepuisTrajet')) {
                        return;
                    }

                    const $dateFacture = $('#facture_date');

                    let newDate = null;
                    for(const id in trajets.value) {
                        const trajet = trajets.value[id];

                        if(trajet.topDestinationArriveeReel) {
                            const trajetDate = Moment(trajet.topDestinationArriveeReel.date, 'DD/MM/YYYY');
                            if(trajetDate.isValid() && (!newDate || trajetDate.isAfter(newDate))) {
                                newDate = trajetDate;
                            }
                        }
                    }

                    if(newDate && newDate.format('DD/MM/YYYY') !== $dateFacture.val()) {
                        $dateFacture.val(newDate.format('DD/MM/YYYY'));
                    }
                };
                const getBatchApplyCount = (groupName) => {
                    const group = groups.value[groupName];
                    const cols = groupCols(groupName);
                    const colFields = Object.values(cols).map((item) => item.fields).flat();
                    const keys = Object.keys(group.batchApplyData);

                    return keys.filter((field) => colFields.includes(field)).length;
                };
                const clearSearch = () => {
                    searchForm.value = JSON.parse(searchSkeletonJSON);
                    searchForm.value.etat = ['realise', 'a_facturer'];
                    searchCurrentPage.value = 1;
                    searchTotalItemCount.value = 0;

                    const $patientBeneficiaire = $('[data-field="patientBeneficiaire"]');
                    if($patientBeneficiaire.val()) {
                        searchForm.value.patient = {id: $patientBeneficiaire.val(), text: $patientBeneficiaire.select2('data')[0].text};
                    } else {
                        const $patientAssure = $('[data-field="patientAssure"]');

                        if($patientAssure.val()) {
                            searchForm.value.patient = {id: $patientAssure.val(), text: $patientAssure.select2('data')[0].text};
                        }
                    }

                    refreshSearch();
                };
                const refreshSearch = () => {
                    if(searchTable.value) {
                        searchTable.value.refresh();
                    }
                };
                const onSearchPopoverShow = () => {
                    clearSearch();
                };
                const onEtatValideClick = (trajet) => {
                    if(mode !== 'view') {
                        if(trajet._error) {
                            update([trajet]);
                        } else if(!trajet._loading) {
                            validate([trajet]);
                        }
                    }
                };
                const formatFormData = (data) => {
                    const newData = {};

                    for (const field in data) {
                        if (!field.startsWith('_')) {
                            const value = data[field];
                            if (Array.isArray(value)) {
                                newData[field] = value.map(item => item && item.id && item.text ? item.id : item);
                            } else {
                                newData[field] = value && value.id && value.text ? value.id : value;
                            }
                        }
                    }

                    return newData;
                };
                const searchItems = (context, callback) => {
                    let params = {structure: structure.value, page: searchCurrentPage.value, trajet_filter: formatFormData(searchForm.value)};

                    if(context.sortBy) {
                        params.direction = context.sortDesc ? 'desc' : 'asc';
                        params.ordre = 'a.'+context.sortBy;
                    }

                    const url = Router.generate('facturation.facture.trajets.ajax', params);

                    fetch(url).then((response) => {
                        if(response.ok) {
                            response.json().then((data) => {
                                searchCurrentPage.value = data.currentPage;
                                searchTotalItemCount.value = data.totalItemCount;
                                searchItemNumberPerPage.value = data.itemNumberPerPage;

                                callback(data.items);
                            });
                        } else {
                            // error
                            callback([]);
                        }
                    });
                    return null;
                };
                const sousTotal = (trajets) => {
                    let total = 0;

                    for(let trajet of trajets) {
                        if(trajet._montantTouteTaxe !== null) {
                            total += parseFloat(trajet._montantTouteTaxe.replace(',', '.'));
                        }
                    }

                    return total;
                };
                const onColPresetChange = (groupName, presetName) => {
                    const group = groups.value[groupName];
                    const preset = group.presets[presetName];

                    group.cols.custom = preset.cols;
                    if(!preset.cols.includes(group.sortColumn)) {
                        group.sortColumn = preset.defaultSortColumn;
                    }

                    if('view' !== mode) {
                        clearBatch(groupName);
                    }
                };
                const onBatchApplyFieldReset = (group, items) => {
                    let data = groups.value[group].batchApplyData;

                    for(let item of items) {
                        const field = item.field;
                        const subField = item.subField;

                        if (!(field in data)) {
                            data[field] = JSON.parse(trajetSkeletonJSON)[field] || '';
                        } else if (!subField) {
                            delete data[field];
                        }

                        if (subField) {
                            if (subField in data[field]) {
                                delete data[field][subField];
                                delete groups.value[group].batchApply[field][subField];
                                if (!Object.keys(data[field]).length) {
                                    delete data[field];
                                }
                            } else {
                                data[field][subField] = '';
                                groups.value[group].batchApply[field][subField] = '';
                            }
                        }
                    }
                };
                const onBatchApplyUpdate = (group, data) => {
                    for(let fieldName in data) {
                        const fieldData = data[fieldName];
                        const field = getField(fieldName);

                        if(field && field.onUpdate) {
                            field.onUpdate(data);
                        }

                        groups.value[group].batchApply[fieldName] = fieldData;
                        groups.value[group].batchApplyData[fieldName] = fieldData;
                    }

                    updateDateFacture();
                };
                const onTrajetUpdate = (trajet, data, context = {}) => {
                    for(let fieldName in data) {
                        const field = getField(fieldName, {trajet, ...context});

                        if(field && field.onUpdate) {
                            field.onUpdate(data);
                        }
                    }
                    for(let fieldName in data) {
                        const fieldData = data[fieldName];

                        _.set(trajet, fieldName, fieldData);

                        if (undefined !== trajet._supplementsVisibles && fieldName in supplements.labels) {
                            const values = typeof fieldData === 'object' ? Object.values(fieldData) : [fieldData];

                            if (values.filter(value => value !== null && value !== '').length) {
                                trajet._supplementsVisibles = trajet._supplementsVisibles.filter(s => s !== fieldName);
                            }
                        }
                    }

                    updateDateFacture();
                    update([trajet]);
                    fixPaginationForTrajet(trajet);
                };
                const validate = (trajets) => {
                    submit(trajets, {
                        validate: trajets.map((trajet) => trajet.id),
                    });
                };
                const update = (trajets) => {
                    const update = [];

                    for(let trajet of trajets) {
                        update.push(formatFormData(trajet));
                    }

                    submit(trajets, {update});
                };
                const submit = (trajets, data) => {
                    let factureData = $('#form').serializeObject();

                    for(let trajet of trajets) {
                        trajet._loading = true;
                    }

                    queueSubmit(
                        trajets.map((trajet) => trajet.id).join('_'),
                        {...factureData, trajets: data},
                        () => {
                            for(let trajet of trajets) {
                                trajet._loading = false;
                                trajet._error = false;
                            }
                        },
                        () => {
                            for(let trajet of trajets) {
                                trajet._loading = false;
                                trajet._error = true;
                            }
                        }
                    );
                };
                const queueSubmit = (key, data, cb = null, cbErr = null) => {
                    submitInProgress.value = true;

                    let promise = (item) => {
                        item.controller = new AbortController()

                        return Request.postJson(formAjaxPath.value, data, {
                            signal: item.controller.signal
                        }).then((data) => {
                            if(data.facture) {
                                handleUpdateFactureData(data.facture);
                            }
                            handleUpdateData(data.trajets || {});

                            if(cb) {
                                cb();
                            }
                        }, (error) => {
                            if (error.name === 'AbortError')  {
                                return;
                            }

                            if(cbErr) {
                                cbErr();
                            }
                        }).finally(() => {
                            if(0 === requestQueue.length()) {
                                submitInProgress.value = false;
                            }
                        });
                    };

                    requestQueue.remove((item) => {
                        if(item.data.key === key) {
                            return true;
                        }
                    });
                    for(let worker of requestQueue.workersList()) {
                        let item = worker.data;

                        if(item.key === key && item.controller) {
                            item.controller.abort();
                        }
                    }
                    requestQueue.push({promise, key});
                };
                const handleUpdateFactureData = (data) => {
                    _this._handleUpdateData(data);
                    updateSidebar();
                };
                const handleUpdateData = (data) => {
                    const validation = data.validation || {};
                    const validationTrajet = validation.trajet || {};

                    formValidation.value.facture = validation.facture || {};

                    for(let id in validationTrajet) {
                        formValidation.value.trajet[id] = validationTrajet[id];
                    }

                    for(let id in data.data || {}) {
                        for(let field in data.data[id]) {
                            trajets.value[id][field] = data.data[id][field];
                        }
                    }
                };
                const getField = (field, context = {}) => {
                    let config = fields.value[field] || {};

                    if(config instanceof Function) {
                        config = config.call(this, context);
                    }

                    return {...config, name: field};
                };
                const canStepCurrentTrajet = (step = 1) => {
                    if(null === currentTrajet.value) {
                        return false;
                    }

                    const trajetsGroup = trajetsByGroup.value[currentTrajetGroup.value];
                    const currentTrajetIndex = trajetsGroup.indexOf(currentTrajet.value);
                    const newStep = currentTrajetIndex + step;

                    return !!trajetsGroup[newStep];
                };
                const stepCurrentTrajet = (step = 1) => {
                    if (null === currentTrajet.value) {
                        return;
                    }

                    const trajets = trajetsByGroup.value[currentTrajetGroup.value];
                    const currentTrajetIndex = trajets.indexOf(currentTrajet.value);
                    const newStep = currentTrajetIndex + step;

                    currentTrajet.value = trajets[newStep];
                };
                const applyPagination = (groupName, trajets) => {
                    const group = groups.value[groupName];
                    const start = (group.pagination - 1) * pageSize.value;
                    const end = start + pageSize.value;

                    return trajets.slice(start, end);
                };
                const clearBatch = (group) => {
                    groups.value[group].batchApply = JSON.parse(trajetSkeletonJSON);
                    groups.value[group].batchApplyData = {};
                };
                const applyBatch = (groupName) => {
                    const group = groups.value[groupName];
                    group.busy = true;

                    const trajets = trajetsByGroup.value[groupName];
                    const factureData = $('#form').serializeObject();

                    for(let trajet of trajets) {
                        trajet._loading = true;
                    }

                    for (const fieldName in group.batchApplyData) {
                        const field = getField(fieldName, {trajet: trajets[0]});

                        if (field && field.onBatchApply) {
                            field.onBatchApply(group.batchApplyData, fieldName);
                        }
                    }

                    queueSubmit(
                        groupName,
                        {...factureData, trajets: {batch: {ids: trajets.map(trajet => trajet.id), data: formatFormData(group.batchApplyData)}}},
                        () => {
                            clearBatch(groupName);
                            group.busy = false;

                            for(let trajet of trajets) {
                                trajet._loading = false;
                            }
                        }
                    );
                };
                const resetSefiIds = () => {
                    const trajets = trajetsArray.value.filter(trajet => trajet.sefiId);

                    if (trajets.length) {
                        const factureData = $('#form').serializeObject();

                        for (let trajet of trajets) {
                            trajet._loading = true;
                        }

                        queueSubmit(
                            'resetSefiIds',
                            {
                                ...factureData,
                                trajets: {batch: {ids: trajets.map((trajet) => trajet.id), data: {sefiId: null}}}
                            },
                            () => {
                                for (let trajet of trajets) {
                                    trajet._loading = false;
                                }
                            }
                        );
                    }
                };
                const sortGroupCol = (group, col) => {
                    if(!cols.value[col].sortFields) {
                        return;
                    }

                    if(groups.value[group].sortColumn === col) {
                        groups.value[group].sortDirection = groups.value[group].sortDirection === 'asc' ? 'desc' : 'asc';
                    } else {
                        groups.value[group].sortColumn = col;
                        groups.value[group].sortDirection = 'asc';
                    }
                };
                const groupCols = (groupName) => {
                    const group = groups.value[groupName];
                    if(!group) return {};

                    let cols2 = group.currentPreset === 'custom' ? group.cols.custom : group.presets[group.currentPreset].cols;

                    return cols2.concat('_montantTouteTaxe', '_etatValide').reduce((res, name) => (res[name] = cols.value[name], res), {});
                };
                const removeExistingTrajet = (id) => {
                    rawDeleteTrajet(trajetsByLink.value[id]);
                };
                const addExistingTrajet = (data) => {
                    addTrajet(data, true);
                };
                const addNewTrajet = (group = 0) => {
                    const tarification = ''+group;
                    const date = Moment().format('DD/MM/YYYY');

                    addTrajet({
                        tarification,
                        tarificationLibre: tarification === '0',
                        topPriseEnChargeDepartReel: {
                            date,
                            time: '00:00',
                        },
                        topDestinationArriveeReel: {
                            date,
                            time: '23:59',
                        },
                    })
                };
                const addTrajet = (data = {}, existing = false) => {
                    newId++;
                    let trajet = {...JSON.parse(trajetSkeletonJSON), ...{
                            id: 'new_'+newId,
                            patientTransporte: '1',
                            _montantTouteTaxe: null,
                            _loading: false,
                        }, ...data};

                    if(patient.value && (!existing || 'standard' === factureType)) {
                        trajet.patient.id = patient.value.id;
                        trajet.patient.prenomUsuel = patient.value.prenomUsuel;
                        trajet.patient.nomUsuel = patient.value.nomUsuel;
                    }

                    trajets.value['new_'+newId] = trajet;

                    if (existing) {
                        updateDateFacture();
                        if (data.caracteristiques.estGarde) {
                            _this._updateEstGarde();
                        }
                    }

                    update([trajet]);
                };
                const rawDeleteTrajet = (trajet) => {
                    const id = trajet.id;

                    submitInProgress.value = true;
                    trajet._busy = true;

                    let data = $('#form').serializeObject();
                    data.trajets = {delete: [id]};

                    Request.postJson(formAjaxPath.value, data).then((data) => {
                        if(data.facture) {
                            handleUpdateFactureData(data.facture);
                        }
                        handleUpdateData(data.trajets || {});

                        delete trajets.value[id];
                        delete formValidation.value.trajet[id];

                        updateDateFacture();

                        submitInProgress.value = false;
                        trajet._busy = false;
                    }, (response) => {
                        submitInProgress.value = false;
                        trajet._busy = false;
                    });
                };
                const deleteTrajet = (trajet) => {
                    swal({
                        title: App.Constants.LIBELLE_ETES_VOUS_SUR,
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonClass: 'bg-danger',
                        confirmButtonText: Translator.trans('action.supprimer'),
                        cancelButtonText: Translator.trans('action.annuler')
                    }).then((result) => {
                        if (result.value) {
                            rawDeleteTrajet(trajet);
                        }
                    });
                };
                const clear = () => {
                    swal({
                        title: App.Constants.LIBELLE_ETES_VOUS_SUR,
                        text: 'Vous allez supprimer tous les trajets de la facture.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonClass: 'bg-danger',
                        confirmButtonText: Translator.trans('action.supprimer'),
                        cancelButtonText: Translator.trans('action.annuler')
                    }).then((result) => {
                        if (result.value) {
                            doClear();
                        }
                    });
                };
                const doClear = () => {
                    submitInProgress.value = true;
                    busy.value = true;

                    let data = $('#form').serializeObject();
                    data.trajets = {clear: true};

                    Request.postJson(formAjaxPath.value, data).then((data) => {
                        if(data.facture) {
                            handleUpdateFactureData(data.facture);
                        }
                        handleUpdateData(data.trajets || {});

                        trajets.value = {};
                        formValidation.value.trajet = {};

                        submitInProgress.value = false;
                        busy.value = false;
                    }, (response) => {
                        submitInProgress.value = false;
                        busy.value = false;
                    });
                };
                const editTrajet = (trajet) => {
                    currentTrajet.value = trajet;
                };
                const getTrajetValidation = (trajet, field = null, strict = false) => {
                    const factureValidation = (formValidation.value.facture[trajet.id] || {});
                    const trajetValidation = (formValidation.value.trajet[trajet.id] || {});

                    let items = [];

                    for(let validation of [factureValidation, trajetValidation]) {
                        for (let key in validation) {
                            if ((null === field && '' === key) || key === field || '_'+key === field || (!strict && key.startsWith(field + '.'))) {
                                items.push(...validation[key]);
                            }
                        }
                    }

                    return items;
                };
                const isTrajetLibre = (trajet) => trajet.tarificationLibre === true;
                const isTrajetAmbu = (trajet) => !trajet.tarificationLibre && trajet.tarification === '1';
                const isTrajetVsl = (trajet) => !trajet.tarificationLibre && trajet.tarification === '2';
                const isTrajetAmbuVsl = (trajet) => isTrajetAmbu(trajet) || isTrajetVsl(trajet);
                const isTrajetTaxi = (trajet) => !trajet.tarificationLibre && trajet.tarification === '3';
                const addSupplement = (trajet, supplement) => {
                    if (undefined === trajet._supplementsVisibles) {
                        trajet._supplementsVisibles = [];
                    }

                    trajet._supplementsVisibles.push(supplement);
                };
                const getTrajetGroup = (trajet) => {
                    if(trajet.tarificationLibre) {
                        return 'libre';
                    }

                    switch (trajet.tarification) {
                        case '1': return 'ambu';
                        case '2': return 'vsl';
                        case '3': return 'taxi';
                    }
                };
                const fixPaginationForTrajet = (trajet) => {
                    const group = getTrajetGroup(trajet);
                    const trajets = trajetsByGroup.value[group];
                    const trajetIndex = trajets.indexOf(trajet);

                    if(-1 !== trajetIndex) {
                        groups.value[group].pagination = Math.floor(trajetIndex/pageSize.value) + 1;
                        return true;
                    }

                    return false;
                };
                const updateSidebar = () => {
                    $('[data-xxl-class]').each((i, el) => $(el).toggleClass($(el).data('xxlClass'), 'trajets' !== factureTab.value));
                };

                const trajetsArray = computed(() => Object.values(trajets.value));

                const trajetsBySefiId = computed(() => {
                    return trajetsArray.value.filter(trajet => trajet.sefiId).reduce((res, item) => (res[item.sefiId] = item, res), {});
                });
                const isFactureStandard = computed(() => {
                    return 'standard' === factureType;
                });
                const isFactureLibre = computed(() => {
                    return 'libre' === factureType;
                });
                const cols = computed(() => {
                    return {
                        _etatValide: {title: 'E', sortFields: ['_etatValide'], fields: ['_etatValide']},
                        _montantTouteTaxe: {title: 'Montant', sortFields: ['_montantTouteTaxe'], fields: ['_montantTouteTaxe']},
                        pec: {title: 'Prise en charge', sortFields: ['topPriseEnChargeDepartReel.date', 'topPriseEnChargeDepartReel.time'], fields: ['topPriseEnChargeDepartReel.date', 'topPriseEnChargeDepartReel.time', 'precisionPriseEnCharge', 'villePriseEnCharge']},
                        pec2: {title: 'Prise en charge', sortFields: ['topPriseEnChargeDepartReel.date', 'topPriseEnChargeDepartReel.time'], fields: ['topPriseEnChargeDepartReel.date', 'topPriseEnChargeDepartReel.time', 'precisionPriseEnCharge', 'adressePriseEnCharge', 'villePriseEnCharge']},
                        dest: {title: 'Destination', sortFields: ['topDestinationArriveeReel.date', 'topDestinationArriveeReel.time'], fields: ['topDestinationArriveeReel.date', 'topDestinationArriveeReel.time', 'precisionDestination', 'villeDestination']},
                        dest2: {title: 'Destination', sortFields: ['topDestinationArriveeReel.date', 'topDestinationArriveeReel.time'], fields: ['topDestinationArriveeReel.date', 'topDestinationArriveeReel.time', 'precisionDestination', 'adresseDestination', 'villeDestination']},
                        vehicule: {title: 'Véhicule', fields: ['vehicule']},
                        personnel1: {title: 'Personnel 1', fields: ['personnel1']},
                        personnel2: {title: 'Personnel 2', fields: ['personnel2']},
                        tarification: {title: 'Mode de tarification', fields: ['tarification', 'tarificationLibre']},
                        forfaitMontant: {title: 'Prestation', fields: ['forfaitMontant']},
                        supplementPeage: {title: 'Péage', fields: ['supplementPeage']},
                        remise: {title: 'Remise', sortFields: ['remise'], fields: ['remise']},
                        attente: {title: 'Attente', sortFields: ['attente'], fields: ['attente']},
                        attenteMontant: {title: 'Attente', sortFields: ['attenteMontant'], fields: ['attenteMontant']},
                        kilometre: {title: 'Distance', sortFields: ['kilometre'], fields: ['kilometre']},
                        supplementRemboursable: {title: 'Remboursable', fields: ['supplementRemboursable']},
                        supplementNonRemboursable: {title: 'Non remboursable', fields: ['supplementNonRemboursable']},
                        patientTransporte: {title: 'Nb patients', sortFields: ['patientTransporte'], fields: ['patientTransporte']},
                        caracteristiques: {title: 'Caractéristiques', fields: ['caracteristiques']},
                        patient: {title: 'Patient', fields: ['patient']},
                        motif: {title: 'Motif', fields: ['motif']},
                        typeForfait: {title: 'Forfait', fields: ['typeForfait']},
                        taxiKilometreApproche: {title: 'Approche', fields: ['taxiKilometreApproche']},
                        taxiCompteurMontant: {title: 'Compteur', fields: ['taxiCompteurMontant']},
                        taxiForfait: {title: 'Forfait', fields: ['taxiForfait']},
                        taxiCalculatrice: {title: '', fields: mode === 'view' ? [] : ['_calculatriceTaxi']},
                        taxiTarif: {title: 'Tarif', fields: ['taxiTarif', 'taxiTarifNeigeVerglas']},
                        supplements: {title: 'Suppléments', fields: ['supplements']},
                        supplementTaxiPriseEnCharge: {title: 'PEC', fields: ['supplementTaxiPriseEnCharge']},
                        supplementTpmr: {title: 'TPMR', fields: ['supplementTpmr']},
                        supplementTaxiAnimalMontant: {title: 'Animal', fields: ['supplementTaxiAnimalMontant']},
                        supplementTaxiBagageMontant: {title: 'Bagage', fields: ['supplementTaxiBagageMontant']},
                        supplementTaxiPassagerMontant: {title: 'Passager', fields: ['supplementTaxiPassagerMontant']},
                        supplementTaxiReservationMontant: {title: 'Réservation', fields: ['supplementTaxiReservationMontant']},
                    };
                });
                const fieldsByGroupByPreset = computed(() => {
                    let fieldsByGroupByPreset = {};

                    for(let group in groups.value) {
                        if (!fieldsByGroupByPreset[group]) {
                            fieldsByGroupByPreset[group] = {};
                        }

                        for(let preset in groups.value[group].presets) {
                            if (!fieldsByGroupByPreset[group][preset]) {
                                fieldsByGroupByPreset[group][preset] = [];
                            }

                            for (let col of groups.value[group].presets[preset].cols) {
                                for (let field of cols.value[col].fields) {
                                    if (!(field in fieldsByGroupByPreset[group][preset])) {
                                        fieldsByGroupByPreset[group][preset].push(field);
                                    }
                                }
                            }
                        }
                    }

                    return fieldsByGroupByPreset;
                });
                const enabledGroups = computed(() => {
                    return Object.keys(groups.value)
                        .filter(key => !groups.value[key].disabled)
                        .reduce((obj, key) => (obj[key] = groups.value[key], obj), {})
                    ;
                });
                const pageSize = computed(() => {
                    return Math.floor(20 / Object.keys(trajetsByGroup.value).length);
                });
                const validationCountByGroupByPreset = computed(() => {
                    let counts = {};

                    for(let group in groups.value) {
                        counts[group] = {};

                        for(let preset in groups.value[group].presets) {
                            counts[group][preset] = {error: 0, warning: 0, info: 0};
                        }
                    }

                    for(let type in formValidation.value) {
                        for(let id in formValidation.value[type]) {
                            if (id in trajets.value) {
                                const group = getTrajetGroup(trajets.value[id]);

                                for(let preset in groups.value[group].presets) {
                                    for (let field in formValidation.value[type][id]) {
                                        let hasField = false;

                                        for (let field2 of fieldsByGroupByPreset.value[group][preset]) {
                                            if (field === field2 || field2.startsWith(field + '.')) {
                                                hasField = true;
                                                break;
                                            }
                                        }

                                        if (hasField) {
                                            for (let violation of formValidation.value[type][id][field]) {
                                                counts[group][preset][violation.type]++;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    return counts;
                });
                const validationCount = computed(() => {
                    let counts = {error: 0, warning: 0, info: 0};

                    for(let type in formValidation.value) {
                        for(let id in formValidation.value[type]) {
                            for(let field in formValidation.value[type][id]) {
                                for(let violation of formValidation.value[type][id][field]) {
                                    counts[violation.type]++;
                                }
                            }
                        }
                    }

                    return counts;
                });
                const currentTrajetGroup = computed(() => {
                    if(null !== currentTrajet.value) {
                        return getTrajetGroup(currentTrajet.value);
                    }
                });
                const currentTrajetSupplementsVisibles = computed(() => {
                    if(null !== currentTrajet.value) {
                        const trajetSupplementsVisibles = currentTrajet.value._supplementsVisibles || [];

                        return _.flatten(supplements.dropdown[currentTrajetGroup.value]).filter(supplement => {
                            if (trajetSupplementsVisibles.includes(supplement)) {
                                return true;
                            }

                            const value = currentTrajet.value[supplement];

                            if (typeof value === 'object') {
                                for (const key in value) {
                                    if(null !== value[key] && '' !== value[key]) {
                                        return true;
                                    }
                                }

                                return false;
                            }

                            return value !== null && value !== '' && value !== undefined;
                        });
                    }
                });
                const saveButtonLocked = computed(() => {
                    return submitInProgress.value;
                });
                const formAjaxPath = computed(() => {
                    return _this.formAjaxPath;
                });
                const fuseSearch = computed(() => {
                    return new Fuse(trajetsArray.value, {
                        keys: ['id'],
                        minMatchCharLength: 1,
                        tokenize: true,
                        threshold: 0,
                    })
                });
                const trajetsByLink = computed(() => {
                    let trajets = {};

                    for(let trajet of trajetsArray.value) {
                        if(trajet.trajet) {
                            trajets[trajet.trajet.id] = trajet;
                        }
                    }

                    return trajets;
                });
                const trajetsByGroup = computed(() => {
                    // Passe 1 : trajets matchant la recherche regroupés par groupe
                    let allTrajets = '' === searchQuery.value ? trajetsArray.value : fuseSearch.value.search(searchQuery.value);
                    let allTrajetsByGroup = {};

                    allTrajets.forEach((trajet) => {
                        const group = getTrajetGroup(trajet);

                        if(!(group in allTrajetsByGroup)) {
                            allTrajetsByGroup[group] = [];
                        }

                        allTrajetsByGroup[group].push(trajet);
                    });

                    // Passe 2 : tri
                    for(let groupName in allTrajetsByGroup) {
                        const group = groups.value[groupName];
                        const sortCol = cols.value[group.sortColumn];

                        if(sortCol && sortCol.sortFields) {
                            const sortFields = [...sortCol.sortFields, 'id'];
                            allTrajetsByGroup[groupName] = _.orderBy(allTrajetsByGroup[groupName], sortFields, Array(sortFields.length).fill(group.sortDirection));
                        }

                    }

                    return allTrajetsByGroup;
                });

                watch(isReady, () => {
                    if(isReady.value) {
                        $('#saveBtn').prop('disabled', false);
                        $('#saveDropdownBtn').prop('disabled', false);
                        $('#controleSefi').prop('disabled', false);
                    }
                });
                watch(factureTab, () => {
                    updateSidebar();
                });
                watch(patient, () => {
                    if(isFactureStandard.value) {
                        for (const trajet of trajetsArray.value) {
                            trajet.patient.id = patient.value ? patient.value.id : null;
                            trajet.patient.prenomUsuel = patient.value ? patient.value.prenomUsuel : null;
                            trajet.patient.nomUsuel = patient.value ? patient.value.nomUsuel : null;
                        }
                    }
                });
                watch(currentTrajet, () => {
                    if(currentTrajet.value) {
                        fixPaginationForTrajet(currentTrajet.value);
                    }
                });
                watch(saveButtonLocked, () => {
                    const saveBtn = document.getElementById('saveBtn');
                    const saveDropdownBtn = document.getElementById('saveDropdownBtn');
                    const controleSefiBtn = document.getElementById('controleSefi');
                    const patientPlusBtn = document.getElementById('patientPlusBtn');
                    const pecPlusBtn = document.getElementById('pecPlusBtn');

                    if (saveBtn) {
                        saveBtn.disabled = saveButtonLocked.value;
                    }
                    if (saveDropdownBtn) {
                        saveDropdownBtn.disabled = saveButtonLocked.value;
                    }
                    if (controleSefiBtn) {
                        controleSefiBtn.disabled = saveButtonLocked.value;
                    }
                    if (patientPlusBtn) {
                        patientPlusBtn.disabled = saveButtonLocked.value;
                    }
                    if (pecPlusBtn) {
                        pecPlusBtn.disabled = saveButtonLocked.value;
                    }
                });
                watch(validationCount, () => {
                    if('view' !== mode) {
                        $('.facture-tab-title[data-tab="trajets"] .badge').remove();

                        for (let type in validationCount.value) {
                            _this._updateTabCount('trajets', type, validationCount.value[type]);
                        }
                    }
                });

                const getValue = (item, path) => _.get(item, path);

                provide('getTrajetValidation', getTrajetValidation);
                provide('getField', getField);

                return {
                    supplements,
                    structure,
                    structureElement,
                    patient,
                    searchQuery,
                    trajets,
                    formValidation,
                    submitInProgress,
                    groups,
                    fields,
                    currentTrajet,
                    searchForm,
                    searchCurrentPage,
                    searchItemNumberPerPage,
                    searchTotalItemCount,
                    searchColumns,
                    trajetsArray,
                    isReady,
                    busy,
                    searchChoices,
                    standard,
                    sousTotal,
                    getField,
                    getValue,
                    getTrajetValidation,
                    getBatchApplyCount,
                    clearSearch,
                    refreshSearch,
                    onSearchPopoverShow,
                    onEtatValideClick,
                    searchItems,
                    onColPresetChange,
                    onBatchApplyFieldReset,
                    onBatchApplyUpdate,
                    onTrajetUpdate,
                    canStepCurrentTrajet,
                    stepCurrentTrajet,
                    applyPagination,
                    clearBatch,
                    applyBatch,
                    resetSefiIds,
                    sortGroupCol,
                    groupCols,
                    removeExistingTrajet,
                    addExistingTrajet,
                    addNewTrajet,
                    deleteTrajet,
                    clear,
                    editTrajet,
                    isTrajetLibre,
                    isTrajetAmbu,
                    isTrajetVsl,
                    isTrajetAmbuVsl,
                    isTrajetTaxi,
                    addSupplement,
                    getTrajetGroup,
                    trajetsBySefiId,
                    isFactureLibre,
                    isFactureStandard,
                    enabledGroups,
                    pageSize,
                    validationCountByGroupByPreset,
                    currentTrajetGroup,
                    currentTrajetSupplementsVisibles,
                    trajetsByLink,
                    trajetsByGroup,
                    handleUpdateData,
                    updateSidebar,
                    searchTable,
                    Translator,
                    Euro,
                    Capitalize,
                };
            },
        }).mount(el);
    }

    static openValiderSwal() {
        return swal({
            title: App.Constants.LIBELLE_ETES_VOUS_SUR,
            type: 'question',
            showCancelButton: true,
            confirmButtonClass: 'bg-info',
            confirmButtonText: Translator.trans('action.valider'),
            cancelButtonText: Translator.trans('action.annuler'),
        })
    }

    static openInvaliderSwal() {
        return swal({
            title: App.Constants.LIBELLE_ETES_VOUS_SUR,
            type: 'question',
            showCancelButton: true,
            confirmButtonClass: 'bg-danger',
            confirmButtonText: Translator.trans('action.invalider'),
            cancelButtonText: Translator.trans('action.annuler'),
        })
    }

    static handleMenuLink(url, e) {
        if (e.which === 1 && !e.ctrlKey) {
            window.location.href = url;
        }
        else if (e.which === 2 || (e.which === 1 && e.ctrlKey)) {
            let win = window.open(url, '_blank');
            if (win) win.focus();
        }
    }

    static _initExport(exportUrl, filename) {
        Cookies.remove('factureExportDownloadFinished');

        let $exportForm = $('#exportForm');
        let $exportPrintBtn = $('#exportPrintBtn');
        let $exportDownloadBtn = $('#exportDownloadBtn');

        let exportPrintBtnLadda = ladda.create($exportPrintBtn[0]);
        let exportDownloadBtnLadda = ladda.create($exportDownloadBtn[0]);

        $exportPrintBtn.click((e) => {
            let url = exportUrl + '?' + decodeURI($exportForm.serialize());

            if(e.ctrlKey) {
                let win = window.open(url, '_blank');
                if(win) {
                    win.focus();
                    return;
                }
            }

            if(e.shiftKey) {
                window.location.href = url;
                return;
            }

            exportPrintBtnLadda.start();
            $exportDownloadBtn.prop('disabled', true);

            App.Utils.print(url, filename).then(() => {
                exportPrintBtnLadda.stop();
                $exportDownloadBtn.prop('disabled', false);
            });
        });

        $exportForm.submit(() => {
            exportDownloadBtnLadda.start();
            $exportPrintBtn.prop('disabled', true);

            let checkFinish = () => {
                if (Cookies.get('factureExportDownloadFinished')) {
                    exportDownloadBtnLadda.stop();
                    $exportPrintBtn.prop('disabled', false);
                    Cookies.remove('factureExportDownloadFinished');
                } else {
                    setTimeout(checkFinish, 1000);
                }
            };

            checkFinish();
        });
    }
};

App.Facturation.Facture.Index = class {
    constructor(choices, paths, form) {
        this.choices = choices;
        this.paths = paths;
        this.form = form;

        const _this = this;

        createApp({
            delimiters: ['[[', ']]'],
            data() {
                return {
                    mode: window.localStorage.getItem('ambuerp_facture_mode') || 'simple',
                    showSolde: window.localStorage.getItem('ambuerp_facture_solde') || false,
                    modes: {
                        simple: 'Simple',
                        trajets: 'Trajets',
                        suivi: 'Suivi',
                        full: 'Complet',
                    },
                };
            },
            mounted() {
                _this._initContextMenu();
                App.Shared.initHoverCards();

            },
            watch: {
                mode() {
                    nextTick(() => App.Shared.initHoverCards());
                    window.localStorage.setItem('ambuerp_facture_mode', this.mode);
                },
                showSolde() {
                    window.localStorage.setItem('ambuerp_facture_solde', this.showSolde);
                },
            }
        }).mount('#app');
    }

    _initContextMenu() {
        let contextMenuLinks = {
            view: this.paths.view,
            edit: this.paths.edit,
            reglement: this.paths.reglement,
            transformer: this.paths.transformer,
            dupliquer_libre: this.paths.dupliquer_libre,
            dupliquer_standard: this.paths.dupliquer_standard,
            creer_avoir: this.paths.creer_avoir
        };

        $.contextMenu({
            selector: '#factures tr',
            callback: function(key, options, e) {
                let id = $(this).data('id');
                let newType = ($(this).data('type') === 'libre' ? 'standard' : 'libre');

                if(key in contextMenuLinks) {
                    let url = contextMenuLinks[key].replace('_ID_', id).replace('_TYPE_', newType);

                    if(e.which === 1 && !e.ctrlKey) {
                        window.location.href = url;
                    }
                    else if(e.which === 2 || (e.which === 1 && e.ctrlKey)) {
                        let win = window.open(url, '_blank');
                        if(win) win.focus();
                    }
                }

            },
            build: ($trigger, event) => {
                let id = $trigger.data('id');
                if (!id) return false;

                let $cell = $(event.target).closest('td');
                let dataCopy = $cell.data('copy');
                let dataSearch = $cell.data('search');
                let dataDate = $cell.data('date');
                let patientId = $cell.data('patientId');
                let patientNom = $cell.data('patientNom');

                let numero = $trigger.data('numero');
                let newType = ($trigger.data('type') === 'libre' ? 'standard' : 'libre');
                let roleView = $trigger.data('roleView');
                let roleEdit = $trigger.data('roleEdit');
                let roleTransformer = $trigger.data('roleTransformer');
                let roleDupliquerStandard = $trigger.data('roleDupliquerStandard');
                let roleCreerAvoir = $trigger.data('roleCreerAvoir');
                let roleDupliquerLibre = $trigger.data('roleDupliquerLibre');
                let roleValider = $trigger.data('roleValider');
                let roleInvalider = $trigger.data('roleInvalider');

                if (!$trigger.hasClass('ui-selected')) {
                    $('.js-selectable-select input:checked').prop('checked', false).change();
                    $trigger.find('.js-selectable-select input:not([disabled])').prop('checked', true).change();
                }

                let items = {};
                let $selectedItems = $('#factures .ui-selected');

                let canValider = true;
                let aValidee = false;
                let aNonValidee = false;
                let sameType = true;
                let sameTarification = true;
                let type = null;
                let tarification = null;

                $selectedItems.each((i, item) => {
                    if ($(item).data('etat-valide') !== 1) {
                        canValider = false;
                    }

                    if ($(item).data('est-validee') === 1) {
                        aValidee = true;
                    } else {
                        aNonValidee = true;
                    }

                    let itemType = $(item).data('type');
                    if (itemType !== type) {
                        if (type === null) {
                            type = itemType;
                        } else {
                            sameType = false;
                        }
                    }

                    let itemTarification = $(item).data('tarification');
                    if (itemTarification !== tarification) {
                        if (tarification === null) {
                            tarification = itemTarification;
                        } else {
                            sameTarification = false;
                        }
                    }
                });

                let itemValider = {
                    name: Translator.trans('action.valider'),
                    icon: 'fa-solid fa-check',
                    disabled: !canValider || !aNonValidee,
                    callback: () => {
                        App.Facturation.Facture.openValiderSwal().then((result) => {
                            if (result.value) {
                                $('#facture_batch_action_action').val(this.choices.valider);
                                $('#formBatch').submit();
                            }
                        });
                    }
                };

                if (dataCopy) {
                    items.copy = {
                        name: 'Copier « ' + dataCopy + ' »', icon: 'fa-regular fa-clipboard', callback: () => {
                            let selection = window.getSelection();
                            let range = document.createRange();
                            range.selectNodeContents($cell[0]);
                            selection.removeAllRanges();
                            selection.addRange(range);
                            document.execCommand('copy');
                            selection.removeAllRanges();
                        }
                    };
                }

                if (patientId) {
                    items.search = {
                        name: 'Filtrer « ' + patientNom + ' »', icon: 'fa-solid fa-magnifying-glass', callback: () => {
                            let newOption = new Option(patientNom, patientId, true, true);
                            $('#' + this.form.patient).append(newOption).trigger('change');
                            $('#form').submit();
                        }
                    };
                } else if (dataDate) {
                    items.search = {
                        name: 'Filtrer au ' + dataDate, icon: 'fa-solid fa-magnifying-glass', callback: () => {
                            $('#' + this.form.debut).val(dataDate);
                            $('#' + this.form.fin).val(dataDate);
                            $('#form').submit();
                        }
                    };
                } else if (dataSearch) {
                    items.search = {
                        name: 'Rechercher « ' + dataSearch + ' »', icon: 'fa-solid fa-magnifying-glass', callback: () => {
                            $('#' + this.form.search).val(dataSearch);
                            $('#form').submit();
                        }
                    };
                }

                if (dataCopy || dataSearch) {
                    items.sep0 = '';
                }

                if ($selectedItems.length === 1) {
                    items.facture = {name: 'Facture ' + numero, disabled: true, icon: 'fa-solid fa-cube'};
                    items.sep1 = '';

                    if (roleView) {
                        items.view = {name: Translator.trans('action.voir'), icon: 'fa-solid fa-eye'};
                    }
                    if (roleEdit) {
                        items.edit = {name: Translator.trans('action.modifier'), icon: 'fa-solid fa-pencil'};
                    }

                    if (aValidee && roleInvalider) {
                        items.invalider = {
                            name: Translator.trans('action.invalider'),
                            icon: 'fa-solid fa-xmark',
                            callback: () => {
                                App.Facturation.Facture.openInvaliderSwal().then((result) => {
                                    if (result.value) {
                                        $('#facture_batch_action_action').val(this.choices.invalider);
                                        $('#formBatch').submit();
                                    }
                                });
                            }
                        }
                    } else if (aNonValidee && roleValider) {
                        items.valider = itemValider;
                    }

                    if ((roleView || roleEdit || (roleValider && aValidee)) && (roleDupliquerStandard || roleDupliquerLibre || roleTransformer || roleCreerAvoir)) {
                        items.sep12 = '';
                    }

                    if (roleDupliquerStandard) {
                        items.dupliquer_standard = {name: 'Dupliquer en standard', icon: 'fa-regular fa-copy'};
                    }
                    if (roleDupliquerLibre) {
                        items.dupliquer_libre = {name: 'Dupliquer en libre', icon: 'fa-regular fa-copy'};
                    }
                    if (roleTransformer) {
                        items.transformer = {name: 'Transformer en ' + newType, icon: 'fa-solid fa-right-left'};
                    }
                    if (roleCreerAvoir) {
                        items.creer_avoir = {name: 'Créer un avoir', icon: 'fa-regular fa-file-invoice'};
                    }

                    items.reglement = {name: 'Saisir un règlement', icon: 'fa-solid fa-wand-magic-sparkles'};

                } else {
                    items.factures = {
                        name: $selectedItems.length + ($selectedItems.length > 1 ? ' factures sélectionnées' : ' facture sélectionnée'),
                        disabled: true,
                        icon: 'fa-solid fa-cubes'
                    };

                    if (roleValider) {
                        items.sep12 = '';

                        items.valider = itemValider;
                    }
                }

                items.sep2 = '';

                items.exporter = {
                    name: Translator.trans('action.exporter'),
                    icon: 'fa-regular fa-file-export',
                    disabled: !sameType || !sameTarification,
                    callback: () => {
                        swal({
                            showConfirmButton: false,
                            showCancelButton: false,
                            html: '<div class="text-center py-5"><div class="spinner-border m-auto" role="status"></div></div>',
                            width: '400px',
                            onOpen: () => {
                                const $content = $(swal.getContent());
                                $content.removeClass('swal2-content').addClass('text-left');

                                $.ajax({
                                    type: 'POST',
                                    url: Router.generate('facturation.facture.export.batch'),
                                    data: {
                                        export_batch: {
                                            factureCollection: $selectedItems.map((i, item) => $(item).data('id')).toArray(),
                                        }
                                    },
                                }).done((data) => {
                                    if (data.redirect) {
                                        window.location.href = window.location;
                                    } else {
                                        $content.html(data.html);
                                        App.Facturation.Facture._initExport(this.paths.export_batch_url);
                                    }
                                });
                            },
                        })
                    }
                };

                items.imprimer = {
                    name: Translator.trans('action.imprimer'),
                    icon: 'fa-solid fa-print',
                    callback: () => {
                        const factureCollection = $selectedItems.map((i, item) => $(item).data('id')).toArray();
                        const url = Router.generate('facturation.facture.print.batch', {export_batch: {factureCollection}});

                        App.Utils.print(url);
                    }
                };

                items.sep3 = '';

                items.lot = {name: 'Créer un lot', icon: 'fa-solid fa-plus', callback: () => {
                    $('#facture_batch_action_action').val(this.choices.creer_lot);
                    $('#formBatch').submit();
                }};

                return {
                    items: items
                };
            },
            events: {
                hide: () => {
                    $('#factures tr.focus').removeClass('focus');
                }
            }

        });
    }
};

App.Facturation.Facture.View = class {
    constructor(params) {
        this.params = params;

        this._initExport();
        this._initTicketModerateur();
        this._initValider();

        App.Facturation.Facture.initSefiFlash(params);
        App.Facturation.Facture.initTrajets(this);
        App.Courrier.initCourrierPopover(this.params.courrierTemplate, this.params.genererCourrierUrl);

        if (this.vm) {
            this.vm.standard = params.standard;
        }
    }

    _initTicketModerateur() {
        $('#ticketModerateurBtn').click(() => {
            $('#actionBtn').popover('toggle').dropdown('toggle');
            return false;
        });
        $('#actionBtn').popover({
            placement: 'bottom',
            fallbackPlacement: [],
            content: this.params.ticketModerateurTemplate,
            html: true,
            sanitize: false,
            title: 'Modifier le payeur du ticket modérateur',
            trigger: 'manual',
        }).on('show.bs.popover', function () {
            $($(this).data('bs.popover').tip).css('min-width', '20%');
        }).on('shown.bs.popover', () => {
            App.Layout.enableSelect2();

            $('#ticket_moderateur_payeur input').change((e) => {
                $('#ticketModerateurSubmit').prop('disabled', e.currentTarget.value === 'mutuelle' && !$('#ticket_moderateur_mutuelle').val());
            });
            $('#ticket_moderateur_mutuelle').change((e) => {
                $('#ticketModerateurSubmit').prop('disabled', $(e.currentTarget).val() === '');
            });
        });

        $('body').on('click', function (e) {
            if (!$(e.target).is('#actionBtn') && $(e.target).parents('#actionBtn').length === 0 && $(e.target).parents('.popover.show').length === 0) {
                $('#actionBtn').popover('hide');
            }
        });
    }

    _initExport() {
        // Export (impression et téléchargement)

        $('#exportBtn').popover({
            placement: 'bottom',
            fallbackPlacement: [],
            content: this.params.exportTemplate,
            html: true,
            sanitize: false,
            title: this.params.exporterFacture
        }).on('show.bs.popover', function () {
            $($(this).data('bs.popover').tip).css('min-width', '20%');
        }).on('shown.bs.popover', () => {
            App.Facturation.Facture._initExport(this.params.exportUrl, this.params.filename);
        });

        $('body').on('click', function (e) {
            if (!$(e.target).is('#exportBtn') && $(e.target).parents('#exportBtn').length === 0 && $(e.target).parents('.popover.show').length === 0) {
                $('#exportBtn').popover('hide');
            }
        });
    }

    _initValider() {
        $('.js-valider-btn').prop('disabled', false).click((e) => {
            App.Facturation.Facture.openValiderSwal().then((result) => {
                if (result.value) {
                    $(e.currentTarget).prop('disabled', true);
                    $(e.currentTarget).closest('form').submit();
                }
            });
        });
    }
};

App.Facturation.Facture.Form = class {
    constructor(params) {
        this.id = params.id;
        this.form = params.form;
        this.paths = params.paths;
        this.libelles = params.libelles;
        this.ids = params.ids;
        this.templates = params.templates;
        this.type = params.type;
        this.factureData = {};

        if(this.type === 'avoir') {
            this.formAjaxPath = this.id ?
                Router.generate('facturation.facture.edit.ajax', {id: this.id}) :
                Router.generate('facturation.facture.new.avoirajax', {base: params.base})
            ;
        } else {
            this.formAjaxPath = this.id ?
                Router.generate('facturation.facture.edit.ajax', {id: this.id}) :
                Router.generate('facturation.facture.new.ajax', {type: this.type})
            ;
        }

        this._initForm();
        this._initErrors();
        this._initAjax();

        if(this.type !== 'avoir') {
            this._initGarde();
            this._initAutocomplete();
            this._initPrescriptionEnLigne();
            App.Facturation.Facture.initTrajets(this);
            this._initExoneration();
        }

        if(this.id) {
            this._initEdit();
        }
        if(this.type === 'libre') {
            this._initLibre();
        }
        else if(this.type === 'standard') {
            App.Facturation.Facture.initSefiFlash(params);
            this._initStandard();
        }
        else if(this.type === 'avoir') {
            this._initAvoir();
        }
    }

    _initGarde() {
        this.estGardeChangedFromUpdate = false;
        $('#'+this.form.estGarde).change((e) => {
            if(e.currentTarget.checked) {
                this._updateEstGarde(true);
            }
        });
    }

    _updateEstGarde(force = false)
    {
        if(this.estGardeChangedFromUpdate) {
            this.estGardeChangedFromUpdate = false;
            return;
        }

        const firstTrajet = this.vm.trajetsArray[0];

        if (firstTrajet && Object.keys(firstTrajet.topPriseEnChargeDepartReel).length) {
            this.ignoreNextUpdate = true;
            let params = {
                structure: App.Shared.getStructure(),
                datetime: firstTrajet.topPriseEnChargeDepartReel.date+' '+firstTrajet.topPriseEnChargeDepartReel.time
            };
            if(force) {
                params.force = 1;
            }

            $.get(Router.generate('facturation.facture.garde.ajax', params)).done((data) => {
                if (data.success) {
                    const $formEstGarde = $('#'+this.form.estGarde);

                    if(!$formEstGarde.is(':checked')) {
                        this.estGardeChangedFromUpdate = true;
                        $formEstGarde.prop('checked', true).change();
                    }

                    $('#' + this.form.gardeDebut.date).val(data.garde.debut.date).change();
                    $('#' + this.form.gardeDebut.time).val(data.garde.debut.time).change();
                    $('#' + this.form.gardeFin.date).val(data.garde.fin.date).change();
                    $('#' + this.form.gardeFin.time).val(data.garde.fin.time).change();
                }
            });
        }
    }

    _initForm()
    {
        $('#sauvegarderEtValiderBtn').click(() => {
            $('#' + this.form.estValidee).val(true);
            $('#form').submit();
            return false;
        });
        $('#sauvegarderSansValiderBtn').click(() => {
            $('#' + this.form.estValidee).val('');
            $('#form').submit();
            return false;
        });
    }

    /**
     * Gestion des erreurs html5 avec les onglets.
     */
    _initErrors()
    {
        $('#form').find('input').on('invalid', function() {
            $(this).closest('.collapse:not(.show)').addClass('show');
            $('.nav-link[href="#' + $(this).closest('.tab-pane').attr('id') + '"]').tab('show');
        });
    }

    /**
     * Initialise la mise à jour automatique du formulaire.
     */
    _initAjax() {
        this.formAjaxTimeout = null;
        this.formAjaxRequest = null;
        this.formAjaxTrajetRefresh = null;
        this.ignoreNextUpdate = false;

        $('body').on('change', '#form', (e) => {
            if($(e.target).closest('#app').length === 0) {
                if(this.ignoreNextUpdate) {
                    this.ignoreNextUpdate = false;

                    return;
                }
                const trajetRefresh = $(e.target).data('triggerTrajetRefresh');
                if(trajetRefresh) {
                    this.formAjaxTrajetRefresh = null !== this.formAjaxTrajetRefresh && trajetRefresh !== this.formAjaxTrajetRefresh ? 'all' : trajetRefresh;
                }

                if (this.formAjaxTimeout) {
                    clearTimeout(this.formAjaxTimeout);
                }

                this.formAjaxTimeout = setTimeout(() => {
                    this._updateAjax();
                    this.formAjaxTimeout = null;
                    this.formAjaxTrajetRefresh = null;
                }, 10);
            }
        });
    }

    _updateTabCount(tab, errorType, count)
    {
        if(count) {
            const $tab = $('[data-tab="'+tab+'"]');

            if($tab.hasClass('nav-link')) {
                $tab.find('.facture-tab-title').prepend('<span class="badge badge-' + (errorType === 'error' ? 'danger' : errorType) + ' badge-pill">' + count + '</span> ');
            }
            else if($tab.hasClass('panel')) {
                $tab.find('.js-facture-block-validation').prepend('<span class="badge badge-' + (errorType === 'error' ? 'danger' : errorType) + ' my-n1">' + count + '</span> ');
            }
        }
    }

    _handleUpdateData(data)
    {
        this.factureData = data.data || {};

        let $form = $('#form');
        $('.facture-tab-title:not([data-tab="trajets"]) .badge').remove();
        $('.js-facture-block-validation .badge').remove();
        $('.js-facture-block-validation-valid').show();

        App.Utils.handleFormErrors($form, data.errors.fields);

        const mainErrors = {error: 0, warning: 0, info: 0};
        for(let errorType in data.errors.tabs) {
            for(const tab in data.errors.tabs[errorType]) {
                const count = data.errors.tabs[errorType][tab];
                const $tab = $('[data-tab="'+tab+'"]');

                if(!$tab.hasClass('nav-link')) {
                    $tab.find('.js-facture-block-validation-valid').hide();
                    mainErrors[errorType]++;
                }
                this._updateTabCount(tab, errorType, count);
            }
        }

        for(const errorType in mainErrors) {
            this._updateTabCount('main', errorType, mainErrors[errorType]);
        }

        let counters = {};
        $('.js-counter').each(function() {
            if($(this).data('field')) {
                counters[$(this).data('field')] = $(this).text();
            }
        });

        let tarification = data.tarification;
        if(tarification) {
            $('.js-facture-recapitulatif').html(data.recapitulatif);
            $('.js-facture-tarification').html(tarification.html);
            for (let field in counters) {
                $('.js-counter[data-field="' + field + '"]').text(counters[field]);
            }

            App.Layout.animateNumbers($('.js-counter[data-field="total"]'), tarification.total, 2);

            if (this.type === 'libre' || this.type === 'avoir') {
                App.Layout.animateNumbers($('.js-counter[data-field="totalHt"]'), tarification.totalHt, 2);
                App.Layout.animateNumbers($('.js-counter[data-field="totalTva"]'), tarification.totalTva, 2);
            } else if (this.type === 'standard') {
                for (let field of ['caisse', 'mutuelle', 'patient', 'prescripteur']) {
                    App.Layout.animateNumbers($('.js-counter[data-field="' + field + 'Pourcentage"]'), tarification[field].pourcentage);
                    App.Layout.animateNumbers($('.js-counter[data-field="' + field + 'Total"]'), tarification[field].total, 2);
                }
            }

            $('#numeroAm').toggle($('#facture_am').val() !== '' || this.factureData.amRequis || data.errors.fields.facture_am !== undefined);
        }
    }

    update(data) {
        if(data.facture) {
            this._handleUpdateData(data.facture);
        }
        if (this.vm) {
            this.vm.handleUpdateData(data.trajets || {});
            this.vm.updateSidebar();
        }
    }
    /**
     * Effectue la mise à jour du formulaire.
     */
    _updateAjax()
    {
        let $form = $('#form');
        $('body').addClass('cursor-progress');

        if(this.formAjaxRequest) {
            this.formAjaxRequest.abort();
            this.formAjaxRequest = null;
        }

        let data = $form.serializeObject();
        if(this.formAjaxTrajetRefresh) {
            data.trajets = {
                refresh: this.formAjaxTrajetRefresh,
            }
        }

        this.formAjaxRequest = $.ajax({
            url: this.formAjaxPath,
            method: 'POST',
            data
        }).always(() => {
            $('body').removeClass('cursor-progress');
        }).done((data) => {
            this.update(data);
        }).fail((e) => {
        });
    }

    /**
     * Initialise le comportement du formulaire de modification.
     */
    _initEdit() {
        // Dirty form

        let $indicateurNonModifie = $('#indicateurNonModifie');
        let $indicateurModifie = $('#indicateurModifie');

        $('#form').dirtyForms({
            dialog: {
                open: (choice) => {
                    swal({
                        title: App.Constants.LIBELLE_ETES_VOUS_SUR,
                        text: this.libelles.dialog,
                        type: 'warning',
                        showCancelButton: true,
                        focusConfirm: false,
                        focusCancel: true,
                        confirmButtonClass: 'bg-danger',
                        confirmButtonText: Translator.trans('action.quitter'),
                        cancelButtonText: Translator.trans('action.annuler'),
                        onOpen: () => {
                            let $saveBtn = $('<button type="button" class="swal2-styled bg-info">' + Translator.trans('action.sauvegarder') + '</button>');
                            $saveBtn.click(function() {
                                $('#form').submit();
                                return false;
                            });
                            $(swal.getButtonsWrapper()).prepend($saveBtn);
                        }
                    }).then((result) => {
                        if (result.value) {
                            choice.proceed = true;
                        }
                        choice.commit(new Event('yolo'));
                    });
                }
            }
        }).on('dirty.dirtyforms', () => {
            $indicateurNonModifie.hide();
            $indicateurModifie.show();
        }).on('clean.dirtyforms', function() {
            $indicateurModifie.hide();
            $indicateurNonModifie.show();
        });
    }

    _initLibre() {
        let $body = $('body');
        let modeleUrl = this.paths.modeleAjax;
        let $container = $('#' + this.form.ligneAbstractCollection);
        let $placeholder = $('.widget-placeholder', $container);
        let $datePaiement = $('#facture_datePaiement');

        // Supprimer toutes les lignes
        $('#clearLignesBtn').click(() => {
            swal({
                title: App.Constants.LIBELLE_ETES_VOUS_SUR,
                text: this.libelles.clearLignesDialog,
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'bg-danger',
                confirmButtonText: Translator.trans('action.supprimer'),
                cancelButtonText: Translator.trans('action.annuler')
            }).then((result) => {
                if (result.value) {
                    $container.find('.js-ligne').remove();
                    $placeholder.show();
                    this._updateAjax();
                }
            });
        });

        $datePaiement.on('change', () => {
            $('#facture_datePaiement').addClass('dirty');
        });

        function updateDatePaiement() {
            if (!$datePaiement.hasClass('dirty') || !$datePaiement.val()) {
                let delais = $('#facture_payeur').find('input:checked').data('delai');
                let structure = $('#facture_structure input:checked, #facture_structure option:selected').val();
                if (typeof delais[structure] !== 'undefined' && delais[structure] !== null) {
                    let dateFacture = $('#facture_date').val().split('/');
                    let date = new Date(dateFacture[2], parseInt(dateFacture[1]) - 1, dateFacture[0]);
                    date.setDate(date.getDate() + delais[structure]);
                    $datePaiement.val(date.toLocaleDateString());
                }
            }
        }

        // Dates de paiement selon la structure et le payeur sélectionné
        $('#facture_payeur input, #facture_structure input').on('change', () => {
            updateDatePaiement();
        });

        // Popover modèle
        $('#modeleBtn').popover({
            placement: 'bottom',
            fallbackPlacement: [],
            content: this.templates.modele,
            sanitize: false,
            html: true,
            title: this.libelles.appliquerModele
        }).on('show.bs.popover', function() {
            $($(this).data('bs.popover').tip).css('min-width', '20%');
        }).on('shown.bs.popover', function() {
            App.Layout.enableSelect2();
            let applyModeleBtnLadda = ladda.create($('#applyModeleBtn')[0]);

            $('#modeleForm').submit(function() {
                applyModeleBtnLadda.start();

                let index = 0;
                $container.find('.js-ligne').each((i, item) => {
                    let id = $(item).find('input[type="hidden"]:first-child').attr('id');
                    let match = /\w+_js(\d+)_\w+/.exec(id);

                    if(match) {
                        index = Math.max(index, +match[1]);
                    }
                });

                $('#modele_index').val(index);

                $.ajax({
                    url: modeleUrl.replace('_STRUCTURE_', App.Shared.getStructure() ? App.Shared.getStructure() : 0),
                    method: 'POST',
                    data: $(this).serialize(),
                }).always(function() {
                    applyModeleBtnLadda.stop();
                }).done((data) => {
                    if(data.html) {
                        $(this).html($(data.html).html());
                        App.Layout.enableSelect2();
                        ladda.bind('#applyModeleBtn');
                    }
                    else if(data.widgets && data.action) {
                        switch (data.action) {
                            case 'prepend':
                                $placeholder.after(data.widgets);
                                break;
                            case 'append':
                                $container.append(data.widgets);
                                break;
                            case 'replace':
                                $container.find('.js-ligne').remove();
                                $container.append(data.widgets);
                                break;
                        }

                        App.Layout.fixEmbed($container);

                        $('#modeleBtn').popover('hide');
                        $('#lignes').change();
                    }
                });

                return false;
            });
        });

        $body.on('click', function (e) {
            if (!$(e.target).is('#modeleBtn') && $(e.target).parents('#modeleBtn').length === 0 && $(e.target).parents('.popover.show').length === 0) {
                $('#modeleBtn').popover('hide');
            }
        });

        updateDatePaiement();
        App.Facturation.initLignes(this.form.ligneAbstractCollection, true);
    }

    _initAvoir() {
        $('#saveBtn').prop('disabled', false);
        $('#saveDropdownBtn').prop('disabled', false);

        App.Facturation.initLignes(this.form.ligneAbstractCollection, false);

        // ~ Empêche de supprimer toutes les lignes
        let $ligneContainer = $('#lignes');
        $ligneContainer.change(() => {
            let $lignes = $ligneContainer.find('.js-ligne');
            $lignes.find('.js-widget-remove').prop('disabled', $lignes.length === 1);
        });

        this._updateAjax();
    }

    _autoSelectCarteCps() {
        if ($('#cpsMenu').length) {
            const cpsStore = useCpsStore();

            const structureId = App.Shared.getStructure();
            const structureMandataireId = App.Shared.getStructureElement().data('societeMandataireSefiId');

            if (!cpsStore.carteDefaut || (!cpsStore.carteDefaut.structureCollection.includes(structureId) && (!structureMandataireId || !cpsStore.carteDefaut.structureCollection.includes(structureMandataireId)))) {
                let foundCarte = null;

                for (const carte of cpsStore.cartes) {
                    if (carte.structureCollection.includes(structureId)) {
                        foundCarte = carte;
                        break;
                    }
                }

                if (!foundCarte && structureMandataireId) {
                    for (const carte of cpsStore.cartes) {
                        if (carte.structureCollection.includes(structureMandataireId)) {
                            foundCarte = carte;
                            break;
                        }
                    }
                }

                if (foundCarte) {
                    cpsStore.setCarteDefaut(foundCarte);
                }
            }
        }
    }

    _initStandard() {
        const standard = {};
        let $date = $('#facture_date');
        let $aTeletransmettre = $('#aTeletransmettre');
        let $tiersPayantAmo = $('#facture_tiersPayantAmo');
        let $tiersPayantAmc = $('#facture_tiersPayantAmc');

        this._autoSelectCarteCps();

        $(document).on('change', 'select[name="facture[structure]"], input[name="facture[structure]"]', () => this._autoSelectCarteCps());

        $('input[name="' + this.form.typeFacture + '"]').change((e) => {
            const estB2 = $(e.currentTarget).val() === this.ids.typeFactureB2.toString();
            const estFEL = $(e.currentTarget).val() === this.ids.typeFactureFEL.toString();;

            if (this.vm) {
                this.vm.standard.estB2 = estB2;
                this.vm.standard.estFEL = estFEL;
            }
            $aTeletransmettre.toggle(estB2).find('input').prop('checked', estB2);
            $date.prop('readonly', estFEL).next().find('.js-datetimebutton').prop('disabled', estFEL);

            if(estFEL) {
                const $naturePiecesJustificativesOuvertureDroits = $('#facture_naturePiecesJustificativesOuvertureDroits');
                if ($naturePiecesJustificativesOuvertureDroits.val() === '') {
                    $naturePiecesJustificativesOuvertureDroits.val(0).change();
                }

                $date.val(Moment().format('DD/MM/YYYY')).change();
                $('#facture_estValidee').val('');
            }
        });

        standard.estB2 = $('#facture_typeFacture_'+this.ids.typeFactureB2).is(':checked');
        standard.estFEL = $('#facture_typeFacture_'+this.ids.typeFactureFEL).is(':checked');

        let pecPlus = new App.PecPlus(this.paths.pecPlusAjax);

        $('#pecPlusBtn').click(() => {
            pecPlus.call($('#form').serialize());
        });

        let $partCaissePourcent = $('#facture_partCaissePourcent');
        let $partMutuellePourcent = $('#facture_partMutuellePourcent');

        $partCaissePourcent.change((e) => {
            const toggle = e.currentTarget.value > 0;
            $tiersPayantAmo.prop('checked', toggle).change().parent().toggleClass('active', toggle);
        });
        $partMutuellePourcent.change((e) => {
            const toggle = e.currentTarget.value > 0;
            $tiersPayantAmc.prop('checked', toggle).change().parent().toggleClass('active', toggle);
        });

        if (this.vm) {
            this.vm.standard = standard;
        }
    }

    /**
     * Initialise le comportement supplémentaire des autocompléteurs (assure, beneficiaire, prescripteur).
     * @private
     */
    _initAutocomplete() {
        const $refreshPatientBtn = $('#refreshPatientBtn');
        const $refreshPrescripteurBtn = $('#refreshPrescripteurBtn');
        let $assure = $('#' + this.form.patientAssure);
        let $beneficiaire = $('#' + this.form.patientBeneficiaire);
        let $caisse = $('#' + this.form.caisse);
        let $mutuelle = $('#' + this.form.mutuelle);
        let $partCaissePourcent = $('#' + this.form.partCaissePourcent);
        let $partMutuellePourcent = $('#' + this.form.partMutuellePourcent);
        let $mutuelleNumeroAdherent = $('#mutuelleNumeroAdherent');
        let $mutuelleDateCmu = $('#mutuelleDateCmu');
        let $inputs = $('#' + this.form.caisse + ', #' + this.form.mutuelle + ', #' + this.form.prescripteur + ', #' + this.form.patientAssure + ', #' + this.form.patientBeneficiaire);
        let structureInitiale = $('select[name="facture[structure]"], input[name="facture[structure]"]').val();
        let assureMutuelle = {
            id: typeof this.ids.assureMutuelle !== 'undefined' ? this.ids.assureMutuelle.id : null,
            text: typeof this.ids.assureMutuelle !== 'undefined' ? this.ids.assureMutuelle.text : null,
            card: typeof this.ids.assureMutuelle !== 'undefined' ? this.ids.assureMutuelle.card : this.templates.placeholder.mutuelle
        };
        let assureCaisse = {
            id: typeof this.ids.assureCaisse !== 'undefined' ? this.ids.assureCaisse.id : null,
            text: typeof this.ids.assureCaisse !== 'undefined' ? this.ids.assureCaisse.text : null,
            card: typeof this.ids.assureCaisse !== 'undefined' ? this.ids.assureCaisse.card : this.templates.placeholder.caisse
        };
        let assurePartMutuellePourcent = null;
        let assurePartCaissePourcent = null;

        // Refresh
        $refreshPatientBtn.click(() => {
            $refreshPatientBtn.prop('disabled', true);
            const $patient = $beneficiaire.val() ? $beneficiaire : ($assure.val() ? $assure : null);
            if($patient) {
                $.get(Router.generate('facturation.facture.refresh-patient.ajax', {patient: $patient.val()})).done((data) => {
                    if(data.assure) {
                        $assure.html(new Option(data.assure.text, data.assure.id, true, true));
                        $assure.trigger({
                            type: 'select2:select',
                            params: {
                                data: data.assure
                            }
                        });
                    }

                    const $newPatient = data.type === 'assure' ? $assure : $beneficiaire;
                    $newPatient.html(new Option(data.text, data.id, true, true));
                    $newPatient.trigger({
                        type: 'select2:select',
                        params: {data}
                    });

                    $refreshPatientBtn.prop('disabled', false);
                });
            }
        });

        const $prescripteurLink = $('#'+this.form.prescripteurLink);
        const $prescripteurForm = $('#'+this.form.prescripteurForm);

        $refreshPrescripteurBtn.click(() => {
            $refreshPrescripteurBtn.prop('disabled', true);
            if ($prescripteurLink.val()) {
                $.get(Router.generate('facturation.facture.refresh-prescripteur.ajax', {prescripteur: $prescripteurLink.val()})).done((data) => {
                    $prescripteurForm.typeahead('val', data.text);
                    $prescripteurForm.trigger('typeahead:select', data);
                    $refreshPrescripteurBtn.prop('disabled', false);
                });
            }
        });

        // Patient assuré, patient bénéficaire, caisse et mutelle

        // Cards
        for(const field of ['patientAssure', 'patientBeneficiaire', 'caisse', 'mutuelle']) {
            $('#'+field+'Card').popover({
                trigger: 'hover',
                placement: 'left',
                html: true,
            }).on('show.bs.popover', function () {
                const $tip = $($(this).data('bs.popover').tip);
                $tip.css('min-width', '25%');
                const $content = $tip.find('.popover-body');
                $content.addClass('p-3');
            });
        }

        $inputs.on('select2:select', (e) => {
            let $el = $(e.currentTarget);
            let data = e.params.data;
            const $card = $('#'+$el.data('field')+'Card');
            $card.attr('data-content', data.card);
            if(data.cardLink) {
                $card.find('.js-card-btn-empty').removeClass('d-flex').addClass('d-none');
                $card.find('.js-card-btn').attr('href', data.cardLink).removeClass('disabled').removeClass('d-none').addClass('d-flex')
            } else {
                $card.find('.js-card-btn').attr('href', '#').addClass('disabled').removeClass('d-flex').addClass('d-none')
                $card.find('.js-card-btn-empty').removeClass('d-none').addClass('d-flex')
            }

            if(data.caisse) {
                if(data.caisse.id) {
                    $caisse.html(new Option(data.caisse.text, data.caisse.id, false, false));
                } else {
                    $caisse.val(null);
                }
                $caisse.trigger('change.select2');

                // Card
                const $caisseCard = $('#caisseCard');

                $caisseCard.attr('data-content', data.caisse.card);

                if(data.caisse.cardLink) {
                    $caisseCard.find('.js-card-btn-empty').removeClass('d-flex').addClass('d-none');
                    $caisseCard.find('.js-card-btn').attr('href', data.caisse.cardLink).removeClass('disabled').removeClass('d-none').addClass('d-flex')
                } else {
                    $caisseCard.find('.js-card-btn').attr('href', '#').addClass('disabled').removeClass('d-flex').addClass('d-none')
                    $caisseCard.find('.js-card-btn-empty').removeClass('d-none').addClass('d-flex')
                }
            }
            if(data.mutuelle) {
                $mutuelleDateCmu.hide();

                if(data.mutuelle.id) {
                    $mutuelle.html(new Option(data.mutuelle.text, data.mutuelle.id, false, false));
                    $mutuelleNumeroAdherent.show().find('input').val(data.mutuelle.numeroAdherent);

                    if(data.mutuelle.id === this.ids.mutuelle.cmu) {
                        $mutuelleDateCmu.show();
                    }
                } else {
                    $mutuelle.val(null);
                    $mutuelleNumeroAdherent.hide().find('input').val(null);
                }
                $mutuelle.trigger('change.select2');

                // Card
                const $mutuelleCard = $('#mutuelleCard');

                $mutuelleCard.attr('data-content', data.mutuelle.card);

                if(data.mutuelle.cardLink) {
                    $mutuelleCard.find('.js-card-btn-empty').removeClass('d-flex').addClass('d-none');
                    $mutuelleCard.find('.js-card-btn').attr('href', data.mutuelle.cardLink).removeClass('disabled').removeClass('d-none').addClass('d-flex')
                } else {
                    $mutuelleCard.find('.js-card-btn').attr('href', '#').addClass('disabled').removeClass('d-flex').addClass('d-none')
                    $mutuelleCard.find('.js-card-btn-empty').removeClass('d-none').addClass('d-flex')
                }
            }

            if(data.nature) {
                $('#' + this.form.nature).val(data.nature).trigger('input');
            }
            if(data.exoneration) {
                $('#' + this.form.exoneration).val(data.exoneration).trigger('input');
            }

            if($el.is($assure) || $el.is($beneficiaire)) {
                if(data.tauxCaisse !== null) {
                    $partCaissePourcent.val(data.tauxCaisse).change();
                } else if(data.caisse) {
                    $caisse.trigger({
                        type: 'select2:select',
                        params: {
                            data: data.caisse
                        }
                    });
                }

                if(data.tauxMutuelle !== null) {
                    $partMutuellePourcent.val(data.tauxMutuelle).change();
                } else if(data.mutuelle) {
                    $caisse.trigger({
                        type: 'select2:select',
                        params: {
                            data: data.mutuelle
                        }
                    });
                }

                $refreshPatientBtn.show();
            }

            if($el.is($assure)) {
                assureCaisse = data.caisse;
                assureMutuelle = data.mutuelle;
                assurePartCaissePourcent = data.tauxCaisse;
                assurePartMutuellePourcent = data.tauxMutuelle;
                $beneficiaire.val(null).trigger('change');
            }
            else if($el.is($mutuelle)) {
                $mutuelleNumeroAdherent.show();
                $mutuelleDateCmu.toggle(data.id === this.ids.mutuelle.cmu)
            }

            $('#form').change();
        });

        $inputs.on('change', (e) =>  {
            let $el = $(e.currentTarget);

            if(!$el.val()) {
                const $card = $('#'+$el.data('field')+'Card');
                $card.attr('data-content', this.templates.placeholder[$el.data('field')]);
                $card.find('.js-card-btn').attr('href', '#').addClass('disabled').removeClass('d-flex').addClass('d-none');
                $card.find('.js-card-btn-empty').removeClass('d-none').addClass('d-flex');

                if($el.is($assure)) {
                    $refreshPatientBtn.hide();

                    assureMutuelle = {
                        id: null,
                        card: this.templates.placeholder.mutuelle
                    };
                    assureCaisse = {
                        id: null,
                        card: this.templates.placeholder.caisse
                    };
                    assurePartMutuellePourcent = null;
                    assurePartCaissePourcent = null;

                    $beneficiaire.val(null).trigger('change');
                }
                else if($el.is($beneficiaire)) {
                    // Field caisse
                    if(assureCaisse.id) {
                        $caisse.html(new Option(assureCaisse.text, assureCaisse.id, false, false));
                    } else {
                        $caisse.val(null);
                    }
                    $partCaissePourcent.val(assurePartCaissePourcent !== null ? assurePartCaissePourcent : ($partCaissePourcent.val() ? $partCaissePourcent.val() : 0)).change();

                    $caisse.trigger('change.select2');

                    // Card caisse
                    const $caisseCard = $('#caisseCard');

                    $caisseCard.attr('data-content', assureCaisse.card);

                    if(assureCaisse.cardLink) {
                        $caisseCard.find('.js-card-btn-empty').removeClass('d-flex').addClass('d-none');
                        $caisseCard.find('.js-card-btn').attr('href', assureCaisse.cardLink).removeClass('disabled').removeClass('d-none').addClass('d-flex')
                    } else {
                        $caisseCard.find('.js-card-btn').attr('href', '#').addClass('disabled').removeClass('d-flex').addClass('d-none')
                        $caisseCard.find('.js-card-btn-empty').removeClass('d-none').addClass('d-flex')
                    }

                    // Field mutuelle
                    if(assureMutuelle.id) {
                        $mutuelle.html(new Option(assureMutuelle.text, assureMutuelle.id, false, false));
                        $mutuelleNumeroAdherent.show().find('input').val(assureMutuelle.numeroAdherent);

                        if(assureMutuelle.id === this.ids.mutuelle.cmu) {
                            $mutuelleDateCmu.show();
                        }
                    } else {
                        $mutuelle.val(null);
                        $mutuelleNumeroAdherent.hide().find('input').val(null);
                        $mutuelleDateCmu.find('input').val(null);
                    }
                    $partMutuellePourcent.val(assurePartMutuellePourcent !== null ? assurePartMutuellePourcent : ($partMutuellePourcent.val() ? $partMutuellePourcent.val() : 0)).change();

                    $mutuelle.trigger('change.select2');

                    // Card mutuelle
                    const $mutuelleCard = $('#mutuelleCard');

                    $mutuelleCard.attr('data-content', assureMutuelle.card);
                    if(assureMutuelle.cardLink) {
                        $mutuelleCard.find('.js-card-btn-empty').removeClass('d-flex').addClass('d-none');
                        $mutuelleCard.find('.js-card-btn').attr('href', assureMutuelle.cardLink).removeClass('disabled').removeClass('d-none').addClass('d-flex')
                    } else {
                        $mutuelleCard.find('.js-card-btn').attr('href', '#').addClass('disabled').removeClass('d-flex').addClass('d-none')
                        $mutuelleCard.find('.js-card-btn-empty').removeClass('d-none').addClass('d-flex')
                    }
                }
                else if($el.is($mutuelle)) {
                    $mutuelleNumeroAdherent.hide().find('input').val(null);
                    $mutuelleDateCmu.hide().find('input').val(null);
                }

                $('#form').change();
            }
        });

        $(document).on('change', 'select[name="facture[structure]"], input[name="facture[structure]"]', (e) => {
            let $el = $(e.currentTarget);
            let partage = $el.data('partageDonnees');
            let grilles = JSON.parse($el.data('grilles'));
            if (!structureInitiale || partage.indexOf(structureInitiale) === -1) {
                $assure.val('').trigger('change');
                $beneficiaire.val('').trigger('change');
                $caisse.val('').trigger('change');
                $mutuelle.val('').trigger('change');
            }

            for(const grille in grilles) {
                const $grille = $('[data-field="grille'+grille.charAt(0).toUpperCase()+grille.slice(1)+'"]');
                const currentGrilleStructure = ($grille.data('custom') || {}).structure;
                const defaultGrille = grilles[grille];

                if(!$grille.val() || (currentGrilleStructure && partage.indexOf(currentGrilleStructure) === -1)) {
                    if(defaultGrille) {
                        $grille.html(new Option(defaultGrille.text, defaultGrille.id, true, true)).change();
                    } else {
                        $grille.val(null).change();
                    }
                }
            }

            structureInitiale = $el.val();
        });

        // Prescripteur
        App.Shared.initPrescripteur(this.paths.prescripteurAjax, this.form.prescripteurLink, this.form.prescripteurForm, this.templates.placeholder.prescripteurLibre, this.templates.placeholder.prescripteur);
    }


    _initPrescriptionEnLigne() {
        $('#facture_numeroPrescription').on('input', (event) => {
            $(event.currentTarget).removeClass('border-theme');
            $('#facture_prescriptionEnLigneData').val('null');
            $('#facture_datePrescription').prop('readonly', false).next().find('.js-datetimebutton').prop('disabled', false);
            if (this.vm) {
                this.vm.resetSefiIds();
            }
        });
    }

    /**
     * Initialise le comportement des champs type exo/exo/nature.
     */
    _initExoneration() {
        const $accidentTravail = $('#' + this.form.dateAccidentTravail);
        const $numeroTravail = $('#' + this.form.numeroAccidentTravail);
        const $dateMaternite = $('#' + this.form.dateMaternite);
        const $estArticleL115 = $('#' + this.form.estArticleL115);
        const $partPrescripteurPourcent = $('#' + this.form.partPrescripteurPourcent);
        const exonerationFieldsByNature = {
            '41': [$numeroTravail, $accidentTravail],
            '30': [$dateMaternite],
        };
        const exonerationFieldsByExoneration = {
            '5': [$estArticleL115],
        };

        App.Shared.initExoneration(
            this.form.id+'_typeExonerationBtn',
            this.form.nature,
            this.form.exoneration,
            this.form.caisse,
            this.form.partCaissePourcent,
            this.form.mutuelle,
            this.form.partMutuellePourcent,
            (id) => {
                if ('8bc2c002-0b1d-41d4-8c27-34c2aaa76512' === id) {
                    $estArticleL115.prop('checked', true).parent().addClass('active');
                }
                $partPrescripteurPourcent.val(0).change();
            },
            (nature, exoneration) => {
                if (5 !== exoneration) {
                    $estArticleL115.prop('checked', false).parent().removeClass('active');
                }

                for(const nature2 in exonerationFieldsByNature) {
                    const $fields = exonerationFieldsByNature[nature2];
                    for(const $field of $fields) {
                        $field.parents('.js-exoneration-field').toggle(nature === nature2);

                        if (nature !== nature2) {
                            $field.val('');
                        }
                    }
                }

                for(const exoneration2 in exonerationFieldsByExoneration) {
                    const $fields = exonerationFieldsByExoneration[exoneration2];
                    for(const $field of $fields) {
                        $field.parents('.js-exoneration-field').toggle(exoneration === exoneration2);

                        if (exoneration !== exoneration2) {
                            $field.val('');
                        }
                    }
                }
            },
            this.vm,
        );
    }
};

App.Facturation.Noemie = class {};

App.Facturation.Noemie.Retour = class {};
App.Facturation.Noemie.Retour.View = class {

    constructor(params) {
        this.params = {};
        this.params.exportPopoverTemplate = params.exportPopoverTemplate;
        this.params.exportPopoverTitle = params.exportPopoverTitle;

        this.initExport(params.entityId)
    }

    initExport(id) {
        $('#exportBtn').popover({
            placement: 'top',
            fallbackPlacement: [],
            content: this.params.exportPopoverTemplate,
            html: true,
            sanitize: false,
            title: this.params.exportPopoverTitle,
        }).on('show.bs.popover', function () {
            $($(this).data('bs.popover').tip).css('min-width', '20%');
        }).on('shown.bs.popover', () => {
            Cookies.remove('retourExportDownloadFinished');

            let $exportPrintBtn = $('#exportPrintBtn');
            let $exportDownloadBtn = $('#exportDownloadBtn');

            let exportPrintBtnLadda = ladda.create($exportPrintBtn[0]);
            let exportDownloadBtnLadda = ladda.create($exportDownloadBtn[0]);

            let printUrl = Router.generate('facturation.noemie.retour.export', {app: App.Constants.APPLICATION, id, type: 'html'});
            let downloadUrl = Router.generate('facturation.noemie.retour.export', {app: App.Constants.APPLICATION, id, type: 'pdf'});

            $exportPrintBtn.on('click', (event) => {
                exportPrintBtnLadda.start();
                $exportDownloadBtn.prop('disabled', true);

                App.Utils.print(printUrl).then(() => {
                    exportPrintBtnLadda.stop();
                    $exportDownloadBtn.prop('disabled', false);
                });
            })

            $exportDownloadBtn.on('click', (event) => {
                exportDownloadBtnLadda.start();
                $exportPrintBtn.prop('disabled', true);

                App.Utils.download(downloadUrl).then(() => {
                    let checkFinish = () => {
                        if (Cookies.get('retourExportDownloadFinished')) {
                            exportDownloadBtnLadda.stop();
                            $exportPrintBtn.prop('disabled', false);
                            Cookies.remove('retourExportDownloadFinished');
                        } else {
                            setTimeout(checkFinish, 1000);
                        }
                    };

                    checkFinish();
                });
            })
        });
    }
};