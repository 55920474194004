<template>
    <textarea v-if="'textarea' === type" :class="inputClass" :value="value" @keyup="onKeyUp" @change="onChange" @input="onInput" autocomplete="ambuerp"></textarea>
    <input v-else :type="type || 'text'" :class="inputClass" :value="value" @keyup="onKeyUp" @change="onChange" @input="onInput" autocomplete="ambuerp">
</template>

<script setup>
import {computed, toRefs} from "vue";

const emit = defineEmits(['reset', 'input', 'change']);

const props = defineProps({
    value: null,
    type: String,
    isInvalid: Boolean,
    outlined: Boolean,
});
const {value, type, isInvalid, outlined} = toRefs(props);

const inputClass = computed(() => ({'form-control': true, 'is-invalid js-vue-validation': isInvalid.value, 'border-primary': outlined.value}));

const onChange = ($event) => {
    emit('change', $event.target.value);
};

const onInput = ($event) => {
    emit('input', $event.target.value);
};

const onKeyUp = (event) => {
    if(event.key === 'Backspace' && (value.value === '' || value.value === undefined)) {
        emit('reset');
    }
};
</script>