<template>
    <DataTable :value="ressource.regulation._trajetCollection"
               scrollable scrollHeight="flex"
               stripedRows
               showGridlines
               size="small"
               class="p-2"
    >
        <Column field="rendezVous" header="RDV">
        </Column>
    </DataTable>
</template>

<script setup>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {toRefs} from "vue";

const props = defineProps({
    ressource: Object,
});
const {ressource} = toRefs(props);
</script>