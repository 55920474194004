<template>
    <tr v-if="value !== undefined">
        <th class="width-180px" :title="title">{{ label }}</th>
        <td>
            <slot>{{ displayValue }}</slot>
        </td>
        <td class="width-50px text-center" v-if="targetObj">
            <b-btn v-if="canReporter" :disabled="loading" @click="reporter" size="sm" :class="btnClass" variant="info" title="Reporter"><i class="fa-regular fa-clone"></i></b-btn>
            <b-btn v-else size="sm" :variant="noReportReason ? 'danger' : 'success'" :class="btnClass" :title="noReportReason ?? 'Reporter'" disabled>
                <i class="fa-solid" :class="noReportReason ? 'fa-xmark px-1' : 'fa-check'"></i>
            </b-btn>
        </td>
        <td v-else-if="$parent.hasReporterColumn"></td>
    </tr>
</template>

<script>
export default {
    props: ['label' ,'value', 'target', 'title', 'noReportReason', 'reportVisibleOnly', 'report'],
    data() {
        return {
            targetValue: null,
            loading: false,
        }
    },
    methods: {
        async reporter() {
            if (this.canReporter) {
                this.loading = true;

                if (this.report) {
                    await this.report();
                } else {
                    if (this.targetObj.hasClass('js-select2-autocomplete')) {
                        let option = new Option(this.value.text, this.reporterValue, false, false);
                        this.targetObj.html(option).trigger({
                            type: 'select2:select',
                            params: {data: this.reporterValue}
                        });
                    } else if(this.targetObj.hasClass('tt-input')) {
                        this.targetObj.typeahead('val', this.reporterValue);

                        if(this.targetObj.hasClass('js-geocodage-active')) {
                            this.targetObj.trigger('input'); // pour reset indicateur géocodage
                        }
                    } else {
                        this.targetObj.val(this.reporterValue);
                    }
                    this.targetObj.change();
                    App.Layout.focusField(this.targetObj);

                    if (this.targetObj.hasClass('select2-hidden-accessible')) {
                        this.targetObj.select2('close');
                    } else if (this.targetObj.hasClass('tt-input')) {
                        this.targetObj.typeahead('close');
                    } else if (this.targetObj.hasClass('js-datetimepicker')) {
                        this.targetObj.blur();
                    }
                }

                this.$emit('report');
                this.$parent.$emit('report');
                this.loading = false;
            }
        },
    },
    computed: {
        btnClass() {
            return 'py-1 mt-n2 mb-n1';
        },
        canReporter() {
            if (this.noReportReason !== undefined || this.value === undefined || !this.targetObj) {
                return false;
            }

            if (Array.isArray(this.targetValue)) {
                return !this.targetValue.includes(this.reporterValue);
            } else {
                const lowercaseTargetValue = this.targetValue !== null ? this.targetValue.toLowerCase() : null;
                return lowercaseTargetValue !== this.reporterValue.toLowerCase();
            }
        },
        reporterValue() {
            if (null === this.value) {
                return '';
            }
            if (typeof this.value === 'object') {
                return this.value.id;
            }
            if (typeof this.value === 'number') {
                return this.value.toString();
            }

            return this.value;
        },
        displayValue() {
            if (null === this.value) {
                return '';
            }
            if (typeof this.value === 'object') {
                return this.value.text;
            }

            return this.value;
        },
        targetObj() {
            if(this.target !== undefined) {
                const $target = $(this.target)

                if($target.length && (!this.reportVisibleOnly || $target.is(':visible'))) {
                    return $target;
                }
            }

            return null;
        }
    },
    watch: {
        targetObj: {
            handler() {
                if (this.targetObj) {
                    this.targetObj.change((event) => {
                        this.targetValue = event.currentTarget.value;
                    })

                    this.targetValue = this.targetObj.val();
                }
            },
            immediate: true,
        },
        canReporter() {
            this.$parent.$emit('refresh');
        }
    }
}
</script>
