'use strict';

import {createApp} from "vue";

App.Concurrency = class {
    constructor(hash) {
        this.hash = hash;

        const concurrency = document.getElementById('concurrency');

        if (concurrency) {
            createApp({
                template: '<concurrency :hash="hash"></concurrency>',
                data() {
                    return {
                        hash,
                    };
                },
            }).use(App.Pinia).mount(concurrency);
        }
    }
};
