'use strict';

App.Facturation.Retour = class {};

App.Facturation.Retour.Index = class {
    constructor(params) {
        this.params = params;

        this._initSidebar();
    }

    _initSidebar() {
        let $body = $('body');
        this.sidebarAjaxRequest = null;

        $body.on('click', '#retourTable tbody tr', (event) => {
            let $el = $(event.currentTarget);
            if($(event.target).closest('js-no-select').length) return;

            let id = $el.data('id');

            if(id) {
                let alreadySelected = $el.hasClass('selected');
                $('#retourTable tbody tr').removeClass('selected');
                $el.addClass('selected');

                if(!alreadySelected) {
                    this._loadSidebar(id);
                }
            }
        });

        $body.click(function(e) {
            let $target = $(e.target);
            if($('#retourTable tbody tr.selected').length && ($target.is('#page-wrapper') || (!$target.closest('#retourTable').length && $target.closest('#retourBox').length))) {
                $('#retourTable tbody tr').removeClass('selected');
                $('.js-retour-block').hide();
                $('#retourEmpty').show();
                $('#retourContent').html('');
            }
        });

    }

    _loadSidebar(id) {
        if(this.sidebarAjaxRequest) {
            this.sidebarAjaxRequest.abort();
            this.sidebarAjaxRequest = null;
        }

        if($('#retourTable tbody tr.selected').data('id')) {
            $('.js-retour-block').hide();
            $('#retourSpinner').show();
            $('#retourContent').html('');
        }

        App.Layout.openRightSide();

        this.sidebarAjaxRequest = $.ajax({
            dataType: 'html',
            url: this.params.sidebarAjax.replace('_ID_', id),
            method: 'GET',
            cache: false
        }).always(function() {
            $('.js-retour-block').hide();
        }).done(function(data) {
            $('#retourContent').html(data).show();
            App.Layout.enableDatatables();

        }).fail(function(e) {
            $('#retourErrorCode').text(e.status);
            $('#retourError').show();
            $('#retourTable tbody tr').removeClass('selected');
        });
    }
};