<template>
    <div class="row">
        <tableau-de-bord-widget-compteurs-item icon="icon-people" variant="warning">
            <template #title>{{ Translator.trans('referentiel.tableau-de-bord.general.personnelsActifs') }}</template>
            <template #content v-if="'personnelActifCount' in data">
                {{ data.personnelActifCount }}
            </template>
        </tableau-de-bord-widget-compteurs-item>
        <tableau-de-bord-widget-compteurs-item icon="ti-tablet" variant="primary">
            <template #title>{{ Translator.trans('referentiel.tableau-de-bord.general.appareilsActifs') }}</template>
            <template #content v-if="'appareilActifCount' in data">
                {{ data.appareilActifCount }}
            </template>
        </tableau-de-bord-widget-compteurs-item>
        <tableau-de-bord-widget-compteurs-item icon="ti-car" variant="danger">
            <template #title>{{ Translator.trans('referentiel.tableau-de-bord.general.vehiculesIndisponibles') }}</template>
            <template #content v-if="'vehiculeIndisponibleCount' in data">
                {{ data.vehiculeIndisponibleCount }}
            </template>
        </tableau-de-bord-widget-compteurs-item>
    </div>
</template>

<script setup>
import {onMounted, toRefs, ref, watch} from "vue";
import Request from "@/App/Request";
import Router from "@/App/Router";
import Translator from "@/App/Translator";
import {useRequest} from "@/Vue/Composables/Request";

const {postJson, abort} = useRequest();

const emit = defineEmits(['refresh', 'error']);

const props = defineProps({
    structures: Array,
    tab: String,
});

const {structures, tab} = toRefs(props);

const data = ref({});

const refresh = () => {
    if(!structures.value.length) {
        abort();
        emit('refresh');

        return;
    }

    data.value = {};

    postJson(Router.generate('referentiel.tableau-de-bord.general'), {
        tableau_de_bord_filter: {
            structure: structures.value,
        }
    }).then((res) => {
        data.value = res;
        emit('refresh');
    }, (err) => emit('error', err));
}

watch(structures, () => refresh());
onMounted(() => refresh());

</script>