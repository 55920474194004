<template>
    <div class="d-flex flex-column overflow-hidden" style="height: calc(100vh - 104px);">
        <h3 class="title-heading d-flex justify-content-between align-items-center">
            <span>Salariés</span>
            <regulation-badge-date :date="rendezVousInput"></regulation-badge-date>
        </h3>
        <div class="row no-gutters form-horizontal form-mini-filter">
            <div class="col">
                <div class="form-group">
                    <input type="text" class="form-control input-sm" placeholder="Code" v-model="filter.code">
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <form-btn-group class="input-sm" v-model="filter.group" type="radio" :htmlLabels="true" :choices="groupChoices"></form-btn-group>
                </div>
            </div>
            <div>
                <div class="btn-group btn-group-toggle input-sm">
                    <form-btn-group-item type="single_checkbox" v-model="filter.termine" :disabled="'non-renseigne' === filter.group" title="Afficher les types horaires terminés">
                        <i class="fa-solid fa-calendar-check"></i>
                    </form-btn-group-item>
                </div>
            </div>
        </div>
        <div class="js-perfect-scrollbar">
            <table class="table b-table table-striped table-hover table-compact table-ultracompact mb-0" id="salaries">
                <thead>
                    <tr>
                        <th is="vue:table-th" v-model:sort="sort" sort-column="code" class="text-center" :title="trans('libelle.code')">#</th>
                        <th is="vue:table-th" v-model:sort="sort" sort-column="type" class="text-center">Type</th>
                        <th is="vue:table-th"></th>
                        <th is="vue:table-th" v-model:sort="sort" sort-column="debut" class="text-center">Deb.</th>
                        <th is="vue:table-th" v-model:sort="sort" sort-column="fin" class="text-center">Fin</th>
                        <th is="vue:table-th" class="text-center" title="Commentaire">C</th>
                        <th is="vue:table-th" v-model:sort="sort" sort-column="pause" class="text-center" title="Pause(s)">P</th>
                        <th v-if="tempsColumn === 'etth'" is="vue:table-th" v-model:sort="sort" sort-column="etth" class="text-center" title="Écart de Temps de Travail Hebdomadaire">ETTH</th>
                        <th v-else-if="tempsColumn === 'tteh'" is="vue:table-th" v-model:sort="sort" sort-column="tteh" class="text-center" title="Temps de Travail Effectif Hebdomadaire">ETTH</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="cursor-context-menu" v-for="personnel in filteredPersonnels" :key="personnelKey(personnel)">
                        <td class="text-normal">
                            <a v-draggable:ressource="personnel" @v-drag-start="onRessourceDragStart(personnel)" @v-drag-end="onRessourceDragEnd(personnel)" target="_blank" v-if="personnel._roles.ROLE_REFERENTIEL_PERSONNEL_VIEW" :href="Router.generate('referentiel.personnel.view', {id: personnel.id})" class="badge d-block badge-dark badge-shadow" :style="'background: '+personnel.couleur+';'" :title="personnel.text">{{ personnel.shortString }}</a>
                            <span v-draggable:ressource="personnel" @v-drag-start="onRessourceDragStart(personnel)" @v-drag-end="onRessourceDragEnd(personnel)" v-else class="badge d-block badge-dark badge-shadow" :style="'background: '+personnel.couleur+';'" :title="personnel.text">{{ personnel.shortString }}</span>
                        </td>
                        <template v-if="personnel.typeCourant">
                            <td>
                                <type-horaire-badge :type="personnel.typeCourant" class="d-block"></type-horaire-badge>
                            </td>
                            <td class="pl-0 width-1px">
                                <i v-if="personnel.typeCourant.estTravailAbstract && personnel.typeCourant.acquittementAt && personnel.typeCourant.acquittementDebutPrevu" class="fa-solid fa-check text-secondary font-size-10px" :class="personnel.typeCourant.estAcquitteAJour ? 'text-success' : 'text-info'" :title="'Prise de service pour '+personnel.typeCourant.acquittementDebutPrevu.time+' acquittée le '+personnel.typeCourant.acquittementAt.date+' à '+personnel.typeCourant.acquittementAt.time+(!personnel.typeCourant.estAcquitteAJour ? ', mais l\'horaire a changé depuis' : '')"></i>
                                <i v-else-if="personnel._estValidePlanning" class="fa-solid fa-check font-size-10px" :title="'Prise de service validée jusqu\'au '+personnel.validationPlanning"></i>
                            </td>
                            <editable class="vertical-middle text-center text-nowrap" :disabled="!canEditInPlace(personnel, 'debut')" field="debut" placeholder="Début" name="planning_type_update" :url="path('regulation.trajet.planning.type.update.ajax', {id: personnel.typeCourant.id})" :class="{'bg-success-light': personnel.typeCourant.debutReel}">
                                <span class="js-editable-text">{{ personnel.typeCourant.debutReel ? personnel.typeCourant.debutReel.time : (personnel.typeCourant.debutPrevu ? personnel.typeCourant.debutPrevu.time : '') }}</span>
                                <span class="js-type-date-sup" v-if="personnel.typeCourant.debutPrevu && personnel.typeCourant.debutReel && personnel.typeCourant.debutPrevu.date !== personnel.typeCourant.debutReel.date"><sup>+1j</sup></span>
                            </editable>
                            <editable class="vertical-middle text-center text-nowrap" :disabled="!canEditInPlace(personnel, 'fin')" field="fin" placeholder="Fin" name="planning_type_update" :url="path('regulation.trajet.planning.type.update.ajax', {id: personnel.typeCourant.id})" :class="{'bg-success-light': personnel.typeCourant.finReel}">
                                <span class="js-editable-text">{{ personnel.typeCourant.finReel ? personnel.typeCourant.finReel.time : (personnel.typeCourant.finPrevu ? personnel.typeCourant.finPrevu.time : '') }}</span>
                                <span class="js-type-date-sup" v-if="personnel.typeCourant.debutPrevu && (personnel.typeCourant.finReel || personnel.typeCourant.finPrevu) && personnel.typeCourant.debutPrevu.date !== (personnel.typeCourant.finReel ? personnel.typeCourant.finReel : personnel.typeCourant.finPrevu).date"><sup>+1j</sup></span>
                            </editable>
                            <td class="vertical-middle text-center">
                                <template v-if="personnel.typeCourant.commentairePublic || personnel.typeCourant.commentaire" >
                                    <i class="fa-solid fa-info-circle text-info" :id="'typeTooltip_'+personnel.typeCourant.id"></i>
                                    <b-tooltip :target="'typeTooltip_'+personnel.typeCourant.id" triggers="hover">
                                        <div class="text-left">
                                            <div v-if="personnel.typeCourant.commentaire">
                                                <strong>Commentaire :</strong>
                                                <div class="white-space-pre-wrap">{{ personnel.typeCourant.commentaire }}</div>
                                            </div>
                                            <div v-if="personnel.typeCourant.commentairePublic" :class="{'mt-3': personnel.typeCourant.commentaire}">
                                                <strong>Commentaire public :</strong>
                                                <div class="white-space-pre-wrap">{{ personnel.typeCourant.commentairePublic }}</div>
                                            </div>
                                        </div>
                                    </b-tooltip>
                                </template>
                            </td>
                            <td class="vertical-middle text-center">
                                <template v-if="personnel.typeCourant.pauseCollection !== null">
                                    {{ personnel.typeCourant.pauseCollection.length }}
                                </template>
                                <template v-else>
                                    -
                                </template>
                            </td>
                            <td v-if="tempsColumn === 'etth'" class="pr-3 vertical-middle text-center" :class="{'text-right': personnel._ecartTempsTravail !== null}">
                                <span v-if="personnel._ecartTempsTravail !== null" :class="{'text-muted':  0 === personnel._ecartTempsTravail, 'text-danger': personnel._ecartTempsTravail > 0, 'text-success': personnel._ecartTempsTravail < 0}">
                                    {{ Duree(personnel._ecartTempsTravail, 'hhmm', true) }}
                                </span>
                                <span class="text-muted" v-else>
                                    -
                                </span>
                            </td>
                            <td v-else-if="tempsColumn === 'tteh'" class="pr-3 vertical-middle text-center" :class="{'text-right': personnel.recapitulatif !== null}">
                                <span v-if="personnel.recapitulatif !== null" :title="recapitulatifTitle(personnel.recapitulatif)" :class="{'text-danger': personnel.recapitulatif.heureSupplementaire50, 'text-warning': personnel.recapitulatif.heureSupplementaire25 && !personnel.recapitulatif.heureSupplementaire50}">
                                    {{ Duree(personnel.recapitulatif.tempsTravailEffectif, 'hhmm') }}
                                </span>
                                <span class="text-muted" v-else>
                                    -
                                </span>
                            </td>
                        </template>
                        <td colspan="7" v-else>
                            <a href="#" class="btn btn-xs btn-success js-salaries-type-add js-context-menu-btn">
                                <i class="fa-solid fa-plus"></i> Ajouter un type horaire
                            </a>
                        </td>
                    </tr>
                    <tr v-if="!filteredPersonnels.length">
                        <td colspan="42" class="text-muted">Aucun élément</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script setup>
import Moment from 'moment';
import Translator from "@/App/Translator";
import Duree from "@/Vue/Filters/Duree";
import _ from "lodash";
import Cookies from "js-cookie";
import {mapState, storeToRefs} from "pinia";
import {useRegulationStore} from "@/Vue/Stores/Regulation";
import {onMounted, ref, computed, inject, watch} from "vue";
import Router from "@/App/Router";
import Request from "@/App/Request";

const store = useRegulationStore();
const {rendezVous, rendezVousInput, salaries, personnels, equipages} = storeToRefs(store);

const onRessourceDragStart = inject('onRessourceDragStart');
const onRessourceDragEnd = inject('onRessourceDragEnd');
const getMessageItem = inject('getMessageItem');

const filter = ref(Cookies.getJSON('ambuerp_regulation_salaries_filter') || {
    code: '',
    group: 'travail',
    termine: false,
});
const sort = ref(Cookies.getJSON('ambuerp_regulation_salaries_sort') || {
    column: 'code',
    direction: 'asc',
});
const tempsColumn = ref('etth'); // tte

const sorts = {
    code: ['shortString'],
    type: ['typeCourant.code'],
    debut: ['typeCourant.debutReel.time', 'typeCourant.debutPrevu.time'],
    fin: ['typeCourant.finReel.time', 'typeCourant.finPrevu.time'],
    pause: ['typeCourant.pauseCollection.length'],
    etth: ['_ecartTempsTravail'],
    tteh: ['recapitulatif.tempsTravailEffectif'],
};

const groupChoices = {
    travail: {label: 'Travail'},
    autre: {label: 'Autre'},
    'non-renseigne': {label: '<i class="fa-solid fa-question"></i>', title: 'Non renseigné'},
};


const personnelsById = computed(() => personnels.value.reduce((res, item) => (res[item.id] = item, res), {}));
const lieux = computed(() => salaries.value.lieux || []);
const modeles = computed(() => salaries.value.modeles || []);

const filteredPersonnels = computed(() =>  {
    const sortFields = sorts[sort.value.column] || ['code'];

    return _.orderBy(personnels.value.filter((personnel) => {
        if (filter.value.code !== '' && !personnel.shortString.toLowerCase().includes(filter.value.code.toLowerCase())) {
            return false;
        }

        const group = personnel.typeCourant ? (personnel.typeCourant.estTravailAbstract ? 'travail' : 'autre') : 'non-renseigne';

        if (filter.value.group && group !== filter.value.group) {
            return false;
        }

        const termine = personnel.typeCourant && personnel.typeCourant.debutReel && personnel.typeCourant.finReel;

        if (termine && !filter.value.termine) {
            return false;
        }

        return true;
    }), sortFields, Array(sortFields.length).fill(sort.value.direction))
});

const recapitulatifTitle = (recapitulatif) => 'HS25% : '+(Duree(recapitulatif.heureSupplementaire25, 'hhmm')||'-')+'\nHS50% : '+(Duree(recapitulatif.heureSupplementaire50, 'hhmm')||'-');

const canEditInPlace = (personnel, field) => {
    const hasReel = personnel.typeCourant[field+'Reel'] !== null;

    if (hasReel && !personnel._roles.ROLE_REGULATION_TRAJET_SIDEBAR_SALARIES_EDIT_REEL) {
        return false;
    }

    return personnel._roles.ROLE_REGULATION_TRAJET_SIDEBAR_SALARIES_EDIT;
};

const personnelKey = (personnel) => {
    let key = personnel.id;
    if(personnel.typeCourant) {
        key += personnel.typeCourant.id;
    }

    return key;
};

const openTypeView = (id) => {
    App.Salaries.openTypeSwal(null, Router.generate('regulation.trajet.planning.type.view.ajax', {id}), false);
};

const openTypeForm = (url, structure, cb = null) => {
    App.Salaries.openTypeSwal(null, url, true, modeles.value, null, null, cb, structure);
};

watch(sort, () => {
    Cookies.set('ambuerp_regulation_salaries_sort', sort.value);
});

onMounted(() => {
    // context menu
    $.contextMenu({
        selector: '#salaries tr',
        build: ($trigger, event) => {
            const index = $trigger.index();
            const personnel = filteredPersonnels.value[index];

            if(!personnel) {
                return false;
            }

            const type = personnel.typeCourant;

            let items = {};

            let typeSubMenu = {typeReplace: {}, type: {}, pause: {}};
            for(let group in typeSubMenu) {
                for(let typeModele of modeles.value[group.replace('Replace', '')]) {
                    if(typeModele.structure === personnel.structure) {
                        typeSubMenu[group][typeModele.id] = {name: typeModele.libelle, icon: function(opt, $itemElement, itemKey, item){
                                $itemElement.html('<span style="vertical-align: middle;border-radius: 10px;background-color: #' + typeModele.couleur + ';height: 16px;width: 25px;margin-left: -20px;margin-right: 5px;display: inline-block;"></span> ' + item.name);
                            }, callback: () => {
                                if(group === 'pause') {
                                    openTypeForm(Router.generate('regulation.trajet.planning.type.edit.ajax', {id: type.id}), personnel.structure, () => {
                                        $('#data_pauseCollectionDropdown [data-modele="'+typeModele.id+'"]').click();
                                    });
                                } else {
                                    if(group === 'typeReplace') {
                                        openTypeForm(Router.generate('regulation.trajet.planning.type.replace.ajax', {id: type.id, modele: typeModele.id}), personnel.structure);
                                    } else {
                                        openTypeForm(Router.generate('regulation.trajet.planning.type.new.ajax', {modele: typeModele.id, personnel: personnel.id, date: rendezVous.format('YYYY-MM-DD')}), personnel.structure);
                                    }
                                }
                            }};
                    }
                }

                if(!Object.keys(typeSubMenu[group]).length) {
                    typeSubMenu[group].empty = {icon: 'fa-solid fa-circle-info', name: Translator.trans('libelle.aucun-element'), disabled: true};
                }
            }

            if($(event.target).hasClass('js-salaries-type-add')) {
                items = typeSubMenu.type;
            } else {
                items.personnel = {name: personnel.text, icon: 'fa-solid fa-user', disabled: true};
                items.sep1 = '';

                if(type) {
                    items.view = {
                        name: Translator.trans('action.voir'),
                        icon: 'fa-solid fa-eye',
                        callback: () => openTypeView(type.id)
                    };
                    if (personnel._roles.ROLE_REGULATION_TRAJET_SIDEBAR_SALARIES_EDIT) {
                        items.edit = {
                            name: Translator.trans('action.modifier'),
                            icon: 'fa-solid fa-pencil',
                            callback: () => openTypeForm(Router.generate('regulation.trajet.planning.type.edit.ajax', {id: type.id}), personnel.structure)
                        };

                        if (personnel._roles.ROLE_REGULATION_TRAJET_SIDEBAR_SALARIES_TYPE_REPLACE || type.pauseCollection !== null) {
                            items.sep2 = '';
                        }

                        if (personnel._roles.ROLE_REGULATION_TRAJET_SIDEBAR_SALARIES_TYPE_REPLACE) {
                            items.replace = {
                                name: 'Remplacer',
                                icon: 'fa-solid fa-right-left',
                                items: typeSubMenu.typeReplace
                            };
                        }


                        if (type.pauseCollection !== null) {
                            items.pause = {
                                name: 'Ajouter une pause',
                                icon: 'fa-solid fa-mug-saucer',
                                items: typeSubMenu.pause
                            };
                        }
                    }

                    if (personnel.appareilCollection.length) {
                        items.sep4 = '';

                        items.envoyerMessage = getMessageItem([personnel]);

                        if (personnel.typeCourant && personnel.typeCourant.amplitude) {
                            items.envoyerPause = {
                                name: Translator.trans('action.envoyer-pause'),
                                icon: 'fa-solid fa-pause',
                                callback: () => App.Utils.openVueSwal('regulation-envoi-pause', {
                                    modeles: modeles.value.pause,
                                    lieux: lieux.value,
                                    personnel1: personnel,
                                })
                            };
                            items.envoyerFinService = {name: Translator.trans('action.envoyer-fin-service'), disabled: personnel.typeCourant.amplitude.envoyerFinServiceTopFinTlaTrajet, icon: 'fa-solid fa-stop', callback: () =>
                                    App.Utils.openVueSwal('regulation-envoi-fin-service', {
                                        personnel1: personnel
                                    })
                            };
                        }
                    }
                } else {
                    if(personnel._roles.ROLE_REGULATION_TRAJET_SIDEBAR_SALARIES_TYPE_NEW) {
                        items.add = {name: 'Ajouter', icon: 'fa-solid fa-plus', items: typeSubMenu.type};
                    }
                }

                if(personnel._roles.ROLE_REGULATION_TRAJET_SIDEBAR_SALARIES_VALIDER_PRISE_PLANNING) {
                    items.sep3 = '';

                    items.valider = {name: '<span title="Valide la prise de poste des salariés jusqu\'au '+rendezVousInput.value+'">Valider prise de poste</span>', isHtmlName: true, icon: 'fa-solid fa-check', callback: () => {
                        Request.getJson(Router.generate('regulation.trajet.planning.valider.ajax')).then((data) => {
                            if(data.success) {
                                App.Notification.success('Le planning a bien été validé.');
                            }
                        });
                    }};
                }
            }

            return {
                items: items
            };
        }
    });
});

</script>
