<template>
    <form-input :outlined="outlined" :is-invalid="isInvalid" v-mask="mask" :value="value" @input="onInput" @change="$emit('change', $event)" @reset="$emit('reset', $event)" ref="input"></form-input>
</template>

<script>
    import moment from 'moment';
    import AirDatepicker from "air-datepicker";
    import AirDatepickerLocaleFr from "air-datepicker/locale/fr";

    export default {
        props: {'value': String, 'picker': [Boolean, Object], 'isInvalid': Boolean, 'outlined': Boolean, 'lunaire': Boolean},
        mounted() {
          if(this.picker) {
              const todayButton = this.picker === true ? true : this.picker.todayButton;

              const datePicker = new AirDatepicker(this.$el, {
                  locale: AirDatepickerLocaleFr,
                  toggleSelected: false,
                  autoClose: true,
                  keyboardNav: false,
                  buttons: todayButton ? [
                      {
                          content: dp => dp.locale.today,
                          onClick: dp => dp.selectDate(new Date()),
                      }
                  ] : false,
                  onShow: (isAnimationComplete)=> {
                      if (!isAnimationComplete && datePicker.$el.value) {
                          datePicker.opts.autoClose = false;
                          const date = moment(this.value, 'DD/MM/YYYY').toDate();
                          datePicker.selectDate(date, {silent: true, updateTime: true});
                          datePicker.setViewDate(date);
                          datePicker.opts.autoClose = true;
                      }
                  },
                  onRenderCell: ({date, cellType})=> {
                      const disabledDays = this.picker.disabledDays || [];

                      if (disabledDays.length && cellType === 'day') {
                          return {disabled: disabledDays.includes(date.getDay())};
                      }
                  },
                  onSelect: ({formattedDate}) => {
                      if (formattedDate) {
                          this.$emit('input', formattedDate);
                          this.$emit('change', formattedDate);
                      }
                  }
              });
          }
        },
        computed: {
            mask() {
                if (this.lunaire) {
                    return {mask: '99/99/9999'};
                }

                return {mask: 'custom-datetime', format: 'dd/mm/yyyy'};
            }
        },
        methods: {
            onInput($event) {
                const fixedValue = this.value === undefined ? '' : this.value;

                if($event !== fixedValue) {
                    this.$emit('input', $event);
                }
            }
        }
    }
</script>
