<template>
    <div>
        <component :is-invalid="isInvalid" :is="component" v-bind="props" :value="value" @resets="$emit('resets', $event)" @reset="$emit('reset', $event)" @input="$emit('input', $event)" @change="$emit('change', $event)" @changes="$emit('changes', $event)"></component>
        <form-validation :items="validation"></form-validation>
    </div>
</template>

<script setup>
import {computed, toRefs} from "vue";

const props2 = defineProps({
    value: null,
    component: String,
    validation: Array,
    props: Object,
});

const {value, component, validation, props} = toRefs(props2);

const isInvalid = computed(() => {
    for (let field in validation.value) {
        if('error' === validation.value[field].type) {
            return true;
        }
    }

    return false;
});
</script>
