<template>
    <div>
        <template v-if="newLot">
            <AnimationSuccess></AnimationSuccess>
            <div class="row">
                <div class="col-6 mx-auto">
                    <div class="alert alert-success text-center">
                        <template v-if="newLot.estEnvoye">Lot créé et envoyé.</template>
                        <template v-else>Lot créé.</template>
                    </div>
                    <tableau-de-bord-widget-lot-title :lot="newLot.lotAbstract"></tableau-de-bord-widget-lot-title>
                    <tableau-de-bord-widget-scor-lot-item :lot="newLot"></tableau-de-bord-widget-scor-lot-item>
                </div>
            </div>
            <div class="mt-5 text-center">
                <b-btn :href="path('facturation.lot.scor.view', {id: newLot.id})" variant="info" target="_blank" :disabled="loading">
                    <i class="fa-solid fa-eye mr-1"></i> {{ Translator.trans('action.voir') }}
                </b-btn>
                <b-btn variant="secondary" :disabled="loading" @click="printLot(newLot)" v-if="newLot.etatEnvoiPapier === false">
                    <i class="fa-solid fa-print"></i>
                    {{ Translator.trans('action.imprimer-bordereau') }}
                </b-btn>
                <b-btn variant="secondary" class="js-close-swal" :disabled="loading">{{ Translator.trans('action.fermer') }}</b-btn>
            </div>
        </template>
        <template v-else>
            <div class="row">
                <div class="col-5 text-left">
                    <h4 class="box-lot-titre m-0 mb-3 text-ellipsis" :title="lot.caisse.libelle">
                        <span class="badge badge-dark mr-3">{{ lot.numero }}</span>
                        {{ lot.caisse.libelle }}
                    </h4>
                    <div>
                        <span v-if="type === 'suggestion'" class="badge badge-info"><i class="fa-regular fa-lightbulb mr-1"></i> Suggestion</span>
                        <span class="badge badge-secondary" :title="lot.structure.libelleComplet">{{ lot.structure.libelle }}</span>
                    </div>
                </div>
                <div class="col-7">
                    <div class="row">
                        <div class="col-4">
                            <div class="box-lot-facture-nombre">
                                {{ factures.length }}<small v-if="factures.length !== lot.standardCollection.length"> / {{ lot.standardCollection.length }}</small>
                            </div>
                            <div class="box-lot-facture-libelle text-muted">{{ Translator.trans('libelle.factures') }}</div>
                        </div>
                        <div class="col-3">
                            <div class="box-lot-facture-nombre">
                                {{ selectedPieceCount }}<small v-if="selectedPieceCount !== totalPieceCount"> / {{ totalPieceCount }}</small>
                            </div>
                            <div class="box-lot-facture-libelle text-muted">{{ Translator.trans('libelle.pieces') }}</div>
                        </div>
                        <div class="col-5">
                            <div class="box-lot-facture-nombre">
                                <div class="position-relative">
                                    <b-progress :max="lotMaxFilesize" height="26px" variant="secondary" class="bg-light">
                                        <b-progress-bar :value="selectedPieceFilesize"></b-progress-bar>
                                    </b-progress>
                                    <div class="position-absolute inset-0 d-flex justify-content-center align-items-center">
                                        <span class="font-size-16px"><span class="text-normal">{{ Filesize(selectedPieceFilesize) }}</span><small> / {{ Filesize(lotMaxFilesize) }}</small></span>
                                    </div>
                                </div>
                            </div>
                            <div class="box-lot-facture-libelle text-muted">{{ Translator.trans('libelle.taille-lot') }}</div>
                        </div>

                    </div>
                </div>
            </div>
            <b-table class="mt-4 table-compact" ref="factureTable" responsive no-sort-reset show-empty bordered striped :items="visibleFactures" :fields="fields" :per-page="perPage" :current-page="currentPage" sort-by="numero" primary-key="id">
                <template #empty="scope">
                    <span class="text-muted">{{ Translator.trans('libelle.aucun-element') }}</span>
                </template>
                <template #cell(patient)="{item}">
                    {{ item.patient.text }}
                </template>
                <template #head(checkbox)="scope">
                    <div class="checkbox">
                        <input type="checkbox" id="facture_checkbox_all" class="form-check-input" :checked="factures.length" @input="toggleAll" v-indeterminate="factures.length && factures.length !== checkableFactures.length" :disabled="!checkableFactures.length">
                        <label for="facture_checkbox_all"></label>
                    </div>
                </template>
                <template #cell(checkbox)="{item}">
                    <div class="checkbox">
                        <input type="checkbox" :id="'facture_checkbox_'+item.id" class="form-check-input" v-model="factures" :value="item" :disabled="!isCheckable(item)">
                        <label :for="'facture_checkbox_'+item.id"></label>
                    </div>
                </template>
                <template #cell(partCaisseMontant)="{item}">
                    {{ Euro(item.partCaisseMontant) }}
                </template>
                <template #cell(pieces)="{item}">
                    <template v-if="item.pieceScorPanierCollection.length">
                        <b-badge :id="'piecePopover'+item.id" :variant="item.pretEnvoiScor ? 'success' : 'info'">
                            <i class="im-files-empty mr-1"></i> {{ item.pieceScorPanierCollection.length }} <small>({{ Filesize(item.pieceScorPanierFilesize) }})</small>
                        </b-badge>
                        <Popover :target="'piecePopover'+item.id" placement="top" trigger="hover" class="piece-scor-popover">
                            <template v-slot:title>
                                <div class="text-center">Pièces à envoyer</div>
                            </template>
                            <piece-scor :pieces-scor="item.pieceScorPanierCollection" :form="true" @remove="removePieceScor($event, item)" @open="openPiece"></piece-scor>
                        </Popover>
                        <b-badge :href="loading ? null : '#'" :class="{'opacity-50': loading}" @click.prevent="marquerPretEnvoi(item)" variant="success" :disabled="loading" v-if="!item.pretEnvoiScor" title="Marquer comme prêt pour envoi SCOR">
                            <i class="fa-solid fa-check"></i>
                        </b-badge>
                    </template>
                    <span class="badge badge-secondary opacity-50" v-else><i class="im-files-empty"></i> 0</span>
                </template>
                <template #cell(etatScor)="{value, item}">
                    <span class="badge badge-dark" v-if="value === -1">Ne pas transmettre</span>
                    <span class="badge badge-warning" v-else-if="value === 0 && !item.pretEnvoiScor">À préparer</span>
                    <span class="badge badge-info" v-else-if="item.pretEnvoiScor" :title="'Prêt pour ' + (value === 1 ? 'nouvel' : '') + ' envoi'">Prêt pour envoi</span>
                    <span class="badge badge-success" v-else-if="value === 1">Transmis</span>
                </template>
                <template #cell(action)="{item}">
                    <span class="text-nowrap">
                        <a :title="Translator.trans('action.voir')" class="btn btn-table btn-info" target="_blank" :href="path('facturation.facture.view', {id: item.id})">
                            <i class="fa-solid fa-fw fa-eye"></i>
                        </a>
                    </span>
                </template>
            </b-table>
            <table-pagination :count="lot.standardCollection.length" :per-page="perPage" v-model="currentPage"></table-pagination>
            <div class="mt-4 row">
                <div class="col-4">
                    <div class="checkbox mt-2">
                        <input type="checkbox" id="envoyer" class="form-check-input" v-model="envoyer" :disabled="loading">
                        <label for="envoyer">{{ Translator.trans('libelle.envoyer-automatiquement') }}</label>
                    </div>
                </div>
                <div class="col-5 text-center">
                    <b-btn variant="success" @click="creerLot" :disabled="loading || !factures.length" v-if="carteCpsDefautOnline">
                        <i class="fa-solid fa-plus mr-1"></i> {{ Translator.trans('action.creer-lot') }}
                    </b-btn>
                    <b-btn variant="warning" disabled v-else>
                        <i class="fa-solid fa-triangle-exclamation mr-1"></i> Carte non connectée
                    </b-btn>
                    <b-btn variant="secondary" class="js-close-swal" :disabled="loading">{{ Translator.trans('action.fermer') }}</b-btn>
                </div>
                <div class="col-3"></div>
            </div>
            <PieceViewer @update="handlePieceUpdate" :types="pieceViewer.types" :type-presets="pieceViewer.typePresets" :piece="previewPiece" :piece-stack="previewStack" @piece="openPiece($event, previewStack)"></PieceViewer>
        </template>
    </div>
</template>

<script setup>
import swal from "sweetalert2";
import Translator from "@/App/Translator";
import Euro from "@/Vue/Filters/Euro";
import Filesize from "@/Vue/Filters/Filesize";
import {onKeyStroke} from "@vueuse/core";
import Router from "@/App/Router";
import Request from "@/App/Request";
import {computed, ref, toRefs, watch, onMounted} from "vue";
import Popover from '@/Vue/Components/Popover';
import PieceViewer from '@/Vue/Components/Piece/Viewer';
import AnimationSuccess from '@/Vue/Components/Animation/Success';

const props = defineProps(['lot', 'type', 'pieceViewer', 'lotMaxFilesize']);
const {lot, type, pieceViewer, lotMaxFilesize} = toRefs(props)
const emit = defineEmits(['refresh']);

const perPage = ref(15);
const currentPage = ref(1);
const fields = [
    {key: 'checkbox'},
    {key: 'numero', label: Translator.trans('libelle.numero'), sortable: true},
    {key: 'date', label: Translator.trans('libelle.date'), sortable: true},
    {key: 'patient', label: Translator.trans('libelle.patient'), sortable: true},
    {key: 'partCaisseMontant', label: Translator.trans('libelle.montant-caisse-abreviation'), sortable: true},
    {key: 'etatScor', label: Translator.trans('libelle.etat'), sortable: true},
    {key: 'pieces', label: Translator.trans('libelle.pieces'), sortable: true},
    {key: 'action', label: Translator.trans('libelle.actions')},
];
const envoyer = ref(true);
const loading = ref(false);
const factures = ref([]);
const newLot = ref(null);
const canExportNewLot = ref(null);
const previewPiece = ref(null);
const previewStack = ref([]);
const factureTable = ref();

const vIndeterminate = (el, binding) => {
    el.indeterminate = Boolean(binding.value);
}

const carteCpsDefautOnline = computed(() => {
    return true; // this.carteCpsDefaut && this.carteCpsDefaut.info && this.carteCpsDefaut.info.clientData;
});
const totalPieceCount = computed(() => lot.value.standardCollection.reduce((res, item) => (res += item.pieceScorPanierCollection.length, res), 0));
const selectedPieceCount = computed(() => factures.value.reduce((res, item) => (res += item.pieceScorPanierCollection.length, res), 0));
const selectedPieceFilesize = computed(() => factures.value.reduce((res, item) => (res += item.pieceScorPanierFilesize, res), 0));
const checkableFactures = computed(() => visibleFactures.value.filter(facture => isCheckable(facture)));
const visibleFactures = computed(() => lot.value.standardCollection);

const marquerPretEnvoi = (facture) => {
    loading.value = true;

    Request.postJson(Router.generate('facturation.tableau-de-bord.scor.facture.pret', {id: facture.id}), {}).then((data) => {
        if(data.success) {
            emit('refresh');
        }
    });
};

const removePieceScor = (pieceScor, facture) => {
    loading.value = true;

    Request.postJson(Router.generate('facturation.tableau-de-bord.scor.piece.delete', {id: pieceScor.id}), {}).then((data) => {
        if(data.success) {
            emit('refresh');
        }
    });
};

const openPiece = (piece, stack = []) => {
    previewPiece.value = piece;
    previewStack.value = piece ? stack : [];
};

const handlePieceUpdate = () => {
    emit('refresh');
};

const isCheckable = (item) => {
    return !loading.value && item.pretEnvoiScor && (factures.value.includes(item) || lotMaxFilesize.value > selectedPieceFilesize.value + item.pieceScorPanierFilesize);
};

const toggleAll = () => {
    if (factures.value.length) {
        factures.value = []
    } else {
        factures.value = []

        for(const facture of factureTable.value.sortedItems) {
            if(isCheckable(facture)) {
                factures.value.push(facture);
            }
        }
    }
};

const creerLot = () => {
    loading.value = true;

    Request.postJson(Router.generate('facturation.tableau-de-bord.scor.creer-lot'), {
        lot_scor: {
            lotAbstract: lot.value.id,
            factureCollection: factures.value.map((item) => item.id),
            envoyer: envoyer.value,
        },
    }).then((data) => {
        emit('refresh');

        if(data.success) {
            loading.value = false;

            // TODO
            newLot.value = data.lotScor;
        } else {
            swal.close();
        }
    });
};

const printLot = (lot) => {
    loading.value = true;

    App.Utils.print(Request.generate('facturation.lot.scor.export', {id: lot.id, type: 'html'}), lot.bordereauFileName).then(() => {
        loading.value = false;
    });
};

watch(lot, () => {
    loading.value = false;
});

onKeyStroke('Escape', (e) => {
    if(!previewPiece.value) {
        swal.clickCancel();
    }
});

onMounted(() => {
    toggleAll();
});

defineExpose({lot});
</script>
