<template>
    <span v-if="trajet._loading" class="badge badge-secondary" title="Chargement…">
        <i class="fa-solid fa-fw fa-arrows-rotate fa-spin"></i>
    </span>
    <a href="#" v-else-if="trajet._error" class="badge badge-danger" @click="emit('click', $event)" title="Erreur lors de la mise à jour du trajet, cliquez pour réessayer.">
        <i class="fa-solid fa-fw fa-triangle-exclamation"></i>
    </a>
    <span v-else @click="emit('click', $event)" class="badge badge-secondary" :title="title" :class="{'badge-danger': -2 === trajet._etatValide, 'badge-warning': -1 === trajet._etatValide, 'badge-info': 1 === trajet._etatValide, 'badge-success': 2 === trajet._etatValide}">
        <i class="fa-solid fa-fw fa-check"></i>
    </span>
</template>

<script setup>
import {computed, toRefs} from "vue";

const emit = defineEmits(['click']);
const props = defineProps({
    trajet: Object,
});
const {trajet} = toRefs(props);

const title = computed(() => {
    switch (trajet.value._etatValide) {
        case -2: return 'Comporte des erreurs';
        case -1: return 'Comporte des alertes';
        case 1: return 'Comporte des informations';
        case 2: return 'Trajet valide';
    }
});
</script>
