<template>
    <slot></slot>
</template>

<script setup>
import {computed, getCurrentInstance, inject, onMounted, onUnmounted, ref, toRefs, watch} from "vue";
import circle from '@turf/circle';
import bbox from '@turf/bbox';
import {LngLatBounds} from "maplibre-gl";

const map = inject('map')

const props = defineProps({
    id: String,
    radius: Number,
    lngLat: [Array, Object],
    autoFit: Boolean,
    fitOptions: Object,
})

const {lngLat, radius, autoFit, fitOptions} = toRefs(props);
const lngLatParent = inject('lngLat', null)

const lngLatComputed = computed(() => lngLat.value ?? lngLatParent.value);
const baseId = 'ambuerp_'+'radius-'+getCurrentInstance().uid; // same partout ?
const fadeDuration = 150;

const fit = () => {
    map.value.fitBounds(bounds.value, {
        padding: 50,
        ...fitOptions.value
    })
}

const mapPadding = inject('mapPadding');

watch (mapPadding, () => {
    if (autoFit.value) {
        fit();
    }
});

const data = computed(() => circle(lngLatComputed.value, radius.value, {
    steps: 250,
    units: 'meters',
}));
const bounds = computed(() => bbox(data.value));

onMounted(() => {
    map.value.addSource(baseId, {
        type: 'geojson',
        data: data.value,
    })

    map.value.addLayer({
        id: baseId,
        type: "fill",
        source: baseId,
        paint: {
            "fill-color": "#8CCFFF",
            "fill-opacity": 0,
        },
    });

    map.value.addLayer({
        id: baseId+'-outline',
        type: "line",
        source: baseId,
        paint: {
            "line-color": "#0094ff",
            "line-width": 3,
            "line-opacity": 0,
        },
    });

    map.value.setPaintProperty(baseId, 'fill-opacity', 0.5, {
        duration: fadeDuration,
    });
    map.value.setPaintProperty(baseId+'-outline', 'line-opacity', 1, {
        duration: fadeDuration,
    });
});

watch(data, () => {
    map.value.getSource(baseId)?.setData(data.value);
});

watch(bounds, () => {
    if (autoFit.value) {
        fit();
    }
}, {immediate: true});


onUnmounted(() => {
    if (map.value.getLayer(baseId)) {
        map.value.removeLayer(baseId);
    }
    if (map.value.getLayer(baseId+'-outline')) {
        map.value.removeLayer(baseId+'-outline');
    }
});


</script>