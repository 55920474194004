const options = {
    emulateJSON: true,
    before(request) {
        if (this.previousRequest) {
            this.previousRequest.abort();
        }

        this.previousRequest = request;
    }
};

export default {
    install: (Vue) => {
        Vue.mixin({
            methods: {
                postJsonRequest(url, data) {
                    return new Promise((resolve, reject) => {
                        this.$http.post(url, data, options).then((response) => {
                            response.json().then((data) => {
                                resolve(data);
                            });
                        }, () => {});
                    });
                },
                getJsonRequest(url) {
                    return new Promise((resolve, reject) => {
                        this.$http.get(url, options).then((response) => {
                            response.json().then((data) => {
                                resolve(data);
                            });
                        }, () => {});
                    });
                },
            }
        })

    }
};
