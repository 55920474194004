<template>
    <div class="btn-group btn-group-toggle d-flex align-items-center overflow-x-auto">
        <slot>
            <form-btn-group-item
                    v-for="(choice, key) in choices" :key="getChoiceKey(choice, key)"
                    :itemValue="getChoiceKey(choice, key)"
                    :type="type"
                    :value="'fields' === type ? (value[getChoiceKey(choice, key)] || false) : value"
                    :title="getChoiceTitle(choice)"
                    :disabled="isChoiceDisabled(choice)"
                    :allowClear="allowClear"
                    :outlined="outlined"
                    @input="onChangeInput('input', choice, key, $event)"
                    @change="onChangeInput('change', choice, key, $event)"
                    @reset="onReset(getChoiceKey(choice, key))"
            >
                <slot name="item" :choice="choice" :key="key">
                    <span v-html="getChoiceLabel(choice)" v-if="htmlLabels"></span>
                    <template v-else>{{ getChoiceLabel(choice) }}</template>
                </slot>
            </form-btn-group-item>
        </slot>
    </div>
</template>

<script setup>
import {toRefs} from "vue";

const props = defineProps(['value', 'choices', 'type', 'htmlLabels', 'allowClear', 'outlined', 'disabled']);
const {value, choices, type, htmlLabels, allowClear, outlined, disabled} = toRefs(props);

const emit = defineEmits(['input', 'change', 'reset']);

const onChangeInput = (event, choice, key, value2) => {
    if (null !== value.value && typeof value.value === 'object' && !Array.isArray(value.value)) {
        emit(event, {...value.value, [getChoiceKey(choice, key)]: value2})
    } else {
        emit(event, value2);
    }
};

const onReset = (field) => {
    emit('reset', field);
};

const getChoiceKey = (choice, key) => {
    return typeof choice === 'object' && choice.key !== undefined ? choice.key : key;
};

const getChoiceLabel = (choice) => {
    return typeof choice === 'object' ? choice.label : choice;
};

const getChoiceTitle = (choice) => {
    return typeof choice === 'object' ? choice.title : '';
};

const isChoiceDisabled = (choice) => {
    return disabled.value || (typeof choice === 'object' ? choice.disabled : false);
};
</script>
