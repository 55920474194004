<template>
    <select :class="selectClass">
        <slot>
            <template v-for="label,value in choices">
                <optgroup v-if="typeof label === 'object'" :label="value">
                    <option v-for="label2,value2 in label" :key="value2" :value="value2">{{ label2 }}</option>
                </optgroup>
                <option v-else :key="value" :value="value">{{ label }}</option>
            </template>
        </slot>
    </select>
</template>

<script>
    import addRemovedHook from "vue-removed-hook-mixin";

    export default {
        props: ['value', 'choices', 'placeholder', 'isInvalid', 'outlined', 'required'],
        mixins: [addRemovedHook],
        data() {
            return {
                initialClass: '',
            };
        },
        mounted: function () {
            $(this.$el)
                .select2({
                    language: App.Constants.LOCALE_SHORT,
                    placeholder: this.placeholder || '…',
                    minimumResultsForSearch: 8,
                    allowClear: !$(this.$el).is('[multiple]') && !this.required,
                })
                .on('change', (e) => {
                    const value = $(e.currentTarget).val();

                    this.$emit('input', value)
                    this.$emit('change', value)
                    if(!value) {
                        this.$emit('reset');
                    }
                }).val(this.value).trigger('change.select2')
            ;
            this.initialClass = this.$el.className || '';
        },
        computed: {
            selectClass() {
                return {[this.initialClass]:true, 'is-invalid js-vue-validation': this.isInvalid, 'border-primary': this.outlined};
            }
        },
        watch: {
            value: function (value) {
                $(this.$el).val(value).trigger('change.select2');
            },
        },
        removed: function () {
            $(this.$el).off().select2('destroy')
        },
    }
</script>
