<template>
    <form-field v-if="'form' === mode" :component="component" :validation="validation" :props="{...field.formProps, ...field.props, ...props}"  :value="value" @resets="emit('reset', $event.map((item) => {return {field: item}}))" @reset="emit('reset', [{field: field.name, subField: $event}])" @input="emit('input', {[field.name]: $event})" @change="emit('change', {[field.name]: $event})" @changes="emit('change', $event)"></form-field>
    <span v-else>
        <component :is="component" v-bind="{...field.viewProps, ...field.props, ...props}" :value="value"></component>
    </span>
</template>

<script setup>
import {computed, toRefs} from "vue";

const emit = defineEmits(['reset', 'input', 'change']);

const props2 = defineProps({
    value: null,
    mode: String,
    field: Object,
    validation: Array,
    props: Object,
});

const {value, mode, field, validation, props} = toRefs(props2);

const isInvalid = computed(() => {
    for (let field in validation.value) {
        if ('error' === validation.value[field].type) {
            return true;
        }
    }

    return false;
});

const defaultComponent = computed(() => {
    if ('form' === mode.value) {
        return 'input';
    }

    return 'text';
});

const component = computed(() => {
    if (field.value.component) {
        return field.value.component;
    }

    return mode.value+'-'+(field.value[mode.value+'Component'] || defaultComponent.value);
});

</script>
