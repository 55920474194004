App.Sefi = class {
    static openRequestSwal(service, action, data = null, response = null) {
        const component = 'sefi-'+service+'-'+action;
        const hasData = data !== null;
        const hasResponse = response !== null;

        let vueData = {}

        if(hasData) {
            vueData.data = data;
        }

        if(hasResponse) {
            vueData.response = response;
        }

        App.Utils.openVueSwal(component, vueData);
    }
}
