<template>
    <div class="flex items-start" :class="{'drop-shadow-sm': focus}">
        <Pin
            chat
            hover
            :variant="message.estEmetteurRegulation ? (focus ? 'indigo-100' : 'indigo-50') : (message.estEmetteurSysteme ? (focus ? 'orange-100': 'orange-50') : (focus ? 'gray-200': 'gray-100'))"
            @click="onClick"
            :class="message.estEmetteurRegulation || message.estEmetteurSysteme ? 'marker-pin-chat-right order-last' : 'marker-pin-chat-left'"
            v-element-hover="onHover"
        >
            <template #content>
                <Text v-if="message.estEmetteurRegulation || message.estEmetteurSysteme" :value="message.createdBy ? message.createdBy.text.split(' ').map(mot => mot.substring(0, 1)).join('') : 'Reg'"></Text>
                <Text v-else-if="ressourceEmetteur" class="!bg-clip-text text-transparent" :style="{'background': ressourceEmetteur.regulation.couleur}" :value="ressourceEmetteur.regulation.shortString"></Text>
                <Text v-else-if="message.personnelEmetteur" :value="message.personnelEmetteur.shortText"></Text>
                <Text v-else-if="message.vehiculeEmetteur" :value="message.vehiculeEmetteur.shortText"></Text>
                <Text v-else-if="message.appareilEmetteur" :value="message.appareilEmetteur.text.split(' ').map(mot => mot.substring(0, 1)).join('')"></Text>
            </template>
            <template #contentHover>
                <i v-if="message.estEmetteurRegulation || message.estEmetteurSysteme" class="fa-solid fa-user"></i>
                <i v-else-if="ressourceEmetteur" :class="[ressourceEmetteur.regulation.categorie.classeIcone, ressourceEmetteur.regulation.categorie.classeCouleur]" :title="ressourceEmetteur.regulation.categorie.title"></i>
                <i v-else-if="message.personnelEmetteur" class="fa-solid fa-user"></i>
                <i v-else-if="message.vehiculeEmetteur" class="fa-solid fa-car"></i>
                <i v-else-if="message.appareilEmetteur" class="fa-solid fa-mobile"></i>
            </template>
        </Pin>
        <div v-element-hover="onHover" class="flex flex-col px-2.5 pt-1.5 pb-1 rounded-b-xl dark:bg-gray-700 max-w-[calc(100%_-_34px_*_2)] transition-colors"
             :class="{'ml-auto rounded-l-xl': message.estEmetteurRegulation || message.estEmetteurSysteme, 'bg-indigo-50': !focus && message.estEmetteurRegulation, 'bg-indigo-100': focus && message.estEmetteurRegulation, 'bg-orange-50': !focus && message.estEmetteurSysteme, 'bg-orange-100': focus && message.estEmetteurSysteme, 'bg-gray-100 rounded-r-xl': !focus && (message.personnelEmetteur || message.vehiculeEmetteur || message.appareilEmetteur), 'bg-gray-200 rounded-r-xl': focus && (message.personnelEmetteur || message.vehiculeEmetteur || message.appareilEmetteur)}"
        >
            <p class="text-sm font-normal text-gray-900 dark:text-white ">{{ message.message }}</p>
            <div class="flex items-center justify-between gap-x-2 text-xs text-gray-500 dark:text-gray-400">
                <div v-if="(message.traiteBy && message.traiteAt) || (message.estEmetteurRegulation || message.estEmetteurSysteme)">
                    <i class="fa-solid fa-square-check text-green-500" v-if="message.traiteBy && message.traiteAt" :title="'Traité par ' + message.traiteBy.longText + ' le '+ message.traiteAt"></i>
                    <MessageDestinataire v-if="message.estEmetteurRegulation || message.estEmetteurSysteme" :message="message"></MessageDestinataire>
                </div>
                <div>
                    <MessageStatus :message="message"></MessageStatus>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {toRefs, computed} from "vue";
import MessageStatus from "@/Vue/Components/MessageStatus";
import MessageDestinataire from "@/Vue/Components/MessageDestinataire";
import { vElementHover } from '@vueuse/components'

import Pin from "@/Vue/Components/RegulationV2/Layout/Pin";
import Text from '@/Vue/Components/RegulationV2/Map/Base/Pin/Text';
import {useGeolocalisationStore} from "@/Vue/Stores/RegulationV2/Geolocalisation";
import {storeToRefs} from "pinia";

const geolocalisationStore = useGeolocalisationStore();
const {ressourcesByIdByType, activeRessourcePopup} = storeToRefs(geolocalisationStore);

const emit = defineEmits(['hover']);

const getRessourceEmetteur = () => {
    if (message.value.vehiculeEmetteur) {
        return ressourcesByIdByType.value.vehicule[message.value.vehiculeEmetteur.id];
    }
}
const ressourceEmetteur = computed(() => getRessourceEmetteur());

const onClick = () => {
    if (ressourceEmetteur.value) {
        activeRessourcePopup.value = getRessourceEmetteur();
    }
}

const onHover = (hover) => {
    emit('hover', hover);
};

const props = defineProps({
    message: Object,
    focus: Boolean
});

const {message, focus} = toRefs(props);
</script>