<template>
    <button class="btn btn-secondary" :disabled="isToday" type="button" tabindex="-1" @click="setToday">
        <i class="fa-solid fa-calendar-days"></i>
    </button>
</template>


<script>
    import moment from 'moment';

    export default {
        props: ['value'],
        computed: {
            isToday() {
                return this.value === moment().format('DD/MM/YYYY');
            }
        },
        methods: {
            setToday() {
                this.$emit('input', moment().format('DD/MM/YYYY'));
            }
        }
    }
</script>