<template>
    <a v-if="type" href="#" @click="open" class="badge" :style="{background: '#'+type.couleur, color: '#'+type.couleurTexte}" :title="type.libelle">
        {{ type.code }}
    </a>
</template>

<script setup>
import Router from "@/App/Router";
import {toRefs} from "vue";

const props = defineProps({
    type: Object
});
const {type} = toRefs(props);

const open = () => App.Salaries.openTypeSwal(null, Router.generate('regulation.trajet.planning.type.view.ajax', {id: type.value.id}), false);
</script>