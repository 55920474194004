import { defineStore } from 'pinia'
import {ref, computed, nextTick, createApp, watch, onMounted, provide} from 'vue';
import Request from "@/App/Request";
import Router from "@/App/Router";
import _ from "lodash";
import Moment from "moment/moment";
import Euro from "@/Vue/Filters/Euro";
import swal from "sweetalert2";
import Translator from "@/App/Translator";
import randomColor from "randomcolor";
import Ladda from "ladda";
import Cookies from "js-cookie";
import Capitalize from "@/Vue/Filters/Capitalize";
import {useUrlSearchParams} from "@vueuse/core";
import sendMessageAudio from '@/../audio/send_message.wav';
import newMessageAudio from '@/../audio/message2.wav';

export const useRegulationStore = defineStore('regulation', () => {
    const advancedFields = {
        caracteristiques: [],
        patient: null,
        motif: null,
        equipage: null,
        personnel: null,
        vehicule: null,
    };
    const filterInit = Cookies.getJSON('ambuerp_regulation_trajet_filter') || {
        etat: ['estAFaire', 'estPret', 'estEnCours'],
        categorie: ['estAmbu', 'estVsl', 'estTaxi', 'tpmr', 'autre'],
        ...advancedFields,
    };
    let showAdvancedFiltersInit = false;
    for(let field in advancedFields) {
        if(filterInit[field] !== '' && filterInit[field] !== null && filterInit[field] !== undefined && filterInit[field].length !== 0) {
            showAdvancedFiltersInit = true;
            break;
        }
    }

    const sortInit = Cookies.getJSON('ambuerp_regulation_trajet_sort') || {
        column: 'rendezVous',
        direction: 'asc',
    };

    const sorts = {
        rendezVous: {
            field: 'rendezVous.time',
            query: 'a.rendezVous',
        },
        topDebut: {
            field: 'topDebut.time', // todo query, non géré pour le moment car implique de trier sur plusieurs champs
        },
        topDestinationArrivee: {
            field: 'topDestinationArrivee.time', // todo query, non géré pour le moment car implique de trier sur plusieurs champs
        },
        topPriseEnChargeArrivee: {
            field: 'topPriseEnChargeArrivee.time', // todo query, non géré pour le moment car implique de trier sur plusieurs champs
        },
        topFin: {
            field: 'topFin.time', // todo query, non géré pour le moment car implique de trier sur plusieurs champs
        },
        equipage: {
            field: '_equipage.shortString',
            query: 'e.code',
        },
        vehicule: {
            field: '_vehicule.shortString',
            query: 'v.code',
        },
        personnel1: {
            field: '_personnel1.shortString',
            query: 'p1.code',
        },
        personnel2: {
            field: '_personnel2.shortString',
            query: 'p2.code',
        },
        motif: {
            field: 'transport.motif.text',
            query: 'm.code',
        },
        patient: {
            field: 'transport.patient.text', // todo query, non géré pour le moment car implique de trier sur plusieurs champs
        },
        etat: {
            field: 'etat',
            query: 'a.etat',
        },
        villePriseEnCharge: {
            field: 'villePriseEnCharge',
            query: 'a.villePriseEnCharge',
        },
        villeDestination: {
            field: 'villeDestination',
            query: 'a.villeDestination',
        },
        simultane: {
            field: 'simultane.id',
            query: 'a.simultane',
        },
    };
    const tops = {
        TOP_DEBUT: 0,
        TOP_PRISE_EN_CHARGE_ARRIVEE: 1,
        TOP_DESTINATION_ARRIVEE: 3,
        TOP_FIN: 5,
    };

    const trajetTab = ref();
    const messagerieTab = ref();
    const ressourcesTab = ref();

    const urlParams = useUrlSearchParams('history');

    const sendMessageSound = ref(null);
    const newMessageSound = ref(null);

    const showAdvancedFilters = ref(showAdvancedFiltersInit);

    const filter =  ref(filterInit);

    const currentTrajetId = ref(null);

    const focusTrajetIndex = ref(null);
    const allTrajetCollection = ref([]);
    const structureCollection = ref([]);
    const structuresRacine = ref([]);
    const rendezVous = ref(null);
    const isLoading = ref(false);
    const simultane = ref(null);
    const simultaneLoading = ref(false);
    const simultaneTrajetIds = ref([]);
    const personnels = ref([]);
    const vehicules = ref([]);
    const equipages = ref([]);
    const activeTab = ref(0);
    const messagerie = ref({});
    const hasPendingMessage = ref(false);
    const salaries = ref({});
    const hasUnreadMessage = ref(false);
    const hasNewRessourceDispo = ref(false);
    const partsToRefresh = ref([]);
    const sort = ref(sortInit);
    const ajaxLocked = ref(false);

    const etatsRessources = {
        'disponible': {
            name: 'disponible',
            variant: 'success',
            section: 'disponible',
            title: 'Disponible',
        },
        'affecte': {
            name: 'affecte',
            variant: 'info',
            section: 'affecte',
            title: 'Affecté',
        },
        'pause': {
            name: 'pause',
            variant: 'warning',
            section: 'pause',
            title: 'En pause',
        },
        'fin-mission': {
            name: 'fin-mission',
            variant: 'warning',
            section: 'mission',
            title: 'En fin de mission',
        },
        'en-mission': {
            name: 'en-mission',
            variant: 'danger',
            section: 'mission',
            title: 'En mission',
        },
        'indisponible': {
            name: 'indisponible',
            variant: 'secondary',
            section: 'indisponible',
            title: 'Indisponible',
        },
    };

    const sectionsRessources = {
        'disponible': {
            label: 'Disponible',
            visible: true,
            orderBy: {'_trajetInfo.minTrajet._topFinReelBool': 'desc', '_trajetInfo.minTrajet._topFinReel': 'asc', '_trajetInfo.total': 'asc'},
        },
        'affecte': {
            label: 'Affecté',
            visible: true,
            orderBy: {'_trajetInfo.minTrajet._debutMissionPrevu': 'desc'},
        },
        'pause': {
            label: 'En pause',
            visible: true,
        },
        'mission': {
            label: 'En mission',
            visible: true,
            orderBy: {'etat': 'desc', '_trajetInfo.minTrajet.topCourant': 'desc', '_trajetInfo.minTrajet.topCourantValue': 'asc'},
        },
        'indisponible': {
            label: 'Indisponible',
            visible: false,
        }
    };

    const searchQueryRessources = ref('');
    const modeRessources = ref(Cookies.getJSON('ambuerp_regulation_ressource_mode') || 'vehicule');

    watch(modeRessources, () => {
        Cookies.set('ambuerp_regulation_ressource_mode', modeRessources.value);
    });

    const ressources = computed(() => {
        let items = [];
        let equipageVehiculeIds = [];
        let equipagePersonnelIds = [];

        if('mixte' === modeRessources.value) {
            for (const equipage of equipages.value) {
                items.push(equipage);

                if (equipage.vehicule) {
                    equipageVehiculeIds.push(equipage.vehicule);
                }
                // if (equipage.personnel1) {
                //     equipagePersonnelIds.push(equipage.personnel1);
                // }
                // if (equipage.personnel2) {
                //     equipagePersonnelIds.push(equipage.personnel2);
                // }
            }
        }
        else if('equipage' === modeRessources.value) {
            for (const equipage of equipages.value) {
                items.push(equipage);
            }
        }

        if('personnel' === modeRessources.value) {
            for (const personnel of personnels.value) {
                if (!equipagePersonnelIds.includes(personnel.id)) {
                    items.push(personnel);
                }
            }
        }
        if('mixte' === modeRessources.value || 'vehicule' === modeRessources.value) {
            for (const vehicule of vehicules.value) {
                if (!equipageVehiculeIds.includes(vehicule.id)) {
                    items.push(vehicule);
                }
            }
        }

        if(searchQueryRessources.value !== '') {
            items = items.filter((item) => {
                return item.shortString.toLowerCase().includes(searchQueryRessources.value.toLowerCase());
            });
        }

        return items;
    });

    const ressourcesBySection = computed(() => {
        const ressourcesBySection = ressources.value.reduce((res, item) => ((res[item._etat.section] = res[item._etat.section] || []).push(item), res), {});
        let sortedRessourceBySection = {};

        for(const section in sectionsRessources) {
            if (section in ressourcesBySection) {
                const orderBy = sectionsRessources[section].orderBy;
                if (orderBy) {
                    sortedRessourceBySection[section] = _.orderBy(ressourcesBySection[section], Object.keys(orderBy), Object.values(orderBy));
                } else {
                    sortedRessourceBySection[section] = ressourcesBySection[section];
                }
            }
        }

        return sortedRessourceBySection;
    });

    const countRessourcesByEtatBySection = computed(() => {
        return ressources.value.reduce((res, item) => {
            const section = item._etat.section;
            const etat = item._etat.name;

            if (!res[section]) {
                res[section] = {};
            }

            if (!res[section][etat]) {
                res[section][etat] = 0;
            }

            res[section][etat]++;

            return res;
        }, {});
    });

    const filterData = computed(() => {
        return {rendezVous: rendezVousInput.value, structureCollection: structureCollection.value};
    });

    const allTrajetCollectionByRessourceId = computed(() => {
        const fieldsByType = {
            personnel: ['personnel1', 'personnel2'],
            vehicule: ['vehicule'],
            equipage: ['equipage'],
        };

        return allTrajetCollection.value.reduce((res, trajet) => {
            for (const type in fieldsByType) {
                if (!res[type]) {
                    res[type] = {};
                }

                for (const field of fieldsByType[type]) {
                    if (null !== trajet[field]) {
                        if (!res[type][trajet[field]]) {
                            res[type][trajet[field]] = [];
                        }
                        if (!res[type][trajet[field]].includes(trajet)) {
                            res[type][trajet[field]].push(trajet);
                        }
                    }
                }
            }

            return res;
        }, {
            personnel: {},
            vehicule: {},
            equipage: {},
        });
    });

    const groupMessagesByRessource = (messages) => {
        const fieldsByType = {
            vehicule: ['vehiculeEmetteur', 'vehiculeDestinataire', 'vehiculeDestinataire2'],
            appareil: ['appareilEmetteur', 'appareilDestinataire', 'appareilDestinataire2'],
            personnel: ['personnelEmetteur', 'personnelDestinataire', 'personnelDestinataire2'],
        };

        return (messages).reduce((res, message) => {
            for (const type in fieldsByType) {
                for (const field of fieldsByType[type]) {
                    const id = message[field]?.id ?? null;

                    if (null !== id) {
                        if (!res[type][id]) {
                            res[type][id] = [];
                        }
                        if (!res[type][id].includes(message)) {
                            res[type][id].push(message);
                        }
                    }
                }
            }

            return res;
        }, {
            vehicule: {},
            appareil: {},
            personnel: {},
        });
    };

    const messages = computed(() => messagerie.value.messages ?? []);
    const messagesById = computed(() => messages.value.reduce((res, message) => (res[message.id] = message, res), {}));
    const messagesByRessourceId = computed(() => groupMessagesByRessource(messages.value));

    const trajetCollectionPretEnvoiTla = computed(() => {
        return trajetCollection.value.filter(trajet => trajet.roles.ROLE_REGULATION_TRAJET_TLA_SEND && trajet.canFirstSendTla && !trajet.estEnvoyeTla);
    });

    const patientIds = computed(() => {
        return trajetCollection.value.map(trajet => trajet.transport.patient.id).filter((v,i,a) => v && a.indexOf(v) === i).join(',');
    });

    const chartOptions = computed(() => {
        return {
            onClick: (e, chartElements) => {
                const chartElement = chartElements[0];

                if(chartElement) {
                    let time = Moment(e.chart.data.labels[chartElement.index], 'HH:mm');

                    trajetCollection.value.some((trajet, index) =>  {
                        if(Moment(trajet.rendezVous.time, 'HH:mm').isSameOrAfter(time)) {
                            selectTrajet(index, true);
                            return true;
                        }
                    });
                }
            },
            plugins: {
                tooltip: {
                    enabled: false,
                    external: function(context) {
                        const tooltipModel = context.tooltip;
                        let $tooltip = $('#chartjs-tooltip');

                        if(!$tooltip.length) {
                            $tooltip = $('<div class="chartjs-tooltip rounded-md" id="chartjs-tooltip"></div>');
                            $('body').append($tooltip);
                        }

                        if (tooltipModel.opacity === 0) {
                            $tooltip.css('opacity', 0);
                            return;
                        }

                        const position = context.chart.canvas.getBoundingClientRect();
                        const time = tooltipModel.title[0];
                        const index = tooltipModel.dataPoints[0].dataIndex;
                        const filterValue = context.chart.data.datasets[0].data[index];
                        const totalValue = context.chart.data.datasets[1].data[index] + filterValue;

                        $tooltip.html('<div><i class="fa-regular fa-clock"></i> ' + time + '</div><b>' + filterValue + '</b>'+(filterValue !== totalValue ? (' / ' + totalValue):'') + ' trajet' + (totalValue > 1 ? 's':''));
                        $tooltip.css('left', position.left + tooltipModel.caretX + 'px');
                        $tooltip.css('top', position.top + tooltipModel.caretY - 64 + 'px');
                        $tooltip.css('opacity', 1);
                    }
                },
            },
            layout: {
                padding: {
                    top: 4,
                    right: 10,
                    bottom: 4,
                }
            },
            scales: {
                x: {
                    grid: {display: false},
                    border: {display: false},
                    ticks: {
                        autoSkip: true, maxTicksLimit: 31, padding: -3, font: {size: 11},
                    },
                },
                y: {
                    grid: {display: false},
                    border: {display: false},
                    ticks: {display: false, stepSize: 1},
                }
            },
        };
    });

    const chartData = computed(() => {
        const time = Moment('1996-08-22 00:00:00');
        let chartData = [
            {
                name: 'filter',
                stack: 'stack',
                data: [],
            },
            {
                name: 'rest',
                stack: 'stack',
                data: [],
            }
        ];

        for (let i = 0; i < 24; ++i) {
            for (let j = 0; j < 4; ++j) {
                time.hours(i).minutes(j*15);

                const timeStr = time.format('HH:mm');
                const nextTimeStr = time.clone().add(15, 'minutes').format('HH:mm');

                let countFilter = 0;
                let countAll = 0;

                for (const trajet of allTrajetCollection.value) {
                    const firstTop = trajet.topDebut || trajet.topPriseEnChargeArrivee || trajet.topDestinationArrivee;
                    const lastTop = trajet.topFin || trajet.topDestinationArrivee || trajet.topPriseEnChargeArrivee;
                    if (lastTop.time >= timeStr && firstTop.time < nextTimeStr) {
                        ++countAll;
                        if(trajetCollection.value.includes(trajet)) {
                            ++countFilter;
                        }
                    }
                }

                chartData[0].data.push([timeStr, countFilter]);
                chartData[1].data.push([timeStr, countAll - countFilter]);
            }
        }

        time.hours(23).minutes(59);
        chartData[0].data.push([time.format('HH:mm'), 0]);
        chartData[1].data.push([time.format('HH:mm'), 0]);

        return chartData;
    });

    const estimationMontantTouteTaxeFiltre = computed(() => getEstimationTotale(trajetCollection.value));
    const estimationMontantTouteTaxeTotal = computed(() => getEstimationTotale(allTrajetCollection.value));
    const personnelsById = computed(() =>personnels.value.reduce((res, item) => (res[item.id] = item, res), {}));
    const vehiculesById = computed(() => vehicules.value.reduce((res, item) => (res[item.id] = item, res), {}));
    const equipagesById = computed(() => equipages.value.reduce((res, item) => (res[item.id] = item, res), {}));

    const rendezVousInput = computed({
        get: function() {
            return rendezVous.value ? rendezVous.value.format('DD/MM/YYYY') : null;
        },
        set: function(value) {
            let date = Moment(value, 'DD/MM/YYYY');
            rendezVous.value = date.isValid() ? date : null;
        }
    });
    const rendezVousIsToday = computed(() => rendezVousInput.value === Moment().format('DD/MM/YYYY'));
    const rendezVousDay = computed(() => Capitalize(Moment(rendezVous.value).format('ddd')));

    const trajetIndexById = computed(() => trajetCollection.value.reduce((res, trajet, index) => (res[trajet.id] = index, res), {}));
    const allTrajetIndexById = computed(() => allTrajetCollection.value.reduce((res, trajet, index) => (res[trajet.id] = index, res), {}));
    const trajetCollection = computed(() => {
        let tmp;

        return _.orderBy(allTrajetCollection.value.filter((trajet) => {
            if(trajet.estAnnule && !filter.value.etat.includes('estAnnule')) {
                return false;
            }

            let matchEtat = false;
            let matchCategorie = false;

            // Filtres (motif, équipage, personnel)
            for(let field in filter.value) {
                let value = filter.value[field];

                // Catégorie
                if('categorie' === field) {
                    let estAutre = true;

                    if(trajet.transport.estTpmr) {
                        estAutre = false;
                        matchCategorie = value.includes('tpmr');
                    } else {
                        for(let field in filterCategorieChoices.value) {
                            if(trajet.transport.categorie && trajet.transport.categorie[field]) {
                                estAutre = false;

                                if(value.includes(field)) {
                                    matchCategorie = true;
                                    break;
                                }
                            }
                        }

                        matchCategorie = matchCategorie || (estAutre && value.includes('autre'));
                    }
                }
                // Etat
                else if('etat' === field) {
                    for(let etatField of value) {
                        if(trajet[etatField]) {
                            matchEtat = true;
                            break;
                        }
                    }
                }
                else if('caracteristiques' === field) {
                    for (let caracteristiqueField of value) {
                        if (!(caracteristiqueField in trajet ? trajet : trajet.transport)[caracteristiqueField]) {
                            return false;
                        }
                    }
                }

                // Autres filtres (si renseigné, doit matcher)
                else if(value) {
                    if('personnel' === field) {
                        if((!trajet.personnel1 || trajet.personnel1 !== value.id) && (!trajet.personnel2 || trajet.personnel2 !== value.id)) {
                            return false;
                        }
                    } else {
                        const trajetField = ['vehicule', 'equipage'].includes(field) ? '_'+field : field;
                        let trajetValue = (trajetField in trajet ? trajet : trajet.transport)[trajetField];

                        if (!trajetValue || trajetValue.id !== value.id) {
                            return false;
                        }
                    }
                }
            }

            return matchEtat && matchCategorie;
        }), [sorts[sort.value.column]?.field || 'rendezVous'], [sort.value.direction]).map((trajet) => {
            if(tmp && (
                (sort.value.column === 'rendezVous' && tmp.rendezVous.time.substring(0,2) !== trajet.rendezVous.time.substring(0,2)) ||
                (sort.value.column === 'topDebut' && tmp.topDebut?.time.substring(0,2) !== trajet.topDebut?.time.substring(0,2)) ||
                (sort.value.column === 'topDestinationArrivee' && tmp.topDestinationArrivee?.time.substring(0,2) !== trajet.topDestinationArrivee?.time.substring(0,2)) ||
                (sort.value.column === 'topPriseEnChargeArrivee' && tmp.topPriseEnChargeArrivee?.time.substring(0,2) !== trajet.topPriseEnChargeArrivee?.time.substring(0,2)) ||
                (sort.value.column === 'topFin' && tmp.topFin?.time.substring(0,2) !== trajet.topFin?.time.substring(0,2)) ||
                (sort.value.column === 'equipage' && tmp.equipage?.id !== trajet.equipage?.id) ||
                (sort.value.column === 'vehicule' && tmp.vehicule?.id !== trajet.vehicule?.id) ||
                (sort.value.column === 'personnel1' && tmp.personnel1?.id !== trajet.personnel1?.id) ||
                (sort.value.column === 'personnel2' && tmp.personnel2?.id !== trajet.personnel2?.id) ||
                (sort.value.column === 'transport' && tmp.transport?.motif?.id !== trajet.transport?.motif?.id) ||
                (sort.value.column === 'patient' && tmp.transport?.patient?.id !== trajet.transport?.patient?.id) ||
                (sort.value.column === 'etat' && tmp.etat !== trajet.etat) ||
                (sort.value.column === 'villePriseEnCharge' && tmp.villePriseEnCharge !== trajet.villePriseEnCharge) ||
                (sort.value.column === 'villeDestination' && tmp.villeDestination !== trajet.villePriseEnCharge)
            )) {
                trajet.separator = true;
            } else {
                trajet.separator = false;
            }

            tmp = trajet;

            return trajet;
        });
    });

    const filterEtatChoices = computed(() => {
        let choices = {
            'estAFaire': 'À faire',
            'estPret': 'Prêt',
            'estEnCours': 'En cours',
            'estTermine': 'Terminé',
            'estAnnule': 'Annulé',
        };

        let counters = {};
        for(let field in choices) {
            counters[field] = 0;
        }

        for(let trajet of allTrajetCollection.value) {
            for(let field in choices) {
                if(trajet[field] && (!trajet.estAnnule || 'estAnnule' === field)) {
                    counters[field]++;
                }
            }
        }

        for(let field in choices) {
            if(counters[field]) {
                choices[field] += ' (' + counters[field] + ')';
            }
        }

        return choices;
    });

    const categoriesColor = ['#66c4ed', '#9dd037', '#fec107', '#ff8c01', '#5A5A5A'];

    const categoriesLibelle = {
        estAmbu: 'AMBU',
        estVsl: 'VSL',
        estTaxi: 'TAXI',
        tpmr: 'TPMR',
        autre: 'Autre',
    };

    const trajetsCountByCategorieChartData = computed(() => {
        const data = [];

        for (const categorie in trajetsCountByCategorie.value) {
            data.push([categoriesLibelle[categorie], trajetsCountByCategorie.value[categorie]]);
        }

        return data;
    });

    const trajetsByCategorie = computed(() => {
        const trajetsByCategories = {};
        const categories = ['estAmbu', 'estVsl', 'estTaxi', 'tpmr', 'autre'];

        for(let field of categories) {
            trajetsByCategories[field] = [];
        }

        for(const trajet of allTrajetCollection.value) {
            let estAutre = true;
            for(const field of categories) {
                if((trajet.transport.categorie[field] && !trajet.transport.estTpmr) || (trajet.transport.estTpmr && 'tpmr' === field)) {
                    trajetsByCategories[field].push(trajet);
                    estAutre = false;
                }
            }

            if (estAutre) {
                trajetsByCategories.autre.push(trajet);
            }
        }

        return trajetsByCategories;
    });

    const trajetsCountByCategorie = computed(() => {
        const counters = {};

        for (const categorie in trajetsByCategorie.value) {
            counters[categorie] = trajetsByCategorie.value[categorie].length;
        }

        return counters;
    });

    const trajetsEstimationByCategorieChartConfig = computed(() => {
        return {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: (context) => Euro(context.raw),
                    },
                },
            },
        }
    });

    const trajetsEstimationByCategorieChartData = computed(() => {
        const data = [];

        for (const categorie in trajetsByCategorie.value) {
            data.push([categoriesLibelle[categorie], getEstimationTotale(trajetsByCategorie.value[categorie])]);
        }

        return data;
    });

    const filterCategorieChoices = computed(() => {
        let choices = {};

        for (let field in categoriesLibelle) {
            choices[field] = categoriesLibelle[field];

            if (trajetsCountByCategorie.value[field]) {
                choices[field] += ' (' + trajetsCountByCategorie.value[field] + ')';
            }
        }

        return choices;
    });

    const currentTrajet = computed(() => {
        if(null !== currentTrajetIndex.value) {
            return trajetCollection.value[currentTrajetIndex.value];
        }

        return null;
    });

    const currentTrajetIndex = computed({
        get() {
            if (currentTrajetId.value === null) {
                return null;
            }

            return trajetIndexById.value[currentTrajetId.value];
        },
        set(value) {
            if(value === null) {
                currentTrajetId.value = null;
            } else {
                currentTrajetId.value = trajetCollection.value[value].id;
            }
        }
    });

    const explodeRessource = (ressource) => {
        let fullPersonnel1 = null;
        let fullPersonnel2 = null;
        let fullVehicule = null;
        let fullEquipage = null;

        if('equipage' === ressource._type) {
            fullEquipage = ressource;
        }
        else if('personnel' === ressource._type) {
            fullPersonnel1 = ressource;
            fullEquipage = ressource._equipage;
        }
        else if('vehicule' === ressource._type) {
            fullVehicule = ressource;
            fullEquipage = ressource._equipage;
        }

        if(fullEquipage) {
            fullPersonnel1 = fullEquipage._personnel1;
            fullPersonnel2 = fullEquipage._personnel2;
            fullVehicule = fullEquipage._vehicule;
        } else {
            const trajet = ressource._trajetInfo.minTrajet;

            if(trajet) {
                fullPersonnel1 = trajet._personnel1;
                fullPersonnel2 = trajet._personnel2;
                fullVehicule = trajet._vehicule;
            }
        }

        return [fullPersonnel1, fullPersonnel2, fullVehicule, fullEquipage];
    };

    const getEstimationTotale = (trajetCollection) => trajetCollection
        .filter((trajet) => !trajet.nePasFacturer && (trajet.montantFactureTouteTaxe !== null || trajet.estimationMontantTouteTaxe !== null))
        .reduce((res, trajet) => (res += parseFloat(trajet.montantFactureTouteTaxe !== null ? trajet.montantFactureTouteTaxe : trajet.estimationMontantTouteTaxe), res), 0);

    const envoyerTla = (trajetCollection) => {
        ajaxLocked.value = true;

        Request.postJson(Router.generate('regulation.trajet.envoyer-tla.ajax'), {
            trajetCollection: trajetCollection.map(trajet => trajet.id),
        }).then((data) => {
            for (const id in data) {
                setTrajet(id, data[id]);
            }
        }, () => {}).finally(() => {
            ajaxLocked.value = false;
        });
    };

    const getRessourcesAppareilCollection = (ressources) => {
        const appareils = [];

        for(const ressource of ressources) {
            if(ressource) {
                for(const appareil of ressource.appareilCollection || []) {
                    appareils.push(appareil);
                }
            }
        }

        return appareils;
    };

    const canSendFinService = (personnel1, personnel2, vehicule) => {
        if(!personnel1 && !personnel2) {
            return false;
        }

        if(personnel1 && (!personnel1.typeCourant || !personnel1.typeCourant.amplitude || personnel1.typeCourant.amplitude.envoyerFinServiceTopFinTlaTrajet)) {
            return false;
        }
        if(personnel2 && (!personnel2.typeCourant || !personnel2.typeCourant.amplitude || personnel2.typeCourant.amplitude.envoyerFinServiceTopFinTlaTrajet)) {
            return false;
        }

        for (const ressource of [personnel1, personnel2, vehicule]) {
            if (ressource?.appareilCollection?.length) {
                return true;
            }
        }

        return false;
    };

    const canSendPause = (personnel1, personnel2, vehicule) => {
        if (!salaries.value.modeles.pause.length) {
            return false;
        }

        if(!personnel1 && !personnel2) {
            return false;
        }

        if(personnel1 && (!personnel1.typeCourant || !personnel1.typeCourant.estTravailAbstract || personnel1.pauseCourante)) {
            return false;
        }
        if(personnel2 && (!personnel2.typeCourant || !personnel2.typeCourant.estTravailAbstract || personnel2.pauseCourante)) {
            return false;
        }

        for (const ressource of [personnel1, personnel2, vehicule]) {
            if(ressource?.appareilCollection?.length) {
                return true;
            }
        }

        return false;
    };

    const openEnvoiPauseSwal = (personnel1, personnel2, vehicule, equipage) => {
        App.Utils.openVueSwal('regulation-envoi-pause', {
            modeles: salaries.value.modeles.pause,
            lieux: salaries.value.lieux,
            personnel1, personnel2, vehicule, equipage
        });
    };

    const openEnvoiFinServiceSwal = (personnel1, personnel2, vehicule, equipage) => {
        App.Utils.openVueSwal('regulation-envoi-fin-service', {
            personnel1, personnel2, vehicule, equipage
        });
    };

    const openRessourceDetailSwal = (ressource) => {
        swal({
            showConfirmButton: false,
            showCancelButton: false,
            width: '800px',
            onOpen: () => {
                createApp({
                    template: '<regulation-ressource-detail :ressource="ressource" :current-trajet="currentTrajet" @change="change" @trajet-select="selectTrajet"></regulation-ressource-detail>',
                    data: () => ({
                        ressource,
                    }),
                    computed: {
                        currentTrajet() {
                            return currentTrajet.value;
                        },
                    },
                    methods: {
                        change(newRessource) {
                            this.ressource = newRessource;
                        },
                        selectTrajet(id) {
                            selectTrajetById(id, true);
                        }
                    },
                    provide: {
                        openEnvoiFinServiceSwal,
                        canSendFinService,
                        explodeRessource,
                        openEnvoiPauseSwal,
                        canSendPause,
                        getRessourcesAppareilCollection,
                        onMessagerieSend,
                        allTrajetCollection: computed(() => allTrajetCollection.value),
                        messagerie: computed(() => messagerie.value),
                    }
                }).mount(swal.getContent().parentElement);
            },
        })
    };

    const cancelPause = (pause, pauseSecondaire) => {
        if(!pause) {
            return;
        }

        swal({
            title: App.Constants.LIBELLE_ANNULER_PAUSE_QUESTION,
            type: 'question',
            showCancelButton: true,
            confirmButtonClass: 'bg-info',
            confirmButtonText: Translator.trans('action.confirmer'),
            cancelButtonText: Translator.trans('action.fermer'),
        }).then((result) => {
            if (result.value) {
                Request.postJson(Router.generate('regulation.supprimer-pause.ajax', {
                    pause: pause.id,
                    pauseSecondaire: pauseSecondaire ? pauseSecondaire.id : null,
                }), {}).then((response) => {
                    if (!response.data.success) {
                        swal({
                            title: response.data.title,
                            text: response.data.text,
                            type: 'warning',
                            confirmButtonClass: 'bg-info',
                            confirmButtonText: Translator.trans('action.fermer'),
                        }).then((result) => {});
                    }
                });
            }
        });
    };

    const onMessagerieSend = (data, callback = null) => {
        sendMessageSound.value.play().catch(() => {});
        hasPendingMessage.value = true;

        Request.postJson(Router.generate('regulation.trajet.message.envoyer'), data).then((data) => {
            if (data.success) {
                if (!App.webSocket.isOnline()) {
                    refresh(['messagerie'])
                }
            } else {
                for (const error of data.errors) {
                    App.Notification.error('<b>Erreur lors de l\'envoi du message :</b><br>'+error);
                }
            }

            if (callback) {
                callback(data);
            }
        });
    };

    const onRessourceClick = (ressource) => {
        const ids = ressource._trajetCollection.map(trajet => trajet.id);

        if(ids.length) {
            if (!currentTrajetId.value || !ids.includes(currentTrajetId.value)) {
                const id = ressource._trajetInfo.minTrajet ? ressource._trajetInfo.minTrajet.id : ids[0];

                selectTrajetById(id, true);
            } else {
                const currentIndex = ids.indexOf(currentTrajetId.value);
                const newIndex = currentIndex === ids.length - 1 ? 0 : currentIndex + 1;
                selectTrajetById(ids[newIndex], true);
            }
        }
    };

    const getRessourceSection = (ressource) => {
        return ressource._etat.section;
    };

    const refreshRessource = (ressource) => {
        const section = getRessourceSection(ressource);

        if(section === 'pause') {
            let progress = 0;
            // TODO fuck: need timeout pour update..
            if(ressource.pauseCourante) {
                const debut = Moment(ressource.pauseCourante.debut, 'DD/MM/YYYY HH:mm');
                const fin = Moment(ressource.pauseCourante.fin, 'DD/MM/YYYY HH:mm');

                const duree = fin.diff(debut, 'seconds');
                const elapsed = Moment().diff(debut, 'seconds');
                if(0 !== duree) {
                    progress = elapsed >= duree ? 1 : elapsed / duree;
                }
            }

            ressource._progress = progress;
        }
    };

    const getRessourceTrajetInfo = (ressource) => {
        let minTrajet = null;
        let count = 0;
        let total = 0;
        const section = getRessourceSection(ressource);

        if(section === 'mission') {
            let minTop = null;

            for(const trajet of ressource._trajetCollection) {
                if(trajet.etat !== 3) {
                    if (trajet.topCourant !== null) {
                        if (null === minTop || minTop > trajet.topCourant) {
                            minTop = trajet.topCourant;
                            minTrajet = trajet;
                            count = 1;
                        } else if (minTop === trajet.topCourant) {
                            count++;
                        }

                        total++;
                    }
                }
            }

            if(null !== minTop) {
                ressource._progress = {0: .25, 1: .5, 2: .5, 3: .75, 4: .75, 5: 1}[minTop];
            }
        }
        else if(section === 'affecte') {
            let minDate = null;

            for(const trajet of ressource._trajetCollection) {
                if(trajet.etat !== 3) {
                    const newMinDate = trajet._debutMissionPrevu;

                    if (newMinDate && (null === minDate || newMinDate.isBefore(minDate))) {
                        minDate = newMinDate;
                        minTrajet = trajet;
                    }

                    total++;
                }
            }
        }
        else if(section === 'disponible') {
            const now = Moment();
            let maxDate = null;

            for(const trajet of ressource._trajetCollection) {
                if(trajet.etat === 3) {
                    const newMaxDate= trajet._topFinReel;

                    if (newMaxDate && (null === maxDate || newMaxDate.isAfter(maxDate)) && newMaxDate.isSameOrBefore(now)) {
                        maxDate = newMaxDate;
                        minTrajet = trajet;
                    }

                    total++;
                }
            }
        }

        return {count, total, minTrajet};
    };

    const editSimultane = (trajet) => {
        simultane.value = {
            trajet: trajet.id,
            simultane: trajet.simultane ? trajet.simultane.id : null,
            couleur: trajet.simultane ? trajet.simultane.couleur : randomColor().substring(1),
        };
        simultaneTrajetIds.value = trajet.simultane ? trajet.simultane.trajetCollection : [trajet.id];
    };

    const toggleSimultane = (trajet, toggle) => {
        if (toggle) {
            simultaneTrajetIds.value.push(trajet.id);
        } else {
            simultaneTrajetIds.value.splice(simultaneTrajetIds.value.indexOf(trajet.id), 1);
        }
    };

    const cancelSimultane = () => {
        simultane.value = null;
        simultaneTrajetIds.value = [];
    };

    const saveSimultane = () => {
        simultaneLoading.value = true;

        Request.postJson(Router.generate('regulation.trajet.simultane.ajax', {id: simultane.value.trajet}), {
            simultane: {
                couleur: simultane.value.couleur,
                trajetCollection: simultaneTrajetIds.value,
            }
        }).then((data) => {
            for(let id in data) {
                setTrajet(id, data[id]);
            }

            simultane.value = null;
            simultaneTrajetIds.value = [];
            simultaneLoading.value = false;
        }, () => {
            simultaneLoading.value = false;
        });
    };

    const onRessourceDispo = (ressource) => {
        if(activeTab.value !== 2) {
            hasNewRessourceDispo.value = true;
        }
    };

    const updateTrajet = (trajet, field, value) => {
        return new Promise((resolve, reject) => {
            Request.patchJson(Router.generate('regulation.trajet.update.ajax', {id: trajet.id}), {
                trajet_update: {
                    [field]: value,
                }
            }).then((data) => {
                for (let id in data) {
                    setTrajet(id, data[id]);
                }
                resolve(data);
            }, (error) => {
                reject(error);
            });
        })
    };

    const print = (event) => {
        let ladda = Ladda.create(event.currentTarget);
        ladda.start();

        App.Utils.print(Router.generate(('regulation.trajet.export'), {
            filters: {
                rendezVous: rendezVousInput.value,
                etat: filter.value.etat,
                categorie: filter.value.categorie,
                structureCollection: structureCollection.value,
                motif: filter.value.motif ? filter.value.motif.id : null,
                equipage: filter.value.equipage ? filter.value.equipage.id : null,
                vehicule: filter.value.vehicule ? filter.value.vehicule.id : null,
                personnel: filter.value.personnel ? filter.value.personnel.id : null,
                caracteristiques: filter.value.caracteristiques,
            },
            ordre: sorts[sort.value.column]?.query || 'a.rendezVous',
            direction: sort.value.direction,
        })).then(() => {
            ladda.stop();
        });
    };

    const selectTrajetById = (id) => {
        selectTrajet(trajetIndexById.value[id]);
    };

    const selectTrajet = (index) => {
        if (null === index || index in trajetCollection.value) {
            currentTrajetIndex.value = index;
        }
    };

    const resetData = () => {
        allTrajetCollection.value = [];
    };

    const computeTrajetFields = (trajet) => {
        trajet._structure = trajet.structure;

        if (trajet.structure.racinePartage) {
            const structureData = structuresRacine.value[trajet.structure.racinePartage];

            if (structureData) {
                trajet._structure.racinePartage = {
                    id: trajet.structure.racinePartage,
                    ...structureData
                };
            }
        }

        trajet._motifAnnulation = null !== trajet.motifAnnulation && trajet._structure.racinePartage ? trajet._structure.racinePartage.motifsAnnulation[trajet.motifAnnulation] : null;

        if (!trajet._personnel1) {
            trajet._personnel1 = null !== trajet.personnel1 ? personnelsById.value[trajet.personnel1] : null;
        }
        if (!trajet._personnel2) {
            trajet._personnel2 = null !== trajet.personnel2 ? personnelsById.value[trajet.personnel2] : null;
        }
        if (!trajet._vehicule) {
            trajet._vehicule = null !== trajet.vehicule ? vehiculesById.value[trajet.vehicule] : null;
        }
        if (!trajet._equipage) {
            trajet._equipage = null !== trajet.equipage ? equipagesById.value[trajet.equipage] : null;
        }

        trajet._topFinReel = trajet.topFinReel !== null ? Moment(trajet.topFinReel.date+' '+trajet.topFinReel.time, 'DD/MM/YYYY HH:mm') : null;
        trajet._topFinReelBool = !!trajet._topFinReel;

        trajet._topPriseEnChargeArrivee = trajet.topPriseEnChargeArrivee !== null ? Moment(trajet.topPriseEnChargeArrivee.date+' '+trajet.topPriseEnChargeArrivee.time, 'DD/MM/YYYY HH:mm') : null;
        trajet._topDestinationArrivee = trajet.topDestinationArrivee !== null ? Moment(trajet.topDestinationArrivee.date+' '+trajet.topDestinationArrivee.time, 'DD/MM/YYYY HH:mm') : null;
        trajet._debutMissionPrevu = trajet.debutMissionPrevu !== null ? Moment(trajet.debutMissionPrevu.date+' '+trajet.debutMissionPrevu.time, 'DD/MM/YYYY HH:mm') : null;
        trajet._topCourantValue = trajet.topCourantValue !== null ? Moment(trajet.topCourantValue.date+' '+trajet.topCourantValue.time, 'DD/MM/YYYY HH:mm') : null;

        return trajet;
    };

    const setTrajet = (id, data) => {
        allTrajetCollection.value[allTrajetIndexById.value[data.id]] = computeTrajetFields(data);
    };

    const handleData = (data) => {
        if(data.rendezVous !== rendezVousInput.value) {
            return;
        }

        // Salariés
        if(data.salaries) {
            salaries.value = data.salaries;
        }

        // Ressources
        if (data.personnels) {
            handleRessourceData(data.personnels, 'personnel');
            refreshSalariesEtth();
        }
        if (data.vehicules) {
            handleRessourceData(data.vehicules, 'vehicule');
        }
        if (data.equipages) {
            handleRessourceData(data.equipages, 'equipage');
        }

        // Liste des trajets
        if (data.index) {
            const trajetCollection = data.index.trajetCollection;

            for (const trajet of trajetCollection) {
                computeTrajetFields(trajet);
            }

            allTrajetCollection.value = trajetCollection;
        }

        if (data.index || data.personnels || data.vehicules || data.equipages) {
            // compute ressource etat / min trajet
            for(const ressources of [personnels, vehicules, equipages]) {
                for(const ressource of ressources.value) {
                    if(ressource.equipage) {
                        ressource._equipage = equipagesById.value[ressource.equipage];
                    }
                    if(ressource.vehicule) {
                        ressource._vehicule = vehiculesById.value[ressource.vehicule];
                    }
                    if(ressource.personnel1) {
                        ressource._personnel1 = personnelsById.value[ressource.personnel1];
                    }
                    if(ressource.personnel2) {
                        ressource._personnel2 = personnelsById.value[ressource.personnel2];
                    }

                    ressource._trajetCollection = allTrajetCollectionByRessourceId.value[ressource._type][ressource.id] || [];
                    ressource._etat = etatsRessources[getEtatRessource(ressource)];
                    ressource._trajetInfo = getRessourceTrajetInfo(ressource);
                    refreshRessource(ressource);
                }
            }
        }

        // Messagerie
        if(data.messagerie) {
            messagerie.value = data.messagerie;
        }

        if (!initDataLoaded.value) {
            nextTick(() => initDataLoaded.value = true);
        }
        // todo specifique regu
        App.Regulation.triggerLoader();
    };

    const getEtatRessource = (ressource) => {
        return App.Regulation.Trajet.getEtatRessource(ressource, allTrajetCollectionByRessourceId.value);
    };

    const handleRessourceData = (ressources, type) => {
        for(const ressource of ressources) {
            ressource._type = type;
            ressource._trajetCollection = [];
            ressource._etat = etatsRessources.indisponible;

            if (type === 'equipage') {
                ressource._debut = ressource.debut !== null ? Moment(ressource.debut, 'DD/MM/YYYY HH:mm') : null;
                ressource._fin = ressource.fin !== null ? Moment(ressource.fin, 'DD/MM/YYYY HH:mm') : null;
            }

            const typeCourant = ressource.typeCourant;

            if (typeCourant) {
                const debut = typeCourant.debutReel || typeCourant.debutPrevu;
                const fin = typeCourant.finReel || typeCourant.finPrevu;

                typeCourant._debut = debut !== null ? Moment(debut.date+' '+debut.time, 'DD/MM/YYYY HH:mm') : null;
                typeCourant._fin = fin !== null ? Moment(fin.date+' '+fin.time, 'DD/MM/YYYY HH:mm') : null;

                for(const pause of typeCourant.pauseCollection || []) {
                    const debut = pause.debutReel || pause.debutPrevu;
                    const fin = pause.finReel || pause.finPrevu;

                    pause._debut = debut !== null ? Moment(debut, 'DD/MM/YYYY HH:mm') : null;
                    pause._fin = fin !== null ? Moment(fin, 'DD/MM/YYYY HH:mm') : null;
                }
            }
            if(ressource.pausePrecedente) {
                ressource.pausePrecedente._fin = Moment(ressource.pausePrecedente.fin, 'DD/MM/YYYY HH:mm');
            }

            ressource._progress = 0;
            ressource._trajetInfo = getRessourceTrajetInfo(ressource);
        }

        ({
            equipage: equipages,
            personnel: personnels,
            vehicule: vehicules,
        }[type]).value = ressources;
    };

    const refreshSalariesEtth = () => {
        const now = Moment();

        for(const personnel of personnels.value) {
            let ecart = null;

            if(null !== personnel.ecartTempsTravail) {
                ecart = personnel.ecartTempsTravail;

                const typeCourant = personnel.typeCourant;

                if(typeCourant && typeCourant.estTravailAbstract && null !== typeCourant.debutPrevu && null !== typeCourant.finPrevu) {
                    const debutPrevu = Moment(typeCourant.debutPrevu.date+' '+typeCourant.debutPrevu.time, 'DD/MM/YYYY HH:mm');
                    const finPrevu = Moment(typeCourant.finPrevu.date+' '+typeCourant.finPrevu.time, 'DD/MM/YYYY HH:mm');

                    const debutRetenu = null !== typeCourant.debutReel ? Moment(typeCourant.debutReel.date+' '+typeCourant.debutReel.time, 'DD/MM/YYYY HH:mm') : debutPrevu;
                    const finRetenue = null !== typeCourant.finReel ? Moment(typeCourant.finReel.date+' '+typeCourant.finReel.time, 'DD/MM/YYYY HH:mm') : (!rendezVous.value || now.format('DD/MM/YYYY') === rendezVous.value.format('DD/MM/YYYY') ? now : finPrevu);

                    const dureePrevue = finPrevu.diff(debutPrevu, 'minutes');
                    const dureeRetenue = finRetenue.diff(debutRetenu, 'minutes');

                    ecart += dureeRetenue - dureePrevue;
                }
            }

            personnel._ecartTempsTravail = ecart;
        }
    };

    const step = (days) => {
        rendezVous.value = Moment(rendezVous.value).add(days, 'days');
    };

    const today = (days) => {
        rendezVous.value = Moment();
    };

    const doRefresh = (parts = null, update = null) => {
        const data = {
            trajet_filter: filterData.value,
        };

        let routeParams = {};

        if(update !== null) {
            routeParams.update = update;
        }

        if (parts !== null) {
            data.parts = parts;
        }

        Request.postJson(Router.generate('regulation.trajet.list.ajax', routeParams), data).then((res) => {
            isLoading.value = false;
            handleData(res);

            if(null === parts) {
                App.webSocket.publish('regulation/transport/trajet', {filter: filterData.value});
            }
        }, (error) => {
            isLoading.value = false;
            // SHOW ERROR
        });
    };

    const refresh = (parts = null) => {
        if(!rendezVous.value) {
            return resetData();
        }

        if (parts === null) {
            isLoading.value = true;
            _refreshDebounced();
        } else {
            partsToRefresh.value = _.union(partsToRefresh.value, parts);
            _refreshThrottled();
        }
    };

    const _refreshThrottled = _.throttle(function() {
        doRefresh(partsToRefresh.value);
        partsToRefresh.value = [];
    }, 2000);

    const _refreshDebounced =_.debounce(doRefresh, 500);

    watch(filterData, () => {
        for (const key in filterData.value) {
            urlParams['trajet_filter['+key+']'+(Array.isArray(filterData.value[key]) ? '[]':'')] = filterData.value[key];
        }
    });

    watch(showAdvancedFilters, (value) => {
        if (!value) {
            for (let field in advancedFields) {
                filter.value[field] = advancedFields[field];
            }
        }
    });

    watch(filter, () => {
        Cookies.set('ambuerp_regulation_trajet_filter', filter.value);
    }, {
        deep: true,
    });

    watch(sort, () => {
        Cookies.set('ambuerp_regulation_trajet_sort', sort.value);
    }, {
        deep: true,
    });

    watch(rendezVous, () => {
        refresh(null, 'manual');
    });

    const initDataLoaded = ref(false);
    watch(structureCollection, (structures) => {
        if (!structures.length) {
            allTrajetCollection.value = [];
        }

        initDataLoaded.value = false;

        refresh(null, 'manual');
    }, {deep: true});

    onMounted(() => {
        // Timers
        setInterval(() => {
            if(!isLoading.value) {
                refreshSalariesEtth();
            }
        }, 1000 * 10);

        // WEBSOCKET
        App.webSocket.subscribe('regulation/transport/trajet', (uri, data) => {
            if (data.action === 'refresh' && !isLoading.value) {
                refresh(data.parts, 'ws');
            }
        });

        // Messagerie
        App.webSocket.subscribe('regulation/transport/trajet/messagerie', (uri, data) => {
            if(data.messages.length && messagerie.value.messages) {
                const oldLastMessage = messagerie.value.messages[messagerie.value.messages.length - 1];
                const newLastMessage = data.messages[data.messages.length - 1];

                if(!hasPendingMessage.value && (!oldLastMessage || oldLastMessage.id !== newLastMessage.id)) {
                    hasUnreadMessage.value = true;
                    newMessageSound.value.play().catch(() => {});
                }

                hasPendingMessage.value = false;
            }

            messagerie.value = data;
        });

        sendMessageSound.value = new Audio(sendMessageAudio);
        newMessageSound.value = new Audio(newMessageAudio);
    });

    return {
        etatsRessources,
        Euro,
        trajetTab,
        messagerieTab,
        ressourcesTab,
        sorts,
        showAdvancedFilters,
        filter,
        currentTrajetId,
        focusTrajetIndex,
        allTrajetCollection,
        structureCollection,
        structuresRacine,
        rendezVous,
        isLoading,
        simultane,
        simultaneLoading,
        simultaneTrajetIds,
        personnels,
        vehicules,
        equipages,
        activeTab,
        messagerie,
        salaries,
        hasUnreadMessage,
        hasNewRessourceDispo,
        partsToRefresh,
        tops,
        sort,
        ajaxLocked,
        filterData,
        allTrajetCollectionByRessourceId,
        messages,
        messagesByRessourceId,
        messagesById,
        groupMessagesByRessource,
        trajetCollectionPretEnvoiTla,
        patientIds,
        chartOptions,
        chartData,
        estimationMontantTouteTaxeFiltre,
        estimationMontantTouteTaxeTotal,
        personnelsById,
        vehiculesById,
        equipagesById,
        rendezVousInput,
        rendezVousIsToday,
        rendezVousDay,
        trajetIndexById,
        allTrajetIndexById,
        trajetCollection,
        filterEtatChoices,
        categoriesColor,
        categoriesLibelle,
        trajetsCountByCategorieChartData,
        trajetsByCategorie,
        trajetsCountByCategorie,
        trajetsEstimationByCategorieChartConfig,
        trajetsEstimationByCategorieChartData,
        filterCategorieChoices,
        currentTrajet,
        currentTrajetIndex,
        sectionsRessources,
        searchQueryRessources,
        modeRessources,
        ressources,
        ressourcesBySection,
        countRessourcesByEtatBySection,
        hasPendingMessage,
        explodeRessource,
        getEstimationTotale,
        envoyerTla,
        getRessourcesAppareilCollection,
        canSendFinService,
        canSendPause,
        openEnvoiPauseSwal,
        openEnvoiFinServiceSwal,
        openRessourceDetailSwal,
        cancelPause,
        onMessagerieSend,
        onRessourceClick,
        getRessourceSection,
        refreshRessource,
        getRessourceTrajetInfo,
        editSimultane,
        toggleSimultane,
        cancelSimultane,
        saveSimultane,
        onRessourceDispo,
        updateTrajet,
        print,
        selectTrajetById,
        selectTrajet,
        resetData,
        computeTrajetFields,
        setTrajet,
        getEtatRessource,
        refreshSalariesEtth,
        step,
        today,
        refresh,
        initDataLoaded,
        urlParams,
    };
});
