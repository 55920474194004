import Inputmask from 'inputmask';

export default {
    install: (Vue) => {
        App.Layout.initInputmask();

        Vue.directive('mask', {
            inserted: (el, binding) => {
                let config = binding.value;
                Inputmask(config.mask || config, config.format ? {inputFormat: config.format} : {}).mask(el);
            }
        });
    }
};
