<template>
    <div>
        <component v-bind="{id, ...props}" :is-invalid="isInvalid" :is="component || 'form-input'" :value="value" @input="$emit('input', $event)">
            <slot></slot>
        </component>
        <FormValidation :items="errors"></FormValidation>
    </div>
</template>

<script setup>
import FormValidation from '@/Vue/Components/Form/Validation';
import {computed, toRefs} from "vue";

const allProps = defineProps(['component', 'value', 'errors', 'props', 'id']);
const {component, value, errors, props, id} = toRefs(allProps);
const isInvalid = computed(() => errors.value && errors.value.length > 0);
</script>
