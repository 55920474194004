<template>
    <div class="d-flex flex-column align-items-center">
        <div class="mb-2" style="font-size: 24px; font-weight: 500;">
            {{ error ? 'Erreur lors de l\'appel au service' : 'Appel au service...'}}
        </div>
        <div class="d-flex align-items-center justify-content-center py-3" :class="{'mb-5': !error && showPopover}">
            <div class="bg-light px-4 py-3 position-relative" :class="{'border border-danger': error && error.type === 'form', 'border border-success': error && error.type !== 'form'}">
                <img src="../../../../images/logo-black.svg" alt="AmbuERP" style="height: 60px;">
                <i class="fa-solid sefi-loader-error-icon" v-if="error" :class="{'fa-rectangle-xmark text-danger': error && error.type === 'form', 'fa-square-check text-success': error && error.type !== 'form'}"></i>
            </div>
            <div class="arrows-spinner" :class="{'opacity-0': error}">
                <div class="arrows-spinner-content"></div>
            </div>
            <div class="bg-light px-4 py-3 position-relative" id="sefiLoaderCps" :class="{'border border-danger': error && error.type === 'cps', 'border border-success': error && error.type === 'sefi'}">
                <img src="../../../../images/cps.svg" alt="Carte CPS" style="height: 60px;" :class="{'grayscale opacity-50': error && error.type === 'form'}">
                <i class="fa-solid sefi-loader-error-icon" v-if="error && (error.type === 'sefi' || error.type === 'cps')" :class="{'fa-rectangle-xmark text-danger': error && error.type === 'cps', 'fa-square-check text-success': error && error.type === 'sefi'}"></i>
            </div>
            <div class="arrows-spinner" :class="{'opacity-0': error || carteCpsDefautLocked}">
                <div class="arrows-spinner-content"></div>
            </div>
            <div class="bg-light px-4 py-3 position-relative" :class="{'border border-danger': error && error.type === 'sefi'}">
                <img src="../../../../images/sefi.png" alt="SEFi" style="height: 60px;" :class="{'grayscale': (error && error.type !== 'sefi') || carteCpsDefautLocked}">
                <i class="fa-solid sefi-loader-error-icon fa-rectangle-xmark text-danger" v-if="error && error.type === 'sefi'"></i>
            </div>
        </div>
        <sefi-error class="mt-5" v-if="error" :error="error"></sefi-error>
        <popover v-else-if="showPopover" show target="sefiLoaderCps" placement="bottom" :key="wasUnlocked">
            <div class="text-success text-normal" v-if="wasUnlocked">
                <i class="fa-solid fa-circle-check"></i> Carte déverrouillée !
            </div>
            <div class="text-danger text-normal" v-else>
                <i class="fa-solid fa-circle-exclamation"></i> Veuillez déverrouiller la carte
            </div>
        </popover>
    </div>
</template>

<script>
import {mapState} from "pinia";
import { useCpsStore } from '@/Vue/Stores/Cps'

export default {
    props: ['error'],
    data() {
        return {
            wasLocked: false,
            wasUnlocked: false,
            showPopover: false,
        };
    },
    mounted() {
        if(this.carteCpsDefautLocked) {
            this.wasLocked = true;
        }

        setTimeout(() => {
            if(this.wasLocked) {
                this.showPopover = true;
            }
        }, 500);
    },
    computed: {
        ...mapState(useCpsStore, {carteCpsDefaut: 'carteDefaut'}),
        carteCpsDefautOnline() {
            return this.carteCpsDefaut && this.carteCpsDefaut.info && this.carteCpsDefaut.info.clientData;
        },
        carteCpsDefautLocked() {
            return this.carteCpsDefautOnline && this.carteCpsDefaut.info.clientData.locked;
        },
        carteCpsDefautUnlocked() {
            return this.carteCpsDefautOnline && !this.carteCpsDefaut.info.clientData.locked;
        },
    },
    watch: {
        carteCpsDefautUnlocked() {
            if(this.carteCpsDefautUnlocked) {
                this.wasUnlocked = true;
            }
        },
    }
}
</script>
