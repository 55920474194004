'use strict';

import moment from 'moment';

App.Xmap = class {
    constructor(parent) {
        this.parent = parent;

        this.layerBureau = GEOLOC_CONST.droits['ROLE_GEOLOCALISATION_BUREAU'];
        this.layerVehicule = GEOLOC_CONST.droits['ROLE_GEOLOCALISATION_VEHICULE'];

        this.timeout = null;
        this.map = null;
        this.error = 0;

        this.layers = [];

        try {
            this.layers['background'] = new L.PtvLayer.Background();
            this.layers['foreground'] = new L.PtvLayer.Foreground();
        } catch(e) {
            throw Error();
        }

        this.layers['background'].options.backgroundProfile = 'carto-bg';
        this.layers['foreground'].options.foregroundProfile = 'carto-fg';

        this.layers['background'].options.profileSnippet = this.layers['foreground'].options.profileSnippet = App.Xmap.getProfil();
        this.layers['background'].options.errorTileUrl = App.Constants.GEOLOC_TRANSPARENT_PIXEL_PATH;
        this.layers['background'].options.onErrorTileSelected = () => {
            if(this.error === 3) {
                this.parent.modeErreur();
            }

            this.error = this.error + 1;
        };

        if(this.layerBureau) {
            this.layers['bureau'] = new L.GeoJSON(null, {
                pointToLayer: (feature, latlng) => {
                    return this.parent.createPoint({
                        'lat': latlng.lat,
                        'lng': latlng.lng,
                        'type': 'bureau',
                        'id': feature.properties.id,
                        'name': feature.properties.name,
                        'adresse': feature.properties.adresse,
                        'ville': feature.properties.ville,
                        'codePostal': feature.properties.codePostal
                    }).getMarker();
                }
            });
        }
        if(this.layerVehicule) {
            this.layers['vehicule'] = new L.GeoJSON(null, {
                pointToLayer: (feature, latlng) => {
                    return this.parent.createPoint({
                        'lat': latlng.lat,
                        'lng': latlng.lng,
                        'markerColor': '#ea8484',
                        'type': 'vehicule',
                        'id': feature.properties.id,
                        'code': feature.properties.code,
                        'name': feature.properties.name,
                        'adresse': feature.properties.adresse,
                        'ville': feature.properties.ville,
                        'codePostal': feature.properties.codePostal,
                        'bureau': feature.properties.bureau,
                        'horodatage': feature.properties.horodatage,
                        'iconClass': feature.properties.classeIcone,
                        'colorClass': feature.properties.classeCouleur
                    }).getMarker();
                },
                onEachFeature : (feature) => {
                    let point = this.parent.getPoint(feature.properties.id);

                    if(point) {
                        let $block = $('button[data-point="' + feature.properties.id + '"]').parent(),
                            $detail = $block.find('.js-geoloc-vehicule-detail'),
                            $boutons = $block.find('.js-geoloc-vehicule-itineraire');

                        $boutons.removeClass('d-none');

                        if (0 === $('.js-geoloc-vehicule-adresse-done', $detail).length) {
                            this.parent.xlocate.reverseGeocode(point, $detail);
                        }
                    }
                }
            });
        }

        this.layers['recherche'] = new L.LayerGroup();
        this.layers['itineraire'] = new L.LayerGroup();
        this.layers['position'] = new L.LayerGroup();

        this.currentItineraire = null;
    }

    enable() {
        this.map = L.map("map", {
            layers: [this.layers['background'], this.layers['foreground']],
            center: [46.8, 1.7],
            crs: L.CRS.PTVMercator,
            zoom: 6.5,
            zoomControl: false
        });
        this.setTimeoutedRefresh();

        this.map.attributionControl.setPosition('bottomleft');
        this.map.attributionControl.setPrefix('Appligos-Opendev © ' + (new Date()).getFullYear());

        L.control.scale({
            position: 'bottomright',
            imperial:false
        }).addTo(this.map);
        L.control.zoom({
            position: 'bottomright',
            zoomInTitle: 'Zoomer',
            zoomOutTitle: 'Dézoomer'
        }).addTo(this.map);

        if(this.layerBureau) {
            this.map.addLayer(this.layers['bureau']);
        }
        if(this.layerVehicule) {
            this.map.addLayer(this.layers['vehicule']);
        }
        this.map.addLayer(this.layers['recherche']);
        this.map.addLayer(this.layers['itineraire']);
        this.map.addLayer(this.layers['position']);

        if(this.layerBureau || this.layerVehicule) {
            let controlLayers = [];
            if (this.layerBureau) {
                controlLayers [GEOLOC_CONST.libelles['bureauCollection']] = this.layers['bureau'];
            }
            if (this.layerVehicule) {
                controlLayers ['Véhicules / Appareils'] = this.layers['vehicule'];
            }
            L.control.layers([], controlLayers, {collapsed: false}).addTo(this.map);
        }

        if(this.layerBureau) {
            this.fetchBureauxGeojson();
        }
        if(this.layerVehicule) {
            this.fetchVehiculesGeojson();
        }
    }

    setReferenceTime(time) {
        $('.js-geoloc-reset-infotrafic strong').text(moment(time).format('DD/MM/YYYY à HH[h]mm'));
        $('.js-geoloc-reset-infotrafic').removeClass('d-none');

        this.layers['foreground'].options.referenceTime = time;
    }

    resetReferenceTime() {
        delete this.layers['foreground'].options.referenceTime;
        $('.js-geoloc-reset-infotrafic').addClass('d-none');

        this.centerPoint(this.map.getCenter());
    }

    setTimeoutedRefresh() {
        delete this.timeout;
        this.timeout = setTimeout(() => {
            this.layers['foreground']._update()
            this.setTimeoutedRefresh();
        }, 60000);
    }

    fetchBureauxGeojson() {
        let bureaux = null;

        if(this.parent.bureaux) {
            bureaux = this.parent.bureaux;
        } else {
            bureaux = $('.js-geoloc-bureaux').val();
        }

        if(bureaux) {
            $.ajax({
                url: GEOLOC_CONST.urls.urlBureauxGeojson,
                method: 'GET',
                data: {bureaux: bureaux},
                timeout: 5000,
                cache: false
            }).done((data) => {
                this.clearLayer('bureau');
                this.layers['bureau'].addData(data);
                if(this.parent.getBounds().length) {
                    this.map.fitBounds(this.parent.getBounds());
                }
            });
        } else {
            this.clearLayer('bureau');
        }
    }

    fetchVehiculesGeojson() {
        let bureaux = null;

        if(this.parent.bureaux) {
            bureaux = this.parent.bureaux;
        } else {
            bureaux = $('.js-geoloc-bureaux').val();
        }

        if(bureaux) {
            $.ajax({
                url: GEOLOC_CONST.urls.urlVehiculesGeojson,
                method: 'GET',
                data: {bureaux: bureaux},
                timeout: 5000,
                cache: false
            }).done((data) => {
                this.clearLayer('vehicule');
                this.layers['vehicule'].addData(data);
            });
        } else {
            this.clearLayer('vehicule');
        }
    }

    clearLayer(layer) {
        this.layers[layer].clearLayers();
    }

    centerPoint(point) {
        this.map.setView([point.lat, point.lng], 17, {reset: true});
    }

    showPoint(layer, point) {
        this.layers[layer].addLayer(point.getMarker());
    }

    showItineraire(itineraire) {
        this.clearLayer('itineraire');

        let polyline = new L.Polyline(itineraire.trace, {
            color: '#00b3fd',
            weight: 10,
            opacity: 0.5,
            smoothFactor: 1
        });
        this.layers['itineraire'].addLayer(polyline);

        let arrowHead = L.polylineDecorator(polyline,{
            patterns: [
                {offset: 25, repeat: 50, symbol: L.Symbol.arrowHead({pixelSize: 7, polygon: false, pathOptions: {stroke: true, opacity: 0.6, color: 'white', weight: 2}})}
            ]
        });
        this.layers['itineraire'].addLayer(arrowHead);

        if(itineraire.params.depart.type === 'arrivee') {
            itineraire.params.depart.configureType('depart');
        }
        if(itineraire.params.arrivee.type === 'depart') {
            itineraire.params.arrivee.configureType('arrivee');
        }

        this.showPoint('itineraire', itineraire.params.depart);
        this.showPoint('itineraire', itineraire.params.arrivee);

        this.map.fitBounds(polyline.getBounds());

        this.currentItineraire = itineraire;
    }

    closePopup() {
        this.map.closePopup();
    }

    static getProfil() {
        return "<Profile>"+
                "<FeatureLayer majorVersion='1' minorVersion='0'>"+
                  "<Themes>"+
                    "<Theme id='PTV_TrafficIncidents' enabled='true'/>"+
                  "</Themes>" +
                "</FeatureLayer>"+
                "<Rendering minorVersion='0' majorVersion='1'>"+
                  "<Country id='*'>"+
                    "<Theme id='PTV_TrafficIncidents'>"+
                      "<OverlappingResolution scope='ALL_THEMES' forceDisplay='false' maximumDisplacement='0'/>"+
                      "<Class name='AbsoluteSpeed-Slow'>"+
                        "<Filter>"+
                          "<Condition displayClass='AbsoluteSpeed'>"+
                            "<Range values='1-35'/>"+
                          "</Condition>"+
                        "</Filter>"+
                        "<Style>"+
                          "<Geometry displayRange='9-14'>"+
                            "<Stroke color='#FF0000' transparency='20' perpendicularOffset='50%' width='100%'>"+
                              "<Arrows drawStroke='false' drawFill='false' fillColor='#FFFFFF' strokeColor='#000000'/>"+
                            "</Stroke>"+
                            "<RenderLevel renderOffset='+10'/>"+
                          "</Geometry>"+
                          "<Geometry displayRange='15-23'>"+
                            "<Stroke color='#FF0000' transparency='20' perpendicularOffset='50%' width='100%'>"+
                              "<Arrows drawStroke='false' drawFill='true' fillColor='#FFFFFF' strokeColor='#000000'/>"+
                            "</Stroke>"+
                            "<RenderLevel renderOffset='+10'/>"+
                          "</Geometry>"+
                        "</Style>"+
                      "</Class>"+
                      "<Class name='AbsoluteSpeed-MediumToFast'>"+
                        "<Filter>"+
                          "<Condition displayClass='AbsoluteSpeed'>"+
                            "<Range values='36-130'/>"+
                          "</Condition>"+
                        "</Filter>"+
                        "<Style>"+
                          "<Geometry displayRange='9-14'>"+
                            "<Stroke color='#FF661a' transparency='50' perpendicularOffset='50%' width='100%'>"+
                              "<Arrows drawStroke='false' drawFill='false' fillColor='#FFFFFF' strokeColor='#000000'/>"+
                            "</Stroke>"+
                            "<RenderLevel renderOffset='+10'/>"+
                          "</Geometry>"+
                          "<Geometry displayRange='15-23'>"+
                            "<Stroke color='#FF661a' transparency='40' perpendicularOffset='50%' width='100%'>"+
                              "<Arrows drawStroke='false' drawFill='true' fillColor='#FFFFFF' strokeColor='#000000'/>"+
                            "</Stroke>"+
                            "<RenderLevel renderOffset='+10'/>"+
                          "</Geometry>"+
                        "</Style>"+
                      "</Class>" +
                    "</Theme>"+
                  "</Country>"+
                "</Rendering>"+
              "</Profile>";
    }
};
