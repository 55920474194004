<template>
    <template v-if="currentTrajet">
        <template :key="currentTrajet.id">
            <PinStatut v-for="statut in currentTrajet.statutCollection.filter(statut => statut.position && statut.type >= 0)" :statut="statut"></PinStatut>
            <TrajetPins :trajet="currentTrajet"></TrajetPins>
            <Route id="currentTrajet" :mode="currentTrajet.estTermine ? null : 'ant'" :ant-speed="50" autoFit :color="currentTrajet.estTermine ? '#22c55e' : '#3b82f6'" :steps="[[currentTrajet.latitudeDegrePriseEnCharge, currentTrajet.longitudeDegrePriseEnCharge], [currentTrajet.latitudeDegreDestination, currentTrajet.longitudeDegreDestination]]"></Route>
        </template>
    </template>
</template>

<script setup>
import Route from "@/Vue/Components/RegulationV2/Map/Base/Route";
import TrajetPins from "@/Vue/Components/RegulationV2/Map/Trajet/Pins";
import PinStatut from "@/Vue/Components/RegulationV2/Map/Trajet/PinStatut";
import {storeToRefs} from "pinia";
import {useRegulationStore} from "@/Vue/Stores/Regulation";

const regulationStore = useRegulationStore();
const {currentTrajet} = storeToRefs(regulationStore);
</script>