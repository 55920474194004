<template>
    <ChatBox v-focustrap formHeight="86px" :messages="messages" ref="chatBox" :errors="errors" hover>
        <template #form v-if="roles.ROLE_REGULATION_V2_MESSAGERIE_SEND">
            <div class="p-2 flex-col flex gap-y-2">
                <MultiSelect v-model="destinataires" @hide="messageInput.$el.focus()" :options="allDestinataires" :disabled="sending" filter autoFilterFocus optionValue="value" optionLabel="label" optionGroupLabel="label" optionGroupChildren="items" placeholder="Tous les TLAs" class="w-full" pt:label="!py-1"/>
                <div class="flex gap-x-2">
                    <InputText type="text" placeholder="Message…" maxlength="140" v-model="message" class="flex-1" autofocus @focus="onFocusFix" ref="messageInput" @keyup.enter="send" :disabled="sending"/>
                    <Button :icon="sending ? 'fa-solid fa-spinner fa-spin' : 'fa-solid fa-paper-plane-top'" title="Envoyer" @click="send" :disabled="sending || '' === message"/>
                </div>
            </div>
        </template>
    </ChatBox>
</template>

<script setup>
import Button from 'primevue/button';
import MultiSelect from 'primevue/multiselect';
import InputText from 'primevue/inputtext';
import ChatBox from '@/Vue/Components/RegulationV2/Layout/Chat/Box';
import {useRegulationStore} from "@/Vue/Stores/Regulation";
import {storeToRefs} from "pinia";
import {computed, nextTick, ref, watch, onMounted} from "vue";
import {useGeolocalisationStore} from "@/Vue/Stores/RegulationV2/Geolocalisation";

const geolocalisationStore = useGeolocalisationStore();
const {clearNotifications} = geolocalisationStore;
const {notificationsByType, roles} = storeToRefs(geolocalisationStore);

const store = useRegulationStore();
const {messagerie, messages} = storeToRefs(store);
const {onMessagerieSend} = store;
const destinataires = ref([]);
const message = ref('');
const chatBox = ref();
const messageInput = ref();

const onFocusFix = (event) => {
    event.target.style.opacity = 0;
    setTimeout(() => event.target.style.opacity = 1);
};


const allDestinatairesIds = computed(() => {
    return messagerie.value.appareils.map(appareil => appareil.id);
});

const allDestinataires = computed(() => {
    let appareils = [];
    let personnels = [];
    let vehicules = [];

    for(const appareil of messagerie.value.appareils || []) {
        if(appareil.personnel) {
            personnels.push({value: appareil.id, label: appareil.personnel.longText});
        }
        else if(appareil.vehicule) {
            vehicules.push({value: appareil.id, label: appareil.vehicule.longText});
        }
        else {
            appareils.push({value: appareil.id, label: appareil.text});
        }
    }

    const res = [];

    if (personnels.length) {
        res.push({
            label: 'Personnels',
            items: personnels
        });
    }

    if (vehicules.length) {
        res.push({
            label: 'Véhicules',
            items: vehicules
        });
    }

    if (appareils.length) {
        res.push({
            label: 'Appareils non affectés',
            items: appareils
        });
    }

    return res;
});

watch(destinataires, () => {
    nextTick(() => chatBox.value.scrollBottom());
});

const sending = ref(false);
const errors = ref([]);

const send = () => {
    if('' === message.value) {
        return;
    }

    sending.value = true;
    errors.value = [];

    const data = {
        message: message.value,
        destinataires: destinataires.value,
    };

    if (!data.destinataires.length) {
        data.destinataires.value = allDestinatairesIds.value;
    }

    onMessagerieSend(data, (res) => {
        sending.value = false;
        if (res.success) {
            message.value = '';
        } else {
            errors.value = res.errors;
        }
        nextTick(() => messageInput.value.$el.focus());
    })
}

const notifications = computed(() => notificationsByType.value.messagerie ?? []);

watch(notifications, () => {
    if (notifications.value.length) {
        clearNotifications(notifications.value);
    }
}, {immediate: true});

</script>