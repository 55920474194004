<template>
    <div ref="content">
        <slot name="content"></slot>
    </div>
    <template v-if="ready">
        <slot></slot>
    </template>
</template>

<script setup>
import {inject, onMounted, ref, toRefs, watch, shallowRef, provide, onUnmounted, computed, nextTick} from "vue";
import {Marker} from 'maplibre-gl';
import {useElementHover} from "@vueuse/core";

const emit = defineEmits(['hover', 'longHover', 'update:popup', 'update:lngLat']);
const map = inject('map')

const props = defineProps({
    focus: Boolean,
    lngLat: [Array, Object],
    anchor: {type: String, default: 'center'},
    draggable: Boolean,
    popup: Boolean,
})
const {lngLat, anchor, focus, draggable, popup: popupInput} = toRefs(props)
const content = ref();
const popup = ref(popupInput.value);
const marker = shallowRef(null);
const ready = ref(false);


const isHovered = useElementHover(content);
const isLongHovered = useElementHover(content, { delayEnter: 600, delayLeave: 300 });

watch(isLongHovered, () => emit('longHover', isLongHovered.value));
watch(isHovered, () => emit('hover', isHovered.value));

provide('marker', marker);
provide('popup', popup);
provide('lngLat', lngLat);

watch(popup, () => emit('update:popup', popup.value));
watch(popupInput, () => popup.value = popupInput.value);

onMounted(() => {
    marker.value = new Marker({
        element: content.value,
        anchor: anchor.value,
        draggable: draggable.value,
    });
    marker.value.setLngLat(lngLat.value);
    marker.value.on('dragend', () => {
        const lngLat = marker.value.getLngLat();
        emit('update:lngLat', [lngLat.lng, lngLat.lat]);
    });
    marker.value.addTo(map.value);

    ready.value = true;
});

onUnmounted(() => {
    marker.value.remove();
});

watch(lngLat, (newLngLat, oldLngLat) => {
    if (popup.value || focus.value) {
        const prevPixel = map.value.project(oldLngLat);
        const newPixel = map.value.project(newLngLat);

        const deltaPixel = [newPixel.x - prevPixel.x, newPixel.y - prevPixel.y];

        map.value.panBy(deltaPixel, {duration: 0});
    }

    marker.value.setLngLat(newLngLat);
}, {deep: true});

const isActive = computed(() => isLongHovered.value || popup.value);
watch(isActive, () => {
    nextTick(() => content.value.classList.toggle('z-[1]', isActive.value))
}, {immediate: true})
</script>