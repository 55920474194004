<template>
    <div class="flex items-center gap-x-2 w-full px-[1px]">
        <div class="h-[4px] rounded" :class="[style.bg, center ? 'flex-1': 'w-[42px]']"></div>
        <div class="font-medium" :class="style.text">
            <slot name="title">
                <i v-if="icon" :class="icon" class="fa-fw"></i>
                {{ title }}
            </slot>
        </div>
        <div class="flex-1 h-[4px] rounded" :class="style.bg"></div>
        <div class="flex gap-x-1" v-if="slots.badges">
            <slot name="badges"></slot>
        </div>
    </div>
</template>

<script setup>
import {toRefs, useSlots, computed} from "vue";

const props = defineProps({
    title: String,
    icon: String,
    center: Boolean,
    variant: {default: 'gray', type: String},
})

const {title, icon, variant} = toRefs(props);
const slots = useSlots();

const style = computed(() => ({
    light: {bg: 'bg-gray-100', text: 'text-gray-400'},
    gray: {bg: 'bg-gray-500', text: 'text-gray-500'},
    indigo: {bg: 'bg-indigo-500', text: 'text-indigo-500'},
    red: {bg: 'bg-red-500', text: 'text-red-500'},
})[variant.value]);

</script>