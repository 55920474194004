import Router from "@/App/Router";

export default {
    install: (Vue) => {
        Vue.mixin({
            methods: {
                path(route, params = {}) {
                    return Router.generate(route, params);
                },
            }
        })

    }
};
