<template>
    <div class="row flex-1">
        <div :class="full ? 'col-3 col-xl-5' : 'col-9'">
            <form-input :value="value.search" @input="update('search', $event)" :placeholder="Translator.trans('placeholder.recherche')"></form-input>
        </div>
        <div class="col-2 col-xl-1" v-if="full">
            <form-btn-group :value="value.nonControle" @input="update('nonControle', $event)" type="single_checkbox" :htmlLabels="true" :choices="nonControleChoices"></form-btn-group>
        </div>
        <div :class="full ? 'col-2 col-xl-1' : 'col-3'">
            <form-btn-group :value="value.self" @input="update('self', $event)" type="single_checkbox" :htmlLabels="true" :choices="selfChoices"></form-btn-group>
        </div>
        <div class="col-5" v-if="full && typeChoicesFilter.length">
            <form-btn-group :value="value.type" @input="update('type', $event)" type="checkbox" :choices="typeChoicesFilter"></form-btn-group>
        </div>
    </div>
</template>

<script setup>
import {computed, toRefs} from "vue";
import Translator from "@/App/Translator";

const emit = defineEmits(['input'])

const props = defineProps({
    groups: Array,
    types: Array,
    value: Object,
    full: Boolean,
});

const {groups, types, value, full} = toRefs(props);

const update = (key, val) => {
    emit('input', { ...value.value, [key]: val });
};

const nonControleChoices = computed(() => ['<span title="Pièces non contrôlées"><i class="fa-solid fa-eye '+(pieceNonControleCount.value ? 'text-danger' : 'text-success')+'"></i>' + ' ('+pieceNonControleCount.value+')</span>']);
const selfChoices = computed(() => ['<span title="Mes pièces uniquement"><i class="fa-solid fa-user"></i>' + ' ('+pieceSelfCount.value+(allPieces.value.length !== 0 ? ('/'+allPieces.value.length) : '')+')</span>']);
const allPieces = computed(() => groups.value.map(group => group.pieces).flat());
const pieceNonControleCount = computed(() => allPieces.value.filter(piece => !piece.estControle).length);
const pieceSelfCount = computed(() => allPieces.value.filter(piece => piece.createdBy?.id === App.Constants.USER_ID).length);
const pieceCountByType = computed(() => allPieces.value.reduce((res, piece) => {
    const key = piece.type ? piece.type.id : null;

    res[key] = (res[key] || 0) + 1;

    return res;
}, {}));

const typeChoicesFilter = computed(() => [{key: null, label: '?', title: 'Inconnu'}]
    .concat(types.value.map(type => ({key: type.id, label: type.abreviation, title: type.libelle})))
    .filter(item => pieceCountByType.value[item.key])
    .map(item => {
        const count = pieceCountByType.value[item.key];
        if(count) {
            item.label += ' ('+count+')';
        }

        return item;
    })
);
</script>
