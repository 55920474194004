<template>
    <td
        class="user-select-none"
        data-inputclass="js-time-input"
        :data-field="field"
        :data-url="url"
        :data-name="name+'['+field+']'"
        data-placement="bottom" :data-placeholder="placeholder"
    ><slot></slot></td>
</template>

<script>
    export default {
        props: ['field', 'value', 'url', 'placeholder', 'name', 'disabled'],
        mounted() {
            if (this.disabled !== true) {
                let config = {
                    emptytext: '',
                    toggle: 'dblclick',
                    params: (params) => {
                        let res = {};
                        res[params.name] = params.value;

                        return res;
                    },
                    send: 'always',
                    showbuttons: false,
                    savenochange: true,
                    ajaxOptions: {
                        type: 'PATCH'
                    },
                    success: (response, newValue) => {
                        $(this.$el).find('.js-editable-text').text(newValue);
                    },
                    display: () => {
                        return false;
                    }
                };

                $(this.$el).on('shown', (e, editable) => {
                    $('.js-time-input').attr('data-inputmask', "'alias': 'custom-datetime'").attr('data-inputmask-inputformat', 'HH:MM');
                    App.Layout.enableCustomInputs();
                }).editable(config);
            }
        }
    }
</script>