<template>
    <Section title="Interface" icon="fa-regular fa-browser"></Section>
    <Group>
        <Row label="Mode sombre" v-model="darkMode" :choices="{false: 'Off', true: 'On', null: 'Auto'}"></Row>
    </Group>

    <template v-if="roles.ROLE_REGULATION_V2_CARTOGRAPHIE">
        <Section title="Carte" icon="fa-regular fa-map"></Section>
        <SelectButton v-model="mapStyle" class="flex" :allowEmpty="false" :pt="{pcButton: {root: '!p-3 !pb-2 mt-2 flex-1', content: 'flex flex-col !gap-1'}}" :options="Object.values(mapStyles)">
            <template #option="slotProps">
                <img class="rounded shadow-sm w-full h-16 object-cover" :src="slotProps.option.config.preview"/>
                <span class="font-semibold text-sm">{{ slotProps.option.name }}</span>
            </template>
        </SelectButton>
        <Group>
            <Tree ref="layerTree" class="!p-0" pt:pcFilterContainer:root="mb-2" :filter="true" filterPlaceholder="Calques" filterMode="lenient" v-model:selectionKeys="selectedLayers" v-model:expandedKeys="expandedLayers" :value="layersTree" selectionMode="checkbox">
                <template #group="{node}">
                    <span class="font-semibold">{{ node.label }}</span>
                </template>
                <template #default="{node}">
                    <span :class="{'text-slate-500': !selectedLayers[node.key] || (!selectedLayers[node.key].checked && !selectedLayers[node.key].partialChecked)}">{{ node.label }}</span>
                </template>
            </Tree>
        </Group>
<!--        <Group>-->
<!--            <Row label="Afficher les incidents" v-model="mapLayers.trafficIncidents"></Row>-->
<!--            <Row label="Afficher le flux de trafic" v-model="mapLayers.trafficFlow"></Row>-->
<!--            <Row label="Afficher les points d'intéret" v-model="mapLayers.poi"></Row>-->
<!--        </Group>-->
    </template>
    <template v-if="showDebug">
        <Section title="Debug" variant="red" icon="fa-solid fa-bug"></Section>
        <Group>
            <Row label="Afficher la carte" v-model="mapVisible"></Row>
            <Row label="Mode sombre carte" v-model="mapDarkMode"></Row>
            <Row label="Afficher la zone de travail" v-model="debug.mapPadding"></Row>
            <Row label="Pin notification interval" v-model="debug.pinNotificationInterval"></Row>
            <Row label="Toujours détacher popup ressource" v-model="debug.forceDetachRessourcePopup"></Row>
            <Row label="Afficher toutes les traces" v-model="ressourcesShowAllTraces"></Row>
            <Row label="Afficher tous les trajets en cours" v-model="ressourcesShowAllTrajets"></Row>
        </Group>
    </template>
</template>

<script setup>
import SelectButton from 'primevue/selectbutton';
import {storeToRefs} from "pinia";
import {useGeolocalisationStore} from "@/Vue/Stores/RegulationV2/Geolocalisation";
import Section from "@/Vue/Components/RegulationV2/Layout/Section";
import Group from "@/Vue/Components/RegulationV2/Sidebar/Parametres/Group";
import Row from "@/Vue/Components/RegulationV2/Sidebar/Parametres/Row";
import {ref, computed} from "vue";
import Tree from 'primevue/tree';

const geolocalisationStore = useGeolocalisationStore();

const {roles, darkMode, mapVisible, mapDarkMode, mapStyle, mapStyles, mapLayers, debug, ressourcesShowAllTraces, ressourcesShowAllTrajets} = storeToRefs(geolocalisationStore)
const {mapPois} = geolocalisationStore;

const showDebug = ref(App.Constants.DEBUG);

const layerTree = ref();

const expandedLayers = computed(() => {
    const defaultLayers = layersTree.value.reduce((res, item) => (res[item.key] = true, res), {});

    if (layerTree.value && layerTree.value.filterValue !== '' && layerTree.value.filterValue !== null) {
        const expandSearch = (items) => {
            for (const item of items) {
                defaultLayers[item.key] = true;

                if (item.children) {
                    expandSearch(item.children);
                }
            }
        }

        expandSearch(layerTree.value.filteredValue);
    }

    return defaultLayers;
});
const selectedLayers = computed({
    get() {
        const res = {};

        for (const key in mapLayers.value) {
            const value = mapLayers.value[key];

            if (Array.isArray(value)) {
                const handle = (group) => {
                    let checkedCount = 0;
                    let total = group.children.length;

                    for (const item of group.children) {
                        if (item.children) {
                            const counts = handle(item);
                            checkedCount += counts[0];
                            total += counts[1];
                            if (counts[0] === counts[1]) {
                                checkedCount++;
                            }
                        } else {
                            if (value.includes(item.key.split('.')[1])) {
                                res[item.key] = {checked: true};
                                checkedCount++;
                            }
                        }

                    }

                    res[group.key] = {checked: total === checkedCount, partialChecked: checkedCount > 0 && total !== checkedCount};

                    return [checkedCount, total];
                }

                handle(layersTreeByKey.value[key]);
            } else if (typeof value === 'object') {
                let checkedCount = 0;

                for (const layer in value) {
                    if (value[layer]) {
                        res[key+'.'+layer] = {checked: true};
                        checkedCount++;
                    }
                }

                const total = Object.keys(value).length;
                res[key] = {checked: checkedCount === total, partialChecked: checkedCount > 0 && checkedCount !== total};
            } else {
                res[key] = {checked: value};
            }
        }

        return res;
    },
    set(data) {
        for (const key in mapLayers.value) {
            const value = mapLayers.value[key];

            if (Array.isArray(value)) {
                const newValue = [];

                const handle = (children) => {
                    for (const item of children) {
                        if (item.children) {
                            handle(item.children);
                        } else {
                            if (data[item.key]?.checked ?? false) {
                                newValue.push(item.key.split('.')[1]);
                            }
                        }

                    }
                }

                handle(layersTreeByKey.value[key].children);

                mapLayers.value[key] = newValue;
            } else if (typeof value === 'object') {
                for (const layer in value) {
                    value[layer] = data[key+'.'+layer]?.checked ?? false;
                }
            } else {
                mapLayers.value[key] = data[key]?.checked ?? false
            }
        }
    }
})

const layersTree = computed(() => [
    {
        key: 'traffic',
        label: 'Info-trafic',
        type: 'group',
        children: [
            {
                key: 'traffic.incidents',
                label: 'Incidents',
                icon: 'fa-fw fa-solid fa-traffic-cone',
            },
            {
                key: 'traffic.flow',
                label: 'Flux',
                icon: 'fa-fw fa-solid fa-traffic-light-go',
            },
            {
                key: 'radars',
                label: 'Radars',
                icon: 'fa-fw fa-solid fa-radar',
            }
        ]
    },
    {
        key: 'poi',
        label: 'Points d\'intéret',
        type: 'group',
        children: mapPois.map((group, index) => ({
            key: '_poi_group_'+index,
            label: group.name,
            icon: group.icon,
            children: Object.keys(group.categories).map(categorie => ({
                key: 'poi.'+categorie,
                label: group.categories[categorie],
            })),
        })),
    },
    {
        key: 'ressources',
        label: 'Ressources',
        type: 'group',
    },
    {
        key: 'bureaux',
        label: 'Bureaux',
        type: 'group',
    },
]);
const layersTreeByKey = computed(() => layersTree.value.reduce((res, group) => (res[group.key] = group, res), {}));

// todo keyboard shortcut to toggle
</script>